import { Router } from '@angular/router';
import { Observable, filter, first, forkJoin, map, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/recipe/reducers';
import { Inject, Injectable } from '@angular/core';
import { CustomerUtilService } from './customer-util.service';
import { RecipeConstants } from '@gfs/constants';
import { InjectionTokens } from '@gfs/shared-services';
import { CustomerPK, RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';


@Injectable({
    providedIn: 'root',
})
export class RecipeRouteAccessService {

    constructor(
        public router: Router,
        @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
        public store: Store<AppState>,
        @Inject(InjectionTokens.ACTIVE_APPLICATION) public activeApplication: string,
        public customerUtilsService: CustomerUtilService,
    ) { }

    pk$: Observable<CustomerPK> = this.store
        .select(state => state.auth.pk)
        .pipe(
            filter(pk => !!pk),
            first()
        );

    shouldRedirectToRecipeProfitCalculator(
        appKey: string,
        pk: CustomerPK
    ): boolean {
        const conditions = [
            RecipeConstants.APPLICATION_KEY == appKey,
            this.customerUtilsService.isUsCustomer(pk),
        ];
        return conditions.every(isTrue => isTrue);
    }

    redirectToRecipeProfitCalculator(): void {
        this.router.navigateByUrl(this.recipeProfitCalculatorConfig.RECIPE_PROFIT_CALCULATOR_PATH);
    }

    handleRedirectToRecipeProfitCalculator(
        redirectToRecipeProfitCalculator: boolean
    ): boolean {
        if (redirectToRecipeProfitCalculator) {
            this.redirectToRecipeProfitCalculator();
            return false;
        }
        return true;
    }

    canActivate(): Observable<boolean> {
        return forkJoin([
            of(this.activeApplication),
            this.pk$
        ])
            .pipe(
                map(([activeApplication, pk]) => this.shouldRedirectToRecipeProfitCalculator(activeApplication, pk)),
                map((redirectToRecipeProfitCalculator: boolean) => this.handleRedirectToRecipeProfitCalculator(redirectToRecipeProfitCalculator))
            );
    }
}

<ng-container *ngFor="let element of worksheetItem.itemCounts">

    <div
        class="item-count"
        *ngIf="worksheetItem?.itemCounts"
    >
        <app-inventory-count-entry
            [localizedUnits]="localizedUnits"
            [itemCountEntry]="element"
            [worksheetItem]="worksheetItem"
            [disableUnitChange]="disableUnitChange"
            (countEntryCompleted)="tryFocusNextUnitTier($event)"
            (productCountChange)="valueChange$.next($event)"
        ></app-inventory-count-entry>
    </div>

</ng-container>
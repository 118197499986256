import {
    Component,
    ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild
  } from '@angular/core';
  import { Form, FormBuilder, FormControl } from '@angular/forms';
  import { MatSelect } from '@angular/material/select';
  import { InventoryConstants } from '@gfs/constants';
  import { IAppContext, IDictionary } from '@gfs/shared-models';
  import {
    InjectionTokens
  } from '@gfs/shared-services';
  import { AppState } from '@gfs/store/inventory/reducers';
  import { ItemCountEntryDTO, TypedLocalizedMeasurementUnitDTO, WorksheetItemDTO } from '@gfs/v2/shared-models';
  import { Store } from '@ngrx/store';
  
  @Component({
    selector: 'app-inventory-count-entry',
    templateUrl: './inventory-count-entry.component.html',
    styleUrls: ['./inventory-count-entry.component.scss']
  })
  export class InventoryCountEntryComponent{
  
    constructor(
      public store: Store<AppState>,
      @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
      private _formBuilder: FormBuilder
    ) { }
  
    // Qty # Input
    @ViewChild('itemCountInput') itemCountInput: ElementRef;
    // Unit Select Input
    @ViewChild('countingUnitSelect') countingUnitSelect: MatSelect;
    @ViewChild('frm') theForm: Form;
  
    @Input() disableUnitChange = true;
  
    @Input() worksheetItem: WorksheetItemDTO;
  
    @Input() itemCountEntry: ItemCountEntryDTO;
  
    // all localized units
    @Input() localizedUnits: IDictionary<TypedLocalizedMeasurementUnitDTO>;
  
    @Output() countEntryCompleted = new EventEmitter<ItemCountEntryDTO>();
    @Output() productCountChange = new EventEmitter<ItemCountEntryDTO>();
  
  
    qtyRegex = InventoryConstants.ITEM_QTY_REGEX;
    hasItemTripleDot = false;
    isMobile$ = this.appContext.isMobile;
  
  
    countedQty = new FormControl();
    countingUnit = new FormControl();
  
    options = this._formBuilder.group({
      countedQty: this.countedQty,
      countingUnit: this.countingUnit,
    });
  
    async onQuantityUpdate(updatedValue: any) {
  
      let j = `${updatedValue}`;
  
      if (j.length > 0) {
        if (j[0] === '.') { j = `0.${j}`; }
        this.itemCountEntry.qty = +j;
      }
      else {
        this.itemCountEntry.qty = null;
      }
      this.productCountChange.emit(this.itemCountEntry);
    }
    disableInput() {
      if(this.itemCountEntry.unit == '--'){
        this.options.get('countedQty').disable();
      } else {
        this.options.get('countedQty').enable();
      }
    }
  
    async countAddClicked(
      item: ItemCountEntryDTO
    ) {
      item.qty++;
      await this.onQuantityUpdate(item.qty);
    }
  
    async countSubtractClicked(
      item: ItemCountEntryDTO
    ) {
      if (item.qty > 0) {
        item.qty--;
        await this.onQuantityUpdate(item.qty);
      }
    }
  
    optionComparer(obj1: any, obj2: any): boolean {
      return obj1 === obj2
    }
  
    getOptionValue(unitKey: string) {
      const localizedUnit = this.localizedUnits[unitKey];
  
      if (!localizedUnit) {
        return `${unitKey} is INVALID`
      }
  
      return localizedUnit.key;
    }
  
    getOptionText(unitKey: string) {
  
      const localizedUnit = this.localizedUnits[unitKey];
  
      if (!localizedUnit) {
        return {
          'en_CA': `unknown option with key: ${unitKey}`,
          'fr_CA': `unknown option with key: ${unitKey}`,
        };
      }
      return localizedUnit.localization;
  
    }
    
    getUniqueConfiguredCountingUnits(configuredCountingUnits){
      const excludedUnitList = [
        // units representing an average of a value
        "KGA", // kilogram average
        "LBA", "lb avg", // pound average
        "OZA", // ounce average
        "UNA", // unit average
        "GA",  // gram average
        "CTA", "CtAvg", // count average
      ];
      
      return configuredCountingUnits
      .filter((v) => excludedUnitList.indexOf(v) === -1)
      .filter((v, i, a) => this.getOptionTextArray(a).indexOf(this.getOptionText(v).en_CA.toLowerCase()) === i);
    }
  
    getOptionTextArray(configuredCountingUnitsList){
      const optionTextArray = [];
      configuredCountingUnitsList.forEach( unit => {
        optionTextArray.push(this.getOptionText(unit).en_CA.toLowerCase());
      });
      return optionTextArray;
    }
  }
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class WhitespaceOnlyValidator {
    public static validate(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean; } | null => {
            const isWhitespaceOnly = (control.value || '').trim().length === 0;
            return isWhitespaceOnly ? { whitespace: true } : null;
        };
    }
}

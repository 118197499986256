<mat-form-field
  class="storage-area-select-container"
  floatLabel="never"
>
  <mat-label class="storage-area-select-label">
    {{ 'INVENTORY_WORKSHEET.JUMP_TO_STORAGE_AREA_LABEL' | translate }}
  </mat-label>
  <mat-select panelClass="storage-area-select-panel">
    <mat-option
      *ngxPermissionsOnly="permissionRoles.createStorageArea"
      id="new-storage-area-option-button"
      class="new-storage-area-option storage-area-select-button"
      [disabled]="!(appContext.isOnline$ | async)"
      [routerLink]="[
        { outlets: { 'modal': [ 'storage-area','new' ] } }
      ]"
    >
      <div class="new-storage-area-label">
        <mat-icon
          [hidden]="!(isOnline$ | async)"
          class="icon storage-area-plus-icon"
          svgIcon="plus-icon"
        ></mat-icon>
        <mat-icon
          [hidden]="isOnline$ | async"
          class="icon storage-area-plus-icon"
          svgIcon="plus-icon-gray"
        ></mat-icon>
        {{ 'STORAGE_AREA.ADD_STORAGE_AREA' | translate }}
      </div>
    </mat-option>
    <ng-container *ngFor="let currentStorageAreaId of this.worksheetModel.storageAreaOrder">
      <ng-container *ngIf="worksheetModel.storageAreas[currentStorageAreaId] as storageArea">
        <mat-option
          class="new-storage-area-option storage-area-select-button"
          (click)='storageAreaSelected(storageArea)'
        >
          <gfs-localized-text-value
            [value]="storageArea.name"
            [maxLength]="17"
          />
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
</mat-form-field>
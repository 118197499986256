import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CustomerEffects } from './effects';
import * as fromCustomerEffects from './reducer';
import * as fromCustomerEffectsState from './state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCustomerEffectsState.FeatureKey, fromCustomerEffects.customerUnitSelectionReducer),
    EffectsModule.forFeature([CustomerEffects])
  ],
  declarations: []
})
export class CustomerUnitSelectionStateModule {}

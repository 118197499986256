import { AppConfigService, IAppConfig } from '@gfs/shared-services';

export class AppFeatureFlags implements IAppFeatureFlags {
  Recipe: { EnableUserRecipeImages: boolean; };
  Inventory: {
    EnableReports: boolean;
    MigrateFromInventoryManager: boolean;
    UseWorksheetV2: boolean;
  };
  System: {
    UseStaticSAPUnits: boolean;
  };
}

export interface IAppFeatureFlags {
  Recipe: {
    EnableUserRecipeImages: boolean;
  };
  Inventory: {
    EnableReports: boolean;
    MigrateFromInventoryManager: boolean;
    UseWorksheetV2: boolean;
  };
  System: {
    UseStaticSAPUnits: boolean;
  };
}

export const appFeatureFlagsFactory = (configService: AppConfigService):
  IAppFeatureFlags => mapFromIAppConfigToAppFeatureFlags(configService.getSettings());

export const mapFromIAppConfigToAppFeatureFlags = (config: IAppConfig): IAppFeatureFlags => {
  const {
    FF_ENABLE_USER_RECIPE_IMAGES,
    FF_REPORTS,
    FF_MIGRATE_FROM_INVENTORY_MANAGER,
    FF_USE_WORKSHEET_V2,
  } = config;
  return {
    Recipe: {
      EnableUserRecipeImages: FF_ENABLE_USER_RECIPE_IMAGES,
    },
    Inventory: {
      EnableReports: FF_REPORTS,
      MigrateFromInventoryManager: FF_MIGRATE_FROM_INVENTORY_MANAGER,
      UseWorksheetV2: FF_USE_WORKSHEET_V2,
    },
    System: {
      UseStaticSAPUnits: false,
    }
  };
};
import { InjectionToken } from '@angular/core';

export const InjectionTokens = {
  // Service Injection
  IAddItemsModalAppSettingsProviderService: 'IAddItemsModalAppSettingsProviderService',
  IAddItemsFeatureBridge: 'IAddItemsFeatureBridge',
  IQuickAddItemsFeatureBridge: 'IQuickAddItemsFeatureBridge',
  ICustomerUnitSelectionBridge: 'ICustomerUnitSelectionBridge',
  IInventoryDataProviderService: 'IInventoryDataProviderService',
  IGlobalDialogService: 'IGlobalDialogService',

  // Value Injection
  API_BASE_URL: 'API_BASE_URL',
  INVENTORY_API_BASE_URL: 'INVENTORY_API_BASE_URL',
  IAPP_CONTEXT: 'APP_CONTEXT',
  ACTIVE_APPLICATION: new InjectionToken<string>('ACTIVE_APPLICATION'),
};

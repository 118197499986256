<form>
  <mat-form-field>
    <mat-icon class="icon-style search-icon" matPrefix svgIcon="search_icon"></mat-icon>
    <input
      matInput
      [placeholder]="'CUSTOMER_UNIT.SEARCH' | translate"
      autocomplete="off"
      name="searchText"
      [(ngModel)]="value"
      (ngModelChange)="search()"
      [attr.aria-label]="'CUSTOMER_UNIT.SEARCH' | translate"
      [maxlength]="maxInputLength"
    />
    <button
      type="button"
      mat-button
      disableRipple
      *ngIf="value"
      matSuffix
      mat-icon-button
      [attr.aria-label]="'CLEAR.CLEAR_SEARCH' | translate"
      (click)="clearSearch()"
    >
      <mat-icon class="icon-style" svgIcon="clear_icon"></mat-icon>
    </button>
    <mat-hint class="hint-style">{{
      'CUSTOMER_UNIT.SEARCH_HINT' | translate
    }}</mat-hint>
  </mat-form-field>
</form>

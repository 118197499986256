export * from './injection-tokens';
export * from './lib/app-settings-providers/add-items-modal-app-settings-provider.service';
export * from './lib/app-settings-providers/feature-flags.factory';
export * from './lib/core/entityUtil';
export * from './lib/guards/app-config.guard';
export * from './lib/guards/feature-authorization.guard';
export * from './lib/guards/unit-selection-guard.service';
export * from './lib/services/GlobalDialogsService.interface';
export * from './lib/services/app-config/IAppConfig';
export * from './lib/guards/unit-selection.guard.fn';
export * from './lib/guards/recipe-route.guard.fn';
export * from './lib/guards/app-config.guard';
export * from './lib/guards/app-config.guard.fn';
export * from './lib/interceptors/authorization.interceptor';
export * from './lib/resolvers/auth-resolver';
export * from './lib/resolvers/auth-resolver.fn';
export * from './lib/resolvers/set-authorization-resolver.fn';
export * from './lib/services/app-config/app-config.service';
export * from './lib/services/app-config/mock-app-config.service';
export * from './lib/services/category.service';
export * from './lib/services/countable-item.service';
export * from './lib/services/custom-item-data.service';
export * from './lib/services/custom-item.service';
export * from './lib/services/custom-unit-utils.service';
export * from './lib/services/customer.service';
export * from './lib/services/error-log-base.service';
export * from './lib/services/general-item.service';
export * from './lib/services/gfs-applications/gfs-applications.service';
export * from './lib/services/google-tag-manager/data-layer.service';
export * from './lib/services/inventory-data-provider';
export * from './lib/services/inventory-icon.service';
export * from './lib/services/inventory-utils.service';
export * from './lib/services/inventory-value.service';
export * from './lib/services/inventory.service';
export * from './lib/services/item-category.service';
export * from './lib/services/item-text-search.service';
export * from './lib/services/localized-value-utils.service';
export * from './lib/services/measurement-unit.service';
export * from './lib/services/message.service';
export * from './lib/services/online-heartbeat/online-heartbeat.service';
export * from './lib/services/product.service';
export * from './lib/services/recipe.service';
export * from './lib/services/reports.service';
export * from './lib/services/storage-area-utils.service';
export * from './lib/services/supplier.service';
export * from './lib/services/unit-conversion-util.service';
export * from './lib/services/user/user.service';
export * from './lib/services/window.service';
export * from './lib/services/worksheet.service';
export * from './lib/shared-services.module';
export * from './lib/util/auth/auth-util';
export * from './lib/validation/category.validator';
export * from './lib/validation/storage-area.validator';
export * from './lib/validation/whitespace-only.validator';
export * from './v2/services/WorksheetHttp.service';
export * from './v2/services/item/item-data.bll';
export * from './v2/services/item/item-data.service';
export * from './v2/v2.shared';
export * from './lib/models/ActivatedAuthorizedRouteSnapshot';
export * from './lib/models/AuthorizedRouteData';
export * from './lib/models/AuthorizedRoute';
export * from './lib/recipe-profit-calculator/recipe-profit-calculator.config';
export * from './lib/guards/feature-authorization.guard';




import { InventoryConstants } from '@gfs/constants';
import { GfsMaterialUnit, IAppContext, IDictionary, LocalizedValue } from '@gfs/shared-models';
import { TranslateService } from '@ngx-translate/core';
import { Observable, first, forkJoin, map } from 'rxjs';
//#region
import gfsMaterialUnitData from 'static-data/api/gfs.v1.materials.units.json';
import usDisplayCodeMap from 'static-data/configuration/mappings/US_UNIT_DISPLAYCODE_MAP.json';

export const API_DATA_GFS_MATERIAL_UNITS: GfsMaterialUnit[] = gfsMaterialUnitData as unknown as GfsMaterialUnit[];
export const US_DISPLAYCODE_SAP_MAPPING: IDictionary<string> = usDisplayCodeMap as unknown as IDictionary<string>;

export type KeyedLocalizedValue = {
  type: string;
  name: LocalizedValue[];
};

export type localizedSAPUnits = {
  single: KeyedLocalizedValue[];
  plural: KeyedLocalizedValue[];
};

export const langCodeMap = {
  'en': 'en_CA',
  'fr': 'fr_CA'
};



//#endregion


export interface IDisposable {
  dispose: () => void;
}

export function escapeRegex(value: string) {
  // The token $& signifies the matched text, which is escaped by a backslash
  return value.replace(InventoryConstants.REGEX_SPECIAL_CHARACTERS, String.raw`\$&`);
}

export function localizeCatalogueToUSEnglishSpelling(
  appContext: IAppContext,
  translate: TranslateService,
  key: string
): Observable<string> {
  return forkJoin([
    appContext.customerPK.pipe(first()),
    appContext.language.pipe(first())
  ])
    .pipe(
      map(([pk, language]) => {
        const localizedText: string = translate.instant(key);

        const isUsEnglishUser = [
          pk.salesOrg == "1000",
          language == InventoryConstants.LANGUAGES.ENGLISH
        ].every(isTrue => isTrue);

        if (isUsEnglishUser) {
          return localizedText.replace('Catalogue', 'Catalog');
        } else {
          return localizedText
        }
      })
    );
}

export function ResolveLocalizationsInObject(potentiallyLocalizedObject: any, lang: string) {
  return Object.keys(potentiallyLocalizedObject).reduce((prev, paramKey) => {
    try {
      return { ...prev, [paramKey]: potentiallyLocalizedObject[paramKey][lang] };
    } catch (ex) {
      return { ...prev, [paramKey]: potentiallyLocalizedObject[paramKey] };
    }
  }, {});
}

import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { trim } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class CategoryValidator {
  public static validate(
    itemCategories$: Observable<any[]>,
    isSavingAddItems$: Observable<boolean>,
    allowEmpty = false
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      let returnValue = { invalidCategory: true };
      if (control) {
        return zip(itemCategories$, isSavingAddItems$).pipe(
          take(1),
          map(([cats, isSaving]) => {
            const value = trim(control.value);
            const selectedCat = (cats ?? []).find(
              (c) => c.name.toLowerCase() === value.toLowerCase()
            );
            const isEmpty = allowEmpty && !value.length;
            if (selectedCat || isSaving || isEmpty || control.touched) {
              returnValue = null;
            }
            return returnValue;
          })
        );
      }
      return of(returnValue);
    };
  }
}

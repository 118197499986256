import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';

@Component({
  selector: 'gfs-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    const importGate = this.dialog.getDialogById(InventoryConstants.LOADING_MODAL_ID + '_RESET_WORKSHEET');
    importGate?.close();
  }

  closeModal() {
    this.dialogRef.close();
  }
}

<div id="item-card-{{ addItemIndex }}" class="item-card">
  <mat-grid-list [cols]="8" [rowHeight]="(isMobile$ | async) ? calculateRowHeight() : '8px'" gutterSize="0px">
    <mat-grid-tile [colspan]="(isMobile$ | async) ? 8 : 6" [rowspan] = "(isMobile$ | async) ? 1 : 8" class="item-description-tile">
      <div id="item-image-wrapper-{{ addItemIndex }}" class="item-image-wrapper">
        <img id="item-thumbnail-{{ addItemIndex }}" class="item-thumbnail" [src]="getLocalizedImageUrl(48,48) | async"
          [alt]="getLocalizedProductDescription() | async">
      </div>
      <div>

      <div *ngIf="isValidItem()" class="item-description-container">

        <div id="item-title-{{ addItemIndex }}" class="item-title">
          {{getLocalizedProductDescription() | async}}
        </div>

        <div *ngIf="!!item.gfsItem" id="item-details-{{ addItemIndex }}" class="item-details">
          #{{getItemId()}} | {{ getLocalizedBrand() | async }} | 
          {{ getUnitDetails() | async }}
        </div>
        <div *ngIf="!!item.customItem || !!item.recipeItem" id="item-details-{{ addItemIndex }}" class="item-details">
          #{{getItemId()}} | {{getLocalizedBrand() | async}}
        </div>

        <div *ngxPermissionsOnly="pricingPermissions">
          <div id="add-item-price-{{ addItemIndex }}" *ngIf="(isMobile$ | async)" class="price-container" >
            <span>{{ 'ADD_ITEMS.CASE' | translate }} <span class="case-price">{{getItemCasePrice() > 0 ? (getItemCasePrice() | currency:'CAD':'$':undefined:(currentLang$ | async)) : '-'}}</span></span>
            <span *ngIf="!isEditItem">{{ 'ADD_ITEMS.UNIT' | translate }} <span class="unit-price">{{getItemUnitPrice() > 0 ? (getItemUnitPrice() | currency:'CAD':'$':undefined:(currentLang$ | async)) : '-'}}</span></span>
          </div>
        </div>

      </div>

      <div id="add-item-mobile-{{ addItemIndex }}" *ngIf="(isMobile$ | async) && !isEditItem" class="add-item-button-wrapper">
        <button id="add-item-button-mobile-{{ addItemIndex }}" class="add-item-button" (click)="onAddItemSelect()">
          <mat-icon class="icon add-item-plus-icon" svgIcon="plus-icon"></mat-icon>
          <span class="add-item-button-label">{{'ADD_ITEMS.ADD_ITEM' | translate}}</span>
        </button>
      </div>
    </div>
    </mat-grid-tile>
    <div *ngIf="!(isMobile$ | async)">
      <div id="item-price-unit-and-value-{{ addItemIndex }}" *ngxPermissionsOnly="pricingPermissions">
        <mat-grid-tile [colspan]="1" [rowspan]="8" class="price-labels-tile">
          <div id="item-unit-container-{{ addItemIndex }}" class="label-container">
            <div class="case-label" *ngIf="getItemPurchaseUnitDisplayCode(currentLang$ | async) && unitTranslations ; else caseTranslation">
              {{ getItemPurchaseUnitDisplayCode(currentLang$ | async) | translate  }}
            </div>
            <ng-template #caseTranslation class="case-label">
              {{ 'ADD_ITEMS.CASE' | translate }}
            </ng-template>
            <div class="unit-label"  *ngIf="!isEditItem">
              {{ 'ADD_ITEMS.UNIT' | translate }}
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="8" class="item-price-tile">
          <div id="item-price-container-{{ addItemIndex }}" class="price-container">
            <div id="item-case-price-{{ addItemIndex }}" class="case-price">
              {{getItemCasePrice() > 0 ? (getItemCasePrice() | currency:'CAD':'$':undefined:(currentLang$ | async)) : '-'}}
            </div>
            <div id="item-unit-price-{{ addItemIndex }}" class="unit-price"  *ngIf="!isEditItem">
              {{getItemUnitPrice() > 0 ? (getItemUnitPrice() | currency:'CAD':'$':undefined:(currentLang$ | async)) : '-'}}
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="6" [rowspan]="5">
          <!-- TODO: add labels here -->
        </mat-grid-tile>
      </div>
      <mat-grid-tile [colspan]="2" [rowspan]="5" class="button-tile">
        <button id="add-item-button-{{ addItemIndex }}" *ngIf="!isEditItem" class="add-item-button" (click)="onAddItemSelect()">
          <mat-icon class="icon add-item-plus-icon" svgIcon="plus-icon" ></mat-icon>
          <span class="add-item-button-label">{{'ADD_ITEMS.ADD_ITEM' | translate}}</span>
        </button>
      </mat-grid-tile>
    </div>
  </mat-grid-list>
</div>
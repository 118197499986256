import { Injectable } from '@angular/core';
import { Cart, Entitlement } from '@gfs/shared-models';
import { Observable } from 'rxjs';

export interface ICustomerUnitSelectionFeatureBridge {
  selectedEntitlement: Observable<Entitlement> | null;
  cartsQuantities: Observable<{
    [customerId: string]: Cart;
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class MockCustomerUnitSelectionContextFactoryService {
  public static factory(mockObj: ICustomerUnitSelectionFeatureBridge) {
    return mockObj;
  }
}

import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  MaterialModule,
  PipeSharingModule,
  SharedComponentsModule
} from '@gfs/shared-components';

@NgModule({
  imports: [
    TranslateModule.forChild(),
    CommonModule,
    BrowserModule,
    FormsModule,
    MaterialModule,
    PipeSharingModule,
    SharedComponentsModule
  ],
  declarations: [],
  exports: [NgxPermissionsModule]
})
export class SharedModule { }

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { InventoryConstants } from '@gfs/constants';
import { CountableItem, CountingUnit, IDictionary, MeasurementUnit, StorageArea, Worksheet, WorksheetItem } from '@gfs/shared-models';
import { US_DISPLAYCODE_SAP_MAPPING } from '@gfs/shared-services';
import { get, trim } from 'lodash-es';
import { iif, merge, Observable, of, zip } from 'rxjs';
import { concatMap, filter, map, mergeMap, take, toArray } from 'rxjs/operators';
import { UnitsHttpService } from '../../v2/services/unit/unit-http-service.service';
import { caseInsensitiveEquals } from '../extensions/primitive';
import { LocalizedValueUtilsService } from './localized-value-utils.service';
import { UnitConversionUtilService } from './unit-conversion-util.service';

@Injectable({
  providedIn: 'root'
})
export class StorageAreaUtilsService {

  constructor(
    public localizedValueUtils: LocalizedValueUtilsService,
    public unitConversionUtilService: UnitConversionUtilService,
    public unitsService: UnitsHttpService
  ) { }
  titleCase = new TitleCasePipe();

  getTranslation(name: string) {
    switch (name) {
      case 'Cooler':
      case 'Réfrigérateur':
        return 'INVENTORY_WORKSHEET.COOLER';
      case 'Dry Storage':
      case 'Entreposage sec':
        return 'INVENTORY_WORKSHEET.DRY_STORAGE';
      case 'Freezer':
      case 'Congélateur':
        return 'INVENTORY_WORKSHEET.FREEZER';
      case 'Unassigned':
      case 'Non assigné':
        return 'INVENTORY_WORKSHEET.UNASSIGNED';
    }
    return name;
  }

  getRowItemCount(storageArea: StorageArea) {
    if (storageArea.worksheetItems) {
      return storageArea.worksheetItems.length;
    }
    return 0;
  }

  getStorageAreasMinusUnassigned(worksheet: Observable<Worksheet>): Observable<StorageArea[]> {
    return this.getStorageAreasMinusCurrentStorageArea(worksheet, 'unassigned');
  }

  getAllStorageAreas(worksheet: Observable<Worksheet>): Observable<StorageArea[]> {
    return zip(this.getUnassignedStorageAreas(worksheet), this.getStorageAreasMinusUnassigned(worksheet))
      .pipe(map(x => x.flat()))
  }

  getStorageAreasMinusCurrentStorageArea(
    worksheet: Observable<Worksheet>,
    storageAreaId: string
  ): Observable<StorageArea[]> {
    return worksheet.pipe(
      // don't emit a value if the supplied worksheet doesn't exist
      filter(ws => !!ws && !!ws.storageAreas),
      map((ws: Worksheet) => {
        const storageAreaOrder = ws.storageAreaOrder || [];
        const storageAreaOrderL = storageAreaOrder.length;
        let storageAreas = ws.storageAreas;
        if (storageAreaOrderL) {
          storageAreas = storageAreas.sort((sa1, sa2) => {
            let index1 = storageAreaOrder.indexOf(sa1.id);
            let index2 = storageAreaOrder.indexOf(sa2.id);
            index1 = index1 < 0 ? storageAreaOrderL : index1;
            index2 = index2 < 0 ? storageAreaOrderL : index2;

            return index1 - index2;
          });
        }
        return storageAreas.filter(sa => sa.id !== storageAreaId);
      })
    );
  }

  getUnassignedStorageArea(worksheet: Observable<Worksheet>): Observable<StorageArea> {
    return worksheet.pipe(
      // don't emit a value if the supplied worksheet and storageAreas doesn't exist
      filter(ws => !!ws && !!ws.storageAreas),
      map((ws: Worksheet) => ws.storageAreas.find(sa => sa.id === 'unassigned'))
    );
  }

  getUnassignedStorageAreas(worksheet: Observable<Worksheet>): Observable<StorageArea[]> {
    return worksheet.pipe(
      // don't emit a value if the supplied worksheet doesn't exist
      filter(ws => !!ws && !!ws.storageAreas),
      map((ws: Worksheet) => {
        const storageAreas = ws.storageAreas;

        return storageAreas.filter(sa => sa.id === 'unassigned');
      })
    );
  }


  storageAreaValidator(
    isSavingWorksheet$: Observable<boolean>,
    worksheet$: Observable<Worksheet>,
    translateFunction: (value) => string,
    allowEmpty = false
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control) {
        return isSavingWorksheet$.pipe(take(1), mergeMap(isSaving => worksheet$.pipe(
          filter(ws => !!ws && !!ws.storageAreas),
          take(1),
          map(ws => {
            const value = trim(control.value);
            const validArea = ws.storageAreas.find(area =>
              translateFunction(area.name) === value
            );
            const isEmpty = allowEmpty && !value.length;
            if (validArea || isSaving || isEmpty || control.touched) {
              return null;
            }
            return { invalidStorageArea: true };
          })
        )));
      }
      return of({ invalidStorageArea: true });
    };
  }

  getPerCaseDetails(
    worksheetItemRef: WorksheetItem,
    lang: string,
    countingUnits: CountingUnit[],
    inventoryItems: { [s: string]: CountableItem },
    caseLabel: string
  ): Observable<string> {
    return this.unitsService
      .getCurrentEntitlementUOMs$()
      .pipe(
        map(staticSapUnits => {
          let result = '';
          if (worksheetItemRef) {
            let unitString;
            if (countingUnits && countingUnits[0]) {
              const countingUnitObjectToDisplay = countingUnits[0];

              const countableItemDetails = inventoryItems[worksheetItemRef.itemId];
              if (countingUnitObjectToDisplay.standardUnit) {
                const itemWeightQty = this.getWeightQty(countableItemDetails, countingUnitObjectToDisplay);
                if (itemWeightQty) {
                  const count = Number(itemWeightQty.toFixed(2)); // Trimming trailing zeros
                  const usePlural = count > 1;
                  const textSource = usePlural ? staticSapUnits.plural : staticSapUnits.single;

                  const SAPText = textSource.find(e => caseInsensitiveEquals(e.type, countingUnitObjectToDisplay.standardUnit.standardUnitId));

                  if (SAPText?.name) {
                    const r = LocalizedValueUtilsService.resolveLocalizedValue(SAPText.name, lang);
                    unitString = count + ' ' + r;
                  }
                }
              } else if (countingUnitObjectToDisplay.customUnit) {
                unitString = countingUnitObjectToDisplay.customUnit.custom.qtyInParent + ' ' + countingUnitObjectToDisplay.customUnit.custom.name;
              }
            }

            if (unitString) {
              result += unitString + '/' + caseLabel;
            }
          }

          return result;
        })
      );

  }

  getPackSizeInformationForCustomItem(
    worksheetItemRef: WorksheetItem,
    lang: string,
    countingUnits: CountingUnit[],
    countableItem: CountableItem,
    standardUnits: MeasurementUnit[],
    caseLabel: string
  ): string {

    let result = '';
    //For Primary
    const primaryUOMInformation: CountingUnit[] = countingUnits?.filter(u =>
      (u.customUnit?.custom?.deleted !== true && worksheetItemRef.primaryUnit === u.unit?.unitId)
    )

    if (primaryUOMInformation
      && primaryUOMInformation[0]?.customUnit
      && worksheetItemRef.primaryUnitType?.toLowerCase() === "custom") {
      result += primaryUOMInformation[0]?.customUnit?.custom?.qtyInParent
        + ' ' + primaryUOMInformation[0]?.customUnit?.custom?.name
        + '/' + caseLabel + ', '
    }
    else if (primaryUOMInformation
      && primaryUOMInformation[0]?.literalUnit
      && worksheetItemRef.primaryUnit?.toLowerCase() === "case"
      && worksheetItemRef.primaryUnitType?.toLowerCase() === "literal") {
      result += "1 " + LocalizedValueUtilsService.resolveLocalizedValue(primaryUOMInformation[0]?.literalUnit?.name, lang, null)
        + '/' + LocalizedValueUtilsService.resolveLocalizedValue(primaryUOMInformation[0]?.literalUnit?.name, lang, null) + ', '
    }

    //For Secondary
    const secondaryUOMInformation: CountingUnit[] = countingUnits?.filter(u =>
      (u.customUnit?.custom?.deleted !== true && worksheetItemRef.secondaryUnit === u.unit?.unitId)
    )

    if (secondaryUOMInformation
      && secondaryUOMInformation[0]?.customUnit
      && worksheetItemRef.secondaryUnitType?.toLowerCase() === "custom") {
      result += secondaryUOMInformation[0]?.customUnit?.custom?.qtyInParent
        + ' ' + secondaryUOMInformation[0]?.customUnit?.custom?.name
        + '/' + caseLabel + ', '
    }
    else if (secondaryUOMInformation
      && secondaryUOMInformation[0]?.literalUnit
      && worksheetItemRef.secondaryUnit?.toLowerCase() === "case"
      && worksheetItemRef.secondaryUnitType?.toLowerCase() === "literal") {
      result += "1 " + LocalizedValueUtilsService.resolveLocalizedValue(secondaryUOMInformation[0]?.literalUnit?.name, lang, null)
        + '/' + LocalizedValueUtilsService.resolveLocalizedValue(secondaryUOMInformation[0]?.literalUnit?.name, lang, null) + ', '
    }


    // For All standard Units
    if (
      primaryUOMInformation
      && secondaryUOMInformation
      && (worksheetItemRef.primaryUnitType?.toLowerCase() === "standard"
        || worksheetItemRef.secondaryUnitType?.toLowerCase() === "standard")
      && (secondaryUOMInformation[0]?.standardUnit
        || primaryUOMInformation[0]?.standardUnit)
      && null != countableItem.customItem.purchaseUnit.custom?.netWeight) {
      const standardUnitName: MeasurementUnit[] = standardUnits.filter(u =>
        (u.id === countableItem.customItem.purchaseUnit.custom?.weightUnitId)
      )
      result += countableItem.customItem.purchaseUnit.custom?.netWeight
        + ' ' + LocalizedValueUtilsService.resolveLocalizedValue(standardUnitName[0]?.name, lang, null)
        + '/' + caseLabel + ', '
    }

    result = result.substring(0, result.lastIndexOf(","))

    return result;
  }

  private getWeightQty(
    itemDetails: CountableItem,
    countingUnitToDisplay: CountingUnit,
  ) {
    let itemWeightQty;
    let itemWeightUnitId;
    let fromConversionUnit;
    let toConversionUnit;
    if (itemDetails && itemDetails.gfsItem) {
      itemWeightQty = itemDetails.gfsItem.netWeightKg;
      itemWeightUnitId = 'metric_weight_kg';
    } else if (itemDetails && itemDetails.customItem) {
      itemWeightQty = itemDetails.customItem.purchaseUnit.custom.netWeight;
      itemWeightUnitId = itemDetails.customItem.purchaseUnit.custom.weightUnitId;
    }

    try {

      fromConversionUnit = this.unitConversionUtilService
        .getConversionUnit(InventoryConstants.UNIT_TYPE_STANDARD, itemWeightUnitId, itemDetails.customItemData);
      toConversionUnit = this.unitConversionUtilService
        .getConversionUnit(InventoryConstants.UNIT_TYPE_STANDARD, countingUnitToDisplay.standardUnit.id, itemDetails.customItemData);

      return this.unitConversionUtilService
        .convertMeasurementUnit(itemWeightQty, fromConversionUnit, toConversionUnit);

    } catch (error) {
      console.warn(error);
      console.log({
        itemDetails,
        countingUnitToDisplay,
        itemWeightQty,
        itemWeightUnitId,
        fromConversionUnit,
        toConversionUnit
      });
      return null;
    }

  }

  determineLessCaseUnitToDisplayByWorksheetItem(worksheetItemRef: WorksheetItem): {
    unitToDisplay: string,
    unitTypeToDisplay: string
  } {
    let unitToDisplay: string;
    let unitTypeToDisplay: string;
    const primaryUnit = worksheetItemRef.primaryUnit;
    if (primaryUnit !== 'CASE') {
      unitToDisplay = primaryUnit;
      unitTypeToDisplay = worksheetItemRef.primaryUnitType;
    } else {
      const secondaryUnit = worksheetItemRef.secondaryUnit;
      unitToDisplay = secondaryUnit;
      unitTypeToDisplay = worksheetItemRef.secondaryUnitType;
    }
    return { unitToDisplay, unitTypeToDisplay };
  }

  getLocalizedUnitCountLabelByCountableItem(
    unitType: string,
    countingUnits: CountingUnit[],
    lang: string,
    countableItem?: CountableItem
  ) {
    return of(countableItem)
      .pipe(
        concatMap(item =>
          iif(
            () => !!item,
            this.getLocalizedUnitCountLabel_GFS_ITEM(item, unitType, lang),
            of<ItemLabelDtoTemp>(null)
          )
            .pipe(
              map((itemLabel) => {

                return ({ item, itemLabel });
              })
            )
        ),
        concatMap(({ item, itemLabel }) =>
          iif(
            () => itemLabel === null,
            this.getLocalizedUnitCountLabel_DEFAULT(countingUnits, unitType, lang)
              .pipe(
                map(unitTextResult => ({
                  plural: '',
                  unitText: unitTextResult
                } as ItemLabelDtoTemp))
              ),
            of(itemLabel),
          )
            .pipe(
              map((label) => ({ item, itemLabel: label }))
            )
        ),
        concatMap(({ itemLabel }) => iif(
          () => !!itemLabel,
          of(itemLabel),
          of<ItemLabelDtoTemp>({ plural: '', unitText: '' })
        )),
        map(({ unitText, plural }) => {
          const result = unitText ? unitText + plural : '';
          return result;
        })
      );
  }

  private getLocalizedUnitCountLabel_DEFAULT(
    countingUnits: CountingUnit[],
    unitType: string,
    lang: string
  ): Observable<string> {
    let unitText = '';

    const unit = countingUnits
      .filter(e => !!e.literalUnit)
      .find(u => caseInsensitiveEquals(u.literalUnit.type, unitType));


    if (unit) {
      unitText = LocalizedValueUtilsService.resolveLocalizedValue(unit.literalUnit.name, lang, null);
    }
    return of(unitText);
  }

  getLocalizedCountingUnitDisplay_GFS_ITEM(item: CountableItem, unitDisplayCode: string, lang: string): Observable<string> {

    return this.unitsService
      .getCurrentEntitlementUOMs$()
      .pipe(
        map(staticSapUnits => {
          let unitText: string = '';

          if (!!item && !!item.gfsItem && !!item.gfsItem.units) {

            const unitTextSource = staticSapUnits.single;
            const resolvedUnit = unitTextSource.find(sapUOM => caseInsensitiveEquals(sapUOM.type, unitDisplayCode));
            if (null != resolvedUnit) {
              unitText = LocalizedValueUtilsService.resolveLocalizedValue(resolvedUnit.name, lang, null);
            } else {
              unitText = unitDisplayCode;
            }
            if ((unitText == null || unitText == "") && lang == 'fr_CA' && null != resolvedUnit) {
              unitText = LocalizedValueUtilsService.resolveLocalizedValue(resolvedUnit.name, 'en_CA', null);
            }
          }else {
              unitText = unitDisplayCode;
          }

          return unitText;
        })
      );

  }

  private getLocalizedUnitCountLabel_GFS_ITEM(item: CountableItem, unitTier: string, lang: string): Observable<ItemLabelDtoTemp> {

    return this.unitsService
      .getCurrentEntitlementUOMs$()
      .pipe(
        map(staticSapUnits => {
          let unitText = '';

          if (!!item && !!item.gfsItem && !!item.gfsItem.units) {
            let usePluralForm = false;

            const unitTierConfig = uomTierConfiguration[unitTier.toLowerCase()];
            const tierUnit = item.gfsItem.units[unitTierConfig.index];

            if (unitTierConfig.pluralizeQuantitiesGreaterThanOne) {
              const hasMasterSellUnit = !!item.gfsItem.qtyPerMasterSellUnit;
              usePluralForm = hasMasterSellUnit && item.gfsItem.qtyPerMasterSellUnit > 1;
            }
            const unitTextSource = usePluralForm ? staticSapUnits.plural : staticSapUnits.single;
            let resolvedUnit = unitTextSource.find(sapUOM => caseInsensitiveEquals(sapUOM.type, tierUnit.displayCode));

            if (!resolvedUnit) {
              let mappedUnit = null;
              if (caseInsensitiveEquals('case', unitTier)) { mappedUnit = 'CS'; }
              if (caseInsensitiveEquals('unit', unitTier)) { mappedUnit = 'UN'; }
              resolvedUnit = unitTextSource
                .find(sapUOM => caseInsensitiveEquals(sapUOM.type, mappedUnit));
            }

            if (!resolvedUnit) {
              resolvedUnit = unitTextSource
                .find(sapUOM => caseInsensitiveEquals(sapUOM.type, 'UN'));
            }

            unitText = LocalizedValueUtilsService.resolveLocalizedValue(resolvedUnit.name, lang, null);
          }

          return { plural: '', unitText };
        })
      );

  }


  getGFSDefaultUnitDetails(
    items: IDictionary<CountableItem>,
    sourceItem: CountableItem,
    lang: string,
    separator: string = ', '
  ): Observable<string> {
    return this.unitsService
      .getCurrentEntitlementUOMs$()
      .pipe(
        map(staticSapUnits => {
          let item = sourceItem;

          if (!!items) {
            const countableItemId = this.getItemIdCore(sourceItem);
            const displayItemId = this.getDisplayItemIdByItemId(countableItemId, items);
            item = items[displayItemId];
          }

          if (!item?.gfsItem?.units) { return ''; }

          const unitsMap = item.gfsItem.units
            .map((r, tier) => ({
              tier,
              name: r.name,
              parentKey: r.parentUnitId,
              key: r.standardUnitId,
              symbol: r.symbol,
              displayCode: r.displayCode,
              qtyInParent: r.qtyInParent,
            }));

          const localizableItemUnitDescription = unitsMap
            .filter(e => !!e.parentKey)
            .map(source => ({
              source,
              parent: unitsMap.find(uom => caseInsensitiveEquals(uom.key, source.parentKey)),
            }))
            .map(({ source, parent }) => {
              let customUnitTextSource = null;
              const usePluralForm = (+source.qtyInParent) > 1;
              const unitTextSource = usePluralForm ? staticSapUnits.plural : staticSapUnits.single;
              let qtyVal = source.qtyInParent;

              if (source.displayCode === 'CUSTOM') {
                // console.warn(`Detected Transient Unit for inner pack size`, source);
                customUnitTextSource = source;
                qtyVal = null;
              }

              return ({
                unitTextSource,
                parent,
                parentDisplayCode: parent.displayCode,
                parentUnitText: staticSapUnits.single.find(uom => caseInsensitiveEquals(uom.type, parent.displayCode)),
                unitDisplayCode: source.displayCode,
                unitText: customUnitTextSource ?? unitTextSource.find(uom => caseInsensitiveEquals(uom.type, source.displayCode)),
                qtyVal,
              });
            })
            .map(({ unitTextSource, parent, parentDisplayCode, parentUnitText, unitDisplayCode, unitText, qtyVal }) => {

              // attempt final resolution of parent ( container ) unit
              if (!parentUnitText) {
                console.warn(`unable to resolve SAP parent unit for '${parentDisplayCode}'`);
                console.warn(`attempting to use SAP parent unit mapping for '${parentDisplayCode}'`);

                parentUnitText = staticSapUnits.single.find(uom =>
                  caseInsensitiveEquals(uom.type, US_DISPLAYCODE_SAP_MAPPING[parentDisplayCode])
                );
                if (parentUnitText) {
                  console.log(`found mapping from '${parentDisplayCode}' => '${US_DISPLAYCODE_SAP_MAPPING[parentDisplayCode]}'`);
                }
                if (!parentUnitText) {
                  console.warn(`unable to resolve SAP parent unit mapping for '${parentDisplayCode}'`);
                  console.warn(`substituting 'CASE' in place of missing parent unit`, item);
                  console.error(`'${parentDisplayCode}' does not exist and has no mapping configured`);

                  parentUnitText = staticSapUnits.single.find(uom =>
                    caseInsensitiveEquals(uom.type, 'CS')
                  );
                }
              }

              // attempt final resolution of inner unit ( inner ) unit
              if (!unitText) {
                console.warn(`unable to resolve SAP child unit for '${unitDisplayCode}'`);
                console.warn(`attempting to use SAP child unit mapping for '${unitDisplayCode}'`);
                unitText = unitTextSource.find(uom =>
                  caseInsensitiveEquals(uom.type, US_DISPLAYCODE_SAP_MAPPING[unitDisplayCode])
                );
                if (unitText) {
                  console.log(`found mapping from '${unitDisplayCode}' => '${US_DISPLAYCODE_SAP_MAPPING[unitDisplayCode]}'`);
                }
                if (!unitText) {
                  console.warn(`unable to resolve SAP child unit mapping for '${unitDisplayCode}'`);
                  console.warn(`substituting 'UNIT' in place of missing child unit`, item);
                  console.error(`'${unitDisplayCode}' does not exist and has no mapping configured`);
                  unitText = unitTextSource
                    .find(sapUOM => caseInsensitiveEquals(sapUOM.type, 'UN'));
                }
              }

              return ({
                parent,
                parentUnitText,
                unitDisplayCode,
                unitText,
                qtyVal
              });
            });

          const result = localizableItemUnitDescription
            .map(source => {

              const [qty, unit, parentUnit] = [
                this.displayLeastCharacters(source.qtyVal),
                LocalizedValueUtilsService.resolveLocalizedValue(source.unitText.name, lang),
                LocalizedValueUtilsService.resolveLocalizedValue(source.parentUnitText.name, lang),
              ];
              return ({
                source,
                text: qty ? `${qty} ${unit}/${parentUnit}` : `${unit}/${parentUnit}`
              });

            })
            .map(({ text }) => text)
            .reverse()
            .join(separator);

          return result;
        }),
      );

  }

  displayLeastCharacters(val: any) {
    if (val === null) {
      return null;
    }
    val = +val;
    const truncatedValueString = (val).toFixed(2);
    // eslint-disable-next-line prefer-const
    let [wholeValue, decimalValue] = truncatedValueString.split('.');


    if (decimalValue === '00') {
      return wholeValue;
    }

    if (+decimalValue[1] > 0) {
      return truncatedValueString;
    }

    decimalValue = decimalValue[0];

    return `${wholeValue}.${decimalValue}`;

  }

  getTextWorksheetItemPrimaryUnit(
    items: IDictionary<CountableItem>,
    worksheetItemRef: WorksheetItem,
    countingUnits: CountingUnit[],
    lang: string,
  ): Observable<string> {

    const primaryUnitLabel = this.getLocalizedUnitCountLabelByCountableItem('case', countingUnits, lang, items[worksheetItemRef.itemId]);
    const unitRef = this.determineLessCaseUnitToDisplayByWorksheetItem(worksheetItemRef);

    const itemUnits =
      this.getCountingUnitsByWorksheetItem(
        items,
        worksheetItemRef,
        countingUnits
      ).filter(
        countingUnit => countingUnitHasKey(countingUnit, unitRef)
      );

    return primaryUnitLabel
      .pipe(
        concatMap(e => this.getPerCaseDetails(
          worksheetItemRef,
          lang,
          itemUnits,
          items,
          e
        )),
        map(textResult => {
          return (textResult ?? '').trim() === '' ? textResult : ('| ' + textResult);
        })
      );
  }

  getDisplayItemIdByItemId(itemId: string, items: IDictionary<CountableItem>) {
    return items[itemId]?.customItem
      ? items[itemId].customItem.id : itemId;
  }

  getItemIdCore(mItem: CountableItem): string {
    if (get(mItem, 'gfsItem.id')) {
      return mItem.gfsItem.id;
    } else if (get(mItem, 'recipeItem.id')) {
      return mItem.recipeItem.id;
    } else if (get(mItem, 'customItem.id')) {
      return mItem.customItem.supplierItemCode;
    } else if (get(mItem, 'generalItem.id')) {
      return mItem.generalItem.id;
    }
    return '';
  }

  getCountingUnitsByWorksheetItem(
    items: IDictionary<CountableItem>,
    worksheetItemRef: WorksheetItem,
    countingUnits: CountingUnit[],
    isSecondaryUnit: boolean = false
  ): CountingUnit[] {
    const itemId = this.getDisplayItemIdByItemId(worksheetItemRef.itemId, items);
    let itemUnits = countingUnits ? countingUnits.filter(u =>
      (u.literalUnit || u.standardUnit || (u.customUnit && (u.itemId === itemId)))
      && (u.customUnit?.custom?.deleted !== true || worksheetItemRef.primaryUnit === u.unit.unitId || worksheetItemRef.secondaryUnit === u.unit.unitId)
    ) : [];
    if (worksheetItemRef.itemType === 'GENERAL') {
      itemUnits = itemUnits.filter(u => u.unit.unitId !== ('UNIT'));
    }

    const standardUnits = itemUnits
      .filter(u => u.unit.unitType === InventoryConstants.COUNTING_UNIT_TYPES.STANDARD);
    const customUnits = itemUnits
      .filter(u => u.unit.unitType === InventoryConstants.COUNTING_UNIT_TYPES.CUSTOM);

    const literalUnits = itemUnits.filter(u => {

      let literals = ['CASE', 'UNIT'];
      if (worksheetItemRef.isEach) {
        literals = !isSecondaryUnit
          ? []
          : [InventoryConstants.SAP_UOM_EACH];
      }

      if (worksheetItemRef.ignoreStandardSecondaryUnit) {
        if (isSecondaryUnit) {
          if (worksheetItemRef.isEach) {
            literals = [InventoryConstants.SAP_UOM_EACH];
          }
          else {
            literals = ['CASE'];
          }
        } else {
          literals = [];
        }
      }

      const isUnitAllowed = literals.includes(u.unit.unitId);
      return isUnitAllowed;
    });
    const combinedUnits = standardUnits.concat(literalUnits).concat(customUnits);

    return combinedUnits;
  }

  getDisplayItemIdByCountableItem(countableItem: CountableItem) {
    let itemIdFullString = '';
    if (countableItem) {
      if (!!countableItem.customItem && !!countableItem.customItem.supplierItemCode) {
        itemIdFullString = countableItem.customItem.supplierItemCode;
      } else if (!!countableItem.gfsItem) {
        itemIdFullString = countableItem.gfsItem.id;
      }
      if (itemIdFullString) {
        itemIdFullString = '#' + itemIdFullString + ' | ';
      }
    }
    return itemIdFullString;
  }

  isSecondaryUnitBlank(
    isSecondaryUnitPresent: boolean,
    unitType: string,
    unit: string
  ): boolean {
    const conditions = [
      !isSecondaryUnitPresent,
      caseInsensitiveEquals(unitType, InventoryConstants.COUNTING_UNIT_TYPES.LITERAL),
      caseInsensitiveEquals(unit, InventoryConstants.LITERAL_UOM_UNIT)
    ];
    return conditions.every(isTrue => isTrue);
  }
}

export const uomTierConfiguration = {
  'case': {
    description: 'primary unit',
    index: 0,
    pluralizeQuantitiesGreaterThanOne: false
  },
  'unit': {
    description: 'secondary unit',
    index: 1,
    pluralizeQuantitiesGreaterThanOne: true
  }
};
export function countingUnitHasKey(countingUnit: CountingUnit, unitKey: { unitToDisplay: string; unitTypeToDisplay: string; }): unknown {
  return countingUnit.unit.unitId === unitKey.unitToDisplay
    && countingUnit.unit.unitType === unitKey.unitTypeToDisplay;
}
export type ItemLabelDtoTemp = { plural: string; unitText: string; };
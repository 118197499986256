import { Platform } from '@angular/cdk/platform';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormControl, UntypedFormGroup, ValidatorFn,
  Validators
} from '@angular/forms';
import { InventoryConstants } from '@gfs/constants';
import {
  CountableItem, CustomItemData, MeasurementUnit, PortioningUnit, PurchaseUnit, StorageArea, WorksheetItem, WorksheetItemPost
} from '@gfs/shared-models';
import {
  AddItemsModalAppSettings,
  CategoryValidator, CustomUnitUtilsService, IAddItemsModalAppSettingsProviderService,
  InjectionTokens,
  LocalizedValueUtilsService,
  StorageAreaUtilsService,
  StorageAreaValidator
} from '@gfs/shared-services';
import {
  ClearEditItemProductInfo,
  CreateCategoryAttempt,
  CreateCustomItemDataAttempt,
  GetItemCategoriesAttempt,
  GetMeasurementUnitsAttempt,
  IAddItemsFeatureBridge,
  IFeatureStateFacade,
  UpdateCustomItemDataAttempt
} from '@gfs/store/feature/add-items';
import {
  CreateStorageAreaName,
  CreateWorksheetItemAttempt,
  DeleteInventoryItem,
  DeleteInventoryItemAttempt,
  GetAllCustomItemDataAttempt
} from '@gfs/store/inventory/actions/worksheets.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get, isNil, sortBy, trim } from 'lodash-es';
import { Observable, combineLatest, of, concat, EMPTY, merge } from 'rxjs';
import { catchError, filter, first, map, mergeMap, startWith, tap, toArray } from 'rxjs/operators';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';

@Component({
  selector: 'gfs-edit-gfs-item',
  templateUrl: './edit-gfs-item.component.html',
  styleUrls: ['./edit-gfs-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGfsItemComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() focusSection: string;
  @Input() countableItem: CountableItem;
  @Input() isEdit = false;
  @Input() isAdd = false;
  @Input() worksheetItemToEdit: WorksheetItem; // required for editing storage areas
  @Input() currentStorageAreaId: string;
  @Output() back = new EventEmitter<number>();
  @Output() closeEmitter = new EventEmitter();
  addItemForm: UntypedFormGroup;
  measurementUnitForm: UntypedFormGroup;
  itemInfoExpanded = true;
  countingUnitExpanded = true;
  unitOfMeasureExpanded = true;
  purchaseUnitList = [this.translate.instant('ADD_ITEMS.CASE'), this.translate.instant('ADD_ITEMS.UNIT')];
  currentLang$ = this.addItemsCtx.currentLang$;
  worksheet$ = this.addItemsCtx.selectedWorksheet$;
  isSavingWorksheet$ = this.addItemsCtx.isSavingWorksheet$;
  isSavingAddItems$ = this.store.select(state => state.addItemsFeature.isSaving);
  itemCategories$ = this.store.select(state => state.addItemsFeature.itemCategories);
  editItemProductInfo$ = this.store.select(state => state.addItemsFeature.editItemProductInfo);
  customItemData$ = this.addItemsCtx.customItemData$.pipe(
    map(customItemData => {
      const data = customItemData?.find(d => d.itemId === this.countableItem?.gfsItem?.id && d.itemType === 'GFS');
      return data ? [data] : [];
    })
  );
  weightUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits).pipe(
    map(units => (units ?? []).filter(unit => unit.measurementType === 'weight' && unit.measurementSystem !== 'imperial'))
  );
  volumeUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits).pipe(
    map(units => (units ?? []).filter(unit => unit.measurementType === 'volume' && unit.measurementSystem !== 'imperial'))
  );
  filteredStorageAreas$: Observable<StorageArea[]>;
  filteredCategories$: Observable<string[]>;
  lastStorageValue = '';
  lastValidCatchWeightUnit: MeasurementUnit = null;
  storageAreaSelected = '';
  initialStorageAreaValue: string;
  initialCategoryValue: string = null;
  initialRecipeMeasurements: any = null;
  appSettings: AddItemsModalAppSettings;
  isIos: any;
  assignedStorageAreas$: Observable<StorageArea[]>;
  unAssignedStorageArea$: Observable<StorageArea>;

  @ViewChild('countingUnitFormComponent') countingUnitFormComp;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IFeatureStateFacade>,
    private translate: TranslateService,
    private storageAreaUtils: StorageAreaUtilsService,
    private localizedValueUtils: LocalizedValueUtilsService,
    private customUnitUtil: CustomUnitUtilsService,
    private changeDetector: ChangeDetectorRef,
    public platform: Platform,
    public overlay: LoadingSpinnerOverlayService,
    @Inject(InjectionTokens.IAddItemsModalAppSettingsProviderService) private configService: IAddItemsModalAppSettingsProviderService,
    @Inject(InjectionTokens.IAddItemsFeatureBridge) private addItemsCtx: IAddItemsFeatureBridge
  ) {
    this.appSettings = this.configService.getSettings();
  }

  ngOnInit() {
    this.isIos = this.platform.IOS && this.platform.SAFARI;

    this.store.dispatch(new GetItemCategoriesAttempt());
    this.store.dispatch(new GetMeasurementUnitsAttempt());

    if (this.isEdit) {
      this.countableItem.gfsItem.price = this.worksheetItemToEdit ? this.worksheetItemToEdit.itemPrice : this.countableItem.gfsItem.price;
      this.editItemProductInfo$.pipe(
        filter(product => !!product),
        first()
      ).subscribe(product => {
        this.countableItem.gfsItem.category = product.category;
      });

      if (!!this.focusSection) {
        setTimeout(() => {
          this.scroll(this.focusSection);
        }, 1000);
      }
    }

    const storageAreaValidator = StorageAreaValidator.validate(
      this.isSavingWorksheet$,
      this.worksheet$,
      this.storageAreaTranslate(),
      true
    );
    const categoryValidator = CategoryValidator.validate(this.itemCategories$, this.isSavingAddItems$);

    this.addItemForm = this.formBuilder.group({
      storageArea: new UntypedFormControl('Unassigned', [], storageAreaValidator),
      category: new UntypedFormControl('', [Validators.required], categoryValidator)
    });
    this.measurementUnitForm = this.formBuilder.group({
      purchaseUnit: new UntypedFormControl(this.purchaseUnitList[0], Validators.required),
      catchWeightUnit: new UntypedFormControl('', null, this.catchWeightUnitValidator()),
      netWeight: new UntypedFormControl('', this.netWeightValidator()),
      volumeUnit: new UntypedFormControl(''),
      netVolume: new UntypedFormControl(''),
      countingUnits: this.formBuilder.array([])
    });

    this.filteredStorageAreas$ = this.addItemForm.controls.storageArea.valueChanges.pipe(
      startWith(''),
      mergeMap(value => this.filterStorageAreas(value))
    );
    this.filteredCategories$ = this.getFilteredCategories();

    if (this.currentStorageAreaId !== undefined) {
      this.setStorageAreaText(this.currentStorageAreaId);
      this.initialStorageAreaValue = this.addItemForm.controls.storageArea.value;
      this.storageAreaSelected = this.initialStorageAreaValue;
    }

    this.setCategoryText();
    this.setRecipeMeasurements();
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  getFilteredCategories(): Observable<string[]> {
    if (this.addItemForm && this.addItemForm.controls && this.addItemForm.controls.category) {
      return this.addItemForm.controls.category.valueChanges.pipe(
        startWith(''),
        mergeMap(value => this.filterCategories(value)),
        map(categories => sortBy(categories, 'name'))
      );
    }
  }

  filterStorageAreas(value: string): Observable<StorageArea[]> {
    value = trim(value);
    return this.worksheet$.pipe(
      filter(ws => !!ws && !!ws.storageAreas),
      map(ws => ws.storageAreas.filter(area => {
        const translatedName = this.storageAreaTranslate()(area.name);
        area.name = translatedName;
        return !value || translatedName.toLowerCase().includes(value.toLowerCase());
      }))
    );

  }

  filterCategories(value: string): Observable<any[]> {
    return this.itemCategories$.pipe(
      map(cats => value ? (cats ?? []).filter(cat => cat.name.toLowerCase().includes(value.toLowerCase())) : cats)
    );
  }

  handleEditStorageArea() {
    if (!this.worksheetItemToEdit) {
      return;
    }
    if (this.initialStorageAreaValue !== this.addItemForm.controls.storageArea.value) {
      this.worksheet$.pipe(filter(ws => !!ws && !!ws.storageAreas), first())
        .subscribe(ws => {
          this.store.dispatch(
            new DeleteInventoryItemAttempt({
              worksheetId: ws.id,
              storageAreaId: this.currentStorageAreaId,
              inventoryItem: this.worksheetItemToEdit
            })
          );
          this.store.dispatch(
            new DeleteInventoryItem({
              worksheetId: ws.id,
              storageAreaId: this.currentStorageAreaId,
              inventoryItem: this.worksheetItemToEdit
            })
          );

          const worksheetItemPost: WorksheetItemPost = {
            itemId: this.countableItem.gfsItem.id,
            itemType: this.worksheetItemToEdit.itemType,
            primaryUnit: this.worksheetItemToEdit.primaryUnit,
            primaryUnitQty: this.worksheetItemToEdit.primaryUnitQty ? this.worksheetItemToEdit.primaryUnitQty.toString() : '',
            primaryUnitType: get(this.worksheetItemToEdit, 'primaryUnitType', InventoryConstants.COUNTING_UNIT_TYPES.LITERAL),
            secondaryUnit: this.worksheetItemToEdit.secondaryUnit,
            secondaryUnitQty: this.worksheetItemToEdit.secondaryUnitQty ? this.worksheetItemToEdit.secondaryUnitQty.toString() : '',
            secondaryUnitType: get(this.worksheetItemToEdit, 'secondaryUnitType', InventoryConstants.COUNTING_UNIT_TYPES.LITERAL)
          };

          const storageArea = ws.storageAreas.find(area =>
            this.translate.instant(this.storageAreaUtils.getTranslation(area.name)) === this.addItemForm.controls.storageArea.value
          );

          this.localizedValueUtils.getLocalizedProductDescription(this.countableItem, this.currentLang$).pipe(first()).subscribe(desc => {
            this.store.dispatch(
              new CreateWorksheetItemAttempt({
                worksheetId: ws.id,
                storageAreaId: storageArea.id,
                worksheetItemPost,
                areaExpandStatus: storageArea.expandStatus,
                itemDescription: desc,
                successMessage: 'MESSAGES.ITEM_ADDED_SUCCESSFULLY',
                duplicate: false
              })
            );
          });
        });
    }
  }

  handleCreateOrEditItemData(newItemData: { customUnits: PortioningUnit[], purchasedByUnit: boolean }) {
    this.overlay.show();
    WorksheetEffects.itemUpdateInProgress$.next();
    combineLatest([this.customItemData$, this.itemCategories$])
      .pipe(filter(([itemData, cats]) => !!itemData && !!cats), first())
      .subscribe(([itemData, cats]) => {

        let categoryId = null;
        if (this.hasEditedCategory()) {
          const catMatch = cats.find(cat => cat.name === this.addItemForm.controls.category.value);
          categoryId = catMatch ? catMatch.id : null;
        }
        const customUnits = newItemData?.customUnits;
        let purchaseUnit: PurchaseUnit = null;

        if (this.hasEditedWeightMeasurement() || this.hasEditedVolumeMeasurement()) {
          const { weight, volume } = this.initialRecipeMeasurements;
          purchaseUnit = {
            gfs: {
              weightUnitId: weight?.isEditable ? this.measurementUnitForm.controls.catchWeightUnit.value : null,
              netWeight: weight?.isEditable ? this.measurementUnitForm.controls.netWeight.value : null,
              volumeUnitId: volume?.isEditable ? this.measurementUnitForm.controls.volumeUnit.value : null,
              netVolume: volume?.isEditable ? this.measurementUnitForm.controls.netVolume.value : null
            }
          };
        }

        const hasEditedRecipeUnits = this.hasEditedRecipeUnits(itemData[0]);
        const recipeUnits = hasEditedRecipeUnits ? this.createRecipeUnitList(itemData[0], this.measurementUnitForm) : null;

        if (customUnits || categoryId || purchaseUnit || hasEditedRecipeUnits) {
          if (itemData.length > 0) {
            this.store.dispatch(new UpdateCustomItemDataAttempt({
              customItemDataId: itemData[0].id,
              countingUnits: newItemData?.customUnits,
              categoryId,
              purchasedByUnit: newItemData.purchasedByUnit,
              purchaseUnit,
              recipeUnits
            }));
          } else {
            this.store.dispatch(new CreateCustomItemDataAttempt({
              itemId: this.countableItem.gfsItem.id,
              itemType: 'GFS',
              countingUnits: newItemData?.customUnits || [],
              categoryId,
              purchasedByUnit: newItemData.purchasedByUnit,
              purchaseUnit,
              recipeUnits: recipeUnits ?? []
            }));
          }
        }
      });


  }

  handleCreateWorksheetItem() {
    this.worksheet$.pipe(filter(ws => !!ws && !!ws.storageAreas), first())
      .subscribe(ws => {
        const storageArea = ws.storageAreas.find(area =>
          this.storageAreaTranslate()(area.name) === this.addItemForm.controls.storageArea.value
        );
        const worksheetItemPost: WorksheetItemPost = {
          itemId: this.countableItem.gfsItem.id,
          itemType: 'GFS',
          primaryUnit: 'CASE',
          primaryUnitQty: null,
          primaryUnitType: InventoryConstants.COUNTING_UNIT_TYPES.LITERAL,
          secondaryUnit: null,
          secondaryUnitQty: null,
          secondaryUnitType: null,
        };

        this.localizedValueUtils.getLocalizedProductDescription(this.countableItem, this.currentLang$).pipe(first()).subscribe(desc => {
          this.store.dispatch(
            new CreateWorksheetItemAttempt({
              worksheetId: ws.id,
              storageAreaId: storageArea ? storageArea.id : 'unassigned',
              worksheetItemPost,
              areaExpandStatus: storageArea ? storageArea.expandStatus : true,
              itemDescription: desc,
              successMessage: 'MESSAGES.ITEM_ADDED_SUCCESSFULLY',
              duplicate: false
            })
          );
        });
      });
  }

  onEditItem(newItemData: { customUnits: PortioningUnit[], purchasedByUnit: boolean }) {
    this.handleEditStorageArea();
    this.handleCreateOrEditItemData(newItemData);
    this.onClose();
  }

  onAddItem(newItemData: { customUnits: PortioningUnit[], purchasedByUnit: boolean }) {
    this.handleCreateWorksheetItem();
    this.handleCreateOrEditItemData(newItemData);
    this.back.emit(1);
  }

  goBack() {
    this.back.emit(0);
  }

  cancel() {
    if ((this.addItemForm.touched || this.measurementUnitForm.touched || this.countingUnitFormComp.countingUnitForm.touched) && this.isDirty()) {
      const message = confirm('Are you sure you want to leave? Leaving will delete any unsaved information');
      if (message) {
        this.onClose();
      }
    } else {
      this.onClose();
    }
  }

  onClose() {
    this.closeEmitter.emit(null);
  }

  ngOnDestroy() {
    if (this.isEdit) {
      this.store.dispatch(new ClearEditItemProductInfo());
    }
    // WorksheetEffects.storageAreaSchemaChange$.next()
  }

  storageAreaTranslate() {
    return (currentValue: string): string => this.translate.instant(this.storageAreaUtils.getTranslation(currentValue));
  }

  createStorageArea(value: string) {
    this.worksheet$.pipe(first()).subscribe(ws => {
      this.store.dispatch(new CreateStorageAreaName({ worksheetId: ws.id, storageAreaName: trim(value) }));
    });
  }

  createCategory(value) {
    this.currentLang$.pipe(first()).subscribe(languageCode =>
      this.store.dispatch(new CreateCategoryAttempt({
        categoryName: {
          languageCode,
          value
        }
      }))
    );
  }

  isExistentStorageArea() {
    return this.worksheet$.pipe(
      filter(ws => !!ws && !!ws.storageAreas),
      map(ws =>
        !!ws.storageAreas.find(area =>
          this.translate.instant(this.storageAreaUtils.getTranslation(area.name)).toLowerCase() === this.lastStorageValue.toLowerCase()
        )
      )
    );
  }

  setStorageAreaText(storageAreaId: string) {
    this.worksheet$.pipe(first()).subscribe(ws => {
      const currentStorageArea = ws.storageAreas.find(area => area.id === storageAreaId);
      this.addItemForm.controls.storageArea.setValue(
        this.storageAreaTranslate()(currentStorageArea.name.trim())
      );
    });
  }

  setCategoryText() {
    combineLatest([this.currentLang$, this.customItemData$, this.itemCategories$, this.editItemProductInfo$])
      .pipe(
        filter(([lang, itemData, itemCats, editItemProductInfo]) =>
          !!lang && !!itemData && !!itemCats && (!this.isEdit || !!editItemProductInfo)
        ),
        first()
      ).subscribe(([lang, itemData, itemCats, _]) => {
        if (itemData.length > 0 && itemData[0].categoryId) {
          const catMatch = itemCats.find(cat => cat.id === itemData[0].categoryId);
          this.initialCategoryValue = catMatch ? catMatch.name : null;
        }
        if (!this.initialCategoryValue) {
          this.initialCategoryValue =
            this.localizedValueUtils.getLocalizedCategory(get(this.countableItem, 'gfsItem.category', []), lang);
        }
        this.initialCategoryValue = this.safeSetInitialCategoryValue(itemCats, this.initialCategoryValue);
        this.addItemForm.controls.category.setValue(this.initialCategoryValue);
      });
  }

  safeSetInitialCategoryValue(itemCats: any[], category: string) {
    const targetCategory = itemCats.find(x => x.name === category);
    const fuzzyMatch = itemCats
      .filter(x => !!x.fuzzyName)
      .find(x => x.fuzzyName.find(y => y === category));

    // try to use the selected category, otherwise failover to a fuzzy match, if there still isnt a match there must be something wrong
    return targetCategory?.name ?? fuzzyMatch?.name ?? null;
  }

  setRecipeMeasurements() {
    combineLatest(([this.editItemProductInfo$, this.customItemData$, this.weightUnits$]))
      .pipe(filter(([product, itemData, units]) => !!itemData && (!this.isEdit || !!product) && !!units && units.length > 0))
      .subscribe(([product, itemData, _]) => {
        const initialMeasurements = {
          weight: {
            weightUom: '',
            netWeight: '',
            isEditable: true
          },
          volume: {
            volumeUom: '',
            netVolume: '',
            isEditable: true
          }
        };

        const purchaseUnit = itemData[0]?.purchaseUnit?.gfs;
        const item = this.isEdit ? product : this.countableItem.gfsItem;
        if (purchaseUnit?.netWeight) {
          this.measurementUnitForm.controls.netWeight.setValue(purchaseUnit.netWeight);
          this.measurementUnitForm.controls.catchWeightUnit.setValue(purchaseUnit.weightUnitId);

          initialMeasurements.weight.netWeight = purchaseUnit.netWeight;
          initialMeasurements.weight.weightUom = purchaseUnit.weightUnitId;
        } else if (item.netWeightKg) {
          this.measurementUnitForm.controls.netWeight.setValue(item.netWeightKg);
          this.measurementUnitForm.controls.catchWeightUnit.setValue('metric_weight_kg');
          this.measurementUnitForm.controls.netWeight.disable();
          this.measurementUnitForm.controls.catchWeightUnit.disable();

          initialMeasurements.weight.netWeight = item.netWeightKg.toString();
          initialMeasurements.weight.weightUom = 'metric_weight_kg';
          initialMeasurements.weight.isEditable = false;
        }
        if (purchaseUnit?.netVolume) {
          this.measurementUnitForm.controls.netVolume.setValue(purchaseUnit.netVolume);
          this.measurementUnitForm.controls.volumeUnit.setValue(purchaseUnit.volumeUnitId);

          initialMeasurements.volume.netVolume = purchaseUnit.netVolume;
          initialMeasurements.volume.volumeUom = purchaseUnit.volumeUnitId;
        } else if (item.netVolumeMl) {
          this.measurementUnitForm.controls.netVolume.setValue(item.netVolumeMl);
          this.measurementUnitForm.controls.volumeUnit.setValue('metric_volume_ml');
          this.measurementUnitForm.controls.netVolume.disable();
          this.measurementUnitForm.controls.volumeUnit.disable();

          initialMeasurements.volume.netVolume = item.netVolumeMl.toString();
          initialMeasurements.volume.volumeUom = 'metric_volume_ml';
          initialMeasurements.volume.isEditable = false;
        }

        this.initialRecipeMeasurements = initialMeasurements;
        if (itemData[0]?.purchasedByUnit) {
          this.updatePurchaseUnit(true);
        }
      });
  }

  updatePurchaseUnit(checked) {
    const purchaseUnit = checked ? this.purchaseUnitList[1] : this.purchaseUnitList[0];
    this.measurementUnitForm.controls.purchaseUnit.setValue(purchaseUnit);

    const qty = checked ? this.countableItem.gfsItem.qtyPerMasterSellUnit : 1;
    if (this.initialRecipeMeasurements?.weight?.isEditable === false) {
      const net = +this.initialRecipeMeasurements.weight.netWeight / qty;
      this.measurementUnitForm.controls.netWeight.setValue(net);
    }
    if (this.initialRecipeMeasurements?.volume?.isEditable === false) {
      const net = +this.initialRecipeMeasurements.volume.netVolume / qty;
      this.measurementUnitForm.controls.netVolume.setValue(net);
    }
  }

  hasEditedWeightMeasurement(): boolean {
    const weight = this.initialRecipeMeasurements?.weight;
    return weight?.isEditable
      && (this.measurementUnitForm.controls.netWeight.value !== weight?.netWeight
        || this.measurementUnitForm.controls.catchWeightUnit.value !== weight?.weightUom);
  }

  hasEditedVolumeMeasurement(): boolean {
    const volume = this.initialRecipeMeasurements?.volume;
    return volume?.isEditable
      && (this.measurementUnitForm.controls.netVolume.value !== volume?.netVolume
        || this.measurementUnitForm.controls.volumeUnit.value !== volume?.volumeUom);
  }

  isDirty() {
    if (this.initialStorageAreaValue !== this.addItemForm.controls.storageArea.value) {
      return true;
    }
    return false;
  }

  onStorageAreaSelected(event) {
    if (event === 'add_storage_area_button') {
      if (!this.lastStorageValue) {
        this.storageAreaSelected = '';
        this.addItemForm.controls.storageArea.setValue('');
        this.addItemForm.controls.storageArea.markAsUntouched();
      } else {
        const trimmedName = this.lastStorageValue.trim();
        this.worksheet$.pipe(first()).subscribe(ws => {
          const existentStorageArea = ws.storageAreas.find(area => {
            const translatedName = this.translate.instant(this.storageAreaUtils.getTranslation(area.name));
            return translatedName.toLowerCase() === trimmedName.toLowerCase();
          });
          if (isNil(existentStorageArea)) {
            this.store.dispatch(new CreateStorageAreaName({ worksheetId: ws.id, storageAreaName: trimmedName }));
            this.addItemForm.controls.storageArea.setValue(trimmedName);
            this.storageAreaSelected = trimmedName;
          } else {
            this.addItemForm.controls.storageArea.setValue(existentStorageArea.name);
            this.storageAreaSelected = existentStorageArea.name;
          }
        });
      }
    } else {
      this.storageAreaSelected = event;
      this.addItemForm.controls.storageArea.setValue(this.storageAreaSelected);
    }
  }

  hasEditedStorageArea(): boolean {
    return this.storageAreaSelected !== this.initialStorageAreaValue;
  }

  hasEditedCategory(): boolean {
    return this.addItemForm.controls.category.value !== this.initialCategoryValue;
  }

  hasEditedRecipeUnits(itemData: CustomItemData): boolean {
    return this.customUnitUtil.hasEditedRecipeUnits(itemData, this.measurementUnitForm);
  }

  createRecipeUnitList(itemData: CustomItemData, form: UntypedFormGroup): PortioningUnit[] {
    return this.customUnitUtil.createRecipeUnitList(itemData, form);
  }

  catchWeightUnitValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control) {
        return this.weightUnits$.pipe(
          first(),
          map(units => {
            const val = control.value;
            if (!val || val.trim().length === 0) {
              this.lastValidCatchWeightUnit = null;
              const qty = this.measurementUnitForm?.controls?.netWeight?.value;
              if (!qty || qty.toString().trim().length === 0) {
                return null;
              } else {
                return { invalidCatchWeightUnit: true };
              }
            } else {
              const selectedUnit = units.find(unit =>
                unit.id.toLowerCase().trim() === control.value.toLowerCase().trim()
              );
              if (selectedUnit) {
                this.lastValidCatchWeightUnit = selectedUnit;
                return null;
              } else {
                return { invalidCatchWeightUnit: true };
              }
            }
          }));
      } else {
        return of({ invalidCatchWeightUnit: true });
      }
    };
  }

  netWeightValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control) {
        const val = control.value;
        const unit = this.measurementUnitForm?.controls?.catchWeightUnit?.value;
        if ((!val || val.toString().trim().length === 0) && unit?.trim().length > 0) {
          return { invalidNetWeight: true };
        }
        return null;
      }
    };
  }

  onCatchWeightUnitBlur(event) {
    this.measurementUnitForm.controls.netWeight.updateValueAndValidity();
    if (get(event, 'relatedTarget.nodeName', null) !== 'MAT-OPTION') {
      this.measurementUnitForm.controls.catchWeightUnit.setValue(this.lastValidCatchWeightUnit?.id ?? '');
    }
  }

  onNetWeightBlur() {
    this.measurementUnitForm.controls.catchWeightUnit.updateValueAndValidity();
  }

  isAddDisabled(): Observable<boolean> {
    return this.isSavingAddItems$.pipe(
      map(isSaving =>
        isSaving || this.addItemForm.invalid || this.countingUnitFormComp?.countingUnitForm?.invalid || this.measurementUnitForm?.invalid
      )
    );
  }

  isEditDisabled(): Observable<boolean> {
    const hasEditedCountingUnit: Observable<boolean> = this.countingUnitFormComp?.hasEditedCountingUnit() || of(false);
    return combineLatest(([hasEditedCountingUnit, this.customItemData$]))
      .pipe(
        map(([hasEditedCountUnit, itemData]) =>
          this.addItemForm.invalid || this.countingUnitFormComp?.countingUnitForm.invalid || this.measurementUnitForm.invalid
          || (!this.hasEditedStorageArea() && !this.hasEditedCategory() && !hasEditedCountUnit
            && !this.hasEditedWeightMeasurement() && !this.hasEditedVolumeMeasurement() && !this.hasEditedRecipeUnits(itemData?.[0]))
        )
      );
  }

  scroll(targetElement: string) {
    document.getElementsByClassName(targetElement)[0].scrollIntoView();
  }

}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { SharedDirectivesModule } from '@gfs/directives';
import { MaterialModule, PipeSharingModule, SharedComponentsModule } from '@gfs/shared-components';
import { InventoryIconService } from '@gfs/shared-services';
import { LocalizedUIV2Module, SharedComponentsV2Module } from '@gfs/v2/shared-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SaveInProgressGuard } from './common/guards/save-in-progress.guard';
import { PreparingAppComponent } from './common/preparing-app/preparing-app.component';
import { WorksheetDataServiceBLL } from './common/services/worksheet-data.bll';
import { ConfigureProductComponent } from './product/configure/configure.component';
import { DeleteComponent } from './worksheet/delete/delete.component';
import { InventoryCountEntryComponent } from './worksheet/edit/inventory-count-entry/inventory-count-entry.component';
import { InventoryCountListComponent } from './worksheet/edit/inventory-count-list/inventory-count-list.component';
import { InventoryWorksheetV2Component } from './worksheet/edit/inventory-worksheet/inventory-worksheet.component';
import { StorageAreaItemPanelV2Component } from './worksheet/edit/storage-area-item-panel/storage-area-item-panel.component';
import { StorageAreaListV2Component } from './worksheet/edit/storage-area-list/storage-area-list.component';
import { StorageAreaSelectV2Component } from './worksheet/edit/storage-area-select/storage-area-select.component';
import { WorksheetListComponent } from './worksheet/list/list.component';
const { icons } = InventoryConstants;

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    PipeSharingModule,
    NgxPermissionsModule,
    SharedComponentsModule,
    MaterialModule,
    LocalizedUIV2Module,
    SharedComponentsV2Module,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
  ],
  declarations: [
    WorksheetListComponent,
    PreparingAppComponent,
    DeleteComponent,
    InventoryCountEntryComponent,
    InventoryCountListComponent,
    StorageAreaListV2Component,
    StorageAreaItemPanelV2Component,
    InventoryWorksheetV2Component,
    ConfigureProductComponent,
    StorageAreaSelectV2Component
  ],
  exports: [
    WorksheetListComponent,
    StorageAreaItemPanelV2Component,
    InventoryWorksheetV2Component
  ],
  providers: [
    WorksheetDataServiceBLL,
    SaveInProgressGuard,
    ConfigureProductComponent
  ]
})

export class InventoryV2Module {
  readonly storageAreaIcons = Object.keys(InventoryConstants.icons).map(icon => icons[icon]);

  constructor(public inventoryIconService: InventoryIconService) {
    inventoryIconService.registerIcons(this.storageAreaIcons);
  }
}
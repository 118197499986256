import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import {
  IAppContext,
  Product,
  Supplier,
  SupplierOrder,
  WorksheetItemGroupPost,
  WorksheetItemPost,
} from '@gfs/shared-models';
import {
  CountableItemService,
  CustomItemDataService,
  CustomItemService,
  GeneralItemService,
  InjectionTokens,
  ItemCategoryService,
  MeasurementUnitService,
  MessageService,
  ProductService,
  SupplierService,
} from '@gfs/shared-services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, interval, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounce,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ActionTypes,
  ActionUnion,
  CreateCategoryError,
  CreateCategorySuccess,
  CreateCustomItemDataAttempt,
  CreateCustomItemDataError,
  CreateCustomItemDataSuccess,
  CreateCustomItemError,
  CreateCustomItemSuccess,
  CreateGeneralItemFromCustomItemAttempt,
  CreateGeneralItemFromCustomItemError,
  CreateGeneralItemFromCustomItemSuccess,
  CreateSupplierError,
  CreateSupplierOrderAttempt,
  CreateSupplierOrderError,
  CreateSupplierOrderSuccess,
  CreateSupplierSuccess,
  DeleteSupplierError,
  DeleteSupplierSuccess,
  GetAutocompleteResultsError,
  GetAutocompleteResultsSuccess,
  GetCountableItemsError,
  GetCountableItemsSuccess,
  GetCustomItemDataAttempt,
  GetCustomItemDataBeginLoading,
  GetCustomItemDataError,
  GetCustomItemDataSuccess,
  GetGeneralItemByCustomItemIdError,
  GetGeneralItemByCustomItemIdSuccess,
  GetItemCategoriesBeginLoading,
  GetItemCategoriesError,
  GetItemCategoriesSuccess,
  GetMeasurementUnitsBeginLoading,
  GetMeasurementUnitsError,
  GetMeasurementUnitsSuccess,
  GetProductInfoError,
  GetProductInfoSuccess,
  GetSupplierOrderError,
  GetSupplierOrderSuccess,
  GetSuppliersAttempt,
  GetSuppliersBeginLoading,
  GetSuppliersError,
  GetSuppliersSuccess,
  PatchSupplierError,
  PatchSupplierSuccess,
  SaveCustomItemFinish,
  UpdateCustomItemDataSuccess,
} from './add-items.actions';
import { IFeatureStateFacade } from './add-items.state';

import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import {
  GetAllCustomItemsAttempt,
  GetAllGeneralItemsAttempt,
} from '../../inventory/store/actions/customerItems.actions';
import {
  CreateGroupWorksheetItemsAttempt,
  CreateWorksheetItemAttempt,
  GetAllCustomItemDataAttempt,
  GetWorksheetSummaryAttempt,
} from '../../inventory/store/actions/worksheets.actions';

@Injectable()
export class AddItemsStoreEffects {

  getAutocompleteResults$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetAutocompleteResultsAttempt),
    debounce((i) => interval(300)),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) =>
      this.productService
        .getProductAutocomplete(
          pk,
          action.payload.locale,
          action.payload.searchPrefix
        )
        .pipe(
          map(
            (results) =>
              new GetAutocompleteResultsSuccess({
                autocompleteSuggestions: results,
                searchLength: action.payload.searchPrefix.length,
              })
          )
        )
    ),
    catchError((err) => of(new GetAutocompleteResultsError(err)))
  ));


  getProductInfo$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetProductInfoAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) =>
      this.productService
        .getProductInfo(action.payload, pk)
        .pipe(map((result) => new GetProductInfoSuccess(result)))
    ),
    catchError((err) => of(new GetProductInfoError(err)))
  ));


  getCountableItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCountableItemsAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!state)),
      this.appContext.customerPK
    ),
    concatMap(([action, state, pk]) => {
      const lastSearch = state.addItemsFeature.lastCountableItemsSearch;

      const returnLastSearch = [
        action.payload.searchText.trim() === lastSearch.searchText.trim(),
        action.payload.searchType === lastSearch.searchType,
        action.payload.offset < lastSearch.countableItems.length,
        action.payload.locale === lastSearch.locale
      ].every(isTrue => isTrue);

      const countableItems = returnLastSearch
        ? of(lastSearch.countableItems)
        : this.countableItemService.getCountableItems(
          pk,
          action.payload.locale,
          action.payload.searchText,
          action.payload.offset,
          action.payload.limit,
          action.payload.inventoryDate,
          action.payload.searchType
        );

      return countableItems.pipe(
        first(),
        map(items => {
          items.forEach((item) => {
            if (item.customItem) {
              item.customItem.storageAreaId = 'unassigned'
            }
          })
          return new GetCountableItemsSuccess({
            searchText: action.payload.searchText,
            searchType: action.payload.searchType,
            locale: action.payload.locale,
            items,
            isNewSearchTerm: action.payload.isNewSearchTerm,
          })
        }
        ),
        catchError((err) => of(new GetCountableItemsError(err)))
      );
    })
  ));


  getGenealItemFromCustomItemId$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetGeneralItemByCustomItemIdAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) =>
      this.generalItemService
        .getGeneralItemByCustomItemId(action.payload.customItemId, pk)
        .pipe(
          map(
            (response) =>
              new GetGeneralItemByCustomItemIdSuccess({
                customItemId: action.payload.customItemId,
                generalItem: response,
              })
          )
        )
    ),
    catchError((err) => of(new GetGeneralItemByCustomItemIdError(err)))
  ));


  createCustomItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateCustomItemAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) =>
      this.customItemService
        .createCustomItem(
          pk,
          action.payload.supplierItemCode,
          action.payload.supplierId,
          action.payload.description,
          action.payload.purchaseUnit,
          action.payload.addToWorksheet,
          action.payload.storageAreaId
        )
        .pipe(
          map(
            (item) => {
              WorksheetEffects.storageAreaSchemaChange$.next()
              return new CreateCustomItemSuccess({
                customItem: item,
                categoryId: action.payload.categoryId,
                worksheetId: action.payload.worksheetId,
                storageAreaId: action.payload.storageAreaId,
                areaExpandStatus: action.payload.areaExpandStatus,
                successMessage: action.payload.successMessage,
                duplicate: action.payload.duplicate,
                customUnits: action.payload.customUnits,
                recipeUnits: action.payload.recipeUnits,
                addToWorksheet: action.payload.addToWorksheet,
                isManage: action.payload.isManage,
              });
            }
          ),
          catchError((err) => of(new CreateCustomItemError(err)))
        )
    )
  ));


  createCustomItemSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateCustomItemSuccess),
    concatMap((action) => {
      const actions: Action[] = [
        new CreateGeneralItemFromCustomItemAttempt({
          customItem: action.payload.customItem,
          categoryId: action.payload.categoryId,
          worksheetId: action.payload.worksheetId,
          storageAreaId: action.payload.storageAreaId,
          areaExpandStatus: action.payload.areaExpandStatus,
          successMessage: action.payload.successMessage,
          duplicate: action.payload.duplicate,
          addToWorksheet: action.payload.addToWorksheet,
          isManage: action.payload.isManage
        }),
        new SaveCustomItemFinish(action.payload.customItem)
      ];
      if (action.payload.customUnits || action.payload.recipeUnits) {
        actions.push(
          new CreateCustomItemDataAttempt({
            itemId: action.payload.customItem.id,
            itemType: 'CUSTOM',
            countingUnits: action.payload.customUnits,
            recipeUnits: action.payload.recipeUnits,
            categoryId: null,
            purchasedByUnit: false,
            purchaseUnit: null,
          })
        );
      }
      return actions;
    })
  ));


  createGeneralItemFromCustomItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateGeneralItemFromCustomItemAttempt),
    mergeMap((action) => {
      const product: Product = {
        id: action.payload.customItem.id,
        type: 'CUSTOM',
        primaryProduct: true,
      };
      return this.generalItemService
        .createGeneralItem(
          action.payload.customItem.customerPK,
          action.payload.customItem.description,
          action.payload.categoryId,
          [product]
        )
        .pipe(
          map(
            (item) =>
              new CreateGeneralItemFromCustomItemSuccess({
                generalItem: item,
                worksheetId: action.payload.worksheetId,
                storageAreaId: action.payload.storageAreaId,
                areaExpandStatus: action.payload.areaExpandStatus,
                successMessage: action.payload.successMessage,
                duplicate: action.payload.areaExpandStatus,
                addToWorksheet: action.payload.addToWorksheet,
                isManage: action.payload.isManage,
              })
          ),
          catchError((err) => of(new CreateGeneralItemFromCustomItemError(err)))
        );
    })
  ));


  createGeneralItemFromCustomItemSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateGeneralItemFromCustomItemSuccess),
    mergeMap((action) => {
      const worksheetItemPost: WorksheetItemPost = {
        itemId: action.payload.generalItem.id,
        itemType: 'GENERAL',
        primaryUnit: 'CASE',
        primaryUnitQty: null,
        primaryUnitType: 'LITERAL',
        secondaryUnit: 'metric_weight_kg',
        secondaryUnitQty: null,
        secondaryUnitType: 'STANDARD',
      };
      const actions = [];
      if (action.payload.isManage) {
        actions.push(
          new GetAllCustomItemsAttempt(),
          new GetAllGeneralItemsAttempt(),
          new GetWorksheetSummaryAttempt()
        );
      }
      if (
        InventoryConstants.GROUP_ENTITIES.includes(
          action.payload.generalItem.customerPK.entityType
        ) &&
        action.payload.addToWorksheet
      ) {
        const worksheetItemGroupPost: WorksheetItemGroupPost = {
          ...worksheetItemPost,
          customerPK: action.payload.generalItem.customerPK,
        };
        actions.push(
          new CreateGroupWorksheetItemsAttempt({
            worksheetItemGroupPost,
            itemDescription: action.payload.generalItem.description,
            successMessage: action.payload.successMessage,
          })
        );
      } else if (
        !InventoryConstants.GROUP_ENTITIES.includes(
          action.payload.generalItem.customerPK.entityType
        ) &&
        action.payload.addToWorksheet &&
        action.payload.worksheetId &&
        action.payload.storageAreaId
      ) {
        actions.push(
          new CreateWorksheetItemAttempt({
            worksheetId: action.payload.worksheetId,
            storageAreaId: action.payload.storageAreaId,
            worksheetItemPost,
            areaExpandStatus: action.payload.areaExpandStatus,
            itemDescription: action.payload.generalItem.description,
            successMessage: action.payload.successMessage,
            duplicate: false,
          })
        );
      }
      return actions;
    })
  ));


  getItemCategories$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetItemCategoriesAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!this.appContext.customerPK)),
      this.appContext.customerPK,
      this.appContext.language
    ),
    mergeMap(([action, state, pk, language]) => {
      if (!!state.addItemsFeature.itemCategories_untranslated) {
        return [
          new GetItemCategoriesSuccess({
            categories: state.addItemsFeature.itemCategories_untranslated,
            language,
          }),
        ];
      }
      if (!state.addItemsFeature.itemCategories_isLoading) {
        this.store.dispatch(new GetItemCategoriesBeginLoading());
        return this.itemCategoryService.getItemCategories(pk).pipe(
          map(
            (categories) =>
              new GetItemCategoriesSuccess({
                categories,
                language,
              })
          ),
          catchError((err) => of(new GetItemCategoriesError(err)))
        );
      }
      return [];
    })
  ));


  createCategory$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateCategoryAttempt),
    withLatestFrom(this.appContext.customerPK, this.appContext.language),
    mergeMap(([action, pk, language]) =>
      this.itemCategoryService
        .createItemCategory(pk, action.payload.categoryName)
        .pipe(
          map(
            (category) =>
              new CreateCategorySuccess({
                category,
                language,
              })
          ),
          catchError((err) => of(new CreateCategoryError(err)))
        )
    )
  ));


  createCategorySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateCategorySuccess),
    tap((action) => {
      const value = action.payload.category.name.find(
        (localizedValue) =>
          localizedValue.languageCode === action.payload.language
      ).value;
      this.messageService.queue(
        this.translate.instant('MESSAGES.SUCCESSFULLY_CREATED', { value })
      );
    })
  ),
    { dispatch: false }
  );


  getMeasurementUnits$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetMeasurementUnitsAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!this.appContext.customerPK)),
      this.appContext.customerPK
    ),
    mergeMap(([action, state, pk]) => {
      if (state.addItemsFeature.measurementUnits !== null) {
        return [
          new GetMeasurementUnitsSuccess(
            state.addItemsFeature.measurementUnits
          ),
        ];
      }
      if (!state.addItemsFeature.measurementUnits_isLoading) {
        this.store.dispatch(new GetMeasurementUnitsBeginLoading());
        return this.measurementUnitService.getMeasurementUnits(pk).pipe(
          map((units) => new GetMeasurementUnitsSuccess(units)),
          catchError((err) => of(new GetMeasurementUnitsError(err)))
        );
      }
      return [];
    })
  ));


  getCustomItemData$ = createEffect((): Observable<any> => this.actions$.pipe(
    ofType(ActionTypes.GetCustomItemDataAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!this.appContext.customerPK)),
      this.appContext.customerPK
    ),
    mergeMap(([action, state, pk]) => {
      if (state.addItemsFeature.customItemData !== null) {
        return [
          new GetCustomItemDataSuccess(state.addItemsFeature.customItemData),
        ];
      }

      if (!state.addItemsFeature.customItemData_isLoading) {
        this.store.dispatch(new GetCustomItemDataBeginLoading());
        return this.customItemDataService
          .getCustomItemData(pk, action.payload.itemId, action.payload.itemType)
          .pipe(
            map((data) => new GetCustomItemDataSuccess(data)),
            catchError((err) => of(new GetCustomItemDataError(err)))
          );
      }

      return of([]);
    })
  ));


  createCustomItemData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateCustomItemDataAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) =>
      this.customItemDataService
        .createCustomItemData(
          pk,
          action.payload.itemId,
          action.payload.itemType,
          action.payload.countingUnits,
          action.payload.categoryId,
          action.payload.purchasedByUnit,
          action.payload.purchaseUnit,
          action.payload.recipeUnits
        )
        .pipe(
          tap(_ => { WorksheetEffects.storageAreaSchemaChange$.next(); }),
          concatMap((data) => [
            new CreateCustomItemDataSuccess(data),
            new GetAllCustomItemDataAttempt(),
          ]),
          catchError((err) => of(new CreateCustomItemDataError(err)))
        )
    )
  ));


  updateCustomItemData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateCustomItemDataAttempt),
    mergeMap((action) =>
      this.customItemDataService
        .updateCustomItemData(
          action.payload.customItemDataId,
          action.payload.countingUnits,
          action.payload.categoryId,
          action.payload.purchasedByUnit,
          action.payload.purchaseUnit,
          action.payload.recipeUnits
        )
        .pipe(
          concatMap((customItemData) => [
            new UpdateCustomItemDataSuccess(customItemData),
            new GetAllCustomItemDataAttempt(),
          ])
        )
    )
  ));


  updateCustomItemDataSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateCustomItemDataSuccess),
    tap(_ => { WorksheetEffects.storageAreaSchemaChange$.next() }),
    switchMap((x) => [
      new GetCustomItemDataSuccess(null),
      new GetCustomItemDataAttempt({
        itemId: x.customItemData.itemId,
        itemType: x.customItemData.itemType,
      }),
    ]),
  ));


  addItemsError$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.GetAutocompleteResultsError,
      ActionTypes.GetCountableItemsError,
      ActionTypes.CreateCustomItemError,
      ActionTypes.CreateGeneralItemFromCustomItemError,
      ActionTypes.GetMeasurementUnitsError,
      ActionTypes.GetCustomItemDataError,
      ActionTypes.CreateCustomItemDataError,
      ActionTypes.UpdateCustomItemDataError
    ),
    filter((action: any) => {
      const httpError = action.error as HttpErrorResponse;
      return !(
        httpError.status === 400 &&
        httpError.error !== null &&
        httpError.error.code === '103'
      );
    }),
    tap((action) => {
      this.messageService.queue(action.error.message);
    })
  ), { dispatch: false });


  duplicateCustomItemError$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateCustomItemError),
    filter((action: any) => {
      const httpError = action.error as HttpErrorResponse;
      return (
        httpError.status === 400 &&
        httpError.error !== null &&
        httpError.error.code === '103'
      );
    }),
    tap((_) => {
      this.messageService.queue(
        this.translate.instant('MESSAGES.ITEM_ALREADY_EXISTS')
      );
    })
  ), { dispatch: false });


  getSuppliersAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetSuppliersAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!this.appContext.customerPK)),
      this.appContext.customerPK
    ),
    mergeMap(([action, state, pk]) => {
      if (state.addItemsFeature.suppliers !== null) {
        return [
          new GetSuppliersSuccess({
            suppliers: state.addItemsFeature.suppliers,
          }),
        ];
      }
      if (state.addItemsFeature.suppliers_loading === false) {
        this.store.dispatch(new GetSuppliersBeginLoading());
        return this.supplierService.getSuppliers(pk).pipe(
          map((suppliers) => new GetSuppliersSuccess({ suppliers })),
          catchError((err) => of(new GetSuppliersError(err)))
        );
      }
      return [];
    })
  ));


  createSupplierAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateSupplierAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) =>
      this.supplierService.createSupplier(pk, action.payload.name).pipe(
        mergeMap((supplier) => [
          new CreateSupplierSuccess(supplier),
          new GetSuppliersSuccess({ suppliers: null }),
          new GetSuppliersAttempt(),
        ]),
        catchError((err) => of(new CreateSupplierError(err)))
      )
    )
  ));


  createSupplierSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateSupplierSuccess),
    tap((action) => this.messageService.queue(
      this.translate.instant('MESSAGES.CRUD.SUCCESSFULLY_CREATED', {
        value: action.supplier.name,
      }))),
    withLatestFrom(
      this.store.select(state => state.addItemsFeature.supplierOrder),
      this.appContext.customerPK
    ),
    mergeMap(([action, supplierOrder, customerPK]) => {
      const order = !supplierOrder
        ? this.supplierService.getSupplierOrders(customerPK)
        : of(supplierOrder);

      return order.pipe(
        map((suppliersInOrder: SupplierOrder) => {
          suppliersInOrder.order.unshift(action.supplier.id);
          return suppliersInOrder;
        }),
        map(suppliersInOrder => new CreateSupplierOrderAttempt({ supplierOrder: suppliersInOrder }))
      );
    })), { dispatch: false });

  deleteSupplierAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteSupplierAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) => this.supplierService.getSuppliers(pk).pipe(
      mergeMap((suppliers) => this.supplierService.deleteSupplier(action.payload.supplier).pipe(
        map((supplier: Supplier) => new DeleteSupplierSuccess({ suppliers, supplier })),
        catchError(err => of(new DeleteSupplierError(err)))
      )
      )))
  ));


  deleteSupplierSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteSupplierSuccess),
    tap((action) => this.messageService.queue(
      this.translate.instant('MESSAGES.CRUD.SUCCESSFULLY_DELETED', {
        value: action.payload.supplier.name,
      }))),
    withLatestFrom(this.store.pipe(
      map(state => ({
        supplierOrder: state.addItemsFeature.supplierOrder,
        suppliers: state.addItemsFeature.suppliers
      }))),
      this.appContext.customerPK
    ),
    mergeMap(([action, { supplierOrder, suppliers }, customerPK]) => {
      const order = !supplierOrder
        ? this.supplierService.getSupplierOrders(customerPK)
        : of(supplierOrder);

      return order.pipe(
        map((suppliersInOrder: SupplierOrder) => {
          suppliersInOrder.order = suppliersInOrder.order
            .filter(item => suppliers.map(sup => sup.id).includes(item))
            .filter(item => item !== action.payload.supplier.id);
          return suppliersInOrder;
        }));
    }),
    map(suppliersInOrder => new CreateSupplierOrderAttempt({ supplierOrder: suppliersInOrder }))
  ));


  patchSupplierAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchSupplierAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) => this.supplierService.getSuppliers(pk).pipe(
      mergeMap((suppliers) => this.supplierService.patchSupplier(action.payload.supplier).pipe(
        map((supplier: Supplier) => new PatchSupplierSuccess({ suppliers, supplier })),
        catchError(err => of(new PatchSupplierError(err)))
      ))
    ))));


  patchSupplierSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchSupplierSuccess),
    tap((action) => this.messageService.queue(
      this.translate.instant('MESSAGES.CRUD.SUCCESSFULLY_UPDATED', {
        value: action.payload.supplier.name,
      }))),
  ), { dispatch: false });


  getSupplierOrderAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetSupplierOrderAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) => this.supplierService.getSupplierOrders(pk)
      .pipe(
        map((supplierOrder: SupplierOrder) => new GetSupplierOrderSuccess({ supplierOrder })),
        catchError(err => of(new GetSupplierOrderError(err)))
      )
    ))
  );


  createSupplierOrderAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateSupplierOrderAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) => this.supplierService.getSuppliers(pk).pipe(
      mergeMap(suppliers => this.supplierService.putSupplierOrders(action.payload.supplierOrder).pipe(
        map((supplierOrder: SupplierOrder) => new CreateSupplierOrderSuccess({ suppliers, supplierOrder })),
        catchError(err => of(new CreateSupplierOrderError(err)))
      )
      )))));


  constructor(
    private actions$: Actions<ActionUnion>,
    private store: Store<IFeatureStateFacade>,
    private productService: ProductService,
    private countableItemService: CountableItemService,
    private customItemService: CustomItemService,
    private generalItemService: GeneralItemService,
    private itemCategoryService: ItemCategoryService,
    private measurementUnitService: MeasurementUnitService,
    private customItemDataService: CustomItemDataService,
    private supplierService: SupplierService,
    private messageService: MessageService,
    private translate: TranslateService,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext
  ) { }
}
<button class="date-picker-button delivery-schedule-widget"
  [ngClass]="{'normal-layout': !wideLayout, 'wide-layout': wideLayout, 'no-borders': wideLayout}"
  [disabled]="disabled"
  [attr.aria-label]="'DELIVERYSCHEDULE.COMBINED_TITLE_ARIA_LABEL' | translate"
>
  <mat-icon class="calendar-image" svgIcon="calendar_icon"></mat-icon>
  <div class="text">
    <ng-container *ngIf="wideLayout">
      <div class="combined-title"> {{'DELIVERYSCHEDULE.TITLE' | translate}} {{'DELIVERYSCHEDULE.REQUIRED' | translate}} </div>
    </ng-container>
    <ng-container *ngIf="!wideLayout">
      <div class="title">{{'DELIVERYSCHEDULE.TITLE' | translate}}</div>
      <!-- TODO: Add ship date logic -->
      <div class="subtitle">{{'DELIVERYSCHEDULE.REQUIRED' | translate}}</div>
    </ng-container>

  </div>
</button>
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageArea } from '@gfs/shared-models';
import { StorageAreaUtilsService } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export interface StorageAreaDialogData {
  storageArea: StorageArea,
  storageAreas: StorageArea[],
}

@Component({
  selector: 'app-storage-area-create-modal',
  templateUrl: './storage-area-create-modal.component.html',
  styleUrls: ['./storage-area-create-modal.component.scss']
})
export class StorageAreaCreateModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  isNewStorageArea: boolean;
  storageArea: StorageArea;
  storageAreaName: string;
  controlValueChanges: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private storageAreaUtils: StorageAreaUtilsService,
    public dialogRef: MatDialogRef<StorageAreaCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StorageAreaDialogData) {}

  ngOnInit() {
    this.isNewStorageArea = !(this.data && this.data.storageArea);
    this.storageArea = (this.data && this.data.storageArea) ? this.storageArea : null;
    this.storageAreaName = (this.data && this.data.storageArea && this.data.storageArea.name)
          ? this.translate.instant(this.storageAreaUtils.getTranslation(this.data.storageArea.name))  : '';
    this.form = this.formBuilder.group({
      storageAreaName: [ (this.data && this.data.storageArea && this.data.storageArea.name) ? this.storageAreaName : '' ,
      [ Validators.required, this.duplicateStorageAreaNameValidator(this.storageAreaName),  this.storageAreaNameValidator() ] ],
      updateOn: 'change'
    });
    const storageAreaControl = this.form.controls.storageAreaName;
    this.controlValueChanges = storageAreaControl.valueChanges.subscribe(() => storageAreaControl.markAsTouched());
  }

  ngOnDestroy() {
    this.controlValueChanges.unsubscribe();
  }

  public duplicateStorageAreaNameValidator(
    originalName: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control && control.value) {
        if (originalName.toLowerCase() !== control.value.toLowerCase()) {
          const areaIndex = this.data.storageAreas.findIndex(el => el.name.toLowerCase() === control.value.toLowerCase());
          if (areaIndex > -1) {
            return { storageAreaExists: true };
          }
        }
      }
      return null;
    };
  }

  public storageAreaNameValidator(): ValidatorFn {
    return ( control: AbstractControl): { [key: string]: boolean } | null => {
      if (control && control.value) {
        if (('Unassigned'.toLowerCase() === control.value.toLowerCase()) ||
            ('Non-assignés'.toLowerCase() === control.value.toLowerCase())) {
              return { invalidStorageAreaName: true };
            } else if (!(control.value as string).replace(/\s/g, '').length) {
              return { storageAreaContainsOnlySpaces: true };
            }
      }
      return null;
    };
  }

  closeModal() {
    this.form.value.storageAreaName = '';
    this.dialogRef.close();
  }

  submit(form) {
    this.dialogRef.close(form.value.storageAreaName);
  }
}

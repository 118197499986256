<gfs-header
  *ngIf="showHeader"
  [showElements]="showHeaderElements"
  [showSubHeader]="showSubHeader"
></gfs-header>
<div class="content">
  <mat-tab-nav-panel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
  <gfs-messages></gfs-messages>
</div>

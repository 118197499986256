import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CustomerPK, IAppContext, Worksheet } from '@gfs/shared-models';
import { AppFeatureFlags, InjectionTokens, InventoryWorksheetStatusEnum, RedirectUri } from '@gfs/shared-services';
import { wrap$ } from '@gfs/shared-services/extensions/rxjs';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { WorksheetDataService } from '@inventory-ui/v2/common/services/worksheet-data.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, first, pluck, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TakeInventoryResolver {
  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private loadingSpinner: LoadingSpinnerOverlayService,
    private worksheetData: WorksheetDataService,
    private ff: AppFeatureFlags
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RedirectUri> {
    return wrap$(this.appContext.customerPK)
      .pipe(
        first(),
        tap(() => this.showSpinnerOverlay()),
        concatMap((pk) => forkJoin([
          of(pk),
          this.getActiveWorksheet(pk)
        ])),
        concatMap(([pk, activeWorksheet]) =>
          this.resolveWorksheetIdForTakingInventory(activeWorksheet.status, activeWorksheet.id, pk)
        ),
        map((worksheetId) => createEditWorkSheetUri(worksheetId)),
        catchError((err) => of(new RedirectUri('/error'))),
      );
  }


  showSpinnerOverlay() {
    return this.loadingSpinner.show();
  }

  hideSpinnerOverlay() {
    return this.loadingSpinner.hide();
  }

  getActiveWorksheet(customerPK: CustomerPK): Observable<Worksheet> {
    return getActiveWorksheet(this.worksheetData, customerPK);
  }

  resolveWorksheetIdForTakingInventory(status: string, id: string, pk: CustomerPK): Observable<string> {

    return resolveWorksheetIdForTakingInventory(this.worksheetData, status, id, pk);
  }
}

//#region domain extensions
export function getActiveWorksheet(worksheetData: WorksheetDataService, customerPK: CustomerPK): Observable<Worksheet> {
  return worksheetData.getMostRecentWorksheet(customerPK);
}

export function resolveWorksheetIdForTakingInventory(
  worksheetData: WorksheetDataService,
  status: string,
  id: string,
  pk: CustomerPK) {
  switch (status) {
    case InventoryWorksheetStatusEnum.Closed:
      return worksheetData.cloneWorksheet(id, pk).pipe(pluck('id'));
    case InventoryWorksheetStatusEnum.Open:
    default:
      return of(id);
  }
}

export function createEditWorkSheetUri(worksheetId: string): RedirectUri {
  return new RedirectUri(`/worksheet/${worksheetId}`);
}
//#endregion
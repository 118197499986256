import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  ActionTypes,
  ActionUnion,
} from '../actions/inventory.actions';
import { Router } from '@angular/router';

@Injectable()
export class InventoryEffects {


  navigateHome = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.NavigateHome),
    map(() => {
      this.router.navigateByUrl('/inventory');
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions<ActionUnion>,
    private router: Router
  ) { }
}

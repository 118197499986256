import { Action } from '@ngrx/store';
import {
  CountableItem,
  GeneralItem,
  ItemCategory,
  MeasurementUnit,
  Supplier,
} from '@gfs/shared-models';
import { HttpErrorResponse } from '@angular/common/http';

export enum ActionTypes {
  GetCountableItemsAttempt = '[Quick Add Items] Get Countable Items Attempt',
  GetCountableItemsSuccess = '[Quick Add Items] Get Countable Items Success',
  GetGeneralItemByCustomItemIdAttempt = '[Quick Add Items] Get General Item By CustomItemId Attempt',
  GetGeneralItemByCustomItemIdSuccess = '[Quick Add Items] Get General Item By CustomItemId Success',
  GetGeneralItemByCustomItemIdError = '[Quick Add Items] Get General Item By CustomItemId Error',
  ClearCountableItems = '[Quick Add Items] Clear Countable Items',
  GetCountableItemsError = '[Quick Add Items] Get Countable Items Error',
  GetItemCategoriesAttempt = '[Quick Add Items] Get Item Categories Attempt',
  GetItemCategoriesBeginLoading = '[Quick Add Items] Get Item Categories Begin Loading',
  GetItemCategoriesSuccess = '[Quick Add Items] Get Item Categories Success',
  GetItemCategoriesError = '[Quick Add Items] Get Item Categories Error',
  GetMeasurementUnitsAttempt = '[Quick Add Items] Get Measurement Units Attempt',
  GetMeasurementUnitsBeginLoading = '[Quick Add Items] Get Measurement Units Begin Loading',
  GetMeasurementUnitsSuccess = '[Quick Add Items] Get Measurement Units Success',
  GetMeasurementUnitsError = '[Quick Add Items] Get Measurement Units Error',
  ClearCustomItemData = '[Quick Add Items] Clear Custom Item Data',
  GetSuppliersAttempt = '[Quick Add Items] Get Suppliers Attempt',
  GetSuppliersBeginLoading = '[Quick Add Items] Get Suppliers Begin Loading',
  GetSuppliersSuccess = '[Quick Add Items] Get Suppliers Success',
  GetSuppliersError = '[Quick Add Items] Get Suppliers Error',
}

export class GetCountableItemsAttempt implements Action {
  readonly type = ActionTypes.GetCountableItemsAttempt;
  constructor(
    public payload: {
      locale: string;
      searchText: string;
      offset: number;
      limit: number;
    }
  ) {}
}

export class GetCountableItemsSuccess implements Action {
  readonly type = ActionTypes.GetCountableItemsSuccess;
  constructor(
    public payload: {
      searchText: string;
      locale: string;
      items: CountableItem[];
    }
  ) {}
}

export class GetGeneralItemByCustomItemIdAttempt implements Action {
  readonly type = ActionTypes.GetGeneralItemByCustomItemIdAttempt;
  constructor(
    public payload: {
      customItemId: string;
    }
  ) {}
}

export class GetGeneralItemByCustomItemIdSuccess implements Action {
  readonly type = ActionTypes.GetGeneralItemByCustomItemIdSuccess;
  constructor(
    public payload: {
      customItemId: string;
      generalItem: GeneralItem;
    }
  ) {}
}

export class GetGeneralItemByCustomItemIdError implements Action {
  readonly type = ActionTypes.GetGeneralItemByCustomItemIdError;
  constructor(public error: any) {}
}

export class ClearCountableItems implements Action {
  readonly type = ActionTypes.ClearCountableItems;
}

export class GetCountableItemsError implements Action {
  readonly type = ActionTypes.GetCountableItemsError;
  constructor(public error: any) {}
}

export class GetItemCategoriesAttempt implements Action {
  readonly type = ActionTypes.GetItemCategoriesAttempt;
}

export class GetItemCategoriesBeginLoading implements Action {
  readonly type = ActionTypes.GetItemCategoriesBeginLoading;
}

export class GetItemCategoriesSuccess implements Action {
  readonly type = ActionTypes.GetItemCategoriesSuccess;
  constructor(
    public payload: { categories: ItemCategory[]; language: string }
  ) {}
}

export class GetItemCategoriesError implements Action {
  readonly type = ActionTypes.GetItemCategoriesError;
  constructor(public error: HttpErrorResponse) {}
}

export class GetMeasurementUnitsAttempt implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsAttempt;
}

export class GetMeasurementUnitsBeginLoading implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsBeginLoading;
}

export class GetMeasurementUnitsSuccess implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsSuccess;
  constructor(public units: MeasurementUnit[]) {}
}

export class GetMeasurementUnitsError implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsError;
  constructor(public error: HttpErrorResponse) { }
}

export class ClearCustomItemData implements Action {
  readonly type = ActionTypes.ClearCustomItemData;
}

export class GetSuppliersAttempt implements Action {
  readonly type = ActionTypes.GetSuppliersAttempt;
}

export class GetSuppliersBeginLoading implements Action {
  readonly type = ActionTypes.GetSuppliersBeginLoading;
}

export class GetSuppliersSuccess implements Action {
  readonly type = ActionTypes.GetSuppliersSuccess;
  constructor(public payload: { suppliers: Supplier[] }) {}
}

export class GetSuppliersError implements Action {
  readonly type = ActionTypes.GetSuppliersError;
  constructor(public error: HttpErrorResponse) {}
}

export type ActionUnion =
  GetCountableItemsAttempt |
  GetCountableItemsSuccess |
  GetGeneralItemByCustomItemIdAttempt |
  GetGeneralItemByCustomItemIdSuccess |
  GetGeneralItemByCustomItemIdError |
  ClearCountableItems |
  GetCountableItemsError |
  GetItemCategoriesAttempt |
  GetItemCategoriesBeginLoading |
  GetItemCategoriesSuccess |
  GetItemCategoriesError |
  GetMeasurementUnitsAttempt |
  GetMeasurementUnitsBeginLoading |
  GetMeasurementUnitsSuccess |
  GetMeasurementUnitsError |
  ClearCustomItemData |
  GetSuppliersAttempt |
  GetSuppliersSuccess |
  GetSuppliersError |
  GetSuppliersBeginLoading;

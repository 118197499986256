import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'gfs-info-dialog',
  templateUrl: './non-gfs-info-dialog.component.html',
  styleUrls: ['./non-gfs-info-dialog.component.scss']
})
export class NonGfsInfoDialogComponent implements OnInit {

  isMobile$: Observable<boolean> = this.store.select(
    (state) => state.layout.isMobile
  );

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<NonGfsInfoDialogComponent>,
  ) {
  }

  ngOnInit() {
    console.log('open dialog');
  }

  closeModal() {
    this.dialogRef.close();
  }

}

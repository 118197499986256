import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, withLatestFrom } from 'rxjs/operators';
import {
  ActionTypes,
  ActionUnion,
  GetCustomerDataSuccess,
  GetCustomerDataError
} from './actions';
import { CustomerService } from '@gfs/shared-services';
import { of } from 'rxjs';
import { FeatureState } from './state';
import { Store } from '@ngrx/store';
import { InventoryConstants } from '@gfs/constants';
import { Customer } from '@gfs/shared-models';

@Injectable()
export class CustomerEffects {

  getCustomerDataAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCustomerDataAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {

      const existentCustomer = state.customers?.[action.payload.customerId];

      if (existentCustomer) {
        return of(new GetCustomerDataSuccess(existentCustomer));
      }

      // only call the /customers service if the entity is not in the GROUP_ENTITIES
      if (!InventoryConstants.GROUP_ENTITIES.includes(action.payload.entityType)) {
        return this.customerService.getCustomerData(action.payload)
          .pipe(
            map((customer: Customer) => new GetCustomerDataSuccess(customer)),
            catchError(err => of(new GetCustomerDataError(err)))
          );
      } else {
        return [];
      }
    })
  ));

  constructor(
    private actions$: Actions<ActionUnion>,
    private customerService: CustomerService,
    private store: Store<FeatureState>
  ) { }
}

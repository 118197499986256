import { Injectable } from '@angular/core';
import { CustomerPK, Worksheet, WorksheetLastModified } from '@gfs/shared-models';
import { InventoryService, InventoryWorksheetStatusEnum, WorksheetService, falseyToNull } from '@gfs/shared-services';
import { isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { GetItemCategoriesAttempt, GetMeasurementUnitsAttempt, GetSuppliersAttempt } from '@gfs/store/feature/add-items';
import { ClearInventoryItems } from '@gfs/store/inventory/actions/inventoryItems.actions';
import { ClearWorksheetFromState, GetAllCustomItemDataAttempt, GetWorksheetAttempt, RefreshWorksheetItems, SelectWorksheet } from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap, find, map } from 'rxjs/operators';

@Injectable()
export class WorksheetDataServiceBLL implements IWorksheetDataServiceBLL {

  closeWorksheetById(worksheetService: WorksheetService, worksheetId: string) {
    return worksheetService
      .patchWorksheet(
        worksheetId,
        {
          status: InventoryWorksheetStatusEnum.Closed
        }
      );
  }

  deleteWorksheetById(worksheetService: WorksheetService, worksheetId: string): Observable<WorksheetLastModified> {
    return worksheetService.deleteWorksheet(worksheetId);
  }

  copyWorksheet(worksheetService: WorksheetService, sourceWorksheetId: string, pk: CustomerPK) {
    return worksheetService.copyWorksheet(sourceWorksheetId, pk).pipe(map(x => x));
  }

  fetchLatestWorksheet(inventoryService: InventoryService, pk: CustomerPK): Observable<Worksheet> {
    return inventoryService.getWorksheetSummaries(pk)
      .pipe(
        concatMap(x => x),
        find(x => x.isMostRecentWorksheet),
        map(x => falseyToNull(x))
      );
  }

  getAllWorksheets(inventoryService: InventoryService, pk: CustomerPK): Observable<Worksheet[]> {
    return inventoryService.getWorksheetSummaries(pk);
  }

  selectWorksheetById$(store: Store<AppState>, worksheetId: string) {
    return this.selectWorksheetFromStoreById$(store, worksheetId);
  }

  dispatchLoadWorksheetActions(store: Store, worksheetId: string) {
    store.dispatch(new SelectWorksheet({ worksheetId, shouldNavigate: false }));
    store.dispatch(new GetWorksheetAttempt(worksheetId));
    store.dispatch(new ClearInventoryItems());
    store.dispatch(new GetMeasurementUnitsAttempt());
    store.dispatch(new GetSuppliersAttempt());
    store.dispatch(new GetItemCategoriesAttempt());
    store.dispatch(new GetAllCustomItemDataAttempt());
  }

  clearWorksheetFromState(store: Store, worksheetId: string) {
    store.dispatch(new ClearWorksheetFromState(worksheetId));
  }

  selectWorksheetFromStoreById$(store: Store<AppState>, worksheetId: string): Observable<Worksheet> {
    store.dispatch(new SelectWorksheet({ worksheetId, shouldNavigate: false }));
    return store.select(this.selectWorksheetFromState()).pipe(isTruthy());
  }

  selectWorksheetFromState(): (state: AppState) => Worksheet {
    return (state: AppState) => {
      return state.worksheets.worksheets[state.worksheets.selected];
    };
  }

  refreshWorksheetItems(store: Store): void {
    store.dispatch(new RefreshWorksheetItems());
  }
}

export type IWorksheetDataServiceBLL = {
  deleteWorksheetById(worksheetService: WorksheetService, worksheetId: string): Observable<WorksheetLastModified>;
  copyWorksheet(
    worksheetService: WorksheetService,
    sourceWorksheetId: string,
    pk: CustomerPK
  );
  fetchLatestWorksheet(inventoryService: InventoryService, pk: CustomerPK): Observable<Worksheet>;
  getAllWorksheets(inventoryService: InventoryService, pk: CustomerPK): Observable<Worksheet[]>;
  selectWorksheetById$(store: Store<AppState>, worksheetId: string);
  dispatchLoadWorksheetActions(store: Store, worksheetId: string);
  clearWorksheetFromState(store: Store, worksheetId: string);
};

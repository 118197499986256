import { SetIsOnline, SetRetryingConnection } from './../actions/network.actions';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { switchMap, mapTo, map, withLatestFrom, filter, pairwise } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ActionTypes } from '../actions/network.actions';
import { IFeatureStateFacade } from '../reducer/network.reducer';
import { OnlineHeartbeatService } from '../../lib/services/online-heartbeat/online-heartbeat.service';

@Injectable()
export class NetworkEffects {

  startOnlineOfflineCheck$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.StartOnlineOfflineCheck),
    switchMap(() => merge(
        this.heartbeatService.getPulse(),
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false)),
      )),
    map(result => {
      setTimeout(() => {
        this.store.dispatch(new SetRetryingConnection(false));
      }, 1000);
      return result;
    }),
    pairwise(),
    withLatestFrom(this.store),
    filter(([[previous, current], state]) => {
      // if the status is false, immediately set the app to offline. If it's true, wait for a second true for confirmation
      // otherwise, the upcoming status is the same as the current status in the store, throw out this emitted value
      if (!current
        && state.network.isOnline) {
        return true;
      } else if (previous === current
        && current !== state.network.isOnline) {
        return true;
      } else {
        return false;
      }
    }),
    map(([[previous, current], state]) => new SetIsOnline(current))
  ));

  constructor(
    private actions$: Actions,
    private heartbeatService: OnlineHeartbeatService,
    private store: Store<IFeatureStateFacade>
    ) { }
}

<div *ngIf="!!storageArea" cdkDropList (cdkDropListDropped)="worksheetItemDropped($event, true)"
  [cdkDropListDisabled]="(hasFilterText$ | async) || (isMobile$ | async)">
  <div *ngIf="!(isMobile$ | async)" class="storage-area-card storage-area-card-dark">
    <mat-grid-list class="row-header-content" cols="12" rowHeight="32px" gutterSize="0px">
      <mat-grid-tile class="mat-grid-tile-align-left" colspan="6">{{'STORAGE_AREA.STORAGE_AREA_CARD.ITEM' | translate |
        uppercase}}</mat-grid-tile>
      <mat-grid-tile class="mat-grid-tile-align-left" colspan="3">{{'STORAGE_AREA.STORAGE_AREA_CARD.QUANTITY_ON_HAND' |
        translate | uppercase}}</mat-grid-tile>
      <mat-grid-tile *ngxPermissionsOnly="pricingPermissions" class="item-value-label-tile" colspan="2">
        {{'STORAGE_AREA.STORAGE_AREA_CARD.VALUE' | translate | uppercase}}</mat-grid-tile>
    </mat-grid-list>
  </div>
  <div cdkDragBoundary=".storage-area-item-panel" cdkDrag [cdkDragDisabled]="isMobile$ | async"
    *ngFor="let item of getCurrentItems()" class="storage-area-card">

    <mat-grid-list [id]="'inventory-item-' + item.id" [cols]="(isMobile$ | async) ? 3 : 12"
      [rowHeight]="(isMobile$ | async) ? '99px' : '94px'" gutterSize="0px">
      <mat-grid-tile [colspan]="(isMobile$ | async) ? (hasItemTripleDot ? 2 : 3) : 6" class="mat-grid-tile-align-left">
        <div class="drag-handle" cdkDragHandle>
          <img class="img-drag-handle" src="assets/images/draghandle.svg" alt="Drag & Drop Button"
            i18n-alt="@@DragAndDropAlt" />
        </div>
        <div class="item-ranking-wrapper">
          <input type="number" class="gfs-numeric-input" [value]="getWorksheetItemRank(item)"
            (click)="$event.target.select()" min="1" (blur)="handleRankingChange($event, getWorksheetItemRank(item))"
            attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.ITEM_SEQUENCE' | translate }}" />
        </div>
        <div class="item-image-wrapper">
          <img [id]="'product-image-' + item.id" class="storage-item-thumbnail"
            [src]="getLocalizedImageUrl(inventoryItems[item.itemId], 35, 35) | async"
            [alt]="getLocalizedProductDescription(inventoryItems[item.itemId]) | async" (error)="img.src = noImage.src"
            #img>
        </div>
        <div class="item-description-container" *ngIf="inventoryItems[item.itemId] !== undefined else missingItem">
          <div [id]="'product-title-' + item.id" class="item-title">
            {{getLocalizedProductDescription(inventoryItems[item.itemId]) | async | truncated: 50 }}
          </div>
          <div [id]="'product-details-' + item.id" class="item-details">
            {{getDisplayItemId(inventoryItems[item.itemId])}}
            {{getLocalizedBrand(inventoryItems[item.itemId]) | async}}
            {{createTextUnitDetails(item, lang) | async}}
          </div>
        </div>
        <ng-template #missingItem>
          <div class="item-description-container">
            <div [id]="'product-title-' + item.id" class="item-title">
              {{ 'STORAGE_AREA.STORAGE_AREA_CARD.MISSING_ITEM.DESCRIPTION' | translate: {'itemId': item.itemId} }}
            </div>
          </div>
        </ng-template>
      </mat-grid-tile>
      <ng-container *ngxPermissionsOnly="permissionRoles.itemTripleDot">
        <mat-grid-tile *ngIf="isMobile$ | async">
          <button mat-icon-button [matMenuTriggerFor]="itemMenu">
            <mat-icon svgIcon="triple-dot-menu-sideways"></mat-icon>
          </button>
        </mat-grid-tile>
      </ng-container>
      <mat-grid-tile [colspan]="(isMobile$ | async) ? 2 : 3">
        <div class="item-count-container">
          <div class="item-count item-count-primary">
            <div *ngIf="isMobile$ | async" class="count-icon-wrapper" (click)="countSubtractPrimaryClicked(item)">
              <mat-icon svgIcon='subtract' class="count-icon"></mat-icon>
            </div>
            <input class="gfs-numeric-input item-count-input" [id]="'product-primary-count-' + item.id"
              appTwoDigitDecimalNumber [regex]="qtyRegex" formcontrolname="primaryCount"
              [(ngModel)]="item.primaryUnitQty" (input)="onQuantityUpdate(item)"
              (keyup.enter)="moveToNextItemCount($event.target.id)" #itemCountInput autocomplete="off"
              attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.PRIMARY_QTY' | translate }}" />
            <div *ngIf="isMobile$ | async" class="count-icon-wrapper" (click)="countAddPrimaryClicked(item)"
              tabindex="1">
              <mat-icon svgIcon='add' class="count-icon"></mat-icon>
            </div>
            <mat-form-field class="item-count-unit">
              <mat-select formcontrolname="primaryUnit" disableOptionCentering
                [disabled]="checkIsDisabled(updateWorksheetItemUnitPermission) | async" 
                [ngModel]="getPrimaryUnit(item)"
                (selectionChange)="onPrimaryUnitSelected(item, $event)"
                aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.PRIMARY_UNIT' | translate }}">
                <mat-option *ngFor="let unit of getCountingUnits(item, (currentLang$ | async), true)"
                  [disabled]="countingUnitDisabled(unit, item.secondaryUnitType, item.secondaryUnit)"
                  attr.aria-label="{{ getUnitDisplayName(unit, (currentLang$ | async)) }}" [value]="unit">{{
                  getUnitDisplayName(unit, (currentLang$ | async)) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="item-count item-count-secondary">
            <div *ngIf="isMobile$ | async" class="count-icon-wrapper" (click)="countSubtractSecondaryClicked(item)">
              <mat-icon svgIcon='subtract' class="count-icon"></mat-icon>
            </div>
            <input class="gfs-numeric-input item-count-input" [id]="'product-secondary-count-' + item.id"
              appTwoDigitDecimalNumber [regex]="qtyRegex" formcontrolname="secondaryCount"
              [(ngModel)]="item.secondaryUnitQty" (input)="onQuantityUpdate(item)"
              (keyup.enter)="moveToNextItemCount($event.target.id)" #itemCountInput autocomplete="off"
              attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.SECONDARY_QTY' | translate }}" />
            <div *ngIf="isMobile$ | async" class="count-icon-wrapper" (click)="countAddSecondaryClicked(item)">
              <mat-icon svgIcon='add' class="count-icon"></mat-icon>
            </div>
            <mat-form-field class="item-count-unit">
              <mat-select formcontrolname="secondaryUnit" disableOptionCentering
                [disabled]="checkIsDisabled(updateWorksheetItemUnitPermission) | async"
                [ngModel]="getSecondaryUnit(item)"
                aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.SECONDARY_UNIT' | translate }}"
                (selectionChange)="onSecondaryUnitSelected(item, $event)">
                <mat-option *ngFor="let unit of getCountingUnits(item)"
                  [disabled]="countingUnitDisabled(unit, item.primaryUnitType, item.primaryUnit)"
                  attr.aria-label="{{ getUnitDisplayName(unit, (currentLang$ | async)) }}" [value]="unit">{{
                  getUnitDisplayName(unit, (currentLang$ | async)) }}
                </mat-option>
                <mat-option *ngIf="isSecondaryUnitNotPresent(item)">--</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="(isMobile$ | async) ? 1 : 2" class="item-value-tile">
        <div *ngxPermissionsOnly="pricingPermissions" class="item-value-container">

          <div class="item-value-primary">
            <ng-container [ngSwitch]="canComputeTierValue(item, item.primaryUnitQty, item.primaryUnit, item.primaryUnitType)">
              <ng-container *ngSwitchCase="true">
                <span *ngIf="getPrimaryItemValue(item) !== null">
                  {{getPrimaryItemValue(item) | currency:'CAD':'$':undefined:(currentLang$ | async)}}
                </span>
                <span *ngIf="getPrimaryItemValue(item) === null">
                  {{'STORAGE_AREA.STORAGE_AREA_CARD.EMPTY_ITEM_VALUE' | translate}}
                </span>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <gfs-warning-icon
                  [i18nTooltip]="'RECIPE.CREATE.MISSING_INFO'"
                  [isPlaceholder]="false"
                  [hidden]="canComputeTierValue(item, item.primaryUnitQty, item.primaryUnit, item.primaryUnitType)">
                </gfs-warning-icon>
              </ng-container>
            </ng-container>
          </div>

          <div class="item-value-secondary">
            <ng-container [ngSwitch]="canComputeTierValue(item, item.secondaryUnitQty, item.secondaryUnit, item.secondaryUnitType)">
              <ng-container *ngSwitchCase="true">
                <span *ngIf="getSecondaryItemValue(item) !== null">
                  {{getSecondaryItemValue(item) | currency:'CAD':'$':undefined:(currentLang$ | async)}}
                </span>
                <span *ngIf="getSecondaryItemValue(item) === null">
                  {{'STORAGE_AREA.STORAGE_AREA_CARD.EMPTY_ITEM_VALUE' | translate}}
                </span>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <gfs-warning-icon
                [i18nTooltip]="'RECIPE.CREATE.MISSING_INFO'"
                  [isPlaceholder]="false"
                  [hidden]="canComputeTierValue(item, item.secondaryUnitQty, item.secondaryUnit, item.secondaryUnitType)">
                </gfs-warning-icon>
              </ng-container>
            </ng-container>
          </div>

        </div>
      </mat-grid-tile>
      <ng-container *ngxPermissionsOnly="permissionRoles.itemTripleDot">
        <mat-grid-tile *ngIf="!(isMobile$ | async)">
          <button mat-icon-button [matMenuTriggerFor]="itemMenu"
            attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU_ARIA_LABEL' | translate }}">
            <mat-icon svgIcon="triple-dot-menu"></mat-icon>
          </button>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>
    <mat-menu #itemMenu>
      <button *ngIf="inventoryItems[item.itemId]" (click)="editClicked(storageArea.id, item)"
        [disabled]="!(isOnline$ | async)" id="item-menu-edit-old" mat-menu-item>
        {{ (isAllowedToEdit(inventoryItems[item.itemId]) | async) ?
        ('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.EDIT' | translate) :
        ('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.VIEW' | translate)
        }}
      </button>
      <button *ngIf="inventoryItems[item.itemId]" (click)="duplicateClicked(item , storageArea.id)"
        [disabled]="!(isOnline$ | async)" id="item-menu-duplicate-old" mat-menu-item>
        {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DUPLICATE' | translate }}
      </button>
      <button [disabled]="!(isOnline$ | async)" [matMenuTriggerFor]="moveTo" id="item-menu-move-to-old" mat-menu-item>
        {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.MOVE_TO' | translate }}
      </button>

      <button (click)="deleteClicked(item , storageArea.id)" [disabled]="!(isOnline$ | async)" id="item-menu-delete-old"
        mat-menu-item>
        {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.REMOVE' | translate }}
      </button>
    </mat-menu>
    <mat-menu #moveTo="matMenu">
      <button *ngFor="let storageAreaMoveTo of (getStorageAreasMinusCurrentStorageArea(storageArea.id) | async)"
        id="item-menu-move-to-sa-{{storageArea.id}}" mat-menu-item
        (click)="moveToClicked(item ,storageArea.id, storageAreaMoveTo.id)">{{getTranslation(storageAreaMoveTo.name) |
        translate}}</button>
    </mat-menu>

  </div>
</div>
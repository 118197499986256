import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from '@gfs/shared-components';
import { Worksheet } from '@gfs/shared-models';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { promiseMeAlways } from '@inventory-ui/v2/common/preparing-app/preparing-app.component';
import { WorksheetDataService } from '@inventory-ui/v2/common/services/worksheet-data.service';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, first, map, mergeMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete',
  template: '',
})
export class DeleteComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingSpinner: LoadingSpinnerOverlayService,
    private worksheetData: WorksheetDataService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.activatedRoute.data.pipe(
      first(),
      map<any, Worksheet>(({ 0: worksheet }) => worksheet),
      mergeMap((worksheet: Worksheet) => this.open(worksheet)),
    ).subscribe();
  }

  open(row: Worksheet): Observable<boolean> {
    return this.createDeleteConfirmationModal(row)
      .afterClosed()
      .pipe(
        first(),
        tap(() => this.showSpinner()),
        concatMap((worksheet) => !!worksheet
          ? this.deleteWorksheetById(worksheet.id)
            .pipe(
              catchError(err => {
                alert('failed to delete worksheet');
                return of({ lastModified: null });
              })
            )
          : of(({ lastModified: null }))
        ),
        tap(() => this.hideSpinner()),
        mergeMap(({ lastModified }) => promiseMeAlways(navigateToWorksheetList(this.router)).pipe(map(() => lastModified !== null)))
      );
  }

  createDeleteConfirmationModal(row) {
    row.name = this.translateName(row.name);
    return createDeleteConfirmationModal(this.dialog, row, this.translateService);
  }

  showSpinner() {
    return this.loadingSpinner.show();
  }

  hideSpinner() {
    return this.loadingSpinner.hide();
  }

  deleteWorksheetById(worksheetId: string) {
    return deleteWorksheetById(this.worksheetData, worksheetId);
  }

  // if using the default name, translate it between english and french
  translateName(name: string): string {
    if (!name) {
      return '';
    }
    if (
      name.toLowerCase() === 'Untitled Inventory'.toLowerCase() ||
      name.toLowerCase() === `Inventaire sans titre`.toLowerCase()
    ) {
      return 'INVENTORY_WORKSHEET.UNTITLED_INVENTORY';
    }
    return name;
  }

}

//#region domain extensions
export const navigateToWorksheetList = async (router: Router) =>
  router.navigateByUrl('/inventory', { replaceUrl: true });

export const createDeleteConfirmationModal = (dialog: MatDialog, row: Worksheet, translate: TranslateService) => {
  let dlgTitle =
  translate.instant(
    'INVENTORY.DELETE_MODAL.DIALOG_TITLE',
    { worksheetName : row.name }
  );
  if (row.name === 'INVENTORY_WORKSHEET.UNTITLED_INVENTORY') {
    dlgTitle = 'INVENTORY.DELETE_MODAL.DIALOG_TITLE_UNTITLED';
  }
  return dialog.open<ConfirmationModalComponent, any, Worksheet>(ConfirmationModalComponent, {
    data: {
      returnData: row,
      title: dlgTitle,
      titleData: { worksheetName: row.name },
      subtitle: 'INVENTORY.DELETE_MODAL.DIALOG_SUBTITLE',
      submitButtonAriaLabel: 'INVENTORY.DELETE_MODAL.SUBMIT_BUTTON',
      submitButtonLabel: 'INVENTORY.DELETE_MODAL.SUBMIT_BUTTON',
      cancelButtonId: 'cancel-delete-worksheet-button',
      submitButtonId: 'delete-worksheet-button',
    },
    width: '604px',
    height: '253px'
  });
};

export const deleteWorksheetById = (worksheetData: WorksheetDataService, worksheetId: string) =>
  worksheetData.deleteWorksheetById(worksheetId);
//#endregion
import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'gfs-delivery-schedule-widget',
  templateUrl: './delivery-schedule-widget.component.html',
  styleUrls: ['./delivery-schedule-widget.component.scss']
})
export class DeliveryScheduleWidgetComponent {
  @Input() disabled = false;
  @Input() wideLayout = false;
}

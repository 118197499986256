export interface IAppConfig {
  readonly FF_WORKSHEET_TOTALS: boolean;
  readonly FF_SEARCH_FILTER_ORDER_GUIDE_ITEMS: any;
  readonly FF_SEARCH_FILTER_RECIPES_ITEMS: any;
  readonly FF_SEARCH_FILTER_NONGFS_ITEMS: any;
  readonly FF_SEARCH_FILTER_CATALOGUE: any;
  readonly GORDON_NOW_CHAT_ENV: string;
  readonly OKTA_BASE_URL: string;
  readonly OKTA_AUTH_SERVER: string;
  readonly OKTA_CLIENT_ID: string;
  readonly LOGOUT_REDIRECT_URL: string;
  readonly NAM_BASE_URL: string;
  readonly NAM_CLIENT_ID: string;
  readonly APP_BASE_URL: string;
  readonly GOOGLE_TAG_MANAGER_SNIPPET: string;
  readonly API_BASE_URL: string;
  readonly INVENTORY_API_BASE_URL: string;
  readonly HOT_JAR_SNIPPET: string;
  readonly FF_OFFLINE_MODE: boolean;
  readonly FF_ADD_NON_GFS_ITEMS: boolean;
  readonly FF_ENABLE_USER_RECIPE_IMAGES: boolean;
  readonly FF_ENABLE_TEST_FRAMEWORK_FILE_UPLOAD: boolean;
  readonly FF_CUSTOM_COUNT_UNITS: boolean;
  readonly FF_REPORTS: boolean;
  readonly FF_CONNECT_UDI_IMPORT: boolean;
  readonly FF_REDIRECT_USERS: boolean;
  readonly FF_MIGRATE_FROM_INVENTORY_MANAGER: boolean;
  readonly FF_USE_WORKSHEET_V2: boolean;
  readonly FF_REDIRECT_USERS_DESTINATION: string;
  readonly FF_REDIRECT_USERS_PLANT_IDS_COMMASEPARATED: string;
  readonly FF_REFRESH_WORKSHEET_ITEMS: boolean;
  readonly FF_GENERAL_LEDGER: boolean;
  readonly FF_INFINITE_SCROLL: boolean;
  readonly FF_RECIPE_PROFIT_CALCULATOR: boolean;
  readonly LOGROCKET_PROJECT: string;
  readonly LOGROCKET_EXCLUDED_USERS: string;
  readonly RECIPE_PATH: string;
  readonly ORDERS_PATH: string;
  readonly INVENTORY_PATH: string;
  readonly INVENTORY_REPORT_PATH_EN_CA?: string;
  readonly INVENTORY_REPORT_PATH_FR_CA?: string;
}

export abstract class IAppConfigService implements IAppConfig {
  FF_WORKSHEET_TOTALS: boolean;
  FF_SEARCH_FILTER_ORDER_GUIDE_ITEMS: any;
  FF_SEARCH_FILTER_RECIPES_ITEMS: any;
  FF_SEARCH_FILTER_NONGFS_ITEMS: any;
  FF_SEARCH_FILTER_CATALOGUE: any;
  GORDON_NOW_CHAT_ENV: string;
  OKTA_BASE_URL: string;
  OKTA_AUTH_SERVER: string;
  OKTA_CLIENT_ID: string;
  LOGOUT_REDIRECT_URL: string;
  NAM_BASE_URL: string;
  NAM_CLIENT_ID: string;
  APP_BASE_URL: string;
  GOOGLE_TAG_MANAGER_SNIPPET: string;
  API_BASE_URL: string;
  INVENTORY_API_BASE_URL: string;
  HOT_JAR_SNIPPET: string;
  FF_OFFLINE_MODE: boolean;
  FF_ADD_NON_GFS_ITEMS: boolean;
  FF_ENABLE_USER_RECIPE_IMAGES: boolean;
  FF_ENABLE_TEST_FRAMEWORK_FILE_UPLOAD: boolean;
  FF_CUSTOM_COUNT_UNITS: boolean;
  FF_REPORTS: boolean;
  FF_CONNECT_UDI_IMPORT: boolean;
  FF_REDIRECT_USERS: boolean;
  FF_MIGRATE_FROM_INVENTORY_MANAGER: boolean;
  FF_REDIRECT_USERS_DESTINATION: string;
  FF_REDIRECT_USERS_PLANT_IDS_COMMASEPARATED: string;
  FF_REFRESH_WORKSHEET_ITEMS: boolean;
  FF_GENERAL_LEDGER: boolean;
  FF_INFINITE_SCROLL: boolean;
  FF_RECIPE_PROFIT_CALCULATOR: boolean;
  LOGROCKET_PROJECT: string;
  LOGROCKET_EXCLUDED_USERS: string;
  RECIPE_PATH: string;
  ORDERS_PATH: string;
  INVENTORY_PATH: string;
  INVENTORY_REPORT_PATH_EN_CA?: string;
  INVENTORY_REPORT_PATH_FR_CA?: string;
  FF_USE_WORKSHEET_V2: boolean;
}

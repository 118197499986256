
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@gfs/shared-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ComponentCanDeactivate {
  confirmMessage: string,
  canDeactivate: () => boolean | Observable<boolean>,
}

@Injectable()
export class PendingChangesGuard
{
  constructor(
    @Inject(WINDOW) public window: Window
  ) { }
  private confirmMessage: string;

  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    this.confirmMessage = component.confirmMessage;
    const canDeactivate = component.canDeactivate();
    if (typeof (canDeactivate) === 'boolean') {
      return this.showConfirm(canDeactivate);
    }
    return canDeactivate.pipe(map(result => this.showConfirm(result)));
  }

  private showConfirm(canDeactivate: boolean): boolean {
    return canDeactivate || this.window.confirm(this.confirmMessage);
  }
}
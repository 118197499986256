import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableDebugTools } from '@angular/platform-browser';

export function initializeApp(appModule: any, isProduction: boolean) {

  if (isProduction) {
    enableProdMode();
  }

  fetch('config/runtime/config.json?t=' + Date.now()).then((response) => {
    response.json().then((config) => {

      // Store config on the session.
      // This will be used by AppConfigService to provide the app config to clients.
      // Devs can now change config flags via javascript, and it will be retained for the duration of their session.
      window.sessionStorage.setItem('__APP_CONFIG__', JSON.stringify(config));

      platformBrowserDynamic()
        .bootstrapModule(appModule)
        .then(moduleRef => {
          /**
           * Enable the change detection profiler when we're developing
           * run "ng.profiler.timeChangeDetection();" in console
           * https://github.com/angular/angular/blob/master/docs/TOOLS.md#performance
           */
          if (!isProduction) {
            const applicationRef = moduleRef.injector.get(ApplicationRef);
            const appComponent = applicationRef.components[0];
            enableDebugTools(appComponent);
          }
        })
        .catch(err => console.log(err));
    });
  });
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Cart, CustomerPK, CustomGuide, Worksheet } from '@gfs/shared-models';
import { InventoryWorksheetStatusEnum } from '@gfs/shared-services';
import { forkJoin, from, Observable, of } from 'rxjs';
import { concatMap, tap, toArray } from 'rxjs/operators';
import { InjectionTokens } from '../../injection-tokens';
@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private baseUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private INVENTORY_API_BASE_URL: string,
  ) {
    this.baseUrl = INVENTORY_API_BASE_URL + '/api/v1';
  }

  getWorksheetSummaries(customerPk: CustomerPK): Observable<Worksheet[]> {
    return this.http.get<Worksheet[]>(`${this.baseUrl}/worksheet-summaries`, { params: { ...customerPk } })
      .pipe(
        concatMap(worksheets =>
          forkJoin([of(worksheets), of(GetMostRecentInventoryId(worksheets))])
        ),
        concatMap(([worksheets, mostRecentSheetId]) =>
          from(worksheets)
            .pipe(
              tap(sheet => {
                sheet.isActiveInventory = sheet.id === mostRecentSheetId && sheet.status === InventoryWorksheetStatusEnum.Open;
                sheet.isMostRecentWorksheet = sheet.id === mostRecentSheetId;
              }),
              toArray()))
      );
  }
  getCustomGuides(customerPk: CustomerPK): Observable<CustomGuide[]> {
    return this.http.get<CustomGuide[]>(`${this.baseUrl}/custom-guides`, { params: { ...customerPk } });
  }

  getCartData(customerPk: CustomerPK, includePrice = true) {
    return this.http.get<Cart>(`${this.baseUrl}/carts`, {
      params: {
        ...customerPk,
        includePrice: includePrice.toString()
      }
    });
  }
}
function resolveIsActiveWorksheet(sheet: Worksheet, activeInventoryId: string): boolean {
  return sheet.id === activeInventoryId;
}

export function GetMostRecentInventoryId(worksheets: Worksheet[]): string {
  return 0 < worksheets.length
    ? worksheets.reduce((prev, curr) => (prev.created > curr.created || prev.status === 'open') ? prev : curr).id
    : '';
}

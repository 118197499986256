import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppContext } from '@gfs/shared-models';
import { AppState } from '@gfs/store/inventory/reducers';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InventoryUserContextFactoryService {
  public static factory(factory: InventoryUserContextFactoryService) {
    return factory.Build();
  }

  constructor(
    private store: Store<AppState>
  ) { }

  Build(): IAppContext {
    return {
      customerPK: this.store.select((x) => x.auth.pk).pipe(filter((x) => !!x)),
      entitlements: this.store.select((state) =>
        state.auth.user ? state.auth.user.entitlements : null
      ),
      title: this.store.select((x) => x.layout.title),
      headerHeight: this.store.select((x) => x.layout.headerHeight),
      isMobile: this.store.select((x) => x.layout.isMobile),
      language: this.store.select((x) => x.layout.language),
      isOnline$: this.store.select(state => state.network.isOnline),
      authorizations$: this.store.select(state => state.auth.authorizations),
      user$: this.store.select(state => state.auth.user),
    };
  }
}

import * as inventory from '../actions/inventory.actions';

export interface State {
  error: string;
}

export const initialState: State = {
  error: null,
};

export function inventoryReducer(
  state: State = initialState,
  action: inventory.ActionUnion
): State {
  switch (action.type) {
    default:
      return state;
  }
}

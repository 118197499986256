<ng-container *ngIf="editAction$ | async"></ng-container>
<ng-container *ngIf="deleteAction$ | async"></ng-container>
<ng-container *ngIf="dropAction$ | async"></ng-container>
<mat-list
  *ngIf="suppliers$ | async"
  id="manage-vendors-list"
  cdkDropList
  (cdkDropListDropped)="dropSubject.next($event)"
>
  <mat-list-item
    *ngFor="let supplier of suppliers  | orderBy: 'ordinal'"
    id="manage-vendors-list-item-{{supplier.name.replace(' ', '-')}}-{{ supplier.id }}"
    (focus)="onFocus(supplier.id, supplier.name)"
    (mouseover)="onFocus(supplier.id, supplier.name)"
    (mouseout)="onBlur(supplier.id, supplier.name)"
    tabindex="0"
    cdkDragBoundary="#manage-vendors-list"
    cdkDrag
  >
    <div class="manage-vendors-item">
      <div cdkDragHandle class="drag-handle">
        <img
          class="img-drag-handle"
          src="assets/images/category_draghandle_gray.svg"
          alt="Drag & Drop Button"
        />
      </div>
      <p class="manage-vendors-name">
        {{ supplier.name | truncated: 17 }}
        ({{ supplier.itemCount }})
      </p>
      <div
        id="manage-vendors-list-edit-container-{{
          supplier.name.replace(' ', '-')
        }}-{{ supplier.id }}"
        class="icon-container manage-vendors-list-edit-container"
      >
        <button
          id="manage-vendors-list-edit-button-{{
            supplier.name.replace(' ', '-')
          }}-{{ supplier.id }}"
          class="icon-hover-bkg"
          (click)="editSubject.next(supplier)"
          tabindex="-1"
          attr.aria-label="{{ 'SUPPLIER.EDIT_SUPPLIER_BUTTON_ARIA_LABEL' | translate }}"
        >
          <mat-icon
            class="icon manage-vendors-edit-icon"
            svgIcon="edit-icon"
            alt="Edit Button"
          ></mat-icon>
        </button>
      </div>
      <div
        id="manage-vendors-list-delete-container-{{
          supplier.name.replace(' ', '-')
        }}-{{ supplier.id }}"
        class="icon-container manage-vendors-list-delete-container"
      >
        <ng-container *ngIf="supplier.itemCount === 0">
          <button
            id="manage-vendors-list-delete-button-{{
              supplier.name.replace(' ', '-')
            }}-{{ supplier.id }}"
            class="icon-hover-bkg"
            (click)="deleteSubject.next(supplier)"
            tabindex="-1"
            attr.aria-label="{{ 'SUPPLIER.DELETE_SUPPLIER_BUTTON_ARIA_LABEL' | translate }}"
          >
            <mat-icon
              class="icon manage-vendors-delete-icon"
              svgIcon="trash-icon"
              alt="Delete Button"
            >
            </mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </mat-list-item>
</mat-list>
<button
  id="add-new-supplier-button"
  class="add-button ghost-button"
  (click)="editSubject.next(null)"
  (focus)="clearFocus()"
  >
  <mat-icon
    class="icon plus-icon"
    svgIcon="plus-icon"
  ></mat-icon>
  <div class="add-label">{{ 'SUPPLIER.NEW_SUPPLIER' | translate }}</div>
</button>
<div class="modal-container">
  <div class="mat-dialog-header modal-header">
    <div class="modal-title">
      <button *ngIf="!isEdit && !isManage" mat-icon-button (click)="goBack(false)" class="back-arrow-button">
        <img class="back-icon" src="assets/images/back-arrow.svg" alt="{{ 'MODALS.BACK' | translate }}" />
      </button>
      <span *ngIf="!isEdit">{{'ADD_ITEMS.ADD_NON_GFS_ITEM' | translate}}</span>
      <span *ngIf="!isAllowedToEdit">{{'EDIT_ITEMS.VIEW_NON_GFS_ITEM' | translate}}</span>
      <span *ngIf="isEdit && isAllowedToEdit">{{'EDIT_ITEMS.EDIT_NON_GFS_ITEM' | translate}}</span>
      <button *ngIf="!isEdit" mat-icon-button class="close-button" (click)="onClose()"
        attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
        <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE' | translate }}" />
      </button>
      <button *ngIf="isEdit" mat-icon-button class="close-button" (click)="onClose()"
        attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
        <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE' | translate }}" />
      </button>
    </div>
  </div>
  <div mat-dialog-content class="custom-item-modal">
    <div class="mat-panel-sub-text">{{'ADD_ITEMS.NON-GFS_ITEMS_SUB_TEXT' | translate}}</div>
    <mat-expansion-panel class="custom-item-panel item-info-panel" [expanded]="itemInfoExpanded">
      <mat-expansion-panel-header class="custom-item-panel-header item-info-panel-header" [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'" (click)="itemInfoExpanded = !itemInfoExpanded">
        <mat-panel-title>
          <div class="panel-title">
            {{'ADD_ITEMS.ITEM_INFO' | translate | uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="form-wrapper">
        <form [formGroup]="itemInfoForm">
          <mat-form-field class="item-name-form">
            <mat-label>{{'ADD_ITEMS.ITEM_NAME' | translate }}</mat-label>
            <input
              matInput
              type="text"
              autocomplete="off"
              class="custom-item-input"
              id="item-name-input"
              formControlName="itemName"
              autofocus
              (blur)="onItemNameBlur()"
            />
            <mat-error *ngIf="itemInfoForm.controls.itemName.hasError('duplicateItemName')">{{'ADD_ITEMS.DUPLICATE_ITEM_NAME_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field class="item-info-form">
            <mat-label>{{'ADD_ITEMS.VENDOR' | translate }}</mat-label>
            <gfs-add-element-autocomplete
              fieldName="supplier"
              [placeholder]="'ADD_ITEMS.VENDOR' | translate"
              formControlName="supplier"
              optionsValue="name"
              [addElementLabel]="'ADD_ITEMS.VENDOR' | translate"
              [options]="filteredSuppliers$ | async"
              (addElement)="createSupplier($event)"
              (optionSelected)="supplierSelected($event)"
              [disabled]="!isAllowedToEdit"
            ></gfs-add-element-autocomplete>
          </mat-form-field>
          <mat-form-field class="item-info-form">
            <mat-label>{{'ADD_ITEMS.ITEM_NUMBER' | translate }}</mat-label>
            <input
              matInput
              type="text"
              autocomplete="off"
              class="custom-item-input"
              formControlName="itemNumber"
              (blur)="onItemNumberBlur()"
            />
            <mat-error *ngIf="itemInfoForm.controls.itemNumber.hasError('duplicateItemNumber')">{{'ADD_ITEMS.DUPLICATE_ITEM_NO_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field class="item-info-form item-category-form">
            <mat-label>{{'ADD_ITEMS.CATEGORY' | translate }}</mat-label>
            <gfs-add-element-autocomplete
              fieldName="category"
              inputClass="custom-item-input"
              [placeholder]="'ADD_ITEMS.CATEGORY' | translate"
              optionsValue="name"
              formControlName="category"
              [addElementLabel]="'ADD_ITEMS.CUSTOM_CATEGORY' | translate"
              [options]="filteredCategories$ | async"
              (addElement)="createCategory($event)"
              [disabled]="!isAllowedToEdit"
            ></gfs-add-element-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel  class="custom-item-panel purchase-unit-panel" [expanded]="purchaseUnitExpanded">
      <mat-expansion-panel-header class="custom-item-panel-header" [collapsedHeight]="'40px'" [expandedHeight]="'40px'"
        (click)="purchaseUnitExpanded = !purchaseUnitExpanded">
        <mat-panel-title>
          <div class="panel-title">
            {{'ADD_ITEMS.PURCHASE_UNIT' | translate | uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mat-panel-sub-text">{{'ADD_ITEMS.PURCHASE_UNIT_SUB_TEXT' | translate}}</div>
      <div class="form-wrapper">
        <form [formGroup]="purchaseUnitForm">
          <div class="form-group-wrapper">
            <mat-form-field class="purchase-unit-form">
              <mat-label>{{'ADD_ITEMS.PURCHASE_UNIT' | translate }}</mat-label>
              <input
                id="purchaseUnitDescription"
                matInput
                placeholder="{{'ADD_ITEMS.PURCHASE_UNIT' | translate }}"
                type="text"
                autocomplete="off"
                class="custom-item-input"
                formControlName="purchaseUnit"
                [matAutocomplete]="purchaseUnitAuto"
                (blur)="onPurchaseUnitBlur()"
                [value]="lastValidPurchaseUnit"
              />
              <mat-autocomplete id="purchaseUnitAutocomplete" #purchaseUnitAuto="matAutocomplete" class="custom-item-auto">
                <mat-option *ngFor="let unit of filteredPurchaseUnits$ | async" [value]="unit" class="custom-item-mat-option">
                  {{ unit }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="radio-group-wrapper">
              <label class="radio-group-label">{{'ADD_ITEMS.PRICED_BY_WEIGHT' | translate}}</label>
              <mat-radio-group id="catch-weight-radio-button-group"
                attr.aria-label="{{ 'ADD_ITEMS.PRICED_BY_WEIGHT' | translate }}"
                [value]="isPricedByWeight"
                formControlName="pricedByWeight"
                (change)="onPricedByWeightSelect($event)"
              >
                <div id="catch-weight-radio-buttons">
                  <mat-radio-button id="catch-weight-radio-button-yes" [value]=true>
                    {{'ADD_ITEMS.YES' | translate}}
                  </mat-radio-button>
                  <mat-radio-button id="catch-weight-radio-button-no" [value]=false>
                    {{'ADD_ITEMS.NO' | translate}}
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <div class="catch-weight-wrapper">
              <div id="not-priced-by-weight-section" [hidden]="isPricedByWeight" class="price-per-unit">
                <mat-form-field class="catch-weight-price-form">
                  <mat-label>{{'ADD_ITEMS.PURCHASE_PRICE' | translate }}</mat-label>
                  <span [hidden]="!shouldShowDollarSign('purchase-price-input')"
                    [ngClass]="{'currency-symbol': true, 'right-side-symbol': (currentLang$ | async) === 'fr_CA'}">
                    $
                  </span>
                  <input
                    matInput
                    appTwoDigitDecimalNumber
                    [regex]="purchaseInputRegex.INPUT_REGEX"
                    placeholder="{{'ADD_ITEMS.PURCHASE_PRICE' | translate }}"
                    type="text"
                    autocomplete="off"
                    class="custom-item-input"
                    [ngStyle]="{'padding': (currentLang$ | async) === 'fr_CA' ? '0 13px 0 0' : '0 0 0 13px'}"
                    id="purchase-price-input"
                    formControlName="purchasePrice"
                    (blur)="markFormAsUntouched(purchaseUnitForm.controls.purchasePrice)"
                  />
                </mat-form-field>
                <div class="purchase-unit-forward-slash">/</div>
                <div *ngFor="let unit of filteredPurchaseUnits$ | async" class="price-per-unit-divisor">
                  {{ unit }}
                </div>
              </div>
            </div>
            <div class="catch-weight-wrapper">
              <div id="priced-by-weight-section" [hidden]="!isPricedByWeight" class="price-per-unit">
                <mat-form-field class="catch-weight-price-form">
                  <span [hidden]="!shouldShowDollarSign('catch-weight-price-input')"
                    [ngClass]="{'currency-symbol': true, 'right-side-symbol': (currentLang$ | async) === 'fr_CA'}">
                    $
                  </span>
                  <input
                    matInput
                    appTwoDigitDecimalNumber
                    [regex]="purchaseInputRegex.INPUT_REGEX"
                    placeholder="{{'ADD_ITEMS.PRICE' | translate }}"
                    type="text"
                    autocomplete="off"
                    class="custom-item-input"
                    [ngStyle]="{'padding': (currentLang$ | async) === 'fr_CA' ? '0 13px 0 0' : '0 0 0 13px'}"
                    id="catch-weight-price-input"
                    formControlName="catchWeightPrice"
                    (blur)="markFormAsUntouched(purchaseUnitForm.controls.catchWeightPrice)"
                  />
                </mat-form-field>
                <div class="purchase-unit-forward-slash">/</div>
                <div id="priced-by-weight-divisor" class="price-per-weight-divisor">
                  <mat-form-field
                    id="net-weight-unit-field"
                    *ngIf="isPricedByWeight"
                    class="catch-weight-unit-form">
                  <mat-label>{{'ADD_ITEMS.UNIT' | translate }}</mat-label>
                  <input
                      matInput
                      placeholder="{{'ADD_ITEMS.UNIT' | translate }}"
                      type="text"
                      autocomplete="off"
                      class="custom-item-input"
                      formControlName="catchWeightUnit"
                      [matAutocomplete]="weightUnitAuto"
                      (blur)="onCatchWeightUnitBlur($event)"
                    />
                    <mat-autocomplete #weightUnitAuto="matAutocomplete" class="custom-item-auto" [displayWith]="unitIdToDisplayName.bind( this, (currentLang$ | async),  (weightUnits$ | async))" >
                      <mat-option *ngFor="let unit of filteredWeightUnits$ | async"
                                  [value]="unit.id"
                                  class="custom-item-mat-option"
                      >
                        {{ getUnitDisplayName(unit, (currentLang$ | async)) }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <mat-error *ngIf="purchaseUnitForm.controls.catchWeightPrice.invalid" class="catch-weight-price-error">{{'ADD_ITEMS.INVALID_PRICE_FORMAT' | translate }}</mat-error>
              </div>
              <mat-form-field class="net-weight-form">
                <input
                  matInput
                  appTwoDigitDecimalNumber
                  [regex]="purchaseInputRegex.INPUT_REGEX"
                  placeholder="{{'ADD_ITEMS.NET_WEIGHT' | translate }}"
                  autocomplete="off"
                  class="custom-item-input"
                  id="net-weight-input"
                  formControlName="netWeight"
                  (blur)="onNetWeightBlur()"
                  [value]="purchaseUnitForm?.controls.netWeight.value"
                />
                <mat-error *ngIf="purchaseUnitForm.controls.netWeight.invalid">{{'ADD_ITEMS.INVALID_WEIGHT_FORMAT' | translate }}</mat-error>
                <span [hidden]="!shouldShowWeightSymbol('net-weight-input')" class="weight-unit-symbol">
                  {{ getLocalizedUnitSymbol(this.lastValidCatchWeightUnit, (currentLang$ | async)) }}
                </span>
              </mat-form-field>
              <mat-form-field
                id="not-by-weight-net-weight-unit-field"
                *ngIf="!isPricedByWeight"
                class="catch-weight-unit-form">
                <mat-label>{{'ADD_ITEMS.UNIT' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{'ADD_ITEMS.UNIT' | translate }}"
                  type="text"
                  autocomplete="off"
                  class="custom-item-input"
                  formControlName="catchWeightUnit"
                  [matAutocomplete]="weightUnitAuto"
                  (blur)="onCatchWeightUnitBlur($event)"
                />
                <mat-autocomplete #weightUnitAuto="matAutocomplete" class="custom-item-auto" [displayWith]="unitIdToDisplayName.bind( this, (currentLang$ | async),  (weightUnits$ | async))">
                  <mat-option *ngFor="let unit of filteredWeightUnits$ | async"
                              [value]="unit.id"
                              class="custom-item-mat-option"
                  >
                    {{ getUnitDisplayName(unit, (currentLang$ | async)) }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [hidden]="!(isInventoryRole$ | async)" class="custom-item-panel" [expanded]="countingUnitExpanded">
      <mat-expansion-panel-header class="custom-item-panel-header" [collapsedHeight]="'40px'" [expandedHeight]="'40px'"
        (click)="countingUnitExpanded = !countingUnitExpanded">
        <mat-panel-title>
          <div class="panel-title">
            {{'ADD_ITEMS.INVENTORY_INFORMATION' | translate | uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mat-panel-sub-text">{{'ADD_ITEMS.COUNTING_UNIT_SUB_TEXT' | translate}}</div>
      <gfs-counting-unit-form
        [isEdit]="isEdit"
        [item]="countableItem"
        [isAllowedToEdit]="isAllowedToEdit"
        (addOrEditItem)="isEdit ? onEditItem($event) : onAddItem($event)"
        #countingUnitFormComponent
      ></gfs-counting-unit-form>
      <div class="mat-panel-sub-text" *ngIf="isAdd">{{'ADD_ITEMS.ADD_TO_WORSHEET_SUB_TEXT' | translate}}</div>
      <form [formGroup]="addToWorksheetForm" *ngIf="isAdd">
        <div class="add-to-worksheet-check">
          <mat-checkbox formControlName="addToWorksheet">
            {{'ADD_ITEMS.ADD_THIS_ITEM' | translate}}
          </mat-checkbox>
        </div>
        <mat-form-field
          [hidden]="!addToWorksheetForm.controls.addToWorksheet.value"
          class="item-info-form item-storage-area-form">
          <gfs-add-element-autocomplete
            fieldName="storage-area"
            inputClass="custom-item-input"
            [placeholder]="'ADD_ITEMS.STORAGE_AREA' | translate"
            formControlName="storageArea"
            optionsValue="name"
            [valueParser]="storageAreaTranslate()"
            [addElementLabel]="'ADD_ITEMS.CUSTOM_STORAGE_AREA' | translate"
            [options]="filteredStorageAreas$ | async"
            (addElement)="createStorageArea($event)"
            [isNullable]="false"
            [canCreateNew]="canEditStorageArea((worksheet$ | async), (pk$ | async))"
          ></gfs-add-element-autocomplete>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="isRecipeRole$ | async" class="custom-item-panel unit-of-measure-panel" [expanded]="unitOfMeasureExpanded">
      <mat-expansion-panel-header class="custom-item-panel-header" [collapsedHeight]="'40px'" [expandedHeight]="'40px'"
        (click)="unitOfMeasureExpanded = !unitOfMeasureExpanded">
        <mat-panel-title>
          <div class="panel-title">
            {{'ADD_ITEMS.RECIPE_INFORMATION' | translate | uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <gfs-unit-of-measure-form
        [measurementUnitForm]="purchaseUnitForm"
        [isAllowedToEdit]="isAllowedToEdit"
        [customItemData$]="customItemData$"
        (weightUnitBlur)="onCatchWeightUnitBlur($event)"
        (netWeightBlur)="onNetWeightBlur()"
      ></gfs-unit-of-measure-form>
    </mat-expansion-panel>
    
  </div>
  <div class="modal-button-bar" [ngStyle]="{'margin-bottom': isIos ? '120px' : '0px' }">
    <button *ngIf="!isEdit && !isManage"
      class="secondary-button large-button"
      type="button"
      (click)="goBack(false)"
      attr.aria-label="{{ 'MODALS.BACK' | translate}}">
      {{ 'MODALS.BACK' | translate }}
    </button>
    <button *ngIf="isEdit || isManage"
      class="secondary-button large-button"
      type="button"
      (click)="onClose()"
      attr.aria-label="{{ 'MODALS.CANCEL' | translate}}">
      {{ 'MODALS.CANCEL' | translate }}
    </button>
    <button *ngIf="!isEdit"
      class="primary-button large-button submit-add-custom-item"
      type="button"
      (click)="countingUnitFormComponent?.createOrUpdateCustomUnits(isEdit)"
      attr.aria-label="{{ 'ADD_ITEMS.ADD_ITEM' | translate}}"
      [disabled]="itemInfoForm?.invalid || countingUnitFormComponent?.countingUnitForm.invalid || purchaseUnitForm?.invalid || (isAddingItem$ | async)">
      {{ 'ADD_ITEMS.ADD_ITEM' | translate }}
    </button>
    <button *ngIf="isEdit"
      class="primary-button large-button submit-add-custom-item"
      type="button"
      (click)="countingUnitFormComponent.createOrUpdateCustomUnits(isEdit)"
      attr.aria-label="{{ 'MODALS.SAVE' | translate }}"
      [disabled]="itemInfoForm?.invalid || countingUnitFormComponent?.countingUnitForm.invalid || purchaseUnitForm?.invalid || (isAddingItem$ | async)">
      {{ 'MODALS.SAVE' | translate }}
    </button>
  </div>
</div>
<gfs-loading-overlay-end/>
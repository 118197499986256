import { Injectable } from '@angular/core';
import { Observable, interval, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, concatMap, timeout, catchError, retry } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SetRetryingConnection } from '../../../common/actions/network.actions';
import { IFeatureStateFacade } from '../../../common/reducer/network.reducer';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class OnlineHeartbeatService {

  private heartbeat: Subject<boolean>;
  private heartbeatUrl: string;
  private httpActivityDetected: boolean;

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private store: Store<IFeatureStateFacade>
  ) {
    const config = this.configService.getSettings();
    this.heartbeatUrl = config.APP_BASE_URL + '/heartbeat';

    // we need to set this observable up to have a Subject as the subscriber so that we can multicast the result
    this.heartbeat = new Subject<boolean>();
    if (this.configService.getSettings().FF_OFFLINE_MODE) {
      interval(10000)
        .pipe(
          concatMap((_) => this.beat()),
          map((result) => result === 'alive')
        )
        .subscribe(this.heartbeat);
    } else {
      this.heartbeat.next(true);
    }
  }

  public getPulse(): Subject<boolean> {
    return this.heartbeat;
  }
  public notifyOfHttpActivity(): void {
    this.httpActivityDetected = true;
  }
  private beat(): Observable<string> {
    if (this.httpActivityDetected) {
      console.log('heartbeat: http has been active');
      this.httpActivityDetected = false;
      return of('alive');
    }
    this.store.dispatch(new SetRetryingConnection(true));
    return this.http
      .get<string>(this.heartbeatUrl, { responseType: 'text' as 'json' })
      .pipe(
        timeout(5000),
        retry(1),
        catchError((err) => of(null))
      );
  }
}

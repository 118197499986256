import { GenericRecipeStoreUpdate } from '@gfs/store/recipe/actions/recipe.actions';
import * as lodash from 'lodash';
import { ImageData } from '@gfs/shared-models';

export function entityDictionaryToArray<TOut>(selector: any): TOut[] {
  const data = lodash.cloneDeep(selector ?? {});
  return Object.keys(data).map((k) => data[k]);
}

export function arrayToEntityDictionary<TModel>(
  source: TModel[],
  keyOf: (entity: TModel) => string | number
): { [s: string]: TModel; } {
  return arrayToEntityDictionaryWithTransformation(source, keyOf);
}

export function arrayToEntityDictionaryWithTransformation<TModel, TValue>(
  source: TModel[],
  keyOf: (entity: TModel) => string | number,
  transform: (entity: TModel) => TValue = (entity) => ({ ...entity } as any as TValue)
): { [s: string]: TValue; } {
  return source.reduce((accum, next) => ({
    ...accum,
    [keyOf(next)]: transform(next)
  }), {} as { [s: string]: TValue; });
}

export interface MethodBucket<TModel> {
  post: TModel[];
  patch: TModel[];
  put: TModel[];
  delete: TModel[];
}

export function entityHttpMethodBucket_inner<TModel>(
  isPost: (entity: TModel) => boolean,
  isPatch: (entity: TModel) => boolean,
  isPut: (entity: TModel) => boolean,
  isDelete: (entity: TModel) => boolean
): (data: TModel[]) => [TModel[], TModel[], TModel[], TModel[]] {
  return (source: TModel[]) => ([source.filter(isPost),
  source.filter(isPatch),
  source.filter(isPut),
  source.filter(isDelete)]);
}

export function entityHttpMethodBucket<TModel>(
  isPost: (entity: TModel) => boolean,
  isPatch: (entity: TModel) => boolean,
  isPut: (entity: TModel) => boolean,
  isDelete: (entity: TModel) => boolean
): (data: TModel[]) => MethodBucket<TModel> {
  return (source: TModel[]) => {
    const [post, patch, put, $delete] = entityHttpMethodBucket_inner<TModel>(isPost, isPatch, isPut, isDelete)(source);
    return {
      post,
      patch,
      put,
      delete: $delete
    };
  };
}

export function createStatePatchByPath(
  state: any,
  action: GenericRecipeStoreUpdate
) {
  const newState = {
    ...state,
  };
  const newVal = action.payload.value;
  const valPath = action.payload.valuePath.split('.');

  const outVal = valPath.reduce((curr, nextPathPart, idx) => {
    curr[nextPathPart] = {
      ...curr[nextPathPart],
    };
    if (idx === valPath.length - 1) {
      curr[nextPathPart] = newVal;
    }
    return curr[nextPathPart];
  }, newState);

  console.log('updated value @ %s: %o', action.payload.valuePath, outVal);

  return newState;
}

export const sanitizeIds = <TModel extends { id: string }>(models: TModel[], sanitizeTokens: string[]): TModel[] =>
  models?.map(model => {
    const id = sanitizeTokens.some(token => model.id?.startsWith(token)) ? '' : model.id ?? '';
    return { ...model, id };
  });

export const mapOrdinal = <TModel extends { ordinal: number }>(models: TModel[]): TModel[] =>
  models.map((model, ordinal) => ({ ...model, ordinal }));

export const getLastImage = <TModel extends { recipeImageSignedUrls?: ImageData[] }>(model: TModel): string => {
  const images = model?.recipeImageSignedUrls;
  return !images
    ? undefined
    : images
      .filter(b => !!b.resourceURI)
      .map(b => b.resourceURI)
      .reduce((_, curr) => curr, undefined);
};

export const sort = <TModel extends {}>(collection: TModel[], predicate: (model: TModel) => string): TModel[] => {
  const sort = [...collection];
  return sort.sort((next, curr) => predicate(curr).toUpperCase() > predicate(next).toUpperCase() ? -1 : 1);
};

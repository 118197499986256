import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Constants } from '@gfs/constants';
import { RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';
import { AppConfigService, WINDOW } from '@gfs/shared-services';

@Injectable({
  providedIn: 'root'
})
export class AppConfigGuard {

  constructor(
    private appConfig: AppConfigService,
    private router: Router,
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) private recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    @Inject(WINDOW) public window: Window,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const keys: string[] = route.data.featureFlags || [];
    const isActive = !!keys.length && keys.every(key => this.isFeatureFlagActive(key));

    const url = this.getCustomerSelectionUrl(this.recipeProfitCalculatorConfig);
    return isActive || this.router.parseUrl(url);
  }

  isFeatureFlagActive(flagKey: string): boolean {
    const flag = this.appConfig.getSettings()[flagKey];
    const isFeatureFlag = typeof flag === 'boolean';
    return !!(isFeatureFlag && flag);
  }

  getCustomerSelectionUrl(
    recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig
  ): string {
    return recipeProfitCalculatorConfig.isNavigatingFromCalculator(this.window)
      ? recipeProfitCalculatorConfig.RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH
      : Constants.SharedFeaturePaths.CUSTOMER_SELECTION_PATH;
  }
}

import { Component } from '@angular/core';
import { AppConfigService } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gfs-try-new-reporting-platform',
  templateUrl: './try-new-reporting-platform.component.html',
  styleUrls: ['./try-new-reporting-platform.component.css']
})
export class TryNewReportingPlatformComponent {
  constructor(
    public translate: TranslateService,
    public configService: AppConfigService
  ) { }
}

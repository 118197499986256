import { Injectable } from '@angular/core';
import { CustomerPK } from '@gfs/shared-models';


@Injectable({
    providedIn: 'root',
})
export class CustomerUtilService {
    static readonly salesOrg_ca = "3000";
    static readonly salesOrg_us = "1000";

    public isUsCustomer(
        pk: CustomerPK
    ): boolean {
        return pk?.salesOrg === CustomerUtilService.salesOrg_us;
    }
}

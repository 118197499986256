import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SetItemFilterText } from '@gfs/store/inventory/actions/worksheets.actions';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject ,debounceTime ,distinctUntilChanged} from 'rxjs';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gfs-item-search-bar',
  templateUrl: './item-search-bar.component.html',
  styleUrls: ['./item-search-bar.component.scss']
})
export class ItemSearchBarComponent implements OnInit, OnDestroy , AfterViewInit {
  @Input() searchText: string;
  @Output() enterSearch = new EventEmitter();
  isMobile$: Observable<boolean> = this.store.select(state => state.layout.isMobile);
  isOnline$: Observable<boolean> = this.store.select(state => state.network.isOnline);
  constructor(private store: Store<AppState>) { }

  notifier = new Subject<void>();

  searchTermSubject = new BehaviorSubject('');
  searchTermChange = new BehaviorSubject('');


  ngOnInit(): void {
    this.searchTermSubject
      .asObservable()
      .pipe(
        startWith(''),
        takeUntil(this.notifier),
        tap((filterText) => {
          this.enterSearch.emit(filterText);
          this.store.dispatch(new SetItemFilterText(filterText));
        })
      )
      .subscribe();
  }
  oninputChange(value:string) {
    this.searchTermChange.next(value)
  }
  ngAfterViewInit() {
    this.searchTermChange.pipe(
      debounceTime(900),
        distinctUntilChanged(),
        takeUntil(this.notifier),
        tap((inputText:any) => {
          this.searchTermSubject.next(inputText)
          WorksheetEffects.itemSearchFilterText$.next(inputText)
        })
    ).subscribe()
}

  ngOnDestroy(): void {
    this.notifier.next();
    this.store.dispatch(new SetItemFilterText(''));
  }
}
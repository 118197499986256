import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';
import { LoadingModalComponent } from '@gfs/shared-components';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerOverlayService {
  defaultConfig = {
    id: InventoryConstants.LOADING_MODAL_ID,
    panelClass: 'recipe-mat-dialog',
    disableClose: true
  };

  /**
   * @description handle global loader overlay
   * @param dialogRef dialog reference
   */
  constructor(private dialog: MatDialog) { }
  show(config?: MatDialogConfig) {
    const dialogInstance = this.tryGetDialogInstance(config?.id);
    if (!dialogInstance) {
      this.dialog.open(LoadingModalComponent, config || this.defaultConfig);
    }
  }
  hide(id?: string) {
    const dialogInstance = this.tryGetDialogInstance(id);
    dialogInstance?.close();
  }

  private tryGetDialogInstance = (id?: string) => this.dialog.getDialogById(id || InventoryConstants.LOADING_MODAL_ID);
}

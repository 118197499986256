import { Action } from '@ngrx/store';

export enum ActionTypes {
  NavigateHome = '[Inventory] Navigate to Home Page'
}

export class NavigateHome implements Action {
  readonly type = ActionTypes.NavigateHome;
}

export type ActionUnion =
  | NavigateHome;

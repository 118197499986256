import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { WINDOW } from '@gfs/shared-services';
import { get } from 'lodash-es';

@Component({
  selector: 'gfs-select-menu',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.scss']
})
export class SelectMenuComponent {
  @Input() buttonId: string;
  @Input() optionIdField: string;
  @Input() buttonLabel: string;
  @Input() icon: string;
  @Input() disabled = false;
  @Input() options = [];
  @Input() optionLabelFn: any;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() hoverToOpen: boolean;
  @Input() isCloseOnMouseLeave: boolean;

  timeoutMs = 50;
  isMenuOpen = false;
  hoverMenuTimeoutId: number;

  constructor(
    @Inject(WINDOW) public window: Window
  ) { }

  selectOption(option: any) {
    if (option.action) {
      option.action();
      this.valueChange.emit(option);
    } else if (this.value !== option) {
      this.valueChange.emit(option);
    }
  }

  getOptionLabel(option: any) {
    return this.optionLabelFn ? this.optionLabelFn(option) : option;
  }

  getOptionId(option: any) {
    return this.optionIdField ? get(option, this.optionIdField) : option.id;
  }

  hoverOpenMenu(trigger: MatMenuTrigger) {
    if (this.hoverToOpen) {
      if (this.hoverMenuTimeoutId) {
        this.window.clearTimeout(this.hoverMenuTimeoutId);
      }
      trigger.openMenu();
    }
  }

  closeMenu(trigger: MatMenuTrigger) {
    if (this.isCloseOnMouseLeave) {
      trigger.closeMenu();
    }
  }

  hoverCloseMenu(trigger: MatMenuTrigger): void {
    if (this.hoverToOpen) {
      this.hoverMenuTimeoutId = this.setCloseMenuOnTimeout(trigger);
    }
  }

  setCloseMenuOnTimeout(trigger: MatMenuTrigger): number {
    const fn = () => { trigger.closeMenu(); };
    return this.window.setTimeout(fn, this.timeoutMs);
  }
}

<div id="modal-header" class="region">
  <div>
    {{ 'MODALS.INVENTORY_MIGRATION_MODAL.TITLE' | translate }}
  </div>
  <button
    class="close-button"
    (click)="closeModal({excapeWorkflow: true})"
    attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
    <img
      class="close-button-img"
      src="assets/images/close-icon.svg"
      alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}" />
  </button>
</div>

<div class="region">
  <div class="heading">
    {{ 'MODALS.INVENTORY_MIGRATION_MODAL.HEADING' | translate }}
  </div>
  <div>
      <div class="checkbox-list">
        <mat-checkbox
          color="primary"
          [(ngModel)]="viewmodel.migrateWorksheet"
          [disabled]="isWorksheetsCheckboxDisabled()"
          labelPosition="after">
          <div>
            <span class="bold">
              {{ 'MODALS.INVENTORY_MIGRATION_MODAL.MIGRATIONS_OPTIONS.INVENTORY_WORKSHEET.TITLE' | translate }}
            </span>
            <span>
              {{ 'MODALS.INVENTORY_MIGRATION_MODAL.MIGRATIONS_OPTIONS.INVENTORY_WORKSHEET.DESCRIPTION' | translate }}
            </span>
          </div>
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [(ngModel)]="viewmodel.migrateCustomItems"
          [disabled]="isCustomItemsCheckboxDisabled()"
          labelPosition="after">
          <div>
            <span class="bold">
              {{ 'MODALS.INVENTORY_MIGRATION_MODAL.MIGRATIONS_OPTIONS.NONGFS_ITEMS.TITLE' | translate }}
            </span>
            <span>
              {{ 'MODALS.INVENTORY_MIGRATION_MODAL.MIGRATIONS_OPTIONS.NONGFS_ITEMS.DESCRIPTION' | translate }}
            </span>
          </div>
        </mat-checkbox>
      </div>

      <div class="controls">
        <button
          type="button"
          (click)="closeModal()"
          attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
          {{ 'MODALS.INVENTORY_MIGRATION_MODAL.CANCEL' | translate}}
        </button>

        <button
          type="submit"
          (click)="submit()"
          [class]="canSubmit() ? 'primary' : null"
          [disabled]="!canSubmit()"
          attr.aria-label="{{ 'MODALS.INVENTORY_MIGRATION_MODAL.CONTINUE_BUTTON_ARIA_LABEL' | translate }}">
          {{ 'MODALS.INVENTORY_MIGRATION_MODAL.CONTINUE' | translate }}
        </button>
      </div>
  </div>
</div>
import { Inject, Injectable } from '@angular/core';
import { CustomerPK, Supplier, SupplierOrder } from '@gfs/shared-models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InjectionTokens } from '../../injection-tokens';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private INVENTORY_API_BASE_URL: string
  ) {
    this.baseUrl = INVENTORY_API_BASE_URL + '/api/v1';
  }

  createSupplier(customerPK: CustomerPK, name: string): Observable<Supplier> {
    return this.http.post<Supplier>(`${this.baseUrl}/suppliers`, { customerPK, name });
  }

  getSuppliers(customerPK: CustomerPK): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(`${this.baseUrl}/suppliers`, { params: { ...customerPK } }).pipe(
      mergeMap((suppliers: Supplier[]) => this.getSupplierOrders(customerPK).pipe(
        map((supplierOrder: SupplierOrder) =>
          suppliers.map(supplier => ({ ...supplier, ordinal: supplierOrder.order.indexOf(supplier.id) }))
        )
      )));
  }

  patchSupplier(supplier: Supplier): Observable<Supplier> {
    return this.http.patch<Supplier>(`${this.baseUrl}/suppliers/${supplier.id}`, supplier);
  }

  deleteSupplier(supplier: Supplier): Observable<Supplier> {
    return this.http.delete<Supplier>(`${this.baseUrl}/suppliers/${supplier.id}`);
  }

  getSupplierOrders(customerPK: CustomerPK): Observable<SupplierOrder> {
    return this.http.get<SupplierOrder>(`${this.baseUrl}/supplier-orders`, { params: { ...customerPK } });
  }

  putSupplierOrders(supplierOrder: SupplierOrder): Observable<SupplierOrder> {
    return this.http.put<SupplierOrder>(`${this.baseUrl}/supplier-orders`, supplierOrder);
  }
}

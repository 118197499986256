import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { SharedDirectivesModule } from '@gfs/directives';
import { PipeSharingModule, SharedComponentsModule } from '@gfs/shared-components';
import { InventoryIconService } from '@gfs/shared-services';
import { LocalizedUIV2Module, SharedComponentsV2Module } from '@gfs/v2/shared-components';
import { InventoryV2Module } from '@inventory-ui/v2/v2.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { InventoryWorksheetComponent } from './inventory-worksheet/inventory-worksheet.component';
import { PrintWorksheetDialogComponent } from './print-worksheet-dialog/print-worksheet-dialog.component';
import { InfiniteStorageAreaCardComponent } from './storage-area/infinite-storage-area-card/infinite-storage-area-card.component';
import { StorageAreaCardComponent } from './storage-area/storage-area-card/storage-area-card.component';
import { StorageAreaCreateModalComponent } from './storage-area/storage-area-create-modal/storage-area-create-modal.component';
import { StorageAreaItemPanelComponent } from './storage-area/storage-area-item-panel/storage-area-item-panel.component';
import { StorageAreaListComponent } from './storage-area/storage-area-list/storage-area-list.component';
import { StorageAreaSelectComponent } from './storage-area/storage-area-select/storage-area-select.component';

const { icons } = InventoryConstants;

@NgModule({
  imports: [
    MatDividerModule,
    MatButtonModule,
    MatOptionModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatIconModule,
    MatSelectModule,
    MatListModule,
    MatDialogModule,
    PipeSharingModule,
    RouterModule,
    MatCardModule,
    MatExpansionModule,
    TranslateModule.forChild(),
    CommonModule,
    BrowserModule,
    DragDropModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    SharedComponentsModule,
    NgxPermissionsModule.forRoot(),
    SharedDirectivesModule,
    LocalizedUIV2Module,
    SharedComponentsV2Module,
    InventoryV2Module
  ],
  declarations: [
    InventoryWorksheetComponent,
    StorageAreaListComponent,
    StorageAreaItemPanelComponent,
    StorageAreaCardComponent,
    InfiniteStorageAreaCardComponent,
    StorageAreaSelectComponent,
    StorageAreaCreateModalComponent,
    PrintWorksheetDialogComponent,
  ],
  exports: [InventoryWorksheetComponent]
})
export class InventoryWorksheetModule {
  readonly storageAreaIcons = Object.keys(icons).map(icon => icons[icon]);

  constructor(public inventoryIconService: InventoryIconService) {
    inventoryIconService.registerIcons(this.storageAreaIcons);
  }
}
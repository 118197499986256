import { Injectable } from '@angular/core';
import { Worksheet } from '@gfs/shared-models';

import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class InventoryUtilsService {
  constructor() { }
  static sortSummaries(sheets): Worksheet[] {
    return sortByDate(sheets, (x) => x.created);
  }

}

export const sortByDate = <TModel>(src: Array<TModel>, valueSelector: (x: TModel) => any, useAscendingOrder = true): TModel[] => {
  return Object.keys(src)
    .map((key) => src[key])
    .sort((A, B) => {
      const aValue = moment(valueSelector(A));
      const bValue = moment(valueSelector(B));

      if (useAscendingOrder) {
        return bValue.valueOf() - aValue.valueOf();
      }
      return aValue.valueOf() - bValue.valueOf();
    });
};

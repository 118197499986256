import { Action } from '@ngrx/store';
import {
  CountableItem,
  CustomItem,
  GeneralItem,
  ItemCategory,
  PurchaseUnit,
  MeasurementUnit,
  GfsItem,
  CustomItemData,
  PortioningUnit,
  Supplier,
  LocalizedValue,
  SupplierOrder,
} from '@gfs/shared-models';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchTabTypes } from './add-items.state';

export enum ActionTypes {
  GetAutocompleteResultsAttempt = '[Add Items] Get Autocomplete Attempt',
  GetAutocompleteResultsSuccess = '[Add Items] Get Autocomplete Success',
  GetAutocompleteResultsError = '[Add Items], Get Autocomplete Error',
  GetCountableItemsAttempt = '[Add Items] Get Countable Items Attempt',
  GetCountableItemsSuccess = '[Add Items] Get Countable Items Success',
  GetGeneralItemByCustomItemIdAttempt = '[Add Items] Get General Item By CustomItemId Attempt',
  GetGeneralItemByCustomItemIdSuccess = '[Add Items] Get General Item By CustomItemId Success',
  GetGeneralItemByCustomItemIdError = '[Add Items] Get General Item By CustomItemId Error',
  ClearCountableItems = '[Add Items] Clear Countable Items',
  GetProductInfoAttempt = '[Add Items] Get Product Info Attempt',
  GetProductInfoSuccess = '[Add Items] Get Product Info Success',
  GetProductInfoError = '[Add Items], Get Product Info Error',
  GetCountableItemsError = '[Add Items] Get Countable Items Error',
  CreateCustomItemAttempt = '[Add Items] Create Custom Item Attempt',
  CreateCustomItemSuccess = '[Add Items] Create Custom Item Success',
  CreateCustomItemError = '[Add Items] Create Custom Item Error',
  SaveCustomItemStart = '[Add Items] Save Custom Item Start',
  SaveCustomItemFinish = '[Add Items] Save Custom Item Finish',
  ClearCustomItem = '[Add Items] Clear custom item',
  CreateGeneralItemFromCustomItemAttempt = '[Add Items] Create General Item Attempt',
  CreateGeneralItemFromCustomItemSuccess = '[Add Items] Create General Item Success',
  CreateGeneralItemFromCustomItemError = '[Add Items] Create General Item Error',
  GetItemCategoriesAttempt = '[Add Items] Get Item Categories Attempt',
  GetItemCategoriesBeginLoading = '[Add Items] Get Item Categories Begin Loading',
  GetItemCategoriesSuccess = '[Add Items] Get Item Categories Success',
  GetItemCategoriesError = '[Add Items] Get Item Categories Error',
  CreateCategoryAttempt = '[Add Items] Create Item Category Attempt',
  CreateCategorySuccess = '[Add Items] Create Item Category Success',
  CreateCategoryError = '[Add Items] Create Item Category Error',
  GetMeasurementUnitsAttempt = '[Add Items] Get Measurement Units Attempt',
  GetMeasurementUnitsBeginLoading = '[Add Items] Get Measurement Units Begin Loading',
  GetMeasurementUnitsSuccess = '[Add Items] Get Measurement Units Success',
  GetMeasurementUnitsError = '[Add Items] Get Measurement Units Error',
  GetCustomItemDataAttempt = '[Add Items] Get Custom Item Data Attempt',
  GetCustomItemDataBeginLoading = '[Add Items] Get Custom Item Data Begin Loading',
  GetCustomItemDataSuccess = '[Add Items] Get Custom Item Data Success',
  GetCustomItemDataError = '[Add Items] Get Custom Item Data Error',
  CreateCustomItemDataAttempt = '[Add Items] Create Custom Item Data Attempt',
  CreateCustomItemDataSuccess = '[Add Items] Create Custom Item Data Success',
  CreateCustomItemDataError = '[Add Items] Create Custom Item Data Error',
  UpdateCustomItemDataAttempt = '[Add Items] Update Custom Item Data Attempt',
  UpdateCustomItemDataSuccess = '[Add Items] Update Custom Item Data Success',
  UpdateCustomItemDataError = '[Add Items] Update Custom Item Data Error',
  ClearCustomItemData = '[Add Items] Clear Custom Item Data',
  GetSuppliersAttempt = '[Add Items] Get Suppliers Attempt',
  GetSuppliersBeginLoading = '[Add Items] Get Suppliers Begin Loading',
  GetSuppliersSuccess = '[Add Items] Get Suppliers Success',
  GetSuppliersError = '[Add Items] Get Suppliers Error',
  CreateSupplierAttempt = '[Add Items] Create Supplier Attempt',
  CreateSupplierSuccess = '[Add Items] Create Supplier Success',
  CreateSupplierError = '[Add Items] Create Supplier Error',
  ClearEditItemProductInfo = '[Add Items] Clear Edit Item Product Info',
  ClearSupplierData = '[Add Items] Clear Supplier Data',
  GetSupplierOrderAttempt = '[Add Items] Get Supplier Order Attempt',
  GetSupplierOrderSuccess = '[Add Items] Get Supplier Order Success',
  GetSupplierOrderError = '[Add Items] Get Supplier Order Error',
  CreateSupplierOrderAttempt = '[Add Items] Create Supplier Order Attempt',
  CreateSupplierOrderSuccess = '[Add Items] Create Supplier Order Success',
  CreateSupplierOrderError = '[Add Items] Create Supplier Order Error',
  DeleteSupplierAttempt = '[Add Items] Delete Supplier Attempt',
  DeleteSupplierSuccess = '[Add Items] Delete Supplier Success',
  DeleteSupplierError = '[Add Items] Delete Supplier Error',
  PatchSupplierAttempt = '[Add Items] Update Supplier Attempt',
  PatchSupplierSuccess = '[Add Items] Update Supplier Success',
  PatchSupplierError = '[Add Items] Update Supplier Error'
}

export class GetItemCategoriesBeginLoading implements Action {
  readonly type = ActionTypes.GetItemCategoriesBeginLoading;
}

export class GetAutocompleteResultsAttempt implements Action {
  readonly type = ActionTypes.GetAutocompleteResultsAttempt;
  constructor(public payload: { locale: string, searchPrefix: string }) { }
}

export class GetAutocompleteResultsSuccess implements Action {
  readonly type = ActionTypes.GetAutocompleteResultsSuccess;
  constructor(
    public payload: { autocompleteSuggestions: string[]; searchLength: number }
  ) {}
}

export class GetAutocompleteResultsError implements Action {
  readonly type = ActionTypes.GetAutocompleteResultsError;
  constructor(public error: any) {}
}

export class GetProductInfoAttempt implements Action {
  readonly type = ActionTypes.GetProductInfoAttempt;
  constructor(public payload: string) {}
}

export class GetProductInfoSuccess implements Action {
  readonly type = ActionTypes.GetProductInfoSuccess;
  constructor(public payload: GfsItem) {}
}

export class GetProductInfoError implements Action {
  readonly type = ActionTypes.GetProductInfoError;
  constructor(public payload: any) {}
}

export class GetCountableItemsAttempt implements Action {
  readonly type = ActionTypes.GetCountableItemsAttempt;
  constructor(
    public payload: {
      locale: string;
      searchText: string;
      offset: number;
      limit: number;
      inventoryDate: string;
      isNewSearchTerm: boolean;
      searchType: SearchTabTypes;
    }
  ) {}
}

export class GetCountableItemsSuccess implements Action {
  readonly type = ActionTypes.GetCountableItemsSuccess;
  constructor(
    public payload: {
      searchText: string;
      searchType: SearchTabTypes;
      locale: string;
      items: CountableItem[];
      isNewSearchTerm: boolean;
    }
  ) {}
}

export class GetGeneralItemByCustomItemIdAttempt implements Action {
  readonly type = ActionTypes.GetGeneralItemByCustomItemIdAttempt;
  constructor(
    public payload: {
      customItemId: string;
    }
  ) {}
}

export class GetGeneralItemByCustomItemIdSuccess implements Action {
  readonly type = ActionTypes.GetGeneralItemByCustomItemIdSuccess;
  constructor(
    public payload: {
      customItemId: string;
      generalItem: GeneralItem;
    }
  ) {}
}

export class GetGeneralItemByCustomItemIdError implements Action {
  readonly type = ActionTypes.GetGeneralItemByCustomItemIdError;
  constructor(public error: any) {}
}

export class ClearCountableItems implements Action {
  readonly type = ActionTypes.ClearCountableItems;
}

export class GetCountableItemsError implements Action {
  readonly type = ActionTypes.GetCountableItemsError;
  constructor(public error: any) {}
}

export class CreateCustomItemAttempt implements Action {
  readonly type = ActionTypes.CreateCustomItemAttempt;
  constructor(public payload: {
    supplierItemCode: string,
    supplierId: string,
    description: string,
    purchaseUnit: PurchaseUnit,
    categoryId: string,
    worksheetId: string,
    storageAreaId: string,
    areaExpandStatus: boolean,
    successMessage: string,
    duplicate: boolean,
    customUnits: PortioningUnit[],
    recipeUnits: PortioningUnit[],
    addToWorksheet: boolean,
    isManage: boolean,
  }) { }
}

export class CreateCustomItemSuccess implements Action {
  readonly type = ActionTypes.CreateCustomItemSuccess;
  constructor(public payload: {
    customItem: CustomItem,
    categoryId: string,
    worksheetId: string,
    storageAreaId: string,
    areaExpandStatus: boolean,
    successMessage: string,
    duplicate: boolean,
    customUnits: PortioningUnit[],
    recipeUnits: PortioningUnit[],
    addToWorksheet: boolean,
    isManage: boolean
  }) { }
}

export class SaveCustomItemStart implements Action {
  readonly type = ActionTypes.SaveCustomItemStart;
}

export class SaveCustomItemFinish implements Action {
  readonly type = ActionTypes.SaveCustomItemFinish;
  constructor(public payload: CustomItem) { }
}

export class CreateCustomItemError implements Action {
  readonly type = ActionTypes.CreateCustomItemError;
  constructor(public error: HttpErrorResponse) {}
}

export class ClearCustomItem implements Action {
  readonly type = ActionTypes.ClearCustomItem;
}

export class CreateGeneralItemFromCustomItemAttempt implements Action {
  readonly type = ActionTypes.CreateGeneralItemFromCustomItemAttempt;
  constructor(public payload: {
    customItem: CustomItem,
    categoryId: string,
    worksheetId: string,
    storageAreaId: string,
    areaExpandStatus: boolean,
    successMessage: string,
    duplicate: boolean,
    addToWorksheet: boolean,
    isManage: boolean
  }) { }
}

export class CreateGeneralItemFromCustomItemSuccess implements Action {
  readonly type = ActionTypes.CreateGeneralItemFromCustomItemSuccess;
  constructor(public payload: {
    generalItem: GeneralItem,
    worksheetId: string,
    storageAreaId: string,
    areaExpandStatus: boolean,
    successMessage: string,
    duplicate: boolean,
    addToWorksheet: boolean,
    isManage: boolean
  }) { }
}

export class CreateGeneralItemFromCustomItemError implements Action {
  readonly type = ActionTypes.CreateGeneralItemFromCustomItemError;
  constructor(public error: HttpErrorResponse) {}
}

export class GetItemCategoriesAttempt implements Action {
  readonly type = ActionTypes.GetItemCategoriesAttempt;
}

export class GetItemCategoriesSuccess implements Action {
  readonly type = ActionTypes.GetItemCategoriesSuccess;
  constructor(
    public payload: { categories: ItemCategory[]; language: string }
  ) {}
}

export class GetItemCategoriesError implements Action {
  readonly type = ActionTypes.GetItemCategoriesError;
  constructor(public error: HttpErrorResponse) {}
}

export class CreateCategoryAttempt implements Action {
  readonly type = ActionTypes.CreateCategoryAttempt;
  constructor(public payload: { categoryName: LocalizedValue }) {}
}

export class CreateCategorySuccess implements Action {
  readonly type = ActionTypes.CreateCategorySuccess;
  constructor(public payload: { category: ItemCategory; language: string }) {}
}

export class CreateCategoryError implements Action {
  readonly type = ActionTypes.CreateCategoryError;
  constructor(public error: HttpErrorResponse) {}
}

export class GetMeasurementUnitsAttempt implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsAttempt;
}

export class GetMeasurementUnitsBeginLoading implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsBeginLoading;
}

export class GetMeasurementUnitsSuccess implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsSuccess;
  constructor(public units: MeasurementUnit[]) {}
}

export class GetMeasurementUnitsError implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsError;
  constructor(public error: HttpErrorResponse) { }
}

export class GetCustomItemDataBeginLoading implements Action {
  readonly type = ActionTypes.GetCustomItemDataBeginLoading;
}

export class GetCustomItemDataAttempt implements Action {
  readonly type = ActionTypes.GetCustomItemDataAttempt;
  constructor(public payload: { itemId: string; itemType: string }) {}
}

export class GetCustomItemDataSuccess implements Action {
  readonly type = ActionTypes.GetCustomItemDataSuccess;
  constructor(public customItemData: CustomItemData[]) {}
}

export class GetCustomItemDataError implements Action {
  readonly type = ActionTypes.GetCustomItemDataError;
  constructor(public error: HttpErrorResponse) {}
}

export class CreateCustomItemDataAttempt implements Action {
  readonly type = ActionTypes.CreateCustomItemDataAttempt;
  constructor(
    public payload: {
      itemId: string;
      itemType: string;
      countingUnits: PortioningUnit[];
      recipeUnits: PortioningUnit[];
      categoryId: string;
      purchasedByUnit: boolean;
      purchaseUnit: PurchaseUnit;
    }
  ) {}
}

export class CreateCustomItemDataSuccess implements Action {
  readonly type = ActionTypes.CreateCustomItemDataSuccess;
  constructor(public customItemData: CustomItemData) {}
}

export class CreateCustomItemDataError implements Action {
  readonly type = ActionTypes.CreateCustomItemDataError;
  constructor(public error: HttpErrorResponse) {}
}

export class UpdateCustomItemDataAttempt implements Action {
  readonly type = ActionTypes.UpdateCustomItemDataAttempt;
  constructor(
    public payload: {
      customItemDataId: string;
      countingUnits: PortioningUnit[];
      recipeUnits: PortioningUnit[];
      categoryId: string;
      purchasedByUnit: boolean;
      purchaseUnit: PurchaseUnit;
    }
  ) {}
}

export class UpdateCustomItemDataSuccess implements Action {
  readonly type = ActionTypes.UpdateCustomItemDataSuccess;
  constructor(public customItemData: CustomItemData) {}
}

export class UpdateCustomItemDataError implements Action {
  readonly type = ActionTypes.UpdateCustomItemDataError;
  constructor(public error: HttpErrorResponse) {}
}

export class ClearCustomItemData implements Action {
  readonly type = ActionTypes.ClearCustomItemData;
}

export class GetSuppliersAttempt implements Action {
  readonly type = ActionTypes.GetSuppliersAttempt;
}

export class GetSuppliersBeginLoading implements Action {
  readonly type = ActionTypes.GetSuppliersBeginLoading;
}

export class GetSuppliersSuccess implements Action {
  readonly type = ActionTypes.GetSuppliersSuccess;
  constructor(public payload: { suppliers: Supplier[] }) {}
}

export class GetSuppliersError implements Action {
  readonly type = ActionTypes.GetSuppliersError;
  constructor(public error: HttpErrorResponse) {}
}

export class CreateSupplierAttempt implements Action {
  readonly type = ActionTypes.CreateSupplierAttempt;
  constructor(public payload: { name: string }) {}
}

export class CreateSupplierSuccess implements Action {
  readonly type = ActionTypes.CreateSupplierSuccess;
  constructor(public supplier: Supplier) {}
}

export class CreateSupplierError implements Action {
  readonly type = ActionTypes.CreateSupplierError;
  constructor(public error: HttpErrorResponse) {}
}

export class ClearEditItemProductInfo implements Action {
  readonly type = ActionTypes.ClearEditItemProductInfo;
}

export class ClearSupplierData implements Action {
  readonly type = ActionTypes.ClearSupplierData;
}

export class GetSupplierOrderAttempt implements Action {
  readonly type = ActionTypes.GetSupplierOrderAttempt;
}

export class GetSupplierOrderSuccess implements Action {
  readonly type = ActionTypes.GetSupplierOrderSuccess;
  constructor(public payload: { supplierOrder: SupplierOrder }) {}
}

export class GetSupplierOrderError implements Action {
  readonly type = ActionTypes.GetSupplierOrderError;
  constructor(public error: any) {}
}

export class CreateSupplierOrderAttempt implements Action {
  readonly type = ActionTypes.CreateSupplierOrderAttempt;
  constructor(public payload: { supplierOrder: SupplierOrder }) {}
}

export class CreateSupplierOrderSuccess implements Action {
  readonly type = ActionTypes.CreateSupplierOrderSuccess;
  constructor(public payload: { suppliers: Supplier[], supplierOrder: SupplierOrder }) {}
}

export class CreateSupplierOrderError implements Action {
  readonly type = ActionTypes.CreateSupplierOrderError;
  constructor(public error: any) {}
}

export class DeleteSupplierAttempt implements Action {
  readonly type = ActionTypes.DeleteSupplierAttempt;
  constructor(public payload: { supplier: Supplier }) {}
}

export class DeleteSupplierSuccess implements Action {
  readonly type = ActionTypes.DeleteSupplierSuccess;
  constructor(public payload: { suppliers: Supplier[], supplier: Supplier } ) {}
}

export class DeleteSupplierError implements Action {
  readonly type = ActionTypes.DeleteSupplierError;
  constructor(public error: any) {}
}

export class PatchSupplierAttempt implements Action {
  readonly type = ActionTypes.PatchSupplierAttempt;
  constructor(public payload: { supplier: Supplier }) {}
}

export class PatchSupplierSuccess implements Action {
  readonly type = ActionTypes.PatchSupplierSuccess;
  constructor(public payload: { suppliers: Supplier[], supplier: Supplier }) {}
}

export class PatchSupplierError implements Action {
  readonly type = ActionTypes.PatchSupplierError;
  constructor(public error: any) {}
}

export type ActionUnion =
  GetAutocompleteResultsAttempt |
  GetAutocompleteResultsSuccess |
  GetAutocompleteResultsError |
  GetCountableItemsAttempt |
  GetCountableItemsSuccess |
  GetGeneralItemByCustomItemIdAttempt |
  GetGeneralItemByCustomItemIdSuccess |
  GetGeneralItemByCustomItemIdError |
  ClearCountableItems |
  GetCountableItemsError |
  GetProductInfoAttempt |
  GetProductInfoError |
  GetProductInfoSuccess |
  CreateCustomItemAttempt |
  CreateCustomItemSuccess |
  SaveCustomItemStart |
  SaveCustomItemFinish |
  CreateCustomItemError |
  ClearCustomItem |
  CreateGeneralItemFromCustomItemAttempt |
  CreateGeneralItemFromCustomItemSuccess |
  CreateGeneralItemFromCustomItemError |
  GetItemCategoriesAttempt |
  GetItemCategoriesSuccess |
  GetItemCategoriesError |
  CreateCategoryAttempt |
  CreateCategorySuccess |
  CreateCategoryError |
  GetMeasurementUnitsAttempt |
  GetMeasurementUnitsBeginLoading |
  GetMeasurementUnitsSuccess |
  GetMeasurementUnitsError |
  GetCustomItemDataAttempt |
  GetCustomItemDataSuccess |
  GetCustomItemDataError |
  CreateCustomItemDataAttempt |
  CreateCustomItemDataSuccess |
  CreateCustomItemDataError |
  UpdateCustomItemDataAttempt |
  UpdateCustomItemDataSuccess |
  UpdateCustomItemDataError |
  ClearCustomItemData |
  GetSuppliersAttempt |
  GetSuppliersSuccess |
  GetSuppliersError |
  CreateSupplierAttempt |
  CreateSupplierSuccess |
  CreateSupplierError |
  ClearEditItemProductInfo |
  ClearSupplierData |
  GetItemCategoriesBeginLoading |
  GetCustomItemDataBeginLoading |
  GetSuppliersBeginLoading |
  GetSupplierOrderAttempt |
  GetSupplierOrderSuccess |
  GetSupplierOrderError |
  CreateSupplierOrderAttempt |
  CreateSupplierOrderSuccess |
  CreateSupplierOrderError |
  DeleteSupplierAttempt |
  DeleteSupplierSuccess |
  DeleteSupplierError |
  PatchSupplierAttempt |
  PatchSupplierSuccess |
  PatchSupplierError
  ;

import { Action } from '@ngrx/store';

export enum ActionTypes {
  StartOnlineOfflineCheck = '[Network] StartOnlineOfflineCheck',
  SetIsOnline = '[Network] SetIsOnline',
  SetRetryingConnection = '[Network] SetRetryingConnection'
}

export class StartOnlineOfflineCheck implements Action {
  readonly type = ActionTypes.StartOnlineOfflineCheck;
}

export class SetIsOnline implements Action {
  readonly type = ActionTypes.SetIsOnline;

  constructor(public payload: boolean) {}
}

export class SetRetryingConnection implements Action {
  readonly type = ActionTypes.SetRetryingConnection;

  constructor(public payload: boolean) {}
}

export type ActionUnion = StartOnlineOfflineCheck | SetIsOnline | SetRetryingConnection;

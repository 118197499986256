import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function duplicateNameValidator(forbiddenNames: string[], exceptionToForbidden: string = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isInvalid = forbiddenNames
            .filter(name => name.toLowerCase().trim() !== exceptionToForbidden?.toLowerCase()?.trim())
            .findIndex(name => name.toLowerCase().trim() === control?.value?.toLowerCase().trim()) > -1;
        if (isInvalid) {
            return { invalidEntityNameValidator: true };
        }
        return null;
    };
}

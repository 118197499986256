<div>
  <ng-container *ngIf="viewmodel">
    <mat-table mat-table [dataSource]="viewmodel.maintainGeneralLedgerSection.glAccounts">
      <ng-container matColumnDef="glCode">
        <mat-header-cell scope="col" *matHeaderCellDef>{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_CODE' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let account">{{ account.glCode }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell scope="col" *matHeaderCellDef id='header_dis'>{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_DESCRIPTION' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let account" id='col_description'>{{ account.description }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="tripleDots">
        <mat-header-cell scope="col" *matHeaderCellDef class="header-columns tripledot">

        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="summary-cell tripledot" id='tripledot-cell'>
          <div *ngIf="!(isAllowedToEdit(row) | async)" class="item-tag chain-item-tag">
            <span class="item-tag-text">{{'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.CHAIN_CODE' | translate }}</span>
          </div>
          <button *ngIf="(isAllowedToEdit(row) | async)"
            mat-icon-button [matMenuTriggerFor]="glaccountMenu"
                  attr.aria-label="{{'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.TRIPLE_DOT_MENU_OPTIONS' | translate }}">
            <mat-icon svgIcon="triple-dot-menu"></mat-icon>
          </button>
          <mat-menu class='glaccount-tripledot-menu' #glaccountMenu>
            <button id='glaccount-menu-edit' mat-menu-item
                    (click)='onEditGlCode(row)'>
              {{ ('INVENTORY.TRIPLE_DOT_MENU_EDIT' | translate)
              }}</button>
            <button  id="glaccount-menu-delete" mat-menu-item
                     (click)="ondeleteAccount(row)">{{ 'INVENTORY.TRIPLE_DOT_MENU_DELETE' | translate }}</button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </ng-container>

</div>

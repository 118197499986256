import { Injectable } from '@angular/core';
import { ICustomerUnitSelectionFeatureBridge } from '@gfs/store/feature/customer-unit-selection/context';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class CustomerUnitSelectionFactoryService {

  constructor(private store: Store<AppState>) { }

  public static factory(factory: CustomerUnitSelectionFactoryService) {
    return factory.Build();
  }

  Build(): ICustomerUnitSelectionFeatureBridge {
    return {
      selectedEntitlement: this.store.select((state) => {
        if (state.auth.pk && state.auth.user) {
          return state.auth.user.entitlements.find(
            (ent) => ent.customerPK.customerId === state.auth.pk.customerId
          );
        }
        return null;
      }),
      cartsQuantities: this.store.select((state) => state.cartFeature.cartsQuantities),
    };
  }
}

<gfs-edit-gfs-item *ngIf="isGfsItem"
  [countableItem]="item"
  [focusSection]="focusSection"
  [isEdit]="true"
  [worksheetItemToEdit]="worksheetItemToEdit"
  [currentStorageAreaId]="currentStorageAreaId"
  (closeEmitter)="close()"
></gfs-edit-gfs-item>
<gfs-add-custom-item *ngIf="!isGfsItem"
  [countableItem]="item"
  [focusSection]="focusSection"
  [isEdit]="true"
  [isManage]="isManage"
  [currentStorageAreaId]="currentStorageAreaId"
  [worksheetItemToEdit]="worksheetItemToEdit"
  (closeEmitter)="close()"
></gfs-add-custom-item>

import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorksheetItem } from '@gfs/shared-models';
import { InventoryValueService, LocalizedValueUtilsService, StorageAreaUtilsService } from '@gfs/shared-services';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollListComponent, InfiniteScrollOptions } from 'libs/shared-components/src/v2/core/infinite-scroll-list/infinite-scroll-list.component';
import { UnitsHttpService } from 'libs/shared-services/src/v2/services/unit/unit-http-service.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { StorageAreaCardComponent } from '../storage-area-card/storage-area-card.component';

@Component({
  selector: 'app-infinite-storage-area-card',
  templateUrl: './infinite-storage-area-card.component.html',
  styleUrls: ['./infinite-storage-area-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteStorageAreaCardComponent
  extends StorageAreaCardComponent
  implements AfterViewInit, OnDestroy {

  constructor(
    private changeDetector: ChangeDetectorRef,
    store: Store<AppState>,
    storageAreaUtils: StorageAreaUtilsService,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    translate: TranslateService,
    localizedValueUtils: LocalizedValueUtilsService,
    permissionsService: NgxPermissionsService,
    inventoryValueService: InventoryValueService,
    d: UnitsHttpService
  ) {
    super(
      store,
      storageAreaUtils,
      dialog,
      snackBar,
      translate,
      localizedValueUtils,
      permissionsService,
      inventoryValueService,
      d
    );
  }

  componentOnDestroy$ = new Subject<void>();

  @Input() getPage: (q: any, pageSize: number, page: number) => Observable<any[]>;

  itemFilterText$ = this.store.select((state) => state.worksheets.itemFilterText);
  storageAreaQuery$ = this.storageAreaId$.pipe(map(v => ({
    storageAreaId: v
  })));

  @ViewChild(InfiniteScrollListComponent) scrollContainer: InfiniteScrollListComponent;

  scrollerOptions: InfiniteScrollOptions = {
    autoLoadFirstPage: true,
    scrollPercent: 60,
    containerHeight: 500,
    pageSize: 10,
    enableLog: true,
    loaderStyle: 'single',
    hideScrollbar: false
  };

  ngOnDestroy(): void {
    this.componentOnDestroy$.next();
  }

  ngAfterViewInit(): void {
    this.createComponentEffects()
      .subscribe();
  }

  createComponentEffects() {
    return combineLatest([
      this.createBehaviorClearDataOnSearchInputChange()
    ]).pipe(
      takeUntil(this.componentOnDestroy$)
    );
  }

  createBehaviorClearDataOnSearchInputChange() {
    return this.itemFilterText$
      .pipe(
        tap(v => {
          this.scrollContainer.resetData();
        })
      );
  }

  override worksheetItemDropped(event: any, isDragDrop: boolean = false) {
    super.worksheetItemDropped(event, isDragDrop);
    this.scrollContainer.refreshLoadedData();
  }

  override getWorksheetItemRank(item: WorksheetItem) {
    return this.scrollContainer.data.findIndex(c => c.id === item.id) + 1;
  }

  override afterWorksheetItemMoved(): void {
    this.scrollContainer.refreshLoadedData();
  }

  override afterWorksheetItemDeleted(): void {
    this.scrollContainer.refreshLoadedData();
  }
}

import { Injectable } from '@angular/core';

import { ReplaySubject, Observable } from 'rxjs';

import { InventoryConstants } from '@gfs/constants';
import { ResizeEventService } from '../resize-event/resize-event.service';

@Injectable({ providedIn: 'root' })
export class DeviceIdentifierService {
  private deviceType = new ReplaySubject<boolean>(1);

  constructor(private resizeEventService: ResizeEventService) {
    this.observeResizeEvents();
  }

  public observeDeviceType(): Observable<boolean> {
    return this.deviceType.asObservable();
  }

  private observeResizeEvents(): void {
    this.resizeEventService.getEvents().subscribe(() => {
      const device = this.getDeviceType();
      const isMobile = device === InventoryConstants.DEVICE.MOBILE;
      this.deviceType.next(isMobile);
    });
  }

  public getDeviceType(): string {
    // pull the device type out of the elements that we created in styles.scss
    return window
      .getComputedStyle(document.querySelector('body'), ':before')
      .getPropertyValue('content')
      .replace(/\"/g, '');
  }
}

<div class="counting-unit-modal">
  <form class="counting-unit-form" [formGroup]="countingUnitForm" *ngIf="countingUnitForm">
    <div formArrayName="countingUnits">
      <div *ngFor="let unit of countingUnitFormArray?.controls; let unitIndex=index; let last = last" [formGroupName]="unitIndex" class="unit-form-wrapper">
        <div [ngClass]="{'counting-unit-block': !(isMobile$ | async)}" [style.display]="getCountingUnitIsDeleted(unitIndex) ? 'none' : 'block'" >
          <div class="counting-unit-fields-wrapper">
            <div class="invisible">
              <mat-form-field>
                <input
                  matInput
                  id="counting-unit-deleted-{{unitIndex}}"
                  formControlName="unitDeleted"
                  [readonly]=true
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="counting-unit-form-field">
                <mat-label>{{'ADD_ITEMS.COUNTING_UNIT' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'ADD_ITEMS.COUNTING_UNIT' | translate }}"
                  type="text"
                  autocomplete="off"
                  class="counting-unit-input"
                  id="counting-unit-name-{{unitIndex}}"
                  formControlName="unitName"
                  (blur)="onCountingUnitNameBlur(unitIndex)"
                  [readonly]="!isUserManagableUnit(unitIndex)"
                  
                  maxlength="200"
                  #nameInput
                />
              </mat-form-field>
              <div class="duplicate-countingUnit-error" [ngClass]="{'gfsItem' : isgfsItem}">
                <mat-error *ngIf="getCountingUnitName(unitIndex).hasError('duplicateCustomUnit')">{{'ADD_ITEMS.DUPLICATE_COUNTING_UNIT' | translate }}</mat-error>
              </div>
            </div>
            <div class="custom-unit-form-field-wrapper">
              <div>
                <mat-form-field class="unit-qty-form-field">
                  <mat-label>{{ getUnitQtyPlaceholder(unitIndex) }}</mat-label>
                  <input
                    matInput
                    appTwoDigitDecimalNumber
                    [regex]="qtyRegex"
                    placeholder="{{ getUnitQtyPlaceholder(unitIndex) }}"
                    type="text"
                    autocomplete="off"
                    class="counting-unit-input"
                    id="counting-unit-qty-{{unitIndex}}"
                    formControlName="unitQty"
                    [readonly]="!isUserManagableUnit(unitIndex)"
                    maxlength="200"
                    #qtyInput
                  />
                  <mat-error *ngIf="getCountingUnitQty(unitIndex).hasError('invalidGreaterThanValidator')">{{'ADD_ITEMS.INVALID_COUNTING_UNIT_QUANTITY' | translate}}</mat-error>
                </mat-form-field>
              </div>
              <div class="forward-slash">/</div>
              <mat-form-field class="parent-unit-form-field">
                <mat-label>{{ 'ADD_ITEMS.PURCHASE_UNIT_ADD_EDIT_TEXT' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'ADD_ITEMS.PURCHASE_UNIT_ADD_EDIT_TEXT' | translate }}"
                  type="text"
                  autocomplete="off"
                  class="counting-unit-input"
                  id="counting-unit-parent-{{unitIndex}}"
                  formControlName="parentUnit"
                  readonly
                />
              </mat-form-field>
              <button
                class="delete-button" *ngIf="isUserManagableUnit(unitIndex)"
                (click)="deleteCountingUnitGroup(unitIndex)"
                [disabled]="!isAllowedToEdit">
                <mat-icon class="icon delete-icon" svgIcon="trash-icon"></mat-icon>
              </button>
            </div>
          </div>
          <div class="purchase-by-units-wrapper">
            <div *ngIf="shouldShowPurchaseByUnit(unitIndex)" class="purchase-by-units-text">
              {{'ADD_ITEMS.PURCHASE_BY_UNIT' | translate}}
            </div>
            <div *ngIf="shouldShowPurchaseByUnit(unitIndex)" class="purchase-by-unit-check">
              <mat-checkbox [checked]="isPurchaseByUnit" (change)="selectPurchaseByUnit($event)">
                {{'ADD_ITEMS.YES' | translate}}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <button class="add-unit-button" (click)="addCountingUnitGroup('', '', false, true)" [disabled]="!isAllowedToEdit">
    <mat-icon class="icon add-unit-plus-icon" svgIcon="plus-icon"></mat-icon>
    <span class="add-unit-button-label">{{'ADD_ITEMS.ADD_COUNTING_UNIT' | translate}}</span>
  </button>
</div>

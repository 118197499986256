import { Injectable } from '@angular/core';
import { RecipeConstants } from '@gfs/constants';
import { CountableItem, CountingUnit, GfsItem, LiteralUnit, LocalizedValue, MeasurementUnit, Recipe, Supplier } from '@gfs/shared-models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { arrayToEntityDictionary, entityDictionaryToArray, getLastImage as getLastImageImport } from '../core/entityUtil';

@Injectable({
  providedIn: 'root'
})
export class LocalizedValueUtilsService {

  getLastImage = getLastImageImport;

  private readonly noImageAsset = 'assets/images/no-image-icon.svg';

  static resolveLocalizedValue(values: LocalizedValue[], lang: string, defaultVal: string = '') {
    if (!values?.length) {
      return defaultVal;
    }
    return (values.find((x) => x.languageCode === lang) || values[0]).value;
  }

  // TODO: eventually, all functions will need to also handle order guide item, recipe item, or non-GFS item
  getLocalizedProductDescription(inventoryItem: CountableItem, currentLang$: Observable<string>): Observable<string> {
    let description = of('');
    if (inventoryItem) {
      if (inventoryItem.customItem) {
        description = of(inventoryItem.customItem.description);
      }
      if (inventoryItem.generalItem) {
        // TODO: Handle description for general items
      }
      if (inventoryItem.gfsItem) {
        description = this.getGfsLocalizedProductDescription(inventoryItem.gfsItem, currentLang$);
      }
      if (inventoryItem.recipeItem) {
        description = of(inventoryItem.recipeItem.name);
      }
    }
    return description;
  }

  getLocalizedImageUrl(inventoryItem: CountableItem, currentLang$: Observable<string>, width: number, height: number, allowOtherLocale: boolean = false): Observable<string> {
    if (inventoryItem && inventoryItem.gfsItem) {
      return this.getGfsLocalizedImageUrl(inventoryItem.gfsItem, currentLang$, width, height, allowOtherLocale);
    } else if (inventoryItem?.recipeItem) {
      return this.getRecipeLocalizedImageUrl(inventoryItem.recipeItem);
    } else {
      return of('assets/images/no-image-icon.svg');
    }
  }

  getLocalizedBrand(
    countableItem: CountableItem,
    suppliers: Supplier[],
    currentLang$: Observable<string>
  ): Observable<string> {
    if (countableItem && countableItem.customItem) {
      if (suppliers?.length > 0) {
        const resolvedSupplier = suppliers.find(supplier => countableItem.customItem.supplierId === supplier.id);
        const customItemSupplierName = resolvedSupplier?.name ?? 'n/a';
        return of(customItemSupplierName);
      }
    }
    else if (countableItem && countableItem.gfsItem) {
      return this.getGfsLocalizedBrand(countableItem.gfsItem, currentLang$);
    }
    else if (countableItem && countableItem.recipeItem) {

      const details = countableItem.recipeItem.details;

      if (details?.batch) {
        return of('Batch Recipe');
      } else if (details?.menu) {
        return of('Menu Item');
      } else {
        return of('Generic Recipe');
      }
    }

    return of('');
  }

  getGfsLocalizedProductDescription(inventoryItem: GfsItem, currentLang$: Observable<string>): Observable<string> {
    return currentLang$.pipe(
      map(lang => {
        if (inventoryItem && inventoryItem.description) {
          const descIndex = inventoryItem.description.findIndex(des => des.languageCode === lang);
          if (descIndex >= 0
            && inventoryItem.description[descIndex].value) {
            return inventoryItem.description[descIndex].value;
          }
          if (inventoryItem.description.length > 0) {
            return inventoryItem.description[0].value;
          }
        } else {
          return '';
        }
      })
    );
  }

  getGfsLocalizedImageUrl(inventoryItem: GfsItem, currentLang$: Observable<string>, width: number, height: number, allowOtherLocale: boolean = false): Observable<string> {
    return currentLang$.pipe(
      map(lang => {
        if (inventoryItem && inventoryItem.image) {
          // filter out images with null value
          const images = inventoryItem.image.filter(j => !!j.value);
          let imageIndex = images.findIndex(img => img.languageCode === lang);

          // if there is an image to display, but its not of the target language, use it anyway
          if (allowOtherLocale && imageIndex === -1 && images.length > 0) {
            imageIndex = 0;
          }

          if (imageIndex >= 0
            && images[imageIndex].value) {
            return images[imageIndex].value + '?width=' + width + '&height=' + height + '&auto=webp&fit=bounds&dpr='
              + window.devicePixelRatio;
          }
        }

        // there were no images configured
        return 'assets/images/no-image-icon.svg';
      })
    );
  }

  getRecipeLocalizedImageUrl(recipe: Recipe): Observable<string> {
    return of(this.getLastImage(recipe) || this.noImageAsset);
  }

  getGfsLocalizedBrand(inventoryItem: GfsItem, currentLang$: Observable<string>): Observable<string> {
    return currentLang$.pipe(
      map(lang => {
        if (inventoryItem && inventoryItem.brand) {
          const brandIndex = inventoryItem.brand.findIndex(brand => brand.languageCode === lang);
          if (brandIndex >= 0
            && inventoryItem.brand[brandIndex].value) {
            return inventoryItem.brand[brandIndex].value;
          }
          if (inventoryItem.brand.length > 0) {
            return inventoryItem.brand[0].value;
          }
        } else {
          return '';
        }
      })
    );
  }

  getLocalizedMeasurementUnitName(unit: MeasurementUnit, lang: string): string {
    if (unit && unit.name) {
      const nameIndex = unit.name.findIndex(name => name.languageCode === lang);
      if (nameIndex >= 0 && unit.name[nameIndex].value) {
        return `${unit.name[nameIndex].value[0].toUpperCase()}${unit.name[nameIndex].value.slice(1)}`;
      }
      if (unit.name.length > 0) {
        return `${unit.name[0].value[0].toUpperCase()}${unit.name[0].value.slice(1)}`;
      }
    } else {
      return '';
    }
  }

  getLocalizedMeasurementUnitSymbol(unit: MeasurementUnit, lang: string): string {
    if (unit && unit.symbol) {
      const symbolIndex = unit.symbol.findIndex(symbol => symbol.languageCode === lang);
      if (symbolIndex >= 0 && unit.symbol[symbolIndex].value) {
        return unit.symbol[symbolIndex].value;
      }
      if (unit.symbol.length > 0) {
        return unit.symbol[0].value;
      }
    } else {
      return '';
    }
  }

  getLocalizedLiteralUnitName(unit: LiteralUnit, lang: string): string {
    if (unit && unit.name) {
      const nameIndex = unit.name.findIndex(name => name.languageCode === lang);

      if (nameIndex !== -1 && unit.name[nameIndex].value) {
        return `${unit.name[nameIndex].value[0].toUpperCase()}${unit.name[nameIndex].value.slice(1)}`;
      }

      if (unit.name.length > 0) {
        return `${unit.name[0].value[0].toUpperCase()}${unit.name[0].value.slice(1)}`;
      }

    } else {
      return '';
    }
  }
  getStandardMeasurementUnitDisplayName(unit: CountingUnit, lang: string): string {
    const localizedName = this.getLocalizedMeasurementUnitName(unit.standardUnit, lang);
    const localizedSymbol = this.getLocalizedMeasurementUnitSymbol(unit.standardUnit, lang);
    return localizedSymbol ? `${localizedName} (${localizedSymbol})` : localizedName;
  }
  getLocalizedCategory(categoryList: LocalizedValue[], lang: string): string {
    if (categoryList.length > 0) {
      const category = categoryList
        .filter(cat => !!cat.value)
        .find(cat => cat.languageCode === lang || cat.languageCode === lang.slice(0, 2));
      return category ? category.value : categoryList[0].value;
    }
    return null;
  }

  getUnitDisplayName(unit: MeasurementUnit, lang: string): string {
    lang = (lang ?? RecipeConstants.LANGUAGES.DEFAULT).replace('-', '_');
    const localizedUnitName = this.getLocalizedUnitDisplayName(unit);
    const localizationLookup = arrayToEntityDictionary<LocalizedValue>(
      localizedUnitName,
      x => x.languageCode
    );
    const resolvedText = localizationLookup[lang] || localizationLookup[RecipeConstants.LANGUAGES.DEFAULT];
    return resolvedText.value;
  }

  getLocalizedUnitDisplayName(unit: MeasurementUnit): LocalizedValue[] {
    return entityDictionaryToArray<string>(RecipeConstants.LANGUAGES)
      .map((languageCode) => {
        const localizedName = this.getLocalizedMeasurementUnitName(unit, languageCode);
        const localizedSymbol = this.getLocalizedMeasurementUnitSymbol(unit, languageCode);
        return {
          languageCode,
          value: localizedSymbol ? `${localizedName} (${localizedSymbol})` : localizedName
        } as LocalizedValue;
      });
  }

  parseNumber(value: string, locale: string): number {
    const example = Intl.NumberFormat(this.getLanguage(locale)).format(1.1);
    const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
    const cleaned = value.replace(cleanPattern, '');
    const normalized = cleaned.replace(example.charAt(1), '.');

    return parseFloat(normalized);
  }

  getLanguage(locale) {
    const index = locale.indexOf('_');
    if (index >= 0) {
      return locale.substring(0, index);
    }
    return locale;
  }

  getCountingUnitDisplayName(unit: CountingUnit, lang: string): string {
    if (unit.unit.unitType === 'LITERAL') {
      return this.getLocalizedLiteralUnitName(unit.literalUnit, lang);
    } else if (unit.unit.unitType === 'STANDARD') {
      return this.getStandardMeasurementUnitDisplayName(unit, lang);
    } else if (unit.unit.unitType === 'CUSTOM') {
      return unit.customUnit.custom.name;
    } else {
      return '';
    }
  }

}

import { CustomerPK } from '@gfs/shared-models';
import { Injectable } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';

@Injectable({
  providedIn: 'root',
})
export class EntityUtil {
  isCustomerEntity(customerPK: CustomerPK): boolean {
    return InventoryConstants.CUSTOMER_ENTITIES.includes(customerPK.entityType);
  }
}

import * as customerItems from '../actions/customerItems.actions';
import { CustomItem, GeneralItem } from '@gfs/shared-models';

export interface State {
  customItems: CustomItem[];
  generalItems: GeneralItem[];
  error: string;
}

export const initialState: State = {
  customItems: null,
  generalItems: null,
  error: null,
};

export function customerItemsReducer(
  state: State = initialState,
  action: customerItems.ActionUnion
): State {
  switch (action.type) {
    case customerItems.ActionTypes.GetAllCustomItemsSuccess: {
      return {
        ...state,
        customItems: action.payload,
      };
    }
    case customerItems.ActionTypes.GetAllGeneralItemsSuccess: {
      return {
        ...state,
        generalItems: action.payload,
      };
    }
    case customerItems.ActionTypes.UpdateCustomItemPriceSuccess: {
      return handleUpdateCustomItem(state, action.payload);
    }
    case customerItems.ActionTypes.UpdateLocalGeneralItem: {
      return handleUpdateGeneralItem(state, action.payload);
    }
    case customerItems.ActionTypes.DeleteLocalCustomItem: {
      return handleDeleteLocalCustomItem(state, action);
    }
    case customerItems.ActionTypes.DeleteGroupLocalCustomItem: {
      return handleGroupDeleteLocalCustomItem(state, action);
    }
    case customerItems.ActionTypes.DeleteCustomItemSuccess: {
      return handleUpdateCustomItem(state, action.payload);
    }
    case customerItems.ActionTypes.DeleteGroupCustomItemSuccess: {
      return handleGroupUpdateCustomItem(state, action.payload);
    }
    case customerItems.ActionTypes.DeleteGeneralItemsSuccess: {
      return handleUpdateGeneralItem(state, action.payload);
    }
    case customerItems.ActionTypes.DeleteGroupGeneralItemsSuccess: {
      return handleGroupUpdateGeneralItem(state, action.payload);
    }
    case customerItems.ActionTypes.GetAllCustomItemsError:
    case customerItems.ActionTypes.GetAllGeneralItemsError:
    case customerItems.ActionTypes.UpdateCustomItemPriceError:
    case customerItems.ActionTypes.DeleteCustomItemError:
    case customerItems.ActionTypes.DeleteGeneralItemError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    default: {
      return state;
    }
  }
}

export function handleUpdateCustomItem(
  state: State,
  customItem: CustomItem
): State {
  const index = state.customItems?.findIndex(
    (item) => item.id === customItem.id
  );
  if (index >= 0) {
    state.customItems[index] = customItem;
  }

  return {
    ...state,
    customItems: state.customItems,
  };
}

export function handleGroupUpdateCustomItem(
  state: State,
  customItems: CustomItem[]
): State {
  customItems.forEach((item)=>{
    const index = state.customItems?.findIndex(
      (items) => items.id === item.id
    );
    if (index >= 0) {
      state.customItems[index] = item;
    }
  })

  return {
    ...state,
    customItems: state.customItems,
  };
}

export function handleDeleteLocalCustomItem(
  state: State,
  action: customerItems.DeleteLocalCustomItem
): State {
  const customIndex = state.customItems?.findIndex(
    (item) => item.id === action.payload.customItemId
  );
  if (customIndex >= 0) {
    state.customItems[customIndex].deleted = true;
  }

  const generalIndex = state.generalItems?.findIndex(
    (item) => item.id === action.payload.generalItemId
  );
  if (generalIndex >= 0) {
    state.generalItems[generalIndex].deleted = true;
  }

  return {
    ...state,
    customItems: state.customItems,
    generalItems: state.generalItems,
  };
}
export function handleGroupDeleteLocalCustomItem(
  state: State,
  action: customerItems.DeleteGroupLocalCustomItem
): State {
  const customItemIds = action.payload.customItemIds ; 
  const generalItemIds = action.payload.generalItemIds;

  customItemIds.forEach((Cids)=>{
    const customIndex = state.customItems?.findIndex(
      (item) => item.id === Cids
    );
    if (customIndex >= 0) {
      state.customItems[customIndex].deleted = true;
    }
  })
  generalItemIds.forEach((Gids)=>{
    const generalIndex = state.generalItems?.findIndex(
      (item) => item.id === Gids
    );
    if (generalIndex >= 0) {
      state.generalItems[generalIndex].deleted = true;
    }
  })
  return {
    ...state,
    customItems: state.customItems,
    generalItems: state.generalItems,
  };
}

export function handleUpdateGeneralItem(
  state: State,
  generalItem: GeneralItem
): State {
  const index = state.generalItems?.findIndex(
    (item) => item.id === generalItem.id
  );
  if (index >= 0) {
    state.generalItems[index] = generalItem;
  }

  return {
    ...state,
    generalItems: state.generalItems,
  };
}
export function handleGroupUpdateGeneralItem(
  state: State,
  generalItems: GeneralItem[]
): State {
  generalItems.forEach((generalItem)=>{
    const index = state.generalItems?.findIndex(
      (item) => item.id === generalItem.id
    );
    if (index >= 0) {
      state.generalItems[index] = generalItem;
    }
  })
  return {
    ...state,
    generalItems: state.generalItems,
  };
}

import { CustomerPK, CustomItemData, Worksheet } from '@gfs/shared-models';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface IQuickAddItemsFeatureBridge {
  pk$: Observable<CustomerPK>;
  currentLang$: Observable<string>;
  selectedWorksheet$: Observable<Worksheet>;
  customItemData$: Observable<CustomItemData[]>;
  isSavingWorksheet$: Observable<boolean>;
  isMobile$: Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class MockQuickAddItemsContextFactoryService {
  public static factory(mockObj: IQuickAddItemsFeatureBridge) {
    return mockObj;
  }
}

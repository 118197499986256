import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { RecipeConstants } from '@gfs/constants';
// import { MaterialModule } from '@gfs/shared-components';
import { ItemReference, reallyCoolOpts, ResolvedItem, ResolvedItemDataSource } from '@gfs/shared-models';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { MaterialModule } from '../../lib/material.module';
import { MeasurementUnitOptionService } from './measurement-unit-option.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    MeasurementUnitOptionService
  ]
})

export class FormV2Module { }

export type DropDownOption = {
  name: string;
  key: string;
};

// export function conditionalValidator(
//   condFn: (control: AbstractControl) => boolean,
//   validators: ValidatorFn | ValidatorFn[]
// ): ValidatorFn {
//   return (control) => {
//     if (!condFn(control)) {
//       return null;
//     }

//     if (!Array.isArray(validators)) {
//       validators = [validators];
//     }

//     return validators
//       .map((v) => v(control))
//       .reduce((errors, result) =>
//         result === null ? errors : Object.assign(errors || {}, result)
//       );
//   };
// }

// export function conditionalAsyncValidator(
//   condition$: Observable<boolean>,
//   validators: AsyncValidatorFn | AsyncValidatorFn[]
// ): AsyncValidatorFn {
//   return (control) => {
//     return forkJoin([
//       condition$.pipe(
//         first(),
//         map((enabled) => {
//           if (!enabled) {
//             return [] as AsyncValidatorFn[];
//           }
//           if (!Array.isArray(validators)) {
//             validators = [validators];
//           }
//           return validators;
//         }),
//         concatMap((validatorsCollection) => from(validatorsCollection)),
//         concatMap((v) => v(control)),
//         reduce(
//           (acc, error) => ({
//             ...acc,
//             ...error,
//           }),
//           {} as ValidationErrors
//         ),
//       )
//     ]).pipe(
//       map(([gg]) => {
//         return gg;
//       }),
//       tap(validationResult => {
//         console.log('CONDITIONAL:', validationResult);
//       })
//     );
//   };
// }

// only allow numbers, slashes, period, comma or space
// only allow three decimal places
export function checkForDecimalsAndFractionsInputGateFn(
  keyPressEvent,
  currentValue: string
): boolean {
  const pressedKey = keyPressEvent.key;
  if (!RecipeConstants.BATCH_YIELD_VALID_INPUT_ARRAY.includes(pressedKey)) {
    return false;
  }
  if (currentValue) {
    const lastindexOfSpace = currentValue.lastIndexOf(' ');
    if (currentValue.length === 0 && pressedKey === ' ') {
      return false;
    }
    if (currentValue.length > 0 && pressedKey === ' ' && lastindexOfSpace === currentValue.length - 1) {
      return false;
    }
    if (currentValue) {
      // begin: do not allow fractions with decimals or the reverse
      const fractionLike = currentValue.indexOf('/') > -1 || currentValue.indexOf(' ') > -1;
      const decimalLike = currentValue.indexOf('.') > -1 || currentValue.indexOf(',') > -1;

      if (decimalLike) {
        if (pressedKey === '/' || pressedKey === ' ') {
          return false;
        }
      }
      if (fractionLike) {
        if (pressedKey === ' ' || pressedKey === '.' || pressedKey === ',') {
          return false;
        }
      }
      // end: do not allow fractions with decimals or the reverse

      if (
        (pressedKey === '/' ||
          pressedKey === '\\' ||
          pressedKey === '.' ||
          pressedKey === ',' ||
          pressedKey === ' ') &&
        currentValue.indexOf(pressedKey) > -1
      ) {
        return false;
      }
      if (currentValue.length >= 4) {
        const lastFourChar = currentValue.substring(currentValue.length - 4);
        if (lastFourChar[0] === '.' || lastFourChar[0] === ',') {
          return false;
        }
      }
    }
  }

  return true;
}


export function getItemDataFn$(itemRef: ItemReference, opts: reallyCoolOpts) {
  return opts.itemDataSource(itemRef, opts)
    .pipe(
      distinctUntilChanged(),
    );
}

export function createAdHocItemDataSource(
  itemDatas: ResolvedItem[]
): (itemref: ItemReference, opts: ResolvedItemDataSource) => Observable<ResolvedItem> {
  return (ref: ItemReference, opts: ResolvedItemDataSource) => of(findItemInCollection(itemDatas, ref, opts.useDisplayItem));
}

export function findItemInCollection(collection: ResolvedItem[], ref: ItemReference, useDisplayItem: boolean): ResolvedItem {
  return collection.find(z => isItemReferenceEqual(z.itemReference, ref)
    // check for the item reference to match on the items parent reference
    || (useDisplayItem && !!z.itemReference.parent && isItemReferenceEqual(z.itemReference.parent, ref)));
}

export function isItemReferenceEqual(a: ItemReference, b: ItemReference): boolean {
  return a.key === b.key && a.type === b.type;
}

export function capitalizeFirstLetter(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function formIsTouchedAndDirty(form: UntypedFormGroup) {
  return form.touched && form.dirty;
}

export function getFormArray(control: AbstractControl): UntypedFormArray {
  return control as UntypedFormArray;
}
export function getFormArrayControls(control: AbstractControl): AbstractControl[] {
  return getFormArray(control).controls as AbstractControl[];
}
import * as cart from './actions';
import { FeatureState, initialState } from './state';

export function cartReducer(
  state: FeatureState = initialState,
  action: cart.ActionUnion
): FeatureState {
  switch (action.type) {
    case cart.ActionTypes.GetCartDataSuccess:
      return {
        ...state,
        cart: action.payload.cart,
        cartsQuantities: {
          ...state.cartsQuantities,
          [action.payload.customerId]: action.payload.cart,
        },
      };
    case cart.ActionTypes.GetCartDataError:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
}

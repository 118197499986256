import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentIsSaving, SaveInProgressGuard } from './save-in-progress.guard';

export const saveInProgressFn: CanDeactivateFn<ComponentIsSaving> =
  (
    component: ComponentIsSaving,
    route: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
    saveInProgressGuard: SaveInProgressGuard = inject(SaveInProgressGuard),
  ): Observable<boolean> => {
    return saveInProgressGuard.canDeactivate(component);
  };

import { UntypedFormGroup } from '@angular/forms';
import { IDisposable } from '@gfs/shared-services';

/*
 * Public API Surface of shared-components
 */
export * from './lib/add-element-autocomplete/add-element-autocomplete.component';
export * from './lib/material.module';
export * from './lib/shared-components.module';
export * from './lib/customer-unit-selection/customer-unit-selection.module';
export * from './lib/customer-unit-selection/customer-unit-card/customer-unit-card.component';
export * from './lib/customer-unit-selection/customer-unit-selection/customer-unit-selection.component';
export * from './lib/customer-unit-selection/customer-unit-selector/customer-unit-selector.component';
export * from './lib/customer-unit-selection/no-results/no-results.component';
export * from './lib/pipes/pipe-sharing.module';
export * from './lib/pipes/truncated.pipe';
export * from './lib/add-items/add-items.module';
export * from './lib/add-items/add-item-dialog/add-item-dialog.component';
export * from './lib/add-items/non-gfs-info-dialog/non-gfs-info-dialog.component';
export * from './lib/add-items/add-item-card/add-item-card.component';
export * from './lib/add-items/add-custom-item/add-custom-item.component';
export * from './lib/add-items/counting-unit-form/counting-unit-form.component';
export * from './lib/add-items/edit-gfs-item/edit-gfs-item.component';
export * from './lib/add-items/edit-item-dialog/edit-item-dialog.component';
export * from './lib/add-items/duplicate-item-dialog/duplicate-item-dialog.component';
export * from './lib/modals/shared-modals.module';
export * from './lib/modals/error-modal/error-modal.component';
export * from './lib/header/header.module';
export * from './lib/header/account-menu/account-menu.component';
export * from './lib/header/hamburger-menu/hamburger-menu.component';
export * from './lib/header/header/header.component';
export * from './lib/header/network-status/network-status.component';
export * from './lib/header/sub-header/sub-header.component';
export * from './lib/header/sub-header/inventory-tools/inventory-tools.component';
export * from './lib/select-menu/select-menu.component';
export * from './lib/messages/messages.component';
export * from './lib/messages/message-content/message-content.component';
export * from './lib/language-menu/language-menu.component';
export * from './lib/cart-icon/cart-icon.component';
export * from './lib/customer-unit-menu/customer-unit-menu.component';
export * from './lib/delivery-schedule-widget/delivery-schedule-widget.component';
export * from './lib/reset-confirm-dialog/reset-confirm-dialog.component';
export * from './lib/loading-modal/loading-modal.component';
export * from './lib/inventory-worksheet-wizard/inventory-worksheet-wizard.component';
export * from './lib/error/error.module';
export * from './lib/error/error/error.component';
export * from './lib/error/error-handler/custom-error-handler.service';
export * from './lib/customer-unit-selection/customer-unit-selection.module';
export * from './lib/customer-unit-selection/customer-unit-card/customer-unit-card.component';
export * from './lib/customer-unit-selection/customer-unit-searchbar/customer-unit-searchbar.component';
export * from './lib/customer-unit-selection/customer-unit-selection/customer-unit-selection.component';
export * from './lib/customer-unit-selection/customer-unit-selector/customer-unit-selector.component';
export * from './lib/customer-unit-selection/no-results/no-results.component';
export * from './lib/legacy-customer-migration/facade/legacy-customer-migration.facade';
export * from './lib/general-ledger';

/*
 * Quick Add Items
 */
export * from './lib/quick-add-items/quick-add-items.module';
export * from './lib/quick-add-items/quick-add-items.component';
/*
 * Inventory display
 */
export * from './lib/manage-custom-items/manage-custom-items.module';
export * from './lib/manage-custom-items/custom-item-panel/custom-item-card.component';
export * from './lib/manage-custom-items/manage-custom-items/manage-custom-items.component';
export * from './lib/manage-custom-items/supplier-item-panel/supplier-item-panel.component';
export * from './lib/modals/confirmation-modal/confirmation-modal.component';
export * from './lib/mobile-hamburger-menu/mobile-hamburger-menu.component';
export * from './lib/inventory-item-card/inventory-item-card.component';



export type DisposableFormGroup = UntypedFormGroup & IDisposable;

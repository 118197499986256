
export const FeatureKey = 'cartFeature';
import { Cart } from '@gfs/shared-models';

export interface IFeatureStateFacade {
  [FeatureKey]: FeatureState;
}

export interface FeatureState {
  cart: Cart;
  cartsQuantities: {
    [customerId: string]: Cart;
  };
  error: string;
}

export const initialState: FeatureState = {
  cart: null,
  cartsQuantities: {},
  error: null
};

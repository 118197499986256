import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { RedirectUri } from '@gfs/shared-services';
import { Observable } from 'rxjs';
import { ExternalUriResolver } from './external-uri.resolver';

export const externalUriResolverFn: ResolveFn<Observable<RedirectUri>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<RedirectUri> => {
  const service = inject(ExternalUriResolver);
  return service.resolve(route, state);
};

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { SetLanguage } from '@gfs/store/common';
import { InventoryConstants } from '@gfs/constants';

interface LanguageType {
  display: string;
  lang: string;
}

@Component({
  selector: 'gfs-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent implements OnInit {
  isMobile$ = this.store.select(state => state.layout.isMobile);
  isOnline$ = this.store.select(state => state.network.isOnline);
  languages: LanguageType[] = [
    {
      display: 'English',
      lang: InventoryConstants.LANGUAGES.ENGLISH,
    },
    {
      display: 'Français',
      lang: InventoryConstants.LANGUAGES.FRENCH
    }
  ];
  selectedLanguage$ = this.store.select((state: AppState) => this.languages.find(langType => langType.lang === state.layout.language));

  constructor(
    private store: Store<AppState>,
    public router: Router,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.sortLanguages();

    this.translate.onLangChange.subscribe(_ => this.sortLanguages());
  }

  sortLanguages() {
    this.selectedLanguage$.subscribe(selected => {
      if (selected.lang !== this.languages[0].lang) {
        this.languages.reverse();
      }
    });
  }

  toggleLanguage(langType: LanguageType): void {
    localStorage.setItem('languageId', langType.lang);
    this.store.dispatch(new SetLanguage(langType.lang));
    this.sortLanguages();
  }

  getOptionLabel(option: LanguageType) {
    return option.display;
  }
}

import { DOCUMENT, formatDate, registerLocaleData } from '@angular/common';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';
import { LoadingModalComponent } from '@gfs/shared-components';
import { WorksheetSummary } from '@gfs/shared-models';
import { AppConfigService, InventoryUtilsService } from '@gfs/shared-services';
import { DownloadInventoryValueReportAttempt, DownloadMultiUnitReportAttempt } from '@gfs/store/inventory/actions/reports.actions';
import { GetWorksheetSummaryAttempt } from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class ReportsComponent implements OnInit, OnDestroy {
  isMobile$ = this.store.select(state => state.layout.isMobile);
  currentLang$ = this.store.select(state => state.layout.language);
  activeWorksheetSummaries$ = this.store.select(state => state.worksheets.worksheets).pipe(
    map(InventoryUtilsService.sortSummaries),
    map(value => value.filter(ws => ws.deleted === false)),
  );
  displayedColumns: string[] = ['radio', 'Description', 'Value', 'Count Date'];
  customerPk$ = this.store.select(state => state.auth.pk);
  summariesLoaded = false;
  lastElement = 5;
  increment = 5;
  selectedCount: WorksheetSummary;
  isChainUser: boolean = null;

  multiUnitDateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  notifier = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private inventoryUtilsService: InventoryUtilsService,
    private datePickerLanguageAdapter: DateAdapter<any>,
    @Inject(DOCUMENT) private document: Document,
    public configService: AppConfigService
  ) { }

  ngOnInit() {
    registerLocaleData(locale_fr_CA);

    this.currentLang$.pipe(takeUntil(this.notifier)).subscribe(newLang => {
      this.datePickerLanguageAdapter.setLocale(newLang);
    });

    this.document.body.classList.add('full-width');
    this.customerPk$.pipe(
      filter(customer => !!customer),
      first(),
      tap(pk => {
        this.isChainUser = !InventoryConstants.CUSTOMER_ENTITIES.includes(pk.entityType);
      })
    ).subscribe(_ => this.store.dispatch(new GetWorksheetSummaryAttempt()));

    this.activeWorksheetSummaries$.pipe(
      filter((sums, index) => index > 0 || (index === 0 && sums.length > 0)),
      first()
    ).subscribe(_ => this.summariesLoaded = true);

    this.isMobile$.pipe(first()).subscribe(isMobile => {
      if (isMobile) {
        this.increment = 4;
        this.lastElement = this.increment;
      }
    });
  }

  ngOnDestroy() {
    this.document.body.classList.remove('full-width');
    this.notifier.next();
  }

  // Filter every date in the future
  dateRangeFilter(dateToFilter: Date | null): boolean {
    return moment(dateToFilter)
      .diff(moment(Date.now())) <= 0;
  }

  isRollupDownloadDisabled(): boolean {
    if (!!this.multiUnitDateRange.controls.start.value
      && !!this.multiUnitDateRange.controls.end.value) {
      return false;
    }
    return true;
  }

  downloadRollupCSV(): void {
    this.openLoadingModal();
    this.store.dispatch(new DownloadMultiUnitReportAttempt(
      {
        dateRange: this.multiUnitDateRange.value,
        locale: this.translateService.currentLang
      }));
  }

  translateName(name: string): string {
    if (name.toLowerCase() === 'Untitled Inventory'.toLowerCase() || name.toLowerCase() === `Inventaire sans titre`.toLowerCase()) {
      return this.translateService.instant('INVENTORY_WORKSHEET.UNTITLED_INVENTORY');
    }
    return name;
  }

  viewMore() {
    this.lastElement += this.increment;
  }

  selectCount(worksheetSummary: WorksheetSummary) {
    this.selectedCount = worksheetSummary;
  }

  downloadCsv(exportType) {
    this.openLoadingModal();
    // TODO: Add listBy logic
    let worksheetName = this.selectedCount.name;
    if (worksheetName.toLowerCase() === 'Untitled Inventory'.toLowerCase()
      || worksheetName.toLowerCase() === 'Inventaire sans titre'.toLowerCase()) {
      worksheetName = this.translateService.instant('INVENTORY_WORKSHEET.UNTITLED_INVENTORY');
    }
    this.currentLang$.pipe(first()).subscribe(lang => {
      // eslint-disable-next-line max-len
      this.store.dispatch(new DownloadInventoryValueReportAttempt({ worksheetSummary: this.selectedCount, worksheetName, locale: lang, exportType }));
    });
  }

  getWorksheetTableDateTime(date: string, currentLang: string): string {
    if (currentLang === InventoryConstants.LANGUAGES.ENGLISH) {
      const dateString = formatDate(date, 'MMM. d, y', currentLang);
      const timeString = formatDate(date, 'shortTime', currentLang);

      return `${dateString} at ${timeString}`;
    } else if (currentLang === InventoryConstants.LANGUAGES.FRENCH) {
      const dateString = formatDate(date, 'mediumDate', currentLang);
      const timeString = formatDate(date, 'HH\'h\'mm', currentLang);

      return `${dateString} à ${timeString}`;
    }
  }

  openLoadingModal() {
    this.dialog.open(LoadingModalComponent, {
      id: InventoryConstants.LOADING_MODAL_ID,
      panelClass: 'recipe-mat-dialog',
      disableClose: true
    });
  }
}

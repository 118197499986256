<div [class.noScrollBar]="options.hideScrollbar">
  <div
    #infiniteContainer
    infinite-container
    [style.height]="enable? scrollerHeight === 'auto' ? scrollerHeight : (options.containerHeight | pixels):''"
  >
    <ng-content>
    </ng-content>

    <div *ngFor="let row of data">
      <div
        *ngIf="rowTemplateRef"
        [ngTemplateOutlet]="rowTemplateRef"
        [ngTemplateOutletContext]="{$implicit:row}"
      >
      </div>
    </div>

    <div
      [ngSwitch]="this.options.loaderStyle"
      *ngIf="enable && rowSkeletonTemplateRef && isLoading()"
    >
      <ng-container *ngSwitchCase="'single'">
        <ng-container [ngTemplateOutlet]="rowSkeletonTemplateRef"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'many'">
        <ng-container
          [ngTemplateOutlet]="rowSkeletonTemplateRef"
          *ngFor="let row of skeletons"
        ></ng-container>
      </ng-container>
    </div>

    <div
      *ngIf="enable && ( isEOD() && scrollerHeight !== 'auto')"
      [ngTemplateOutlet]="eodTemplateRef"
      [ngTemplateOutletContext]="{$implicit:this}"
    >
    </div>

  </div>
</div>
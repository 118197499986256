import { inject } from '@angular/core';
import { Observable, forkJoin, map, of, tap } from 'rxjs';
import { ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Authorization, IAuthStateFacade, SetAuthorization } from '@gfs/store/common';
import { ActivatedAuthorizedRouteSnapshot } from '../models/ActivatedAuthorizedRouteSnapshot';

export const setFeatureAuthorizationResolver: ResolveFn<Observable<boolean>> = (
    route: ActivatedAuthorizedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store<IAuthStateFacade> = inject(Store<IAuthStateFacade>)
): Observable<boolean> => {
    return forkJoin([
        of(route.data),
        of(store)
    ])
        .pipe(
            tap(([data, store]) => {
                Object.entries(data.setAuthorizations)
                    .forEach(([authorization, value]: [Authorization, boolean]) =>
                        store.dispatch(new SetAuthorization({ authorization, value })))
            }),
            map(() => true)
        )
};

import { Injectable } from '@angular/core';
import { ResolveLocalizationsInObject } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public messages$ = new Subject<string>();
  constructor(
    private translate: TranslateService
  ) {}

  public queue(message: string, messageParams?: any , noLocalization?: boolean) {
    if(messageParams){
      if(!noLocalization){
        messageParams = ResolveLocalizationsInObject(messageParams, this.translate.currentLang);
        const localizedMessage = this.translate.instant(
          message,
          messageParams
        );
        this.messages$.next(localizedMessage);
      } else {
        const localizedMessage = this.translate.instant(
          message,
          messageParams
        );
        this.messages$.next(localizedMessage);
      }
    }else{
      this.messages$.next(message);
    }
}
}


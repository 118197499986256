import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { RecipeRouteAccessService } from '../services/recipe-route-access.service';


export const recipeRouteGuardFn: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    recipeRouteAccessService: RecipeRouteAccessService = inject(RecipeRouteAccessService),
): Observable<boolean> => {
    return recipeRouteAccessService.canActivate();
};

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import {  UpdateItemCategoryGLAccountInput } from '../models/general-ledger.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/inventory/reducers';


export interface AssignAccountsViewModel {
    assignAccountsTableData: {
        itemCategoryId: string,
        isCustomItemCategory: boolean,
        localizedItemCategoryName: string,
        accountId?: string,
        accountName?: string,
        fromFamily ?:boolean
    }[];
    dropdownOptions: AssignAccountsDropdownOption[];
}

export interface AssignAccountsDropdownOption {
    id: string;
    value: string;
    entityType: string;
}

@Component({
    selector: 'gfs-assign-accounts',
    templateUrl: './assign-accounts.component.html',
    styleUrls: ['./assign-accounts.component.scss']
})
export class AssignAccountsComponent {

    @Input() viewmodel: AssignAccountsViewModel;

    @Output() updateItemCategoryGLAccount = new EventEmitter<UpdateItemCategoryGLAccountInput>();

    displayedColumns = [
        // 'itemCategoryId',    // Not displayed
        // 'accountId',         // Not displayed
        'categoryType',
        'categoryName',
        'accountSelection'
    ];

    selectionChange(event: MatSelectChange, record: { itemCategoryId: string, accountId: string }) {
        this.updateItemCategoryGLAccount.emit({
            itemCategoryId: record.itemCategoryId,
            removeFromGeneralLedgerAccountId: record.accountId,
            addtoGeneralLedgerAccountId: event.value
        });
    }

    findNonFamilyUnits(dropdownOptions: any[], isFromFamily: boolean): any[] {
        if(null == isFromFamily){
            return dropdownOptions
        }
        else{ 
            return dropdownOptions.filter(option => option.entityType.endsWith("-customer"));    
        }

      }

}

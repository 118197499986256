import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Entitlement, IAppContext } from '@gfs/shared-models';
import { Store } from '@ngrx/store';
import { GetCartDataAttempt } from '@gfs/store/feature/cart/actions';
import { GetCustomerDataAttempt, ICustomerUnitSelectionFeatureBridge, IFeatureStateFacade } from '@gfs/store/feature/customer-unit-selection';
import { entityTypes } from '@gfs/constants';
import { Subscription } from 'rxjs';
import { InjectionTokens } from '@gfs/shared-services';
import { SelectLocation } from '@gfs/store/common';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'gfs-customer-unit-card',
  templateUrl: './customer-unit-card.component.html',
  styleUrls: ['./customer-unit-card.component.scss']
})
export class CustomerUnitCardComponent implements OnInit, OnDestroy {
  @Input() customerUnit: Entitlement;
  @Input() active?: boolean;
  @Input() isSubAccount;

  cartsQuantities$ = this.featureContext.cartsQuantities;
  customers$ = this.store.select(state => state.customerUnitSelectionFeature.customers);
  customersSubscription: Subscription;
  offsetEntityTypes: string[] = [entityTypes.CaChain, entityTypes.CaGroup, entityTypes.UsCustomerFamily, entityTypes.SapCommon, entityTypes.SapGroup];
  customerEntityTypes: string[] = [entityTypes.UsCustomer, entityTypes.CaCustomer, entityTypes.SapCustomer];
  offset = false;
  cartLoader$ = this.appContext.entitlements.pipe(
    tap((x) => {
      if (x.length < 50) {
        this.store.dispatch(new GetCustomerDataAttempt(this.customerUnit.customerPK));
        this.store.dispatch(
          new GetCartDataAttempt({
            customerPK: this.customerUnit.customerPK,
            includePrice: false,
          })
        );
      }
    })
  );

  constructor(
    private store: Store<IFeatureStateFacade>,
    @Inject(InjectionTokens.ICustomerUnitSelectionBridge) private featureContext: ICustomerUnitSelectionFeatureBridge,
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext
  ) { }

  ngOnInit() {

    this.offset = this.hasOffset();
  }

  ngOnDestroy() {
    this.customersSubscription?.unsubscribe();
  }

  /**
   * determine if the entity should be offset in the list UI
   *
   * @returns boolean - is the entity standalone or group/chain/family
   */
  private hasOffset(): boolean {
    return !this.isSubAccount;
  }

  /**
   * when user has clicked on a unit/location card,
   * select that unit/location and route them to /inventory
   */
  selectCustomerUnit(): void {
    this.store.dispatch(new SelectLocation({
      customerPK: this.customerUnit.customerPK,
      customerName: this.customerUnit.customerName
    }));
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { IAppContext, IDictionary } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gfs-localized-text-value',
  // template: '{{value | currency: \'CAD\' : \'$\' : undefined : (lang$ | async) }}',
  templateUrl: './localized-text.component.html'
})
export class LocalizedTextComponent implements OnInit {

  lang$ = this.appContext.language;
  localizedValue$ = new BehaviorSubject<IDictionary<string>>(null);
  renderValue$: Observable<string>;

  private mValue: IDictionary<string>;
  public get value(): IDictionary<string> {
    return this.mValue;
  }
  @Input()
  public set value(value: IDictionary<string>) {
    this.mValue = value;
    this.localizedValue$.next(this.mValue);
  }
  @Input()
  maxLength = 9999;
  /**
   *
   */
  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext,
  ) { }

  async ngOnInit() {
    this.renderValue$ = this.createLocalizedObservableString(
      this.lang$,
      this.localizedValue$.asObservable()
    );
  }


  createLocalizedObservableString(
    langSource: Observable<string>,
    localizationSource: Observable<IDictionary<string>>
  ) {

    return combineLatest([
      langSource, localizationSource.pipe(isTruthy())]
    )
      .pipe(
        map(
          ([lang, localizedValues]) => {
            return localizedValues[lang];
          }));
  }

}
import { Injectable } from '@angular/core';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';

// jsPDF is understand as a module by typescript instead of an
// actual class, because of how the library is exporting and declaring everything.
// When we try to make a new on jsPDF we get TS2351 in build server. Casting to any
// we circumvent that error.

@Injectable({
  providedIn: 'root',
})
export class JsPdfService {
  getJsPdfInstance(uom: 'pt' | 'px' | 'in' | 'mm' | 'cm' | 'ex' | 'em' | 'pc') {
    return new jsPDF({
      unit: uom,
      format: 'letter',
    });
  }
}

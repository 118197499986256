import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { HamburgerMenuComponent } from './hamburger-menu/hamburger-menu.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { RouterModule } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { InventoryIconService } from '@gfs/shared-services';
import { NetworkStatusComponent } from './network-status/network-status.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { InventoryToolsComponent } from './sub-header/inventory-tools/inventory-tools.component';
import { PipeSharingModule } from '../pipes/pipe-sharing.module';
import { SharedComponentsModule } from '../shared-components.module';
import { CustomerUnitSelectionModule } from '../customer-unit-selection/customer-unit-selection.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LegacyCustomerMigrationModule } from '../legacy-customer-migration/legacy-customer-migration.module';
import { ManageMenuComponent } from './manage-menu/manage-menu.component';

const { icons } = InventoryConstants;

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forChild(),
    FormsModule,
    MatGridListModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatExpansionModule,
    TranslateModule.forChild(),
    SharedComponentsModule,
    PipeSharingModule,
    RouterModule,
    CustomerUnitSelectionModule,
    LegacyCustomerMigrationModule
  ],
  declarations: [
    HeaderComponent,
    HamburgerMenuComponent,
    SubHeaderComponent,
    NetworkStatusComponent,
    AccountMenuComponent,
    InventoryToolsComponent,
    ManageMenuComponent
  ],
  exports: [HeaderComponent]
})

export class HeaderModule {
  readonly headerIcons = [icons.arrow, icons.search_icon];

  constructor(public inventoryIconService: InventoryIconService) {
    inventoryIconService.registerIcons(this.headerIcons);
  }
}

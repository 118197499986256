import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { WorksheetHttpService } from '@gfs/shared-services';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { WorksheetDTO } from '@gfs/v2/shared-models';
import { Observable, of } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetWorksheetResolverV2 implements Resolve<Observable<WorksheetDTO>> {

  constructor(
    private worksheetData: WorksheetHttpService,
    private loadingSpinner: LoadingSpinnerOverlayService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<WorksheetDTO> {
    return of(route.params)
      .pipe(
        first(),
        tap(() => {
          this.loadingSpinner.show();
        }
        ),
        concatMap(({ id: worksheetId }) => this.tryGetWorksheetById(worksheetId)),
        first(),
      );
  }

  tryGetWorksheetById(worksheetId: any): Observable<WorksheetDTO> {
    return this.worksheetData.createWorksheetGetRequest(worksheetId);
  }
}
<mat-form-field class="storage-area-select-container" floatLabel="never" *ngIf="worksheet$ | async">
  <mat-label class="storage-area-select-label">{{ 'INVENTORY_WORKSHEET.JUMP_TO_STORAGE_AREA_LABEL' | translate }}</mat-label>
  <mat-select panelClass="storage-area-select-panel">
    <mat-option
      *ngxPermissionsOnly="permissionRoles.createStorageArea"
      id="new-storage-area-option-button"
      class="new-storage-area-option storage-area-select-button"
      [disabled]="!(isOnline$ | async)"
      (click)="onAddStorageArea()">
      <div class="new-storage-area-label">
        <mat-icon [hidden]="!(isOnline$ | async)" class="icon storage-area-plus-icon" svgIcon="plus-icon"></mat-icon>
        <mat-icon [hidden]="isOnline$ | async" class="icon storage-area-plus-icon" svgIcon="plus-icon-gray"></mat-icon>
        {{ 'STORAGE_AREA.ADD_STORAGE_AREA'  | translate }}
      </div>
    </mat-option>
    <mat-option class="new-storage-area-option storage-area-select-button" (click)="storageAreaSelected({id: 'unassigned'})">
      {{ 'INVENTORY_WORKSHEET.UNASSIGNED' | translate}}
    </mat-option>
    <mat-option
      class="new-storage-area-option storage-area-select-button"
      *ngFor="let storageArea of (getStorageAreasMinusUnassigned() | async)"
      (click)='storageAreaSelected(storageArea)'>{{ getTranslation(storageArea.name)  | translate | truncated:17 }}
    </mat-option>
  </mat-select>
</mat-form-field>

import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Customer, CustomerPK } from '@gfs/shared-models';

export enum ActionTypes {
  GetCustomerDataAttempt = '[Customer] Get Customer Data Attempt',
  GetCustomerDataSuccess = '[Customer] Get Customer Data Success',
  GetCustomerDataError = '[Customer] Get Customer Data Error'
}

export class GetCustomerDataAttempt implements Action {
  readonly type = ActionTypes.GetCustomerDataAttempt;
  constructor(public payload: CustomerPK) {}
}

export class GetCustomerDataSuccess implements Action {
  readonly type = ActionTypes.GetCustomerDataSuccess;
  constructor(public payload: Customer) {}
}

export class GetCustomerDataError implements Action {
  readonly type = ActionTypes.GetCustomerDataError;
  constructor(public payload: HttpErrorResponse) {}
}

export type ActionUnion =
  GetCustomerDataAttempt
  | GetCustomerDataSuccess
  | GetCustomerDataError;

import { formatDate } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import {
  CountableItem, CountingUnit, CustomPortioningUnit,
  MeasurementUnit, StorageArea, Supplier, WorksheetItem, WorksheetPrintInfo, WorksheetPrintMetaInfo
} from '@gfs/shared-models';
import { LocalizedValueUtilsService, StorageAreaUtilsService, WINDOW } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Observable, Observer, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { JsPdfService } from '../js-pdf/js-pdf.service';
import { WorksheetPrintConstants } from './worksheet-print-constants';

export interface ProductInfoTableRow {
  item: string,
  packSize: string,
  brand: string,
  description: string,
  primaryCountUnit: string,
  primaryCountQty: string,
  secondaryCountUnit: string,
  secondaryCountQty: string,
}

@Injectable({
  providedIn: 'root'
})
export class WorksheetPrintService {
  static readonly PRINT_ANALYTICS_STORAGE_AREA = 'general';
  static readonly PRINT_ANALYTICS_ACTION = 'print';
  static readonly PRINT_ANALYTICS_LABEL = 'pdf';
  static readonly CTRLP_PRINT_ANALYTICS_LABEL = 'ctrlp';

  private styles = {
    fillColor: WorksheetPrintConstants.PAGE_FILL_COLOR,
    textColor: WorksheetPrintConstants.PAGE_TEXT_COLOR,
    fontSize: WorksheetPrintConstants.FONT_SIZE_MEDIUM,
    minCellHeight: WorksheetPrintConstants.MIN_CELL_HEIGHT,
    lineColor: WorksheetPrintConstants.TABLE_LINE_COLOR,
    lineWidth: WorksheetPrintConstants.TABLE_LINE_WIDTH,
    cellPadding: WorksheetPrintConstants.CELL_PADDING,
    overflow: 'linebreak'
  };

  private columnStyles = {
    item: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_ITEM_WIDTH,
      overflow: 'linebreak',
      valign: 'middle'
    },
    packSize: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_PACK_SIZE_WIDTH,
      overflow: 'linebreak',
      valign: 'middle',
      halign: 'center'
    },
    brand: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_BRAND_WIDTH,
      overflow: 'linebreak',
      valign: 'middle'
    },
    description: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_DESCRIPTION_WIDTH,
      overflow: 'linebreak',
      valign: 'middle'
    },
    primaryCountUnit: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_PRIMARY_UNIT_WIDTH,
      overflow: 'ellipsize',
      valign: 'middle'
    },
    primaryCountQty: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_INPUT_FIELD_WIDTH,
      overflow: 'ellipsize',
      valign: 'middle'
    },
    secondaryCountUnit: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_SECONDARY_UNIT_WIDTH,
      overflow: 'ellipsize',
      valign: 'middle'
    },
    secondaryCountQty: {
      cellWidth: WorksheetPrintConstants.PRINT_COLUMN_INPUT_FIELD_WIDTH,
      overflow: 'ellipsize',
      valign: 'middle'
    }
  };

  private headStyles = {
    overflow: 'linebreak',
    cellPadding: 0,
    halign: 'center',
    valign: 'bottom',
    minCellHeight: WorksheetPrintConstants.TABLE_HEADER_MIN_CELL_HEIGHT,
    lineWidth: 0
  };

  beforePrint: () => Observable<WorksheetPrintInfo>;
  afterPrint: () => void;
  private todaysDate: number = Date.now();
  customerBrand: string;
  printLogo: string;

  constructor(
    private translateService: TranslateService,
    private jsPdfService: JsPdfService,
    private storageAreaUtils: StorageAreaUtilsService,
    private localizedValueUtils: LocalizedValueUtilsService,
    @Inject(WINDOW) private window: Window
  ) {
  }

  setUp(
    beforePrint?: () => Observable<WorksheetPrintInfo>,
    afterPrint?: () => void
  ): void {
    this.beforePrint = beforePrint;
    this.afterPrint = afterPrint;
  }

  tearDown(): void {
    this.beforePrint = null;
    this.afterPrint = null;
  }

  print(countingUnits: CountingUnit[], suppliers: Supplier[]): Observable<boolean> {

    let trigger$ = of({ name: '', worksheet: null });
    if (this.beforePrint) {
      trigger$ = this.beforePrint();
    }

    const worksheetPrintMetaInfo: WorksheetPrintMetaInfo = {
      translations: {},
      logoImageData: '',
      printDate: '',
      totalPages: ''
    };
    const doc: jsPDF = this.jsPdfService.getJsPdfInstance(
      WorksheetPrintConstants.PAGE_UOM
    );

    return trigger$
      .pipe(
        first(),
        concatMap((printInfo: WorksheetPrintInfo) => {
          worksheetPrintMetaInfo.worksheetPrintInfo = printInfo;
          worksheetPrintMetaInfo.totalPages = '{}';
          worksheetPrintMetaInfo.printDate = this.getDate();

          return this.getTranslations();
        }),
        concatMap((translations: { [key: string]: string }) => {
          worksheetPrintMetaInfo.translations = translations;
          return this.fetchImageData(
            worksheetPrintMetaInfo.translations[this.printLogo]
          ).pipe(first());
        }),
        concatMap(async logoDataUrl => {
          worksheetPrintMetaInfo.logoImageData = logoDataUrl;

          const storageAreaPages = await Promise.all(worksheetPrintMetaInfo.worksheetPrintInfo.selectedStorageAreas
            .filter((storageArea) =>
              worksheetPrintMetaInfo.worksheetPrintInfo.items
              && storageArea
              && storageArea.worksheetItems.length > 0)
            .map(async (storageArea, i) => ({
              name: storageArea.name,
              head: this.buildTableHeader(
                worksheetPrintMetaInfo.translations,
                storageArea.worksheetItems
              ),
              body: await this.buildStorageAreaTable(storageArea,
                worksheetPrintMetaInfo.worksheetPrintInfo.items,
                worksheetPrintMetaInfo.worksheetPrintInfo.customUnits,
                worksheetPrintMetaInfo.worksheetPrintInfo.standardUnits,
                countingUnits,
                suppliers)
            })));


          this.buildPDF(doc, worksheetPrintMetaInfo, storageAreaPages);
          if (this.afterPrint) {
            this.afterPrint();
          }

          return true;
        })
      );
  }

  private addStorageAreaName(
    doc: jsPDF,
    currentStorageAreaName: string
  ): number {
    const storageAreaName = this.translateService.instant(this.storageAreaUtils.getTranslation(currentStorageAreaName));
    let yPosition = this.getYCoordinate(doc);

    if (
      this.getPageHeight(doc) - yPosition >=
      WorksheetPrintConstants.PAGE_MARGIN_BOTTOM_Y_THRESHOLD
    ) {
      doc.setFontSize(WorksheetPrintConstants.FONT_SIZE_LARGE);
      doc.setFont(WorksheetPrintConstants.FONT_FAMILY_SANS, WorksheetPrintConstants.FONT_STYLE_BOLD);
      doc.setCharSpace(WorksheetPrintConstants.TEXT_CHAR_SPACE_BODY);

      doc.text(
        storageAreaName.toUpperCase(),
        WorksheetPrintConstants.PAGE_SIDE_MARGIN,
        yPosition
      );

    } else {
      // to avoid just table header getting printed at bottom with body
      yPosition =
        yPosition + WorksheetPrintConstants.PAGE_MARGIN_BOTTOM_Y_THRESHOLD;
    }
    return yPosition;
  }

  public buildPDF(
    doc: jsPDF,
    worksheetPrintMetaInfo: WorksheetPrintMetaInfo,
    reportData: {
      name: string;
      head: ProductInfoTableRow[];
      body: ProductInfoTableRow[];
    }[]
  ): void {
    if (!worksheetPrintMetaInfo ||
      !worksheetPrintMetaInfo.worksheetPrintInfo ||
      !worksheetPrintMetaInfo.worksheetPrintInfo.worksheet ||
      worksheetPrintMetaInfo.worksheetPrintInfo.worksheet.storageAreas.length === 0
    ) {
      return;
    }
    let lastPageNumDrawn = 0;

    doc.setFont(WorksheetPrintConstants.FONT_FAMILY_SANS, WorksheetPrintConstants.FONT_STYLE_NORMAL);

    reportData.forEach((storageArea, i) => {
      const yPosition = this.addStorageAreaName(doc, storageArea.name);
      (doc as any).autoTable({
        head: storageArea.head,
        body: storageArea.body,
        theme: 'grid',
        tableWidth: WorksheetPrintConstants.TABLE_WIDTH,
        startY: yPosition + WorksheetPrintConstants.TABLE_OFFSET_FROM_STORAGE_AREA,
        styles: this.styles,
        headStyles: this.headStyles,
        columnStyles: this.columnStyles,
        margin: {
          top: WorksheetPrintConstants.PAGE_MARGIN_TOP,
          right: WorksheetPrintConstants.PAGE_SIDE_MARGIN,
          left: WorksheetPrintConstants.PAGE_SIDE_MARGIN,
          bottom: WorksheetPrintConstants.PAGE_MARGIN_BOTTOM
        },
        rowPageBreak: 'avoid',
        didParseCell: (data: any) => this.styleCells(data),
        didDrawCell: (data: any) => {
          this.drawCellsWithDivider(data);
        },
        didDrawPage: () => {
          if (lastPageNumDrawn !== doc.internal.pages.length) {
            this.drawCurrentPage(doc, worksheetPrintMetaInfo, storageArea.name);
            lastPageNumDrawn = doc.internal.pages.length;
          }
        }
      });
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(worksheetPrintMetaInfo.totalPages);
    }

    if ((doc as any).previousAutoTable) {
      (doc as any).previousAutoTable.finalY = undefined;
    }
    this.generatePDF(doc, worksheetPrintMetaInfo.worksheetPrintInfo.name, worksheetPrintMetaInfo.worksheetPrintInfo.mobile);
  }

  private drawCurrentPage(
    doc: jsPDF,
    worksheetPrintMetaInfo: WorksheetPrintMetaInfo,
    storageAreaName: string
  ) {
    this.drawPageHeader(doc, worksheetPrintMetaInfo, storageAreaName);
    this.drawPageFooter(
      doc,
      worksheetPrintMetaInfo.totalPages,
      worksheetPrintMetaInfo.translations
    );
  }

  private getYCoordinate(doc: jsPDF): number {
    return (doc as any).previousAutoTable.finalY === undefined
      ? WorksheetPrintConstants.HEADER_HEIGHT +
      WorksheetPrintConstants.TABLE_STORAGE_AREA_LINE_HEIGHT +
      WorksheetPrintConstants.TABLE_STORAGE_AREA_MARGIN_TOP
      : (doc as any).previousAutoTable.finalY +
      WorksheetPrintConstants.TABLE_STORAGE_AREA_LINE_HEIGHT +
      WorksheetPrintConstants.TABLE_STORAGE_AREA_MARGIN_TOP;
  }

  private getPageHeight(doc: jsPDF): number {
    return doc.internal.pageSize.height
      ? doc.internal.pageSize.height
      : doc.internal.pageSize.getHeight();
  }

  private isEmptyStorageArea(items: WorksheetItem[]): boolean {
    return (
      items ? items.length < 1 : false
    );
  }

  /**
   * Converts local logo images into base64 encoded string
   */
  private getBase64EncodedImage(
    callback: (dataUrl: string) => any,
    filePath: string
  ): void {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function() {
      const canvas: any = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      canvas.height = (this as HTMLImageElement).naturalHeight;
      canvas.width = (this as HTMLImageElement).naturalWidth;
      ctx.drawImage(this, 0, 0);
      const dataURL = canvas.toDataURL('image/jpeg');
      callback(dataURL);
    };
    img.onerror = function() {
      callback(null);
    };
    img.src = filePath;
  }

  public fetchImageData(filePath: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.getBase64EncodedImage(base64 => {
        observer.next(base64);
        observer.complete();
      }, filePath);
    });
  }

  private generatePDF(doc: jsPDF, filename: string, isMobile: boolean): void {
    doc.autoPrint();
    doc.save(`${filename}.pdf`);
  }

  public getTranslations(): Observable<{ [key: string]: string }> {
    this.printLogo = WorksheetPrintConstants.PRINT_LOGO_FILEPATH;
    return this.translateService.get([
      WorksheetPrintConstants.PRINT_COLUMN_ITEM,
      WorksheetPrintConstants.PRINT_COLUMN_PACK_SIZE,
      WorksheetPrintConstants.PRINT_COLUMN_BRAND,
      WorksheetPrintConstants.PRINT_COLUMN_DESCRIPTION,
      WorksheetPrintConstants.PRINT_COLUMN_PRIMARY_UNIT,
      WorksheetPrintConstants.PRINT_COLUMN_SECONDARY_UNIT,
      WorksheetPrintConstants.INVENTORY_FIELDS_HEADER,
      WorksheetPrintConstants.PRINT_UNASSIGNED_STORAGE_AREA,
      this.printLogo,
      WorksheetPrintConstants.PRINT_HEADER_PAGE_COUNT_DATE,
      WorksheetPrintConstants.PRINT_HEADER_PAGE_CREATED,
      WorksheetPrintConstants.PRINT_FOOTER_PAGE
    ]);
  }

  private buildTableHeader(
    translations: { [key: string]: string },
    items: WorksheetItem[]
  ): ProductInfoTableRow[] {
    if (this.isEmptyStorageArea(items)) {
      return [];
    }
    return [
      {
        item: translations[WorksheetPrintConstants.PRINT_COLUMN_ITEM],
        packSize: translations[WorksheetPrintConstants.PRINT_COLUMN_PACK_SIZE],
        brand: translations[WorksheetPrintConstants.PRINT_COLUMN_BRAND],
        description: translations[WorksheetPrintConstants.PRINT_COLUMN_DESCRIPTION],
        primaryCountUnit: translations[WorksheetPrintConstants.PRINT_COLUMN_PRIMARY_UNIT],
        primaryCountQty: '',
        secondaryCountUnit: translations[WorksheetPrintConstants.PRINT_COLUMN_SECONDARY_UNIT],
        secondaryCountQty: ''
      }
    ];
  }

  private async buildStorageAreaTable(
    storageArea: StorageArea,
    gfsItems: { [s: string]: CountableItem },
    customUnits: { [s: string]: CustomPortioningUnit[] },
    standardUnits: MeasurementUnit[],
    countingUnits: CountingUnit[],
    suppliers: Supplier[]
  ) {

    const sortedItems = this.getSortedWorksheetItems(storageArea);
    return await Promise.all(sortedItems.map(async wsItem => {
      const countableItem = gfsItems[wsItem.itemId];
      let itemCode = wsItem.itemId;
      const itemId = wsItem.itemType === 'GENERAL'
        ? gfsItems[wsItem.itemId]?.customItem?.id ?? wsItem.itemId
        : wsItem.itemId;
      let brandLocalized = null;
      let packSize: string = null;
      let descLocalized = null;

      if (!countableItem) {
        descLocalized = {
          value: this.translateService.instant('STORAGE_AREA.STORAGE_AREA_CARD.MISSING_ITEM.DESCRIPTION', { itemId: wsItem.itemId })
        };
      } else if (countableItem.gfsItem) {
        const gfsItem = countableItem.gfsItem;
        if (gfsItem.brand) {
          brandLocalized = gfsItem.brand.find(brand => brand.languageCode === this.translateService.currentLang);
          if (!brandLocalized || !brandLocalized.value) {
            brandLocalized = gfsItem.brand.length > 0 ? gfsItem.brand[0] : null;
          }
        }

        if (gfsItem.description) {
          descLocalized = gfsItem.description.find(description => description.languageCode === this.translateService.currentLang);
          if (!descLocalized || !descLocalized.value) {
            descLocalized = gfsItem.description.length > 0 ? gfsItem.description[0] : null;
          }
        }

        //Pack Size Information Setting

        //Loop through gfsItems[wsItem.itemId]?.gfsItem?.units?.forEach
        const [a, b, c] = [
          await this.storageAreaUtils.getLocalizedUnitCountLabelByCountableItem('unit', countingUnits, this.translateService.currentLang)
            .pipe(first()).toPromise(),
          await this.storageAreaUtils.getLocalizedUnitCountLabelByCountableItem(
            'unit', countingUnits, this.translateService.currentLang, gfsItems[wsItem.itemId])
            .pipe(first()).toPromise(),
          await this.storageAreaUtils.getLocalizedUnitCountLabelByCountableItem('case', countingUnits, this.translateService.currentLang)
            .pipe(first()).toPromise()
        ];
        let gfsItemPurchaseUnit = null
        let gfsLocalizedPurchaseUnitDisplay = null
        gfsItems[wsItem.itemId]?.gfsItem?.units?.forEach(un => {
          if(un.standardUnitId === "CS") {
            gfsItemPurchaseUnit = un.displayCode
          }
        })
        if(null != gfsItemPurchaseUnit){
          gfsLocalizedPurchaseUnitDisplay = await this.storageAreaUtils.getLocalizedCountingUnitDisplay_GFS_ITEM(
            gfsItems[wsItem.itemId], gfsItemPurchaseUnit, this.translateService.currentLang)
            .pipe(first())
            .toPromise()
        }

        let unitLiteralDescription = ""

        if ((wsItem.primaryUnit === "UNIT" && wsItem.primaryUnitType === "LITERAL")
          || (wsItem.secondaryUnit === "UNIT" && wsItem.secondaryUnitType === "LITERAL")) {

            unitLiteralDescription = '/' + a + ', '

      }

        //Pack size Printing
          packSize = gfsItems[wsItem.itemId]?.gfsItem?.innerPackSize
          + unitLiteralDescription
          + gfsItems[wsItem.itemId]?.gfsItem?.qtyPerMasterSellUnit
          + ' '
          + b
          + '/'
          + (gfsLocalizedPurchaseUnitDisplay ?? c)
       
      } else if (countableItem.customItem) {
        const customItem = countableItem.customItem;
        descLocalized = { value: customItem.description };
        itemCode = customItem.supplierItemCode;

        packSize = this.storageAreaUtils.getPackSizeInformationForCustomItem(
          wsItem,
          this.translateService.currentLang,
          countingUnits,
          countableItem,
          standardUnits,
          await this.storageAreaUtils.getLocalizedUnitCountLabelByCountableItem('case', countingUnits, 'en_CA')
            .pipe(first())
            .toPromise()
        )
        brandLocalized = { value: suppliers.find(sub => sub.id === customItem.supplierId)?.name ?? '' };
      }

      return {
        item: itemCode,
        packSize,
        brand: brandLocalized ? brandLocalized.value : '',
        description: descLocalized ? descLocalized.value : '',
        primaryCountUnit: await this.getDefaultCountUnitTranslation(wsItem.primaryUnitType, wsItem.primaryUnit,
          customUnits[itemId], standardUnits, gfsItems[wsItem.itemId]),
        primaryCountQty: '',
        secondaryCountUnit: await this.getDefaultCountUnitTranslation(wsItem.secondaryUnitType, wsItem.secondaryUnit,
          customUnits[itemId], standardUnits, gfsItems[wsItem.itemId]),
        secondaryCountQty: ''
      };
    }));
  }

  private styleCells(data: any): void {

    if (data.section === 'body' && (
      data.column.dataKey === 'primaryCountUnit' ||
      data.column.dataKey === 'primaryCountQty' ||
      data.column.dataKey === 'secondaryCountUnit' ||
      data.column.dataKey === 'secondaryCountQty'
    )) {
      data.cell.styles.lineWidth = 0;
    }

    // Adjust for Description header
    if (data.section === 'head' && (
      data.column.dataKey === 'description'
    )) {
      data.cell.styles.cellPadding = {
        top: 0,
        right: 0,
        bottom: 0,
        left: WorksheetPrintConstants.DESCRIPTION_HEADER_LEFT_PADDING
      };
      data.cell.styles.halign = 'left';
    }

    // Adjust for count unit headers
    if (data.section === 'head' && (
      data.column.dataKey === 'primaryCountUnit' ||
      data.column.dataKey === 'secondaryCountUnit'
    )) {
      data.cell.styles.cellPadding = {
        top: 0,
        right: 0,
        bottom: 0,
        left: WorksheetPrintConstants.COUNT_HEADER_LEFT_PADDING
      };
      data.cell.colSpan = 2;
      data.cell.styles.halign = 'left';
    }
  }

  /**
   * Draws input box divider for each input column
   *
   * @param data - jsPDF HookData
   */
  private drawCellsWithDivider(data: any): void {
    // Manually draw cell borders for cells with divider
    if (data.section === 'body' && this.isCountField(data)) {
      data.doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
      data.doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
      data.doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
      data.doc.line(data.cell.x + data.cell.width,
        data.cell.y + WorksheetPrintConstants.COLUMN_DIVIDER_OFFSET,
        data.cell.x + data.cell.width,
        data.cell.y + data.cell.height - WorksheetPrintConstants.COLUMN_DIVIDER_OFFSET);
    }

    if (data.section === 'body' &&
      data.column.dataKey === 'primaryCountQty' ||
      data.column.dataKey === 'secondaryCountQty') {
      data.doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
      data.doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
      data.doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
    }
  }

  /**
   * Calculates the x co-ordinate for drawing a right align text
   * JSPDF does not provide right align to page property
   */
  private getTextOffset(doc: jsPDF, text: string): number {
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.getFontSize()) /
      doc.internal.scaleFactor;
    return (
      doc.internal.pageSize.width -
      textWidth -
      WorksheetPrintConstants.PAGE_SIDE_MARGIN
    );
  }

  private drawPageHeader(
    doc: jsPDF,
    worksheetPrintMetaInfo: WorksheetPrintMetaInfo,
    storageAreaname: string
  ): void {
    const originalCharSpace = doc.getCharSpace();
    doc.setFont(WorksheetPrintConstants.FONT_FAMILY_SANS, WorksheetPrintConstants.FONT_STYLE_BOLD);
    doc.setCharSpace(WorksheetPrintConstants.TEXT_CHAR_SPACE_BODY);
    // TODO: Implement once Count Date is available
    // doc.text(
    //   this.translateService.instant(WorksheetPrintConstants.PRINT_HEADER_PAGE_COUNT_DATE),
    //   doc.internal.pageSize.width / 2,
    //   WorksheetPrintConstants.TITLE_MARGIN_TOP,
    //   'center'
    // );
    let worksheetName = worksheetPrintMetaInfo.worksheetPrintInfo.name.toUpperCase();
    if (worksheetName.toLowerCase() === 'Untitled Inventory'.toLowerCase()
      || worksheetName.toLowerCase() === 'Inventaire sans titre'.toLowerCase()) {
      worksheetName = this.translateService.instant('INVENTORY_WORKSHEET.UNTITLED_INVENTORY');
    }
    const customerInfo = `${worksheetPrintMetaInfo.worksheetPrintInfo.customerName} #${worksheetPrintMetaInfo.worksheetPrintInfo.customerId
      }`;
    doc.setLineWidth(WorksheetPrintConstants.PAGE_MARGIN_LINE_WIDTH);

    doc.text(
      worksheetName,
      this.getTextOffset(doc, worksheetName) - 30,
      WorksheetPrintConstants.TITLE_MARGIN_TOP
    );

    doc.setFont(WorksheetPrintConstants.FONT_FAMILY_SANS, WorksheetPrintConstants.FONT_STYLE_NORMAL);

    const createdDate = `${this.translateService.instant(WorksheetPrintConstants.PRINT_HEADER_PAGE_CREATED)} ${worksheetPrintMetaInfo.worksheetPrintInfo.formattedCreatedDate
      }`;
    doc.text(
      createdDate,
      doc.internal.pageSize.width / 2 - 40,
      WorksheetPrintConstants.SUBTITLE_MARGIN_TOP,
      { align: 'center' }
    );

    doc.text(
      customerInfo,
      this.getTextOffset(doc, customerInfo) - 30,
      WorksheetPrintConstants.SUBTITLE_MARGIN_TOP
    );
    doc.setDrawColor(0, 0, 0);
    doc.line(
      WorksheetPrintConstants.PAGE_SIDE_MARGIN,
      WorksheetPrintConstants.HEADER_HEIGHT,
      WorksheetPrintConstants.PAGE_SIDE_MARGIN + WorksheetPrintConstants.TABLE_WIDTH,
      WorksheetPrintConstants.HEADER_HEIGHT
    );

    if (worksheetPrintMetaInfo.logoImageData) {
      let logoHeight = WorksheetPrintConstants.LOGO_HEIGHT;
      let logoWidth = WorksheetPrintConstants.LOGO_WIDTH;
      let logoMarginTop = WorksheetPrintConstants.LOGO_MARGIN_TOP;
      // Adjust for french logo
      if (this.translateService.currentLang === InventoryConstants.LANGUAGES.FRENCH) {
        logoHeight = WorksheetPrintConstants.LOGO_HEIGHT_FR;
        logoWidth = WorksheetPrintConstants.LOGO_WIDTH_FR;
        logoMarginTop = WorksheetPrintConstants.LOGO_MARGIN_TOP_FR;
      }

      doc.addImage(
        worksheetPrintMetaInfo.logoImageData,
        WorksheetPrintConstants.LOGO_BASE64_FILE_TYPE,
        WorksheetPrintConstants.PAGE_SIDE_MARGIN,
        logoMarginTop,
        logoWidth,
        logoHeight,
      );
    }
    doc.setFont(WorksheetPrintConstants.FONT_FAMILY_SANS, WorksheetPrintConstants.FONT_STYLE_BOLD);

    if (doc.internal.pages.length > 1) {
      const storageAreaName = this.translateService.instant(this.storageAreaUtils.getTranslation(
        storageAreaname));
      doc.text(
        storageAreaName.toUpperCase(),
        WorksheetPrintConstants.PAGE_SIDE_MARGIN,
        WorksheetPrintConstants.HEADER_HEIGHT +
        WorksheetPrintConstants.TABLE_STORAGE_AREA_LINE_HEIGHT +
        WorksheetPrintConstants.TABLE_STORAGE_AREA_MARGIN_TOP
      );
      doc.setCharSpace(originalCharSpace);
    }
  }

  private drawPageFooter(
    doc: jsPDF,
    totalPages: string,
    translations: { [key: string]: string }
  ): void {
    const originalCharSpace = doc.getCharSpace();
    doc.setCharSpace(WorksheetPrintConstants.TEXT_CHAR_SPACE_FOOTER);
    doc.setFontSize(WorksheetPrintConstants.FONT_SIZE_MEDIUM);
    doc.setDrawColor(220, 220, 220);
    doc.setLineWidth(WorksheetPrintConstants.PAGE_MARGIN_LINE_WIDTH);

    const footer = this.translateService.parser.interpolate(
      translations[WorksheetPrintConstants.PRINT_FOOTER_PAGE],
      {
        currentPage: doc.internal.pages.length - 1,
        totalPages
      }
    );

    const pageHeight = this.getPageHeight(doc);

    doc.line(
      WorksheetPrintConstants.PAGE_SIDE_MARGIN,
      pageHeight - WorksheetPrintConstants.PAGE_MARGIN_BOTTOM,
      WorksheetPrintConstants.PAGE_SIDE_MARGIN + WorksheetPrintConstants.TABLE_WIDTH,
      pageHeight - WorksheetPrintConstants.PAGE_MARGIN_BOTTOM
    );

    doc.text(
      footer,
      this.getTextOffset(doc, footer),
      pageHeight - WorksheetPrintConstants.FOOTER_MARGIN_BOTTOM
    );
    doc.setCharSpace(originalCharSpace);
  }

  private getDate(): string {
    return formatDate(this.todaysDate, 'shortDate', this.translateService.currentLang);
  }

  private isCountField(data: any): boolean {
    return (
      data.column.dataKey === 'primaryCountUnit' ||
      data.column.dataKey === 'secondaryCountUnit'
    );
  }

  async getDefaultCountUnitTranslation(
    type: string,
    unit: string,
    customUnits: CustomPortioningUnit[],
    standardUnits: MeasurementUnit[],
    gfsItem: CountableItem
  ): Promise<string> {
    if(unit === "--" || unit === ""){
      return "--"
    }
    if(unit === "CASE" && type === "LITERAL"){
      if (!!gfsItem && !!gfsItem.gfsItem && !!gfsItem.gfsItem.units) {
        //Manually converting to type SAP for v1 compatibility
        unit = "CS"
        type = "SAP"
      }
    }
    
    if (type === 'CUSTOM') {
      if (customUnits) {
        unit = customUnits.find(c => c.id === unit)?.name;
      }
    } else if (type === 'STANDARD') {
      const measurementUnit = standardUnits.find(s => s.id === unit);
      unit = this.localizedValueUtils.getLocalizedMeasurementUnitName(measurementUnit, this.translateService.currentLang);
    } else if (type === 'SAP') {
      let displayCode = unit;
      gfsItem?.gfsItem?.units?.forEach(un => {
        if (un.standardUnitId === unit) {
          displayCode = un.displayCode
        }
      })
      let displayText =  await this.storageAreaUtils.getLocalizedCountingUnitDisplay_GFS_ITEM(
        gfsItem, displayCode, this.translateService.currentLang)
        .pipe(first())
        .toPromise();
        if(displayText == 'Case'){
          displayText = this.translateService.instant('PRINT.CASE');
        }
        unit = displayText

    } else {
      if (unit === 'CASE') {
        unit = this.translateService.instant('PRINT.CASE');
      } else if (unit === 'UNIT') {
        unit = this.translateService.instant('PRINT.UNIT');
      }
    }
    return unit;
  }

  private getSortedWorksheetItems(storageArea: StorageArea): WorksheetItem[] {
    const sortedItems: WorksheetItem[] = !storageArea.worksheetItemOrder ?
      storageArea.worksheetItems
      : storageArea.worksheetItemOrder
        .map(itemId => storageArea.worksheetItems.find(item => item.id === itemId))
        .filter(item => !!item);

    const itemsLeftUnsorted = storageArea.worksheetItems
      .filter(unsortedItem => !sortedItems.some(sortedItem => sortedItem.id === unsortedItem.id));

    const allItems = sortedItems.concat(itemsLeftUnsorted);

    return allItems;
  }
}
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InjectionTokens } from '../../injection-tokens';
import { CustomerSearchRequest, CustomerSearchResult } from '@gfs/shared-models';

@Injectable({
    providedIn: 'root'
})
export class CustomerSearchService {
    private baseUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string
    ) {
        this.baseUrl = API_BASE_URL + '/api/v1';
    }

    searchCustomerLocations(
        request: CustomerSearchRequest
    ): Observable<CustomerSearchResult[]> {
        const params = Object.entries(request)
            .reduce((accumulation, [key, value]) => {
                if (value) {
                    accumulation[key] = value;
                }
                return accumulation;
            }, {});
        return this.http.get<CustomerSearchResult[]>(`${this.baseUrl}/customer-search`, { params });
    }
}

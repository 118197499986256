<div class="modal-container">
  <div class="mat-dialog-header modal-header">
    <div class="modal-title">
      <span>{{ 'NON_GFS_ITEMS_DIALOG.HEADER' | translate }}</span>
      <button mat-icon-button class="close-button" (click)="closeModal()"
        attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
        <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE' | translate }}" />
      </button>
    </div>
  </div>
  <div mat-dialog-content class="non-gfs-info-dialog-modal">
      <h4>{{ 'NON_GFS_ITEMS_DIALOG.TITLE' | translate }}</h4>
      <p>{{ 'NON_GFS_ITEMS_DIALOG.INFO' | translate  }}</p>
      <p><strong>{{ 'NON_GFS_ITEMS_DIALOG.NOTE' | translate  }}</strong></p>
      <p>{{ 'NON_GFS_ITEMS_DIALOG.INFO1' | translate  }}</p>
      
      <h4>{{ 'NON_GFS_ITEMS_DIALOG.RECIPE' | translate  }}</h4>
      <p>{{ 'NON_GFS_ITEMS_DIALOG.INFO2' | translate  }}</p>
      
      <h4>{{ 'NON_GFS_ITEMS_DIALOG.INVENTORY' | translate  }}</h4>
      <p>{{ 'NON_GFS_ITEMS_DIALOG.INFO3' | translate  }}</p>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { entityTypes } from '@gfs/constants';
import { CustomerPK, Entitlement, IAppContext } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, first, map, timeout } from 'rxjs/operators';

export interface ParentLocation {
  entitlement: Entitlement;
  subLocations: Entitlement[];
}

@Component({
  selector: 'gfs-customer-unit-selection',
  templateUrl: './customer-unit-selection.component.html',
  styleUrls: ['./customer-unit-selection.component.scss'],
})
export class CustomerUnitSelectionComponent implements OnInit {

  isMobile$ = this.appContext.isMobile;
  entitlements$ = this.appContext.entitlements;

  selectedCustomerPK$ = this.appContext.customerPK
    .pipe(
      first(),
      timeout({
        each: 750,
        with: () => {
          // Timeout enables customer unit selection to populate with entitlements
          //  even when app state does not have a selected customer unit unit
          return of({} as CustomerPK)
        }
      })
    );

  otherEntitlements$: Observable<ParentLocation[]>;
  routeData$ = this.activatedRoute.data;
  assignedEntitlements: Entitlement[] = [];

  searchText: string;
  selectedUnit: Entitlement;
  otherEntitlementsSubject: BehaviorSubject<ParentLocation[]> = new BehaviorSubject<ParentLocation[]>([]);
  offsetEntityTypes: string[] = [
    entityTypes.CaChain,
    entityTypes.CaGroup,
    entityTypes.UsCustomerFamily,
    entityTypes.SapCommon,
    entityTypes.SapGroup
  ];

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.otherEntitlements$ = this.otherEntitlementsSubject
      .pipe(
        map(parentLocations => parentLocations
          .map(parentLocation => {
            const distinctSubLocations = parentLocation.subLocations
              .reduce((acc, curr) => ({ ...acc, [curr.customerPK.customerId]: curr }), {});

            const subLocationArray = Object.keys(distinctSubLocations)
              .map(customerName => distinctSubLocations[customerName]);

            return { ...parentLocation, subLocations: subLocationArray };
          }))
      );

    combineLatest([
      this.selectedCustomerPK$,
      this.entitlements$,
      this.routeData$,
    ])
      .pipe(filter(([, perms,]) => {
        return !!perms;
      }))
      .subscribe(([customerPK, entitlements, data]) => {
        this.assignedEntitlements = entitlements.filter(
          entitlement => entitlement.roleName.toLocaleLowerCase().includes(data.entitlementFilter)
        );

        if (customerPK) {
          this.selectedUnit = this.assignedEntitlements.find(
            (entitlement: Entitlement) =>
              entitlement.customerPK.customerId === customerPK.customerId
          );
        }
        this.buildDisplayUnits();
      });
  }

  private buildDisplayUnits() {
    // get all entitlements that should be left aligned (groups, chains, families, standalone units)
    // put the standalone units (groupCode === null) at the end
    const groups = this.assignedEntitlements.filter((entitlement: Entitlement) => {
      const isGroupChainFamily: boolean = this.offsetEntityTypes.includes(
        entitlement.entityType
      );

      if (isGroupChainFamily) {
        return true;
      }
    });

    // get all entitlements that should be right aligned (units part of a group, chain, or family)
    const units = this.assignedEntitlements.filter(
      (entitlement: Entitlement) => {
        const isGroupChainFamily: boolean = this.offsetEntityTypes.includes(
          entitlement.entityType
        );

        if (!isGroupChainFamily && this.entitlementMatchesFilter(entitlement)) {
          return true;
        }
      }
    );

    const reorderedEntitlements = this.reorderEntitlements(groups, units);
    this.otherEntitlementsSubject.next(reorderedEntitlements);
  }

  reorderEntitlements(
    groups: Entitlement[],
    units: Entitlement[]
  ): ParentLocation[] {
    const reorderedEntitlements: ParentLocation[] = [];

    // for each left aligned entitlement, grab all of it's sub-units and add them to the
    // reorderedEntitlements array underneath their parent entitlement
    groups.forEach((entitlement: Entitlement) => {
      let subLocations = [];
      if (entityTypes.CaGroup === entitlement.entityType || entityTypes.SapGroup === entitlement.entityType) {
        subLocations = units.filter(
          (subEntitlement) => {
            return subEntitlement.groupCode === entitlement?.groupCode;
          });
        units = units.filter(item => subLocations.indexOf(item) < 0);
      } else if ([entityTypes.CaChain, entityTypes.UsCustomerFamily, entityTypes.SapCommon].indexOf(entitlement.entityType as entityTypes) > -1) {
        subLocations = units.filter(
          (subEntitlement) => {
            return subEntitlement.chainCode === entitlement?.chainCode;
          });
        units = units.filter(item => subLocations.indexOf(item) < 0);
      }
      if (this.entitlementMatchesFilter(entitlement) || subLocations.length > 0) {
        reorderedEntitlements.push({ entitlement, subLocations });
      }
    });

    // Add units with no matching chain or group
    units.forEach((entitlement: Entitlement) => {
      reorderedEntitlements.push({ entitlement, subLocations: [] });
    });

    return reorderedEntitlements;
  }

  isChain(entitlement: Entitlement): boolean {
    return [
      entityTypes.CaChain,
      entityTypes.UsCustomerFamily,
      entityTypes.SapCommon
    ].indexOf(entitlement.entityType as entityTypes) > -1;
  }

  /**
   * update this.customerFilter as user types
   *
   * @param searchText search input value
   */
  updateFilter(searchText: string): void {
    this.searchText = searchText;
    this.buildDisplayUnits();
  }

  /**
   * show a unit (location) card if it is selected or if this.searchText
   * matches the unit's customerId, customerName, or customerCountry
   *
   * @returns boolean
   */
  showSelectedUnit(): boolean {
    if (this.selectedUnit && this.searchText) {
      const searchValues =
        this.selectedUnit.customerPK.customerId +
        ' ' +
        this.selectedUnit.customerName +
        ' ' +
        this.selectedUnit.customerCountry;
      return searchValues
        .toLocaleLowerCase()
        .includes(this.searchText.toLocaleLowerCase());
    } else if (this.selectedUnit && !this.searchText) {
      return true;
    }

    return false;
  }

  private entitlementMatchesFilter(entitlement: Entitlement) {
    if (this?.searchText) {
      const sText = this.searchText.toLocaleLowerCase();
      if (entitlement.customerName.toLocaleLowerCase().includes(sText) ||
        entitlement.customerCountry.toLocaleLowerCase().includes(sText) ||
        entitlement.customerPK.customerId.toLocaleLowerCase().includes(sText)) {
        return true;
      }
      return false;
    }
    return true;
  }
}

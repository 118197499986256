import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';
import { AuthenticationService } from '@gfs/shared-services/auth/authentication-state.service';
import { AuthenticationState } from '../../models/authentication-state.model';

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
    message: Observable<string>;
    isError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        public authService: AuthenticationService,
        private router: Router,
        @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    ) { }

    ngOnInit(): void {
        this.message = this.authService.handleRedirect$()
            .pipe(
                map((authState: AuthenticationState) => {
                    if (authState.error) {
                        throw new Error(authState.error.message);
                    }
                    this.navigateToCustomerUnitSelection();
                    return "Success!";
                }),
                catchError(error => {
                    this.isError.next(true)
                    return of((error as Error).message);
                })
            );
    }

    navigateToCustomerUnitSelection(): void {
        const path = this.recipeProfitCalculatorConfig.RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATHNAME;
        this.router.navigateByUrl(path);
    }
}

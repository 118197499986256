import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { CountableItem, IDictionary } from '@gfs/shared-models';
import { InjectionTokens, LocalizedValueUtilsService, MeasurementUnitService, StorageAreaUtilsService, arrayToEntityDictionary } from '@gfs/shared-services';
import { isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { IAddItemsFeatureBridge } from '@gfs/store/feature/add-items';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { UnitsHttpService } from 'libs/shared-services/src/v2/services/unit/unit-http-service.service';
import { get } from 'lodash-es';
import { Observable, firstValueFrom } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';
import { capitalizeFirstChar } from '../counting-unit-form/counting-unit-form.component';

@Component({
  selector: 'gfs-add-item-card',
  templateUrl: './add-item-card.component.html',
  styleUrls: ['./add-item-card.component.scss'],
})
export class AddItemCardComponent implements OnInit {

  @Input() item: CountableItem;
  @Input() addItemIndex: number;
  @Input() isEditItem: boolean;
  @Output() itemSelected = new EventEmitter<CountableItem>();

  currentLang$ = this.addItemsCtx.currentLang$;
  isMobile$ = this.addItemsCtx.isMobile$;
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;
  suppliers$ = this.store.select(state => state.addItemsFeature.suppliers);
  items$: Observable<IDictionary<CountableItem>> = this.store.select(
    (state) => state.addItemsFeature.countableItems
  ).pipe(map(src => arrayToEntityDictionary(src, (e) => this.storageAreaUtils.getItemIdCore(e))));
  unitTranslations: IDictionary<IDictionary<string>>;
  constructor(
    private store: Store<AppState>,
    private localizedValueUtils: LocalizedValueUtilsService,
    private storageAreaUtils: StorageAreaUtilsService,
    private unitsHttpService: UnitsHttpService,
    public worksheetItemService2: MeasurementUnitService,
    @Inject(InjectionTokens.IAddItemsFeatureBridge) private addItemsCtx: IAddItemsFeatureBridge
  ) { }

  async ngOnInit(): Promise<void> {
    this.unitTranslations = await this.getUnits();
  }

  private async getUnits(): Promise<IDictionary<IDictionary<string>>> {
    const sapUnits = (await firstValueFrom(this.unitsHttpService.getCurrentEntitlementUOMs$()))
      .single.reduce((p, c) => ({
        ...p,
        [c.type]: c.name.reduce((p1, c1) => ({
          ...p1,
          [c1.languageCode]: c1.value
        }), {})
      }), {});
    const literalCountingUnits = (await firstValueFrom(this.store.select(v => v.worksheets.countingUnits)))
      .filter(v => !!v.literalUnit)
      .reduce((prev, j) => ({
        ...prev,
        [j.unit.unitId]: j.literalUnit.name.reduce((p, c) => ({ ...p, [c.languageCode]: c.value }), {})
      }), {});
    return firstValueFrom(
      this.addItemsCtx.pk$
        .pipe(
          concatMap(customerPk => this.worksheetItemService2.getMeasurementUnits(customerPk)
          ),
          map(v => v.reduce((prev, curr) => ({
            ...prev,
            [curr.id]: curr.name.reduce((p, c) => ({ ...p, [c.languageCode]: c.value }), {})
          }), {})),
          map(standardUnits => ({
            ...literalCountingUnits,
            ...standardUnits,
            ...sapUnits,
          }))
        )
    );
  }

  // TODO: all functions will need to be updated to handle order guide item, recipe item, or non-GFS item
  getLocalizedImageUrl(width: number, height: number): Observable<string> {
    return this.localizedValueUtils.getLocalizedImageUrl(this.item, this.currentLang$, width, height);
  }

  getLocalizedProductDescription(): Observable<string> {
    return this.localizedValueUtils.getLocalizedProductDescription(this.item, this.currentLang$);
  }

  getLocalizedBrand(): Observable<string> {
    return this.suppliers$
      .pipe(
        first(),
        concatMap(
          suppliers =>
            this.localizedValueUtils.getLocalizedBrand(this.item, suppliers, this.currentLang$)
        )
      );
  }

  getUnitDetails(): Observable<string> {
    return this.items$
      .pipe(isTruthy())
      .pipe(
        first(),
        map(items => Object.keys(items).length > 0 ? items : null),
        concatMap(items => this.currentLang$
          .pipe(
            concatMap(lang => this.storageAreaUtils.getGFSDefaultUnitDetails(items, this.item, lang))
          )
        )
      );
  }

  getItemId(): string {
    return this.storageAreaUtils.getItemIdCore(this.item);
  }


  getItemInnerPackSize() {
    return get(this.item, 'gfsItem.innerPackSize') ? this.item.gfsItem.innerPackSize : '';
  }

  getItemQtyPerUnit() {
    return get(this.item, 'gfsItem.qtyPerMasterSellUnit') ? this.item.gfsItem.qtyPerMasterSellUnit : '';
  }

  getItemCasePrice(): number {
    if (this.item.gfsItem) {
      return this.item.gfsItem?.price?.casePrice ?? 0;
    } else if (this.item.customItem) {
      if (this.item.customItem?.purchaseUnit?.custom?.catchWeight) {
        return Number.parseFloat(this.item.customItem?.purchaseUnit?.custom?.price)
          * Number.parseFloat(this.item.customItem?.purchaseUnit?.custom?.netWeight) ?? 0;
      } else {
        return Number.parseFloat(this.item.customItem?.purchaseUnit?.custom?.price) ?? 0;
      }
    }
  }

  getItemUnitPrice(): number {
    if (this.item.gfsItem) {
      return this.item.gfsItem?.price?.unitPrice ?? 0;
    } else if (this.item.customItem) {
      return 0;
    }
  }

  getItemPurchaseUnitDisplayCode(lang: string): string {
    let r = 'ADD_ITEMS.CASE';
    if (this.item.gfsItem && this.unitTranslations) {
      r = this.unitTranslations[this.item.gfsItem.units?.find(v => v.parentUom?.length === 0).displayCode]?.[lang]
      ?? this.item.gfsItem.units.find(v => v.parentUom?.length === 0).displayCode;
      return capitalizeFirstChar(
        reflow[this.unitTranslations[r.toUpperCase()]?.[lang] ?? r] ?? r
      );
    } 
    
  }

  onAddItemSelect() {
    this.itemSelected.emit(this.item);
  }

  calculateRowHeight() {
    return this.isEditItem ? '136px' : '160px';
  }

  /**
   * This component only supports gfsItem's, customItem's or recipeItem's
   */
  isValidItem() {
    return (!!this.item.gfsItem || !!this.item.customItem || !!this.item.recipeItem);
  }
}

const reflow = {
  "CS": "ADD_ITEMS.CASE",
  "CASE": "ADD_ITEMS.CASE",
  "UN": "ADD_ITEMS.UNIT",
  "UNIT": "ADD_ITEMS.UNIT",
  "EA": "ADD_ITEMS.UNIT",
  "EACH": "ADD_ITEMS.UNIT",
}
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { InventoryWorksheetWizardModalService } from '@gfs/shared-components';
import { IAppContext } from '@gfs/shared-models';
import {
  AppFeatureFlags,
  InjectionTokens,
} from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { WorksheetListStore } from './list.component.store';

@Component({
  selector: 'app-worksheet-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [WorksheetListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetListComponent implements OnInit {
  displayedColumns: string[] = ['Description', 'Created', 'Modified', 'Status'];

  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryCounts;
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;

  inventoryButtonText$ = this.createLocalizedTakeInventoryButtonText();
  vm$ = this.componentStore.getVM$();
  reportsUrl = InventoryConstants.REPORTS_PATH;

  constructor(
    private translateService: TranslateService,
    public componentStore: WorksheetListStore,
    public featureFlags: AppFeatureFlags,
    private worksheetWizardModal: InventoryWorksheetWizardModalService,
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext
  ) { }

  ngOnInit(): void {
    this.componentStore.init();
  }

  /** open the worksheet wizard modal
   *
   * @param resetCurrentSheet - flag provided to indicate if the current sheet should be reset
   */
  onGetStartedClick(resetCurrentSheet: boolean = false) {
    this.worksheetWizardModal.open({ resetCurrentSheet });
  }

  /** update the value column visibility to reflect auth status
   *  @param isAuthorized - boolean
   */
  pricingAuthHandler(isAuthorized: boolean) {
    this.displayedColumns = resolveDisplayedColumns(this.displayedColumns, isAuthorized, 'Value', 1);
  }

  /** update tripleDot value column visibility to reflect auth status
   *  @param isAuthorized - boolean
   */
  tripleDotAuthHandler(isAuthorized: boolean) {
    let newDisplayedColumns =
      resolveDisplayedColumns(this.displayedColumns, isAuthorized, 'Info', 'end');
    newDisplayedColumns = resolveDisplayedColumns(this.displayedColumns, isAuthorized, 'tripleDot', 'end');

    this.displayedColumns = newDisplayedColumns;
  }

  /** translate text
   *
   * @param localizedKeyOrString - text to translate
   * @returns localized text
   */
  localizedTextResolver(localizedKeyOrString: string): string {
    return localizedTextResolver(this.translateService, localizedKeyOrString);
  }

  /**
   *
   * @returns localized text for the take inventory button
   */
  createLocalizedTakeInventoryButtonText() {
    return createLocalizedConditionalText(
      this.componentStore.getHasActiveWorksheet$(),
      'INVENTORY.CONTINUE_IN_PROGRESS',
      'INVENTORY.START_NEW'
    );
  }
}

//#region domain extensions
export const resolveCustomerUnitHashFromRoute$ = (activatedRoute: ActivatedRoute) =>
  activatedRoute.params.pipe(
    map(x => x.customerUnitHash),
    first(),
  );
//#endregion

//#region data-security
export const resolveDisplayedColumns = (
  displayedColumns: string[],
  isAuthorized: boolean,
  permission: string,
  insertIndex: number | 'end') => {
  if (isAuthorized) {
    if (!displayedColumns.includes(permission)) {
      // add the permission to the list of displayed
      if (insertIndex === 'end') { displayedColumns.push(permission); }
      if (typeof insertIndex === 'number') { displayedColumns.splice(insertIndex, 0, permission); }
    }
    return displayedColumns;
  }

  // remove the permission from the list of displayed columns
  const colIndex = displayedColumns.findIndex(col => col === permission);
  if (colIndex >= 0) {
    displayedColumns.splice(colIndex, 1);
  }

  return displayedColumns;
};
//#endregion

//#region localization helpers
export const localizedTextResolver = (translateService: TranslateService, i18nKey: string): string =>
  translateService.instant(i18nKey);
//#endregion

//#region rxjs operators/extensions
export const createLocalizedConditionalText = (booleanSrc: Observable<boolean>, trueText, falseText): Observable<string> =>
  booleanSrc.pipe(map(x => x ? trueText : falseText));
//#endregion

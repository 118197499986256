import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@gfs/shared-components';
import { CustomerPK, CustomItem, Supplier, SupplierOrder } from '@gfs/shared-models';
import { CreateSupplierAttempt, CreateSupplierOrderAttempt, DeleteSupplierAttempt, PatchSupplierAttempt } from '@gfs/store/feature/add-items';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EditSupplierModalComponent } from '../edit-supplier-modal/edit-supplier-modal.component';

export interface SupplierItemDictionary {
  [supplierId: string]: number;
}

export interface SupplierItemCount {
  supplier: Supplier;
  itemCount: number;
  ordinal: number;
}

@Component({
  selector: 'gfs-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss'],
})
export class SupplierListComponent implements OnInit, OnDestroy {

  @Input() suppliers$: Observable<Supplier[]>;
  @Input() customItems$: Observable<CustomItem[]>;

  suppliers = new Array<Supplier>();

  notifier$ = new Subject<void>();

  suppliersSubject$ = new BehaviorSubject<Supplier[]>([]);

  public editSubject = new Subject();
  editAction$ = this.editSubject.asObservable()
    .pipe(
      withLatestFrom(this.suppliersSubject$.pipe(map(suppliers => suppliers.map(supplier => supplier.name)))),
      mergeMap(([entity, forbiddenNames]: [Supplier, string[]]) => this.dialog.open(EditSupplierModalComponent, {
        data: {
          entity,
          forbiddenNames
        },
        panelClass: 'create-supplier-dialog',
      }).afterClosed()
        .pipe(
          filter((modalSupplier: Supplier) => !!modalSupplier),
          tap((supplierEdited: Supplier) => {
            const update = () => this.store.dispatch(new PatchSupplierAttempt({ supplier: supplierEdited }));
            const create = () => this.store.dispatch(new CreateSupplierAttempt({ name: supplierEdited.name }));
            (!!supplierEdited.id ? update : create)();
          }),
          filter((supplierEdited: Supplier) => !!supplierEdited.id),
          tap((supplierEdited: Supplier) => {
            const index = this.suppliers.findIndex(supplier => supplier.id === supplierEdited.id);
            this.suppliers[index] = supplierEdited;
          }))));

  public deleteSubject = new Subject();
  deleteAction$ = this.deleteSubject.asObservable()
    .pipe(
      filter((supplier: Supplier) => !!supplier),
      mergeMap((supplier: Supplier) => this.dialog.open(ConfirmationModalComponent, {
        data: {
          returnData: supplier,
          title: this.translate.instant(
            'SUPPLIER.DELETE_SUPPLIER_MODAL.DELETE_SUPPLIER_DIALOG_TITLE',
            { supplierName: supplier.name }
          ),
          cancelButtonId: 'cancel-delete-manage-vendors-button',
          submitButtonId: 'delete-manage-vendors-button',
          submitButtonAriaLabel: this.translate.instant('SUPPLIER.DELETE_SUPPLIER_BUTTON_ARIA_LABEL'),
          submitButtonLabel: this.translate.instant('SUPPLIER.DELETE_SUPPLIER_BUTTON')
        },
        disableClose: true
      }).afterClosed()
        .pipe(
          filter((supplierToDelete: Supplier) => !!supplierToDelete),
          tap((supplierToDelete: Supplier) => {
            this.store.dispatch(new DeleteSupplierAttempt({ supplier: supplierToDelete }));
          }))));

  public dropSubject = new Subject();
  dropAction$ = this.dropSubject.asObservable()
    .pipe(
      filter((drop: CdkDragDrop<SupplierListComponent>) => drop.previousIndex !== drop.currentIndex),
      map(drop => {
        moveItemInArray(this.suppliers, drop.previousIndex, drop.currentIndex);
        return this.suppliers
          .map(supplier => supplier.id);
      }),
      withLatestFrom(this.store.select(state => state.auth.pk)),
      map(([orderedIds, pk]: [string[], CustomerPK]) => ({
        customerPK: pk,
        order: orderedIds
      })),
      tap((supplierOrder: SupplierOrder) => this.store.dispatch(new CreateSupplierOrderAttempt({ supplierOrder }))),
    );

  constructor(
    @Inject(DOCUMENT) private documentService: Document,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.customItems$
      .pipe(
        takeUntil(this.notifier$),
        filter((items: CustomItem[]) => !!items),
        map((items: CustomItem[]) => items.filter(item => !item.deleted && !!item.supplierId)),
        map((items: CustomItem[]) => _(items)
          .groupBy((item: CustomItem) => item.supplierId)
          .map(({ length: itemCount }, supplierId) => ({ supplierId, itemCount }))
          .reduce((acc: SupplierItemDictionary, curr) => ({ ...acc, [curr.supplierId]: curr.itemCount }), {})
        ),
        mergeMap((dict: SupplierItemDictionary) => this.suppliers$.pipe(
          filter((suppliers: Supplier[]) => !!suppliers),
          map((suppliers: Supplier[]) => suppliers.map(supplier => ({ ...supplier, itemCount: dict?.[supplier.id] ?? 0 }))),
          tap(suppliers => this.suppliers = suppliers),
          tap(suppliers => this.suppliersSubject$.next(suppliers))
        ))).subscribe();
  }

  ngOnDestroy() {
    this.notifier$.next();
  }

  onFocus(supplierId: string, supplierName: string) {
    this.clearFocus();
    this.setButtonVisibility(supplierId, supplierName, 'delete', 'visible');
    this.setButtonVisibility(supplierId, supplierName, 'edit', 'visible');
  }

  onBlur(supplierId: string, supplierName: string) {
    this.setButtonVisibility(supplierId, supplierName, 'delete', 'hidden');
    this.setButtonVisibility(supplierId, supplierName, 'edit', 'hidden');
  }

  clearFocus() {
    const elements = this.documentService.getElementsByClassName('icon-hover-bkg');
    Array.prototype.forEach.call(elements, (el: HTMLElement) => {
      el.style.visibility = 'hidden';
    });
  }

  private setButtonVisibility(supplierId: string, supplierName: string, type: string, visibility: string) {
    const identifier = supplierName.replace(' ', '-') + '-' + supplierId;
    const button = this.documentService.getElementById(`manage-vendors-list-${type}-button-${identifier}`);

    if (button) {
      button.style.visibility = visibility;
    }
  }
}

import { CustomerPK } from '.';

export interface Supplier {
  id: string;
  customerPK: CustomerPK;
  name: string;
  deleted?: boolean;
  ordinal?: number;
  itemCount?: number;
}

export interface SupplierUpdate {
  name: string;
}

export interface SupplierOrder {
  id?: string;
  customerPK: CustomerPK;
  order: string[];
}

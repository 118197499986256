import { Inject, Injectable } from '@angular/core';
import { IAppContext, Worksheet } from '@gfs/shared-models';
import { BaseComponentStore, CallState, getError, InjectionTokens, InventoryWorksheetStatusEnum, LoadingState, sortByDate } from '@gfs/shared-services';
import { AppState } from '@gfs/store/inventory/reducers';
import { WorksheetDataService } from '@inventory-ui/v2/common/services/worksheet-data.service';
import { tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, OperatorFunction } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';

export interface LocalizedWorksheet {
    id: string;
    name: string;
    status: string;
    totalValue: number;
    created: string;
    lastModified: string;
    inventoryDate: string;
    isActiveInventory: boolean;
    isDeletable: boolean;
}

export interface WorksheetSummaryState {
    callState: CallState;
    worksheets: LocalizedWorksheet[];
}

@Injectable()
export class WorksheetListStore extends BaseComponentStore<WorksheetSummaryState> {

    constructor(
        store: Store<AppState>,
        private worksheetDataSvc: WorksheetDataService,
        @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext
    ) {
        super(store, {
            callState: LoadingState.INIT,
            worksheets: [],
        });
    }
    //#region internal selectors
    private readonly error$: Observable<string> = this.select(state => getError(state.callState));

    private readonly worksheets$ = this.select(({ worksheets }) => worksheets);
    private readonly averageInventoryValue$ = this.select(({ worksheets }) =>
        calculateAverageWorksheetValue(worksheets)
    );
    private hasWorksheets$ = this.select(({ worksheets }) => worksheets?.length > 0);
    private isLoading$ = this.select(({ callState }) => callState === LoadingState.LOADING);
    private isReady$ = this.select(({ callState }) => callState === LoadingState.LOADED);

    //#region UPDATERS
    updateError = this.updater((state: WorksheetSummaryState, error: string) => {
        return ({
            ...state,
            callState: {
                errorMsg: error
            }
        });
    });

    setLoading = this.updater((state: WorksheetSummaryState, str) => ({
        ...state,
        callState: LoadingState.LOADING
    }));

    setLoaded = this.updater((state: WorksheetSummaryState) => ({
        ...state,
        callState: LoadingState.LOADED
    }));
    //#endregion
    //#region EFFECTS
    getAllWorksheets = this.effect(
        (appCtx: Observable<IAppContext>) => appCtx.pipe(
            concatMap(({ customerPK, language }) => forkJoin([
                customerPK.pipe(first()),
                language.pipe(first())
            ])),
            concatMap(([pk, lang]) => {
                this.setLoading();
                return this.worksheetDataSvc.getAllWorksheets(pk).pipe(
                    onlyActive(x => x.deleted),
                    map(worksheets => sortByDate(worksheets, y => y.created)),
                    tapResponse(sortedWorksheets => {
                        this.setState(state => ({
                            ...state,
                            worksheets: sortedWorksheets.map(x => this.mapToLocalizedWorksheet(x, sortedWorksheets))
                        }));
                        this.setLoaded();
                    },
                        (e: string) => this.updateError(e)
                    ),
                );
            })
        ));

    init() {
        this.getAllWorksheets(this.appContext);
    }

    getHasActiveWorksheet$() {
        return this.select(({ worksheets }) => worksheets.some(y => {
            return y.isActiveInventory;
        }));
    }

    //#endregion
    getVM$() {
        return this.select(
            this.isLoading$,
            this.isReady$,
            this.worksheets$,
            this.hasWorksheets$,
            this.averageInventoryValue$,
            this.error$,
            (isLoading, isReady, worksheets, hasWorksheets, averageInventoryValue, error) => ({
                isLoading,
                isReady,
                worksheets,
                hasWorksheets,
                averageInventoryValue,
                error,
            })
        );
    }

    //#endregion


    //#region MAPPERS/RESOLVERS
    /**
     *
     * @param worksheet the worksheet to map
     * @param language the language to use
     * @returns a localized worksheet
     */
    mapToLocalizedWorksheet(worksheet: Worksheet, sortedWorksheets: Worksheet[]): LocalizedWorksheet {

        const isMostRecent = sortedWorksheets.indexOf(worksheet) === 0;
        return ({
            id: worksheet.id,
            name: this.tryConvertWorksheetNameToi18n(worksheet.name),
            status: this.convertWorksheetStatusToi18n(worksheet.status),
            totalValue: worksheet.totalValue,
            created: worksheet.created,
            lastModified: worksheet.lastModified,
            inventoryDate: worksheet.inventoryDate,
            isActiveInventory: worksheet.isActiveInventory,
            isDeletable: !isMostRecent && !worksheet.isActiveInventory
        });
    }



    /**
     * Convert the worksheet status to a localized string
     * @param worksheetStatusCode the worksheet status code
     * @returns returns the localized string if found
     */
    convertWorksheetStatusToi18n(status) {
        if (status === InventoryWorksheetStatusEnum.Open) {
            return 'INVENTORY.IN_PROGRESS';
        }
        return 'INVENTORY.DONE';
    }

    /**
     * Try to convert the worksheet name to a localized string
     * @param worksheetName the worksheet name
     * @returns returns the localized string if found, otherwise the original name
     */
    tryConvertWorksheetNameToi18n(worksheetName: string): string {
        if (this.resolveIsNewWorksheetByName(worksheetName)) {
            return 'INVENTORY_WORKSHEET.UNTITLED_INVENTORY';
        }
        return worksheetName;
    }

    /**
     *
     * @param name the worksheet name
     * @returns boolean indicating if the worksheet has a title that looks like a new worksheet
     */
    private resolveIsNewWorksheetByName(name: string) {
        return ['inventaire sans titre', 'untitled inventory'].some(toLowerCaseMatches(name));
    }
}


//#region primitive extensions
export const toLowerCaseMatches = (name: string): (value: string, index: number, array: string[]) =>
    boolean => x => x.toLowerCase() === name.toLowerCase();

//#endregion

//#region domain extensions
export function calculateAverageWorksheetValue(worksheets: LocalizedWorksheet[]): number {
    if (worksheets.length === 0) { return 0; }
    return worksheets.reduce((acc, curr) => acc + curr.totalValue, 0) / worksheets.length;
}
//#endregion

//#region rxjs extensions
export const onlyActive = <TModel>(deletedFlagFn: (x: TModel) => boolean): OperatorFunction<TModel[], TModel[]> =>
    map<Array<TModel>, Array<TModel>>(x => x.filter(y => !deletedFlagFn(y)));
//#endregion

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { MaterialModule, PipeSharingModule } from '@gfs/shared-components';
import { InventoryIconService } from '@gfs/shared-services';
import { SharedComponentsV2Module } from '@gfs/v2/shared-components';
import { TranslateModule } from '@ngx-translate/core';
import { ReportsComponent } from './reports.component';

const { icons } = InventoryConstants;

@NgModule({
    imports: [
        CommonModule,
        MatDividerModule,
        MatButtonModule,
        MatCardModule,
        RouterModule,
        MatIconModule,
        MatTableModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatGridListModule,
        MaterialModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        TranslateModule.forChild(),
        PipeSharingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedComponentsV2Module
    ],
    declarations: [ReportsComponent],
    exports: [ReportsComponent]
})

export class ReportsModule {
  readonly storageAreaIcons = Object.keys(icons).map(icon => icons[icon]);

  constructor(public inventoryIconService: InventoryIconService) {
    inventoryIconService.registerIcons(this.storageAreaIcons);
  }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { LogOutAttempt } from '@gfs/store/common';

interface Option {
  id: string;
  display: string;
  icon: string;
  action?: any;
}

@Component({
  selector: 'gfs-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {

  isMobile$ = this.store.select(state => state.layout.isMobile);
  isOnline$ = this.store.select(state => state.network.isOnline);
  selectedOption = '';
  options: Option[] = [{
    id: 'logout-menu-button',
    icon: 'unavailable',
    display: 'HEADER.LOGOUT',
    action: () => this.logout()
  }];

  constructor(
    public translate: TranslateService,
    private store: Store<AppState>
  ) { }

  /**
   * translate an option display label
   *
   * @returns a translation
   */
  getOptionLabel() {
    return (option: Option) => this.translate.instant(option.display);
  }

  /**
   * dispatch a new LogOutAttempt action
   */
  logout(): void {
    this.store.dispatch(new LogOutAttempt());
  }
}

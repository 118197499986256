<div>
    <div class="table-label">
        {{ 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.SECTION_HEADING' | translate }}
    </div>
    <ng-container *ngIf="viewmodel">
        <mat-table mat-table [dataSource]="viewmodel.assignAccountsTableData">
            <ng-container matColumnDef="itemCategoryId">
                <mat-header-cell scope="col" *matHeaderCellDef>Category Id</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let record">{{ record.itemCategoryId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="accountId">
                <mat-header-cell scope="col" *matHeaderCellDef>Account Id</mat-header-cell>
                <mat-cell *matCellDef="let record">{{ record.accountId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="categoryType">
                <mat-header-cell scope="col" *matHeaderCellDef>{{ 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.COLUMN_HEADING_CATEGORY_TYPE' | translate }}</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let record">{{ (record.isCustomItemCategory ? 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.CATEGORY_TYPE_CUSTOM' : 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.CATEGORY_TYPE_DEFAULT') | translate}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="categoryName">
                <mat-header-cell scope="col" *matHeaderCellDef>{{ 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.COLUMN_HEADING_CATEGORY_NAME' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let record">{{ record.localizedItemCategoryName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="accountSelection">
                <mat-header-cell scope="col" *matHeaderCellDef>{{ 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.COLUMN_HEADING_GL_ACCOUNT' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let record">
                    <mat-form-field appearance="outline">
                        <mat-select *ngIf="record.fromFamily || null" [disabled]="record.fromFamily"
                            placeholder="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_CODE' | translate }}"
                            [value]="record.accountId"
                            (selectionChange)="selectionChange($event, record)">
                            <mat-option>{{ 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.UNASSIGNED' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let account of viewmodel.dropdownOptions"
                                [value]="account.id">
                                {{ account.value }}
                            </mat-option>
                        </mat-select>
                        <mat-select *ngIf="!record.fromFamily" [disabled]="record.fromFamily"
                            placeholder="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_CODE' | translate }}"
                            [value]="record.accountId"
                            (selectionChange)="selectionChange($event, record)">
                            <mat-option>{{ 'GENERAL_LEDGER.ASSIGN_ACCOUNTS.UNASSIGNED' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let account of findNonFamilyUnits(viewmodel.dropdownOptions, record.fromFamily)"
                                [value]="account.id">
                                {{ account.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </ng-container>
</div>

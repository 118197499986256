import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, filter, withLatestFrom, mergeMap, catchError, concatMap, tap } from 'rxjs/operators';
import {
  ActionUnion, ActionTypes, GetInventoryItemsSuccess,
  GetGeneralItemsSuccess, GetCustomItemsSuccess,
  GetGeneralItemsError, GetCustomItemsError, GetInventoryItemsError,
  UpdateCustomItemsSuccess, UpdateCustomItemsError, UpdateGeneralItemsSuccess,
  UpdateGeneralItemsError
} from '../actions/inventoryItems.actions';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { MessageService, ProductService, CustomItemService, GeneralItemService } from '@gfs/shared-services';
import { CountableItem, GfsItem } from '@gfs/shared-models';
import { of } from 'rxjs';
import { GetAllCustomItemDataAttempt } from '../actions/worksheets.actions';
import { GetAllCustomItemsAttempt, UpdateCustomItemPriceSuccess, UpdateLocalGeneralItem } from '../actions/customerItems.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SaveCustomItemFinish } from '@gfs/store/feature/add-items';

@Injectable()
export class InventoryItemsEffects {

  getInventoryItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetInventoryItemsAttempt),
    // only run this effect once the PK has been loaded
    withLatestFrom(this.store.pipe(
      map(state => state.auth.pk),
      filter(pk => !!pk))),
    concatMap(([action, pk]) => this.productService
      .getProductsInfo(action.payload, pk)
      .pipe(
        map((infos: GfsItem[]) => infos.map((item: GfsItem) => {
          const countableItem: CountableItem = {
            gfsItem: item,
            recipeItem: null,
            generalItem: null,
            customItem: null,
            customItemData: null
          };
          return countableItem;
        })),
        map((response: CountableItem[]) => [].concat(...response)),
        // filter(infos => infos.length > 0),
        map(infos => new GetInventoryItemsSuccess({ items: infos })),
        catchError(err => of(new GetInventoryItemsError(err)))
      ))));


  getGeneralItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetGeneralItemsAttempt),
    // only run this effect once the PK has been loaded
    withLatestFrom(this.store.pipe(filter(state => !!state.auth.pk))),
    mergeMap(([action, state]) =>

      // this.customItemService.getCustomItems()
      // TODO: This needs to be changed to get CustomItems based on the general items
      this.generalItemService.getGeneralItems(action.payload.ids, action.payload.includeDeleted, state.auth.pk).pipe(
        map((infos): CountableItem[] => infos.map((item): CountableItem => ({
          gfsItem: null,
          recipeItem: null,
          generalItem: item,
          customItem: null,
          customItemData: null
        })))
      ).pipe(map(
        response => [].concat(...response)),
        // filter(infos => infos.length > 0),
        map(infos => new GetGeneralItemsSuccess({ items: infos, includeDeleted: action.payload.includeDeleted })
        ),
        catchError(err => of(new GetGeneralItemsError(err)))
      )
    )
  ));


  getCustomItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetGeneralItemsSuccess),
    // only run this effect once the PK has been loaded
    withLatestFrom(this.store.pipe(filter(state => !!state.auth.pk))),
    mergeMap(([action, state]) => {
      const products = action.payload.items.map(items => items.generalItem.productList
        .filter(primary => primary.primaryProduct === true)
        .map(product => product.id));
      const productIds = [].concat(...products);
      // this.customItemService.getCustomItems()
      // TODO: This needs to be changed to get CustomItems based on the general items
      return this.customItemService.getCustomItems(productIds, action.payload.includeDeleted, state.auth.pk).pipe(
        map((infos): CountableItem[] => infos.map((item): CountableItem => ({
          gfsItem: null,
          recipeItem: null,
          generalItem: null,
          customItem: item,
          customItemData: null
        })))
      ).pipe(map(
        response => [].concat(...response)),
        filter(infos => infos.length > 0),
        map(infos => new GetCustomItemsSuccess(infos.concat(...action.payload.items))
        ),
        catchError(err => of(new GetCustomItemsError(err)))
      );

    })));


  updateCustomItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateCustomItemsAttempt),
    mergeMap((action) => {
      const generalItemId = action.payload.generalItemId;
      return this.customItemService.updateCustomItem(action.payload.customItemId, action.payload.customItemPatch)
        .pipe(
          concatMap(customItem => [
            new UpdateCustomItemsSuccess({ customItem, generalItemId }),
            new GetAllCustomItemsAttempt(),
            new GetAllCustomItemDataAttempt(),
            new UpdateCustomItemPriceSuccess(customItem),
            new SaveCustomItemFinish(customItem)
          ]),
          catchError((err) => of(new UpdateCustomItemsError(err)))
        );
    })
  ));


  updateCustomItemSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateCustomItemsSuccess),
    concatMap((action) => [
      new SaveCustomItemFinish(action.payload.customItem),
      new GetAllCustomItemsAttempt(),
    ])
  ));


  updateGeneralItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateGeneralItemsAttempt),
    mergeMap((action) => this.generalItemService.updateGeneralItem(action.payload.generalItemId, action.payload.generalItemPatch)
      .pipe(
        concatMap(generalItem => [
          new UpdateGeneralItemsSuccess({ generalItem }),
          new UpdateLocalGeneralItem(generalItem),
          new GetAllCustomItemsAttempt()
        ]),
        catchError((err) => of(new UpdateGeneralItemsError(err)))
      )
    )
  ));


  duplicateCustomItemError$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateCustomItemsError),
    filter((action: any) => {
      const httpError = action.error as HttpErrorResponse;
      return (
        httpError.status === 400 &&
        httpError.error !== null &&
        httpError.error.code === '103'
      );
    }),
    tap((_) => {
      this.messageService.queue(
        this.translate.instant('MESSAGES.ITEM_ALREADY_EXISTS')
      );
    }),
    map((action) => new SaveCustomItemFinish(null))
  ));

  constructor(
    private actions$: Actions<ActionUnion>,
    private store: Store<AppState>,
    private productService: ProductService,
    private generalItemService: GeneralItemService,
    private customItemService: CustomItemService,
    private messageService: MessageService,
    private translate: TranslateService,
  ) { }
}

<div try-reporting-container>
  <div try-reporting-container-left>
    <h1 try-reporting-heading>
      {{'CTA.TRY_NEW_REPORT_PLATFORM.HEADING' | translate }}
    </h1>
    <h2 try-reporting-sub-heading>
      {{'CTA.TRY_NEW_REPORT_PLATFORM.SUB_HEADING' | translate }}
    </h2>
    <p>
      {{'CTA.TRY_NEW_REPORT_PLATFORM.CONTENT' | translate }}
    </p>

    <ng-container *ngIf="configService.getSettings() as config">
      <ng-container *ngIf="translate.currentLang.startsWith('en')">
        <a
          mat-button
          class="mat-button mat-icon-button"
          try-reporting-button
          [href]="config.INVENTORY_REPORT_PATH_EN_CA | safe"
          target="_blank"
          rel="noopener noreferrer"
        >
          <mat-icon
            svgIcon="open_in_new"
            open_in_new
            style="margin-left: 5px;"
          ></mat-icon>
          <span>
            {{ 'CTA.TRY_NEW_REPORT_PLATFORM.BUTTON_TEXT' | translate }}
          </span>

        </a>
      </ng-container>
      <ng-container *ngIf="translate.currentLang.startsWith('fr')">
        <a
          mat-button
          try-reporting-button
          [href]="config.INVENTORY_REPORT_PATH_FR_CA | safe"
          target="_blank"
          rel="noopener noreferrer"
        >
          <mat-icon
            svgIcon="open_in_new"
            open_in_new
            style="margin-left: 5px;"
          ></mat-icon>
          <span>
            {{ 'CTA.TRY_NEW_REPORT_PLATFORM.BUTTON_TEXT' | translate }}
          </span>

        </a>
      </ng-container>
    </ng-container>


  </div>

  <div
    try-reporting-graphic
    try-reporting-container-left
    class="image-container"
  >
    <img
      src="/assets/images/try-new-reporting/MicroStrategyReportPreview.png"
      alt="Image"
    >
  </div>


</div>

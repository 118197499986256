import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MaterialModule } from '../material.module';
import { AssignAccountsComponent } from './assign-accounts/assign-accounts.component';
import { GeneralLedgerComponent } from './general-ledger/general-ledger.component';
import { CommonModule } from '@angular/common';
import { MaintainAccountsInputComponent } from './maintain-accounts/manage-accounts-input/maintain-accounts-input.component';
import { MaintainAccountsTableComponent } from './maintain-accounts/maintain-accounts-table/maintain-accounts-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { EditGeneralLedgerPopupComponent } from './edit-general-ledger-popup/edit-general-ledger-popup.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FormsModule,
  ],
    declarations: [
        AssignAccountsComponent,
        MaintainAccountsInputComponent,
        MaintainAccountsTableComponent,
        GeneralLedgerComponent,
        EditGeneralLedgerPopupComponent,
    ],
    exports: [
        GeneralLedgerComponent,
    ]
})
export class GeneralLedgerModule { }

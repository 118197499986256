<main>
  <div class="page-header-container">
    <div class="header">
      <h1>
        {{ 'MANAGE_NONGFS_ITEMS.MANAGE_NONGFS_ITEMS' | translate }}
      </h1>
      <br>
      </div>
  <div class="bulk-edit-container">
    <div class="search-container">
      <gfs-item-search-bar [searchText]="'MANAGE_NONGFS_ITEMS.FILTER_PRODUCTS'"></gfs-item-search-bar>
    </div>
    <ng-container>
      <div class="button-container">

    <button 
    class="enable-bulk-mode secondary-button"
    [ngClass]="{'primary-button': toggleButtonState , 'secondary-button' : !toggleButtonState}"
    (click)="toggleEditMode($event)">
    {{ (toggleButtonState ? 'INVENTORY_WORKSHEET.EXIT_EDIT_MODE' : 'INVENTORY_WORKSHEET.ENABLE_EDIT_MODE') | translate }}
    </button>        
    <div class="delete-btn-container">
    <button 
      class="group-delete secondary-button"
      *ngIf="(useManageNonGfsGroupOperations$ | async)"
      (click)="SupplierItemPanelComponent.onGroupDeleteClicked()"
      [disabled]="SupplierItemPanelComponent?.checkboxSelectedItems?.length < 1"
      >
      {{ 'INVENTORY_WORKSHEET.REMOVE_SELECTED' | translate }}
    </button>
    <div 
        class="selected-item-count-container"
        *ngIf="(useManageNonGfsGroupOperations$ | async)"
        >
          {{ SupplierItemPanelComponent?.checkboxSelectedItems?.length }} {{'INVENTORY_WORKSHEET.ITEMS_SELECTED' | translate }}
        </div>
    </div>
      </div>
</ng-container>

    <div class="sub-header-container-mobile" *ngIf="(isMobile$ | async)">
      <div class="sub-header-mobile">
        <p>{{ 'MANAGE_NONGFS_ITEMS.SUB_HEADER_MOBILE' | translate}} <a (click)="showNonGFSDialog()">{{ 'MANAGE_NONGFS_ITEMS.SUB_HEADER_LINK_MOBILE' | translate}}</a></p>
      </div>
    </div>
    <ng-container *ngIf="!(useManageNonGfsGroupOperations$ | async)">
    <div class="title-button-bar">
      <button *ngxPermissionsOnly="permissionRoles.addItems" class="primary-button" [disabled]="!(isOnline$ | async)"
        (click)="onAddItemClick()">
        {{ 'ADD_ITEMS.ADD_NON_GFS_ITEM' | translate }}
      </button>
    </div>
  </ng-container>
  </div>
  </div>
  <div class="sub-header-container" *ngIf="!(isMobile$ | async)">
    <div class="sub-header">
      <p>{{ 'MANAGE_NONGFS_ITEMS.SUB_HEADER' | translate}} <a (click)="showNonGFSDialog()">{{ 'MANAGE_NONGFS_ITEMS.SUB_HEADER_LINK' | translate}}</a></p>
    </div>
  </div>
  <div
    class="supplier-main-container"
    [ngSwitch]="(isMobile$ | async)">

    <div *ngSwitchCase="true">
      <gfs-supplier-item-panel
        [suppliers$]="this.suppliers$"
        [customItems$]="this.customItems$">
      </gfs-supplier-item-panel>
    </div>

    <mat-drawer-container *ngSwitchDefault>
      <mat-drawer mode="side" opened [disableClose]="true">
        <gfs-supplier-list
          [suppliers$]="this.suppliers$"
          [customItems$]="this.customItems$">
        </gfs-supplier-list>
      </mat-drawer>
      <mat-drawer-content>
        <div class="supplier-item-panel-wrapper">
          <gfs-supplier-item-panel
            [suppliers$]="this.suppliers$"
            [customItems$]="this.customItems$">
          </gfs-supplier-item-panel>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</main>
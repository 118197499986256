import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/inventory/reducers';
import { concatMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { GfsApplicationsService } from '@gfs/shared-services';
import { Authorization, Authorizations } from '@gfs/store/common';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

const logoLinkDestinationConfiguration = {
  [Authorization.RecipeProfitCalculator]: false
};

@Component({
  selector: 'gfs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

  static HOME_PAGE_PATH = '/';

  @Input() customerBrand: string;
  @Input() showElements: boolean;
  @Input() showSubHeader: boolean;

  CUSTOMER_UNIT_PATH = InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH;
  selectedCustomerPk$ = this.store.select(state => state.auth.pk);
  entitlements$ = this.store.select(state => state.auth.user ? state.auth.user.entitlements : null);
  allEntitlements$ = this.store.select(state => state.auth.entitlements);
  isMobile$ = this.store.select(state => state.layout.isMobile);
  showBanner = false;
  isOnline$ = this.store.select(state => state.network.isOnline);
  retryingConnection$ = this.store.select(state => state.network.retryingConnection);
  gfsApplications$: Observable<any[]> = this.allEntitlements$
    .pipe(
      filter(entitlements => !!entitlements.length),
      withLatestFrom(this.selectedCustomerPk$),
      concatMap(([entitlements, customerPK]) => {
        return this.gfsApplicationsService.getApplications$(entitlements, customerPK)
      })
    )

  LOGO_ROUTER_LINK$: Observable<string> = this.getLogoRouterLinks$(this.store, this.router);

  constructor(
    private store: Store<AppState>,
    public gfsApplicationsService: GfsApplicationsService,
    public router: Router
  ) { }

  ngOnInit() {
    this.isOnline$.subscribe(isOnline => {
      if (!isOnline) {
        this.showBanner = true;
      } else {
        setTimeout(() => {
          this.showBanner = false;
        }, 3000);
      }
    });
  }

  getLogoRouterLinks$(
    store: Store<AppState>,
    router: Router
  ): Observable<string> {
    return store
      .select(state => state.auth.authorizations)
      .pipe(
        withLatestFrom(of(logoLinkDestinationConfiguration), of(router.url)),
        map(([authorizations, activationConfig, currentUrl]: [Authorizations, object, string]) => {
          const canNavigateHome = Object.entries(activationConfig)
            .map(([authorization, value]: [Authorization, boolean]) =>
              ({ authorization: authorization, value }))
            .map(({ authorization, value }) => authorizations[authorization] === value)
            .every((isTrue: boolean) => isTrue);
          return canNavigateHome ? HeaderComponent.HOME_PAGE_PATH : currentUrl;
        }));
  }
}

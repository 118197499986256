import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LayoutActionTypes as ActionTypes, LayoutActionUnion as ActionUnion } from '../actions/layout.actions';

@Injectable()
export class LayoutEffects {

  constructor(
    private actions$: Actions<ActionUnion>,
    private translateService: TranslateService,
    private titleService: Title
  ) { }


  setTitle$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetTitle),
    filter(x => x.payload !== 'TITLE'),
    tap(action => {
      this.titleService.setTitle(action.payload);
    })
  ), { dispatch: false });


  setLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetLanguage),
    tap(action => {
      this.translateService.use(action.payload);
    })
  ), { dispatch: false });
}

import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy, Component,
  ElementRef,
  EventEmitter, Input, OnInit,
  Output, QueryList, ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InventoryConstants } from '@gfs/constants';
import { ConfirmationModalComponent, EditItemDialogComponent } from '@gfs/shared-components';
import {
  CountableItem,
  CountingUnit,
  CustomItemData,
  MeasurementUnit,
  StorageArea,
  Supplier,
  WorksheetItem,
  WorksheetItemChange,
  WorksheetItemPost
} from '@gfs/shared-models';
import {
  InventoryValueService, LocalizedValueUtilsService,
  StorageAreaUtilsService
} from '@gfs/shared-services';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { firstValueFrom, isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { UpdateCustomItemsAttempt } from '@gfs/store/inventory/actions/inventoryItems.actions';
import {
  CreateWorksheetItemAttempt, DeleteInventoryItem,
  DeleteInventoryItemAttempt,
  GetWorksheetAttempt,
  MoveWorksheetItemToStorageArea,
  UpdateWorksheetItem
} from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UnitsHttpService } from 'libs/shared-services/src/v2/services/unit/unit-http-service.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Observable, Subject, forkJoin, iif, of } from 'rxjs';
import { concatMap, filter, first, map, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-storage-area-card',
  templateUrl: './storage-area-card.component.html',
  styleUrls: ['./storage-area-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageAreaCardComponent implements OnInit {

  static nonGfsItemTypes = [
    InventoryConstants.WORKSHEET_ITEM_TYPES.CUSTOM,
    InventoryConstants.WORKSHEET_ITEM_TYPES.GENERAL
  ];

  constructor(
    public store: Store<AppState>,
    private storageAreaUtils: StorageAreaUtilsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private localizedValueUtils: LocalizedValueUtilsService,
    private permissionsService: NgxPermissionsService,
    private inventoryValueService: InventoryValueService,
    private d: UnitsHttpService
  ) {
  }

  @Input() worksheetId: string;
  @Input()
  public get storageArea(): StorageArea {
    return this._storageArea;
  }
  public set storageArea(value: StorageArea) {
    this.storageAreaId.next(value.id);
    this._storageArea = value;
  }
  private _storageArea: StorageArea;

  @Input() inventoryItems: { [s: string]: CountableItem; };
  @Input() activeItems: WorksheetItem[];
  @Input() pageOffset: number;
  @Input() standardUnits: MeasurementUnit[];
  @Input() countingUnits: CountingUnit[];
  @Input() customItemData: CustomItemData[];

  @Output() worksheetItemDrop = new EventEmitter<any>();
  useInfiniteScroll = true;
  isOnline$: Observable<boolean> = this.store.select(state => state.network.isOnline);
  noImage: HTMLImageElement = new Image();
  qtyRegex = InventoryConstants.ITEM_QTY_REGEX;
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;
  hasItemTripleDot = false;

  customerPK$ = this.store.select(state => state.auth.pk);
  currentLang$ = this.store.select(state => state.layout.language);
  isMobile$ = this.store.select(state => state.layout.isMobile);
  worksheet$ = this.store.select(state => state.worksheets.worksheets[state.worksheets.selected]);
  hasFilterText$ = this.store.select(state => !!state.worksheets.itemFilterText);
  suppliers$ = this.store.select(state => state.addItemsFeature.suppliers) as Observable<Supplier[]>;
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;
  updateWorksheetItemUnitPermission = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet.updateWorksheetItemUnit;
  storageAreaId = new BehaviorSubject<string>(null);
  storageAreaId$ = this.storageAreaId.pipe(isTruthy());
  @ViewChildren('itemCountInput')
  itemCountInputs: QueryList<ElementRef>;
  titleCase = new TitleCasePipe();

  async ngOnInit() {
    this.noImage.src = 'assets/images/no-image-icon.svg';
    this.hasItemTripleDot = await this.permissionsService.hasPermission(this.permissionRoles.itemTripleDot);
  }

  getCurrentItems(): WorksheetItem[] {
    if (this.activeItems) {
      return this.activeItems;
    } else {
      return [];
    }
  }

  showGFSUnitDetails(item: WorksheetItem) {
    return item.itemType === 'GFS';
  }

  processAction(undo: boolean, worksheetId: string, storageAreaId: string, inventoryItem: WorksheetItem) {
    if (undo) {
      this.store.dispatch(new GetWorksheetAttempt(worksheetId));
    } else {
      this.store.dispatch(new DeleteInventoryItem({
        worksheetId,
        storageAreaId,
        inventoryItem
      }));
    }
  }

  getQuantityString(quantity: number) {
    let quantityString = '';
    if (typeof quantity !== 'undefined' && quantity) {
      quantityString = quantity.toString();
    }
    return quantityString;
  }
  afterWorksheetItemMoved(): void { }
  afterWorksheetItemDeleted(): void { }

  async moveToClicked(
    item: WorksheetItem,
    originalStorageAreaId: string,
    destinationStorageAreaId: string
  ): Promise<void> {
    this.moveToClickedAction(
      item,
      originalStorageAreaId,
      destinationStorageAreaId
    ).toPromise();
  }

  moveToClickedAction(
    item: WorksheetItem,
    originalStorageAreaId: string,
    destinationStorageAreaId: string
  ): Observable<any> {
    const countableItem = this.inventoryItems[item.itemId];
    const model = {
      worksheetItem: item,
      storageAreaSource: originalStorageAreaId,
      destinationStorageAreaId: destinationStorageAreaId,
      initialStorageAreaId: originalStorageAreaId,
      customItemIdx: this.storageAreaUtils.getItemIdCore(countableItem),
      worksheetItemPost: {
        itemId: item.itemId,
        itemType: item.itemType,
        primaryUnit: item.primaryUnit,
        primaryUnitQty: this.getQuantityString(item.primaryUnitQty),
        primaryUnitType: item.primaryUnitType,
        secondaryUnit: item.secondaryUnit,
        secondaryUnitQty: this.getQuantityString(item.secondaryUnitQty),
        secondaryUnitType: item.secondaryUnitType
      },
      customItemUpdate: {
        storageAreaId: destinationStorageAreaId
      }
    };

    return forkJoin([
      this.worksheet$.pipe(first()),
      of(model),
      this.getLocalizedProductDescription(countableItem).pipe(first()),
    ])
      .pipe(
        map(([ws, modelData, worksheetItemDescription]) => {
          return {
            worksheet: ws,
            modelData: {
              ...modelData,
              worksheetItemDescription
            },
          };
        }),
        tap(({ modelData }) => {
          this.store.dispatch(
            new UpdateCustomItemsAttempt({
              customItemId: modelData.customItemIdx,
              generalItemId: modelData.worksheetItem.itemId,
              customItemPatch: modelData.customItemUpdate,
            }));
        }),
        tap(({ worksheet, modelData }) => {
          this.store.dispatch(
            new MoveWorksheetItemToStorageArea({
              worksheetId: worksheet.id,
              initialStorageAreaId: modelData.initialStorageAreaId,
              destinationStorageAreaId: modelData.destinationStorageAreaId,
              worksheetItemPost: modelData.worksheetItemPost,
              areaExpandStatus: true,
              itemDescription: modelData.worksheetItemDescription,
              successMessage: 'MESSAGES.ITEM_MOVED_TO_SUCCESSFULLY',
              duplicate: false,
              inventoryItem: modelData.worksheetItem
            }))
        }),
        tap(() => this.afterWorksheetItemMoved())
      );
  }

  async duplicateClicked(item: WorksheetItem, storageAreaId: string) {
    const itemName = await firstValueFrom(this.getLocalizedProductDescription(this.inventoryItems[item.itemId])).toPromise();
    const worksheetItemPost: WorksheetItemPost = {
      itemId: item.itemId,
      itemType: item.itemType,
      primaryUnit: item.primaryUnit,
      primaryUnitQty: '',
      primaryUnitType: item.primaryUnitType,
      secondaryUnit: item.secondaryUnit,
      secondaryUnitQty: '',
      secondaryUnitType: item.secondaryUnitType
    };
    this.worksheet$.pipe(first()).subscribe(ws => {
      this.store.dispatch(new CreateWorksheetItemAttempt({
        worksheetId: ws.id,
        storageAreaId,
        worksheetItemPost,
        areaExpandStatus: true,
        itemDescription: itemName,
        successMessage: 'MESSAGES.ITEM_DUPLICATED_SUCCESSFULLY',
        duplicate: true
      }));
    });
  }

  async deleteClicked(item: WorksheetItem, storageAreaId: string): Promise<void> {
    const itemName = await firstValueFrom(this.getLocalizedProductDescription(this.inventoryItems[item.itemId])).toPromise();
    const title = this.translate.instant(
      'STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_MODAL.REMOVE_STORAGE_AREA_ITEM_DIALOG_TITLE',
      { itemName }
    );
    const submitButtonAriaLabel = this.translate.instant('STORAGE_AREA.REMOVE_STORAGE_AREA_BUTTON_ARIA_LABEL');
    const submitButtonLabel = this.translate.instant('STORAGE_AREA.REMOVE_STORAGE_AREA_ITEM_BUTTON');
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        returnData: item,
        title,
        cancelButtonId: 'cancel-delete-item-button',
        submitButtonId: 'delete-item-button',
        submitButtonAriaLabel,
        submitButtonLabel
      },
      width: '608px',
      height: '237px'
    });

    dialogRef.afterClosed().subscribe(worksheetItemToBeDeleted => {
      if (worksheetItemToBeDeleted) {
        this.worksheet$.pipe(first()).subscribe(ws => {
          this.store.dispatch(new DeleteInventoryItemAttempt({
            worksheetId: ws.id,
            storageAreaId,
            inventoryItem: item
          }));
          const snackref = this.snackBar.open(`${itemName} ` +
            this.translate.instant('STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_DELETED_SUCCESSFULLY'),
            this.translate.instant('UNDO'), { duration: 4000 });
          let undo = false;
          snackref.afterDismissed().subscribe(() => {
            this.processAction(undo, ws.id, storageAreaId, item);
            this.afterWorksheetItemDeleted();
          });
          snackref.onAction().subscribe(() => {
            undo = true;
          });
          this.afterWorksheetItemDeleted();
        });
      }
    });
  }

  async editClicked(storageAreaId: string, worksheetItemToEdit: WorksheetItem): Promise<void> {
    const itemName = await firstValueFrom(this.getLocalizedProductDescription(this.inventoryItems[worksheetItemToEdit.itemId])).toPromise();
    this.openGfsEditItemModal(this.inventoryItems[worksheetItemToEdit.itemId], storageAreaId, itemName, worksheetItemToEdit);
  }

  openGfsEditItemModal(item: CountableItem, storageAreaId: string, itemName: string, worksheetItemToEdit: WorksheetItem) {
    this.dialog.open(EditItemDialogComponent, {
      data: {
        item,
        itemName,
        currentStorageAreaId: storageAreaId,
        worksheetItemToEdit
      },
      width: '840px',
      autoFocus: false,
      panelClass: 'inventory-edit-item-dialog'
    });
  }

  getLocalizedMeasurementUnitName(unit: MeasurementUnit, lang: string): string {
    return this.localizedValueUtils.getLocalizedMeasurementUnitName(unit, lang);
  }

  getLocalizedMeasurementUnitSymbol(unit: MeasurementUnit, lang: string): string {
    return this.localizedValueUtils.getLocalizedMeasurementUnitSymbol(unit, lang);
  }

  getUnitDisplayName(unit: CountingUnit, lang: string): string {
    return this.localizedValueUtils.getCountingUnitDisplayName(unit, lang);
  }

  private getItemId(item: WorksheetItem) {
    return item.itemType === 'GENERAL' && this.inventoryItems[item.itemId] && this.inventoryItems[item.itemId].customItem
      ? this.inventoryItems[item.itemId].customItem.id : item.itemId;
  }

  getCountingUnits(
    worksheetItem: WorksheetItem,
    isSecondaryUnit?: boolean
  ) {
    return this.storageAreaUtils.getCountingUnitsByWorksheetItem(
      this.inventoryItems,
      worksheetItem,
      this.countingUnits,
      isSecondaryUnit
    );
  }

  getItemCountingUnits(item: WorksheetItem, lang: string) {
    const unitKey = this.storageAreaUtils.determineLessCaseUnitToDisplayByWorksheetItem(item);
    return this.getCountingUnits(item).filter(countingUnit =>
      countingUnit.unit.unitId === unitKey.unitToDisplay
      && countingUnit.unit.unitType === unitKey.unitTypeToDisplay);
  }

  countingUnitDisabled(countingUnit: CountingUnit, selectedUnitType: string, selectedUnit: string): boolean {
    const disabled = ((countingUnit.unit.unitType === selectedUnitType) &&
      (countingUnit.unit.unitId === selectedUnit));
    return disabled;
  }

  getPrimaryItemValue(item: WorksheetItem): number {
    const itemId = this.storageAreaUtils.getDisplayItemIdByItemId(item.itemId, this.inventoryItems);
    const itemData = this.customItemData?.find(data => data.itemId === itemId);
    const countableItem = this.inventoryItems[item.itemId];
    return this.inventoryValueService.computePrimaryItemValue(item, countableItem, this.standardUnits, itemData);
  }

  getSecondaryItemValue(item: WorksheetItem): number {
    const itemId = this.storageAreaUtils.getDisplayItemIdByItemId(item.itemId, this.inventoryItems);
    const itemData = this.customItemData.find(data => data.itemId === itemId);
    const countableItem = this.inventoryItems[item.itemId];
    return this.inventoryValueService.computeSecondaryItemValue(item, countableItem, this.standardUnits, itemData);
  }

  onPrimaryCountBlur(item: WorksheetItem, event: any) {
    item.primaryUnitQty = (event.target.value === '' ? 0 : event.target.value);
  }

  onSecondaryCountBlur(item: WorksheetItem, event: any) {
    item.secondaryUnitQty = (event.target.value === '' ? 0 : event.target.value);
  }

  onPrimaryUnitSelected(item: WorksheetItem, event: any) {
    item.primaryUnit = event.value.unit.unitId;
    item.primaryUnitType = event.value.unit.unitType;
    this.updateWorksheetItem(item);
  }

  getPrimaryUnit(item: WorksheetItem): CountingUnit {
    const primaryUnit = this.countingUnits
      ? this.countingUnits.find(u => {
        const result = [
          u.unit.unitType === item.primaryUnitType,
          u.unit.unitId === item.primaryUnit
        ].every(isTrue => isTrue);
        return result;
      })
      : null;

    if (primaryUnit?.unit?.unitId === 'CASE' && item.isEach) {
      return this.countingUnits?.find(unit => unit?.literalUnit?.type === InventoryConstants.SAP_UOM_EACH) || null;
    }

    return primaryUnit;
  }

  onSecondaryUnitSelected(item: WorksheetItem, event: any) {
    if (event.value) {
      item.secondaryUnit = event.value.unit.unitId;
      item.secondaryUnitType = event.value.unit.unitType;
    } else {
      item.secondaryUnit = 'UNIT';
      item.secondaryUnitType = 'LITERAL';
    }
    this.updateWorksheetItem(item);
  }

  isSecondaryUnitNotPresent(item: WorksheetItem): boolean {
    if (item.itemType === 'GENERAL') {
      return true;
    }
    if (this.getCountingUnits(item).length === 2) {
      return true;
    }
    return false;
  }

  getSecondaryUnit(item: WorksheetItem): CountingUnit {
    const selected = this.countingUnits
      ? this.countingUnits.find(u => {
        const result = [
          u.unit.unitType === item.secondaryUnitType,
          u.unit.unitId === item.secondaryUnit
        ].every(isTrue => isTrue);
        return result;
      })
      : null;
    return selected;
  }

  async onQuantityUpdate(item: WorksheetItem) {
    let primaryQty = null;
    let secondQty = null;
    let update = false;

    if (item.primaryUnitQty !== null && item.primaryUnitQty.toString() !== '.') {
      if (item.secondaryUnitQty !== null && item.secondaryUnitQty.toString() !== '.') {
        primaryQty = item.primaryUnitQty;
        secondQty = item.secondaryUnitQty;
      }
      else {
        primaryQty = item.primaryUnitQty;
      }
      update = true;
    }

    if (item.secondaryUnitQty !== null && item.secondaryUnitQty.toString() !== '.') {
      if (item.primaryUnitQty !== null && item.primaryUnitQty.toString() !== '.') {
        primaryQty = item.primaryUnitQty;
        secondQty = item.secondaryUnitQty;
      }
      else {
        secondQty = item.secondaryUnitQty;
      }
      update = true;
    }

    if (update) {
      const worksheetItemUpdate: WorksheetItem = {
        id: item.id,
        itemId: item.itemId,
        itemType: item.itemType,
        primaryUnit: item.primaryUnit,
        primaryUnitQty: primaryQty,
        primaryUnitType: item.primaryUnitType,
        secondaryUnit: item.secondaryUnit,
        secondaryUnitQty: secondQty,
        secondaryUnitType: item.secondaryUnitType,
        lastModified: item.lastModified,
        itemPrice: item.itemPrice
      };
      this.updateWorksheetItem(worksheetItemUpdate);
    }
  }

  updateWorksheetItem(item: WorksheetItem) {
    if (item.primaryUnit &&
      ((item.primaryUnit.toLowerCase() === 'case') || (item.primaryUnit.toLowerCase() === 'unit'))) {
      item.primaryUnitType = 'LITERAL';
    }
    if (item.secondaryUnit &&
      ((item.secondaryUnit.toLowerCase() === 'case') || (item.secondaryUnit.toLowerCase() === 'unit'))) {
      item.secondaryUnitType = 'LITERAL';
    }
    // WTIM-1276 This number is sometimes set to a blank string and preventing saves. Not sure how since it's a number and not a string
    // Ignored the string check to make tslint happy
    // @ts-ignore
    if ('' === item.primaryUnitQty) {
      item.primaryUnitQty = null;
    }
    // @ts-ignore
    if ('' === item.secondaryUnitQty) {
      item.secondaryUnitQty = null;
    }

    console.warn('dispatch UpdateWorksheetItem');
    this.store.dispatch(new UpdateWorksheetItem({
      worksheetId: this.worksheetId,
      storageAreaId: this.storageArea.id,
      worksheetItem: item
    } as WorksheetItemChange));
  }

  countAddPrimaryClicked(item: WorksheetItem) {
    item.primaryUnitQty++;
    this.onQuantityUpdate(item);
  }

  countAddSecondaryClicked(item: WorksheetItem) {
    item.secondaryUnitQty++;
    this.onQuantityUpdate(item);
  }

  countSubtractPrimaryClicked(item: WorksheetItem) {
    if (item.primaryUnitQty > 0) {
      item.primaryUnitQty--;
      this.onQuantityUpdate(item);
    }
  }

  countSubtractSecondaryClicked(item: WorksheetItem) {
    if (item.secondaryUnitQty > 0) {
      item.secondaryUnitQty--;
      this.onQuantityUpdate(item);
    }
  }

  getStorageAreasMinusCurrentStorageArea(storageAreaId: string): Observable<StorageArea[]> {
    return this.storageAreaUtils.getStorageAreasMinusCurrentStorageArea(this.worksheet$, storageAreaId);
  }

  getTranslation(storageAreaName: string): string {
    return this.storageAreaUtils.getTranslation(storageAreaName);
  }

  moveToNextItemCount(inputId: string) {
    const itemCountInputs = this.itemCountInputs.toArray();
    const maxIndex = itemCountInputs.length - 1;

    const index = itemCountInputs.findIndex(item => item.nativeElement.id === inputId);
    if (index < maxIndex) { // advance to the next element index
      itemCountInputs[index + 1].nativeElement.focus();
    } else if (index === maxIndex) {

      const input = this.itemCountInputs.toArray()[0];
      if (input) {
        input.nativeElement.focus();
      }
    }
  }

  worksheetItemDropped(event: any, isDragDrop: boolean = false) {
    this.worksheetItemDrop.emit({
      currentIndex: event.currentIndex,
      previousIndex: event.previousIndex,
      storageAreaId: this.storageArea.id,
      isDragDrop
    });
  }

  getWorksheetItemRank(item: WorksheetItem) {

    return this.getCurrentItems().findIndex(c => c.id === item.id) + this.pageOffset + 1;
  }

  handleRankingChange(event, previousRank: number) {
    // cannot set rank to something below 0 or greater than the total number of items
    if ((+event.target.value) < 1) {
      event.target.value = '1';
    } else if ((+event.target.value) > this.storageArea.worksheetItems.length) {
      event.target.value = `${this.storageArea.worksheetItems.length}`;
    }

    this.worksheetItemDropped({
      currentIndex: +event.target.value - 1,
      previousIndex: previousRank - 1
    });
  }

  checkIsDisabled(permission: string): Observable<boolean> {
    return this.permissionsService.permissions$.pipe(
      map((permissions) => !permissions[permission])
    );
  }

  isAllowedToEdit(item: CountableItem): Observable<boolean> {
    return this.customerPK$.pipe(
      filter(pk => !!pk),
      map(pk => {
        if (item.customItem) {
          return pk.customerId === item.customItem.customerPK.customerId;
        }
        return true;
      })
    );
  }

  createTextUnitDetails(worksheetItemRef: WorksheetItem, lang: string): Observable<string> {
    const countableItem = this.inventoryItems[worksheetItemRef.itemId];

    return this.storageAreaUtils
      .getTextWorksheetItemPrimaryUnit(
        this.inventoryItems,
        worksheetItemRef,
        this.countingUnits,
        lang
      ).pipe(
        withLatestFrom(
          of(this.showGFSUnitDetails(worksheetItemRef))
            .pipe(
              concatMap(showGFSUnitDetails => iif(
                () => showGFSUnitDetails,
                this.storageAreaUtils.getGFSDefaultUnitDetails(this.inventoryItems, countableItem, lang),
                of('')
              ))
            )
        ),
        map(([text, defaultGFSUnitInfo]) => {
          text = text?.trim() === '' ? null : text;

          const result = text ?? ' | ' + defaultGFSUnitInfo;

          return result;
        }));

  }

  getLocalizedBrand(
    countableItem: CountableItem
  ): Observable<string> {
    return this.suppliers$
      .pipe(
        concatMap(allSuppliers =>
          this.localizedValueUtils.getLocalizedBrand(
            countableItem,
            allSuppliers,
            this.currentLang$
          )),
        first()
      );
  }

  getDisplayItemId(countableItem: CountableItem): string {
    return this.storageAreaUtils.getDisplayItemIdByCountableItem(countableItem);
  }

  getLocalizedProductDescription(
    countableItem: CountableItem
  ): Observable<string> {
    return this.localizedValueUtils.getLocalizedProductDescription(
      countableItem,
      this.currentLang$,
    );
  }
  getLocalizedImageUrl(
    countableItem: CountableItem,
    w: number,
    h: number
  ) {
    return this.localizedValueUtils.getLocalizedImageUrl(countableItem, this.currentLang$, w, h);
  }

  isNonGfsItemTierHavingNoQuantityOrUnit(
    itemType: string,
    unit: string,
    quantity: number
  ): boolean {
    const conditions = [
      StorageAreaCardComponent.nonGfsItemTypes
        .some(type => caseInsensitiveEquals(type, itemType)),
      caseInsensitiveEquals(unit, InventoryConstants.LITERAL_UOM_UNIT),
      !quantity
    ];
    return conditions.every(isTrue => isTrue);
  }

  isSecondaryUnitBlank(
    item: WorksheetItem,
    unitType: string,
    unit: string
  ): boolean {
    const isSecondaryUnitPresent = !this.isSecondaryUnitNotPresent(item);
    return this.storageAreaUtils.isSecondaryUnitBlank(isSecondaryUnitPresent, unitType, unit);
  }

  canComputeTierValue(
    item: WorksheetItem,
    quantity: number,
    unit: string,
    unitType: string
  ): boolean {
    const isNonGfsItemTierHavingNoQuantityOrUnit = this.isNonGfsItemTierHavingNoQuantityOrUnit(item.itemType, unit, quantity);
    if (isNonGfsItemTierHavingNoQuantityOrUnit) {
      return true;
    }

    const isSeconaryUnitBlank = this.isSecondaryUnitBlank(item, unitType, unit);
    if (isSeconaryUnitBlank) {
      return false;
    }

    return this.inventoryValueService
      .canComputeTierValue(quantity, unit, unitType, item.itemPrice);
  }
}

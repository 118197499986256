import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CountableItem,
  CustomerPK,
  IAppContext,
  RECIPE_PROFIT_CALCULATOR_CONFIG,
  RecipeProfitCalculatorConfig
} from '@gfs/shared-models';
import {
  CountableItemService,
  GeneralItemService,
  InjectionTokens,
  ItemCategoryService,
  MeasurementUnitService,
  MessageService,
  SupplierService,
  WINDOW
} from '@gfs/shared-services';
import { RecipeService } from '@gfs/shared-services/services/recipe.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, forkJoin, merge, of } from 'rxjs';
import {
  catchError, concatMap, debounceTime, filter, first, map, mergeMap, tap, toArray, withLatestFrom
} from 'rxjs/operators';
import {
  ActionTypes,
  ActionUnion, GetCountableItemsAttempt, GetCountableItemsError, GetCountableItemsSuccess, GetGeneralItemByCustomItemIdError,
  GetGeneralItemByCustomItemIdSuccess, GetItemCategoriesBeginLoading,
  GetItemCategoriesError, GetItemCategoriesSuccess, GetMeasurementUnitsBeginLoading,
  GetMeasurementUnitsError, GetMeasurementUnitsSuccess, GetSuppliersBeginLoading,
  GetSuppliersError, GetSuppliersSuccess
} from './quick-add-items.actions';
import { IFeatureStateFacade, QuickSearchTypes } from './quick-add-items.state';

type GetCountableItemsAttemptData = [GetCountableItemsAttempt, CountableItem[], CountableItem[], CountableItem[]];

@Injectable()
export class QuickAddItemsStoreEffects {

  getCountableItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCountableItemsAttempt),
    withLatestFrom(this.appContext.customerPK),
    filter(([action]) => action.payload.searchText.length > 0),
    debounceTime(1000),
    concatMap(([action, pk]) => {
      const orderGuidItems$ = this.countableItemService.getCountableItems(
        pk,
        action.payload.locale,
        action.payload.searchText,
        action.payload.offset,
        action.payload.limit,
        new Date().toISOString(),
        QuickSearchTypes.OrderGuideItems);

      const isUserInCalculator = this.recipeProfitCalculatorConfig
        .isNavigatingFromCalculator(this.window);
      const nonGfsItems$ = this.getNonGfsItems$(
        action,
        pk,
        isUserInCalculator);

      const recipes$ = this.recipeService.searchRecipes(
        pk,
        action.payload.searchText)
        .pipe(
          first(),
          map((recipes) =>
            recipes.map(rec => ({
              recipeItem: rec,
              gfsItem: null,
              generalItem: null,
              customItem: null,
              customItemData: null
            } as CountableItem))),
        );

      return forkJoin([
        of(action),
        orderGuidItems$,
        nonGfsItems$,
        recipes$]
      )
        .pipe(
          concatMap(([
            action,
            catalogueItems,
            customItems,
            recipeItems
          ]: GetCountableItemsAttemptData) =>
            forkJoin([
              of(action),
              merge(
                catalogueItems,
                customItems.slice(0, 5),
                recipeItems.slice(0, 5))
                .pipe(toArray())
            ]),
          ),
          map(([action, items]) => {
            return new GetCountableItemsSuccess({
              searchText: action.payload.searchText,
              locale: action.payload.locale,
              items
            });
          }),
          // catchError needs to be wrapped in a higher-order mapping operator,
          //    otherwise the effect will unsubscribe and the user will need to reload the page.
          //    Receiving http 500 in this effect would otherwise break the quick-add feature.
          catchError((err) => of(new GetCountableItemsError(err))));
    })));


  getGenealItemFromCustomItemId$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetGeneralItemByCustomItemIdAttempt),
    withLatestFrom(this.appContext.customerPK),
    mergeMap(([action, pk]) =>
      this.generalItemService
        .getGeneralItemByCustomItemId(action.payload.customItemId, pk)
        .pipe(
          map(
            (response) =>
              new GetGeneralItemByCustomItemIdSuccess({
                customItemId: action.payload.customItemId,
                generalItem: response,
              })
          )
        )
    ),
    catchError((err) => of(new GetGeneralItemByCustomItemIdError(err)))
  ));


  getItemCategories$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetItemCategoriesAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!this.appContext.customerPK)),
      this.appContext.customerPK,
      this.appContext.language
    ),
    mergeMap(([action, state, pk, language]) => {
      if (state.quickAddItemsFeature.itemCategories !== null) {
        return [
          new GetItemCategoriesSuccess({
            categories: state.quickAddItemsFeature.itemCategories,
            language,
          }),
        ];
      }
      if (!state.quickAddItemsFeature.itemCategories_isLoading) {
        this.store.dispatch(new GetItemCategoriesBeginLoading());
        return this.itemCategoryService.getItemCategories(pk).pipe(
          map(
            (categories) =>
              new GetItemCategoriesSuccess({
                categories,
                language,
              })
          ),
          catchError((err) => of(new GetItemCategoriesError(err)))
        );
      }
      return [];
    })
  ));


  getMeasurementUnits$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetMeasurementUnitsAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!this.appContext.customerPK)),
      this.appContext.customerPK
    ),
    mergeMap(([action, state, pk]) => {
      if (state.quickAddItemsFeature.measurementUnits !== null) {
        return [new GetMeasurementUnitsSuccess(state.quickAddItemsFeature.measurementUnits)];
      }
      if (!state.quickAddItemsFeature.measurementUnits_isLoading) {
        this.store.dispatch(new GetMeasurementUnitsBeginLoading());
        return this.measurementUnitService.getMeasurementUnits(pk).pipe(
          map((units) => new GetMeasurementUnitsSuccess(units)),
          catchError((err) => of(new GetMeasurementUnitsError(err)))
        );
      }
      return [];
    })
  ));

  addItemsError$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.GetCountableItemsError,
      ActionTypes.GetMeasurementUnitsError
    ),
    filter((action: any) => {
      const httpError = action.error as HttpErrorResponse;
      return !(
        httpError.status === 400 &&
        httpError.error !== null &&
        httpError.error.code === '103'
      );
    }),
    tap((action) => {
      this.messageService.queue(action.error.message);
    })
  ), { dispatch: false });


  getSuppliers$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetSuppliersAttempt),
    withLatestFrom(
      this.store.pipe(filter((state) => !!this.appContext.customerPK)),
      this.appContext.customerPK
    ),
    mergeMap(([action, state, pk]) => {
      if (state.quickAddItemsFeature.suppliers !== null) {
        return [
          new GetSuppliersSuccess({
            suppliers: state.quickAddItemsFeature.suppliers,
          }),
        ];
      }
      if (state.quickAddItemsFeature.suppliers_loading === false) {
        this.store.dispatch(new GetSuppliersBeginLoading());
        return this.supplierService.getSuppliers(pk).pipe(
          map((suppliers) => new GetSuppliersSuccess({ suppliers })),
          catchError((err) => of(new GetSuppliersError(err)))
        );
      }
      return [];
    })
  ));

  constructor(
    private actions$: Actions<ActionUnion>,
    private store: Store<IFeatureStateFacade>,
    private countableItemService: CountableItemService,
    private generalItemService: GeneralItemService,
    private itemCategoryService: ItemCategoryService,
    private measurementUnitService: MeasurementUnitService,
    private supplierService: SupplierService,
    private messageService: MessageService,
    private recipeService: RecipeService,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    @Inject(WINDOW) public window: Window,
  ) { }

  getNonGfsItems$(
    action: GetCountableItemsAttempt,
    pk: CustomerPK,
    isUserInRecipeProfitCalculator: boolean
  ): Observable<CountableItem[]> {
    return isUserInRecipeProfitCalculator
      ? of([])
      : this.countableItemService.getCountableItems(
        pk,
        action.payload.locale,
        action.payload.searchText,
        action.payload.offset,
        action.payload.limit,
        new Date().toISOString(),
        QuickSearchTypes.NonGfsItems);
  }
}

import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { CountableItem, IAppContext, MeasurementUnit, StorageArea, Worksheet, WorksheetPrintInfo } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { AppState } from '@gfs/store/inventory/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { PrintWorksheetDialogComponent } from '@inventory-ui/inventory-worksheet/print-worksheet-dialog/print-worksheet-dialog.component';
import { promiseMeAlways } from '@inventory-ui/v2/common/preparing-app/preparing-app.component';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { concatMap, first, map, mergeMap, skip,timeout } from 'rxjs/operators';

@Component({
  selector: 'app-configure-product',
  template: '',
})
export class PrintWorksheetLauncherComponent implements OnInit {

  printStorageAreas: StorageArea[] = [];
  worksheet$: Observable<Worksheet> = this.store.select(
    (state) => state.worksheets.worksheets[state.worksheets.selected]
  );
  standardMeasurementUnits$: Observable<MeasurementUnit[]> = this.store
    .select((state) => state.addItemsFeature.measurementUnits)
    .pipe(
      map((units) => (units ?? []).filter((unit) => unit.measurementType === 'weight'))
    );

  inventoryItems$ = this.store.select(
    (state) => state.inventoryItems.inventoryItems
  );
  customItemData$ = this.store.select(
    (state) => state.worksheets.customItemData
  );
  customerInfo$ = this.store.select((state: AppState) =>
    state.auth ? state.auth : null
  );
  currentLang$ = this.store.select((state) => state.layout.language);
  isMobile$: Observable<boolean> = this.store.select(
    (state) => state.layout.isMobile
  );

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private loadingOverlay: LoadingSpinnerOverlayService,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,

  ) { }

  ngOnInit(): void {
    this.launchPrintDialog().subscribe();
  }



  launchPrintDialog() {
    return this.activatedRoute.data.pipe(
      map(
        ({ 0: worksheetItemConfig }) => {
          return worksheetItemConfig as Worksheet;
        }
      ),
      concatMap(v => this.printWorksheet(v)),
      mergeMap((worksheetId) =>
        // navigate back to the worksheet on close
        promiseMeAlways(this.navigateToWorksheet(worksheetId))
      )
    );
  }

  printWorksheet(ws: Worksheet): Observable<string> {
    this.hideSpinner();

    return of(ws)
      .pipe(
        first(),
        concatMap((ws) => {

          if (ws) {
            this.printStorageAreas = ws.storageAreas.filter(
              (sa) => sa.worksheetItems.length > 0
            );
            if (this.printStorageAreas.length < 1) {
              this.printStorageAreas = ws.storageAreas.filter(
                (sa) => sa.name === 'unassigned'
              );
            }

            return this.beforePrint()
              .pipe(
                first(),
                concatMap(printInfo =>
                  this.dialog.open(PrintWorksheetDialogComponent, {
                    data: {
                      worksheetPrintInfo: printInfo,
                    },
                    panelClass: 'print-worksheet-dialog',
                  }).afterClosed()
                    .pipe(
                      map(v => ws.id)
                    )
                )
              );
          }
          return of('');
        })
      );
  }

  beforePrint(): Observable<WorksheetPrintInfo> {
    const worksheetInfo$ = this.worksheet$.pipe(
      map((worksheetData) => ({
        name: worksheetData.name,
        worksheet: worksheetData,
      }))
    );

      const allProducts$: Observable<{
        [s: string]: CountableItem;
      }> = this.inventoryItems$.pipe(
        skip(2),
        timeout({
          each: 3000,
          with: () =>  this.inventoryItems$.pipe(map((items) => items))
        }),
        map((items) => items)
      )

    const customUnitsMap$ = this.customItemData$.pipe(
      map((items) =>
        items.reduce((acc, next) => {
          acc[next.itemId] = next.countingUnits.map((value) => value.custom);
          return acc;
        }, {})
      )
    );

    const units$ = combineLatest([
      customUnitsMap$,
      this.standardMeasurementUnits$,
    ]).pipe(
      first(),
      map(([customUnitsMap, standardMeasurementUnits]) => ({
        customUnitMap: customUnitsMap,
        standardUnits: standardMeasurementUnits,
      }))
    );

    return combineLatest([
      worksheetInfo$,
      this.customerInfo$,
      allProducts$,
      this.currentLang$,
      this.isMobile$,
      units$,
    ]).pipe(
      first(),
      map(
        ([
          worksheetInfo,
          customerInfo,
          itemMap,
          currentLang,
          isMobile,
          units,
        ]) => ({
          name: worksheetInfo.name,
          worksheet: worksheetInfo.worksheet,
          formattedCreatedDate: this.getFormattedDate(
            worksheetInfo.worksheet.created,
            currentLang
          ),
          customerName: customerInfo.customerName,
          customerId: customerInfo.pk.customerId,
          items: itemMap,
          customUnits: units.customUnitMap,
          selectedStorageAreas: this.printStorageAreas,
          mobile: isMobile,
          standardUnits: units.standardUnits,
        })
      )
    );
  }

  getFormattedDate(date: string, currentLang: string): string {
    // NOTE: If this gets used outside this component, turn this into a pipe
    let dateString: string;
    if (currentLang === InventoryConstants.LANGUAGES.ENGLISH) {
      dateString = formatDate(date, 'MMM. d, y', currentLang);
    } else {
      dateString = formatDate(date, 'mediumDate', currentLang);
    }

    return dateString;
  }

  hideSpinner() {
    return this.loadingOverlay.hide();
  }
  navigateToWorksheet(worksheetId: string) {
    return this.router.navigateByUrl(`/worksheet/${worksheetId}`, { replaceUrl: true });
  }
}
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EditItemDialogComponent } from '@gfs/shared-components';
import { WorksheetItemConfigDTO } from '@gfs/shared-models';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { promiseMeAlways } from '@inventory-ui/v2/common/preparing-app/preparing-app.component';
import { Observable } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-configure-product',
  template: '',
})
export class ConfigureProductComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingSpinner: LoadingSpinnerOverlayService,
  ) { }

  ngOnInit() {
    this.createOpenDialogBehavior()
      .subscribe();
  }


  createOpenDialogBehavior() {
    return this.activatedRoute.data.pipe(
      first(),
      map(
        ({ 0: worksheetItemConfig }) => {
          return worksheetItemConfig as WorksheetItemConfigDTO;
        }
      ),
      mergeMap(v => this.open(v))
    );
  }

  open(
    row: WorksheetItemConfigDTO
  ): Observable<any> {
    return this.createConfigureProductModal(row)
      .afterClosed()
      .pipe(
        first(),
        tap(() => {
        }),
        mergeMap(() =>
          promiseMeAlways(
            this.navigateToWorksheet(this.router, row.worksheetId)
          )
        )
      );
  }


  createConfigureProductModal(
    row: WorksheetItemConfigDTO
  ) {
    return this.dialog.open(EditItemDialogComponent, {
      data: {
        item: row.countableItem,
        currentStorageAreaId: row.storageArea.id,
        worksheetItemToEdit: row.worksheetItem
      },
      width: '840px',
      autoFocus: false,
      panelClass: 'inventory-edit-item-dialog'
    });
  }

  showSpinner() {
    return this.loadingSpinner.show();
  }

  hideSpinner() {
    return this.loadingSpinner.hide();
  }

  async navigateToWorksheet(
    router: Router,
    worksheetId: string,
  ) {
    return router.navigateByUrl(`/worksheet/${worksheetId}`, { replaceUrl: true });
  }

}
import { Entitlement } from '@gfs/shared-models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthUtil {

  roleOrder = [
    { roleName: 'com.gfs:inventory.admin', roleOrder: '1' },
    { roleName: 'com.gfs:inventory.editor2', roleOrder: '2' },
    { roleName: 'com.gfs:inventory.editor1', roleOrder: '3' },
    { roleName: 'com.gfs:inventory.counter2', roleOrder: '4' },
    { roleName: 'com.gfs:inventory.counter1', roleOrder: '5' },
    { roleName: 'com.gfs:inventory.access', roleOrder: '6' }
  ];

  processEntitlements(usersInventoryEntitlements: Entitlement[]): Entitlement {
    let selectedEntitlement: Entitlement;

    if (usersInventoryEntitlements.length > 1) {
      let entitlement1: Entitlement;
      let role1;
      let entitlement2: Entitlement;
      let role2;
      for (const index in usersInventoryEntitlements) {
        if (usersInventoryEntitlements.length > Number(index) + 1) {
          entitlement1 = usersInventoryEntitlements[index];
          role1 = this.roleOrder.find(value => value.roleName === entitlement1.roleName);
          entitlement2 = usersInventoryEntitlements[Number(index) + 1];
          role2 = this.roleOrder.find(value => value.roleName === entitlement2.roleName);
          if (role1 && role2 &&
            role1 !== role2 &&
            role1.roleOrder < role2.roleOrder) {
            selectedEntitlement = entitlement1;
          } else if (role1 && role2 && role1.roleOrder > role2.roleOrder) {
            selectedEntitlement = entitlement2;
          } else if (role1) {
            selectedEntitlement = entitlement1;
          } else if (role2) {
            selectedEntitlement = entitlement2;
          }
        }
      }
    } else {
      selectedEntitlement = usersInventoryEntitlements[0];
    }
    return selectedEntitlement;
  }
}

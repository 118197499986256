import {
  CountableItem,
  CustomItem,
  MeasurementUnit,
  CustomItemData,
} from '@gfs/shared-models';

export enum QuickSearchTypes {
  OrderGuideItems = 'ORDER_GUIDE',
  NonGfsItems = 'NONGFS_ITEMS',
  RecipeItems = 'RECIPES_ITEMS',
}

export const FeatureKey = 'quickAddItemsFeature';

export interface IFeatureStateFacade {
  [FeatureKey]: FeatureState;
}
export interface FeatureState {
  countableItems: CountableItem[];
  suppliers: any[];
  suppliers_loading: boolean | null;
  itemCategories: any[];
  itemCategories_isLoading: boolean | null;
  customItem: CustomItem;
  measurementUnits: MeasurementUnit[];
  measurementUnits_isLoading: boolean | null;
  searching: boolean;
  customItemData: CustomItemData[];
  customItemData_isLoading: boolean;
  error: string;
  isSaving: boolean;
}

export const initialState: FeatureState = {
  countableItems: [],
  suppliers: null,
  suppliers_loading: false,
  itemCategories: null,
  itemCategories_isLoading: false,
  customItemData: null,
  customItemData_isLoading: false,
  customItem: null,
  measurementUnits: null,
  measurementUnits_isLoading: false,
  searching: false,
  error: null,
  isSaving: false,
};

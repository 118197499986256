export interface PrintableRecipeVM {
  id: string;
  isLoading: boolean;
  isReady: boolean;
  recipeName: string;
  formattedPrepTime: string;
  formattedCookTime: string;
  ingredients: PrintableIngredientEntry[];
  prepInstructions: string;
  orderedImageUriList: string[];
  errorMessage?: string;
}
export interface PrintableIngredientEntry {
  formattedMeasurement: string;
  ingredientName: string;
}

import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { AuthResolver } from './auth-resolver';

export const authResolverFn: ResolveFn<Observable<boolean>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: AuthResolver = inject(AuthResolver)
): Observable<boolean> => {
  return service.resolve();
};

import { Component, Inject, Input, OnInit } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { CountableItem, IAppContext, ItemReference, ResolvedItem } from '@gfs/shared-models';
import { arrayToEntityDictionary, InjectionTokens, LocalizedValueUtilsService, StorageAreaUtilsService } from '@gfs/shared-services';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { firstValueFrom, isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { GetSuppliersAttempt, IFeatureStateFacade } from '@gfs/store/feature/add-items';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, first, map } from 'rxjs/operators';

@Component({
  selector: 'gfs-inventory-item-card',
  templateUrl: './inventory-item-card.component.html',
  styleUrls: ['./inventory-item-card.component.scss'],
})
export class InventoryItemCardComponent implements OnInit {
  @Input()
  public set itemData(value: any) { if (!!value) { this.item$.next(value); } }

  constructor(
    private localizedValueUtils: LocalizedValueUtilsService,
    @Inject(InjectionTokens.IAPP_CONTEXT)
    private appCtx: IAppContext,
    private storageAreaUtils: StorageAreaUtilsService,
    public store: Store<IFeatureStateFacade>
  ) {
    if (this.displayItemPrices === undefined) {
      this.displayItemPrices = true;
    }
  }

  language$ = this.appCtx.language;
  item$ = new BehaviorSubject<ResolvedItem>(null);
  @Input() uiIndex = 0;
  @Input() isEditItem: false;
  @Input() currentLanguage: string;
  @Input() isMobile$: Observable<boolean>;
  vm$ = new Subject<any>();
  hasIngredientPurchasePrice$ = new BehaviorSubject<boolean>(false);
  mItemData: ResolvedItem = null;


  @Input() displayItemPrices: boolean;
  @Input()
  public set hasIngredientPurchasePrice(value: boolean) {
    this.hasIngredientPurchasePrice$.next(value);
  }

  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;

  suppliers$ = this.store.select(state => state.addItemsFeature.suppliers);


  showDescriptionFilter = ['GFS'];
  async ngOnInit(): Promise<void> {
    this.store.dispatch(new GetSuppliersAttempt());
    this.mItemData = await this.item$.pipe(
      isTruthy(),
      first(),
    ).toPromise();
    this.createVM$()
      .subscribe(this.vm$);
  }


  createVM$() {
    return this.item$.pipe(
      isTruthy(),
      // map(item => this.patchItemCompatibilityResolvedItem(item) /* patch to make interoperable with resolvedItem*/),
      distinctUntilChanged(),
      concatMap(item => combineLatest([
        combineLatest([
          of(item),
          of(item.itemReference),
          of(this.getItemReference(item).key),
          of(this.getItemReference(item).type),
        ]),
        combineLatest([
          of(this.getItemId()),
          of(this.showDescription()),
          this.getLocalizedBrand(),
          this.getLocalizedProductDescription(),
          this.getLocalizedImageUrl(48, 48),
        ]),
        combineLatest([
          of(this.getItemInnerPackSize()),
          of(this.getItemQtyPerUnit()),
          of(this.getItemCasePrice()),
          of(this.getItemUnitPrice()),
          of(this.calculateRowHeight())
        ]),
        combineLatest([
          of(item).pipe(map(g => g.customItem.supplierItemCode), catchError(() => of(null))),
          this.hasIngredientPurchasePrice$,
          this.createTextUnitDetails()
        ])
      ])),
      map(([
        [itemData, itemRef, key, type],
        [itemId, showDescription, brand, productDescription, imageUri],
        [innerPackSize, qtyPerUnit, casePrice, unitPrice, rowHeight],
        [supplierItemCode, hasIngredientPurchasePrice, unitDetails]
      ]) => {
        return ({
          itemData,
          itemRef,
          key,
          type,
          itemId,
          brand,
          showDescription,
          productDescription,
          supplierItemCode,
          imageUri,
          innerPackSize,
          qtyPerUnit,
          casePrice,
          unitPrice,
          rowHeight,
          showOutOfDateWarning: !hasIngredientPurchasePrice,
          unitDetails
        });
      }
      ),
    );

  }
  private getItemReference(item: any): ItemReference {
    return item.itemReference;
  }

  // TODO: all functions will need to be updated to handle order guide item, recipe item, or non-GFS item
  getLocalizedImageUrl(width: number, height: number): Observable<string> {
    return this.localizedValueUtils.getLocalizedImageUrl(
      this.mItemData,
      this.language$,
      width,
      height
    );
  }

  getLocalizedProductDescription(): Observable<string> {
    return this.localizedValueUtils.getLocalizedProductDescription(
      this.mItemData,
      this.language$
    );
  }

  getLocalizedBrand(): Observable<string> {
    return firstValueFrom(this.suppliers$)
      .pipe(
        concatMap(
          suppliers =>
            this.localizedValueUtils.getLocalizedBrand(this.mItemData, suppliers, this.language$)
        )
      );
  }

  getItemId(): string {
    if (this.mItemData.itemReference) {
      return this.mItemData.itemReference.key;
    }
    return (this.mItemData as any).id;
  }
  showDescription(): boolean {
    return this.mItemData != null &&
      this.showDescriptionFilter.map(r => caseInsensitiveEquals(this.mItemData.itemReference.type, r)).indexOf(true) > -1;
  }
  getItemInnerPackSize() {
    if (caseInsensitiveEquals(this.mItemData.itemReference.type, 'GFS') && this.mItemData.gfsItem) {
      return this.mItemData.gfsItem.innerPackSize;
    }
    return '';
  }

  getItemQtyPerUnit() {
    if (caseInsensitiveEquals(this.mItemData.itemReference.type, 'GFS') && this.mItemData.gfsItem) {
      return this.mItemData.gfsItem.qtyPerMasterSellUnit;
    }
    return '';
  }

  getItemCasePrice(): number {
    const item = (this.mItemData ?? ({ itemReference: { type: '' } } as any)) as ResolvedItem;
    if (caseInsensitiveEquals('GFS', item.itemReference.type)) {
      return item.gfsItem?.price?.casePrice ?? 0;
    } else if (caseInsensitiveEquals(item.itemReference.type, 'CUSTOM')) {
      if (item?.customItem.purchaseUnit?.custom?.catchWeight) {
        return (
          Number.parseFloat(item?.customItem.purchaseUnit?.custom?.price) *
          Number.parseFloat(item?.customItem.purchaseUnit?.custom?.netWeight) ?? 0
        );
      } else {
        return item?.customItem.purchaseUnit?.custom?.price
          ? Number.parseFloat(item?.customItem.purchaseUnit?.custom?.price) :
          0;
      }
    }
  }

  getItemUnitPrice(): number {
    const item = this.mItemData ?? ({ itemReference: { type: '' } } as any);
    if (item.itemReference.type === 'GFS') {
      return item?.price?.unitPrice ?? 0;
    }
    return 0;
  }

  calculateRowHeight() {
    return this.isEditItem ? '136px' : '55px';
  }

  createTextUnitDetails(): Observable<string> {
    return this.item$
      .pipe(
        isTruthy(),
        first(),
        concatMap(results =>
          this.language$
            .pipe(
              concatMap((lang) => {

                if (results.itemReference.type === 'GFS') {
                  const inventoryItems = arrayToEntityDictionary([results], (e) => e.itemReference.key);
                  return this.storageAreaUtils.getGFSDefaultUnitDetails(inventoryItems, this.mItemData, lang, ', ');
                }

                return of('');
              })
            )
        ));
  }
}

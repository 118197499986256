<a *ngIf="selectedEntitlement$ | async as selected"
   role="link"
   [routerLink]="routerLinkUrl$ | async"
   class="customer-unit-selector" >
  <div *ngIf="(customers$ | async) as customers">
    <div class="selected-customer-unit" *ngIf="(getSelectedCustomerUnit() | async) as customer">
      <mat-icon class="shop-icon" svgIcon="shop"></mat-icon>
      {{customer.customerName | truncated: 16}} #{{customer.customerPK.customerId}}
    </div>
  </div>
</a>

import { Injectable } from '@angular/core';
import { RecipeConstants } from '@gfs/constants';
import { CustomerPK, IDictionary } from '@gfs/shared-models';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class RedirectUri {
  /**
   *
   */
  constructor(public uri: string) {
  }
}
export const enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED'
}
export const enum SavingState {
  IDLE = 'IDLE',
  SAVING = 'SAVING',
  SAVED = 'SAVED'
}
export interface ErrorState {
  errorMsg: string;
}

export type CallState = LoadingState | SavingState | ErrorState;

export enum InventoryWorksheetStatusEnum {
  Open = 'open',
  Closed = 'closed'
}

// Utility function to extract the error from the state
export const getError = (callState: CallState): LoadingState | string | null => {
  if ((callState as ErrorState).errorMsg !== undefined) {
    return (callState as ErrorState).errorMsg;
  }

  return null;
};

@Injectable()
export class BaseComponentStore<TModel extends object> extends ComponentStore<TModel> {

  onDestroy$ = new Subject<void>();
  userContext$ = this.store.select(state => ({ pk: state.auth.pk as CustomerPK, lang: state.layout.language as string }));
  language$ = this.userContext$.pipe(map(r => r.lang), distinctUntilChanged());

  constructor(
    public store: Store<any>,
    defaultState: TModel
  ) {
    super(defaultState);
    console.log(defaultState);
    // @note something is causing the component store isInitialized flag to not get set correctly... investigate later
    (this as any).isInitialized = true;
  }
}

//#region rxjs operators/extensions

//#endregion

export const falseyToNull = <TModel>(x: TModel): TModel | null => x ?? null;


export type UnitOfMeasureGroupConfigEntry = {
  ordinal: number,
  heading_i18nKey: string,
  createPrompt_i18nKey: string,
  alwaysShowCreatePrompt?: boolean,
  renderWithoutGroup?: boolean;
};

export const unitGr = {
  'GFS': ['COUNT', 'WEIGHT', 'VOLUME', 'CUSTOM'],
  'RECIPE': ['RECIPE_YIELD', 'COUNT', 'CUSTOM', 'RECIPE', 'WEIGHT', 'VOLUME', 'MENUITEM'],
  'CUSTOM': ['COUNT', 'WEIGHT', 'VOLUME', 'CUSTOM'],
  'BATCHRECIPE': ['RECIPE_YIELD', 'WEIGHT', 'VOLUME'],
  'MENUITEMRECIPE': ['MENUITEM'],

  'INGREDIENT_MENUITEMRECIPE': [],
  'INGREDIENT_BATCHRECIPE': ['RECIPE', 'WEIGHT', 'VOLUME']
};

export const unitGroupConfig: IDictionary<UnitOfMeasureGroupConfigEntry> = {

  // @note option group of GFS Weight units
  [RecipeConstants.MEASUREMENT_TYPE.WEIGHT.toUpperCase()]: {
    ordinal: 2,
    heading_i18nKey: 'RECIPE.CREATE.UNITS_WEIGHT_LABEL',
    createPrompt_i18nKey: 'RECIPE.CREATE.CONFIGURE_MISSING_WEIGHT_UNIT',
  },

  // @note option group of GFS Volume units
  [RecipeConstants.MEASUREMENT_TYPE.VOLUME.toUpperCase()]: {
    ordinal: 3,
    heading_i18nKey: 'RECIPE.CREATE.UNITS_VOLUME_LABEL',
    createPrompt_i18nKey: 'RECIPE.CREATE.CONFIGURE_MISSING_VOLUME_UNIT',
  },

  // @note option group of GFS Counting units
  'COUNT': {
    ordinal: -1,
    heading_i18nKey: null,
    createPrompt_i18nKey: null
  },

  // @note option group displayed when an item is a recipe(ingredient)
  'RECIPE': {
    ordinal: 0,
    heading_i18nKey: 'RECIPE.CREATE.UNITS_RECIPE_LABEL',
    createPrompt_i18nKey: null
  },

  // @note option group displayed when an item is a Batch Recipe
  'RECIPE_YIELD': {
    ordinal: 0,
    heading_i18nKey: 'RECIPE.CREATE.UNITS_RECIPE_LABEL',
    createPrompt_i18nKey: null
  },

  'MENUITEM': {
    ordinal: 0,
    heading_i18nKey: 'RECIPE.CREATE.UNITS_RECIPE_LABEL',
    createPrompt_i18nKey: null
  },

  // @note option group of user managed units
  'CUSTOM': {
    ordinal: 1,
    heading_i18nKey: 'ADD_ITEMS.CUSTOM_UNITS',
    createPrompt_i18nKey: 'RECIPE.CREATE.ADD_EDIT_CUSTOM_UNIT',
    alwaysShowCreatePrompt: true
  },



  // @note not rendered
  'BATCHRECIPE': {
    ordinal: 0,
    heading_i18nKey: 'BATCHRECIPE RECIPE.CREATE.UNITS_RECIPE_LABEL',
    createPrompt_i18nKey: null
  },

  // @note not rendered
  'INGREDIENTRECIPE': {
    ordinal: 0,
    heading_i18nKey: 'INGREDIENTRECIPE RECIPE.CREATE.UNITS_RECIPE_LABEL',
    createPrompt_i18nKey: null
  },
};

export const userConfiguredUnitTypeMap = [
  RecipeConstants.MEASUREMENT_TYPE.WEIGHT,
  RecipeConstants.MEASUREMENT_TYPE.VOLUME,
  'CUSTOM',
].map(x => x.toUpperCase());

export function isUserConfiguredUnitType(unitKey: string): boolean {
  return userConfiguredUnitTypeMap.indexOf(unitKey) > -1;
}

export function optionHasValue(val: string | number) {
  return `${val ?? ''}`.trim().length > 0;
}

export function sanitizeOptionValue<TModel extends string | number>(val: TModel, defaultVal: TModel) {
  if (optionHasValue(val)) {
    return val;
  }
  return defaultVal;
}

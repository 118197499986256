import { formatDate, registerLocaleData } from '@angular/common';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import { Component, Inject, Input } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { IAppContext } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gfs-localized-datetime-value',
  template: '{{localizedValue$ | async}}'
})
export class LocalizedDatetimeValueComponent {
  private value$ = new BehaviorSubject<string | Date>(null);

  localizedValue$ = createLocalizedDateTime$(this.appContext.language, this.value$);
  @Input()
  public set value(value: Date) {
    this.value$.next(value);
  }

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext,
  ) {
    registerLocaleData(locale_fr_CA);
  }

}

const defaultLocale = InventoryConstants.LANGUAGES.ENGLISH;
const localizedDateTimeFormats = {
  [InventoryConstants.LANGUAGES.ENGLISH]: {
    dateFormat: 'MMM. d, y',
    timeFormat: 'h:mm a',
    separator: 'at',
  },
  [InventoryConstants.LANGUAGES.FRENCH]: {
    dateFormat: 'MMM d, y',
    timeFormat: 'HH\'h\'mm',
    separator: 'à',
  }
};

const resolveDateTimeFormat = (language: string) => {
  switch (language) {
    case InventoryConstants.LANGUAGES.FRENCH:
      return localizedDateTimeFormats[InventoryConstants.LANGUAGES.FRENCH];
    case InventoryConstants.LANGUAGES.ENGLISH:
    default:
      return localizedDateTimeFormats[InventoryConstants.LANGUAGES.ENGLISH];
  }
};

export const translateDateTime = (date: string | Date, lang: string): string => {
  const { dateFormat, timeFormat, separator } = resolveDateTimeFormat(lang);
  const datePart = formatDate(date, dateFormat, lang);
  const timePart = formatDate(date, timeFormat, lang);

  return `${datePart} ${separator} ${timePart}`;
};

export const createLocalizedDateTime$ = (languageSource: Observable<string>, valueSource: Observable<string | Date>): Observable<string> =>
  combineLatest([languageSource.pipe(map(x => localizedDateTimeFormats[x] ? x : defaultLocale)), valueSource])
    .pipe(map(([lang, dateVal]) => translateDateTime(dateVal, lang)));

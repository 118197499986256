import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  ActionTypes,
  ActionUnion,
  DownloadInventoryValueReportSuccess,
  DownloadInventoryValueReportError,
  DownloadMultiUnitReportError,
  DownloadMultiUnitReportSuccess,
} from '../actions/reports.actions';
import { of } from 'rxjs';
import { ReportsService } from '../../../lib/services/reports.service';
import { IGlobalDialogsService, InjectionTokens } from '@gfs/shared-services';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';

@Injectable()
export class ReportsEffects {


  downloadInventoryValueExcelAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DownloadInventoryValueReportAttempt),
    mergeMap((action) =>
      this.reportsService
        .downloadInventoryValueExcel(
          action.payload.worksheetSummary,
          action.payload.worksheetName,
          action.payload.locale,
          action.payload.exportType,
          action.payload.listBy
        )
        .pipe(
          map((_) => new DownloadInventoryValueReportSuccess()),
          catchError((err) => of(new DownloadInventoryValueReportError(err)))
        )
    )
  ));


  downloadInventoryValueExcelEnd$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.DownloadInventoryValueReportSuccess,
      ActionTypes.DownloadInventoryValueReportError,
      ActionTypes.DownloadMultiUnitReportSuccess,
      ActionTypes.DownloadMultiUnitReportError),
    tap((_) => this.globalDialogsService.closeLoadingModal())
  ), { dispatch: false });


  downloadMultiUnitReport$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DownloadMultiUnitReportAttempt),
    withLatestFrom(this.store.select(state => state.auth.pk)),
    mergeMap(([action, pk]) => {
      return this.reportsService
        .downloadMultiUnitReportExcel(
          action.payload.dateRange,
          pk
        )
        .pipe(
          map((_) => new DownloadMultiUnitReportSuccess()),
          catchError((err) => of(new DownloadMultiUnitReportError(err)))
        );
    })
  ));

  constructor(
    private actions$: Actions<ActionUnion>,
    private reportsService: ReportsService,
    public store: Store<AppState>,
    @Inject(InjectionTokens.IGlobalDialogService) private globalDialogsService: IGlobalDialogsService
  ) { }
}

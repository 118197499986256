import { ScrollingModule } from '@angular/cdk/scrolling';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { InventoryConstants } from '@gfs/constants';
import {
    AddItemsModule,
    CustomErrorHandlerService,
    CustomerUnitSelectionModule,
    ErrorModule,
    GeneralLedgerEffects,
    HeaderModule,
    ManageCustomItemsModule,
    MessageContentComponent,
    MessagesComponent,
    SharedComponentsModule,
    SharedModalsModule
} from '@gfs/shared-components';
import { APPLICATION_USER_ROLE, RECIPE_PROFIT_CALCULATOR_CONFIG, SelectedEntitlementObservableDependency, applicationUserRoleFactory } from '@gfs/shared-models';
import {
    AppConfigService,
    AppFeatureFlags,
    AuthorizationInterceptor,
    recipeProfitCalculatorConfigurationFactory,
    InjectionTokens,
    InventoryIconService,
    OnlineHeartbeatService,
    WINDOW_PROVIDERS,
    WINDOW,
    appFeatureFlagsFactory,
    getAPIBaseUrl,
    getInventoryAPIBaseUrl,
    employeeOIDCConfigFactory,
    oktaConfigFactory
} from '@gfs/shared-services';
import {
    AuthEffects,
    IAuthStateFacade,
    LayoutEffects,
    NetworkEffects
} from '@gfs/store/common';
import { CartEffects } from '@gfs/store/feature/cart/effects';
import { CustomerEffects } from '@gfs/store/feature/customer-unit-selection/effects';
import { AppEffects } from '@gfs/store/inventory/effects/app.effects';
import { CustomerItemsEffects } from '@gfs/store/inventory/effects/customerItems.effects';
import { InventoryEffects } from '@gfs/store/inventory/effects/inventory.effects';
import { InventoryItemsEffects } from '@gfs/store/inventory/effects/inventoryItems.effects';
import { ReportsEffects } from '@gfs/store/inventory/effects/reports.effects';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { reducers } from '@gfs/store/inventory/reducers';
import { SharedComponentsV2Module } from '@gfs/v2/shared-components';
import { GlobalDialogsService } from '@inventory-ui/architect/global-dialogs.service';
import { environment } from '@inventory-ui/environment/environment.prod';
import { AddItemsContextFactoryService } from '@inventory-ui/services/architect/add-items-context-factory.service';
import { CustomerUnitSelectionFactoryService } from '@inventory-ui/services/architect/customer-unit-selection-context-factory.service';
import { InventoryUserContextFactoryService } from '@inventory-ui/services/architect/user-context-factory.service';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { DefaultItemUnitConfigurationFactory, ItemUnitConfiguration } from 'libs/shared-services/src/v2/services/item/unit-source-config';
import { SelectedCustomerUnitFactoryService } from 'libs/shared-services/src/v2/services/selected-customer-unit-factory.service';
import { UnitsHttpService } from 'libs/shared-services/src/v2/services/unit/unit-http-service.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InventoryWorksheetModule } from './inventory-worksheet/inventory-worksheet.module';
import { InventoryModule } from './inventory/inventory.module';
import { MaterialModule } from './material.module';
import { ReportsModule } from './reports/reports.module';
import { InventoryAddItemsModalAppSettingsProviderServiceService } from './services/architect/inventory-add-items-modal-app-settings-provider-service.service';
import { PendingChangesGuard } from './services/guards/pending-changes.guard';
import { HttpActivityInterceptor } from './services/interceptors/http-activity.interceptor';
import { SharedModule } from './shared/shared.module';
import { InventoryV2Module } from './v2/v2.module';
import {
    AuthenticationService,
    NamAuthenticationService,
    NAM_OIDC_CONFIG,
    OpenIDConnectService,
    OktaAuthenticationService
} from '@gfs/shared-services/auth';

@NgModule({
    declarations: [
        AppComponent,
        MessageContentComponent,
        MessagesComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HeaderModule,
        HttpClientModule,
        ScrollingModule,
        MaterialModule,
        InventoryModule,
        InventoryWorksheetModule,
        InventoryV2Module,
        SharedComponentsV2Module,
        ReportsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        OktaAuthModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                // We are manually disabling the immutability checks introduced with angular 9
                // using html templates with store models is causing regression issues in angular 9+
                // In the future, it would be good to re-enable these checks to enforce good coding practices.
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictStateSerializability: false,
                strictActionSerializability: false,
                strictActionWithinNgZone: false
            }
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot([
            AppEffects,
            LayoutEffects,
            NetworkEffects,
            WorksheetEffects,
            InventoryItemsEffects,
            CustomerEffects,
            ReportsEffects,
            InventoryEffects,
            CustomerItemsEffects,
            AuthEffects,
            CustomerEffects,
            CartEffects,
            GeneralLedgerEffects
        ]),
        ErrorModule,
        SharedModule,
        ManageCustomItemsModule,
        NgxPermissionsModule.forRoot(),
        SharedComponentsModule,
        CustomerUnitSelectionModule,
        SharedModalsModule,
        AddItemsModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: CustomErrorHandlerService },
        WINDOW_PROVIDERS,
        { provide: LOCALE_ID, useValue: window.navigator.language },
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHref,
            deps: [PlatformLocation]
        },
        Title,
        TranslateService,
        AppConfigService,
        {
            provide: OKTA_CONFIG,
            useFactory: oktaConfigFactory,
            deps: [AppConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            deps: [
                AppConfigService,
                Store<IAuthStateFacade>
            ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpActivityInterceptor,
            deps: [OnlineHeartbeatService],
            multi: true
        },
        PendingChangesGuard,
        {
            provide: InjectionTokens.IAddItemsModalAppSettingsProviderService,
            useClass: InventoryAddItemsModalAppSettingsProviderServiceService,
        },
        { provide: InjectionTokens.API_BASE_URL, useFactory: getAPIBaseUrl, deps: [AppConfigService] },
        { provide: InjectionTokens.INVENTORY_API_BASE_URL, useFactory: getInventoryAPIBaseUrl, deps: [AppConfigService] },
        {
            provide: InjectionTokens.IAPP_CONTEXT,
            useFactory: InventoryUserContextFactoryService.factory,
            deps: [InventoryUserContextFactoryService]
        },
        {
            provide: InjectionTokens.IAddItemsFeatureBridge,
            useFactory: AddItemsContextFactoryService.factory,
            deps: [AddItemsContextFactoryService],
        },
        {
            provide: InjectionTokens.ICustomerUnitSelectionBridge,
            useFactory: CustomerUnitSelectionFactoryService.factory,
            deps: [CustomerUnitSelectionFactoryService]
        },
        {
            provide: SelectedEntitlementObservableDependency,
            useFactory: SelectedCustomerUnitFactoryService.factory,
            deps: [SelectedCustomerUnitFactoryService],
        },
        {
            provide: AppFeatureFlags,
            deps: [AppConfigService],
            useFactory: appFeatureFlagsFactory
        },
        {
            provide: InjectionTokens.IGlobalDialogService,
            useClass: GlobalDialogsService,
        },
        {
            provide: InjectionTokens.ACTIVE_APPLICATION,
            useValue: InventoryConstants.APPLICATION_KEY
        },
        { provide: ItemUnitConfiguration, useValue: DefaultItemUnitConfigurationFactory.build() },
        {
            provide: APP_INITIALIZER,
            useFactory: (unitsSvc: UnitsHttpService) => () => unitsSvc.init(),
            deps: [UnitsHttpService],
            multi: true
        },
        {
            provide: APPLICATION_USER_ROLE,
            useFactory: applicationUserRoleFactory,
            deps: [WINDOW],
        },
        {
            provide: NAM_OIDC_CONFIG,
            useFactory: employeeOIDCConfigFactory,
            deps: [
                InjectionTokens.ACTIVE_APPLICATION,
                AppConfigService,
                OpenIDConnectService,
                APPLICATION_USER_ROLE
            ],
        },
        {
            provide: RECIPE_PROFIT_CALCULATOR_CONFIG,
            useFactory: recipeProfitCalculatorConfigurationFactory,
            deps: [
                APPLICATION_USER_ROLE,
                AppConfigService,
            ],
        },
        {
            provide: AuthenticationService,
            useFactory: AuthenticationService.factory,
            deps: [
                APPLICATION_USER_ROLE,
                OktaAuthenticationService,
                NamAuthenticationService
            ]
        },
        NamAuthenticationService,
        OktaAuthenticationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    readonly storageAreaIcons = Object.keys(icons).map(icon => icons[icon]);

    constructor(public inventoryIconService: InventoryIconService) {
        inventoryIconService.registerIcons(this.storageAreaIcons);
    }

}
const { icons } = InventoryConstants;

export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}

export function createTranslateLoader(http: HttpClient) {
    const cacheBust = '.json?t=' + Date.now();
    return new TranslateHttpLoader(http, '../assets/i18n/', cacheBust);
}

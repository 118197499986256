import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { Constants } from '@gfs/constants';
import { SharedDirectivesModule } from '@gfs/directives';
import { SharedComponentsModule } from '@gfs/shared-components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OktaAuthGuard } from '@okta/okta-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MaterialModule } from '../material.module';
import { SharedModalsModule } from '../modals/shared-modals.module';
import { PipeSharingModule } from '../pipes/pipe-sharing.module';
import { CustomItemCardComponent } from './custom-item-panel/custom-item-card.component';
import { EditSupplierModalComponent } from './edit-supplier-modal/edit-supplier-modal.component';
import { ManageCustomItemsComponent } from './manage-custom-items/manage-custom-items.component';
import { SupplierItemPanelComponent } from './supplier-item-panel/supplier-item-panel.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { unitSelectionGuardFn } from '@gfs/shared-services';

@NgModule({
    imports: [
        RouterModule,
        DragDropModule,
        BrowserModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        CommonModule,
        MaterialModule,
        PipeSharingModule,
        NgxPermissionsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        SharedDirectivesModule,
        SharedModalsModule,
        SharedComponentsModule,
        BrowserAnimationsModule
    ],
    declarations: [
        ManageCustomItemsComponent,
        SupplierItemPanelComponent,
        CustomItemCardComponent,
        SupplierListComponent,
        EditSupplierModalComponent
    ],
    exports: [
        ManageCustomItemsComponent,
    ],
    providers: [
        CurrencyPipe,
        TranslateService
    ]
})
export class ManageCustomItemsModule { }

export const ManageCustomItemsRoutes = [{
    path: Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATH,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
    component: ManageCustomItemsComponent,
}];

import { Component } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'gfs-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss']
})
export class HamburgerMenuComponent {
  constructor(public router: Router) {}

  navigateToHamburgerMenu() {
    this.router.navigateByUrl(InventoryConstants.MOBILE_HAMBURGER_MENU_PATH + `?prev=${this.router.url}`);
  }
}

<main *ngIf="this.worksheetModel">
    <div
      id="storage-area-header"
      [ngClass]="{'offline-mode': !isOnline}"
    >
      <div class="back-button">
        <span
        class = "inventory-back-button primary-button mat-primary mat-mdc-button-base"
        *ngIf="isMobile"
        >
          <span class = "mat-mdc-button-persistent-ripple mdc-button__ripple">
            <span class="breadcrumb-back-tick">&lt;</span>
            <a style="color: #dcdcdc; font-size: 11px"
              *ngIf="isOnline"
              [routerLink]="['/inventory']"
            >{{ 'INVENTORY_WORKSHEET.BACK_BUTTON' | translate
              }}</a>
            <a style="color: #dcdcdc; font-size: 11px"
              *ngIf="!isOnline"
              disabled
            >{{ 'INVENTORY_WORKSHEET.BACK_BUTTON' | translate }}</a>
          </span>
        </span>
        <div
        class="sub-text-container"
        *ngIf="!isMobile"
        >
          <span class = "inventory-back-button primary-button mat-primary mat-mdc-button-base">
            <span class = "mat-mdc-button-persistent-ripple mdc-button__ripple">
              <span class="breadcrumb-back-tick">&lt;</span>
              <a style="color: #dcdcdc; font-size: 11px"
                *ngIf="isOnline"
                [routerLink]="['/inventory']"
              >{{ 'INVENTORY_WORKSHEET.BACK_BUTTON' | translate
                }}</a>
              <a style="color: #dcdcdc; font-size: 11px"
                *ngIf="!isOnline"
                disabled
              >{{ 'INVENTORY_WORKSHEET.BACK_BUTTON' | translate }}</a>
            </span>
          </span>
          <div class="worksheet-sub-title">
            <div
                  class="title-sub-text"
                  id="worksheet-subtitle"
                >
                  <div>
                    {{ 'INVENTORY_WORKSHEET.CREATED' | translate }}:
                    <gfs-localized-datetime-value [value]="worksheetModel.header.createdDate" />
                  </div>
                  <div [hidden]="isSaving$ | async">
                    {{ 'INVENTORY_WORKSHEET.MODIFIED' | translate }}:
                    <gfs-localized-datetime-value [value]="worksheetModel.header.lastModifiedDate" />
                  </div>
                  <div [hidden]="!(isSaving$ | async)">
                    {{ 'INVENTORY_WORKSHEET.SAVING' | translate }}
                  </div>
                </div>
            </div>
        </div>
        <mat-slide-toggle
          [checked]="useInfiniteScrolling$ | async"
          (change)="toggleInfiniteScrolling($event)"
        >
          {{'INVENTORY_WORKSHEET.INFINITE_SCROLL'| translate }}
        </mat-slide-toggle>
        <a style="color: #2b5a87; font-size: 14px"
          mat-button
          class="print-button"
          (click)="printWorksheet()"
          attr.aria-label="{{ 'INVENTORY_WORKSHEET.PRINT_BUTTON' | translate }}"
        >
          <div class="print-container">
            <mat-icon
              class="icon print-icon"
              svgIcon="print"
            ></mat-icon>
            <div>{{ 'INVENTORY_WORKSHEET.PRINT_BUTTON' | translate }}</div>
          </div>
        </a>

      </div>
      <mat-divider></mat-divider>
      <div class="worksheet-title-container"
          [ngStyle]="{'margin-bottom': !(useGroupOperations$ | async) ? '10px' : '0px' }">
        <ng-container *ngIf="isActiveInventory() else historicalWorksheetNotice" />

        <ng-container *ngIf="uiConfig.showRefreshWorksheetItemsButton">
          <div id="refresh-worksheet-items">
            <div>Click this button to correct worksheet items that are not properly displaying due to ERP migration.</div>
            <div>The scan may take a few minutes. The page will reload when complete.</div>
            <div>
              <button
                (click)="refreshWorksheetItems()"
                class="primary-destructive-button"
              >
                Scan Worksheet Items
              </button>
            </div>
          </div>
        </ng-container>

        <div class="worksheet-title-line">
          <div class="worksheet-name-container">
            <div
              [hidden]="isEditingName$ | async"
              id="worksheet-title"
              class="worksheet-title"
              (click)="editName()"
            >
              {{ translateName(worksheetModel.header.name) | truncated:20}}
            </div>
            <form
              [formGroup]="editWorksheetNameForm"
              [hidden]="!(isEditingName$ | async)"
            >
              <input
                id="edit-name-input"
                matInput
                #editNameInput
                type="text"
                (blur)="saveName()"
                class="inventory-name worksheet-title"
                formControlName="name"
                maxlength="200"
                autocomplete="off"
                placeholder=""
              >
            </form>
          </div>
          <div
            class="worksheet-name-container"
            *ngIf="!isOnline"
          >
            <div class="worksheet-title">
              {{ worksheetModel.header.name | truncated:20 }}
            </div>
          </div>
          <div class="search-container">
            <gfs-item-search-bar
              #searchBar
              [searchText]="'HEADER.SEARCH.PLACEHOLDER'"
              (enterSearch)="onExpandFilter($event)"
            >
            </gfs-item-search-bar>
          </div>
          <ng-container>
            <div class="tooltip">
            <button
              class="group-edit-btn"
              (click)="toggleEditMode($event)"
              [ngClass]="{'primary-button': toggleButtonState , 'secondary-button' : !toggleButtonState}"
              [disabled]="!isActiveInventory()"
            >
              {{ (toggleButtonState ? 'INVENTORY_WORKSHEET.EXIT_EDIT_MODE' : 'INVENTORY_WORKSHEET.ENABLE_EDIT_MODE') | translate }}
            </button>
            <div class="tooltiptext"
            *ngIf="!isActiveInventory()">
            {{ 'INVENTORY_WORKSHEET.DISABLED_BUTTON_TEXT' | translate }}
          </div>
          </div>
            <ng-container *ngIf="!isMobile">
                <button
                class="move-To-btn secondary-button"
                [matMenuTriggerFor]="MoveToMenu"
                *ngIf="useGroupOperations$ | async"
                [disabled]="!(storageAreasContainer?.checkedWorksheetItemsList.length)"
                >
                {{ 'INVENTORY_WORKSHEET.MOVE_TO' | translate }} <strong>></strong>
                </button>
              <div class="delete-btn-container">
              <button
                class="group-delete secondary-button"
                (click)="storageAreasContainer.onGroupDeleteClicked(this.worksheetModel?.worksheetId)"
                *ngIf="useGroupOperations$ | async"
                [disabled]="!(storageAreasContainer?.checkedWorksheetItemsList.length)"
                >
                {{ 'INVENTORY_WORKSHEET.REMOVE_SELECTED' | translate }}
              </button>
            </div>
          </ng-container>
          </ng-container>
          <div
            *ngIf="!isMobile && !(useGroupOperations$ | async)"
            class="worksheet-title-button-bar"
          >
            <button
              *ngxPermissionsOnly="permissionRoles.addItems"
              class="add-items-button secondary-button"
              [disabled]="!isOnline"
              [routerLink]="[
                { outlets: { 'modal': [ 'product','add' ] } }
              ]"
            >
              {{ 'INVENTORY_WORKSHEET.ADD_ITEMS' | translate }}
            </button>
          </div>

        </div>
        <div
        class="selected-item-count-container"
        *ngIf="(useGroupOperations$ | async)"
        >
          {{ storageAreasContainer?.checkedWorksheetItemsList?.length }} {{'INVENTORY_WORKSHEET.ITEMS_SELECTED' | translate }}
        </div>
        <ng-container *ngIf="canShowTotals() || isMobile">
        <div class="worksheet-sub-title">
          <div
                  class="title-sub-text"
                  id="worksheet-subtitle"
                  *ngIf="isMobile"
                >
                  <div>
                    {{ 'INVENTORY_WORKSHEET.CREATED' | translate }}:
                    <gfs-localized-datetime-value [value]="worksheetModel.header.createdDate" />
                  </div>
                  <div [hidden]="isSaving$ | async">
                    {{ 'INVENTORY_WORKSHEET.MODIFIED' | translate }}:
                    <gfs-localized-datetime-value [value]="worksheetModel.header.lastModifiedDate" />
                  </div>
                  <div [hidden]="!(isSaving$ | async)">
                    {{ 'INVENTORY_WORKSHEET.SAVING' | translate }}
                  </div>
                </div>
          <ng-container *ngxPermissionsOnly="pricingPermissions">
            <div
              *ngIf="!isMobile"
              class="total-value-text "
            >
                <div *ngIf="canShowTotals()">
                  {{ 'INVENTORY_WORKSHEET.TOTAL_VALUE' | translate }}:
                  <gfs-localized-currency-value class="xx-blur"
                  *ngIf="getValuation(worksheetModel.worksheetId) as valuation"
                  [class.blur-value]="isValueBlurred(valuation.status)"
                  [value]="valuation.value"
                  [placeholderText]="'—'"
                  />
                </div>
            </div>
          </ng-container>
        </div>
        </ng-container>
        <div *ngIf="isMobile" class="mobile-view-container">
          <ng-container *ngIf="!(useGroupOperations$ | async)">
            <div class="worksheet-title-button-bar-mobile" >
              <button *ngxPermissionsOnly="permissionRoles.addItems" class="add-items-button secondary-button"
                [disabled]="!isOnline" [routerLink]="[
                        { outlets: { 'modal': [ 'product','add' ] } }
                      ]">
                {{ 'INVENTORY_WORKSHEET.ADD_ITEMS' | translate }}
              </button>
            </div>
          </ng-container>
          <ng-container  *ngIf="useGroupOperations$ | async">
              <button
              class="move-To-btn secondary-button"
              [matMenuTriggerFor]="MoveToMenu"
              [disabled]="!(storageAreasContainer?.checkedWorksheetItemsList.length)"
              >
              {{ 'INVENTORY_WORKSHEET.MOVE_TO' | translate }} <strong>></strong>
              </button>
            <div class="delete-btn-container">
              <button
              class="group-delete secondary-button"
              (click)="storageAreasContainer.onGroupDeleteClicked(this.worksheetModel?.worksheetId)"
              [disabled]="!(storageAreasContainer?.checkedWorksheetItemsList.length)"
              >
              {{ 'INVENTORY_WORKSHEET.REMOVE_SELECTED' | translate }}
            </button>
            </div>
          </ng-container>
          <div class="total-value-text">
            <div *ngIf="canShowTotals()">
              <div class="total-value-label-mobile">
                {{ 'INVENTORY_WORKSHEET.TOTAL_VALUE' | translate }}:
              </div>
              <div class="total-value-amount-mobile">
                <gfs-localized-currency-value class="xx-blur" *ngIf="getValuation(worksheetModel.worksheetId) as valuation"
                  [class.blur-value]="isValueBlurred(valuation.status)" [value]="valuation.value" [placeholderText]="'—'" />
              </div>
            </div>
          </div>
        </div>
    </div>

      <div *ngIf="isMobile">
        <app-v2-storage-area-select
          [worksheetModel]="worksheetModel"
          (addStorageArea)="navToAddStorageArea(event$)"
          class="app-storage-area-select"
        />
      </div>
    </div>

    <div id="storage-area-main-container">
      <div *ngIf="isMobile">
        <app-storage-area-item-panel
          [productFilter]="filteredStorageAreaItemOrder"
          [worksheetModel]="worksheetModel"
          [productValuationStatus]="productValuationStatus"
          [storageAreaExpansionState]="storageAreaExpansionState"
          [invalidateStorageAreaValue]="invalidateStorageAreaValue"
          (productCountChange)="productCountIngress.next($event)"
        />
      </div>
      <mat-sidenav-container *ngIf="!isMobile">
        <mat-sidenav
          mode="side"
          opened
          class="storage-area-sidenav"
          [disableClose]="true"
        >
          <app-storage-area-list-v2
            [(worksheetModel)]="worksheetModel"
            (editStorageArea)="onEditStorageArea($event)"
            (storageAreaSelected)="storageAreasContainer.expandAndFocusStorageArea($event)"
          >
          </app-storage-area-list-v2>

          <button
            *ngxPermissionsOnly="permissionRoles.createStorageArea"
            id="new-storage-area-button"
            class="ghost-button"
            [disabled]="!isOnline"
            [routerLink]="[
              { outlets: { 'modal': [ 'storage-area','new' ] } }
            ]"
          >
            <mat-icon
              [hidden]="!isOnline"
              class="icon storage-area-plus-icon"
              svgIcon="plus-icon"
            ></mat-icon>
            <mat-icon
              [hidden]="isOnline"
              class="icon storage-area-plus-icon"
              svgIcon="plus-icon-gray"
            >
            </mat-icon>
            <div class='new-storage-area-label'>{{ 'STORAGE_AREA.NEW_STORAGE_AREA' | translate }}</div>
          </button>
        </mat-sidenav>
        <mat-sidenav-content
          id="mat-sidenav-content"
          #storageAreaMainContainer
          [ngStyle]="{'padding-right': scrollWidth || '32px'}"
        >
          <div class="storage-area-sidenav-content">
            <app-storage-area-item-panel
              [productFilter]="filteredStorageAreaItemOrder"
              [worksheetModel]="worksheetModel"
              [productValuationStatus]="productValuationStatus"
              [storageAreaExpansionState]="storageAreaExpansionState"
              [invalidateStorageAreaValue]="invalidateStorageAreaValue"
              (productCountChange)="productCountIngress.next($event)"
            >
            </app-storage-area-item-panel>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>

    <gfs-loading-overlay-end />

  </main>
  <mat-menu #MoveToMenu="matMenu">
    <div class="matMenu-btn-container">
    <ng-container *ngFor="let storageAreaTargetId of worksheetModel?.storageAreaOrder">
      <ng-container
        *ngIf="worksheetModel.storageAreas[storageAreaTargetId] as storageAreaTarget"
      >
        <button
           class="mat-menu-options"
          *ngIf="storageAreaTargetId !== currentStorageAreaId"
          id="item-menu-move-to-sa-{{storageAreaTargetId}}"
          (click)="storageAreasContainer.groupMoveToStorageAreaClicked(worksheetModel.worksheetId,storageAreaTargetId)"
          mat-menu-item
        >
          <gfs-localized-text-value [value]="storageAreaTarget.name" />
        </button>
        <mat-divider class="mat-menu-divider"></mat-divider>
      </ng-container>
    </ng-container>
  </div>
  </mat-menu>

  <ng-template #historicalWorksheetNotice>
    <div class="worksheet-historic-banner">
      <div>
        <mat-icon
          [inline]="true"
          svgIcon="info_icon_black"
        ></mat-icon>
      </div>
      <div class="historic-banner-text">
        <div class="historic-banner-header">
          {{ 'INVENTORY_WORKSHEET.HISTORICAL_BANNER_HEADING' | translate }}
        </div>
        <div class="historic-banner-body">
          {{ 'INVENTORY_WORKSHEET.HISTORICAL_BANNER_BODY' | translate }}
        </div>
      </div>
    </div>
  </ng-template>

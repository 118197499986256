<a class="cart-container"
  *ngIf="(hasNaooRoles$ | async) && (cart$ | async)"
  [ngClass]="{disabled: disabled, 'wide-layout': wideLayout}"
  role="link"
  tabindex="0"
  [attr.aria-label]="'CART.TITLE' | translate"
  [href]="naooUrl"
>
  <div class="cart-icon-badge">
    <mat-icon class="cart-icon-header" role="img" svgIcon="cart_icon"></mat-icon>
    <div *ngIf="!wideLayout" class="product-count-badge">
      <div class="item-count"> {{(cart$ | async)?.totalQuantity || 0}} </div>
    </div>
  </div>
  <div *ngIf="!(isMobile$ | async) || wideLayout" class="text-container">
    <div class="text">
      <div class="view-order" [title]="'CART.VIEW_ORDER' | translate">
        {{ 'CART.VIEW_ORDER' | translate }}
      </div>
      <div class="cart-total"><span *ngIf="wideLayout">: </span>${{(cart$ | async)?.totalPrice || '0.00'}}</div>
      <div *ngIf="wideLayout" class="cart-icon-badge">
        <div class="product-count-badge">
          <div class="item-count"> {{(cart$ | async)?.totalQuantity || 0}} </div>
        </div>
      </div>
    </div>
  </div>
</a>

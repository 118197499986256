import {HttpErrorResponse} from '@angular/common/http';
import {ItemCategory} from '@gfs/shared-models';
import {Action} from '@ngrx/store';

import {

  GeneraLedgerAccountPatch,
  GeneralLedgerAccount,
  GeneralLedgerAccountPage,
  ItemCategoryGLAccount,
  UpdateItemCategoryGLAccountInput,
  GeneralLedgerAccountDelete,
} from '../models/general-ledger.model';
import {GeneralLedgerAccountInput} from '../models/general-ledger-account-input.model';

export enum ActionTypes {

  LoadGeneralLedger = '[General Ledger] Load General Ledger',
  LoadGeneralLedgerCompleted = '[General Ledger] Get Accounts Complete',
  AddAccount = '[General Ledger] Add Account',
  AddAccountCompleted = '[General Ledger] Add Account Completed',
  UpdateItemCategoryAccount = '[General Ledger] Update Item Category Account',
  UpdateItemCategoryAccountCompleted = '[General Ledger] Update Item Category Account Completed',
  AddItemCategoryToAccount = '[General Ledger] Add Item Category To Account',
  AddItemCategoryToAccountCompleted = '[General Ledger] Add Item Category To Account Completed',
  RemoveItemCategoryFromAccount = '[General Ledger] Remove Item Category To  Account',
  RemoveItemCategoryFromAccountCompleted = '[General Ledger] Remove Item Category To  Account Completed',
  ActionFailed = '[General Ledger] Get Accounts Failed',
  UpdateAccountPopUp = '[General Ledger] update Account ',
  UpdateAccountCompleted ='[General Ledger] update account completed',
  DeleteGeneralLedger = '[General Ledger] Delete General Ledger Account',
  DeleteGeneralLedgerCompleted = '[General Ledger] Delete General Ledger Account Completed'

}

export class LoadGeneralLedger implements Action {
  readonly type = ActionTypes.LoadGeneralLedger;
}

export class LoadGeneralLedgerCompleted implements Action {
  readonly type = ActionTypes.LoadGeneralLedgerCompleted;

  constructor(public payload: { accountsDto: GeneralLedgerAccountPage, itemCategories: ItemCategory[] }) {
  }
}

export class AddAccount implements Action {
  readonly type = ActionTypes.AddAccount;

  constructor(public model: GeneralLedgerAccountInput) {
  }
}

export class UpdateAccountPopUp implements Action {
  readonly type = ActionTypes.UpdateAccountPopUp;

  constructor(public model: GeneraLedgerAccountPatch) {
  }
}

export class AddAccountCompleted implements Action {
  readonly type = ActionTypes.AddAccountCompleted;

  constructor(public model: GeneralLedgerAccount) {
  }
}
export class UpdateAccountCompleted implements Action {
  readonly type = ActionTypes.UpdateAccountCompleted;

  constructor(public model: GeneraLedgerAccountPatch) {
  }
}

export class UpdateItemCategoryAccount implements Action {
  readonly type = ActionTypes.UpdateItemCategoryAccount;

  constructor(public payload: UpdateItemCategoryGLAccountInput) {
  }
}

export class DeleteGeneralLedger implements Action {
  readonly type = ActionTypes.DeleteGeneralLedger;
  constructor(public model: GeneralLedgerAccountDelete) { }
}
export class DeleteGeneralLedgerCompleted implements Action {
  readonly type = ActionTypes.DeleteGeneralLedgerCompleted;
  constructor(public model: GeneralLedgerAccount) { }
}

export class UpdateItemCategoryAccountCompleted implements Action {
  readonly type = ActionTypes.UpdateItemCategoryAccountCompleted;
}

export class AddItemCategoryToAccount implements Action {
  readonly type = ActionTypes.AddItemCategoryToAccount;

  constructor(public payload: { generalLedgerAccountId: string, itemCategoryId: string }) {
  }
}

export class AddItemCategoryToAccountCompleted implements Action {
  readonly type = ActionTypes.AddItemCategoryToAccountCompleted;

  constructor(public model: ItemCategoryGLAccount) {
  }
}

export class RemoveItemCategoryFromAccount implements Action {
  readonly type = ActionTypes.RemoveItemCategoryFromAccount;

  constructor(public payload: { generalLedgerAccountId: string, itemCategoryId: string }) {
  }
}

export class RemoveItemCategoryFromAccountCompleted implements Action {
  readonly type = ActionTypes.RemoveItemCategoryFromAccountCompleted;

  constructor(public model: ItemCategoryGLAccount) {
  }
}

export class ActionFailed implements Action {
  readonly type = ActionTypes.ActionFailed;

  constructor(public error: HttpErrorResponse) {
  }
}
//export const closePopup = createAction('[Popup] Close Popup');


export type ActionUnion =
  LoadGeneralLedger |
  LoadGeneralLedgerCompleted |
  AddAccount |
  UpdateAccountPopUp |
  UpdateAccountCompleted|
  AddAccountCompleted |
  UpdateItemCategoryAccount |
  UpdateItemCategoryAccountCompleted |
  AddItemCategoryToAccount |
  AddItemCategoryToAccountCompleted |
  RemoveItemCategoryFromAccount |
  RemoveItemCategoryFromAccountCompleted |
  DeleteGeneralLedger |
  DeleteGeneralLedgerCompleted |
  ActionFailed;



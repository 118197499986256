<ng-container *ngIf='viewModel'>
    <form [formGroup]='form' (ngSubmit)='onSubmit()'>
        <div class="modal-title">{{ 'GENERAL_LEDGER.EDIT_GENERAL_LEDGER_MODAL.EDIT_GENERAL_LEDGER_TITLE' | translate }}</div>
      <mat-dialog-content>
        <mat-form-field>
          <input
            formControlName="glCode"
            placeholder="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_CODE' | translate }}"
            type="text" matTooltip="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_CODE' | translate }}"
            matInput>
          <mat-error
            *ngIf="form.get('glCode').hasError('invalidEntityNameValidator')">{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DUPLICATE_CODE' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            formControlName="description"
            placeholder="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_DESCRIPTION' | translate }}"
            type="text" matTooltip="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_DESCRIPTION' | translate }}"
            matInput>
          <mat-error
            *ngIf="form.get('description').hasError('invalidEntityNameValidator')">{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DUPLICATE_DESCRIPTION' | translate }}</mat-error>
        </mat-form-field>
      </mat-dialog-content>
        <div class="modal-button-bar">
          <button id="cancel-button"
                  class="secondary-button large-button"
                  type="button"
                  (click)="onCancel()"
                  attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
            {{ 'MODALS.CANCEL' | translate }}
          </button>
          <button
            class="primary-button large-button"
            [disabled]="isFormDisabled(form)"
            type="onSubmit">
            {{ 'GENERAL_LEDGER.EDIT_GENERAL_LEDGER_MODAL.EDIT_GENERAL_LEDGER_BUTTON' | translate }}
          </button>
        </div>
    </form>
    <button class="close-button" (click)="onCancel()" attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
     <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"/>
    </button>
</ng-container>



import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { CountableItem, CustomItem, CustomItemData, CustomItemUpdate, Supplier } from '@gfs/shared-models';
import { AppState } from '@gfs/store/inventory/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, takeUntil, tap, withLatestFrom,first} from 'rxjs/operators';
import { ConfirmationModalComponent, CustomItemCardComponent } from '@gfs/shared-components';
import { ItemTextSearchService } from '@gfs/shared-services';
import _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { DeleteCustomItemAttempt, DeleteGroupCustomItemAttempt, DeleteGroupLocalCustomItem, DeleteLocalCustomItem, GetAllCustomItemsAttempt, GetAllGeneralItemsAttempt } from '@gfs/store/inventory/actions/customerItems.actions';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import {CustomerItemsEffects} from 'libs/shared-services/src/inventory/store/effects/customerItems.effects'
interface SupplierCustomItems {
  supplier: Supplier;
  ordinal: number;
  itemCount: number;
  customItems: CustomItem[];
  idAttribute: string;
  isExpanded: boolean;
}

interface ViewModel {
  supplierCustomItems: SupplierCustomItems[];
}

interface CountableItemMap {
  [id: string]: CountableItem;
}

@Component({
  selector: 'gfs-supplier-item-panel',
  templateUrl: './supplier-item-panel.component.html',
  styleUrls: ['./supplier-item-panel.component.scss']
})
export class SupplierItemPanelComponent implements OnInit, OnDestroy  {
  constructor(
    private searchService: ItemTextSearchService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public overlay:LoadingSpinnerOverlayService
  ) { this.supplierItemPaging = {} }
  

  @Input() suppliers$: Observable<Supplier[]>;
  @Input() customItems$: Observable<CustomItem[]>;
  checkboxSelectedItems = [];

  viewModel = new BehaviorSubject<ViewModel>(null);

  suppliers: Supplier[] = [];

  notifier$ = new Subject<void>();
  supplierItemPaging: { [s: string]: number };

  customItemDataMap$: Observable<{ [itemId: string]: CustomItemData; }> = this.store
    .select(state => state.worksheets.customItemData)
    .pipe(
      filter(data => !!data),
      map(data => data
        .reduce((acc, next) => {
          if (next.itemType === 'CUSTOM') {
            acc[next.itemId] = next;
          }
          return acc;
        }, {}))
    );

  panelClickSubject = new Subject<string>();
  panelClickAction = this.panelClickSubject.pipe(
    withLatestFrom(this.viewModel),
    map(([supplierId, viewModel]: [string, ViewModel]) => {
      const index = viewModel.supplierCustomItems.findIndex(a => a.supplier.id === supplierId);
      const supplier = viewModel.supplierCustomItems[index];
      supplier.isExpanded = !supplier.isExpanded;
      viewModel.supplierCustomItems[index] = supplier;
      return viewModel;
    }),
    tap(viewModel => this.viewModel.next(viewModel))
  );

  countableItems$: BehaviorSubject<CountableItemMap> = new BehaviorSubject<CountableItemMap>(null);

  @ViewChildren('customItemCard')
  customItemCards: QueryList<CustomItemCardComponent>;

  filterItemsBySearch = (customItems: CustomItem[], searchText: string, countableItems: CountableItemMap) => {
    return customItems.filter((item: CustomItem) => this.searchService.workItemTextSearch(
      searchText ?? '',
      null,
      item.supplierItemCode ?? '',
      countableItems[item.id]
    ));
  };

  groupItemsBySupplier = (customItems: CustomItem[]) =>
    _(customItems)
      .groupBy((item: CustomItem) => item.supplierId)
      .map((items, supplierId) => ({ supplierId, items }))
      .reduce((acc: { [supplierId: string]: CustomItem[]; }, curr) => ({ ...acc, [curr.supplierId]: curr.items }), {});

  buildViewModel = (customItemsBySupplier: { [x: string]: CustomItem[] }, suppliers: Supplier[], viewModel: ViewModel): ViewModel => {
    const supplierCustomItems: SupplierCustomItems[] = suppliers.map(supplier => {
      const items = customItemsBySupplier[supplier.id] ?? [];
      const sortedItems = [...items].sort((curr, next) => curr.description.localeCompare(next.description));

      return {
        supplier,
        ordinal: supplier.ordinal,
        itemCount: sortedItems.length,
        customItems: sortedItems,
        idAttribute: `supplier-item-panel-${supplier.name.replace(' ', '-')}-${supplier.id}`,

        // Supplier panels are collaped by default
        isExpanded: !!viewModel?.supplierCustomItems?.find(item => item.supplier.id === supplier.id)?.isExpanded
      };
    });

    return { supplierCustomItems };
  };

  ngOnInit(): void {
    const countableItemsBuilder = combineLatest([
      this.store.select(state => state.customerItems.generalItems),
      this.customItems$,
      this.customItemDataMap$])
      .pipe(
        takeUntil(this.notifier$),
        filter(([generalItems, customItems, customItemData]) => !!generalItems && !!customItems),
        map(([generalItems, customItems, customItemData]) => {
          const customItemMap = customItems.reduce((acc, next) => {
            acc[next.id] = next;
            return acc;
          }, {});

          return generalItems.reduce((acc, next) => {
            if (!next.deleted) {
              next.productList.forEach(product => {
                if (product.type === 'CUSTOM') {
                  const customItem = customItemMap[product.id];
                  if (customItem && !customItem.deleted) {
                    const countableItem = {
                      generalItem: next,
                      customItem,
                      customItemData: customItemData[product.id],
                      gfsItem: null,
                      recipeItem: null
                    };
                    acc[product.id] = countableItem;
                  }
                }
              });
            }
            return acc;
          }, {});
        }),
        tap(countableItems => this.countableItems$.next(countableItems))
      );
    countableItemsBuilder.subscribe();

    const viewModelBuilder = combineLatest([
      this.customItems$,
      this.store.select(state => state.worksheets.itemFilterText),
      this.countableItems$.pipe(filter(countableItems => !!countableItems)),
      this.suppliers$ // Necessary to include this.suppliers$ in combineLatest because it triggers emission from parent component
    ]).pipe(
      takeUntil(this.notifier$),
      map(([customItems, searchText, countableItems, suppliers]) => ({
        customItems: this.filterItemsBySearch(customItems, searchText, countableItems),
        suppliers
      })),
      map(({ customItems, suppliers }) => ({
        customItemsBySupplier: this.groupItemsBySupplier(customItems),
        suppliers
      })),
      tap(({ suppliers }) => this.suppliers = suppliers),
      withLatestFrom(this.viewModel),
      map(([{ customItemsBySupplier, suppliers }, viewModel]) => this.buildViewModel(customItemsBySupplier, suppliers, viewModel)),
      tap(nextViewModel => this.viewModel.next(nextViewModel))
    );
    viewModelBuilder.subscribe();
    CustomerItemsEffects.customItemsDeleted$.subscribe((data)=>{
      if(data){
        this.overlay.hide()
      }
    })
  }

  ngOnDestroy(): void {
    this.notifier$.next();
  }

  onLastPriceEnter(supplierId: string) {
    const index = this.suppliers.findIndex(sup => sup.id === supplierId);
    if (index >= 0 && (index + 1) < this.suppliers.length) {
      const cards = this.customItemCards.toArray();
      cards[index + 1].selectFirstPrice(this.suppliers[index + 1].id);
    }
  }
  getCurrentPageBysupplierItemId(
    customItemID: string,
    offsetToAdd: number = 0
  ) {
    return this.supplierItemPaging[customItemID]
      ? this.supplierItemPaging[customItemID] + offsetToAdd
      : 0 + offsetToAdd;
      
  }
  onPageClick(event: PageEvent, customItemSupplierID?: string) {
      this.supplierItemPaging[customItemSupplierID] = event.pageIndex * event.pageSize;
  }

  checkboxSelected(event){
    if(event.checked){
      const index = this.checkboxSelectedItems.findIndex(element => element.id === event.source.value.id)
      if(index == -1 ){
        this.checkboxSelectedItems.push(event.source.value)
      }
    } else {
      const indexInArray = this.checkboxSelectedItems.findIndex(element => element.id === event.source.value.id)
      if(indexInArray > -1){
        this.checkboxSelectedItems.splice(indexInArray,1)
      }
    }
  }

  onGroupDeleteClicked() {
    this.countableItems$.pipe(first()).subscribe(countableItems => {
      const itemsArray = [];
      this.checkboxSelectedItems.forEach((item)=>{
        itemsArray.push(countableItems[item.id])
      })
      const submitButtonAriaLabel = this.translate.instant('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DELETE');
      const submitButtonLabel = this.translate.instant('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DELETE');
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        data: {
          returnData: itemsArray,
          type:'GROUP_DELETE',
          title: 'STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_MODAL.REMOVE_STORAGE_AREA_ITEM_DIALOG_TITLE',
          titleData: { itemName: `${this.checkboxSelectedItems.length}`},
          cancelButtonId: 'cancel-delete-item-button',
          submitButtonId: 'delete-item-button',
          submitButtonAriaLabel,
          submitButtonLabel
        },
        width: '608px',
        height: '270px'
      });

      dialogRef.afterClosed().subscribe(itemToDelete => {
        if (itemToDelete) {
          this.store.dispatch(new DeleteGroupLocalCustomItem({
            customItemIds: itemToDelete.map((item)=>item.customItem.id),
            generalItemIds: itemToDelete.map((item)=>item.generalItem.id)
          }));

          const snackref = this.snackBar.open(`${this.checkboxSelectedItems.length} items ` +
            this.translate.instant('STORAGE_AREA.DELETE_MANAGE_AREA_ITEM_DELETED_SUCCESSFULLY'),
            this.translate.instant('UNDO'), { duration: 4000 });
          let undo = false;
          snackref.afterDismissed().subscribe(() => {
            this.processGroupDelete(undo, itemsArray);
          });
          snackref.onAction().subscribe(() => {
            undo = true;
          });
        }
      });
    });
  }

  processGroupDelete(undo: boolean, item: CountableItem[]) {
    if (undo) {
      this.store.dispatch(new GetAllCustomItemsAttempt());
      this.store.dispatch(new GetAllGeneralItemsAttempt());
    } else {
      this.overlay.show();
      this.store.dispatch(new DeleteGroupCustomItemAttempt({
        customItemIds: item.map((customIds)=>customIds.customItem),
        generalItemIds: item.map((generalIds)=>generalIds.generalItem)
      }));
    }
    this.checkboxSelectedItems = [];
  }
}


import {
  CountableItem,
  MeasurementUnit,
  CustomItemData,
  GfsItem,
  Supplier,
  ItemCategory,
  SupplierOrder,
} from '@gfs/shared-models';

export const FeatureKey = 'addItemsFeature';

export enum SearchTabTypes {
  Catalogue = 'CATALOGUE',
  OrderGuideItems = 'ORDER_GUIDE',
  NonGfsItems = 'NONGFS_ITEMS',
  RecipeItems = 'RECIPES_ITEMS',
}

export interface IFeatureStateFacade {
  [FeatureKey]: FeatureState;
}
export interface FeatureState {
  autocompleteResults: any[];
  countableItems: CountableItem[];
  lastCountableItemsSearch: {
    searchText: string;
    searchType: SearchTabTypes;
    locale: string;
    countableItems: CountableItem[];
  };
  suppliers: Supplier[];
  suppliers_loading: boolean | null;
  supplierOrder: SupplierOrder;
  itemCategories: any[];
  itemCategories_isLoading: boolean | null;
  itemCategories_untranslated: ItemCategory[] | null;
  customItem: CountableItem;
  isAddingCustomItem: boolean;
  measurementUnits: MeasurementUnit[];
  measurementUnits_isLoading: boolean | null;
  searching: boolean;
  editItemProductInfo: GfsItem;
  customItemData: CustomItemData[];
  customItemData_isLoading: boolean;
  error: string;
  isSaving: boolean;
}

export const initialState: FeatureState = {
  autocompleteResults: [],
  countableItems: [],
  lastCountableItemsSearch: {
    searchText: '',
    searchType: SearchTabTypes.Catalogue,
    locale: '',
    countableItems: [],
  },
  suppliers: null,
  suppliers_loading: false,
  supplierOrder: null,
  itemCategories: null,
  itemCategories_isLoading: false,
  itemCategories_untranslated: null,
  customItemData: null,
  customItemData_isLoading: false,
  customItem: null,
  isAddingCustomItem: false,
  measurementUnits: null,
  measurementUnits_isLoading: false,
  searching: false,
  editItemProductInfo: null,
  error: null,
  isSaving: false,
};

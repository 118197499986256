<div class="modal-container">
  <div class="mat-dialog-header modal-header">
    <div class="edit-modal-title">
      <button *ngIf="!isEdit" mat-icon-button (click)="goBack()" class="back-arrow-button">
        <img class="back-icon" src="assets/images/back-arrow.svg" alt="{{ 'MODALS.BACK' | translate }}" />
      </button>
      <span *ngIf="!isEdit">{{'ADD_ITEMS.ADD_ITEM' | translate}}</span>
      <span *ngIf="isEdit">{{'EDIT_ITEMS.EDIT_ITEM_TITLE' | translate}}</span>
      <button *ngIf="!isEdit"
        mat-icon-button
        class="edit-close-button"
        (click)="onClose()"
        attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
        <img class="edit-close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE' | translate }}" />
      </button>
      <button *ngIf="isEdit"
        mat-icon-button
        class="edit-close-button"
        (click)="cancel()"
        attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
        <img class="edit-close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE' | translate }}" />
      </button>
    </div>
  </div>
  <div mat-dialog-content class="edit-item-modal">
    <mat-expansion-panel class="edit-item-panel item-info-panel" [expanded]="itemInfoExpanded">
      <mat-expansion-panel-header
        class="edit-item-panel-header item-background"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'"
        (click)="itemInfoExpanded = !itemInfoExpanded">
        <mat-panel-title>
          <div class="item-info-title">
            {{'ADD_ITEMS.ITEM_INFO' | translate | uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <gfs-add-item-card [item]="countableItem" [isEditItem]="true"></gfs-add-item-card>
      <div class="form-wrapper">
        <form class="add-item-form" [formGroup]="addItemForm">
          <mat-form-field class="add-item-form-field" *ngIf="isAdd">
            <mat-label>{{'ADD_ITEMS.STORAGE_AREA' | translate }}</mat-label>
            <gfs-add-element-autocomplete
              (optionSelected)="onStorageAreaSelected($event.option.value)"
              fieldName="storage-area"
              [placeholder]="'ADD_ITEMS.STORAGE_AREA' | translate"
              formControlName="storageArea"
              optionsValue="name"
              [addElementLabel]="'ADD_ITEMS.CUSTOM_STORAGE_AREA' | translate"
              [options]="filteredStorageAreas$ | async"
              (addElement)="createStorageArea($event)"
            ></gfs-add-element-autocomplete>
          </mat-form-field>
          <mat-form-field class="add-item-form-field">
            <mat-label>{{'ADD_ITEMS.CATEGORY' | translate }}</mat-label>
            <gfs-add-element-autocomplete
              fieldName="category"
              [placeholder]="'ADD_ITEMS.CATEGORY' | translate"
              formControlName="category"
              optionsValue="name"
              [addElementLabel]="'ADD_ITEMS.CUSTOM_CATEGORY' | translate"
              [options]="filteredCategories$ | async"
              (addElement)="createCategory($event)"
            ></gfs-add-element-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="edit-item-panel"
      [expanded]="countingUnitExpanded"
      [hidden]="!appSettings.FF_CUSTOM_COUNT_UNITS"
    >
      <mat-expansion-panel-header
        class="edit-item-panel-header counting-unit-panel-header item-background"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'"
        (click)="countingUnitExpanded = !countingUnitExpanded"
        [ngStyle]="{'margin-bottom': countingUnitExpanded ? '16px' : '0'}"
        >
        <mat-panel-title>
          <div class="item-info-title">
            {{'ADD_ITEMS.INVENTORY_INFORMATION' | translate | uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <gfs-counting-unit-form
        [isEdit]="isEdit"
        [item]="countableItem"
        (addOrEditItem)="isEdit ? onEditItem($event) : onAddItem($event)"
        (purchasedByUnitCheck)="updatePurchaseUnit($event)"
        [isgfsItem]= "true"
        #countingUnitFormComponent
      ></gfs-counting-unit-form>
    </mat-expansion-panel>
    <mat-expansion-panel class="edit-item-panel unit-of-measure-panel" [expanded]="unitOfMeasureExpanded">
      <mat-expansion-panel-header
        class="edit-item-panel-header"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'"
        (click)="unitOfMeasureExpanded = !unitOfMeasureExpanded"
        [ngStyle]="{'margin-bottom': unitOfMeasureExpanded ? '0' : '24px'}">
        <mat-panel-title>
          <div class="item-info-title">
            {{'ADD_ITEMS.RECIPE_INFORMATION' | translate | uppercase }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <gfs-unit-of-measure-form
        [measurementUnitForm]="measurementUnitForm"
        [customItemData$]="customItemData$"
        (weightUnitBlur)="onCatchWeightUnitBlur($event)"
        (netWeightBlur)="onNetWeightBlur()"
      ></gfs-unit-of-measure-form>
    </mat-expansion-panel>
  </div>
  <div class="modal-button-bar" [ngStyle]="{'margin-bottom': isIos ? '120px' : '0px' }">
    <button *ngIf="!isEdit"
      class="secondary-button large-button"
      type="button"
      (click)="goBack()"
      attr.aria-label="{{ 'MODALS.BACK' | translate}}">
      {{ 'MODALS.BACK' | translate }}
    </button>
    <button *ngIf="isEdit"
      class="secondary-button large-button"
      type="button"
      (click)="cancel()"
      attr.aria-label="{{ 'MODALS.BACK' | translate}}">
      {{ 'Cancel' | translate }}
    </button>
    <button *ngIf="!isEdit"
      class="primary-button large-button submit-add-item"
      type="button"
      (click)="countingUnitFormComponent.createOrUpdateCustomUnits(isEdit)"
      attr.aria-label="{{ 'ADD_ITEMS.ADD_ITEM' | translate}}"
      [disabled]="isAddDisabled() | async">
      {{ 'ADD_ITEMS.ADD_ITEM' | translate }}
    </button>
    <button *ngIf="isEdit"
      class="primary-button large-button submit-add-item"
      type="button"
      (click)="countingUnitFormComponent.createOrUpdateCustomUnits(isEdit)"
      attr.aria-label="{{ 'MODALS.SAVE' | translate}}"
      [disabled]="isEditDisabled() | async">
      {{ 'MODALS.SAVE' | translate }}
    </button>
  </div>
</div>
<gfs-loading-overlay-end/>
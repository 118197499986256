<div>
  <div *ngIf="!(isMobile$ | async)" class="custom-item-card custom-item-card-dark">
    <mat-grid-list class="row-header-content" cols="20" rowHeight="32px" gutterSize="0px">
      <mat-grid-tile class="mat-grid-tile-align-left" colspan="7">
        {{'STORAGE_AREA.STORAGE_AREA_CARD.ITEM' | translate | uppercase}}
      </mat-grid-tile>
      <mat-grid-tile class="mat-grid-tile-align-left" colspan="6">
        {{'ADD_ITEMS.PURCHASE_UNIT' | translate | uppercase}}
      </mat-grid-tile>
      <mat-grid-tile *ngxPermissionsOnly="pricingPermissions" class="mat-grid-tile-align-left" colspan="6">
        {{'MANAGE_NONGFS_ITEMS.PURCHASE_PRICE' | translate | uppercase}}</mat-grid-tile>
        <mat-grid-tile *ngIf="(useManageNonGfsGroupOperations$ | async)" class="mat-grid-tile-align-left" colspan="1">
          SELECT </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div *ngFor="let item of getCustomItems(); let itemIndex = index" class="custom-item-card">
    <mat-grid-list
      [id]="'custom-item-' + item.id"
      [cols]="(isMobile$ | async) ? 6 : 20"
      [rowHeight]="(isMobile$ | async) ? '66px' : '66px'"
      gutterSize="0px"
    >
      <mat-grid-tile
        [colspan]="(isMobile$ | async) ? 5 : 7"
        class="mat-grid-tile-align-left"
      >
      <div class="item-tile-container">
        <div class="item-title" [id]="'custom-item-title-' + item.id">
          {{ item.description }}
        </div>
        <div *ngIf="item.supplierItemCode"  class="item-details" [id]="'custom-item-details-' + item.id">
          #{{ item.supplierItemCode }}
        </div>
      </div>
      </mat-grid-tile>
      <mat-grid-tile
        *ngIf="(isMobile$ | async) && !(useManageNonGfsGroupOperations$ | async)"
        colspan="1"
        class="mat-grid-tile-align-left"
      >
        <div class="triple-dot-wrapper" *ngxPermissionsOnly="tripleDotPermissions">
          <button mat-icon-button [matMenuTriggerFor]="itemMenu" attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU_ARIA_LABEL' | translate }}" >
            <mat-icon svgIcon="triple-dot-menu-sideways"></mat-icon>
          </button>
        </div>
      </mat-grid-tile>
      <mat-grid-tile 
      *ngIf="(isMobile$ | async) && (useManageNonGfsGroupOperations$ | async)"
        colspan="1"
        class="mat-grid-tile-align-left">
      <mat-checkbox 
      class="item-checkbox"
      [value]="item"
      (change)="itemSelectedForBulkOperation($event)"
      [disabled]="!(isAllowedToEdit(item) | async)"
      >
      </mat-checkbox>
    </mat-grid-tile>
      <mat-grid-tile
        [colspan]="(isMobile$ | async) ? 5 : 6"
        class="mat-grid-tile-align-left"
      >
        <div class="item-tile-container">
          <div *ngIf="(isMobile$ | async)" class="media-content-header">
            {{'ADD_ITEMS.PURCHASE_UNIT' | translate | uppercase}}
          </div>
          <div class="purchase-unit-wrapper">
            <div class="item-purchase-unit">
              {{ displayPurchaseUnit(item, (currentLang$ | async)) | async }}
            </div>
            <div class="item-price-per-weight">
              {{ displayPricePerWeight(item, (currentLang$ | async)) | async }}
            </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile
        [colspan]="3"
        class="mat-grid-tile-align-left"
      >
        <div class="item-tile-container">
          <div *ngIf="(isMobile$ | async)" class="media-content-header">
            {{ 'MANAGE_NONGFS_ITEMS.PURCHASE_PRICE' | translate | uppercase}}
          </div>
            <div class="item-price-input-wrapper">
              <span
                [ngClass]="{'currency-symbol': true, 'right-side-symbol': (currentLang$ | async) === 'fr_CA'}">
                $
              </span>
              <div>
                <input
                  matInput
                  [id]="'item-price-input-' + item.id"
                  class="gfs-numeric-input item-price-input"
                  appTwoDigitDecimalNumber
                  [regex]="priceInputRegex.INPUT_REGEX"
                  type="text"
                  autocomplete="off"
                  #priceInput="ngModel"
                  [ngStyle]="{'padding': (currentLang$ | async) === 'fr_CA' ? '0 20px 0 7px' : '0 7px 0 20px'}"
                  [ngModel]="formatPrice(item.purchaseUnit.custom.price, (currentLang$ | async))"
                  (ngModelChange)="item.purchaseUnit.custom.price = convertPriceToNumber($event)"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  (blur)="saveUpdatedPrice(item)"
                  [pattern]="priceInputRegex.VALID_CURRENCY_REGEX"
                  [disabled]="!(isOnline$ | async) || !(isAllowedToEdit(item) | async)"
                  (keyup.enter)="moveToNextItemPrice(itemIndex)"
                  (click)="selectFullPrice(itemIndex)"
                >
                <mat-error
                  *ngIf="(isMobile$ | async) && priceInput.invalid && priceInput.touched"
                  class="price-error">
                  {{'ADD_ITEMS.INVALID_PRICE_FORMAT' | translate }}
                </mat-error>
              </div>
              <mat-error
                *ngIf="!(isMobile$ | async) && priceInput.invalid && priceInput.touched"
                class="price-error">
                {{'ADD_ITEMS.INVALID_PRICE_FORMAT' | translate }}
              </mat-error>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="3" class="mat-grid-tile-align-left">
        <div class="item-tile-container item-tag-right-align">
          <div class="item-tag-wrapper">
            <div *ngIf="countableItems$ | async as resolvedCountableItems">
              <div *ngIf="isOnWorksheet(resolvedCountableItems[item.id]) | async"
                class="item-tag on-worksheet-tag">
              <span class="item-tag-text">{{'MANAGE_NONGFS_ITEMS.ON_WORKSHEET' | translate }}</span>
              </div>
            </div>
            <div *ngIf="!(isAllowedToEdit(item) | async)" class="item-tag chain-item-tag">
              <span class="item-tag-text">{{'MANAGE_NONGFS_ITEMS.CHAIN_ITEM' | translate }}</span>
            </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile
        *ngIf="!(isMobile$ | async) && !(useManageNonGfsGroupOperations$ | async)"
        colspan="1"
        class="mat-grid-tile-align-left"
      >
        <div class="triple-dot-wrapper" *ngxPermissionsOnly="tripleDotPermissions">
          <button mat-icon-button [matMenuTriggerFor]="itemMenu" attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU_ARIA_LABEL' | translate }}" >
            <mat-icon svgIcon="triple-dot-menu"></mat-icon>
          </button>
        </div>
      </mat-grid-tile>
      <mat-grid-tile 
      *ngIf="!(isMobile$ | async) && (useManageNonGfsGroupOperations$ | async)"
        colspan="1"
        class="mat-grid-tile-align-left"
      matTooltip="{{'MANAGE_NONGFS_ITEMS.CHAIN_ITEM_ERROR' | translate }}"
      matTooltipPosition="after"
      matTooltipClass="select-all-custom-tooltip"
      [matTooltipDisabled]="(isAllowedToEdit(item) | async)"
        >
      <mat-checkbox 
      class="item-checkbox"
      [value]="item"
      (change)="itemSelectedForBulkOperation($event)"
      [disabled]="!(isAllowedToEdit(item) | async)"
      [checked]="checkboxSelectedItemsFromPanel.includes(item)"
      >
      </mat-checkbox>
    </mat-grid-tile>
    </mat-grid-list>
    <mat-menu #itemMenu>
      <button
        (click)="onEditClicked(item.id)"
        [disabled]="!(isOnline$ | async)" id="custom-item-menu-edit" mat-menu-item
      >
        {{ (isAllowedToEdit(item) | async) ?
          ('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.EDIT' | translate) :
          ('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.VIEW' | translate)
        }}
      </button>
      <button
        (click)="onDuplicateClicked(item.id)"
        [disabled]="!(isOnline$ | async)"
        id="custom-item-menu-duplicate"
        mat-menu-item>
        {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DUPLICATE' | translate }}
      </button>
      <button
        *ngIf="(isAllowedToEdit(item) | async) && suppliers.length > 1"
        [disabled]="!(isOnline$ | async)"
        [matMenuTriggerFor]="moveTo"
        id="custom-item-menu-move-to"
        mat-menu-item>
        {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.MOVE_TO' | translate }}
      </button>
      <button
        *ngIf="(isAllowedToEdit(item) | async)"
        (click)="onDeleteClicked(item.id)"
        [disabled]="!(isOnline$ | async)"
        id="custom-item-menu-delete"
        mat-menu-item>
        {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DELETE' | translate }}
      </button>
    </mat-menu>
    <mat-menu #moveTo="matMenu" class="move-to-menu-panel">
      <ng-container *ngFor="let supplier of suppliers | orderBy: 'ordinal'">
        <ng-container *ngIf="supplier.id !== item.supplierId">
          <button
            (click)="moveToSupplier(item.id, supplier.id)"
            [disabled]="!(isOnline$ | async)"
            id="custom-item-move-to-{{supplier.id}}"
            mat-menu-item>
            {{ supplier.name }}
          </button>
        </ng-container>
      </ng-container>
    </mat-menu>
  </div>
</div>

// Printing for Custom Guide and Order Guide
import { Worksheet, StorageArea } from './worksheet.model';
import { CountableItem, CustomPortioningUnit, MeasurementUnit } from '.';

export interface WorksheetPrintInfo {
  name: string;
  mobile: boolean;
  worksheet: Worksheet;
  items: { [s: string]: CountableItem };
  customUnits: { [s: string]: CustomPortioningUnit[] };
  formattedCreatedDate: string;
  customerName: string;
  customerId: string;
  selectedStorageAreas: StorageArea[];
  standardUnits: MeasurementUnit[];
}

<main>
  <div id="storage-area-header" [ngClass]="{'offline-mode': !(isOnline$ | async)}"
    *ngIf="(worksheet$ | async) as worksheet">
    <div class="back-button">
      <span>
        <button mat-button class="back-to-inventory-counts-button primary-button" *ngIf="(isOnline$ | async)" [disabled]=""
        [routerLink]="['/inventory']">&lt; {{ 'INVENTORY_WORKSHEET.BACK_BUTTON' | translate }}</button>
      </span>
      <mat-slide-toggle
        [checked]="useInfiniteScrolling$ | async"
        (change)="toggleInfiniteScrolling($event)"
        *ngIf="showInifniteScrollToggle()">
        {{'INVENTORY_WORKSHEET.INFINITE_SCROLL'| translate }}
      </mat-slide-toggle>
      <a mat-button class="print-button" (click)="printWorksheet()"
        attr.aria-label="{{ 'INVENTORY_WORKSHEET.PRINT_BUTTON' | translate }}">
        <div class="print-container">
          <mat-icon class="icon print-icon" svgIcon="print"></mat-icon>
          <div>{{ 'INVENTORY_WORKSHEET.PRINT_BUTTON' | translate }}</div>
        </div>
      </a>

    </div>
    <mat-divider></mat-divider>
    <div class="worksheet-title-container">
      <div class="worksheet-historic-banner" *ngIf="!(isActiveInventory$ | async)">
        <div>
          <mat-icon [inline]="true" svgIcon="info_icon_black"></mat-icon>
        </div>
        <div class="historic-banner-text">
          <div class="historic-banner-header">
            {{ 'INVENTORY_WORKSHEET.HISTORICAL_BANNER_HEADING' | translate }}
          </div>
          <div class="historic-banner-body">
            {{ 'INVENTORY_WORKSHEET.HISTORICAL_BANNER_BODY' | translate }}
          </div>
        </div>
      </div>
    <ng-container *ngIf="showRefreshWorksheetItemsButton()">
      <div id="refresh-worksheet-items">
        <div>Click this button to correct worksheet items that are not properly displaying due to ERP migration.</div>
        <div>The scan may take a few minutes. The page will reload when complete.</div>
        <div>
          <button (click)="refreshWorksheetItems()" class="primary-destructive-button">
            Scan Worksheet Items
          </button>
        </div>
      </div>
    </ng-container>
      <div class="worksheet-title-line">
        <div class="worksheet-name-container" *ngIf="(isOnline$ | async)">
          <div [hidden]="isEditingName$ | async" id="worksheet-title" class="worksheet-title" (click)="editName()">
            {{translateName(worksheet.name) | truncated:20}}
          </div>
          <form [hidden]="!(isEditingName$ | async)" [formGroup]="editWorksheetNameForm">
            <input id="edit-name-input" matInput #editNameInput type="text" (blur)="saveName()"
              class="inventory-name worksheet-title" formControlName="name" maxlength="200" autocomplete="off"
              placeholder="">
          </form>
        </div>
        <div class="worksheet-name-container" *ngIf="!(isOnline$ | async)">
          <div class="worksheet-title">
            {{translateName(worksheet.name) | truncated:20}}
          </div>
        </div>
        <div class="search-container">
          <gfs-item-search-bar [searchText]="'HEADER.SEARCH.PLACEHOLDER'" (enterSearch)="onExpandFilter($event)">
          </gfs-item-search-bar>
        </div>
        <div *ngIf="!(isMobile$ | async)" class="worksheet-title-button-bar">
          <button *ngxPermissionsOnly="permissionRoles.addItems" class="add-items-button secondary-button"
            [disabled]="!(isOnline$ | async)" (click)="onAddItemClick()">
            {{ 'INVENTORY_WORKSHEET.ADD_ITEMS' | translate }}
          </button>
        </div>
      </div>
      <div class="worksheet-sub-title">
        <div class="title-sub-text" id="worksheet-subtitle">
          <div *ngIf="!worksheet.closedDate">
            {{ 'INVENTORY_WORKSHEET.CREATED' | translate }}:
            {{getSubTitleDateTime(worksheet.created, (currentLang$ | async))}}
          </div>
          <div *ngIf="!!worksheet.closedDate">
            {{ 'INVENTORY_WORKSHEET.CLOSED' | translate }}:
            {{getSubTitleDateTime(worksheet.closedDate, (currentLang$ | async))}}
          </div>
          <div [hidden]="isSaving$ | async">
            {{ 'INVENTORY_WORKSHEET.MODIFIED' | translate }}:
            {{getSubTitleDateTime((worksheetLastModifiedDate$ | async), (currentLang$ | async))}}
          </div>
          <div [hidden]="!(isSaving$ | async)">
            {{ 'INVENTORY_WORKSHEET.SAVING' | translate }}
          </div>
        </div>
        <ng-container *ngxPermissionsOnly="pricingPermissions">
          <div *ngIf="!(isMobile$ | async) && !(isTotalWorksheetValueNull() | async)" class="total-value-text">
            {{ 'INVENTORY_WORKSHEET.TOTAL_VALUE' | translate }}: {{(getTotalWorksheetValue() | async) |
            currency:'CAD':'$':undefined:(currentLang$ | async)}}
          </div>
          <div *ngIf="!(isMobile$ | async) && (isTotalWorksheetValueNull() | async)" class="total-value-text">
            {{ 'INVENTORY_WORKSHEET.TOTAL_VALUE' | translate }}: {{'STORAGE_AREA.STORAGE_AREA_CARD.EMPTY_ITEM_VALUE' |
            translate}}
          </div>
        </ng-container>
      </div>
      <div *ngIf="isMobile$ | async" class="worksheet-title-button-bar-mobile">
        <button *ngxPermissionsOnly="permissionRoles.addItems" class="add-items-button secondary-button"
          [disabled]="!(isOnline$ | async)" (click)="onAddItemClick()">
          {{ 'INVENTORY_WORKSHEET.ADD_ITEMS' | translate }}
        </button>
        <button class="done-button primary-button" mat-button
          [disabled]="!(isOnline$ | async) || (closeWorkSheetInProgress$ | async)" (click)="setWorksheetDone()">

          {{ (DoneButtonText$ | async) | translate }}
        </button>
      </div>
      <div *ngIf="isMobile$ | async" class="total-value-text">
        <div class="total-value-label-mobile">
          {{ 'INVENTORY_WORKSHEET.TOTAL_VALUE' | translate }}:
        </div>
        <div *ngIf="!(isTotalWorksheetValueNull() | async)" class="total-value-amount-mobile">
          {{(getTotalWorksheetValue() | async) | currency:'CAD':'$':undefined:(currentLang$ | async)}}
        </div>
        <div *ngIf="(isTotalWorksheetValueNull() | async)" class="total-value-text">
          {{'STORAGE_AREA.STORAGE_AREA_CARD.EMPTY_ITEM_VALUE' | translate}}
        </div>
      </div>
    </div>
    <div *ngIf="isMobile$ | async">
      <app-storage-area-select class="app-storage-area-select" (addStorageArea)="onEditStorageArea()">
      </app-storage-area-select>
    </div>
  </div>

  <div id="storage-area-main-container" *ngIf="(worksheet$ | async) as worksheet">
    <div *ngIf="(isMobile$ | async) && worksheet?.storageAreas">
      <app-v2-storage-area-item-panel>
      </app-v2-storage-area-item-panel>
    </div>
    <mat-sidenav-container *ngIf="!(isMobile$ | async) && worksheet?.storageAreas">
      <mat-sidenav mode="side" opened class="storage-area-sidenav" [disableClose]="true">
        <app-storage-area-list (editStorageArea)="onEditStorageArea($event)">
        </app-storage-area-list>
        <button *ngxPermissionsOnly="permissionRoles.createStorageArea" id="new-storage-area-button"
          class="ghost-button" [disabled]="!(isOnline$ | async)" (click)="onEditStorageArea()"
          (focus)="storageAreaList.clearFocus()">
          <mat-icon [hidden]="!(isOnline$ | async)" class="icon storage-area-plus-icon" svgIcon="plus-icon"></mat-icon>
          <mat-icon [hidden]="isOnline$ | async" class="icon storage-area-plus-icon" svgIcon="plus-icon-gray">
          </mat-icon>
          <div class='new-storage-area-label'>{{ 'STORAGE_AREA.NEW_STORAGE_AREA' | translate }}</div>
        </button>
      </mat-sidenav>
      <mat-sidenav-content id="mat-sidenav-content" #storageAreaMainContainer
        [ngStyle]="{'padding-right': scrollWidth || '32px'}">
        <div class="storage-area-sidenav-content">
          <app-v2-storage-area-item-panel>
          </app-v2-storage-area-item-panel>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</main>

<input
    matInput
    type="text"
    autocomplete="off"
    class="{{fieldName}}-input {{inputClass}} add-element-autocomplete-trigger mat-input-element mat-form-field-autofill-control cdk-text-field-autofill-monitored"
    (keyup)="onKeyUp($event)"
    (blur)="onBlur($event)"
    [maxLength]="maxlength"
    #autocompleteInput="matAutocompleteTrigger"
    [matAutocomplete]="fieldAutocomplete"
    [disabled]="disabled"
    attr.aria-label="{{ placeholder | translate }}" />
<mat-autocomplete
    autoActiveFirstOption
    #fieldAutocomplete="matAutocomplete"
    class="{{autocompleteClass}} {{fieldName}}-add-element-autocomplete add-element-autocomplete"
    (optionSelected)="onOptionSelected($event)">
    <mat-option
        *ngIf="canCreateNew"
        id="{{fieldName}}-new-element-option"
        value="add_element_button"
        [ngClass]="{'disabled': elementExists()}">
        <div class="new-element-label">
            <mat-icon *ngIf="elementExists()" class="icon new-element-plus-icon" svgIcon="plus-icon-gray"></mat-icon>
            <mat-icon *ngIf="!elementExists()" class="icon new-element-plus-icon" svgIcon="plus-icon"></mat-icon>
            {{ 'SHARED.ADD' | translate }}
            <ng-container *ngIf="lastValue">"<strong>{{lastValue | truncated: 35}}</strong>"</ng-container>
            <ng-container *ngIf="!lastValue">{{addElementLabel}}</ng-container>
        </div>
    </mat-option>
    <mat-option
        *ngFor="let option of options"
        class="add-element-autocomplete-mat-option"
        [value]="getOptionValue(option)"
        #elementOption>
        <span [innerHTML]="getOptionText(option)"></span>
    </mat-option>
</mat-autocomplete>

import { Injectable } from '@angular/core';
import { LoadingSpinnerOverlayService } from 'libs/shared-components/src/v2/core/loader/loading-spinner-overlay.service';
import { Observable, of } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';

export interface ComponentIsSaving {
  getSavingNotifier: () => {
    saveInProgressNotifier$: Observable<boolean>
    onSaveComplete: Observable<boolean>
  }
}

@Injectable()
export class SaveInProgressGuard {
  constructor(
    private overlay: LoadingSpinnerOverlayService,
  ) { }

  canDeactivate(component: ComponentIsSaving): Observable<boolean> {
    const { saveInProgressNotifier$, onSaveComplete } = component.getSavingNotifier();

    return saveInProgressNotifier$
      .pipe(
        first(),
        concatMap(isInProgress => {
          if (isInProgress) {
            this.overlay.show();
            return onSaveComplete
              .pipe(tap(() => this.overlay.hide()));
          }
          return of(true);
        }),
      );
  }
}

import { Inject, Injectable } from '@angular/core';
import { RecipeConstants } from '@gfs/constants';
import { IAppContext, IDictionary, ItemReference, ItemTypeKeyReference, LocalizedMeasurementUnitOptionGroupx, reallyCoolOpts, Recipe, ResolvedItem, ResolvedMeasurementUnitGroup } from '@gfs/shared-models';
import { arrayToEntityDictionary, InjectionTokens, isUserConfiguredUnitType, LocalizedValueUtilsService, unitGr, unitGroupConfig } from '@gfs/shared-services';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { tapLog } from '@gfs/shared-services/extensions/rxjs';
import { combineLatest, forkJoin, iif, Observable, of } from 'rxjs';
import { concatMap, debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';
import { capitalizeFirstLetter, DropDownOption, getItemDataFn$ } from './v2.form.module';



@Injectable({
  providedIn: 'root'
})
export class MeasurementUnitOptionService {
  language$ = this.appContext.language;
  getItemData$ = getItemDataFn$;
  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext
  ) { }

  // creates a flat lookup of localized measurement units
  createLocalizedMeasurementUnitLookup$(
    itemReference: ItemReference,
    opts: reallyCoolOpts
  ) {
    return this.getLocalizedMeasurementUnits$(itemReference, opts)
      .pipe(
        map(optionGroups => {
          return optionGroups.reduce((acc, next) => ({
            ...acc, ...arrayToEntityDictionary(next.options, z => z.key)
          }), {} as IDictionary<DropDownOption>);
        })
      );
  }

  // @note creates a hot observable
  getLocalizedMeasurementUnits$(
    ingredientItemReference: ItemReference,
    opts: reallyCoolOpts
  ): Observable<LocalizedMeasurementUnitOptionGroupx[]> {
    return combineLatest([
      this.language$,
      of(ingredientItemReference)
        .pipe(
          concatMap(itemRef =>
            this.getItemDataWithSourceItemReference$(itemRef, opts)
          ),
          concatMap(({ resolvedItem }) => {
            // if the resolved item has a child use that item as the measurement unit source
            // otherwise continue with the current item reference
            return iif(
              // when we need a display (renderable) version of our item and it has an attached child
              () => opts.useDisplayItem && !!resolvedItem.itemReference.child,
              // use the child item reference
              of(resolvedItem.itemReference.child),
              // otherwise we already have a renderable item-reference
              of(resolvedItem.itemReference)
            );

          }),
          concatMap((itemRef) =>
            // @note was previously using display-item datasource
            this.getItemDataWithSourceItemReference$(itemRef, opts)
              .pipe(
                map(r => r.resolvedItem)
              )
          ),
          debounceTime(500)
        ),
    ])
      .pipe(
        tapLog('localizing ingredient measurement unit options...'),
        filter(([, units]) => !!units),
        concatMap(([language, resolvedItem]) => {
          return forkJoin([
            of(language),
            of(resolvedItem.units.units.filter(g => {
              // determine if this option group is related to the item
              const itemRef = resolvedItem.itemReference as ItemTypeKeyReference;
              return unitGr[itemRef.type].indexOf(g.group) > -1;
            })),
            iif(() => opts.useDisplayItem,
              of({
                [RecipeConstants.MEASUREMENT_TYPE.VOLUME.toUpperCase()]: resolvedItem.units.hasVolumeUnitConfiguration,
                [RecipeConstants.MEASUREMENT_TYPE.WEIGHT.toUpperCase()]: resolvedItem.units.hasWeightUnitConfiguration,
                ['CUSTOM']: true,
              }),
              of({
                [RecipeConstants.MEASUREMENT_TYPE.VOLUME.toUpperCase()]: true,
                [RecipeConstants.MEASUREMENT_TYPE.WEIGHT.toUpperCase()]: true,
                ['CUSTOM']: true,
              })
            ),
          ]);
        }
        ),
        map(([language, unitGroups, unitConfigState]) => {
          return [...unitGroups]
            .sort((a, b) => unitGroupConfig[a.group].ordinal - unitGroupConfig[b.group].ordinal)
            .map(unitGroup => {
              const { groupKey, uiConfig } = this.resolveMeasurementUnitUIConfig(unitGroup);

              const o = unitGroup.units
                .filter(() => {
                  if (isUserConfiguredUnitType(unitGroup.group)) {

                    return unitConfigState[unitGroup.group];
                  }
                  return true;
                })
                .map(u => {
                  let unitText = LocalizedValueUtilsService.resolveLocalizedValue(u.localized, language).trim();
                  const symbolText = LocalizedValueUtilsService.resolveLocalizedValue(u.localizedSymbol, language).trim();
                  unitText = capitalizeFirstLetter(unitText);
                  const symbolTextWithParen = symbolText.length ? `(${symbolText})` : '';

                  let output = `${unitText} ${symbolTextWithParen}`;
                  if (opts?.minText && symbolText.length > 0) {
                    output = `${symbolText}`;
                  }

                  return {
                    name: output,
                    key: u.key,
                  };
                });
              return {
                groupType: groupKey.toUpperCase(),
                name: uiConfig.heading_i18nKey,
                createPromptTexti18n: uiConfig.createPrompt_i18nKey,
                options: o
              };
            }) as LocalizedMeasurementUnitOptionGroupx[];
        }),
        withLatestFrom(this.getItemData$(ingredientItemReference, opts)),
        map(([e, resolvedItem]) => {
          if (opts.useDisplayItem && caseInsensitiveEquals(resolvedItem.itemReference.type, 'RECIPE')) {
            return this.tryFilterRecipeIngredientMeasurementUnitOptionsOptions(e, resolvedItem);
          }
          return e;
        }),
      );
  }

  private getItemDataWithSourceItemReference$(
    itemRef: ItemReference,
    opts: reallyCoolOpts
  ): Observable<{ itemRef: ItemReference; resolvedItem: ResolvedItem; }> {
    return this.getItemData$(itemRef, opts)
      .pipe(
        map(resolvedItem => ({ itemRef, resolvedItem }))
      );
  }

  private tryFilterRecipeIngredientMeasurementUnitOptionsOptions(
    allOptionGroups: LocalizedMeasurementUnitOptionGroupx[],
    resolvedItem: ResolvedItem
  ): LocalizedMeasurementUnitOptionGroupx[] {
    const { recipeItem, itemReference } = resolvedItem;
    return allOptionGroups

      .filter(({ groupType }) => this.unitGroupsForItemType(itemReference, groupType))
      .map(r => {
        this.tryFilterBatchRecipeIngredientOptions(recipeItem, allOptionGroups, r);
        this.tryFilterMenuItemIngredientOptions(recipeItem, r);
        return r;
      })
      .filter(x => !!x.options && x.options.length > 0);
  }

  private tryFilterMenuItemIngredientOptions(
    recipeItem: Recipe,
    unitOptionGroup: LocalizedMeasurementUnitOptionGroupx
  ) {
    if (caseInsensitiveEquals(recipeItem.itemType, 'MENUITEMRECIPE')) {
      if (unitGr[recipeItem.itemType].indexOf(unitOptionGroup.groupType) === -1) {
        unitOptionGroup.options = null;
      } else {
        unitOptionGroup.options = unitOptionGroup.options.filter(h => caseInsensitiveEquals(h.key, 'menuItem_each'));
      }
    }
  }

  private tryFilterBatchRecipeIngredientOptions(
    recipeItem: Recipe,
    allOptions: LocalizedMeasurementUnitOptionGroupx[],
    unitOptionGroup: LocalizedMeasurementUnitOptionGroupx
  ) {
    if (caseInsensitiveEquals(recipeItem.itemType, 'BATCHRECIPE')) {
      const yieldUnitGroupReverseLookup = this.createAdhocUnitReverseLookupFromOptions(allOptions);
      const yieldUnitGroup = yieldUnitGroupReverseLookup[recipeItem.details.batch.totalYieldUnit];

      if (yieldUnitGroup === 'RECIPE_YIELD') {
        unitOptionGroup.options = unitOptionGroup.options.filter(h => caseInsensitiveEquals(h.key, recipeItem.details.batch.totalYieldUnit));
      } else {
        if (!caseInsensitiveEquals(unitOptionGroup.groupType, yieldUnitGroup)) {
          unitOptionGroup.options = null;
        }
      }
    }
  }

  private createAdhocUnitReverseLookupFromOptions(allOptions: LocalizedMeasurementUnitOptionGroupx[]) {
    return allOptions
      .filter(localizedOptions => !!localizedOptions.options)
      .map(j => j.options.reduce((acc, curr) => ({ ...acc, [curr.key]: j.groupType }), {} as IDictionary<string>)
      ).reduce((acc, next) => ({ ...acc, ...next }), {} as IDictionary<string>);
  }

  private unitGroupsForItemType(itemReference: ItemReference, groupType: string): boolean {
    return unitGr[itemReference.type].indexOf(groupType) > -1;
  }

  private resolveMeasurementUnitUIConfig(e: ResolvedMeasurementUnitGroup) {
    const groupKey = e.group.toUpperCase();
    const uiConfig = unitGroupConfig[e.group] ?? unitGroupConfig[groupKey];
    return { groupKey, uiConfig };
  }

}

export function getOptionDisplayTextFn(option: { name: string }): string { return option ? option.name.trim() : ''; }

export function showConfigureButtonFn(optionGroup: LocalizedMeasurementUnitOptionGroupx) {
  if (isUserConfiguredUnitType(optionGroup.groupType)) {
    const alwaysShow = unitGroupConfig[optionGroup.groupType].alwaysShowCreatePrompt === true;
    const hasNoOptions = optionGroup.createPromptTexti18n !== null && optionGroup.options.length === 0;

    return alwaysShow || hasNoOptions;
  }
  return false;
}

<mat-toolbar class="network-status-toolbar" [ngClass]="{'online-mode': (isOnline$ | async), 'offline-mode': !(isOnline$ | async)}">
    <mat-toolbar-row *ngIf="!(isOnline$ | async)">
        <mat-expansion-panel
          *ngIf="!(retryingConnection$ | async)"
          [(expanded)]="expanded"
          (closed)="onExpandedToggle()"
          (opened)="onExpandedToggle()">
            <mat-expansion-panel-header [collapsedHeight]="(isMobile$ | async) ? '40px' : '56px'" expandedHeight="40px">
                <mat-panel-title>
                    <mat-icon class="icon connection-status" svgIcon="connection-status"></mat-icon>
                    {{'NETWORK_STATUS.NO_CONNECTION_TITLE' | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            {{'NETWORK_STATUS.NO_CONNECTION_DESCRIPTION' | translate}}
        </mat-expansion-panel>
        <div class="reconnect-text" *ngIf="retryingConnection$ | async">
            <mat-icon class="icon connection-status" svgIcon="connection-status"></mat-icon>
            {{'NETWORK_STATUS.RETRYING_CONNECTION' | translate}}...
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="isOnline$ | async">
        <div class="back-online-text">
            <mat-icon class="icon connection-status" svgIcon="connection-status"></mat-icon>
            {{'NETWORK_STATUS.RECONNECTED' | translate}}
        </div>
    </mat-toolbar-row>
</mat-toolbar>
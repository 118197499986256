import { Injectable } from '@angular/core';
import { ConversionUnit, CustomItemData, MeasurementUnit } from '@gfs/shared-models';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { InventoryConstants } from '@gfs/constants';
import { IFeatureStateFacade as AddItemsFeatureState } from '@gfs/store/feature/add-items';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionUtilService {

  public static CONVERT_KG_TO_LB = 2.20;

  standardWeightUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits).pipe(
    filter(x => x !== null),
    map(units => units.filter(unit => unit.measurementType === 'weight'))
  );
  standardUnits: MeasurementUnit[] = [];
  customItemData: CustomItemData[] = [];

  constructor(
    private store: Store<AddItemsFeatureState>
  ) {
    this.standardWeightUnits$.pipe(take(4)).subscribe(val => this.standardUnits = val);
  }

  convertMeasurementUnit(fromQty: number, fromUnit: ConversionUnit, toUnit: ConversionUnit): number {
    const conversionFactor = this.getConversionFactorBetweenUnits(toUnit, fromUnit);
    const newQty = fromQty * conversionFactor;
    return newQty;

  }

  getConversionFactorBetweenUnits(
    toUnit: ConversionUnit,
    fromUnit: ConversionUnit
  ): number {
    const conditions = [
      fromUnit?.unitId === InventoryConstants.STANDARD_WEIGHT_UNIT_KG,
      toUnit?.unitId === InventoryConstants.STANDARD_WEIGHT_UNIT_LB
    ];
    const convert = conditions.every(isTrue => isTrue);
    if (convert) {
      return UnitConversionUtilService.CONVERT_KG_TO_LB;
    }

    let conversionFactor = 1.0;
    // convert original unit to base parent unit
    if (toUnit && fromUnit) {
      let conversionUnit = toUnit;
      while (conversionUnit.qtyInParent !== '1') {
        conversionFactor = this.convertUnitToParent(conversionFactor, conversionUnit);
        conversionUnit = this.getParentUnit(conversionUnit);
      }
      // convert base unit to final unit
      const baseUnit = conversionUnit;
      conversionUnit = fromUnit;

      const retryLimit = 400;
      let iteration = 0;
      while (!((conversionUnit.unitId === baseUnit.unitId) && (conversionUnit.unitType === baseUnit.unitType))) {
        if (iteration > retryLimit) {
          break;
        }
        conversionFactor = this.convertUnitFromParent(conversionFactor, conversionUnit);
        conversionUnit = this.getParentUnit(conversionUnit);
        iteration++;
      }
    }
    return conversionFactor;
  }

  private convertUnitToParent(value: number, unit: ConversionUnit): number {
    return value * parseFloat(unit.qtyInParent);
  }

  private getParentUnit(unit: ConversionUnit): ConversionUnit {
    if (unit.unitType === InventoryConstants.UNIT_TYPE_STANDARD) {
      return this.getStandardUnit(unit.parentUnitId);
    } else if (unit.unitType === InventoryConstants.UNIT_TYPE_CUSTOM) {
      return {
        unitType: InventoryConstants.UNIT_TYPE_LITERAL,
        unitId: 'case',
        parentUnitId: null,
        qtyInParent: '1'
      } as ConversionUnit;
    } else {
      return {
        unitType: InventoryConstants.UNIT_TYPE_STANDARD,
        unitId: InventoryConstants.STANDARD_WEIGHT_UNIT_KG,
        parentUnitId: InventoryConstants.STANDARD_WEIGHT_UNIT_KG,
        qtyInParent: '1'
      } as ConversionUnit;
    }
  }

  private getStandardUnit(unitId: string): ConversionUnit {
    if (this.standardUnits) {
      const standardUnit = this.standardUnits.find(unit => unit.id === unitId);
      if (standardUnit) {
        return {
          unitType: InventoryConstants.UNIT_TYPE_STANDARD,
          unitId: standardUnit.id,
          parentUnitId: standardUnit.parentUnitId,
          qtyInParent: standardUnit.qtyInParent
        } as ConversionUnit;
      }
    }
  }

  private getCustomUnit(unitId: string, customItemData: CustomItemData): ConversionUnit {
    if (customItemData && customItemData.countingUnits) {
      const customUnit = customItemData.countingUnits.find(unit => unit.custom && (unit.custom.id === unitId));
      if (customUnit && customUnit.custom) {
        return {
          unitType: InventoryConstants.UNIT_TYPE_CUSTOM,
          unitId: customUnit.custom.id,
          parentUnitId: customUnit.custom.parentUnitId,
          qtyInParent: customUnit.custom.qtyInParent
        } as ConversionUnit;
      }
    }
  }

  private convertUnitFromParent(value: number, unit: ConversionUnit): number {
    return value / parseFloat(unit.qtyInParent);
  }

  getConversionUnit(
    unitType: string,
    unitId: string,
    customItemData: CustomItemData,
  ): ConversionUnit {
    if (unitType === InventoryConstants.UNIT_TYPE_LITERAL) {
      if (unitId && (unitId.toLowerCase() === InventoryConstants.LITERAL_UOM_CASE)) {
        return {
          unitType: InventoryConstants.UNIT_TYPE_SAP,
          unitId: InventoryConstants.SAP_UOM_CASE,
          parentUnitId: InventoryConstants.SAP_UOM_CASE,
          qtyInParent: '1'
        };
      } else if (unitId
        && ((unitId.toLowerCase() === InventoryConstants.LITERAL_UOM_UNIT) || (unitId === InventoryConstants.SAP_UOM_EACH))
      ) {
        return {
          unitType: InventoryConstants.UNIT_TYPE_SAP,
          unitId: InventoryConstants.SAP_UOM_EACH,
          parentUnitId: InventoryConstants.SAP_UOM_EACH,
          qtyInParent: '1'
        };
      }
    } else if (unitType === InventoryConstants.UNIT_TYPE_STANDARD) {
      return this.getStandardUnit(unitId);
    } else if (unitType === InventoryConstants.UNIT_TYPE_CUSTOM) {
      return this.getCustomUnit(unitId, customItemData);
    } else {
      return { unitType, unitId, parentUnitId: unitId, qtyInParent: '1' };
    }
  }
}
import { Injectable } from '@angular/core';


export class AddItemsModalAppSettings {
  FF_ADD_NON_GFS_ITEMS: any;
  FF_SEARCH_FILTER_RECIPES_ITEMS: any;
  FF_SEARCH_FILTER_NONGFS_ITEMS: any;
  FF_SEARCH_FILTER_CATALOGUE: any;
  FF_SEARCH_FILTER_ORDER_GUIDE_ITEMS: any;
  FF_CUSTOM_COUNT_UNITS: any;
}

export declare interface IAddItemsModalAppSettingsProviderService {
  getSettings(): AddItemsModalAppSettings;
}


@Injectable({
  providedIn: 'root',
})
export class MockItemsModalAppSettingsProviderService
  implements IAddItemsModalAppSettingsProviderService {
  constructor(private settings: AddItemsModalAppSettings) {}
  getSettings(): AddItemsModalAppSettings {
    return this.settings;
  }
}



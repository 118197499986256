<div class="customer-unit-selection">
  <h1 class="heading">
    {{ 'CUSTOMER_UNIT.SELECT_A_LOCATION' | translate }}
  </h1>
  <div>
    <div class="customer-unit-heading">
      <h3 *ngIf="!(isMobile$ | async) || !selectedUnit" class="heading">
        {{ 'CUSTOMER_UNIT.AVAILABLE_LOCATIONS' | translate }}
      </h3>
      <h3 *ngIf="(isMobile$ | async) && selectedUnit" class="heading">
        {{ 'CUSTOMER_UNIT.CURRENTLY_VIEW_AS' | translate }}
      </h3>
      <gfs-customer-unit-searchbar *ngIf="!(isMobile$ | async)"
        (searchTextEmitter)="updateFilter($event)"
      ></gfs-customer-unit-searchbar>
    </div>
    <div class="selected-unit-mobile" *ngIf="(isMobile$ | async) && showSelectedUnit()">
      <gfs-customer-unit-card
        class="total"
        [active]="true"
        [customerUnit]="selectedUnit"
      ></gfs-customer-unit-card>
    </div>
    <h3 *ngIf="(isMobile$ | async) && selectedUnit" class="heading other-locations">
      {{ 'CUSTOMER_UNIT.OTHER_LOCATIONS' | translate }}
    </h3>
    <gfs-customer-unit-searchbar *ngIf="isMobile$ | async"
      (searchTextEmitter)="updateFilter($event)"
    ></gfs-customer-unit-searchbar>
    <div class="cards-wrapper">
      <ng-container *ngIf="otherEntitlements$ | async as otherEntitlementsResolved">
        <div *ngIf="!(isMobile$ | async) && showSelectedUnit()">
          <gfs-customer-unit-card
            class="total"
            [active]="true"
            [customerUnit]="selectedUnit"
            [isSubAccount]='false'
          ></gfs-customer-unit-card>
        </div>
        <div *ngFor="let row of (otherEntitlementsResolved)">
          <gfs-customer-unit-card
            class="total"
            [active]="row.entitlement.customerPK.customerId === (selectedCustomerPk$ | async)?.customerId"
            [customerUnit]="row.entitlement"
            [isSubAccount]='false'
          >
        </gfs-customer-unit-card>
          <div *ngFor="let subRow of (row.subLocations)">
            <gfs-customer-unit-card
              class="total"
              [active]="subRow.customerPK.customerId === (selectedCustomerPk$ | async)?.customerId"
              [customerUnit]="subRow"
              [isSubAccount]='true'
            >
            </gfs-customer-unit-card>
          </div>
        </div>
      </ng-container>
    </div>
    <gfs-no-results
      *ngIf="(otherEntitlements$ | async)?.length === 0 && !showSelectedUnit()"
      [searchText]="searchText"
      [isMobile]="isMobile$ | async"
    ></gfs-no-results>
  </div>
</div>

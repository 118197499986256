import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Observable, concatMap, of } from 'rxjs';
import { AuthenticationService, blockFromRecipeProfitCalculator as blockFromRecipeProfitCalculatorFn } from '@gfs/shared-services/auth/authentication-state.service';
import { AuthenticationState } from '../models/authentication-state.model';
import { Constants } from '@gfs/constants';
import { RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';
import { WINDOW } from '@gfs/shared-services/services/window.service';

export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    authenticationService: AuthenticationService = inject(AuthenticationService),
    router: Router = inject(Router),
    recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig = inject(RECIPE_PROFIT_CALCULATOR_CONFIG),
    window: Window = inject(WINDOW) as Window,
    blockFromRecipeProfitCalculator = blockFromRecipeProfitCalculatorFn
): Observable<boolean> => {
    return authenticationService.authenticationState$()
        .pipe(
            concatMap((authState: AuthenticationState) => {
                const isRecipeProfitCalculatorBlocked = blockFromRecipeProfitCalculator(
                    recipeProfitCalculatorConfig,
                    window);
                if (isRecipeProfitCalculatorBlocked) {
                    router.navigateByUrl(Constants.SharedFeaturePaths.ERROR);
                    return of(false);
                }

                if (authState.isAuthenticated) {
                    return of(true);
                } else {
                    return authenticationService.canActivate(route, state);
                }
            })
        );
};

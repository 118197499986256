import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class AppEffects {

  actionLogger$ = createEffect(() => this.actions$.pipe(
    tap((action) => {
      const ACTIONS_TO_EXCLUDE = [
        '[Worksheet] Worksheet Synchronization Attempt',
        '[Network] SetRetryingConnection',
      ];
      if (!ACTIONS_TO_EXCLUDE.includes(action.type)) {
        console.log(action);
      }
    })
  ), { dispatch: false });

  constructor(private actions$: Actions) { }
}

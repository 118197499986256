import { CustomerPK } from './user.model';

export interface Customer {
  customerName: string;
  statusCode: string;
  shipToAddress1: string;
  shipToAddress2: string;
  shipToCity: string;
  shipToCountry: string;
  shipToPhoneNumber: number;
  shipToPostalCode: string;
  shipToProvinceState: string;
  customerPK: CustomerPK;
}

export type CustomerSearchRequest = {
  customerId: string;
  customerName: string;
  city: string;
  stateOrProvinceCode: string;
  countryCode: string;
}

export type CustomerSearchResult = {
  customerName: string;
  customerId: string;
  city: string;
  stateProvinceCode: string;
  customerPK: CustomerPK;
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomerPK, MeasurementUnit } from '@gfs/shared-models';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { catchError, concatMap, first } from 'rxjs/operators';
import { InjectionTokens } from '../../injection-tokens';
import { MessageService } from '../../lib/services/message.service';
import { pushToSubject } from '../extensions/rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeasurementUnitService {

  private baseUrl: string;
  private apiCache$ = new BehaviorSubject<MeasurementUnit[]>(null);

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private translate: TranslateService,
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = this.API_BASE_URL + '/api/v1';
  }

  lastPKHash = null;
  getMeasurementUnits(customerPK: CustomerPK): Observable<MeasurementUnit[]> {
    if (this.lastPKHash !== customerPK.hash) {
      this.lastPKHash = customerPK.hash;
      this.apiCache$.next(null);
    }

    return this.apiCache$
      .pipe(
        concatMap(cache =>
          iif(
            () => !cache,
            this.http.get<MeasurementUnit[]>(`${this.baseUrl}/measurement-units`, { params: { ...customerPK } })
              .pipe(
                pushToSubject(this.apiCache$),
                catchError(() => {
                  const localizedErrorMessage = this.translate.instant('MESSAGES.SNACKBAR_ERROR_MESSAGE');
                  this.notifyUser(`${localizedErrorMessage} getMeasurementUnits`);
                  return of([]);
                })
              ),
            this.apiCache$.asObservable(),
          )
        ),
        first(),
      );
  }

  public notifyUser(message: string) {
    this.messageService.queue(message);
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  ActionFailed,
  ActionTypes,
  ActionUnion,
  AddAccountCompleted,
  AddItemCategoryToAccount,
  AddItemCategoryToAccountCompleted,
  DeleteGeneralLedgerCompleted,
  LoadGeneralLedgerCompleted,
  RemoveItemCategoryFromAccount,
  RemoveItemCategoryFromAccountCompleted,
  UpdateAccountCompleted
} from './general-ledger.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IAppContext } from '@gfs/shared-models';
import { InjectionTokens, ItemCategoryService, MessageService } from '@gfs/shared-services';
import { catchError, concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { GeneralLedgerService } from '../services/general-ledger.service';
import { forkJoin, of } from 'rxjs';
import { GeneraLedgerAccountPatch, GeneralLedgerAccount, ItemCategoryGLAccount } from '../models/general-ledger.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GeneralLedgerEffects {

  constructor(
    private actions$: Actions<ActionUnion>,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private generalLedgerService: GeneralLedgerService,
    private itemCategoryService: ItemCategoryService,
    private messageService: MessageService,
    private translate :TranslateService
  ) {
  }

  loadGeneralLedger$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LoadGeneralLedger),
    withLatestFrom(this.appContext.customerPK),
    concatMap(([_, pk]) =>
      forkJoin([
        this.generalLedgerService.getGeneralLedger(pk),
        this.itemCategoryService.getItemCategories(pk)
      ]).pipe(
        map(([accountsDto, itemCategories]) => {
          return new LoadGeneralLedgerCompleted({ accountsDto, itemCategories });
        }),
        catchError(e => of(new ActionFailed(e)))
      ))
  ));

  addAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.AddAccount),
    withLatestFrom(this.appContext.customerPK),
    concatMap(([{ model }, pk]) => this.generalLedgerService.addAccount({
      customerPK: pk,
      description: model.description,
      glCode: model.glCode
    }).pipe(
      map((model: GeneralLedgerAccount) => new AddAccountCompleted(model)),
      catchError(e => of(new ActionFailed(e)))
    ))
  ));

  updateItemCategoryAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateItemCategoryAccount),
    filter(({ payload }) => payload.addtoGeneralLedgerAccountId !== payload.removeFromGeneralLedgerAccountId),
    concatMap(({ payload }) => {
      const actions: (AddItemCategoryToAccount | RemoveItemCategoryFromAccount)[] = [];
      if (payload.removeFromGeneralLedgerAccountId) {
        actions.push(new RemoveItemCategoryFromAccount({
          generalLedgerAccountId: payload.removeFromGeneralLedgerAccountId,
          itemCategoryId: payload.itemCategoryId
        }));
      }

      if (payload.addtoGeneralLedgerAccountId) {
        actions.push(new AddItemCategoryToAccount({
          generalLedgerAccountId: payload.addtoGeneralLedgerAccountId,
          itemCategoryId: payload.itemCategoryId
        }));
      }

      return actions;
    })
  ));


  addItemCategoryToAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.AddItemCategoryToAccount),
    withLatestFrom(this.appContext.customerPK),
    concatMap(([{ payload }, pk]) => this.generalLedgerService.addItemCategory(pk, payload.generalLedgerAccountId, payload.itemCategoryId)
      .pipe(
        map((model: ItemCategoryGLAccount) => new AddItemCategoryToAccountCompleted(model)),
        catchError(e => of(new ActionFailed(e)))
      ))
  ));

  removeItemCategoryToAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RemoveItemCategoryFromAccount),
    withLatestFrom(this.appContext.customerPK),
    concatMap(([{ payload }, pk]) => {
      return this.generalLedgerService.removeItemCategory(pk, payload.generalLedgerAccountId, payload.itemCategoryId)
        .pipe(
          map((model: ItemCategoryGLAccount) => new RemoveItemCategoryFromAccountCompleted(model)),
          catchError(e => of(new ActionFailed(e)))
        );
    })
  ));

  actionFailed$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ActionFailed),
    tap(() => this.messageService.queue('There was an error. Please try again.'))
  ), { dispatch: false });

  updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.UpdateAccountPopUp),
      withLatestFrom(this.appContext.customerPK),
      concatMap(([{ model }, pk]) => {
        return this.generalLedgerService.updateAccount({
          customerPK: pk,
          id: model.id,
          description: model.description,
          glCode: model.glCode
        }).pipe(
          map((model: GeneraLedgerAccountPatch) => new UpdateAccountCompleted(model)),
          catchError(e => of(new ActionFailed(e)))
        );
      })
    );
  });


  deleteGeneralAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteGeneralLedger),
    withLatestFrom(this.appContext.customerPK),
    concatMap(([{ model }, pk]) => this.generalLedgerService.deleteAccount(pk, model.id)
      .pipe(
        map((model: GeneralLedgerAccount) => new DeleteGeneralLedgerCompleted(model)),
        catchError(e => of(new ActionFailed(e)))
      ))
  ));

  deleteGeneralAccountCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteGeneralLedgerCompleted),
    tap(action => {
      const message = this.translate.instant('MESSAGES.DELETION_MESSAGE', { value: action.model.description });
      this.messageService.queue(message);
    })
  ), { dispatch: false });


}

import { Component, Output, EventEmitter, ViewChild, AfterViewChecked } from '@angular/core';
import { Worksheet, StorageArea } from '@gfs/shared-models';
import { MatSelect } from '@angular/material/select';
import { StorageAreaUtilsService } from '@gfs/shared-services';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as workSheetActions from '@gfs/store/inventory/actions/worksheets.actions';
import { first } from 'rxjs/operators';
import { InventoryConstants } from '@gfs/constants';


@Component({
  selector: 'app-storage-area-select',
  templateUrl: './storage-area-select.component.html',
  styleUrls: ['./storage-area-select.component.scss']
})
export class StorageAreaSelectComponent implements AfterViewChecked {
  @Output() addStorageArea = new EventEmitter<void>();
  isOnline$: Observable<boolean> = this.store.select(state => state.network.isOnline);
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;

  worksheet$: Observable<Worksheet> = this.store.select(state => state.worksheets.worksheets[state.worksheets.selected]);
  @ViewChild(MatSelect) select: MatSelect;
  configSelect = true;

  constructor(
    private store: Store<AppState>,
    private storageAreaUtils: StorageAreaUtilsService
  ) { }

  ngAfterViewChecked() {
    if (this.select && this.configSelect) {
      this.configSelect = false;
    }
  }

  async storageAreaSelected(storageArea: StorageArea) {
    this.store.dispatch(new workSheetActions.SetStorageAreaExpandStatus({ status: true, areaId: storageArea.id }));
    const name = storageArea.name ? storageArea.name.replace(' ', '-') + '-' : '';
    const storageAreaId = `storage-area-item-panel-${name}${storageArea.id}`;
    const el = document.getElementById(storageAreaId);

    // added a slight async delay here because the element isnt expanded by the time we try to scroll it into view
    await new Promise(resolve => setTimeout(resolve, 100));

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  getTranslation(name: string) {
    return this.storageAreaUtils.getTranslation(name);
  }

  getStorageAreasMinusUnassigned(): Observable<StorageArea[]> {
    return this.storageAreaUtils.getStorageAreasMinusUnassigned(this.worksheet$);
  }

  onAddStorageArea() {
    this.isOnline$.pipe(first()).subscribe(isOnline => {
      if (isOnline) {
        this.addStorageArea.emit();
      }
    });
  }
}

export class GeneralLedgerAccountInput {
    private _glCode: string;
    public get glCode(): string {
        return this._glCode;
    }
    public set glCode(value: string) {
        this._glCode = value.trim();
    }

    private _description: string;
    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value.trim();
    }
}

<a
  (click)="selectCustomerUnit()"
  role="link"
  id="customer-unit-{{customerUnit.customerPK.customerId}}"
  *ngIf="customerUnit && cartLoader$ | async"
  [attr.aria-label]="active ? ('CUSTOMER_UNIT.CURRENTLY_VIEW_AS_CUSTOMER_NAME' | translate: { customerName: customerUnit.customerName })
      : customerUnit.customerName"
  [attr.alt]="active ? ('CUSTOMER_UNIT.CURRENTLY_VIEW_AS_CUSTOMER_NAME' | translate: { customerName: customerUnit.customerName })
      : customerUnit.customerName"
  href="javascript:void(0)"
  [ngClass]="{'offset' : offset}">
  <div *ngIf="(customers$ | async) as customers">
    <div class="customer-unit-card" [ngClass]="{ selected: active, 'offset' : offset}">
      <div class="customer-info">
        <ng-container *ngIf="customers[customerUnit.customerPK.customerId] as customer">
          <div class="customer-name">{{ customer.customerName }}</div>
          <div class="customer-number">#{{ customerUnit.customerPK.customerId }}</div>
          <div class="address">
            <span *ngIf="customer?.shipToAddress2?.trim()">
              {{ customer?.shipToAddress2 }},
            </span>
            <span *ngIf="customer?.shipToCity?.trim()">
              {{ customer?.shipToCity }},
            </span>
            <span *ngIf="customer?.shipToProvinceState?.trim()">
              {{ customer?.shipToProvinceState }},
            </span>
            <span *ngIf="customer?.shipToPostalCode?.trim()">
              {{ customer?.shipToPostalCode }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!customers[customerUnit.customerPK.customerId]">
          <div class="customer-name">{{ customerUnit.customerName }}</div>
          <div class="customer-number">#{{ customerUnit.customerPK.customerId }}</div>
        </ng-container>
      </div>
      <div class="cart-info" *ngIf="!offsetEntityTypes.includes(customerUnit.entityType) && (cartsQuantities$ | async)[customerUnit.customerPK.customerId] as cartsQuantities;">
        <div class="cart-info-wrapper">
          <div class="cart-items-wrapper">
            <div class="cart-icon">
              <mat-icon role="img" svgIcon="cart_icon_gray"></mat-icon>
            </div>
            <div class="cart-items-count">
              <div *ngIf="cartsQuantities; else dash">
                {{ cartsQuantities.totalQuantity || 0 }}
              </div>
              <ng-template #dash>-</ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>

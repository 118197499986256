import { Component, Input } from '@angular/core';

@Component({
  selector: 'gfs-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {

  readonly diameter: number = 70;
  readonly strokeWidth: number = 7;

  @Input() spinnerClass: string;
}

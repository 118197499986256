import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { IAppContext, Worksheet } from '@gfs/shared-models';
import { InjectionTokens, WorksheetService } from '@gfs/shared-services';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetWorksheetNoCacheResolver implements Resolve<Observable<Worksheet>> {

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private worksheetSvc: WorksheetService,
    private overlaySvc: LoadingSpinnerOverlayService

  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Worksheet> {
    this.overlaySvc.show();
    return this.worksheetSvc.getWorkSheet(route.parent.params.id);
  }

}
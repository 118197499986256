import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuickAddItemsStoreEffects } from './quick-add-items.effects';
import * as fromQuickAddItems from './quick-add-items.reducer';
import * as fromQuickAddItemsState from './quick-add-items.state';
// import { RecipeProfitCalculatorModule } from './../../../../shared-components/src/lib/recipe-profit-calculator';

@NgModule({
  imports: [
    CommonModule,
    // RecipeProfitCalculatorModule,
    StoreModule.forFeature(fromQuickAddItemsState.FeatureKey, fromQuickAddItems.quickAddItemsReducer),
    EffectsModule.forFeature([QuickAddItemsStoreEffects])
  ],
  declarations: []
})
export class QuickAddItemsStateModule { }

import { Inject, Injectable } from '@angular/core';
import { CustomerPK, CountableItem } from '@gfs/shared-models';
import { HttpClient } from '@angular/common/http';
import { Observable, first } from 'rxjs';
import moment from 'moment';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class CountableItemService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private INVENTORY_API_BASE_URL: string,
  ) {
    this.baseUrl = INVENTORY_API_BASE_URL + '/api/v1';
  }

  getCountableItems(
    customerPK: CustomerPK,
    userLocale: string,
    searchText: string,
    offset: number,
    limit: number,
    date: string,
    type: string
  ): Observable<CountableItem[]> {
    const obj = Object.assign(customerPK,
      {
        locale: userLocale,
        searchText,
        offset: offset.toString(),
        limit: limit.toString(),
        date: moment.utc(date).format('YYYY-MM-DD'),
        type
      });
    return this.http.get<CountableItem[]>(`${this.baseUrl}/countable-items`, { params: { ...obj } })
      .pipe(first());
  }

}

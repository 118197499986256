import { Platform } from '@angular/cdk/platform';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { InventoryConstants } from '@gfs/constants';
import { CountableItem, CustomerPK, CustomItem, CustomItemData, CustomItemUpdate, Entitlement, GeneralItemUpdate, ItemCategory, MeasurementUnit, PortioningUnit, PurchaseUnit, StorageArea, Supplier, Worksheet, WorksheetItem, WorksheetItemPost } from '@gfs/shared-models';
import {
  CategoryValidator, CustomUnitUtilsService, InjectionTokens, LocalizedValueUtilsService, StorageAreaUtilsService, StorageAreaValidator
} from '@gfs/shared-services';
import {
  ClearCustomItem,
  CreateCategoryAttempt,
  CreateCustomItemAttempt,
  CreateCustomItemDataAttempt,
  CreateSupplierAttempt,
  GetItemCategoriesAttempt,
  GetMeasurementUnitsAttempt,
  GetSuppliersAttempt,
  IAddItemsFeatureBridge,
  IFeatureStateFacade,
  SaveCustomItemStart,
  UpdateCustomItemDataAttempt
} from '@gfs/store/feature/add-items';
import { GetAllCustomItemsAttempt, GetAllGeneralItemsAttempt } from '@gfs/store/inventory/actions/customerItems.actions';
import {
  UpdateCustomItemsAttempt,
  UpdateGeneralItemsAttempt
} from '@gfs/store/inventory/actions/inventoryItems.actions';
import {
  CreateStorageAreaName,
  CreateWorksheetItemAttempt,
  DeleteInventoryItem,
  DeleteInventoryItemAttempt,
} from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get, sortBy, trim } from 'lodash-es';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  startWith,
  takeUntil, withLatestFrom
} from 'rxjs/operators';

@Component({
  selector: 'gfs-add-custom-item',
  templateUrl: './add-custom-item.component.html',
  styleUrls: ['./add-custom-item.component.scss'],
})
export class AddCustomItemComponent implements OnInit, OnDestroy {
  @Input() focusSection: string;
  @Input() currentStorageAreaId: string;
  @Input() countableItem: CountableItem;
  @Input() worksheetItemToEdit: WorksheetItem; // only used for storage areas
  @Input() isEdit = false;
  @Input() isAdd = false;
  @Input() isDuplicate = false;
  @Input() isManage = false;
  @Input() isDirectCustomItemAdd;
  @Output() itemAdded = new EventEmitter<CountableItem>();
  @Output() back = new EventEmitter<number>();
  @Output() closeEmitter = new EventEmitter();
  itemInfoExpanded = true;
  purchaseUnitExpanded = true;
  countingUnitExpanded = true;
  unitOfMeasureExpanded = true;
  addToWorksheetExpanded = true;
  itemInfoForm: UntypedFormGroup;
  purchaseUnitForm: UntypedFormGroup;
  addToWorksheetForm: UntypedFormGroup;
  allEntitlements$ = this.appStore.select(appStore => appStore.auth.entitlements);
  pk$ = this.addItemsCtx.pk$;
  currentLang$ = this.addItemsCtx.currentLang$;
  worksheet$ = this.addItemsCtx.selectedWorksheet$.pipe(
    map((ws) => (this.isManage && ws?.status !== 'open' ? null : ws))
  );
  isSavingWorksheet$ = this.addItemsCtx.isSavingWorksheet$;
  itemCategories$ = this.store.select(
    (state) => state.addItemsFeature.itemCategories
  );
  createdItem$ = this.store.select((state) => state.addItemsFeature.customItem);
  isAddingItem$ = this.store.select(
    (state) => state.addItemsFeature.isAddingCustomItem
  );
  isSavingAddItems$ = this.store.select(
    (state) => state.addItemsFeature.isSaving
  );
  weightUnits$ = this.store
    .select((state) => state.addItemsFeature.measurementUnits)
    .pipe(
      map((units) =>
        (units ?? []).filter(
          (unit) =>
            unit.measurementType === 'weight' &&
            unit.measurementSystem !== 'imperial'
        )
      )
    );
  volumeUnits$ = this.store
    .select((state) => state.addItemsFeature.measurementUnits)
    .pipe(
      map((units) =>
        (units ?? []).filter(
          (unit) =>
            unit.measurementType === 'volume' &&
            unit.measurementSystem !== 'imperial'
        )
      )
    );
  customItemData$ = this.addItemsCtx.customItemData$.pipe(
    map((customItemData) => {
      const data = customItemData?.find(
        (d) =>
          d.itemId === this.countableItem?.customItem?.id &&
          d.itemType === 'CUSTOM'
      );
      return data ? [data] : [];
    })
  );

  suppliers$: Observable<Supplier[]> = this.store.select(
    (state) => {
      if (state.addItemsFeature.suppliers) {
        return state.addItemsFeature.suppliers.sort((s1, s2) => s1.ordinal - s2.ordinal);
      }
    }
  );

  customItems$: Observable<CustomItem[]> = this.appStore
    .select(appState => appState.customerItems.customItems)
    .pipe(
      filter(items => !!items),
      map(items => items.filter(item => !item.deleted))
    );

  isRecipeRole$: Observable<boolean>;
  isInventoryRole$: Observable<boolean>;
  purchaseUnitList = [this.translate.instant('ADD_ITEMS.CASE')];
  filteredCategories$: Observable<ItemCategory[]>;
  filteredStorageAreas$: Observable<StorageArea[]>;
  filteredWeightUnits$: Observable<MeasurementUnit[]>;
  filteredPurchaseUnits$: Observable<string[]>;
  filteredPrimaryCountingUnits$: Observable<string[]>;
  filteredSuppliers$: Observable<Supplier[]>;
  lastValidCategory = null;
  lastValidCatchWeightUnit: MeasurementUnit = null;
  lastValidPurchaseUnit: string;
  isAddingItem = false;
  isPricedByWeight = false;
  purchaseInputRegex: any;
  isAllowedToEdit = true;
  supplierNameForEdit: string;
  isIos: any;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private store: Store<IFeatureStateFacade>,
    private appStore: Store<AppState>,
    private storageAreaUtils: StorageAreaUtilsService,
    private localizedValueUtils: LocalizedValueUtilsService,
    public platform: Platform,
    private customUnitUtil: CustomUnitUtilsService,
    @Inject(InjectionTokens.IAddItemsFeatureBridge) private addItemsCtx: IAddItemsFeatureBridge
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new GetSuppliersAttempt());
    this.store.dispatch(new GetAllCustomItemsAttempt());
    this.store.dispatch(new GetAllGeneralItemsAttempt());
    this.isIos = this.platform.IOS && this.platform.SAFARI;

    this.isRecipeRole$ = this.allEntitlements$.pipe(
      filter((perms) => !!perms),
      first(),
      map(perms => this.hasRole(perms, InventoryConstants.RECIPE_OKTA_ROLES))
    );
    this.isInventoryRole$ = this.allEntitlements$.pipe(
      filter((perms) => !!perms),
      first(),
      map(perms => this.hasRole(perms, InventoryConstants.INVENTORY_OKTA_ROLES)));

    if (this.isDirectCustomItemAdd === undefined) {
      this.isDirectCustomItemAdd = false;
    }

    let isLoading = true;
    let storageAreaValidator = null;

    this.worksheet$
      .pipe(
        filter((ws) => !!ws),
        first(),
        catchError((err) => of({} as Worksheet))
      )
      .subscribe((ws) => {
        if (ws.status === 'open') {
          storageAreaValidator = StorageAreaValidator.validate(
            this.isSavingWorksheet$,
            of(ws),
            this.storageAreaTranslate(),
            true
          );
        }
      });
    const categoryValidator = CategoryValidator.validate(
      this.itemCategories$,
      this.isSavingAddItems$,
      true
    );
    this.itemInfoForm = this.formBuilder.group({
      itemName: new UntypedFormControl('', [
        Validators.required,
        this.itemNameValidator(),
      ]),
      supplier: new UntypedFormControl('', [
        Validators.required,
        this.supplierValidator()
      ]),
      itemNumber: new UntypedFormControl('', [
        this.itemNumberValidator()
      ]),
      category: new UntypedFormControl('', null, categoryValidator),
    });

    this.purchaseUnitForm = this.formBuilder.group({
      purchaseUnit: new UntypedFormControl(
        this.purchaseUnitList[0],
        this.purchaseUnitValidator()
      ),
      purchasePrice: new UntypedFormControl('', this.priceAndWeightValidator()),
      catchWeightPrice: new UntypedFormControl('', this.priceAndWeightValidator()),
      catchWeightUnit: new UntypedFormControl(
        '',
        null,
        this.catchWeightUnitValidator()
      ),
      netWeight: new UntypedFormControl('', [
        this.priceAndWeightValidator(),
        this.netWeightValidator(),
      ]),
      pricedByWeight: new UntypedFormControl(false),
      volumeUnit: new UntypedFormControl(''),
      netVolume: new UntypedFormControl(''),
      countingUnits: this.formBuilder.array([])
    });
    this.addToWorksheetForm = this.formBuilder.group({
      addToWorksheet: new UntypedFormControl(true),
      storageArea: new UntypedFormControl(
        this.storageAreaTranslate()('Unassigned'),
        null,
        storageAreaValidator
      ),
    });

    this.store.dispatch(new GetItemCategoriesAttempt());
    this.store.dispatch(new GetMeasurementUnitsAttempt());
    this.store.dispatch(new GetSuppliersAttempt());

    this.pk$
      .pipe(
        filter((pk) => !!pk),
        first()
      )
      .subscribe((pk) => {
        this.isAllowedToEdit =
          !this.isEdit ||
          this.countableItem?.customItem?.customerPK?.customerId ===
          pk.customerId;
        if (!this.isAllowedToEdit) {
          this.purchaseUnitForm.disable();
          this.itemInfoForm.controls.itemName.disable();
          this.itemInfoForm.controls.itemNumber.disable();
        }
      });

    this.currentLang$.pipe(first()).subscribe((lang) => {
      this.purchaseInputRegex =
        InventoryConstants.PURCHASE_UNIT_LOCALIZED_REGEX[lang] ||
        InventoryConstants.PURCHASE_UNIT_LOCALIZED_REGEX.en_CA;
    });

    this.filteredStorageAreas$ = this.addToWorksheetForm.controls.storageArea.valueChanges.pipe(
      startWith(''),
      mergeMap((value) => this.filterStorageAreas(value)),
      map((areas) =>
        areas?.map((area) => {
          area.name = this.storageAreaTranslate()(area.name);
          return area;
        })
      )
    );
    this.filteredCategories$ = this.itemInfoForm.controls.category.valueChanges.pipe(
      startWith(''),
      mergeMap((value) => this.filterCategories(value)),
      map((categories) => sortBy(categories, 'name'))
    );
    this.filteredWeightUnits$ = this.purchaseUnitForm.controls.catchWeightUnit.valueChanges.pipe(
      startWith(''),
      mergeMap((value) => this.filterWeightUnits(value)),
      map((units) => sortBy(units, ['measurementSystem', 'size']))
    );
    this.filteredPurchaseUnits$ = this.purchaseUnitForm.controls.purchaseUnit.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterPurchaseUnits(value))
    );
    this.filteredSuppliers$ = this.itemInfoForm.controls.supplier.valueChanges.pipe(
      startWith(''),
      mergeMap((value) => this.filterSuppliers(value))
    );

    // ensure custom item is not a duplicate before returning to previous page
    this.createdItem$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((item) => !!item && !!item.generalItem)
      )
      .subscribe((item) => {
        if (this.isManage || this.isEdit) {
          this.onClose();
        } else {
          this.itemAdded?.emit(item);
          this.goBack(true);
        }
      });

    if (!!this.countableItem) {
      if (this.currentStorageAreaId !== undefined) {
        this.filteredStorageAreas$.pipe(first()).subscribe((storageAreas) => {
          const storageArea = storageAreas.find(
            (area) => area.id === this.currentStorageAreaId
          );
          if (storageArea && isLoading) {
            this.addToWorksheetForm.controls.storageArea.setValue(
              storageArea.name
            );
            this.addToWorksheetForm.controls.storageArea.markAsPristine();
            isLoading = false;
          }
        });
      } else {
        this.filteredStorageAreas$.pipe(first()).subscribe((storageAreas) => {
          const storageArea = storageAreas.find(
            (area) => area.id === this.countableItem.customItem.storageAreaId
          );
          if (storageArea && isLoading) {
            this.addToWorksheetForm.controls.storageArea.setValue(
              storageArea.name
            );
            this.addToWorksheetForm.controls.storageArea.markAsPristine();
            isLoading = false;
          }
        });
      }
      if (this.isDuplicate) {
        this.itemInfoForm.controls.itemName.setValue(
          this.translate.instant('ADD_ITEMS.COPY_OF', { itemName: this.countableItem.customItem.description })
        );
        this.itemInfoForm.controls.itemName.markAsTouched();
        this.itemInfoForm.controls.itemNumber.setValue(null);
        this.itemInfoForm.controls.itemNumber.markAsTouched();
      } else {
        this.itemInfoForm.controls.itemName.setValue(
          this.countableItem.customItem.description
        );
        this.itemInfoForm.controls.itemNumber.setValue(
          this.countableItem.customItem.supplierItemCode
        );
      }
      this.isPricedByWeight = this.countableItem.customItem.purchaseUnit.custom.catchWeight;
      this.purchaseUnitForm.controls.pricedByWeight.setValue(
        this.countableItem.customItem.purchaseUnit.custom.catchWeight
      );

      // we need to wait fill in unit ids once we have received the units
      // from the back end or else they will not display correctly in the modal
      this.weightUnits$.pipe(
        filter(units => units?.length > 0),
        first()
      ).subscribe(_ => {
        this.purchaseUnitForm.controls.catchWeightUnit.setValue(
          this.countableItem.customItem.purchaseUnit.custom.weightUnitId
        );
      });
      this.volumeUnits$.pipe(
        filter(units => units?.length > 0),
        first()
      ).subscribe(_ => {
        this.purchaseUnitForm.controls.volumeUnit.setValue(
          this.countableItem.customItem.purchaseUnit.custom.volumeUnitId
        );
      });
      this.currentLang$.pipe(first()).subscribe((lang) => {
        const locale = this.localizedValueUtils.getLanguage(lang);
        const netWeight = this.countableItem.customItem.purchaseUnit.custom
          .netWeight;
        const localizedWeight = netWeight
          ? Number(netWeight).toLocaleString(locale)
          : null;
        this.purchaseUnitForm.controls.netWeight.setValue(localizedWeight);
        const netVolume = this.countableItem.customItem.purchaseUnit.custom
          .netVolume;
        const localizedVolume = netVolume
          ? Number(netVolume).toLocaleString(locale)
          : null;
        this.purchaseUnitForm.controls.netVolume.setValue(localizedVolume);
        let localizedPrice = Number(
          this.countableItem.customItem.purchaseUnit.custom.price
        ).toLocaleString(locale);

        // we dont want to substitute NaN with 0, this will keep the ui input blank
        localizedPrice = localizedPrice === 'NaN' ? '' : localizedPrice;


        if (this.isPricedByWeight) {
          this.purchaseUnitForm.controls.catchWeightPrice.setValue(
            localizedPrice
          );
        } else {
          this.purchaseUnitForm.controls.purchasePrice.setValue(localizedPrice);
        }
      });

      this.suppliers$
        .pipe(
          filter(
            (suppliers) =>
              !!suppliers && (this.isEdit ? suppliers.length > 0 : true)
          ),
          map((suppliers) =>
            suppliers.find(
              (supp) => supp.id === this.countableItem.customItem.supplierId
            )
          ),
          first()
        )
        .subscribe((supplier) => {
          if (supplier) {
            this.itemInfoForm.controls.supplier.setValue(supplier.name);
            this.supplierNameForEdit = supplier.name;
            this.itemInfoForm.controls.supplier.markAsPristine();
          }
        });

      this.filteredCategories$
        .pipe(
          filter((cats) => !!cats && cats.length > 0),
          first()
        )
        .subscribe((categories) => {
          const category = categories.find(
            (cat) => cat.id === this.countableItem.generalItem?.categoryId
          );
          if (category) {
            this.itemInfoForm.controls.category.setValue(category.name);
            this.itemInfoForm.controls.category.markAsPristine();
          }
        });
    }

    if (!!this.focusSection) {
      setTimeout(() => {
        this.scroll(this.focusSection);
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearCustomItem());
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  canEditStorageArea(worksheet: Worksheet, pk: CustomerPK): boolean {
    return (
      !!worksheet?.storageAreas &&
      InventoryConstants.CUSTOMER_ENTITIES.includes(pk.entityType)
    );
  }

  filterStorageAreas(value: string): Observable<StorageArea[]> {
    const trimmedValue = trim(value);
    return combineLatest([this.worksheet$, this.pk$]).pipe(
      filter(([_, pk]) => !!pk),
      map(([ws, pk]) => {
        const storageAreas = this.canEditStorageArea(ws, pk)
          ? ws.storageAreas
          : [
            {
              id: null,
              name: this.storageAreaTranslate()('Unassigned'),
              expandStatus: null,
            },
          ];

        if (!trimmedValue) {
          return storageAreas;
        }

        return storageAreas.filter((area) => {
          const translatedName = this.storageAreaTranslate()(area.name);
          return translatedName
            .toLowerCase()
            .includes(trimmedValue.toLowerCase());
        });
      })
    );
  }

  filterCategories(value: string): Observable<any[]> {
    return this.itemCategories$.pipe(
      map((cats) =>
        value
          ? (cats ?? []).filter((cat) =>
            cat.name.toLowerCase().includes(value.toLowerCase())
          )
          : cats
      )
    );
  }

  filterWeightUnits(value: string): Observable<MeasurementUnit[]> {
    return this.currentLang$.pipe(
      mergeMap((lang) =>
        this.weightUnits$.pipe(
          map((units) => {
            if (value) {
              return units.filter((unit) =>
                this.getUnitDisplayName(unit, lang)
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            }
            return units;
          })
        )
      )
    );
  }

  filterSuppliers(value: string): Observable<Supplier[]> {
    return this.suppliers$.pipe(
      map((suppliers) =>
        value
          ? (suppliers ?? []).filter((sup) =>
            sup.name.toLowerCase().includes(value.toLowerCase())
          )
          : suppliers
      )
    );
  }

  filterPurchaseUnits(value: string): string[] {
    return this.purchaseUnitList.filter((unit) =>
      unit.toLowerCase().includes(value.toLowerCase())
    );
  }

  getLocalizedUnitSymbol(unit: MeasurementUnit, lang: string): string {
    return this.localizedValueUtils.getLocalizedMeasurementUnitSymbol(
      unit,
      lang
    );
  }

  getUnitDisplayName(unit: MeasurementUnit, lang: string): string {
    return this.localizedValueUtils.getUnitDisplayName(unit, lang);
  }

  itemNameAndSupplierUnchangedOnEdit(itemName: string, supplier: string): boolean {
    return this.isEdit &&
      itemName === this.countableItem.customItem.description &&
      supplier === this.supplierNameForEdit;
  }

  itemNumberAndSupplierUnchangedOnEdit(itemNumber: string, supplier: string): boolean {
    return this.isEdit &&
      itemNumber === this.countableItem.customItem.supplierItemCode &&
      supplier === this.supplierNameForEdit;
  }

  itemNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control && control.value) {
        const val = control.value;
        if (val.trim().length === 0) {
          return { emptyName: true };
        } else if (this.itemNameAndSupplierUnchangedOnEdit(val.trim(), this.itemInfoForm.controls.supplier.value)) {
          return null;
        } else if (this.isDuplicateItemNameUnderSupplier(val.trim(), this.itemInfoForm.controls.supplier.value)) {
          return { duplicateItemName: true };
        }
      } else {
        return { emptyName: true };
      }
      return null;
    };
  }

  supplierValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control && control.value) {
        const val = control.value;
        if (val.trim().length === 0) {
          return null;
        } else {
          if (this.itemNameAndSupplierUnchangedOnEdit(this.itemInfoForm.controls.itemName.value, val.trim())
            && this.itemNumberAndSupplierUnchangedOnEdit(this.itemInfoForm.controls.itemNumber.value, val.trim())) {
            return null;
          }
          if (this.isDuplicateItemNameUnderSupplier(this.itemInfoForm.controls.itemName.value, val.trim())) {
            this.itemInfoForm.controls.itemName.setErrors({ duplicateItemName: true });
          } else if (this.itemInfoForm.controls.itemName.hasError('duplicateItemName')) {
            this.itemInfoForm.controls.itemName.setErrors({ duplicateItemName: null });
            this.itemInfoForm.controls.itemName.updateValueAndValidity();
          }
          if (this.isDuplicateItemNumberUnderSupplier(this.itemInfoForm.controls.itemNumber.value, val.trim())) {
            this.itemInfoForm.controls.itemNumber.setErrors({ duplicateItemNumber: true });
          } else if (this.itemInfoForm.controls.itemNumber.hasError('duplicateItemNumber')) {
            this.itemInfoForm.controls.itemNumber.setErrors({ duplicateItemNumber: null });
            this.itemInfoForm.controls.itemNumber.updateValueAndValidity();
          }
          return null;
        }
      } else {
        return null;
      }
    };
  }

  itemNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control && control.value) {
        const val = control.value;
        if (val.trim().length === 0) {
          return null; // empty is allowed
        } else if (this.itemNumberAndSupplierUnchangedOnEdit(val.trim(), this.itemInfoForm.controls.supplier.value)) {
          return null;
        } else if (this.isDuplicateItemNumberUnderSupplier(val.trim(), this.itemInfoForm.controls.supplier.value)) {
          return { duplicateItemNumber: true };
        }
      } else {
        return null; // empty is allowed
      }
      return null;
    };
  }

  isDuplicateItemNameUnderSupplier(itemName: string, supplierName: string): boolean {
    let returnVal: boolean;
    if ( (this.countableItem?.customItem?.description.toLowerCase() === itemName.toLowerCase()) && !this.isDuplicate) {
      returnVal = false;
    } else {
      this.suppliers$.pipe(first()).forEach((suppliers) => {
        const supplier = suppliers.find(
          (supp) => supp.name === supplierName
        );
        if (supplier && itemName) {
          this.customItems$.pipe(first()).forEach((customItems) => {
            const customItem = customItems.find(
              (customIt) => (customIt?.supplierId.toLowerCase() === supplier.id.toLowerCase() 
              && customIt?.description.toLowerCase() === itemName.toLowerCase())
            );
            if (customItem) {
              returnVal = true;
            }
          });
        }
      });
    }
    
    return returnVal;
  }

  isDuplicateItemNumberUnderSupplier(itemNumber: string, supplierName: string): boolean {
    let returnVal: boolean;
    if((this.countableItem?.customItem?.supplierItemCode?.toLowerCase() === itemNumber?.toLowerCase()) && !this.isDuplicate){
      returnVal = false;
    } else {
      this.suppliers$.pipe(first()).forEach((suppliers) => {
        const supplier = suppliers.find(
          (supp) => supp.name === supplierName
        );
        if (supplier && itemNumber) {
          this.customItems$.pipe(first()).forEach((customItems) => {
            const customItem = customItems.find(
              (customIt) => (customIt?.supplierId.toLowerCase() === supplier.id.toLowerCase() 
                && customIt?.supplierItemCode?.toLowerCase() === itemNumber?.toLowerCase())
            );
            if (customItem) {
              returnVal = true;
            }
          });
        }
      });
    }
    return returnVal;
  }

  priceAndWeightValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        control &&
        control.value &&
        !control.value.match(this.purchaseInputRegex.VALID_CURRENCY_REGEX)
      ) {
        return { invalidInput: true };
      }
      return null;
    };
  }

  catchWeightUnitValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      if (control) {
        return this.weightUnits$.pipe(
          first(),
          map((units) => {
            const val = control.value;
            if (!val || val.trim().length === 0) {
              this.lastValidCatchWeightUnit = null;
              const qty = this.purchaseUnitForm?.controls?.netWeight?.value;
              const isCatchWeight = this.purchaseUnitForm?.controls
                ?.pricedByWeight?.value;
              if ((!qty || qty.trim().length === 0) && !isCatchWeight) {
                return null;
              } else {
                return { invalidCatchWeightUnit: true };
              }
            } else {
              const selectedUnit = units.find(
                (unit) =>
                  unit.id.toLowerCase().trim() ===
                  control.value.toLowerCase().trim()
              );
              if (selectedUnit) {
                this.lastValidCatchWeightUnit = selectedUnit;
                return null;
              } else {
                return { invalidCatchWeightUnit: true };
              }
            }
          })
        );
      } else {
        return of({ invalidCatchWeightUnit: true });
      }
    };
  }

  netWeightValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control) {
        const val = control.value;
        const unit = this.purchaseUnitForm?.controls?.catchWeightUnit?.value;
        const isCatchWeight = this.purchaseUnitForm?.controls?.pricedByWeight
          ?.value;
        if (
          (!val || val.trim().length === 0) &&
          !isCatchWeight &&
          unit?.trim().length > 0
        ) {
          return { invalidNetWeight: true };
        }
        return null;
      }
    };
  }

  purchaseUnitValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control && control.value) {
        const validUnit = this.purchaseUnitList.find(
          (unit) => unit === control.value
        );
        if (validUnit) {
          this.lastValidPurchaseUnit = validUnit;
          return null;
        }
      }
      return { invalidPrimaryUnit: true };
    };
  }

  createSupplier(name: string) {
    this.store.dispatch(new CreateSupplierAttempt({ name }));
  }

  supplierSelected(event: any) {
    this.itemInfoForm.controls.itemName.markAsTouched();
    this.itemInfoForm.controls.itemNumber.markAsTouched();
    this.itemInfoForm.controls.itemName.updateValueAndValidity();
    this.itemInfoForm.controls.itemNumber.updateValueAndValidity();
  }

  createStorageArea(value: string) {
    this.worksheet$
      .pipe(
        first(),
        filter((ws) => !!ws)
      )
      .subscribe((ws) => {
        this.store.dispatch(
          new CreateStorageAreaName({
            worksheetId: ws.id,
            storageAreaName: value,
          })
        );
      });
  }

  storageAreaTranslate() {
    return (currentValue: string): string =>
      this.translate.instant(
        this.storageAreaUtils.getTranslation(currentValue)
      );
  }

  onItemNameBlur() {
    this.itemInfoForm.controls.itemName.setValue(
      this.itemInfoForm.controls.itemName.value.trim()
    );
  }

  onItemNumberBlur() {
    this.itemInfoForm.controls.itemNumber.setValue(
      this.itemInfoForm.controls.itemNumber.value?.trim()
    );
    this.markFormAsUntouched(this.itemInfoForm.controls.itemNumber);
  }

  onCatchWeightUnitBlur(event) {
    this.purchaseUnitForm.controls.netWeight.updateValueAndValidity();
    if (get(event, 'relatedTarget.nodeName', null) !== 'MAT-OPTION') {
      this.purchaseUnitForm.controls.catchWeightUnit.setValue(
        this.lastValidCatchWeightUnit?.id
      );
      this.markFormAsUntouched(this.purchaseUnitForm.controls.catchWeightUnit);
    }
  }

  onNetWeightBlur() {
    this.purchaseUnitForm.controls.catchWeightUnit.updateValueAndValidity();
    this.markFormAsUntouched(this.purchaseUnitForm.controls.netWeight);
  }

  onPurchaseUnitBlur() {
    this.purchaseUnitForm.controls.purchaseUnit.setValue(
      this.lastValidPurchaseUnit
    );
  }

  unitIdToDisplayName(lang, units, unitId) {
    const unit = units.find((u) => u.id === unitId);
    return this.getUnitDisplayName(unit, lang);
  }

  // an empty form is styled differently than a filled in form, use this as a way to
  // check if the form is empty (using the .ng-touched class)
  markFormAsUntouched(control: AbstractControl) {
    if (!control.value) {
      control.markAsUntouched();
    }
  }

  onPricedByWeightSelect(event) {
    this.isPricedByWeight = event.value;
    this.purchaseUnitForm.controls.purchasePrice.setValue(null);
    this.purchaseUnitForm.controls.catchWeightPrice.setValue(null);
    this.purchaseUnitForm.controls.catchWeightUnit.updateValueAndValidity();
    this.purchaseUnitForm.controls.netWeight.updateValueAndValidity();
  }

  shouldShowWeightSymbol(id: string): boolean {
    if (get(this.purchaseUnitForm, 'controls.netWeight.value', false)) {
      return this.purchaseUnitForm.controls.netWeight.value.length > 0;
    }
    if (document.getElementById(id) === document.activeElement) {
      return true;
    }
    return false;
  }

  shouldShowDollarSign(id: string): boolean {
    if (this.isPricedByWeight) {
      if (
        get(this.purchaseUnitForm, 'controls.catchWeightPrice.value', false)
      ) {
        return this.purchaseUnitForm.controls.catchWeightPrice.value.length > 0;
      }
    } else {
      if (get(this.purchaseUnitForm, 'controls.purchasePrice.value', false)) {
        return this.purchaseUnitForm.controls.purchasePrice.value.length > 0;
      }
    }
    if (document.getElementById(id) === document.activeElement) {
      return true;
    }
    return false;
  }

  convertValueToNumber(value: string): string {
    return value
      ? value.replace(
        this.purchaseInputRegex.REPLACE_REGEX,
        (match) => this.purchaseInputRegex.REPLACEMENTS[match]
      )
      : null;
  }

  onAddItem(newItemData) {
    this.isAddingItem = true;
    this.worksheet$.pipe(
      first(),
      withLatestFrom(this.itemCategories$, this.currentLang$, this.customItemData$),
    ).subscribe(([ws, categories, lang, itemData]) => {
      let storageArea = null;
      if (ws?.storageAreas) {
        storageArea = ws.storageAreas.find(area =>
          this.storageAreaTranslate()(area.name) === this.addToWorksheetForm.controls.storageArea.value
        );
        if (!storageArea) {
          storageArea = ws.storageAreas.find(sa => sa.id === 'unassigned') || ws.storageAreas[0];
        }
      }
      const category = categories.find(
        (cat) => cat.name === this.itemInfoForm.controls.category.value
      );

      let price = this.isPricedByWeight
        ? this.purchaseUnitForm.controls.catchWeightPrice.value
        : this.purchaseUnitForm.controls.purchasePrice.value;
      if (price) {
        price = this.localizedValueUtils.parseNumber(price, lang).toString();
      } else {
        price = null;
      }
      const hasEditedRecipeUnits = this.hasEditedRecipeUnits(itemData[0]);
      const recipeUnits = hasEditedRecipeUnits ? this.createRecipeUnitList(itemData[0], this.purchaseUnitForm) : null;

      const pUnit: PurchaseUnit = {
        custom: {
          name: 'Case',
          price,
          catchWeight: this.isPricedByWeight,
          netWeight: this.convertValueToNumber(
            this.purchaseUnitForm.controls.netWeight.value
          ),
          weightUnitId: this.lastValidCatchWeightUnit?.id,
          netVolume: this.convertValueToNumber(
            this.purchaseUnitForm.controls.netVolume.value
          ),
          volumeUnitId:
            this.purchaseUnitForm.controls.volumeUnit.value || null,
        },
      };
      this.suppliers$.pipe(first()).subscribe((suppliers) => {
        const supplier = suppliers.find(
          (supp) => supp.name === this.itemInfoForm.controls.supplier.value
        );

        this.store.dispatch(new SaveCustomItemStart());
        this.store.dispatch(
          new CreateCustomItemAttempt({
            supplierItemCode: this.itemInfoForm.controls.itemNumber.value
              ? this.itemInfoForm.controls.itemNumber.value.trim()
              : null,
            supplierId: supplier?.id,
            description: this.itemInfoForm.controls.itemName.value
              ? this.itemInfoForm.controls.itemName.value.trim()
              : null,
            purchaseUnit: pUnit,
            categoryId: category?.id,
            worksheetId: ws?.id,
            storageAreaId: storageArea?.id,
            areaExpandStatus: !this.isManage
              ? storageArea?.expandStatus
              : null,
            successMessage: 'MESSAGES.ITEM_ADDED_SUCCESSFULLY',
            duplicate: false,
            customUnits: newItemData.customUnits ?? [],
            recipeUnits: recipeUnits ?? [],
            addToWorksheet: this.addToWorksheetForm.controls.addToWorksheet
              .value,
            isManage: this.isManage,
          })
        );
      });
    });
  }

  editStorageArea(storageArea: StorageArea) {
    if (!this.worksheetItemToEdit) {
      return;
    }
    this.worksheet$
      .pipe(
        filter((ws) => !!ws && !!ws.storageAreas),
        first()
      )
      .subscribe((ws) => {
        this.store.dispatch(
          new DeleteInventoryItemAttempt({
            worksheetId: ws.id,
            storageAreaId: this.currentStorageAreaId,
            inventoryItem: this.worksheetItemToEdit,
          })
        );
        this.store.dispatch(
          new DeleteInventoryItem({
            worksheetId: ws.id,
            storageAreaId: this.currentStorageAreaId,
            inventoryItem: this.worksheetItemToEdit,
          })
        );

        const worksheetItemPost: WorksheetItemPost = {
          itemId: this.countableItem.generalItem.id,
          itemType: this.worksheetItemToEdit.itemType,
          primaryUnit: this.worksheetItemToEdit.primaryUnit,
          primaryUnitQty: this.worksheetItemToEdit.primaryUnitQty
            ? this.worksheetItemToEdit.primaryUnitQty.toString()
            : '',
          primaryUnitType: this.worksheetItemToEdit.primaryUnitType,
          secondaryUnit: this.worksheetItemToEdit.secondaryUnit,
          secondaryUnitQty: this.worksheetItemToEdit.secondaryUnitQty
            ? this.worksheetItemToEdit.secondaryUnitQty.toString()
            : '',
          secondaryUnitType: this.worksheetItemToEdit.secondaryUnitType,
        };

        this.localizedValueUtils
          .getLocalizedProductDescription(this.countableItem, this.currentLang$)
          .pipe(first())
          .subscribe((desc) => {
            this.store.dispatch(
              new CreateWorksheetItemAttempt({
                worksheetId: ws.id,
                storageAreaId: storageArea.id,
                worksheetItemPost,
                areaExpandStatus: storageArea.expandStatus,
                itemDescription: desc,
                successMessage: 'MESSAGES.ITEM_ADDED_SUCCESSFULLY',
                duplicate: false,
              })
            );
          });
      });
  }

  onEditItem(newItemData) {
    const customItemPatch: CustomItemUpdate = {};
    this.currentLang$.pipe(first()).subscribe((lang) => {
      if (this.itemInfoForm.controls.itemName.dirty) {
        customItemPatch.description = get(
          this.itemInfoForm,
          'controls.itemName.value',
          ''
        );
      }

      if (this.itemInfoForm.controls.itemNumber.dirty) {
        customItemPatch.supplierItemCode =
          this.itemInfoForm?.controls?.itemNumber?.value || null;
      }

      if (this.itemInfoForm.controls.supplier.dirty) {
        this.suppliers$.pipe(first()).subscribe((suppliers) => {
          const supplier = suppliers.find(
            (supp) => supp.name === this.itemInfoForm.controls.supplier.value
          );
          customItemPatch.supplierId = supplier.id;
        });
      }

      if (this.hasEditedPurchaseUnit()) {
        let price = this.isPricedByWeight ?
          get(this.purchaseUnitForm, 'controls.catchWeightPrice.value', false) :
          get(this.purchaseUnitForm, 'controls.purchasePrice.value', false);
        if (price) {
          price = this.localizedValueUtils.parseNumber(price, lang).toString();
        } else {
          price = null;
        }
        customItemPatch.purchaseUnit = {
          custom: {
            catchWeight: this.isPricedByWeight,
            name: get(this.purchaseUnitForm, 'controls.purchaseUnit.value', ''),
            netVolume: this.convertValueToNumber(
              this.purchaseUnitForm.controls.netVolume.value
            ),
            netWeight: this.convertValueToNumber(
              get(this.purchaseUnitForm, 'controls.netWeight.value', '')
            ),
            price,
            volumeUnitId:
              this.purchaseUnitForm.controls.volumeUnit.value || null,
            weightUnitId: this.lastValidCatchWeightUnit?.id,
          },
        };
      }

      if (this.addToWorksheetForm.controls.storageArea.dirty) {
        this.filteredStorageAreas$.pipe(first()).subscribe((storageAreas) => {
          const storageArea = storageAreas.find(
            (area) =>
              area.name === this.addToWorksheetForm.controls.storageArea.value
          );
          if (storageArea) {
            customItemPatch.storageAreaId = storageArea.id;
          }
        });
      }

      if ((Object.keys(customItemPatch).length > 0) && this.isAllowedToEdit) {
        this.store.dispatch(new SaveCustomItemStart());
        this.store.dispatch(
          new UpdateCustomItemsAttempt({
            customItemId: this.countableItem.customItem.id,
            generalItemId: this.countableItem.generalItem.id,
            customItemPatch,
          })
        );
      }

      const generalItemPatch: GeneralItemUpdate = {};
      if (this.itemInfoForm.controls.category.dirty) {
        this.filteredCategories$.pipe(first()).subscribe((categories) => {
          const category = categories.find(
            (cat) =>
              cat.name ===
              get(this.itemInfoForm, 'controls.category.value', false)
          );
          if (category) {
            generalItemPatch.categoryId = category.id;
          }
        });
      }

      if (Object.keys(generalItemPatch).length > 0) {
        this.store.dispatch(
          new UpdateGeneralItemsAttempt({
            generalItemId: this.countableItem.generalItem.id,
            generalItemPatch,
          })
        );
      }

      this.itemAdded.emit({
        ...this.countableItem,
        customItem: {
          ...this.countableItem.customItem,
          ...customItemPatch,
        },
        generalItem: {
          ...this.countableItem.generalItem,
          ...generalItemPatch,
        },
      });

      this.customItemData$
        .pipe(
          filter((itemData) => !!itemData),
          first()
        )
        .subscribe((itemData) => {
          const hasEditedRecipeUnits = this.hasEditedRecipeUnits(itemData[0]);
          const recipeUnits = hasEditedRecipeUnits ? this.createRecipeUnitList(itemData[0], this.purchaseUnitForm) : null;

          if (newItemData.customUnits || hasEditedRecipeUnits) {
            if (itemData.length > 0) {
              this.store.dispatch(new UpdateCustomItemDataAttempt({
                customItemDataId: itemData[0].id,
                countingUnits: newItemData.customUnits,
                categoryId: null,
                purchasedByUnit: false,
                purchaseUnit: null,
                recipeUnits
              }));
            } else {
              this.store.dispatch(new CreateCustomItemDataAttempt({
                itemId: this.countableItem.customItem.id,
                itemType: 'CUSTOM',
                countingUnits: newItemData.customUnits ?? [],
                categoryId: null,
                purchasedByUnit: false,
                purchaseUnit: null,
                recipeUnits: recipeUnits ?? []
              }));
            }
          }
          this.onClose();
        });

      if (
        this.isDirectCustomItemAdd &&
        this.addToWorksheetForm.controls.addToWorksheet.value
      ) {
        combineLatest([this.filteredStorageAreas$, this.worksheet$])
          .pipe(first())
          .subscribe(([storageAreas, worksheet]) => {
            const storageArea = storageAreas.find(
              (area) =>
                area.name === this.addToWorksheetForm.controls.storageArea.value
            );
            const worksheetItemPost: WorksheetItemPost = {
              itemId: this.countableItem.generalItem.id,
              itemType: 'GENERAL',
              primaryUnit: 'CASE',
              primaryUnitQty: null,
              primaryUnitType: 'LITERAL',
              secondaryUnit: 'metric_weight_kg',
              secondaryUnitQty: null,
              secondaryUnitType: 'STANDARD',
            };
            this.store.dispatch(
              new CreateWorksheetItemAttempt({
                worksheetId: worksheet?.id,
                storageAreaId: storageArea?.id ?? 'unassigned',
                areaExpandStatus: true,
                worksheetItemPost,
                itemDescription: this.itemInfoForm.controls.itemName.value,
                successMessage: 'MESSAGES.ITEM_ADDED_SUCCESSFULLY',
                duplicate: false,
              })
            );
          });
      }
    });
  }

  goBack(itemAdded: boolean) {
    this.back.emit(itemAdded ? 1 : 0);
  }

  onClose() {
    this.closeEmitter.emit(null);
  }

  createCategory(value) {
    this.currentLang$.pipe(first()).subscribe((languageCode) =>
      this.store.dispatch(
        new CreateCategoryAttempt({
          categoryName: {
            languageCode,
            value,
          },
        })
      )
    );
  }

  hasEditedPurchaseUnit() {
    const controls = this.purchaseUnitForm.controls;
    return controls.purchaseUnit.dirty || controls.purchasePrice.dirty || controls.catchWeightPrice.dirty
      || controls.catchWeightUnit.dirty || controls.netWeight.dirty || controls.pricedByWeight.dirty
      || controls.volumeUnit.dirty || controls.netVolume.dirty;
  }

  hasEditedRecipeUnits(itemData: CustomItemData): boolean {
    return this.customUnitUtil.hasEditedRecipeUnits(itemData, this.purchaseUnitForm);
  }

  createRecipeUnitList(itemData: CustomItemData, form: UntypedFormGroup): PortioningUnit[] {
    return this.customUnitUtil.createRecipeUnitList(itemData, form);
  }

  scroll(targetElement: string) {
    document.getElementsByClassName(targetElement)[0].scrollIntoView();
  }

  hasRole(perms: Entitlement[], role: string): boolean {
    return !!perms.find(entitlement => {
      if (entitlement.roleName.indexOf(role) > -1) {
        return true;
      }
      return false;
    });
  }
}
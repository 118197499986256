import { formatDate } from '@angular/common';
import {
  AfterViewChecked, Component,
  ElementRef, OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenavContent } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { AddItemDialogComponent } from '@gfs/shared-components';
import {
  CountableItem,
  MeasurementUnit, StorageArea, Worksheet,
  WorksheetPrintInfo
} from '@gfs/shared-models';
import { AppConfigService, InventoryValueService, InventoryWorksheetStatusEnum, ItemTextSearchService, MessageService, WorksheetService } from '@gfs/shared-services';
import { isTruthy, pushToSubject } from '@gfs/shared-services/extensions/rxjs';
import {
  CreateStorageAreaName, PatchStorageArea,
  PatchWorksheetAttempt,
  SetInfiniteScrollingToggleState,
  SetIsEditingName,
  SetStorageAreaExpandStatus
} from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { ComponentCanDeactivate } from '@inventory-ui/services/guards/pending-changes.guard';
import { WorksheetPrintService } from '@inventory-ui/services/shared/worksheet-print/worksheet-print.service';
import { ComponentIsSaving } from '@inventory-ui/v2/common/guards/save-in-progress.guard';
import { WorksheetDataService } from '@inventory-ui/v2/common/services/worksheet-data.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  forkJoin,
  of
} from 'rxjs';
import {
  catchError,
  concatMap, filter, first,
  map, switchMap, takeUntil, tap, toArray, withLatestFrom
} from 'rxjs/operators';
import { PrintWorksheetDialogComponent } from '../print-worksheet-dialog/print-worksheet-dialog.component';
import { StorageAreaCreateModalComponent } from '../storage-area/storage-area-create-modal/storage-area-create-modal.component';
import { StorageAreaListComponent } from '../storage-area/storage-area-list/storage-area-list.component';

@Component({
  selector: 'app-inventory-worksheet',
  templateUrl: './inventory-worksheet.component.html',
  styleUrls: ['./inventory-worksheet.component.scss'],
})
export class InventoryWorksheetComponent implements
  OnInit,
  OnDestroy,
  AfterViewChecked,
  ComponentCanDeactivate,
  ComponentIsSaving {

  get confirmMessage(): string {
    return this.translateService.instant(
      'INVENTORY_WORKSHEET.LEAVE_CONFIRMATION_MODAL'
    );
  }

  notifier = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private printService: WorksheetPrintService,
    private configService: AppConfigService,
    private inventoryValueService: InventoryValueService,
    private messageService: MessageService,
    private searchService: ItemTextSearchService,
    private worksheetService: WorksheetService,
    private overlay: LoadingSpinnerOverlayService,
    private worksheetDataService: WorksheetDataService
  ) { }

  isSpinnerActive$ = new BehaviorSubject(false);
  isLoadingDataNotifier$ = this.store.select(
    (state: any) => {
      return ([
        state.inventoryItems.loadingGeneralItems,
        state.inventoryItems.loadingInventoryItems,
        state.worksheets.loadingCustomItemData,
        state.worksheets.isRefreshingItems
      ]);
    }
  ).pipe(
    map(loadingFlags => {
      return loadingFlags.some(isLoading => !!isLoading);
    }),
    pushToSubject(this.isSpinnerActive$)
  );

  showSpinnerOverlayWhenDataIsLoading$ =
    this.isSpinnerActive$.pipe(
      tap(isLoading => {
        (
          isLoading
            ? this.overlay.show()
            : this.overlay.hide()
        );
      })
    );

  worksheet$: Observable<Worksheet> = this.store.select(
    (state) => state.worksheets.worksheets[state.worksheets.selected]
  );

  worksheetLastModifiedDate$ = this.createWorksheetLastModifiedDate$(this.worksheet$);


  isActiveInventory$: Observable<boolean> = this.worksheet$.pipe(
    map((worksheet: Worksheet) => {
      return worksheet.status === 'open';
    })
  );

  useInfiniteScrolling$: Observable<boolean> = this.store.select(
    (state) => state.worksheets.useInfiniteScrolling
  ).pipe(filter(v => v !== undefined));

  isOpen$: Observable<boolean> = this.worksheet$.pipe(
    map((worksheet: Worksheet) => worksheet.status === 'open')
  );

  currentLang$ = this.store.select((state) => state.layout.language);
  isMobile$: Observable<boolean> = this.store.select(
    (state) => state.layout.isMobile
  );
  isEditingName$: Observable<boolean> = this.store.select(
    (state) => state.worksheets.isEditingName
  );
  editWorksheetNameForm: UntypedFormGroup;
  isSaving$: Observable<boolean> = this.store.select(
    (state) => state.worksheets.isSaving
  );
  itemFilterText$: Observable<string> = this.store.select(
    (state) => state.worksheets.itemFilterText
  );
  isOnline$: Observable<boolean> = this.store.select(
    (state) => state.network.isOnline
  );
  customerInfo$ = this.store.select((state: AppState) =>
    state.auth ? state.auth : null
  );
  printStorageAreas: StorageArea[] = [];
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;
  permissionRoles =
    InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;
  inventoryItems$ = this.store.select(
    (state) => state.inventoryItems.inventoryItems
  );
  standardMeasurementUnits$: Observable<MeasurementUnit[]> = this.store
    .select((state) => state.addItemsFeature.measurementUnits)
    .pipe(
      map((units) => (units ?? []).filter((unit) => unit.measurementType === 'weight'))
    );


  customItemData$ = this.store.select(
    (state) => state.worksheets.customItemData
  );
  scrollWidth: string;
  ITEM_CHANGE_SYNC_INTERVAL = 3000;

  @ViewChild('editNameInput') editNameInput: ElementRef;
  @ViewChild(MatSidenavContent) storageAreaContent: MatSidenavContent;
  @ViewChild(StorageAreaListComponent)

  storageAreaList: StorageAreaListComponent;

  closeWorkSheetInProgress$ = new BehaviorSubject<boolean>(false);
  closeWorkSheetCompleted$ = new BehaviorSubject<null | boolean>(null);
  DoneButtonText$ = this.createDoneButtonTextSource$();

  toggleInfiniteScrolling(state) {
    this.store.dispatch(new SetInfiniteScrollingToggleState(state.checked));
  }
  createWorksheetLastModifiedDate$(worksheet$: Observable<Worksheet>) {
    return combineLatest([
      worksheet$.pipe(isTruthy(), map(w => w.lastModified)),
      this.store.select(
        (state: any) => state.worksheets.activeWorksheetLastModified as string
      )
    ]).pipe(
      map(r => r.filter(x => !!x).pop()));
  }

  getSavingNotifier() {
    return {
      saveInProgressNotifier$: combineLatest([
        this.worksheetService.muxUpdateWorksheetItemRequest.getInProgressNotifier$(),
        this.store.select(x => x.worksheets.isSaving)
      ]).pipe(
        map((inProgress) => inProgress.some(x => x)),
      ),
      onSaveComplete: this.worksheetService.muxUpdateWorksheetItemRequest
        .onComplete()
        .pipe(
          concatMap(x => forkJoin([
            this.closeWorkSheetInProgress$
              .pipe(
                first(),
                concatMap(isInProgress => {
                  // if its in progress we need to wait for it to complete
                  // otherwise we can bypass the worksheet close to gate navigation
                  return isInProgress
                    ? this.closeWorkSheetCompleted$
                      .pipe(
                        isTruthy(),
                        first(),
                      )
                    : of(true);
                }),
              ),
            this.store.select(state => !state.worksheets.isSaving)
              .pipe(
                filter(isNotSaving => isNotSaving),
                first(),
              )
          ])),
          map((completionResults) => !completionResults.some(isCompleted => !isCompleted)),
        )
    };
  }

  createDoneButtonTextSource$() {
    return combineLatest([
      of('INVENTORY.DONE'),
      this.closeWorkSheetInProgress$,
      this.worksheetService.muxUpdateWorksheetItemRequest.notifyPendingOperationsCount$
    ]).pipe(
      map(([
        defaultText,
        inProgress,
        remainingSaveOperations
      ]) => {

        if (inProgress) {
          if (remainingSaveOperations > 0) {
            const localizedText = this.translateService
              .instant('INVENTORY_WORKSHEET.SAVING');
            return `${localizedText} (${remainingSaveOperations})`;
          }
          return 'INVENTORY_WORKSHEET.SAVING';
        }

        return defaultText;
      })
    );
  }

  ngOnInit() {
    const importGate = this.dialog.getDialogById(InventoryConstants.LOADING_MODAL_ID + '_RESET_WORKSHEET');
    importGate?.close();

    combineLatest([
      this.isLoadingDataNotifier$,
      this.showSpinnerOverlayWhenDataIsLoading$
    ])
      .pipe(takeUntil(this.notifier))
      .subscribe();

    this.editWorksheetNameForm = this.formBuilder.group({
      name: '',
    });

    this.currentLang$
      .pipe(takeUntil(this.notifier))
      .subscribe((_) => {
        const name = this.editWorksheetNameForm.value.name;
        this.editWorksheetNameForm.setValue({
          name: this.translateName(name),
        });
      });

    this.printService.setUp(
      this.beforePrint.bind(this),
      this.afterPrint.bind(this)
    );

    this.isOnline$
      .pipe(takeUntil(this.notifier))
      .subscribe((isOnline) => {
        const hotjar = document.getElementById('_hj_feedback_container');
        if (hotjar) {
          if (isOnline) {
            hotjar.style.display = 'block';
          } else {
            hotjar.style.display = 'none';
          }
        }
      });

    const infiniteScrollSetting = this.configService.getInfiniteScrollSetting();
    this.toggleInfiniteScrolling({ checked: infiniteScrollSetting });
  }

  ngAfterViewChecked() {
    if (!!this.storageAreaContent && !this.scrollWidth) {
      const nativeElement = this.storageAreaContent.getElementRef()
        .nativeElement;
      if (nativeElement.scrollHeight > nativeElement.offsetHeight) {
        const normalw = nativeElement.offsetWidth;
        const scrollw = normalw - nativeElement.clientWidth;
        setTimeout(() => {
          this.scrollWidth = 32 - scrollw + 'px';
        }, 100);
      }
    }
  }

  ngOnDestroy() {
    this.printService.tearDown();
    this.notifier.next();
  }

  canDeactivate(): Observable<boolean> {
    if (this.configService.getSettings().FF_OFFLINE_MODE) {
      return this.isOnline$.pipe(
        first(),
        concatMap((isOnline) => {
          if (isOnline) {
            return this.worksheetService.muxUpdateWorksheetItemRequest.onComplete();
          }
          return of(false);
        })
      );
    }
    return of(true);
  }


  // if using the default name, translate it between english and french
  translateName(name: string): string {
    if (
      name.toLowerCase() === 'Untitled Inventory'.toLowerCase() ||
      name.toLowerCase() === `Inventaire sans titre`.toLowerCase()
    ) {
      return this.translateService.instant(
        'INVENTORY_WORKSHEET.UNTITLED_INVENTORY'
      );
    }
    return name;
  }

  editName() {

    this.worksheet$
      .pipe(first())
      .subscribe((sheet) => {
        if (sheet) {
          this.editWorksheetNameForm.setValue({ ['name']: sheet.name });
          this.store.dispatch(new SetIsEditingName(true));

          setTimeout(() => {
            this.editNameInput.nativeElement.focus();
          }, 0);
        }
      });
  }

  saveName() {
    this.createSaveWorksheetNameRequest().subscribe();
  }

  createSaveWorksheetNameRequest() {
    return this.worksheet$
      .pipe(
        first(),
        map(worksheet => ({
          worksheet,
          newName: ((this.editWorksheetNameForm.value.name || '') as string).trim()
        })),
        tap(({ worksheet: { name: oldName, id }, newName }) => {
          // if the name was cleared out, reset it to its original state
          if (newName.length === 0) {
            this.editWorksheetNameForm.setValue({ ['name']: oldName });
          }
          if (newName.length > 0 && newName !== oldName) {
            this.store.dispatch(
              new PatchWorksheetAttempt({
                worksheetId: id,
                fields: { name: newName },
              })
            );
          }
        })
      );
  }
  getSubTitleDateTime(date: string, currentLang: string): string {
    // NOTE: If this gets used outside this component, turn this into a pipe
    const now = moment();
    const target = moment(date);
    let timeString = formatDate(date, 'shortTime', currentLang);
    let dateString: string;
    if (now.isSame(target, 'date')) {
      dateString = this.translateService.instant(
        'INVENTORY_WORKSHEET.DATE_TODAY'
      );
    } else if (now.subtract({ day: 1 }).isSame(target, 'date')) {
      dateString = this.translateService.instant(
        'INVENTORY_WORKSHEET.DATE_YESTERDAY'
      );
    } else {
      if (currentLang === InventoryConstants.LANGUAGES.ENGLISH) {
        dateString = formatDate(date, 'MMM. d, y', currentLang);
      } else {
        dateString = formatDate(date, 'mediumDate', currentLang);
      }
    }

    if (currentLang === InventoryConstants.LANGUAGES.FRENCH) {
      // eslint-disable-next-line @typescript-eslint/quotes
      timeString = formatDate(date, "HH'h'mm", currentLang);
    }

    return `${dateString}, ${timeString}`;
  }

  getFormattedDate(date: string, currentLang: string): string {
    // NOTE: If this gets used outside this component, turn this into a pipe
    let dateString: string;
    if (currentLang === InventoryConstants.LANGUAGES.ENGLISH) {
      dateString = formatDate(date, 'MMM. d, y', currentLang);
    } else {
      dateString = formatDate(date, 'mediumDate', currentLang);
    }

    return dateString;
  }

  onAddItemClick(): void {
    this.worksheet$.pipe(first()).subscribe((ws) => {
      this.dialog.open(AddItemDialogComponent, {
        data: {
          worksheet: ws,
        },
        panelClass: 'inventory-add-item-dialog',
      });
    });
  }

  onEditStorageArea(storageArea?: StorageArea) {
    this.worksheet$.pipe(first()).subscribe((ws) => {
      const storageAreas = ws.storageAreas;
      const dialogRef = this.dialog.open(StorageAreaCreateModalComponent, {
        data: {
          storageArea,
          storageAreas,
        },
        panelClass: 'inventory-create-storage-area-dialog',
      });

      dialogRef.afterClosed().subscribe((storageAreaName: string) => {
        if (storageAreaName) {
          if (storageArea) {
            storageArea.name = storageAreaName;
            this.store.dispatch(
              new PatchStorageArea({
                worksheetId: ws.id,
                storageAreaId: storageArea.id,
                fields: {
                  name: storageAreaName,
                },
              })
            );
          } else {
            const trimmedName = storageAreaName.trim();
            this.store.dispatch(
              new CreateStorageAreaName({
                worksheetId: ws.id,
                storageAreaName: trimmedName,
              })
            );
          }
        }
      });
    });
  }

  setWorksheetDone() {
    this.worksheet$
      .pipe(
        first(),
        tap(() => {
          this.overlay.show();
          this.closeWorkSheetInProgress$.next(true);
          this.closeWorkSheetCompleted$.next(false);
        }),
        concatMap(sheet => {
          return this.worksheetService.muxUpdateWorksheetItemRequest.onComplete()
            .pipe(
              concatMap(() => this.worksheetService.patchWorksheet(sheet.id, { status: InventoryWorksheetStatusEnum.Closed })
                .pipe(
                  tap(() => this.worksheetDataService.triggerLoadWorksheetData(sheet.id)),
                  concatMap(x => {
                    return this.worksheetDataService.getWorksheetById$(sheet.id)
                      .pipe(
                        filter(updatedSheet => updatedSheet.status === 'closed'),
                        first(),
                        tap(() => { this.closeWorkSheetCompleted$.next(true); }),
                      );
                  }),
                )),
            );
        }),
        tap(() => {
          this.router.navigateByUrl('/inventory');
        }),
        catchError(err => {
          this.closeWorkSheetInProgress$.next(false);
          this.messageService.queue(
            this.translateService.instant('MESSAGES.SNACKBAR_ERROR_MESSAGE')
          );
          return of(err);
        }),
        tap(() => { this.overlay.hide(); }),
      )
      .subscribe();
  }

  getTotalWorksheetValue(): Observable<number> {
    return this.worksheet$
      .pipe(
        withLatestFrom(
          this.inventoryItems$,
          this.customItemData$,
          this.standardMeasurementUnits$),
        map(([ws, items, data, units]) =>
          this.inventoryValueService.getTotalWorksheetValue(ws, items, units, data))
      );
  }

  isTotalWorksheetValueNull(): Observable<boolean> {
    return this.getTotalWorksheetValue().pipe(map((val) => val === null));
  }

  printWorksheet() {
    this.worksheet$.pipe(first()).subscribe((ws) => {
      if (ws) {
        this.printStorageAreas = ws.storageAreas.filter(
          (sa) => sa.worksheetItems.length > 0
        );
        if (this.printStorageAreas.length < 1) {
          this.printStorageAreas = ws.storageAreas.filter(
            (sa) => sa.name === 'unassigned'
          );
        }

        this.beforePrint()
          .pipe(first())
          .subscribe((printInfo) => {
            this.dialog.open(PrintWorksheetDialogComponent, {
              data: {
                worksheetPrintInfo: printInfo,
              },
              panelClass: 'print-worksheet-dialog',
            });
          });
      }
    });
  }

  afterPrint() { }

  beforePrint(): Observable<WorksheetPrintInfo> {
    const worksheetInfo$ = this.worksheet$.pipe(
      map((worksheetData) => ({
        name: worksheetData.name,
        worksheet: worksheetData,
      }))
    );

    const allProducts$: Observable<{
      [s: string]: CountableItem;
    }> = this.inventoryItems$.pipe(map((items) => items));

    const customUnitsMap$ = this.customItemData$.pipe(
      map((items) =>
        items.reduce((acc, next) => {
          acc[next.itemId] = next.countingUnits.map((value) => value.custom);
          return acc;
        }, {})
      )
    );

    const units$ = combineLatest([
      customUnitsMap$,
      this.standardMeasurementUnits$,
    ]).pipe(
      first(),
      map(([customUnitsMap, standardMeasurementUnits]) => ({
        customUnitMap: customUnitsMap,
        standardUnits: standardMeasurementUnits,
      }))
    );

    return combineLatest([
      worksheetInfo$,
      this.customerInfo$,
      allProducts$,
      this.currentLang$,
      this.isMobile$,
      units$,
    ]).pipe(
      first(),
      map(
        ([
          worksheetInfo,
          customerInfo,
          itemMap,
          currentLang,
          isMobile,
          units,
        ]) => ({
          name: worksheetInfo.name,
          worksheet: worksheetInfo.worksheet,
          formattedCreatedDate: this.getFormattedDate(
            worksheetInfo.worksheet.created,
            currentLang
          ),
          customerName: customerInfo.customerName,
          customerId: customerInfo.pk.customerId,
          items: itemMap,
          customUnits: units.customUnitMap,
          selectedStorageAreas: this.printStorageAreas,
          mobile: isMobile,
          standardUnits: units.standardUnits,
        })
      )
    );
  }


  // Expands panels for item search filter of GFS, and Non-GFS items in English and French with button click or keyup.
  onExpandFilter(filterText): void {
    const storageAreasSource = this.worksheet$.pipe(
      first(),
      map((x) => x?.storageAreas ?? []),
    );

    const filteredItemsSource =
      combineLatest([
        // this.itemFilterText$,
        this.currentLang$,
        this.inventoryItems$,
      ]).pipe(
        first(),
        switchMap(([lang, items]) =>
          Object.values<CountableItem>(
            items
          ).filter((countableItem: CountableItem) =>
            this.searchService.workItemTextSearch(
              filterText,
              lang,
              'EMPTY_WORKITEM_ID',
              countableItem
            )
          )
        ),
        map(
          (item: CountableItem) =>
            item.gfsItem?.id ?? item.generalItem?.id ?? item.customItem.id
        ),
        toArray()
      );

    combineLatest([filteredItemsSource, storageAreasSource])
      .pipe(
        first(),
        switchMap(([matches, storageAreas]) => {
          return storageAreas.map((x) => {
            const isExpanded = x.worksheetItems.some(
              (y) => matches.indexOf(y.itemId) !== -1
            );
            return new SetStorageAreaExpandStatus({
              status: isExpanded,
              areaId: x.id,
            });
          });
        }),
        tap((expansionStatus: SetStorageAreaExpandStatus) => { this.store.dispatch(expansionStatus); })
      )
      .subscribe();
  }

  async refreshWorksheetItems(): Promise<void> {
    this.worksheetDataService.refreshWorksheetItems();
  }

  showRefreshWorksheetItemsButton(): boolean {
    return this.configService.getSettings().FF_REFRESH_WORKSHEET_ITEMS;
  }

  showInifniteScrollToggle(): boolean {
    return this.configService.getSettings().FF_INFINITE_SCROLL;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LegacyCustomerMigrationFacade } from './facade/legacy-customer-migration.facade';
import { InventoryMigrationService } from './services/inventory-migration.service';
import { InventoryMigrationConfigurationModalComponent } from './modal/inventory-migration-configuration-modal.component';

@NgModule({
    imports: [
        TranslateModule.forChild(),
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatCheckboxModule,
    ],
    declarations: [
        InventoryMigrationConfigurationModalComponent
    ],
    providers: [
        LegacyCustomerMigrationFacade,
        InventoryMigrationService,
    ]
})
export class LegacyCustomerMigrationModule {
    constructor() {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InventoryConstants } from '@gfs/constants';
import { SharedDirectivesModule } from '@gfs/directives';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../lib/material.module';
import { TryNewReportingPlatformComponent } from './components/call-to-action/try-new-reporting-platform/try-new-reporting-platform.component';
import { InfiniteScrollListComponent } from './core/infinite-scroll-list/infinite-scroll-list.component';
import { LoadEndComponent } from './core/load-end/load-end.component';
import { LoadingSpinnerOverlayService } from './core/loader/loading-spinner-overlay.service';
import { LocalizedUIV2Module } from './core/localization/v2.localizedUI.module';
import { HasPermissionService } from './core/security/has-permission.service';
import { SafePipe } from './core/pipes/safe.pipe';
import { FormV2Module } from './form/v2.form.module';
const { icons } = InventoryConstants;







@NgModule({
  declarations: [
    InfiniteScrollListComponent,
    TryNewReportingPlatformComponent,
    SafePipe,
    LoadEndComponent
  ],
  providers: [
    LoadingSpinnerOverlayService,
    HasPermissionService
  ],
  imports: [
    CommonModule,
    LocalizedUIV2Module,
    FormV2Module,
    SharedDirectivesModule,
    BrowserModule,
    TranslateModule.forChild(),
    // MatIconModule,
    MaterialModule
  ],
  exports: [
    LocalizedUIV2Module,
    FormV2Module,
    InfiniteScrollListComponent,
    TryNewReportingPlatformComponent,
    LoadEndComponent
  ],
})

export class SharedComponentsV2Module { }
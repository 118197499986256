import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InventoryConstants } from '@gfs/constants';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { GfsItem, CustomerPK } from '@gfs/shared-models';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private baseUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private INVENTORY_API_BASE_URL: string,
  ) {
    this.baseUrl = INVENTORY_API_BASE_URL + '/api/v1';
  }

  getProductsInfo(productIds: string[], customerPk: CustomerPK, includeAdditionalInfo = false): Observable<GfsItem[]> {
    const products = productIds.slice();
    const requests = new Array<Observable<GfsItem[]>>();
    while (products.length) {
      const currentIds = products.splice(0, InventoryConstants.MAX_INVENTORY_PRODUCTS).join(',');
      const options = { params: { ...customerPk, productIds: currentIds, includeAdditionalInfo: `${includeAdditionalInfo}` } };
      const request = this.http.get<GfsItem[]>(`${this.baseUrl}/products`, options);
      requests.push(request);
    }
    const result = forkJoin(requests)
      .pipe(
        map((response: GfsItem[][]) => new Array<GfsItem>().concat(...response))
      );
    return result;
  }

  getProductAutocomplete(customerPK: CustomerPK, userLocale: string, searchTermPrefix: string): Observable<string[]> {
    const obj = Object.assign(customerPK, { locale: userLocale, searchPrefix: searchTermPrefix });
    return this.http.get<string[]>(`${this.baseUrl}/product-autocompletions`, { params: { ...obj } });
  }

  getProductInfo(productId: string, customerPK: CustomerPK): Observable<GfsItem> {
    const obj = Object.assign(customerPK);
    return this.http.get<GfsItem>(`${this.baseUrl}/products/${productId}`, { params: { ...obj } });
  }
}

import {
    Observable,
    concatMap,
    forkJoin,
    from,
    map,
    of,
    switchMap
} from 'rxjs';
import { AuthenticationState } from '../../models/authentication-state.model';
import {
    OKTA_CONFIG,
    OktaAuthGuard,
    OktaAuthStateService,
    OktaConfig
} from '@okta/okta-angular';
import {
    AuthenticationService,
    blockFromRecipeProfitCalculator as blockFromRecipeProfitCalculatorFn,
} from '../../authentication-state.service';
import { AuthState } from '@okta/okta-auth-js';
import {
    Inject,
    Injectable
} from '@angular/core';
import { WINDOW } from '@gfs/shared-services/services/window.service';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
@Injectable()
export class OktaAuthenticationService
    implements AuthenticationService {

    blockFromRecipeProfitCalculator = blockFromRecipeProfitCalculatorFn;

    constructor(
        public oktaAuthStateService: OktaAuthStateService,
        @Inject(OKTA_CONFIG) public oktaConfig: OktaConfig,
        @Inject(WINDOW) public window: Window,
        public oktaAuthGuard: OktaAuthGuard,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const canActivate = this.oktaAuthGuard.canActivate(route, state);
        return from(canActivate);
    }

    reauthorizeWithoutPrompt$(): Observable<void> {
        const tokenResponse = this.oktaConfig.oktaAuth.token
            .getWithoutPrompt({ responseType: ['token', 'id_token'] });
        return forkJoin([
            from(tokenResponse)
        ]).pipe(
            switchMap(() => {
                return of(void 0);
            }),
        );
    }

    handleRedirect$(): Observable<AuthenticationState> {
        return from(this.oktaConfig.oktaAuth.handleRedirect())
            .pipe(concatMap(() => this.authenticationState$()))
    }

    authenticationState$(): Observable<AuthenticationState> {
        return this.oktaAuthStateService.authState$
            .pipe(
                map((authState: AuthState) => {
                    return {
                        accessToken: authState.accessToken?.accessToken,
                        idToken: authState.idToken?.idToken,
                        claims: authState.accessToken?.claims,
                        isAuthenticated: authState.isAuthenticated,
                        error: authState.error
                    }
                })
            );
    }

    loginWithRedirect$(): Observable<void> {
        return forkJoin([
            from(this.oktaConfig.oktaAuth.signInWithRedirect())
        ]).pipe(concatMap(() => {
            return of(void 0)
        }));
    }

    logout$(): Observable<boolean> {
        this.window.localStorage.removeItem('languageId');
        const postLogoutRedirectUri = this.oktaConfig.oktaAuth.options.postLogoutRedirectUri;
        const signOut = this.oktaConfig.oktaAuth.signOut({ postLogoutRedirectUri });
        return from(signOut);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerUnitSelectionComponent } from './customer-unit-selection/customer-unit-selection.component';
import { RouterModule, Routes } from '@angular/router';
import { CustomerUnitCardComponent } from './customer-unit-card/customer-unit-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { InventoryConstants } from '@gfs/constants';
import { InventoryIconService } from '@gfs/shared-services';
import { NoResultsComponent } from './no-results/no-results.component';
import { CustomerUnitSelectorComponent } from './customer-unit-selector/customer-unit-selector.component';
import { PipeSharingModule } from '../pipes/pipe-sharing.module';
import { CustomerUnitSearchbarComponent } from './customer-unit-searchbar/customer-unit-searchbar.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

const routes: Routes = [
  {
    path: '',
    component: CustomerUnitSelectionComponent
  }
];

const { icons } = InventoryConstants;

@NgModule({
  imports: [
    PipeSharingModule,
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    MatIconModule,
    FormsModule,
    MaterialModule
  ],
  declarations: [
    CustomerUnitSelectionComponent,
    CustomerUnitCardComponent,
    NoResultsComponent,
    CustomerUnitSelectorComponent,
    CustomerUnitSearchbarComponent
  ],
  exports: [
    CustomerUnitSelectorComponent,
    CustomerUnitSelectionComponent
  ]
})
export class CustomerUnitSelectionModule {
  readonly customerUnitSelectionIcons = Object.keys(icons).map(icon => icons[icon]);

  constructor(public inventoryIconService: InventoryIconService) {
    inventoryIconService.registerIcons(this.customerUnitSelectionIcons);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pixels'
})
export class PixelsPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    return `${value}px`;
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from '@gfs/directives';
import { AddItemsStateModule } from '@gfs/store/feature/add-items';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedComponentsV2Module } from '../../v2/v2.module';
import { MaterialModule } from '../material.module';
import { PipeSharingModule } from '../pipes/pipe-sharing.module';
import { SharedComponentsModule } from '../shared-components.module';
import { AddCustomItemComponent } from './add-custom-item/add-custom-item.component';
import { AddItemCardComponent } from './add-item-card/add-item-card.component';
import { AddItemDialogComponent } from './add-item-dialog/add-item-dialog.component';
import { CountingUnitFormComponent } from './counting-unit-form/counting-unit-form.component';
import { DuplicateItemDialogComponent } from './duplicate-item-dialog/duplicate-item-dialog.component';
import { EditGfsItemComponent } from './edit-gfs-item/edit-gfs-item.component';
import { EditItemDialogComponent } from './edit-item-dialog/edit-item-dialog.component';
import { NonGfsInfoDialogComponent } from './non-gfs-info-dialog/non-gfs-info-dialog.component';
import { UnitOfMeasureFormComponent } from './unit-of-measure-form/unit-of-measure-form.component';

@NgModule({
  declarations: [
    AddItemDialogComponent,
    NonGfsInfoDialogComponent,
    EditItemDialogComponent,
    DuplicateItemDialogComponent,
    AddItemCardComponent,
    AddCustomItemComponent,
    EditGfsItemComponent,
    CountingUnitFormComponent,
    UnitOfMeasureFormComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forChild(),
    PipeSharingModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    NgxPermissionsModule.forRoot(),
    AddItemsStateModule,
    SharedComponentsV2Module
  ],
  exports: [
    AddItemDialogComponent,
    NonGfsInfoDialogComponent,
    EditItemDialogComponent,
    DuplicateItemDialogComponent,
    AddItemCardComponent,
    AddCustomItemComponent,
    EditGfsItemComponent,
    CountingUnitFormComponent,
  ],
})
export class AddItemsModule { }
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { trim } from 'lodash-es';
import { Worksheet } from '@gfs/shared-models';

@Injectable({
  providedIn: 'root',
})
export class StorageAreaValidator {
  public static validate(
    isSavingWorksheet$: Observable<boolean>,
    worksheet$: Observable<Worksheet>,
    translateFunction: (value) => string,
    allowEmpty = false
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      if (control) {
        return isSavingWorksheet$.pipe(
          take(1),
          mergeMap((isSaving) =>
            worksheet$.pipe(
              filter((ws) => {
                return !!ws && !!ws.storageAreas;
              }),
              take(1),
              map((ws) => {
                const value = trim(control.value);
                const validArea = ws.storageAreas.find(
                  (area) => translateFunction(area.name) === value
                );
                const isEmpty = allowEmpty && !value.length;
                if (validArea || isSaving || isEmpty || control.touched) {
                  return null;
                }
                return { invalidStorageArea: true };
              })
            )
          )
        );
      }
      return of({ invalidStorageArea: true });
    };
  }
}

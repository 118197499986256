<div class="quick-add-items-container" #quickSearchContainer>
  <div class="quick-add-items-search-container">
    <div class="quick-add-items-title">{{ "QUICK_ADD.QUICK_ADD" | translate }}</div>
    <div class="quick-add-instructions"></div>
    <div class="quick-add-items-input-wrapper">
      <mat-form-field class="quick-add-items-form-field">
        <mat-icon class="icon-style" matPrefix svgIcon="search_icon"></mat-icon>
        <input
          #quickAddItemsInput
          matInput
          placeholder="{{ ('QUICK_ADD.SEARCH_PLACEHOLDER' | translate) }}"
          type="text"
          autocomplete="off"
          class="quick-add-items-input"
          id="quick-add-items-input"
          maxlength="200"
          (input)="onSearch($event, quickAddItemsInput.value)"
          (keydown.enter)="onSearch($event, quickAddItemsInput.value)"
          (focusout)="closeDropdownIfBlurred($event)"
        />
        <mat-hint align="start">{{ getSearchPrompt() }}</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="quick-add-items-results-container" #quickAddItemsResultsContainer *ngIf="quickAddItemsInput.value.length > 0" (focusout)="closeDropdownIfBlurred($event)">
    <div *ngIf="(searchResult$ | async).length > 0 && (isSearching$ | async) !== true" class="quick-add-items-dropdown">
      <mat-grid-list
        *ngFor="let item of (searchResult$ | async)"
        [cols]="8"
        [rowHeight]="'10px'"
        gutterSize="0px"
        class="quick-add-items-result-card"
        tabindex="0"
        >
        <mat-grid-tile [colspan]="6" [rowspan]="8" class="quick-item-description-tile">
          <div id="quick-item-image-wrapper-{{ addItemIndex }}" class="quick-item-image-wrapper">
            <img id="quick-item-thumbnail-{{ addItemIndex }}" class="quick-item-thumbnail" [src]="getLocalizedImageUrl(item,48,48) | async"
              [alt]="getLocalizedProductDescription(item) | async">
          </div>
          <div
            *ngIf="!!item.gfsItem"
            class="quick-item-description-container">
            <div id="quick-item-title-{{ addItemIndex }}" class="quick-item-title">
              {{getLocalizedProductDescription(item) | async}}
            </div>
            <div id="quick-item-details-{{ addItemIndex }}" class="quick-item-details">
              #{{getItemId(item)}} | {{getLocalizedBrand(item) | async}} | {{createTextUnitDetails(item, lang) | async}}
            </div>
          </div>
          <div
            *ngIf="!!item.customItem"
            class="quick-item-description-container">
            <div id="quick-item-title-{{ addItemIndex }}" class="quick-item-title">
              {{item.customItem.description}}
            </div>
            <div id="quick-item-details-{{ addItemIndex }}" class="quick-item-details">
              {{item.customItem.supplierItemCode}} | {{getLocalizedBrand(item) | async}}
            </div>
          </div>
          <div
            *ngIf="!!item.recipeItem"
            class="quick-item-description-container">
            <div id="quick-item-title-{{ addItemIndex }}" class="quick-item-title">
              {{item.recipeItem.name}}
            </div>
            <div *ngIf="item.recipeItem.details.menu !== null" id="quick-item-details-{{ addItemIndex }}" class="quick-item-details">
              {{ 'CATEGORY.RECIPE_CARD.MENU_ITEM' | translate }}
            </div>
            <div *ngIf="item.recipeItem.details.batch !== null" id="quick-item-details-{{ addItemIndex }}" class="quick-item-details">
              {{ 'CATEGORY.RECIPE_CARD.BATCH_RECIPE' | translate }}
              <span *ngIf="!!item.recipeItem.details.batch?.batchItemQty && !!item.recipeItem.details.batch?.batchItemUnit">
               | {{ item.recipeItem.details.batch.batchItemQty }}/{{ item.recipeItem.details.batch.batchItemUnit }}
              </span>
            </div>
          </div>
        </mat-grid-tile>
        <div>
          <mat-grid-tile [colspan]="2" [rowspan]="8" class="button-tile">
            <button type="button" id="quick-add-item-button-{{ addItemIndex }}" class="quick-add-item-button" (click)="onAddClicked(item)">
              <mat-icon class="icon quick-add-item-plus-icon" svgIcon="plus-icon" ></mat-icon>
              <span class="quick-add-item-button-label">{{'ADD_ITEMS.ADD_ITEM' | translate}}</span>
            </button>
          </mat-grid-tile>
        </div>
      </mat-grid-list>
      <div *ngIf="hasSearched && ((isSearching$ | async) === false)">
        <button
          type="button"
          id="quick-add-items-search-catalogue-button"
          (click)="onSearchCatalogueClicked()"
          class="quick-add-catalogue-button">
          <mat-icon class="icon quick-add-item-search-icon" svgIcon="search_icon" ></mat-icon>
          <span class="quick-add-item-button-label">{{ localizeCatalogueSpelling$ | async }}</span>
        </button>
      </div>
    </div>
    <div *ngIf="isSearching$ | async" class="quick-add-items-dropdown">
      <div class="quick-add-spinner">
        <mat-spinner [diameter]="70" [strokeWidth]="7"></mat-spinner>
      </div>
    </div>
    <div *ngIf="((searchResult$ | async).length === 0) && hasSearched" class="quick-add-items-dropdown">
      <div class="quick-add-items-no-results">
        <div id="quick-add-items-no-ingredients-found">{{ 'QUICK_ADD.NO_INGREDIENTS_FOUND' | translate}}</div>
        <div *ngIf="hasSearched && ((isSearching$ | async) === false)">
          <button
            type="button"
            id="quick-add-items-search-catalogue-button"
            (click)="onSearchCatalogueClicked()"
            class="quick-add-catalogue-button">
            <mat-icon class="icon quick-add-item-search-icon" svgIcon="search_icon" ></mat-icon>
            <span class="quick-add-item-button-label">{{ localizeCatalogueSpelling$ | async }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import { IDictionary, MeasurementUnit, ResolvedItem } from '@gfs/shared-models';
import { optionHasValue, sanitizeOptionValue } from '@gfs/shared-services';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { Observable, of } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { UnitConfigurationResolver } from './item-data.bll';

export class ItemUnitConfiguration {
    [key: string]: IDictionary<UnitConfigurationResolver[]>;
}

// @note uom config availability
export class DefaultItemUnitConfigurationFactory {
    private static unitSourceConfig: ItemUnitConfiguration = {
        'GFS': {
            'WEIGHT': [
                (item: ResolvedItem, _availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item).pipe(
                        map(x => {
                            return optionHasValue(x.gfsItem.netWeightKg);
                        }),
                        catchError(() => of(false))),
                (item: ResolvedItem, _availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item).pipe(
                        map(x => {
                            return optionHasValue(x.customItemData.purchaseUnit.gfs.netWeight);
                        }),
                        catchError(() => of(false))
                    )
            ],
            'VOLUME': [
                (item: ResolvedItem, _availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item).pipe(
                        map(x => optionHasValue(x.gfsItem.netVolumeMl)
                            && x.gfsItem.netVolumeMl > 0),
                        catchError(() => of(false))
                    ),
                (item: ResolvedItem, _availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item).pipe(
                        map(x => {
                            return optionHasValue(x.customItemData.purchaseUnit.gfs.netVolume)
                                && +x.customItemData.purchaseUnit.gfs.netVolume > 0;
                        }),
                        catchError(() => of(false))
                    )
            ]
        },
        'CUSTOM': {
            'WEIGHT': [
                (item: ResolvedItem, _availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item).pipe(
                        map(x => optionHasValue(x.customItem.purchaseUnit.custom.netWeight)),
                        catchError(() => of(false))
                    ),
            ],
            'VOLUME': [
                (item: ResolvedItem, _availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item).pipe(
                        map(x => optionHasValue(x.customItem.purchaseUnit.custom.netVolume)),
                        catchError(() => of(false))
                    ),
            ]
        },
        'RECIPE': {
            'WEIGHT': [
                (item: ResolvedItem, availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item)
                        .pipe(
                            map((x) => sanitizeOptionValue(x.recipeItem.details.batch.totalYieldUnit, null)),
                            withLatestFrom(of(availableUnits)),
                            map(([unitId, units]) => units.find(x => x.id === unitId)),
                            map((unit) => caseInsensitiveEquals(unit.measurementType, 'WEIGHT')),
                            catchError(() => of(false))
                        )
            ],
            'VOLUME': [
                (item: ResolvedItem, availableUnits: MeasurementUnit[]): Observable<boolean> =>
                    of(item)
                        .pipe(
                            map((x) => sanitizeOptionValue(x.recipeItem.details.batch.totalYieldUnit, null)),
                            withLatestFrom(of(availableUnits)),
                            map(([unitId, units]) => units.find(x => x.id === unitId)),
                            map((unit) => caseInsensitiveEquals(unit.measurementType, 'VOLUME')),
                            catchError(() => of(false))
                        )
            ]
        }
    };
    static build(): ItemUnitConfiguration { return this.unitSourceConfig; }


}

import { Component, Inject, Input } from '@angular/core';
import { IAppContext } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';

@Component({
  selector: 'gfs-localized-currency-value',
  template: '{{value ? (value | currency: \'CAD\' : \'$\' : undefined : (lang$ | async)) : placeholderText }}'
})
export class LocalizedCurrencyValueComponent {


  lang$ = this.appContext.language;

  private mValue: number;
  public get value(): number {
    return this.mValue;
  }
  @Input()
  public set value(value: number) {
    this.mValue = value;
  }
  @Input()
  placeholderText;

  /**
   *
   */
  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext,
  ) {
  }

}
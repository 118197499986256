<mat-accordion id="storage-area-item-accordian" multi="true">
  <mat-expansion-panel class="storage-area-item-panel" id="storage-area-item-panel-unassigned"
    [expanded]="(unAssignedStorageArea$ | async)?.expandStatus">
    <mat-expansion-panel-header class="storage-area-item-panel-header item-background" [collapsedHeight]="'40px'"
      [expandedHeight]="'40px'" (click)="fireSetStorageAreaExpandStatus($event, null)">
      <mat-panel-title class="storage-area-title">
        <div class="storage-area-name">{{'INVENTORY_WORKSHEET.UNASSIGNED' | translate | uppercase }}
          ({{(filterStorageAreaItemsByFilterText((unAssignedStorageArea$ | async)) | async)?.length}})</div>
        <div *ngxPermissionsOnly="pricingPermissions" class="storage-area-total">
          <div class="storage-area-total-inner" *ngIf="!(isUnassignedStorageAreaTotalNull() | async)">
            {{(getUnassignedStorageAreaTotal() | async) | currency:'CAD':'$':undefined:(currentLang$ | async)}}
          </div>
          <div class="storage-area-total-inner" *ngIf="(isUnassignedStorageAreaTotalNull() | async)">
            {{'STORAGE_AREA.STORAGE_AREA_CARD.EMPTY_ITEM_VALUE' | translate}}</div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div >
        <div *ngIf="!(useInfiniteScrolling$ | async) as useInfiniteScrolling">
          <app-storage-area-card [pageOffset]="getCurrentPageByStorageAreaId((unAssignedStorageArea$ | async).id)"
            [worksheetId]="(getWorksheetId() | async)" [storageArea]="(unAssignedStorageArea$ | async)" [activeItems]="(filterStorageAreaItemsByFilterText((unAssignedStorageArea$ | async)) | async)
        | slice: getCurrentPageByStorageAreaId((unAssignedStorageArea$ | async).id)
        : getCurrentPageByStorageAreaId((unAssignedStorageArea$ | async).id, 10)"
            [inventoryItems]="filterInventoryItemsByArea$((unAssignedStorageArea$ | async), inventoryItems$) | async"
            [standardUnits]="standardUnits$ | async" [countingUnits]="countingUnits$ | async"
            [customItemData]="customItemData$ | async" (worksheetItemDrop)="handleWorksheetItemDrop($event)"
            (lastItemEnter)="onLastItemEnter()">
          </app-storage-area-card>
          <div #storageAreaPaginatorIndexPlaceholder id="paginator-unassigned">
          </div>
          <mat-paginator #storageAreaPaginator class="storage-area-paginator"
            [length]="(filterStorageAreaItemsByFilterText((unAssignedStorageArea$ | async)) | async).length"
            [pageSize]="10"
            [pageIndex]="storageAreaPaging[(unAssignedStorageArea$ | async).id] ? storageAreaPaging[(unAssignedStorageArea$ | async).id] / 10 : 0"
            (page)="onPageClick($event)" hidePageSize="true" showFirstLastButtons="true">
          </mat-paginator>
        </div>
        <div *ngIf="(useInfiniteScrolling$ | async) as useInfiniteScrolling ">
          <app-infinite-storage-area-card [getPage]="getPage.bind(this)"
            [pageOffset]="getCurrentPageByStorageAreaId((unAssignedStorageArea$ | async).id)"
            [worksheetId]="(getWorksheetId() | async)" [storageArea]="(unAssignedStorageArea$ | async)" [activeItems]="(filterStorageAreaItemsByFilterText((unAssignedStorageArea$ | async)) | async)
    | slice: getCurrentPageByStorageAreaId((unAssignedStorageArea$ | async).id)
    : getCurrentPageByStorageAreaId((unAssignedStorageArea$ | async).id, 10)"
            [inventoryItems]="filterInventoryItemsByArea$((unAssignedStorageArea$ | async), inventoryItems$) | async"
            [standardUnits]="standardUnits$ | async" [countingUnits]="countingUnits$ | async"
            [customItemData]="customItemData$ | async" (worksheetItemDrop)="handleWorksheetItemDrop($event)"
            (lastItemEnter)="onLastItemEnter()">
          </app-infinite-storage-area-card>
        </div>
      </div>

    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel class="storage-area-item-panel"
    id="storage-area-item-panel-{{storageArea?.name.replace(' ', '-')}}-{{storageArea?.id}}"
    *ngFor="let storageArea of (assignedStorageAreas$ | async) trackBy: identify" [disabled]="isMobile$ | async"
    [expanded]="storageArea?.expandStatus"
    attr.id="storage-area-item-panel-{{storageArea?.name.replace(' ', '-')}}-{{storageArea?.id}}">
    <mat-expansion-panel-header class="storage-area-item-panel-header item-background" [collapsedHeight]="'40px'"
      [expandedHeight]="'40px'" (click)="fireSetStorageAreaExpandStatus($event, storageArea)">
      <mat-panel-title class="storage-area-title">
        <div class="storage-area-name">
          {{getTranslation(storageArea?.name) | translate | truncated:17 | uppercase }}
          ({{(filterStorageAreaItemsByFilterText(storageArea) | async)?.length}})
        </div>
        <div *ngxPermissionsOnly="pricingPermissions" class="storage-area-total">
          <div class="storage-area-total-inner" *ngIf="!(isStorageAreaValueTotalNull(storageArea) | async)">
            {{getStorageAreaValueTotal(storageArea) | async | currency:'CAD':'$':undefined:(currentLang$ | async)}}
          </div>
          <div class="storage-area-total-inner" *ngIf="(isStorageAreaValueTotalNull(storageArea) | async)">
            {{'STORAGE_AREA.STORAGE_AREA_CARD.EMPTY_ITEM_VALUE' | translate}}</div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ng-container *ngIf="filterStorageAreaItemsByFilterText(storageArea) | async as storageAreaItems">
        <div >
          <div *ngIf="!(useInfiniteScrolling$ | async)">
            <app-storage-area-card [pageOffset]="getCurrentPageByStorageAreaId(storageArea.id)"
              [worksheetId]="(getWorksheetId() | async)" [storageArea]="storageArea" [activeItems]="storageAreaItems
            | slice: getCurrentPageByStorageAreaId(storageArea.id)
              : getCurrentPageByStorageAreaId(storageArea.id, 10)"
              [inventoryItems]="filterInventoryItemsByArea$(storageArea, inventoryItems$) | async"
              [standardUnits]="standardUnits$ | async" [countingUnits]="countingUnits$ | async"
              [customItemData]="customItemData$ | async" (worksheetItemDrop)="handleWorksheetItemDrop($event)"
              (lastItemEnter)="onLastItemEnter(storageArea)">
            </app-storage-area-card>
            <div #storageAreaPaginatorIndexPlaceholder id="paginator-{{storageArea.id}}">
            </div>
            <mat-paginator #storageAreaPaginator class="storage-area-paginator" [length]="storageAreaItems.length"
              [pageSize]="10"
              [pageIndex]="storageAreaPaging[storageArea.id] ? storageAreaPaging[storageArea.id] / 10 : 0"
              (page)="onPageClick($event, storageArea.id)" hidePageSize="true" showFirstLastButtons="true">
            </mat-paginator>
          </div>
          <div *ngIf="(useInfiniteScrolling$ | async)">
            <app-infinite-storage-area-card [getPage]="getPage.bind(this)"
              [pageOffset]="getCurrentPageByStorageAreaId(storageArea.id)" [worksheetId]="(getWorksheetId() | async)"
              [storageArea]="storageArea" [activeItems]="storageAreaItems
            | slice: getCurrentPageByStorageAreaId(storageArea.id)
              : getCurrentPageByStorageAreaId(storageArea.id, 10)"
              [inventoryItems]="filterInventoryItemsByArea$(storageArea, inventoryItems$) | async"
              [standardUnits]="standardUnits$ | async" [countingUnits]="countingUnits$ | async"
              [customItemData]="customItemData$ | async" (worksheetItemDrop)="handleWorksheetItemDrop($event)"
              (lastItemEnter)="onLastItemEnter(storageArea)">
            </app-infinite-storage-area-card>
          </div>
        </div>

      </ng-container>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>

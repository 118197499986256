import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export * from './toElementStream';
export * from './tapAudit';


/**
 * convert a array stream to an element stream inline
 */
export function inlineFrom<TModel>() { return concatMap<TModel[], Observable<TModel>>(x => from(x)); }

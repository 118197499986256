import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { UnitSelectionGuardService } from '@gfs/shared-services';
import { RECIPE_PROFIT_CALCULATOR_CONFIG } from '@gfs/shared-models';

export const unitSelectionGuardFn: CanActivateFn =
  (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,

    // This dependency is configured as a singleton
    unitSelectionGuard = inject(UnitSelectionGuardService),

    // This dependency must be provided to UnitSelectionGuard.
    // It should be instanced at the start of the activated route lifetime scope.
    recipeProfitCalculatorConfig = inject(RECIPE_PROFIT_CALCULATOR_CONFIG),
  ): Observable<boolean> => {
    return unitSelectionGuard.canActivate(recipeProfitCalculatorConfig);
  };

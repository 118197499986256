<div *ngIf="isChainUser !== null">
  <div class="reports-container">
    <div class="table-label">
      <div class="inventory-counts">{{ 'REPORTS.INVENTORY_VALUE_REPORT' | translate }}</div>
    </div>
    <div class="table-label" *ngIf="configService.getSettings().FF_SHOW_TRY_REPORTING" >
      <gfs-try-new-reporting-platform/>
    </div>


    <mat-expansion-panel
      *ngIf="isChainUser === false"
      class="inventory-counts-panel"
      id="inventory-counts-panel-report"
      [ngClass]="{'mobile': isMobile$ | async}"
      [expanded]="true">
      <mat-expansion-panel-header
        class="inventory-counts-panel-header"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'">
        <mat-panel-title class="inventory-counts-title">
          {{'REPORTS.INVENTORY_COUNT' | translate | uppercase }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="inventory-counts-table-title">{{ 'REPORTS.WHICH_INVENTORY_COUNT' | translate }}</div>
      <mat-table id="inventory-counts-table" mat-table [dataSource]="(activeWorksheetSummaries$ | async) | slice : 0 : lastElement" >

        <ng-container matColumnDef="radio">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns"></mat-header-cell>
          <mat-cell *matCellDef="let row" class="inventory-counts-cell mobile-card-title">
            <mat-radio-button id="radio-worksheet-{{row.id}}" (change)="selectCount(row)"
                              name="report-radio-selection"
                              aria-label="{{row.name}}"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Description">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">DESCRIPTION</mat-header-cell>
          <mat-cell *matCellDef="let row" class="inventory-counts-cell mobile-card-title">
            <div class="inventory-counts-cell-value">{{translateName(row.name)}}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Value">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.VALUE' | translate | uppercase }}</mat-header-cell>
          <mat-cell *matCellDef="let row" class="inventory-counts-cell total-value">
            <div class="inventory-counts-cell-value">{{row.totalValue | currency:'CAD':'$':undefined:(currentLang$ | async)}}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Count Date">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'REPORTS.COUNT_DATE' | translate | uppercase }}</mat-header-cell>
          <mat-cell *matCellDef="let row" class="inventory-counts-cell">
            <div class="inventory-counts-cell-value">
              <span *ngIf="isMobile$ | async" class="mobile-label-count-date">{{ 'REPORTS.COUNT_DATE' | translate | uppercase }}</span>
              <span class="date-time">{{getWorksheetTableDateTime(row.created, (currentLang$ | async))}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row id="table-header" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <button
        *ngIf="(activeWorksheetSummaries$ | async)?.length > lastElement"
        class="view-more-button secondary-button"
        (click)="viewMore()">
        {{ 'REPORTS.VIEW_MORE' | translate }}
      </button>
    </mat-expansion-panel>
  </div>
  <div *ngIf="isChainUser === false" class="reports-footer">
    <div class="action-buttons">
      <button
        class="download-csv-button primary-button"
        [disabled]="!selectedCount"
        (click)="downloadCsv('excel')">
        {{ 'REPORTS.DOWNLOAD_EXCEL' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="false" class="reports-container">
    <div class="table-label">
      <div class="inventory-counts">{{ 'REPORTS.MULTI_UNIT_ROLLUP' | translate }}</div>
    </div>
    <div id="multi-unit-detail-text">{{ 'REPORTS.MULTI_UNIT_DETAIL' | translate }}</div>
    <div id="multi-unit-dates-container">
      <mat-form-field>
        <mat-label>{{ 'REPORTS.DATE_RANGE' | translate }}</mat-label>
        <mat-hint>{{ 'REPORTS.MULTI_UNIT_HINT' | translate }}</mat-hint>
        <mat-date-range-input [formGroup]="multiUnitDateRange" [rangePicker]="multiUnitPicker" [dateFilter]="dateRangeFilter">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="multiUnitPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #multiUnitPicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

<!--  Making this ng-if as false to disbale multi-unit roll up-->
  <div *ngIf="false" class="reports-footer">
    <div class="action-buttons">
      <button
        class="download-csv-button primary-button"
        [disabled]="isRollupDownloadDisabled()"
        (click)="downloadRollupCSV()">
        {{ 'REPORTS.DOWNLOAD_EXCEL' | translate }}
      </button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { IAddItemsFeatureBridge } from '@gfs/store/feature/add-items';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AddItemsContextFactoryService {
  public static factory(factory: AddItemsContextFactoryService) {
    return factory.Build();
  }

  constructor(private store: Store<AppState>) {}

  Build(): IAddItemsFeatureBridge {
    return {
      pk$: this.store.select((state) => state.auth.pk),
      currentLang$: this.store.select((state) => state.layout.language),
      selectedWorksheet$: this.store.select(
        (state) => state.worksheets.worksheets[state.worksheets.selected]
      ),
      customItemData$: this.store.select(
        (state) => state.worksheets.customItemData
      ),
      isSavingWorksheet$: this.store.select(
        (state) => state.worksheets.isSaving
      ),
      isMobile$: this.store.select((state) => state.layout.isMobile),
    };
  }
}

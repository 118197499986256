import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageArea } from '@gfs/shared-models';
import { CreateStorageAreaName, PatchStorageArea } from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { StorageAreaCreateModalComponent } from '@inventory-ui/inventory-worksheet/storage-area/storage-area-create-modal/storage-area-create-modal.component';
import { promiseMeAlways } from '@inventory-ui/v2/common/preparing-app/preparing-app.component';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { first, map, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({ selector: 'app-storage-area-edit', template: '' })
export class StorageAreaEditComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.initBehavior()
      .subscribe();
  }

  initBehavior() {
    return combineLatest([
      this.createBehaviorOpenWindowOnRouteActivation(this.activatedRoute)
    ])
      .pipe(takeUntil(this.destroy$));
  }

  createBehaviorOpenWindowOnRouteActivation(
    activatedRoute: ActivatedRoute
  ) {
    return activatedRoute.data.pipe(
      map(({ 0: storageAreas }: { 0: Array<StorageArea>; }) => ({ storageAreas })),
      withLatestFrom(
        combineLatest([
          this.activatedRoute.params,
          this.activatedRoute.parent.params
        ])
      ),
      first(),
      mergeMap(([{ storageAreas }, [params, parentParams]]) => this.open(parentParams.id, params.storageAreaId, storageAreas)
      )
    );
  }

  open(
    worksheetId: string,
    storageAreaId: string,
    storageAreas: StorageArea[],
  ): Observable<any> {
    const storageArea = storageAreas.find(v => v.id === storageAreaId);
    return this.createStorageAreaEditModal(storageAreaId, storageAreas)
      .afterClosed()
      .pipe(
        tap((storageAreaName: string) => {
          if (storageAreaName) {
            if (storageArea) {
              storageArea.name = storageAreaName;
              this.store.dispatch(
                new PatchStorageArea({
                  worksheetId,
                  storageAreaId: storageArea.id,
                  fields: {
                    name: storageAreaName.trim(),
                  },
                })
              );
            } else {
              const trimmedName = storageAreaName.trim();
              this.store.dispatch(
                new CreateStorageAreaName({
                  worksheetId,
                  storageAreaName: trimmedName,
                })
              );
            }
          }

        }),
        mergeMap(() =>
          promiseMeAlways(this.navigateToWorksheet(this.router, worksheetId))
        )
      );
  }

  createStorageAreaEditModal(
    storageAreaId: string,
    storageAreas: StorageArea[],
  ) {
    return this.dialog.open(StorageAreaCreateModalComponent, {
      data: {
        storageArea: storageAreas.find(v => v.id === storageAreaId),
        storageAreas,
      },
      panelClass: 'inventory-create-storage-area-dialog',
    });
  }


  async navigateToWorksheet(
    router: Router,
    worksheetId: string
  ) {
    return router.navigateByUrl(`/worksheet/${worksheetId}`, { replaceUrl: true });
  }
}
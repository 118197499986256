import * as customer from './actions';
import { FeatureState, initialState } from './state';

export function customerUnitSelectionReducer(
  state: FeatureState = initialState,
  action: customer.ActionUnion
): FeatureState {
  switch (action.type) {
    case customer.ActionTypes.GetCustomerDataSuccess:
      return {
        ...state,
        customers: {
          ...state.customers,
          [action.payload.customerPK.customerId]: action.payload,
        },
      };
    case customer.ActionTypes.GetCustomerDataError:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

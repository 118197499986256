import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IAppContext } from '@gfs/shared-models';
import { RedirectUri, InjectionTokens, GfsApplicationsService } from '@gfs/shared-services';
import { toEntityDictionary } from '@gfs/shared-services/extensions/rxjs';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, filter, first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExternalUriResolver {

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    public gfsApplicationsService: GfsApplicationsService,
    private loadingSpinner: LoadingSpinnerOverlayService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<RedirectUri> {
    return combineLatest([
      this.appContext.entitlements,
      this.appContext.customerPK,
    ]).pipe(
      first(),
      tap(() => this.showSpinnerOverlay()),
      filter(([entitlements, customerPk]) => !!(entitlements && customerPk)),
      concatMap(([entitlements, customerPk]) => this.gfsApplicationsService.getApplications$(entitlements, customerPk)),
      toEntityDictionary((x) => x.key),
      map(x => x[route.params.reference].redirect),
      catchError((err) => of(new RedirectUri('/error'))),
      tap(() => this.hideSpinnerOverlay()),
    );
  }

  showSpinnerOverlay() {
    return this.loadingSpinner.show();
  }

  hideSpinnerOverlay() {
    return this.loadingSpinner.hide();
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Entitlement } from '@gfs/shared-models';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TruncatedPipe } from '../pipes/truncated.pipe';

@Component({
  selector: 'gfs-customer-unit-menu',
  templateUrl: './customer-unit-menu.component.html',
  styleUrls: ['./customer-unit-menu.component.scss']
})
export class CustomerUnitMenuComponent {
  @Input() selectedUnit: Entitlement;
  @Output() selectedUnitChange: EventEmitter<Entitlement> = new EventEmitter<Entitlement>();

  isMobile$ = this.store.select(state => state.layout.isMobile);
  isOnline$ = this.store.select(state => state.network.isOnline);
  entitlements$ = this.store.select(state => state.auth.user ? state.auth.user.entitlements : null);

  constructor(private store: Store<AppState>) { }

  selectUnit(selectedUnit) {
    this.selectedUnitChange.emit(selectedUnit);
  }

  getOptionLabel(option: any) {
    const truncatedPipe = new TruncatedPipe();
    const custumerName = truncatedPipe.transform(option.customerName, 16);

    return `${custumerName} #${option.customerPK.customerId}`;
  }
}

import { Md5 } from 'ts-md5';
export type AuditData<TModel> = {
  timestamp: string;
  key: string;
  hash: string;
  data: TModel;
};
export class Audix<TModel>{
  private entries: Array<AuditData<TModel>> = [];
  constructor(public key: string, private getKey: (data: TModel) => string) {
    this.init();
  }

  append = (data: TModel) => {
    this.entries.unshift({
      data,
      key: this.getKey(data),
      hash: Md5.hashStr(JSON.stringify(data)),
      timestamp: new Date().toISOString()
    });
  };

  dump = (key: string = '') => {
    console.table(this.entries.filter(x => key === '' || x.key === key));
  };

  private init = () => {
    this.bootStrapWindow();
    this.attachInstanceToWindow(this.key);
  };

  private attachInstanceToWindow = (key: string) => {
    window['__audix'][key] = this;
  };

  private bootStrapWindow = () => {
    const ready = !!window['__audix'];
    if (!ready) {
      const f: { [k: string]: Audix<TModel>; } = {};
      window['__audix'] = f;
    }
  };
}

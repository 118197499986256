<div>
    <ng-container *ngIf="viewmodel">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <mat-form-field>
                <input
                    placeholder="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_CODE' | translate }}"
                    formControlName="glCode"
                    id="glCode"
                    type="text"
                    matInput>
                <mat-error *ngIf="form.get('glCode').hasError('invalidEntityNameValidator')">{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DUPLICATE_CODE' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input
                    placeholder="{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.GL_DESCRIPTION' | translate }}"
                    formControlName="description"
                    type="text"
                    matInput>
                <mat-error *ngIf="form.get('description').hasError('invalidEntityNameValidator')">{{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DUPLICATE_DESCRIPTION' | translate }}</mat-error>
            </mat-form-field>
            <button
                class="primary-button large-button"
                [disabled]="isFormDisabled(form)"
                type="submit">
                {{ 'SHARED.ADD' | translate }}
            </button>
        </form>
    </ng-container>
</div>
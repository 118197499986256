import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomerPK } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import {
    GeneraLedgerAccountPatch,
    GeneralLedgerAccount,
    GeneralLedgerAccountPage,
    GeneralLedgerAccountPost,
    ItemCategoryGLAccount
} from '../models/general-ledger.model';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class GeneralLedgerService {
    private generalLedgerUrl: string;
    private maintainGeneralLedgerUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private INVENTORY_API_BASE_URL: string,
    ) {
        this.generalLedgerUrl = INVENTORY_API_BASE_URL + '/api/v1/general-ledger';
        this.maintainGeneralLedgerUrl = INVENTORY_API_BASE_URL + '/api/v1/maintain-general-ledger';
    }

    getGeneralLedger(customerPk: CustomerPK): Observable<GeneralLedgerAccountPage> {
        return this.http.get<GeneralLedgerAccountPage>(`${this.maintainGeneralLedgerUrl}`, { params: { ...customerPk } });
    }

    getAccounts(customerPk: CustomerPK): Observable<GeneralLedgerAccount[]> {
        return this.http.get<GeneralLedgerAccount[]>(`${this.generalLedgerUrl}`, { params: { ...customerPk } });
    }

    addAccount(model: GeneralLedgerAccountPost): Observable<GeneralLedgerAccount> {
        return this.http.post<GeneralLedgerAccount>(`${this.generalLedgerUrl}`, model);
    }

    updateAccount(model: GeneraLedgerAccountPatch): Observable<GeneralLedgerAccount> {
        return this.http.patch<GeneralLedgerAccount>(`${this.generalLedgerUrl}/${model.id}`, model);
    }

    deleteAccount(customerPK: CustomerPK, id: string): Observable<GeneralLedgerAccount> {
        return this.http.delete<GeneralLedgerAccount>(`${this.generalLedgerUrl}/${id}`, { body: customerPK });
    }

    addItemCategory(customerPK: CustomerPK, generalLedgerAccountId: string, itemCategoryid: string): Observable<ItemCategoryGLAccount> {
        return this.http.post<ItemCategoryGLAccount>(`${this.generalLedgerUrl}/${generalLedgerAccountId}/item-category/${itemCategoryid}`, customerPK);
    }

    removeItemCategory(customerPK: CustomerPK, generalLedgerAccountId: string, itemCategoryId: string): Observable<ItemCategoryGLAccount> {
        return this.http.delete<ItemCategoryGLAccount>(`${this.generalLedgerUrl}/${generalLedgerAccountId}/item-category/${itemCategoryId}`, { body: customerPK });
    }
}

<ng-container *ngIf="(vm$|async) as vm">
  <div id="item-card-{{ uiIndex }}" class="item-card">
    <mat-grid-list [cols]="8" gutterSize="0px">
      <mat-grid-tile [colspan]="(isMobile$ | async) ? 8 : 6" [rowspan]="1" class="item-description-tile">
        <div id="item-image-wrapper-{{ uiIndex }}" class="item-image-wrapper">
          <img id="item-thumbnail-{{ uiIndex }}" class="item-thumbnail" [src]="vm.imageUri"
            [alt]="vm.productDescription" />
        </div>

        <gfs-warning-icon [i18nTooltip]="'RECIPE.PRICING.OUT_OF_DATE'" [hidden]="!vm.showOutOfDateWarning"
          [isPlaceholder]="false">
        </gfs-warning-icon>

        <div>
          <div *ngIf="vm.type === 'GFS'" class="item-description-container">
            <div *ngIf="mItemData.gfsItem" id="item-title-{{ uiIndex }}" class="item-title">
              {{ vm.productDescription }}
            </div>
            <div *ngIf="!mItemData.gfsItem" id="item-title-{{ uiIndex }}" class="item-title">
              item #{{ vm.itemId }} is missing
            </div>

            <div id="item-details-{{ uiIndex }}" class="item-details">
              #{{ vm.itemId }} | {{ vm.brand}} | {{vm.unitDetails}}
            </div>
            <div *ngxPermissionsOnly="pricingPermissions">
              <div id="add-item-price-{{ uiIndex }}" *ngIf="(isMobile$ | async) && displayItemPrices"
                class="price-container">
                <span>{{ 'ADD_ITEMS.CASE' | translate }}
                  <span class="case-price">{{
                    vm.casePrice > 0
                    ? (vm.casePrice
                    | currency: 'CAD':'$':undefined:(currentLang$ | async))
                    : '-'
                    }}</span></span>
                <span *ngIf="!isEditItem">
                  {{ 'ADD_ITEMS.UNIT' | translate }}
                  <span class="unit-price">{{
                    vm.unitPrice > 0
                    ? (vm.unitPrice
                    | currency: 'CAD':'$':undefined:(currentLang$ | async))
                    : '-'
                    }}</span>
                </span>
              </div>
            </div>

          </div>
          <div *ngIf="['CUSTOM','GENERAL'].indexOf(vm.type) > -1" class="item-description-container">
            <div id="item-title-{{ uiIndex }}" class="item-title">
              {{ vm.productDescription }}
            </div>
            <div id="item-details-{{ uiIndex }}" class="item-details">
              {{ vm.supplierItemCode }} | {{ vm.brand }}
            </div>
            <div *ngxPermissionsOnly="pricingPermissions">
              <div id="add-item-price-{{ uiIndex }}" *ngIf="(isMobile$ | async) && displayItemPrices"
                class="price-container">
                <span>{{ 'ADD_ITEMS.CASE' | translate }}
                  <span class="case-price">{{
                    vm.casePrice > 0
                    ? (vm.casePrice
                    | currency: 'CAD':'$':undefined:(currentLang$ | async))
                    : '-'
                    }}</span></span>
                <span *ngIf="!isEditItem">{{ 'ADD_ITEMS.UNIT' | translate }}
                  <span class="unit-price">{{
                    vm.unitPrice > 0
                    ? (vm.unitPrice
                    | currency: 'CAD':'$':undefined:(currentLang$ | async))
                    : '-'
                    }}</span></span>
              </div>
            </div>
          </div>
          <div *ngIf="['RECIPE','BATCH_RECIPE'].indexOf(vm.type) > -1" class="item-description-container">
            <div id="item-title-{{ uiIndex }}" class="item-title">
              {{ vm.itemData?.recipeItem?.name }}
            </div>
            <div *ngIf="vm.itemData?.recipeItem?.details.menu !== null" id="item-details-{{ uiIndex }}"
              class="quick-item-details">
              {{ 'CATEGORY.RECIPE_CARD.MENU_ITEM' | translate }}
            </div>
            <div *ngIf="vm.itemData?.recipeItem?.details.batch !== null" id="item-details-{{ uiIndex }}"
              class="quick-item-details">
              {{ 'CATEGORY.RECIPE_CARD.BATCH_RECIPE' | translate }}
              <span
                *ngIf="!!vm.itemData?.recipeitem?.details.batch?.batchItemQty && !!vm.itemData?.recipeitem?.details.batch?.batchItemUnit">
                | {{ vm.itemData?.recipeitem?.details.batch.batchItemQty }}/{{
                vm.itemData?.recipeitem?.details.batch.batchItemUnit }}
              </span>
            </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="!(isMobile$ | async) && displayItemPrices">
        <div id="item-unit-container-{{ uiIndex }}" class="label-container">
          <div class="case-label">
            {{ 'ADD_ITEMS.CASE' | translate }}
          </div>
          <div class="unit-label" *ngIf="!isEditItem">
            {{ 'ADD_ITEMS.UNIT' | translate }}
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="!(isMobile$ | async) && displayItemPrices">
        <div id="item-price-container-{{ uiIndex }}" class="price-container">
          <div id="item-case-price-{{ uiIndex }}" class="case-price">
            {{
            vm.casePrice > 0
            ? (vm.casePrice
            | currency: 'CAD':'$':undefined:currentLanguage)
            : '-'
            }}
          </div>
          <div id="item-unit-price-{{ uiIndex }}" class="unit-price" *ngIf="!isEditItem">
            {{
            vm.unitPrice > 0
            ? (vm.unitPrice
            | currency: 'CAD':'$':undefined:currentLanguage)
            : '-'
            }}
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</ng-container>

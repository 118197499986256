<form
  [formGroup]="options"
  #frm
>
  <h2>{{frm.status}}</h2>
  <mat-icon
      svgIcon='subtract'
      class="count-icon"
      *ngIf="isMobile$ | async"
      (click)="countSubtractClicked(itemCountEntry)"
      style="height:14px"
    ></mat-icon>

  <input
    class="gfs-numeric-input item-count-input"
    [id]="'product-count-' + itemCountEntry.ordinal"
    appTwoDigitDecimalNumber
    [regex]="qtyRegex"
    [formControl]="countedQty"
    [(ngModel)]="itemCountEntry.qty"
    (input)="onQuantityUpdate(itemCountInput.value)"
    (keyup.enter)="countEntryCompleted.emit(itemCountEntry)"
    #itemCountInput
    autocomplete="off"
    attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.PRIMARY_QTY' | translate }}"
  />

  <mat-icon
      svgIcon='add'
      class="count-icon"
      *ngIf="isMobile$ | async"
      (click)="countAddClicked(itemCountEntry)"
      style="height:14px"
    ></mat-icon>
  <mat-form-field class="item-count-unit">

    <mat-select
      #countingUnitSelect
      disableOptionCentering
      [(ngModel)]="itemCountEntry.unit"
      [compareWith]="optionComparer"
      (selectionChange)="onQuantityUpdate(itemCountInput.value)"
      [formControl]="countingUnit"
      aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.PRIMARY_UNIT' | translate }}"
    >
      <mat-option
        *ngFor="let unitKey of getUniqueConfiguredCountingUnits(worksheetItem.configuredCountingUnits)"
        [value]="getOptionValue(unitKey)"
      >
        <gfs-localized-text-value [value]="getOptionText(unitKey)" />
      </mat-option>
      
      <mat-option
        *ngIf="itemCountEntry.ordinal > 0"
        [value]="getOptionValue('--')"
      >
        <gfs-localized-text-value [value]="getOptionText('--')" />

      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

































<!-- 












<div class="item-0">
  <div
    *ngIf="isMobile$ | async"
    class="count-icon-wrapper"
    (click)="countSubtractPrimaryClicked(item)"
  >
    <mat-icon
      svgIcon='subtract'
      class="count-icon"
    ></mat-icon>
  </div>

  <input
    #itemCountInput
    [(ngModel)]="itemCountEntry.qty"
    appTwoDigitDecimalNumber
    formcontrolname="primaryCount"
    [regex]="qtyRegex"
    autocomplete="off"
    class="gfs-numeric-input item-count-input"
    attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.PRIMARY_QTY' | translate }}"
  />

  <div
    *ngIf="isMobile$ | async"
    class="count-icon-wrapper"
    (click)="countAddPrimaryClicked(item)"
  >
    <mat-icon
      svgIcon='add'
      class="count-icon"
    ></mat-icon>
  </div>
</div>
<div class="item-1">
  <mat-form-field class="item-count-unit">
    <mat-select
      #countingUnitSelect
      formcontrolname="primaryUnit"
      disableOptionCentering
      [(ngModel)]="itemCountEntry.unit"
      aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.PRIMARY_UNIT' | translate }}"
    >

      <mat-option
        *ngFor="let unitKey of worksheetItem.configuredCountingUnits"
        [value]="unitKey"
      >
        <gfs-localized-text-value [value]="localizedUnits[unitKey]" />

      </mat-option>

    </mat-select>

  </mat-form-field>

</div> -->
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AddElementAutocompleteComponent } from './add-element-autocomplete/add-element-autocomplete.component';
import { CartIconComponent } from './cart-icon/cart-icon.component';
import { CustomerUnitMenuComponent } from './customer-unit-menu/customer-unit-menu.component';
import { CustomerUnitSelectionModule } from './customer-unit-selection/customer-unit-selection.module';
import { DeliveryScheduleWidgetComponent } from './delivery-schedule-widget/delivery-schedule-widget.component';
import { InventoryItemCardComponent } from './inventory-item-card/inventory-item-card.component';
import { InventoryWorksheetWizardComponent } from './inventory-worksheet-wizard/inventory-worksheet-wizard.component';
import { LanguageMenuComponent } from './language-menu/language-menu.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { MaterialModule } from './material.module';
import { MobileHamburgerMenuComponent } from './mobile-hamburger-menu/mobile-hamburger-menu.component';
import { PipeSharingModule } from './pipes/pipe-sharing.module';
import { TruncatedPipe } from './pipes/truncated.pipe';
import { ResetConfirmDialogComponent } from './reset-confirm-dialog/reset-confirm-dialog.component';
import { SelectMenuComponent } from './select-menu/select-menu.component';
import { ItemSearchBarComponent } from './item-search-bar/item-search-bar.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { WarningIconComponent } from './warning-icon/warning-icon.component';

@NgModule({
  declarations: [
    AddElementAutocompleteComponent,
    SelectMenuComponent,
    LanguageMenuComponent,
    ResetConfirmDialogComponent,
    CustomerUnitMenuComponent,
    DeliveryScheduleWidgetComponent,
    LoadingModalComponent,
    InventoryWorksheetWizardComponent,
    CartIconComponent,
    MobileHamburgerMenuComponent,
    InventoryItemCardComponent,
    ItemSearchBarComponent,
    LoadingSpinnerComponent,
    LoadingOverlayComponent,
    WarningIconComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    PipeSharingModule,
    CustomerUnitSelectionModule,
    RouterModule,
    NgxPermissionsModule.forRoot()
  ],
  exports: [
    AddElementAutocompleteComponent,
    TruncatedPipe,
    SelectMenuComponent,
    LanguageMenuComponent,
    ResetConfirmDialogComponent,
    CustomerUnitMenuComponent,
    DeliveryScheduleWidgetComponent,
    LoadingModalComponent,
    InventoryWorksheetWizardComponent,
    CartIconComponent,
    MobileHamburgerMenuComponent,
    InventoryItemCardComponent,
    ItemSearchBarComponent,
    LoadingSpinnerComponent,
    LoadingOverlayComponent,
    WarningIconComponent,
  ],
  providers: [TranslateService, RouterModule],
})
export class SharedComponentsModule {
  constructor() {
    // to please sonarlint
  }
}

import { Injectable } from '@angular/core';
import { TokenResponse } from '../models/TokenResponse';
import { PKCECredential } from '../models/PKCECredential';
import {
    AuthorizationServer,
    discoveryRequest as discoveryRequestFn,
    processDiscoveryResponse as processDiscoveryResponseFn,
    generateRandomCodeVerifier as generateRandomCodeVerifierFn,
    calculatePKCECodeChallenge as calculatePKCECodeChallengeFn,
    validateAuthResponse as validateAuthResponseFn,
    isOAuth2Error as isOAuth2ErrorFn,
    parseWwwAuthenticateChallenges as parseWwwAuthenticateChallengesFn,
    processAuthorizationCodeOAuth2Response as processAuthorizationCodeOAuth2ResponseFn,
    expectNoState,
    skipStateCheck,
    WWWAuthenticateChallenge,
    OAuth2TokenEndpointResponse,
    OAuth2Error
} from 'oauth4webapi';
import { Observable, concatMap, forkJoin, from, map, of } from 'rxjs';
import { OIDC } from '../models/oidc-config.model';

@Injectable({
    providedIn: 'root'
})
export class OpenIDConnectService {

    // Attaching oauth4webapi functions on to the class enables them to be mocked
    discoveryRequest = discoveryRequestFn;
    processDiscoveryResponse = processDiscoveryResponseFn;
    generateRandomCodeVerifier = generateRandomCodeVerifierFn;
    calculatePKCECodeChallenge = calculatePKCECodeChallengeFn;
    validateAuthResponse = validateAuthResponseFn;
    isOAuth2Error = isOAuth2ErrorFn;
    parseWwwAuthenticateChallenges = parseWwwAuthenticateChallengesFn;
    processAuthorizationCodeOAuth2Response = processAuthorizationCodeOAuth2ResponseFn;

    resolveAuthorizationServer$(
        issuer: string
    ): Observable<AuthorizationServer> {
        const url = new URL(issuer);
        return from(this.discoveryRequest(url))
            .pipe(
                concatMap(response => {
                    const clone = response.clone();
                    return from(this.processDiscoveryResponse(url, clone));
                })
            );
    }

    generatePKCECredential$(): Observable<PKCECredential> {
        const code_verifier = this.generateRandomCodeVerifier();
        return forkJoin([
            of(code_verifier),
            from(this.calculatePKCECodeChallenge(code_verifier))
        ]).pipe(
            map(([codeVerifier, codeChallenge]) =>
                ({ codeVerifier, codeChallenge }))
        )
    }

    parseCallbackParameters(
        oidc: OIDC,
        parameters: URLSearchParams | URL,
        expectedState: string | typeof expectNoState | typeof skipStateCheck = expectNoState
    ): URLSearchParams {
        const callbackParams = this.validateAuthResponse(
            oidc.authorizationServer,
            { client_id: oidc.clientId },
            parameters,
            expectedState);

        const isError = this.isOAuth2Error(callbackParams);
        if (isError) {
            throw new Error(`Redirect error: ${JSON.stringify(callbackParams)}`);
        }

        return callbackParams;
    }

    validateTokenResponse$(
        oidc: OIDC,
        tokenResponse$: Observable<Response>
    ): Observable<TokenResponse> {
        return tokenResponse$
            .pipe(
                concatMap(response => {
                    const authChallenges: WWWAuthenticateChallenge[] | undefined =
                        this.parseWwwAuthenticateChallenges(response);
                    if (authChallenges) {
                        const challengesMessage = authChallenges
                            .map(challenge => JSON.stringify(challenge))
                            .join(',');
                        throw new Error(`Authorization server returned authentication challenges: ${challengesMessage}`);
                    }

                    const authorizationCodeOAuth2Response = this.processAuthorizationCodeOAuth2Response(
                        oidc.authorizationServer,
                        { client_id: oidc.clientId },
                        response);
                    return from(authorizationCodeOAuth2Response);
                }),
                map((response: OAuth2Error | OAuth2TokenEndpointResponse) => {
                    // Error often thrown here, investigate
                    if (this.isOAuth2Error(response)) {
                        throw new Error(`Authorization server token endpoint return error: ${JSON.stringify(response)}`);
                    }
                    return {
                        accessToken: response.access_token
                    };
                }),
            );
    }
}

import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../window.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  constructor(@Inject(WINDOW) private windowService: any) { }

  public push(newLayerItem) {
    if (!this.windowService.dataLayer) {
      this.windowService.dataLayer = [];
    }
    this.windowService.dataLayer.push(newLayerItem);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InventoryConstants } from '@gfs/constants';
import {  MessageService } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { catchError, filter, mergeAll, startWith } from 'rxjs/operators';
import { MessageContentComponent } from './message-content/message-content.component';

@Component({
  selector: 'gfs-messages',
  templateUrl: './messages.component.html',
})
export class MessagesComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    // have to bind scope to keep our DI stuff available in the callbacks
    combineLatest([
      this.messageService.messages$.pipe(startWith('')),
    ]).pipe(
      mergeAll(),      
      filter(v => v?.length > 0),
      catchError(this.onError.bind(this))
    ).subscribe(this.makeSnack.bind(this));

  }

  makeSnack(message) {
    this.snackBar.openFromComponent(MessageContentComponent, {
      duration: InventoryConstants.TOAST_MESSAGE_DURATION_DEFAULT,
      panelClass: 'inventory-snack-bar',
      data: { message }
    });
  }

  onError(err) {
    console.log(err);
    this.makeSnack(this.translate.instant('MESSAGES.SNACKBAR_ERROR_MESSAGE'));
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatedPipe } from './truncated.pipe';


@NgModule({
  declarations: [
    TruncatedPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TruncatedPipe
  ]
})
export class PipeSharingModule { }

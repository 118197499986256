<div *ngIf="!!storageArea" cdkDropList (cdkDropListDropped)="worksheetItemDropped($event, true)"
  [cdkDropListDisabled]="(hasFilterText$ | async) || (isMobile$ | async)">
  <gfs-infinite-scroll-list [getPage]="getPage" [options]="scrollerOptions" [query]="storageAreaQuery$ | async">

    <ng-template let-item #row>
    
    </ng-template>

    <ng-template #rowSkeleton>
      <div class="infinite-scroll-row-skeleton-loader align-center">
        <mat-spinner [diameter]="100" [strokeWidth]="70"></mat-spinner>
      </div>
    </ng-template>

    <ng-template let-scroller #eod>
      <div class="infinite-scroll-row-custom-content align-center" #eod>
        <button type="button" mat-button disableRipple matSuffix (click)="scroller.goToTop()">
          Back To Top
        </button>
      </div>
    </ng-template>
  </gfs-infinite-scroll-list>
</div>
import { NgModule } from '@angular/core';
import { OrderByPipe } from './orderBy.pipe';
import { PixelsPipe } from './pixels.pipe';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal.directive';

@NgModule({
  declarations: [
    OrderByPipe,
    TwoDigitDecimalNumberDirective,
    PixelsPipe,
  ],
  imports: [],
  exports: [
    OrderByPipe,
    TwoDigitDecimalNumberDirective,
    PixelsPipe,
  ],
})
export class SharedDirectivesModule { }
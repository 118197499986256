import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { animations } from './animations/animations';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { AppConfigService, InjectionTokens } from '@gfs/shared-services';
import { InventoryConstants } from '@gfs/constants';
import { IFeatureStateFacade } from '@gfs/store/feature/cart';
import { IAppContext } from '@gfs/shared-models';

@Component({
  selector: 'gfs-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
  animations: [animations.badgeIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartIconComponent implements OnInit {
  @Input() disabled = false;
  @Input() wideLayout = false;
  cartWCAGTitle = 'CART.TITLE';

  isMobile$ = this.appContext.isMobile;
  entitlements$ = this.appContext.entitlements;
  cart$ = this.cartStore.select(state => state.cartFeature.cart);
  hasNaooRoles$: Observable<boolean>;
  naooUrl = '';

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private cartStore: Store<IFeatureStateFacade>,
    private configService: AppConfigService
  ) {
    const settings = this.configService.getSettings();
    this.naooUrl = settings.ORDERS_PATH + '/cart';
  }

  ngOnInit() {
    this.hasNaooRoles$ = this.entitlements$.pipe(
      filter(ents => !!ents),
      map(ents => !!ents.find(ent => ent.roleName.includes(InventoryConstants.ORDERING_OKTA_ROLES)))
    );
  }
}

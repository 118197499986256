import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GfsValidators } from '@gfs/shared-services/validation';
import { GeneralLedgerAccount, MaintainGeneralLedgerSection } from '../models/general-ledger.model';
import {
  MaintainAccountsInputViewModel
} from '../maintain-accounts/manage-accounts-input/maintain-accounts-input.component';


export interface GeneralLedgerPopupViewModel {

  maintainGeneralLedgerSection: MaintainGeneralLedgerSection;

}

@Component({
  selector: 'gfs-edit-general-ledger-popup',
  templateUrl: './edit-general-ledger-popup.component.html',
  styleUrls: ['./edit-general-ledger-popup.component.scss']
})
export class EditGeneralLedgerPopupComponent implements OnInit {
  glCode: String;
  description: String;
  form: FormGroup;
  @Input() fromParent;
  @Input() viewModel: GeneralLedgerPopupViewModel;


  constructor(
    public dialogRef: MatDialogRef<EditGeneralLedgerPopupComponent>,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: GeneralLedgerPopupViewModel
  ) {
  }

  ngOnInit() {
    const forbiddenGlCodes = this.getForbiddenEntries(this.viewModel, account => account.glCode);
    const filteredForbiddenGlCode =forbiddenGlCodes.filter((element) => element !== this.fromParent.glCode)
    const forbiddenGlDescriptions = this.getForbiddenEntries(this.viewModel, account => account.description);
    const filteredForbiddenGlDescription =forbiddenGlDescriptions.filter((element) => element !== this.fromParent.description)
    this.form = this.formBuilder.group({
      glCode: new FormControl<string>(this.fromParent.glCode, [
        Validators.required,
        GfsValidators.notEmpty(),
        GfsValidators.duplicateName(filteredForbiddenGlCode)
      ]),
      description: new FormControl<string>(this.fromParent.description, [
        Validators.required,
        GfsValidators.notEmpty(),
        GfsValidators.duplicateName(filteredForbiddenGlDescription)
      ])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.form = this.onChanges(changes, this.form);
  }

  onChanges(changes: SimpleChanges, form: FormGroup): FormGroup {
    const updateFormValidation = changes.viewModel && !!form;
    return updateFormValidation
      ? this.updateFormValidation(form, changes.viewModel.currentValue)
      : form;
  }

  updateFormValidation(
    form: FormGroup,
    viewModel: GeneralLedgerPopupViewModel
  ): FormGroup {
    const forbiddenGlCodes = this.getForbiddenEntries(viewModel, account => account.glCode);
    const forbiddenGlDescriptions = this.getForbiddenEntries(viewModel, account => account.description);

    form.controls.glCode.setValidators([
      Validators.required,
      GfsValidators.notEmpty(),
      GfsValidators.duplicateName(forbiddenGlCodes)
    ]);
    form.controls.description.setValidators([
      Validators.required,
      GfsValidators.notEmpty(),
      GfsValidators.duplicateName(forbiddenGlDescriptions)
    ]);

    form.controls.glCode.updateValueAndValidity();
    form.controls.description.updateValueAndValidity();
    return form;
  }

  getForbiddenEntries(
    viewModel: GeneralLedgerPopupViewModel,
    predicate: (model: GeneralLedgerAccount) => string
  ): string[] {
    return viewModel.maintainGeneralLedgerSection.glAccounts.map(account => predicate(account));
  }

  onCancel(): void {

    this.dialogRef.close();
  }
  isFormDisabled(form: FormGroup) {
    return form.invalid;
  }

  onSubmit(): void {
    const formData = {
      glCode: this.form.value.glCode,
      description: this.form.value.description
    };
    this.dialogRef.close(formData);
  }
}

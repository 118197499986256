import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constants, InventoryConstants } from '@gfs/constants';
import {
  CustomerUnitSelectionComponent,
  ErrorComponent,
  GeneralLedgerRoutes,
  ManageCustomItemsRoutes,
  MobileHamburgerMenuComponent
} from '@gfs/shared-components';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { InventoryWorksheetComponent } from './inventory-worksheet/inventory-worksheet/inventory-worksheet.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ReportsComponent } from './reports/reports.component';
import { SaveInProgressGuard } from './v2/common/guards/save-in-progress.guard';
import { saveInProgressFn } from './v2/common/guards/save-in-progress.guard.fn';
import { PreparingAppComponent } from './v2/common/preparing-app/preparing-app.component';
import { ConfigureProductComponent } from './v2/product/configure/configure.component';
import { SearchProductComponent } from './v2/product/search/search.component';
import { externalUriResolverFn } from './v2/resolvers/external-uri.resolver.fn';
import { GetStorageAllAreasInWorksheetResolver as GetAllStorageAreasInWorksheetResolver } from './v2/resolvers/preloader/get-all-storage-areas-in-worksheet.resolver';
import { GetProductResolver } from './v2/resolvers/preloader/get-product.resolver';
import { GetWorksheetNoCacheResolver } from './v2/resolvers/preloader/get-worksheet-no-cache.resolver';
import { GetWorksheetResolverV2 } from './v2/resolvers/preloader/get-worksheet-v2.resolver';
import { GetWorksheetResolver } from './v2/resolvers/preloader/get-worksheet.resolver';
import { resolveWorksheetFn, resolveWorksheetForPrintFn } from './v2/resolvers/preloader/get-worksheet.resolver.fn';
import { takeInventoryResolveFn } from './v2/resolvers/preloader/take-inventory.resolver.fn';
import { StorageAreaEditComponent } from './v2/storage-area/edit/edit.component';
import { CloseWorksheetComponent } from './v2/worksheet/close/close.component';
import { DeleteComponent } from './v2/worksheet/delete/delete.component';
import { InventoryWorksheetV2Component } from './v2/worksheet/edit/inventory-worksheet/inventory-worksheet.component';
import { WorksheetListComponent } from './v2/worksheet/list/list.component';
import { PrintWorksheetLauncherComponent } from './v2/worksheet/print/print.component';
import {
  unitSelectionSingleUnitRedirectGuardFn
} from "@gfs/shared-services/guards/unit-selection-single-unit-redirect.guard.fn";
import { unitSelectionGuardFn } from '@gfs/shared-services';

export function getDefaultRoute(storage): string {
  const json = storage.getItem('customer');
  const localCustomer = JSON.parse(json);
  const isInventoryEntityType = InventoryConstants.GROUP_ENTITIES.includes(localCustomer?.entityType);
  const isLocalCustomerInInventoryGroup = localCustomer && isInventoryEntityType;
  return isLocalCustomerInInventoryGroup
    ? Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATHNAME
    : '/customer-unit-selection';
}

const routes: Routes = [
  { path: '', redirectTo: getDefaultRoute(localStorage), pathMatch: 'full' },

  {
    path: 'inventory',
    component: WorksheetListComponent,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'v1/inventory',
    component: InventoryComponent,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
  },
  {
    path: 'worksheet/:id/delete',
    component: DeleteComponent,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
    runGuardsAndResolvers: 'always',
    resolve: [resolveWorksheetFn]
  },


  {
    path: 'worksheet/:id/close',
    component: CloseWorksheetComponent,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
    runGuardsAndResolvers: 'always',
    resolve: [resolveWorksheetFn],
  },
  {
    path: 'worksheet/:id',
    component: InventoryWorksheetV2Component,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
    canDeactivate: [
      SaveInProgressGuard
    ],
    resolve: [
      GetWorksheetResolverV2,
      GetWorksheetResolver,
    ],
    runGuardsAndResolvers: 'pathParamsChange',
    children: [
      {
        path: 'print',
        component: PrintWorksheetLauncherComponent,
        resolve: [resolveWorksheetForPrintFn],
        runGuardsAndResolvers: 'pathParamsChange',
        outlet: 'modal'
      },
      {
        path: 'product/add',
        component: SearchProductComponent,
        resolve: [GetWorksheetNoCacheResolver],
        runGuardsAndResolvers: 'pathParamsChange',
        outlet: 'modal'
      },
      {
        path: 'product/:worksheetItemId/configure',
        component: ConfigureProductComponent,
        resolve: [GetProductResolver],
        runGuardsAndResolvers: 'pathParamsChange',
        outlet: 'modal'
      },
      {
        path: 'storage-area/new',
        component: StorageAreaEditComponent,
        resolve: [GetAllStorageAreasInWorksheetResolver],
        runGuardsAndResolvers: 'pathParamsChange',
        outlet: 'modal'
      },
      {
        path: 'storage-area/:storageAreaId/edit',
        component: StorageAreaEditComponent,
        resolve: [GetAllStorageAreasInWorksheetResolver],
        runGuardsAndResolvers: 'pathParamsChange',
        outlet: 'modal',
      },
      {
        path: 'storage-area/:storageAreaId/delete',
        component: DeleteComponent,
        runGuardsAndResolvers: 'pathParamsChange',
        resolve: [GetWorksheetResolver]
      },

    ]
  },
  {
    path: 'v1/worksheet/:id',
    component: InventoryWorksheetComponent,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
    canDeactivate: [saveInProgressFn],
    resolve: [resolveWorksheetFn],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'take-inventory',
    component: PreparingAppComponent,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn],
    runGuardsAndResolvers: 'always',
    resolve: [takeInventoryResolveFn]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [OktaAuthGuard, unitSelectionGuardFn]
  },
  {
    path: 'mobile-hamburger-menu',
    canActivate: [OktaAuthGuard],
    component: MobileHamburgerMenuComponent,
  },
  {
    path: Constants.SharedFeaturePaths.CUSTOMER_SELECTION_PATH,
    canActivate: [OktaAuthGuard, unitSelectionSingleUnitRedirectGuardFn],
    data: { entitlementFilter: InventoryConstants.INVENTORY_OKTA_ROLES },
    component: CustomerUnitSelectionComponent,
  },
  ...ManageCustomItemsRoutes,
  ...GeneralLedgerRoutes,
  { path: 'implicit/callback', component: OktaCallbackComponent },
  {
    path: 'redirect/:reference',
    component: PreparingAppComponent,
    runGuardsAndResolvers: 'always',
    resolve: [externalUriResolverFn]
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

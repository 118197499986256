<div class="error-modal-container">
  <button 
    mat-icon-button
    (click)="closeModal()"
    class="close-button"
    attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
    <mat-icon svgIcon="close-icon"></mat-icon>
  </button>
  <div class="error-modal-content">
    <div class="error-modal-image-wrapper">
      <img
        class="error-modal-image" 
        src="../../../assets/images/error-modal-image.svg"
        alt="{{ 'MESSAGES.OPEN_WORKSHEET_EXISTS_TITLE' | translate }}" />
    </div>
    <div class="modal-text error-modal-title">{{ 'MESSAGES.OPEN_WORKSHEET_EXISTS_TITLE' | translate }}</div>
    <div class="modal-text error-modal-description">{{ 'MESSAGES.OPEN_WORKSHEET_EXISTS' | translate }}</div>
  </div>
  <div class="error-modal-button-bar">
      <button id="error-okay-button"
              type="button"
              class="primary-button large-button"
              (click)="closeModal()">
              {{ 'MESSAGES.OKAY' | translate }}
      </button>
    </div>
</div>

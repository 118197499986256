import { Component, Input } from '@angular/core';

@Component({
  selector: 'gfs-loading-overlay',
  templateUrl: './loading-overlay.component.html'
})
export class LoadingOverlayComponent {
  @Input() overlayClass = 'loading-spinner-overlay';
  @Input() spinnerClass: string;
}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/recipe/reducers';
import { first, filter, tap, concatMap } from 'rxjs/operators';
import { LogInAttempt } from '@gfs/store/common';
import { APPLICATION_USER_ROLE, ApplicationUserRole } from '@gfs/shared-models';
import { WINDOW } from '../services/window.service';

@Injectable()
export class AuthResolver {
  constructor(
    private store: Store<AppState>,
    @Inject(APPLICATION_USER_ROLE) public userRole: ApplicationUserRole,
    @Inject(WINDOW) public window: Window,
  ) { }

  resolve(): Observable<boolean> {
    return this.initAuth().pipe(
      concatMap(() => this.waitForAuth()));
  }

  initAuth() {
    return this.store
      .select(state => state.auth.isAuthenticated)
      .pipe(
        first(),
        tap(auth => {
          if (!auth) {
            // Since LogInAttempt is dispatched in AppComponent, then what is the role of this dispatch?
            const storedCustomer = JSON.parse(this.window.localStorage.getItem('customer'));
            this.store.dispatch(new LogInAttempt({
              storedCustomerId: storedCustomer?.customerId,
              // persistToStorage: true
            }));
          }
        })
      );
  }

  waitForAuth(): Observable<boolean> {
    return this.store.select(state => state.auth.isAuthenticated)
      .pipe(
        filter(auth => auth),
        first()
      );
  }
}

import { InjectionToken } from '@angular/core';

export const RECIPE_PROFIT_CALCULATOR_CONFIG = new InjectionToken<RecipeProfitCalculatorConfig>('recipe-profit-calculator-config');

export type RecipeProfitCalculatorPaths = {
    RECIPE_PROFIT_CALCULATOR_PATH: string,
    RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH: string,
    RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATHNAME: string,
}

export type RecipeProfitCalculatorConfig = RecipeProfitCalculatorPaths & {

    // Needs to be resolved dynamically
    isNavigatingFromCalculator(window: Window): boolean,

    isFeatureEnabled(): boolean,
}

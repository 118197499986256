import { filter, first, map, timeout } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { ICustomerUnitSelectionFeatureBridge } from '@gfs/store/feature/customer-unit-selection';
import { AppConfigService, InjectionTokens, WINDOW } from '@gfs/shared-services';
import { Constants } from '@gfs/constants';
import { CustomerPK, RecipeProfitCalculatorConfig } from '@gfs/shared-models';

export interface RedirectUsersConfig {
  isActive: boolean;
  destination: string;
  plantIds: string[];
}

@Injectable({ providedIn: 'root' })
export class UnitSelectionGuardService {

  constructor(
    private router: Router,
    private store: Store<AppState>,
    @Inject(InjectionTokens.ICustomerUnitSelectionBridge) private featureContext: ICustomerUnitSelectionFeatureBridge,
    @Inject(WINDOW) private window: Window,
    private appConfig: AppConfigService,
  ) { }
  entitlements$ = this.store.select(state => state.auth.user?.entitlements);
  selectedCustomerPK$ = this.store.select(state => state.auth.pk);

  getRedirectUsersConfig(): RedirectUsersConfig {
    const appSettings = this.appConfig.getSettings();
    if (!appSettings) {
      return {
        isActive: false,
        destination: '',
        plantIds: []
      };
    }

    return {
      isActive: this.appConfig.getSettings().FF_REDIRECT_USERS,
      destination: this.appConfig.getSettings().FF_REDIRECT_USERS_DESTINATION,
      plantIds: this.appConfig.getSettings()
        .FF_REDIRECT_USERS_PLANT_IDS_COMMASEPARATED
        .split(',')
        .map(plantId => plantId.trim())
    };
  }

  handleRedirectToCustomerUnitSelection(
    customerPk: CustomerPK,
    recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig
  ): boolean {
    // This guard function is executed before the auth effects complete the SetCustomerPK action.
    // If 'customer' is found in local storage, then hold off on redirect to allow auth effects to complete.
    const storedCustomerJson = this.window.localStorage.getItem('customer');
    const storedCustomerId = storedCustomerJson
      ? JSON.parse(storedCustomerJson).customerId
      : undefined;

    const shouldRedirect = this.shouldRedirectToCustomerUnitSelection(customerPk, storedCustomerId);
    if (shouldRedirect) {
      this.router.navigateByUrl(Constants.SharedFeaturePaths.CUSTOMER_SELECTION_PATH);
      return true;
    }

    return false;
  }

  shouldRedirectToCustomerUnitSelection(
    customerPk: CustomerPK,
    storedCustomerId: string,
  ): boolean {
    return customerPk ? false : true
  }

  canActivate(
    recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
  ): Observable<boolean> {
    return forkJoin([
      this.selectedCustomerPK$.pipe(first()),
      this.entitlements$.pipe(
        filter(value => !!value),
        timeout({ each: 1500, with: () => of([]) })
      ),
      this.featureContext.selectedEntitlement.pipe(first()),
      of(this.getRedirectUsersConfig()),
    ]).pipe(
      map(([
        customerPk,
        entitlements,
        selectedEntitlement,
        config
      ]) => {

        // Require that a customer unit is selected. Otherwise redirect to customer unit selection
        this.handleRedirectToCustomerUnitSelection(customerPk, recipeProfitCalculatorConfig);

        // If the redirect !isActive, guard should allow unit to navigate to inventory
        if (config.isActive) {

          // Check if selectedEntitlement was not selected (ie. when navigating directly to /inventory)
          const entitlement = selectedEntitlement || entitlements.find(entitlement => entitlement.customerPK.customerId === customerPk?.customerId);
          const plantId = entitlement.plantId;

          // Compare plantId to config and redirect if necessary
          const isRedirectUser = plantId
            ? this.isRedirectUser(config.plantIds, plantId)
            : true;

          if (isRedirectUser) {
            this.redirectUser(plantId, config);
          }
        }

        return true;
      }));
  }

  redirectUser(
    plantId: string,
    config: RedirectUsersConfig
  ): void {
    if (config.isActive) {
      this.redirect(config.destination);
    }
  }

  isRedirectUser(
    redirectPlantIds: string[],
    userPlantId: string
  ): boolean {
    return redirectPlantIds
      .some(redirectPlantId =>
        userPlantId?.startsWith(redirectPlantId) ?? false);
  }

  redirect(redirectDestination: string): void {
    this.window.history.replaceState({}, 'foo', '/');
    this.window.location.href = redirectDestination;
  }
}

<div class="modal-container">
  <ng-container *ngIf="viewModelSubject.asObservable() | async as model">
    <form [formGroup]="model.form" (ngSubmit)="submit(model.form.value.supplierName)" >
      <div class="rename-modal-container">
        <div class="modal-title">{{ model.modalTitle | translate }}</div>
        <mat-form-field>
          <input
            #supplierName
            matInput
            formControlName="supplierName"
            class="recipe-heading"
            maxlength="25"
            autocomplete="off"
            placeholder="{{ model.inputPlaceholder | translate }}"
            (keydown)="model.form.controls.supplierName.markAsTouched()">
          <mat-hint align="end">{{model.form.value.supplierName?.length || 0}}/25</mat-hint>
          <mat-error *ngIf="model.form.get('supplierName').hasError('invalidEntityNameValidator')">
            {{ model.inputValidation.duplicate | translate }}
          </mat-error>
          <mat-error *ngIf="model.form.get('supplierName').hasError('required') || model.form.get('supplierName').hasError('whitespace')">
            {{ model.inputValidation.required | translate }}
          </mat-error>
        </mat-form-field>
        <div class="modal-button-bar">
          <button
            class="secondary-button large-button"
            type="button"
            (click)="closeModal()"
            attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
            {{ 'MODALS.CANCEL' | translate }}
          </button>
          <button
            class="primary-button large-button"
            type="submit"
            [disabled]="!model.form.valid"
            attr.aria-label="{{ model.submitButtonAriaLabel | translate }}">
              {{ model.submitButtonText | translate }}
          </button>
        </div>
      </div>
    </form>
    <button class="close-button" (click)="closeModal()" attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
      <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"/>
    </button>
  </ng-container>
</div>

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Worksheet } from '@gfs/shared-models';
import { isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { ClearWorksheetFromState, GetAllInventoryItemsAttempt } from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { WorksheetDataService } from '@inventory-ui/v2/common/services/worksheet-data.service';
import { Store } from '@ngrx/store';
import { Observable, concatMap, filter, first, map, of } from 'rxjs';
import { GetWorksheetResolver } from './get-worksheet.resolver';

export const resolveWorksheetFn: ResolveFn<Observable<Worksheet>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Worksheet> => {
  const service = inject(GetWorksheetResolver);
  return service.resolve(route, state);
};


export const resolveWorksheetForPrintFn: ResolveFn<Observable<Worksheet>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Worksheet> => {
  const store = inject(Store<AppState>) as Store<AppState>;
  const servisce = inject(WorksheetDataService);

  return of(route.parent.params)
    .pipe(
      first(),
      concatMap(({ id: worksheetId }) => {
        store.dispatch(new ClearWorksheetFromState(worksheetId))
        servisce.triggerLoadWorksheetData(worksheetId)
        return store
          .select(v => v.worksheets.worksheets)
          .pipe(
            map(v => v[worksheetId]),
            isTruthy(),
            concatMap(z => {
              store.dispatch(new GetAllInventoryItemsAttempt(worksheetId));
              return store.select(v => v.inventoryItems.inventoryItems)
                .pipe(filter(v => Object.keys(v).length > 0), first(), map(v => z))
            }),
            first()
          )
      }),
    );

};
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  GeneraLedgerAccountPatch,
  MaintainGeneralLedgerSection,
  GeneralLedgerAccountDelete, GeneralLedgerAccount
} from '../../models/general-ledger.model';
import { MatDialog } from '@angular/material/dialog';
import { EditGeneralLedgerPopupComponent } from '../../edit-general-ledger-popup/edit-general-ledger-popup.component';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../../../modals/confirmation-modal/confirmation-modal.component';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/inventory/reducers';


export interface MaintainAccountsTableComponentViewModel {
  maintainGeneralLedgerSection: MaintainGeneralLedgerSection,
}

@Component({
  selector: 'gfs-maintain-accounts-table',
  templateUrl: './maintain-accounts-table.component.html',
  styleUrls: ['./maintain-accounts-table.component.scss'],
  providers:[]
})
export class MaintainAccountsTableComponent {
  customerPK$ = this.store.select(state => state.auth.pk);
  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {
  }

  @Input() viewmodel: MaintainAccountsTableComponentViewModel;
  @Output() updateAccount = new EventEmitter<GeneraLedgerAccountPatch>();
  @Output() deleteGeneralAccount = new EventEmitter<GeneralLedgerAccountDelete>();

  displayedColumns = [
    'glCode',
    'description',
    'tripleDots'
  ];

  onEditGlCode(generalLedgerAccountPatch: GeneraLedgerAccountPatch): void {
    const dialogRef = this.dialog.open(EditGeneralLedgerPopupComponent, {
      width: '655px',
      height: '415px',

    });
    const data = {
      glCode: generalLedgerAccountPatch.glCode,
      description: generalLedgerAccountPatch.description
    };
    dialogRef.componentInstance.fromParent = data;
    dialogRef.componentInstance.viewModel = this.viewmodel;
    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        const model = generalLedgerAccountPatch;
        model.glCode = formData.glCode;
        model.description = formData.description;
        this.updateAccount.emit(model);

      }
    });
  }
  ondeleteAccount(generalLedgerAccount: GeneralLedgerAccount): void {

        const title = this.translate.instant(
          'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DELETE_MODAL.DIALOG_TITLE',
          { glCode : generalLedgerAccount.description }
        );
        const subtitle = this.translate.instant('GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DELETE_MODAL.DIALOG_SUBTITLE');
        const submitButtonAriaLabel = this.translate.instant('GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DELETE_MODAL.SUBMIT_BUTTON');
        const submitButtonLabel = this.translate.instant('GENERAL_LEDGER.MAINTAIN_ACCOUNTS.DELETE_MODAL.SUBMIT_BUTTON');
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
          data: {
            returnData: generalLedgerAccount,
            title,
            subtitle,
            cancelButtonId: 'cancel-delete-storage-area-button',
            submitButtonId: 'delete-storage-area-button',
            submitButtonAriaLabel,
            submitButtonLabel
          },
          width: '604px',
          height: '280px',
          disableClose: true
        });

        dialogRef.afterClosed()
          .subscribe((generalLedgerAccount: GeneralLedgerAccount ) => {
            if (generalLedgerAccount) {
              this.deleteGeneralAccount.emit(generalLedgerAccount);

            }
          });
      }



  isAllowedToEdit(item: GeneralLedgerAccount): Observable<boolean> {
    return this.customerPK$.pipe(
      filter(pk => !!pk),
      map(pk => pk.customerId === item.customerPK.customerId)
    );
  }
}




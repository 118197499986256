import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { IAppContext, IDictionary } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const inputValue = 0;
const formattedString = 1;
@Component({
  selector: 'gfs-localized-image-value',
  // template: '{{value | currency: \'CAD\' : \'$\' : undefined : (lang$ | async) }}',
  templateUrl: './localized-image.component.html'
})
export class LocalizedImageComponent implements OnInit, AfterViewInit {

  lang$ = this.appContext.language;
  localizedValue$ = new BehaviorSubject<IDictionary<string>>(null);
  localizedValue_altText$ = new BehaviorSubject<IDictionary<string>>(null);
  renderValue$: Observable<string>;
  renderValue_altText$: Observable<string>;
  imageQueryString: string;

  @Input()
  public get value(): IDictionary<string> {
    return this.mValue;
  }
  public set value(value: IDictionary<string>) {
    this.mValue = value;
    this.localizedValue$.next(this.mValue);
  }
  private mValue: IDictionary<string>;

  @Input()
  public get alt() {
    return this.mAlt;
  }
  public set alt(value) {
    this.mAlt = value;
    this.localizedValue_altText$.next(this.mValue);

  }
  private mAlt: IDictionary<string>;

  @Input()
  requestHeight = null;

  @Input()
  requestWidth = null;

  @Input()
  style = '';

  @Input()
  class = '';


  noImage: HTMLImageElement = new Image();

  /**
   *
   */
  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext,
  ) { }

  ngAfterViewInit(): void {
    this.imageQueryString = this.createImageQueryString();
  }

  async ngOnInit() {
    this.renderValue$ = this.createLocalizedObservableString(
      this.lang$,
      this.localizedValue$.asObservable()
    );
    this.renderValue_altText$ = this.createLocalizedObservableString(
      this.lang$,
      this.localizedValue_altText$.asObservable()
    );
  }


   createImageQueryString() {
    const sizeConstraints = [
      [this.requestHeight, `height=${this.requestHeight}`],
      [this.requestWidth, `width=${this.requestWidth}`]
    ];

    const contentSizeParams = sizeConstraints
      .filter(param => param[inputValue] != null)
      .map(param => param[formattedString]);

    const contentParams = contentSizeParams.length > 0
      ? [
        'auto=webp',
        'fit=bounds',
        `dpr=${window.devicePixelRatio}`
      ]
      : [];


    const queryString = [...contentSizeParams, ...contentParams].join('&');

    return '?' + queryString;
  }

  createLocalizedObservableString(
    langSource: Observable<string>,
    localizationSource: Observable<IDictionary<string>>
  ) {

    return combineLatest([
      langSource, localizationSource.pipe(isTruthy())]
    )
      .pipe(
        map(
          ([lang, localizedValues]) => {
            try {
              return localizedValues[lang];
            } catch {
              return 'ERR';
            }
          }
        ),
      );
  }

}
<div class="modal-container">
  <form [formGroup]="form" (ngSubmit)="submit(form)" >
    <div class="rename-modal-container">
      <div *ngIf="isNewStorageArea" class="modal-title">{{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.CREATE_TITLE' | translate }}</div>
      <div *ngIf="!isNewStorageArea" class="modal-title">{{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.RENAME_TITLE' | translate }}</div>
      <mat-form-field>
        <input
          id="storage-area-modal-name-input"
          matInput
          formControlName="storageAreaName"
          #storageAreaName
          class="recipe-heading"
          maxlength="100"
          autocomplete="off"
          placeholder="{{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.STORAGE_AREA_NAME_PLACEHOLDER' | translate }}">
        <mat-hint align="end">{{storageAreaName.value?.length || 0}}/100</mat-hint>
        <mat-error *ngIf="form.get('storageAreaName').hasError('storageAreaExists') || form.get('storageAreaName').hasError('invalidStorageAreaName')">
          {{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.STORAGE_AREA_ALREADY_EXISTS_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('storageAreaName').hasError('required')">{{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.STORAGE_AREA_IS_REQUIRED_ERROR' | translate }}</mat-error>
        <mat-error *ngIf="form.get('storageAreaName').hasError('storageAreaContainsOnlySpaces')">{{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.STORAGE_AREA_IS_REQUIRED_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <div class="modal-button-bar">
        <button id="cancel-button"
          class="secondary-button large-button"
          type="button"
          (click)="closeModal()"
          attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
          {{ 'MODALS.CANCEL' | translate }}
        </button>
        <button
          *ngIf="isNewStorageArea"
          id="create-storage-area-submit"
          class="primary-button large-button"
          type="submit"
          [disabled]="!form.valid"
          attr.aria-label="{{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.CREATE_STORAGE_AREA_BUTTON_ARIA_LABEL' | translate }}">
            {{ 'STORAGE_AREA.CREATE_STORAGE_AREA_MODAL.CREATE_STORAGE_AREA_BUTTON' | translate }}
        </button>
        <button
          *ngIf="!isNewStorageArea"
          id="edit-storage-area-submit"
          class="primary-button large-button"
          type="submit"
          [disabled]="!form.valid"
          attr.aria-label="{{ 'STORAGE_AREA.EDIT_STORAGE_AREA_BUTTON_ARIA_LABEL' | translate }}">
          {{ 'STORAGE_AREA.EDIT_STORAGE_AREA_BUTTON' | translate }}
        </button>
      </div>
    </div>
    <button class="close-button" (click)="closeModal()" attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
      <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"/>
    </button>
  </form>
</div>
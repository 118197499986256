import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { Entitlement } from '@gfs/shared-models';
import { AppConfigService, GfsApplicationsService, GFSRoute, InjectionTokens } from '@gfs/shared-services';
import { LogOutAttempt } from '@gfs/store/common';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, filter, first, map } from 'rxjs/operators';
import { handleSubMenuPermissions as handleSubMenuPermissionsFn } from '../header/sub-header/sub-header.component';

type SubHeaderLink = {
  text: string,
  link: string,
  isActive: boolean,
};

@Component({
  selector: 'gfs-mobile-hamburger-menu',
  templateUrl: './mobile-hamburger-menu.component.html',
  styleUrls: ['./mobile-hamburger-menu.component.scss']
})
export class MobileHamburgerMenuComponent {
  activeApplication: 'recipe' | 'inventory';
  selectedCustomerPk$ = this.store.select(state => state.auth.pk);
  isOnline$ = this.store.select(state => state.network.isOnline);

  handleSubMenuPermissions = handleSubMenuPermissionsFn;

  gfsApplications$ = forkJoin([
    this.store
      .select(state => state.auth.entitlements)
      .pipe(
        filter((entitlements: Entitlement[]) => !!entitlements?.length),
        first()
      ),
    this.selectedCustomerPk$.pipe(first()),
  ]).pipe(
    concatMap(([entitlements, pk]) => {
      return this.gfsApplicationsService.getApplications$(entitlements, pk);
    }),
    map((gfsRoutes: GFSRoute[]) => {
      return gfsRoutes
        .sort((gfsRoute: GFSRoute) => gfsRoute.url === '/' ? -1 : 0)
        .map((gfsRoute: GFSRoute) => ({
          ...gfsRoute,
          action: () => {
            if (gfsRoute.url === '/') {
              this.router.navigate([gfsRoute.url]);
            } else {
              window.location.href = gfsRoute.url;
            }
          }
        }));
    }),
  );

  selectedApp$ = this.gfsApplications$
    .pipe(
      map(apps => apps.find(app => app.selected)),
    );

  isCustomerUnitRoute$: Observable<{ value: boolean }> = this.route.queryParams
    .pipe(
      map(params => {
        const value = params.prev
          ? params.prev === InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH
          : false;
        return { value };
      }),
    );

  subHeaderLinks$: Observable<SubHeaderLink[]> = forkJoin([
    this.permissionsService.permissions$
      .pipe(first()),
    this.store.
      select(state => state.auth.pk)
      .pipe(first()),
    of(this.router.url),
  ]).pipe(
    map(([permissions, pk, url]) => {
      const subHeaderLinks = this.handleSubMenuPermissions(permissions, pk)[this.activeApplication];
      const activeLink = subHeaderLinks.find(link => url.includes(link.link))
      const activeLinkIndex = subHeaderLinks.indexOf(activeLink);
      return subHeaderLinks
        .map((link: SubHeaderLink, index: number) => {
          return { ...link, isActive: index === activeLinkIndex }
        });
    }),
  );

  constructor(
    private translateService: TranslateService,
    private location: Location,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    public gfsApplicationsService: GfsApplicationsService,
    private configService: AppConfigService,
    private permissionsService: NgxPermissionsService,
    @Inject(InjectionTokens.ACTIVE_APPLICATION) activeApp: 'recipe' | 'inventory'
  ) {
    this.activeApplication = activeApp;
  }

  navigateBack() {
    this.location.back();
  }

  logout() {
    this.store.dispatch(new LogOutAttempt());
  }

  getApplicationOptionLabel() {
    return option => this.translateService.instant(option.label).toUpperCase();
  }
}

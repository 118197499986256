export const FeatureKey = 'customerUnitSelectionFeature';
import { Customer } from '@gfs/shared-models';

export interface IFeatureStateFacade {
  [FeatureKey]: FeatureState;
}

export interface FeatureState {
  error: string;
  customers: {
    [customerId: string]: Customer;
  };
}

export const initialState: FeatureState = {
  error: null,
  customers: {}
};

import { iif, MonoTypeOperatorFunction, Observable, of, race, Subject, throwError } from 'rxjs';
import { concatMap, delay, distinctUntilChanged, filter, first, retryWhen, scan, tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { isTruthy } from './isTruthy';

export * from './isTruthy';
export * from './mux';
export * from './pop';
export * from './retryWithDelay';
export * from './toEntityArray';
export * from './toEntityDictionary';
export * from './wrap';

/**
 * distinct until MD5 hash of object changes
 */
export function distinctUntilHashChanged<TModel>() {
    return distinctUntilChanged<TModel>((x, y) => Md5.hashStr(JSON.stringify(x)) === Md5.hashStr(JSON.stringify(y)));
}

/**
 * filter requires collection to contain elements
 */
export function hasElements<TModel>() {
    return filter<Array<TModel>>(x => !!x && x.length > 0);
}

/**
 * first value emitted from the steam
 */
export function firstValueFrom<TModel>(x: Observable<TModel>) {
    return x.pipe(first());
}

/**
 * write a string to the console
 */
export function tapLog<TModel>(message: string) {
    return tap<TModel>(() => { console.log(message); });
}

/**
 * write a string to the console
 */
export function tapLogWithOutput<TModel>(message: string) {
    return tap<TModel>((x) => { console.log(message, x); });
}

/**
 * calls next on the provided subject using the current value
 */
export function pushToSubject<TModel>(bs: Subject<TModel>) {
    return tap<TModel>(x => bs.next(x));
}

/**
 * calls next with no value on the provided subject
 */
export function notifySubject<TModel>(bs: Subject<void>) {
    return tap<TModel>(() => bs.next());
}


export function createTimeBoxedOperation<TResult>(
    operationToResolve: Observable<TResult>,
    errorMessage: string,
    timeoutMs: number,
) {
    return race([
        operationToResolve,
        of(undefined).pipe(
            delay(timeoutMs),
            concatMap(v => throwError({ message: errorMessage }))
        )
    ]);
}


export function retryWhenOnline<T>(
    cooldownMS: number,
    maxFailCount: number,
    onlineNotifier$: Observable<boolean>
): MonoTypeOperatorFunction<T> {
    return retryWhen(
        (errors) => errors.pipe(
            scan(
                (acc, error) => ({ count: acc.count + 1, error }),
                {
                    count: 0,
                    error: undefined as any,
                }
            ),
            tap((current) => {
                if (current.count > maxFailCount) {
                    throw current.error;
                }
            }),
            // withLatestFrom(),
            concatMap(
                async () => {
                    const isOnline = true;
                    return iif(() => isOnline,
                        of(`request failed... retrying in ${cooldownMS}ms`)
                            .pipe(
                                tap(x => { console.log(x); }),
                                delay(cooldownMS)
                            ),
                        of('wait for network connection')
                            .pipe(
                                tap(x => { console.log(x); }),
                                concatMap(() => onlineNotifier$.pipe(isTruthy(), first()))
                            )
                    );
                })
        ));
}
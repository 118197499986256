<div *ngIf="!!(filteredWeightUnits$ | async) && !!(filteredVolumeUnits$ | async) ">
  <div class="mat-panel-section-header-standard">{{'ADD_ITEMS.STANDARD_UNITS' | translate}}</div>
  <div class="mat-panel-sub-text">{{'ADD_ITEMS.PROVIDE_WEIGHT_AND_VOLUME' | translate}}<br>{{'ADD_ITEMS.PROVIDE_WEIGHT_AND_VOLUME_EXAMPLES' | translate}}</div>
  <div class="form-wrapper">
    <form [formGroup]="measurementUnitForm">
      <div class="form-group-wrapper flex-wrap">
        <div>
          <mat-form-field class="unit-measure-form-field">
            <mat-label>{{ 'ADD_ITEMS.WEIGHT_UNIT' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADD_ITEMS.WEIGHT_UNIT' | translate }}"
              type="text"
              autocomplete="off"
              class="custom-item-input"
              formControlName="catchWeightUnit"
              maxlength="200"
              [matAutocomplete]="measureWeightUnitAuto"
              (blur)="onCatchWeightUnitBlur($event)"
            />
            <mat-autocomplete #measureWeightUnitAuto="matAutocomplete" class="custom-item-auto" [displayWith]="unitIdToDisplayName.bind( this, (currentLang$ | async),  (weightUnits$ | async))" >
              <mat-option *ngFor="let unit of filteredWeightUnits$ | async"
                          [value]="unit.id"
                          class="custom-item-mat-option"
              >
                {{ getUnitDisplayName(unit, (currentLang$ | async)) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="unit-measure-form-field-wrapper">
          <div>
            <mat-form-field class="unit-measure-qty-form-field">
              <mat-label>{{ getUnitQtyPlaceholder((currentLang$ | async),  (weightUnits$ | async), 'catchWeightUnit') }}</mat-label>
              <input
                matInput
                appTwoDigitDecimalNumber
                [regex]="qtyRegex"
                placeholder="{{ getUnitQtyPlaceholder((currentLang$ | async),  (weightUnits$ | async), 'catchWeightUnit') }}"
                type="text"
                autocomplete="off"
                class="custom-item-input"
                formControlName="netWeight"
                maxlength="200"
                (blur)="onNetWeightBlur()"
                [value]="measurementUnitForm?.controls.netWeight.value"
                />
            </mat-form-field>
          </div>
          <div class="unit-measure-forward-slash">/</div>
          <mat-form-field class="parent-unit-form-field">
            <mat-label>{{ 'ADD_ITEMS.PURCHASE_UNIT' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADD_ITEMS.PURCHASE_UNIT' | translate }}"
              type="text"
              autocomplete="off"
              class="custom-item-input"
              formControlName="purchaseUnit"
              readonly
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="unit-measure-form-field">
            <mat-label>{{ 'ADD_ITEMS.VOLUME_UNIT' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADD_ITEMS.VOLUME_UNIT' | translate }}"
              type="text"
              autocomplete="off"
              class="custom-item-input"
              formControlName="volumeUnit"
              maxlength="200"
              [matAutocomplete]="volumeUnitAuto"
              (blur)="onVolumeUnitBlur($event)"
            />
            <mat-autocomplete #volumeUnitAuto="matAutocomplete" class="custom-item-auto" [displayWith]="unitIdToDisplayName.bind( this, (currentLang$ | async),  (volumeUnits$ | async))" >
              <mat-option *ngFor="let vUnit of filteredVolumeUnits$ | async"
                          [value]="vUnit.id"
                          class="custom-item-mat-option"
              >
                {{ getUnitDisplayName(vUnit, (currentLang$ | async)) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="unit-measure-form-field-wrapper">
          <div>
            <mat-form-field class="unit-measure-qty-form-field">
              <mat-label>{{ getUnitQtyPlaceholder((currentLang$ | async),  (volumeUnits$ | async), 'volumeUnit') }}</mat-label>
              <input
                matInput
                appTwoDigitDecimalNumber
                [regex]="qtyRegex"
                placeholder="{{ getUnitQtyPlaceholder((currentLang$ | async),  (volumeUnits$ | async), 'volumeUnit') }}"
                type="text"
                autocomplete="off"
                class="custom-item-input"
                formControlName="netVolume"
                maxlength="200"
                (blur)="onNetVolumeBlur()"
              />
            </mat-form-field>
          </div>
          <div class="unit-measure-forward-slash">/</div>
          <mat-form-field class="parent-unit-form-field">
            <mat-label>{{ 'ADD_ITEMS.PURCHASE_UNIT' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADD_ITEMS.PURCHASE_UNIT' | translate }}"
              type="text"
              autocomplete="off"
              class="custom-item-input"
              formControlName="purchaseUnit"
              readonly
            />
          </mat-form-field>
        </div>
      </div>
      <div class="mat-panel-section-header-custom">{{'ADD_ITEMS.CUSTOM_UNITS' | translate}}</div>
      <div class="mat-panel-sub-text">{{'ADD_ITEMS.PROVIDE_CUSTOM_UNITS' | translate}}</div>
      <div formArrayName="countingUnits">
        <div *ngIf="(!saveClicked)">
          <mat-spinner
          [diameter]="50"
          [strokeWidth]="5">
          </mat-spinner>
        </div>
        <div *ngFor="let unit of countingUnitFormArray.controls; let unitIndex=index" [formGroupName]="unitIndex">
          <div>
            <div class="unit-measure-form-field-wrapper form-group-wrapper">
              <div>
                <mat-form-field class="custom-unit-measure-form-field">
                  <mat-label>{{ 'ADD_ITEMS.UOM_NAME' | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'ADD_ITEMS.UOM_NAME' | translate }}"
                    type="text"
                    autocomplete="off"
                    class="custom-item-input"
                    id="custom-meas-unit-name-{{unitIndex}}"
                    formControlName="unitName"
                    maxlength="20"
                    #nameInput
                  />
                  <mat-error *ngIf="getCountingUnitName(unitIndex).hasError('duplicateCustomUnit')">{{'ADD_ITEMS.DUPLICATE_COUNTING_UNIT' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="unit-measure-form-field-wrapper">
                <div>
                  <mat-form-field class="custom-unit-measure-qty-form-field">
                    <mat-label>{{ getCustomUnitQtyPlaceholder(unitIndex) }}</mat-label>
                    <input
                      matInput
                      appTwoDigitDecimalNumber
                      [regex]="qtyRegex"
                      placeholder="{{ getCustomUnitQtyPlaceholder(unitIndex) }}"
                      type="text"
                      autocomplete="off"
                      class="custom-item-input"
                      id="custom-meas-unit-qty-{{unitIndex}}"
                      formControlName="unitQty"
                      maxlength="200"
                    />
                  </mat-form-field>
                </div>
                <div class="unit-measure-forward-slash">/</div>
                <mat-form-field class="parent-unit-form-field">
                  <mat-label>{{'ADD_ITEMS.AVAILABLE_UNITS' | translate}}</mat-label>
                  <input
                    matInput
                    placeholder="{{'ADD_ITEMS.AVAILABLE_UNITS' | translate}}"
                    type="text"
                    autocomplete="off"
                    class="custom-item-input"
                    formControlName="parentUnit"
                    maxlength="200"
                    [matAutocomplete]="parentUnitAuto"
                    (blur)="onStandardUnitBlur($event, unitIndex)"
                  />
                  <mat-autocomplete #parentUnitAuto="matAutocomplete" class="custom-item-auto" [displayWith]="unitIdToDisplayName.bind( this, (currentLang$ | async),  (measurementUnits$ | async))" >
                    <mat-option *ngFor="let sUnit of (filteredStandardUnitList$[unitIndex] | async)"
                                [value]="sUnit.id"
                                class="custom-item-mat-option"
                    >
                      {{ getUnitDisplayName(sUnit, (currentLang$ | async)) }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <button
                  class="delete-button"
                  (click)="deleteCountingUnitGroup(unitIndex)"
                  [disabled]="!isAllowedToEdit">
                  <mat-icon class="icon delete-icon" svgIcon="trash-icon"></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <button class="add-unit-button" (click)="addCountingUnitGroup('', '', '')" [disabled]="(!isAllowedToEdit) || (!saveClicked)">
      <mat-icon class="icon add-unit-plus-icon" svgIcon="plus-icon"></mat-icon>
      <span class="add-unit-button-label">{{'ADD_ITEMS.ADD_CUSTOM_UNIT_MEASURE' | translate}}</span>
    </button>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import {
  CustomerPK, IAppContext, StorageArea, StorageAreaLastModified, StorageAreaUpdate,
  Worksheet, WorksheetItem, WorksheetItemGroupPost, WorksheetItemLastModified,
  WorksheetItemPost, WorksheetLastModified, WorksheetUpdate
} from '@gfs/shared-models';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { InjectionTokens } from '../../injection-tokens';
import { Audix } from '../core/audix';
import { Mux, MuxConfig } from '../extensions/rxjs';
import { tapAudit } from '../operators';
type UpdateWorksheetItemRequest = { worksheetId: string, storageAreaId: string, worksheetItem: WorksheetItem; };
export const serverTimeoutMessage = 'the connection to the server timed out';
const tenSeconds = 10000;

@Injectable({
  providedIn: 'root'
})
export class WorksheetService {
  private baseUrl: string;

  audix = new Audix<UpdateWorksheetItemRequest>('UpdateWorksheetItem', (x) => x.worksheetItem.id);
  muxUpdateWorksheetItemRequest = new Mux<UpdateWorksheetItemRequest, WorksheetLastModified>(
    {
      bufferTime: 5000,
      maxRetries: 4,
      retryCooldown: 5000,
    } as MuxConfig,
    (req) => req.worksheetItem.id,
    (req) => of(req).pipe(
      tapAudit(this.audix),
      concatMap(x => {
        return this.updateWorksheetItem_core(x);
      })
    ),
    this.appContext.isOnline$);



  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getWorkSheet(worksheetId: string): Observable<Worksheet> {
    return this.http.get<Worksheet>(`${this.baseUrl}/worksheets/${worksheetId}`);
  }

  createBlankWorksheet(customerPK: CustomerPK): Observable<Worksheet> {
    return this.http.post<Worksheet>(`${this.baseUrl}/worksheet-builder/blank`, { customerPK });
  }

  copyWorksheet(worksheetId: string, customerPK: CustomerPK): Observable<Worksheet> {
    return this.http.post<Worksheet>(`${this.baseUrl}/worksheet-builder/copy`, { customerPK, worksheetId });
  }

  importWorksheetFromOrderGuid(
    customerPK: CustomerPK,
    sorting: boolean): Observable<Worksheet> {
    return this.http.post<Worksheet>(
      `${this.baseUrl}/worksheet-builder/order-guide`,
      { customerPK, sorting }
    );
  }

  importWorksheetFromCustomGuide(
    customerPK: CustomerPK,
    customGuideIds: string[],
    sorting: string
  ): Observable<Worksheet> {
    return this.http.post<Worksheet>(
      `${this.baseUrl}/worksheet-builder/custom-guide`,
      { customerPK, customGuideIds, sorting }
    );
  }

  createStorageArea(worksheetId: string, storageAreaName: string): Observable<StorageAreaLastModified> {
    const newStorageArea: StorageArea = {
      id: null,
      name: storageAreaName,
      worksheetItems: null,
      expandStatus: false
    };
    return this.http.post<StorageAreaLastModified>(
      `${this.baseUrl}/worksheets/${worksheetId}/storage-areas`,
      newStorageArea
    );
  }

  updateStorageAreaName(worksheetId: string, storageAreaId: string, fields: StorageAreaUpdate): Observable<WorksheetLastModified> {
    return this.http.patch<WorksheetLastModified>(
      `${this.baseUrl}/worksheets/${worksheetId}/storage-areas/${storageAreaId}`, fields);
  }

  deleteStorageArea(worksheetId: string, storageArea: StorageArea): Observable<any> {
    return this.http.delete<WorksheetLastModified>(
      `${this.baseUrl}/worksheets/${worksheetId}/storage-areas/${storageArea.id}`
    );
  }
  createWorksheetItem(
    worksheetId: string,
    storageAreaId: string,
    worksheetItemPost: WorksheetItemPost
  ): Observable<WorksheetItemLastModified> {
    return of(worksheetItemPost)
      .pipe(
        map(e => {
          const invalidEntries = ['', null, undefined];
          if (invalidEntries.indexOf(e.secondaryUnitType) > -1) {
            e.secondaryUnitType = InventoryConstants.COUNTING_UNIT_TYPES.LITERAL;
          }
          if (invalidEntries.indexOf(e.secondaryUnit) > -1) {
            e.secondaryUnit = '';
            e.secondaryUnitQty = '';
          }

          return e;
        }),
        concatMap(e => {
          const url = `${this.baseUrl}/worksheets/${worksheetId}/storage-areas/${storageAreaId}/worksheet-items`
          return this.http.post<WorksheetItemLastModified>(url, worksheetItemPost);
        })
      );
  }

  createGroupWorksheetItems(
    worksheetItemGroupPost: WorksheetItemGroupPost
  ): Observable<WorksheetItemLastModified[]> {
    return this.http.post<WorksheetItemLastModified[]>(`${this.baseUrl}/worksheets/groups/worksheet-items`, worksheetItemGroupPost);
  }

  patchWorksheet(worksheetId: string, fields: WorksheetUpdate): Observable<WorksheetLastModified> {
    return this.http
      .patch<WorksheetLastModified>(`${this.baseUrl}/worksheets/${worksheetId}`, fields);
  }

  deleteWorksheet(worksheetId: string): Observable<WorksheetLastModified> {
    return this.http.delete<WorksheetLastModified>(`${this.baseUrl}/worksheets/${worksheetId}`);
  }

  deleteInventoryItem(worksheetId: string, storageAreaId: string, inventoryItem: WorksheetItem): Observable<any> {
    const url = `${this.baseUrl}/worksheets/${worksheetId}/storage-areas/${storageAreaId}/worksheet-items/${inventoryItem.id}`;
    return this.http.delete<WorksheetLastModified>(url);
  }

  updateWorksheetItem(worksheetId: string, storageAreaId: string, worksheetItem: WorksheetItem): Observable<WorksheetLastModified> {
    console.log('worksheet-mux:worksheetService.updateWorksheetItem:begin');
    return this.muxUpdateWorksheetItemRequest.next$(of({ worksheetId, storageAreaId, worksheetItem }));
  }

  updateWorksheetItem_core(aaa: UpdateWorksheetItemRequest): Observable<WorksheetLastModified> {
    const { worksheetId, storageAreaId, worksheetItem } = aaa;
    console.log('audit http:', { itemId: worksheetItem.itemId, qty: worksheetItem.primaryUnitQty, qty2: worksheetItem.secondaryUnitQty });
    if (worksheetItem.primaryUnitQty) {
      worksheetItem.primaryUnitQty = parseFloat(worksheetItem.primaryUnitQty.toString());
    }
    if (worksheetItem.secondaryUnitQty) {
      worksheetItem.secondaryUnitQty = parseFloat(worksheetItem.secondaryUnitQty.toString());
    }
    return this.http.patch<WorksheetLastModified>(
      `${this.baseUrl}/worksheets/${worksheetId}/storage-areas/${storageAreaId}/worksheet-items/${worksheetItem.id}`, worksheetItem);
  }

  refreshWorksheetItems(worksheetId: string): Observable<WorksheetLastModified> {
    const url = `${this.baseUrl}/worksheets/${worksheetId}/refresh-worksheet-items`;
    return this.http.get<WorksheetLastModified>(url);
  }
}
import { Injectable } from '@angular/core';
import {
  ActionTypes,
  ActionUnion,
  DeleteCustomItemError,
  DeleteCustomItemSuccess,
  DeleteGeneralItemAttempt,
  DeleteGeneralItemError,
  DeleteGeneralItemSuccess,
  DeleteGroupCustomItemSuccess,
  DeleteGroupGeneralItemAttempt,
  DeleteGroupGeneralItemsSuccess,
  GetAllCustomItemsAttempt,
  GetAllCustomItemsError,
  GetAllCustomItemsSuccess,
  GetAllGeneralItemsError,
  GetAllGeneralItemsSuccess,
  UpdateCustomItemPriceError,
  UpdateCustomItemPriceSuccess } from '../actions/customerItems.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { CustomItemService, GeneralItemService } from '@gfs/shared-services';
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { CustomItemUpdate } from '@gfs/shared-models';

@Injectable()
export class CustomerItemsEffects {
  constructor(
    private actions$: Actions<ActionUnion>,
    private store: Store<AppState>,
    private customItemService: CustomItemService,
    private generalItemService: GeneralItemService
  ) { }

  static customItemsDeleted$ = new Subject<{}>();
  getAllCustomItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetAllCustomItemsAttempt),
    withLatestFrom(this.store.pipe(filter(state => !!state.auth.pk))),
    mergeMap(([action, state]) => this.customItemService.getCustomItems(null, false, state.auth.pk)
      .pipe(
        map(items => {
          CustomerItemsEffects.customItemsDeleted$.next(true);
          return new GetAllCustomItemsSuccess(items)
        }),
        catchError(err => of(new GetAllCustomItemsError(err)))
      )
    )
  ));


  getAllGeneralItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetAllGeneralItemsAttempt),
    withLatestFrom(this.store.pipe(filter(state => !!state.auth.pk))),
    mergeMap(([action, state]) => this.generalItemService.getGeneralItems(null, false, state.auth.pk)
      .pipe(
        map(items => new GetAllGeneralItemsSuccess(items)),
        catchError(err => of(new GetAllGeneralItemsError(err)))
      )
    )
  ));


  updateCustomItemPrice$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateCustomItemPriceAttempt),
    mergeMap((action) => {
      const customItemUpdate: CustomItemUpdate = { purchaseUnit: action.payload.purchaseUnit };
      return this.customItemService.updateCustomItem(action.payload.customItemId, customItemUpdate)
        .pipe(
          map(item => new UpdateCustomItemPriceSuccess(item)),
          catchError(err => of(new UpdateCustomItemPriceError(err)))
        );
    })
  ));


  deleteCustomItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteCustomItemAttempt),
    mergeMap((action) =>  this.customItemService.deleteCustomItem(action.payload.customItemId)
      .pipe(
        concatMap(customItem => [
          new DeleteCustomItemSuccess(customItem),
          new DeleteGeneralItemAttempt(action.payload.generalItemId)
        ]),
        catchError((err) => of(new DeleteCustomItemError(err)))
      )
    )
  ));
  deleteGroupCustomItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteGroupCustomItemAttempt),
    mergeMap((action) =>  this.customItemService.deleteGroupCustomItem(action.payload.customItemIds)
      .pipe(
        concatMap(customItem => {
          return [
          new DeleteGroupCustomItemSuccess(action.payload.customItemIds),
          new DeleteGroupGeneralItemAttempt(action.payload.generalItemIds),
        ]
      }),
        catchError((err) => {
          CustomerItemsEffects.customItemsDeleted$.next(true);
          return  of(new DeleteCustomItemError(err))})
      )
    )
  ));
  deleteGroupCustomItemSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteGroupCustomItemSuccess),
    map((action) =>{
      return  new GetAllCustomItemsAttempt()
    })
  ));

  deleteCustomItemSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteCustomItemSuccess),
    map((action) => new GetAllCustomItemsAttempt())
  ));

  deleteGeneralItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteGeneralItemAttempt),
    mergeMap((action) => this.generalItemService.deleteGeneralItem(action.payload)
      .pipe(
        map(generaItem => new DeleteGeneralItemSuccess(generaItem)),
        catchError((err) => of(new DeleteGeneralItemError(err)))
      )
    )
  ));

  deleteGroupGeneralItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteGroupGeneralItemAttempt),
    mergeMap((action) => this.generalItemService.deleteGroupGeneralItem(action.payload)
      .pipe(
        map(generaItem =>{
          return new DeleteGroupGeneralItemsSuccess(action.payload)
          }),
        catchError((err) => of(new DeleteGeneralItemError(err)))
      )
    )
  ));
}

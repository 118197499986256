import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  SetTitle = '[Layout] Set Title',
  SetMobile = '[Layout] Set Mobile',
  SetLanguage = '[Layout] Set Language',
  SetHeaderHeight = '[Layout] Set Header Height'
}

export class SetTitle implements Action {
  readonly type = LayoutActionTypes.SetTitle;
  constructor(public payload: string) {}
}

export class SetMobile implements Action {
  readonly type = LayoutActionTypes.SetMobile;
  constructor(public payload: boolean) {}
}

export class SetLanguage implements Action {
  readonly type = LayoutActionTypes.SetLanguage;
  constructor(public payload: string) {}
}

export class SetHeaderHeight implements Action {
  readonly type = LayoutActionTypes.SetHeaderHeight;
  constructor(public payload: number) {}
}


export type LayoutActionUnion =
  SetTitle |
  SetMobile |
  SetLanguage |
  SetHeaderHeight;

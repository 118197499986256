import { InjectionToken } from '@angular/core';
import { Constants } from '@gfs/constants';

export const APPLICATION_USER_ROLE = new InjectionToken<ApplicationUserRole>('application-user-role');

export enum ApplicationUserRole {
    Customer = 'Customer',
    Employee = 'Employee',
}

export function applicationUserRoleFactory(
    window: Window
): ApplicationUserRole {
    const employeePaths = Constants
        .RecipeFeaturePaths
        .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee];
    const isEmployee = Object
        .values(employeePaths)
        .concat(Constants.RecipeFeaturePaths.RECIPE_PROFIT_CALCULATOR.callback)
        .some((path: string) => 0 < window.location.href.indexOf(path));

    return isEmployee
        ? ApplicationUserRole.Employee
        : ApplicationUserRole.Customer;
}

import { ItemCategory } from '@gfs/shared-models';
import { arrayToEntityDictionary, entityDictionaryToArray } from '@gfs/shared-services';
import { GeneralLedgerAccount, GeneralLedgerAccountPage, ItemCategoryGLAccount } from '../models/general-ledger.model';
import { ActionTypes, ActionUnion, DeleteGeneralLedgerCompleted } from './general-ledger.actions';


export interface GeneralLedgerState {
  itemCategories: ItemCategory[];
  generalLedgerAccountPage: GeneralLedgerAccountPage;
  isLoading: boolean;
  isSaving: boolean;
}

export const initialState: GeneralLedgerState = {
  itemCategories: [],
  generalLedgerAccountPage: undefined,
  isLoading: false,
  isSaving: false
};

export function generalLedgerReducer(
  state: GeneralLedgerState = initialState,
  action: ActionUnion
): GeneralLedgerState {
  switch (action.type) {
    case ActionTypes.LoadGeneralLedger: {
      return {
        ...initialState,
        isLoading: true
      };
    }


    case ActionTypes.UpdateItemCategoryAccount:
    case ActionTypes.AddAccount: {
      return {
        ...state,
        isSaving: true
      };
    }

    case ActionTypes.AddAccountCompleted: {
      const accounts = state.generalLedgerAccountPage.generalLedgerSection.glAccounts;
      const keyedAccounts = arrayToEntityDictionary(accounts, account => account.id);
      const keyedAccountsUpdated = { ...keyedAccounts, [action.model.id]: action.model };
      const updatedAccounts = entityDictionaryToArray<GeneralLedgerAccount>(keyedAccountsUpdated);
      return {
        ...state,
        isSaving: false,
        generalLedgerAccountPage: {
          ...state.generalLedgerAccountPage,
          generalLedgerSection: {
            ...state.generalLedgerAccountPage.generalLedgerSection,
            glAccounts: updatedAccounts
          }
        }
      };
    }

    case ActionTypes.DeleteGeneralLedgerCompleted: {
      return handleDelete(state, action);
    }

    case ActionTypes.AddItemCategoryToAccountCompleted:
    case ActionTypes.RemoveItemCategoryFromAccountCompleted: {
      const itemCategoryGLAccounts = state.generalLedgerAccountPage.itemCategorySection.itemCategories;
      const keyedItemCategoryGLAccounts = arrayToEntityDictionary(itemCategoryGLAccounts, itemCategory => itemCategory.itemCategoryId);
      const keyedItemCategoryGLAccountsUpdated = {
        ...keyedItemCategoryGLAccounts,
        [action.model.itemCategoryId]: action.model
      };
      const updatedItemCategoryGLAccounts = entityDictionaryToArray<ItemCategoryGLAccount>(keyedItemCategoryGLAccountsUpdated);
      return {
        ...state,
        generalLedgerAccountPage: {
          ...state.generalLedgerAccountPage,
          itemCategorySection: {
            ...state.generalLedgerAccountPage.itemCategorySection,
            itemCategories: updatedItemCategoryGLAccounts
          }
        }
      };
    }

    case ActionTypes.UpdateItemCategoryAccountCompleted:
    case ActionTypes.ActionFailed: {
      return {
        ...state,
        isLoading: false,
        isSaving: false
      };
    }


    case ActionTypes.LoadGeneralLedgerCompleted: {
      return {
        ...state,
        isLoading: false,
        generalLedgerAccountPage: action.payload.accountsDto,
        itemCategories: action.payload.itemCategories
      };
    }


    case ActionTypes.UpdateAccountPopUp: {
      return {
        ...state,
        isSaving: true
      };
    }

    case ActionTypes.UpdateAccountCompleted: {
      const accounts = state.generalLedgerAccountPage.generalLedgerSection.glAccounts;
      const keyedAccounts = arrayToEntityDictionary(accounts, account => account.id);
      const keyedAccountsUpdated = { ...keyedAccounts, [action.model.id]: action.model };
      const updatedAccounts = entityDictionaryToArray<GeneralLedgerAccount>(keyedAccountsUpdated);
      return {
        ...state,
        isSaving: false,
        generalLedgerAccountPage: {
          ...state.generalLedgerAccountPage,
          generalLedgerSection: {
            ...state.generalLedgerAccountPage.generalLedgerSection,
            glAccounts: updatedAccounts
          }
        }
      };
    }


    default: {
      return state;
    }
  }
}

export function removeItemCategoryGLAccount(models: ItemCategoryGLAccount[], remove: ItemCategoryGLAccount): ItemCategoryGLAccount[] {
  return models.filter(itemCategoryGLAccounts =>
    itemCategoryGLAccounts.generalLedgerAccountId !== remove.generalLedgerAccountId && itemCategoryGLAccounts.itemCategoryId !== remove.itemCategoryId);
}

export function handleDelete(
  state: GeneralLedgerState,
  action: DeleteGeneralLedgerCompleted
) {

  const accountId = action.model.id;

  const glAccounts = state.generalLedgerAccountPage.generalLedgerSection.glAccounts;
  const itemCategories = state.generalLedgerAccountPage.itemCategorySection.itemCategories;

  const glAccountsToDelete = glAccounts.filter(account => account.id !== accountId);


  return {
    ...state,
    isSaving: false,
    generalLedgerAccountPage: {
      ...state.generalLedgerAccountPage,
      generalLedgerSection: {
        ...state.generalLedgerAccountPage.generalLedgerSection,
        glAccounts: glAccountsToDelete
      },
      itemCategorySection: {
        ...state.generalLedgerAccountPage.itemCategorySection,
        itemCategories: itemCategories
      }
    }
  };
}


export class WorksheetPrintConstants {
  public static readonly LOGO_BASE64_FILE_TYPE = 'JPEG';
  public static readonly LOGO_HEIGHT = 18;
  public static readonly LOGO_HEIGHT_FR = 21;
  public static readonly LOGO_WIDTH = 57;
  public static readonly LOGO_WIDTH_FR = 57;
  public static readonly LOGO_MARGIN_TOP = 22;
  public static readonly LOGO_MARGIN_TOP_FR = 20;
  public static readonly TITLE_MARGIN_TOP = 26;
  public static readonly SUBTITLE_MARGIN_TOP = 39;

  public static readonly HEADER_HEIGHT = 45;

  public static readonly FONT_SIZE_LARGE = 10;
  public static readonly FONT_SIZE_MEDIUM = 8;
  public static readonly FONT_FAMILY_SANS = 'helvetica';
  public static readonly FONT_STYLE_BOLD = 'bold';
  public static readonly FONT_STYLE_NORMAL = 'normal';

  public static readonly TEXT_CHAR_SPACE_BODY = 0.9;
  public static readonly TEXT_CHAR_SPACE_FOOTER = 0;

  public static readonly PAGE_TEXT_COLOR = [0, 0, 0];
  public static readonly PAGE_FILL_COLOR = [255, 255, 255];

  public static readonly PAGE_UOM = 'pt';
  public static readonly PAGE_SIDE_MARGIN = 23;
  public static readonly PAGE_MARGIN_TOP = 69;
  public static readonly PAGE_MARGIN_BOTTOM = 48;
  public static readonly PAGE_MARGIN_LINE_WIDTH = 1;

  public static readonly FOOTER_MARGIN_BOTTOM = 38;

  public static readonly TABLE_STORAGE_AREA_LINE_HEIGHT = 11;
  public static readonly TABLE_STORAGE_AREA_MARGIN_TOP = 14;
  public static readonly TABLE_WIDTH = 570;
  public static readonly TABLE_OFFSET_FROM_STORAGE_AREA = 3;
  public static readonly TABLE_LINE_COLOR = [80, 80, 80];
  public static readonly TABLE_LINE_WIDTH = 0.5;

  public static readonly TABLE_HEADER_MIN_CELL_HEIGHT = 15;
  public static readonly MIN_CELL_HEIGHT = 10;
  public static readonly CELL_PADDING = 2.75;
  public static readonly UNIT_COLUMN_BOX_LENGTH = 10;
  public static readonly UNIT_COLUMN_BOX_PADDING = 6;
  public static readonly UNIT_COLUMN_BOX_RADIUS = 1;
  public static readonly COLUMN_DIVIDER_OFFSET = 3;
  public static readonly PAGE_MARGIN_BOTTOM_Y_THRESHOLD =
    WorksheetPrintConstants.PAGE_MARGIN_BOTTOM +
    WorksheetPrintConstants.MIN_CELL_HEIGHT * 3;

  public static readonly PRINT_COLUMN_ITEM = 'PRINT.TABLE_HEADERS.ITEM';
  public static readonly PRINT_COLUMN_ITEM_WIDTH = 40;

  public static readonly PRINT_COLUMN_PACK_SIZE = 'PRINT.TABLE_HEADERS.PACK_SIZE';
  public static readonly PRINT_COLUMN_PACK_SIZE_WIDTH = 140;

  public static readonly PRINT_COLUMN_BRAND = 'PRINT.TABLE_HEADERS.BRAND';
  public static readonly PRINT_COLUMN_BRAND_WIDTH = 69;

  public static readonly PRINT_COLUMN_DESCRIPTION =
    'PRINT.TABLE_HEADERS.DESCRIPTION';
  public static readonly PRINT_COLUMN_DESCRIPTION_WIDTH = 177;
  public static readonly DESCRIPTION_HEADER_LEFT_PADDING = 5;

  public static readonly PRINT_COLUMN_PRIMARY_UNIT = 'PRINT.TABLE_HEADERS.PRIMARY_UNIT';
  public static readonly PRINT_COLUMN_PRIMARY_UNIT_WIDTH = 35;

  public static readonly PRINT_COLUMN_SECONDARY_UNIT = 'PRINT.TABLE_HEADERS.SECONDARY_UNIT';
  public static readonly PRINT_COLUMN_SECONDARY_UNIT_WIDTH = 35;
  public static readonly COUNT_HEADER_LEFT_PADDING = 5;

  public static readonly PRINT_COLUMN_INPUT_FIELD_WIDTH = 37;
  public static readonly INVENTORY_FIELDS_HEADER =
    'PRINT.TABLE_HEADERS.INVENTORY_FIELDS_HEADER';

  public static readonly PRINT_UNASSIGNED_STORAGE_AREA =
    'PRINT.TABLE_HEADERS.UNASSIGNED_STORAGE_AREA';
  public static readonly PRINT_LOGO_FILEPATH = 'PRINT.LOGO.GFS';

  public static readonly PRINT_FOOTER_PAGE = 'PRINT.PAGE_FOOTER';

  public static readonly PRINT_HEADER_PAGE_COUNT_DATE = 'PRINT.PAGE_HEADER.COUNT_DATE';
  public static readonly PRINT_HEADER_PAGE_CREATED = 'PRINT.PAGE_HEADER.CREATED';
}

import { CountableItem } from '@gfs/shared-models';
import * as inventoryItems from '../actions/inventoryItems.actions';

export interface State {
  inventoryItems: { [s: string]: CountableItem };
  error: string;
}

export const initialState: State = {
  inventoryItems: {},
  error: null,
};

export function inventoryItemsReducer(
  state: State = initialState,
  action: inventoryItems.ActionUnion
): State {
  switch (action.type) {
    case inventoryItems.ActionTypes.GetGeneralItemsAttempt: {
      updateLoadingGeneralItemsState(state, 1);
      return state;
    }
    case inventoryItems.ActionTypes.GetGeneralItemsSuccess: {

      state = updateLoadingGeneralItemsState(state, -1);

      // convert array of items into items mapped by itemId
      const itemMap = action.payload.items.reduce((acc, next) => {
        if (next.gfsItem) {
          acc[next.gfsItem.id] = next;
        } else if (next.generalItem) {
          acc[next.generalItem.id] = next;
        }
        return acc;
      }, {});

      return {
        ...state,
        inventoryItems: {
          ...state.inventoryItems,
          ...itemMap,
        },
      };
    }
    case inventoryItems.ActionTypes.GetGeneralItemsError: {

      updateLoadingGeneralItemsState(state, -1);

      return {
        ...state,
        error: action.error.error,
      };
    }
    case inventoryItems.ActionTypes.GetInventoryItemsAttempt: {
      updateLoadingInventoryItemsState(state, 1);

      return { ...state };

    }
    case inventoryItems.ActionTypes.GetInventoryItemsSuccess: {

      updateLoadingInventoryItemsState(state, -1);

      // convert array of items into items mapped by itemId
      const itemMap = action.payload.items.reduce((acc, next) => {
        if (next.gfsItem) {
          acc[next.gfsItem.id] = next;
        } else if (next.generalItem) {
          acc[next.generalItem.id] = next;
        }
        return acc;
      }, {});

      return {
        ...state,
        inventoryItems: {
          ...state.inventoryItems,
          ...itemMap,
        },
      };
    }
    case inventoryItems.ActionTypes.GetInventoryItemsError: {
      updateLoadingInventoryItemsState(state, -1);
      return {
        ...state,
        error: action.payload,
      };
    }
    case inventoryItems.ActionTypes.AddCountableItemsToWorksheet: {
      const items = action.payload.filter(
        (item) => {
          return (item.gfsItem != null && !(item.gfsItem.id in state.inventoryItems))
            || (item.recipeItem != null && !(item.recipeItem.id in state.inventoryItems));
        }
      );
      const newInventoryItems = items.reduce((result, item) => {
        if (item.gfsItem) {
          result[item.gfsItem.id] = item;
        }
        else if (item.recipeItem) {
          result[item.recipeItem.id] = item;
        }
        return result;
      }, state.inventoryItems);

      return {
        ...state,
        inventoryItems: newInventoryItems,
      };
    }
    case inventoryItems.ActionTypes.GetCustomItemSuccess: {
      // convert array of items into items mapped by itemId
      const customMap = action.payload.reduce((acc, next) => {
        if (next.customItem) {
          acc[next.customItem.id] = next;
        }
        return acc;
      }, {});

      const itemMap = action.payload.reduce((acc, next) => {
        if (next.generalItem) {
          const countableItem: CountableItem =
            customMap[
            next.generalItem.productList
              .filter((primary) => primary.primaryProduct === true)
              .map((item) => item.id)[0]
            ];
          if (countableItem) {
            const itemToAdd = {
              ...next,
              customItem: countableItem.customItem,
            };
            acc[next.generalItem.id] = itemToAdd;
          }
        }
        return acc;
      }, {});

      return {
        ...state,
        inventoryItems: {
          ...state.inventoryItems,
          ...itemMap,
        },
      };
    }
    case inventoryItems.ActionTypes.UpdateCustomItemsSuccess: {
      if (state.inventoryItems[action.payload.generalItemId]) {
        state.inventoryItems[action.payload.generalItemId].customItem =
          action.payload.customItem;
      }

      return {
        ...state,
        inventoryItems: {
          ...state.inventoryItems,
        },
      };
    }
    case inventoryItems.ActionTypes.UpdateGeneralItemsSuccess: {
      if (state.inventoryItems[action.payload.generalItem.id]) {
        state.inventoryItems[action.payload.generalItem.id].generalItem =
          action.payload.generalItem;
      }

      return {
        ...state,
        inventoryItems: {
          ...state.inventoryItems,
        },
      };
    }
    case inventoryItems.ActionTypes.ClearInventoryItems: {
      return {
        ...state,
        inventoryItems: {}
      };
    }
    case inventoryItems.ActionTypes.SetCountableItem: {
      return {
        ...state,
        inventoryItems: {
          [action.value.worksheetItem.id]: action.value.countableItem
        }
      }
    }
    default: {
      return state;
    }
  }

  function updateLoadingGeneralItemsState(currentState: any, valueShift) {
    const j = currentState.generalItemPending ?? 0;
    currentState.generalItemPending = valueShift + j;
    currentState.loadingGeneralItems = currentState.generalItemPending > 0;
    return currentState;
  }
  function updateLoadingInventoryItemsState(currentState: any, valueShift) {
    const j = currentState.inventoryItemPending ?? 0;
    currentState.inventoryItemPending = valueShift + j;
    currentState.loadingInventoryItems = currentState.inventoryItemPending > 0;

    return currentState;
  }
}
<div
  class="modal-container"
  [ngStyle]="{'height': addItemStep === 'SEARCH' ? '627px' : '656px'}"
>
  <div
    class="mat-dialog-header modal-header"
    *ngIf="addItemStep === 'SEARCH'"
  >
    <div
      id="modal-title"
      class="modal-title"
    >
      <span>{{'ADD_ITEMS.ADD_ITEMS_TITLE' | translate}}</span>
    </div>
  </div>
  <div
    class="modal-contents"
    [hidden]="addItemStep !== 'SEARCH'"
  >
    <div
      id="inventory-info-bar"
      class="inventory-info-bar"
    >
      <span *ngIf="worksheet">
        {{ translateName(worksheet.name) }}
      </span>
    </div>
    <div class="search-wrapper">
      <mat-form-field
        [floatLabel]="'never'"
        class="search-form"
      >
        <input
          matInput
          [formControl]="queryField"
          type="text"
          class="search-box"
          placeholder="{{ 'ADD_ITEMS.PLACEHOLDER' | translate }}"
          #searchString
          name="searchString"
          autocomplete="off"
          [matAutocomplete]="auto"
          id="item-search-input"
          (keyup.enter)="searchForValue(searchString.value)"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          class="results-autocomplete"
          (optionSelected)="searchForValue($event.option.value)"
        >
          <div *ngIf="queryField.value">
            <mat-option
              *ngFor="let result of resultList$ | async; let i = index"
              [value]="result.text"
              id="autocomplete-result-{{i}}">
              <span>
              <mat-icon svgIcon="search_icon" class="result-search-icon"></mat-icon>
              <strong>{{result.text.substring(0,result.termLength)}}</strong>{{result.text.substring(result.termLength)}}
              </span>
              <img
                class="result-arrow-icon"
                src="assets/images/arrow-up-left.svg"
                alt="{{ 'ADD_ITEMS.PLACEHOLDER' | translate }}"
              />
            </mat-option>
          </div>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="add-items-filters-container">
      <div
        tabindex="0"
        class="add-items-filter"
        [ngClass]="selectedFilter === 'CATALOGUE' ? 'selected' : ''"
        *ngIf="true"
        (click)="selectFilter('CATALOGUE')"
        (keyup.enter)="selectFilter('CATALOGUE')"
        [hidden]="!configService.getSettings().FF_SEARCH_FILTER_CATALOGUE"
      >
        {{ localizeCatalogueSpelling$ | async }}
      </div>
      <div
        tabindex="0"
        class="add-items-filter"
        [ngClass]="selectedFilter === 'ORDER_GUIDE' ? 'selected' : ''"
        *ngIf="true"
        (click)="selectFilter('ORDER_GUIDE')"
        (keyup.enter)="selectFilter('ORDER_GUIDE')"
        [hidden]="!configService.getSettings().FF_SEARCH_FILTER_ORDER_GUIDE_ITEMS"
      >
        {{ 'ADD_ITEMS.ORDER_GUIDE_ITEMS' | translate }}
      </div>
      <div
        tabindex="0"
        class="add-items-filter"
        [ngClass]="selectedFilter === 'NONGFS_ITEMS' ? 'selected' : ''"
        *ngIf="true"
        (click)="selectFilter('NONGFS_ITEMS')"
        (keyup.enter)="selectFilter('NONGFS_ITEMS')"
        [hidden]="shouldHideNonGfsItemsTab()"
      >
        {{ 'ADD_ITEMS.NONGFS_ITEMS' | translate }}
      </div>
      <div
        tabindex="0"
        class="add-items-filter"
        [ngClass]="selectedFilter === 'RECIPES_ITEMS' ? 'selected' : ''"
        *ngIf="true"
        (click)="selectFilter('RECIPES_ITEMS')"
        (keyup.enter)="selectFilter('RECIPES_ITEMS')"
        [hidden]="shouldHideRecipeItemsTab()"
      >
        {{ 'ADD_ITEMS.RECIPES_ITEMS' | translate }}
      </div>
    </div>
    <div class="results-container">
      <div class="results-contents">
        <div
          class="spinner-wrapper"
          *ngIf="searchingItems$ | async"
        >
          <mat-spinner
            [diameter]="70"
            [strokeWidth]="7"
          ></mat-spinner>
        </div>
        <ng-container *ngIf="!(searchingItems$ | async)">
          <div
            *ngIf="showNoSearchPerformed()"
            class="no-search-wrapper"
          >
            <img
              class="no-search-icon"
              src="assets/images/search-gray-background.svg"
              alt="{{ 'ADD_ITEMS.NO_SEARCH_MAIN' | translate }}"
            />
            <div class="main-text">{{ 'ADD_ITEMS.NO_SEARCH_MAIN' | translate }}
              <span class="no-results-bolded">{{selectedSearchText}}</span>
            </div>
            <div class="sub-text">{{ 'ADD_ITEMS.NO_SEARCH_SUB' | translate }}</div>
          </div>
          <cdk-virtual-scroll-viewport
            #itemsViewport
            *ngIf="showResults(itemList$) | async"
            itemSize="108"
            class="list-container"
          >
            <div
              *ngFor="let item of (itemList$ | async) | slice : 0 : displayAmount ; let itemIndex=index"
              class="item-card-wrapper"
            >
              <gfs-add-item-card
                *ngIf="selectedFilter === SearchTabTypes.Catalogue || selectedFilter === SearchTabTypes.OrderGuideItems"
                [item]="item"
                [isAdd]="true"
                [addItemIndex]="itemIndex"
                (itemSelected)="onItemSelected($event)"
              ></gfs-add-item-card>
              <gfs-add-item-card
                *ngIf="selectedFilter === SearchTabTypes.NonGfsItems"
                [item]="item"
                [addItemIndex]="itemIndex"
                (itemSelected)="onAddNonGfsItem($event)"
              ></gfs-add-item-card>
              <gfs-add-item-card
                *ngIf="selectedFilter === SearchTabTypes.RecipeItems"
                [item]="item"
                [addItemIndex]="itemIndex"
                (itemSelected)="onAddRecipeItem($event)"
              ></gfs-add-item-card>
            </div>
            <div
              id="load-more-button-container"
              *ngIf="isMoreToLoad"
              class="load-more-button-container"
            >
              <button
                id="load-more-button"
                class="ghost-button load-more-button"
                (click)="loadMore()"
              >
                <img
                  class="refresh-icon"
                  src="assets/images/refresh-icon.svg"
                  alt="{{ 'ADD_ITEMS.LOAD_MORE_RESULTS' | translate }}"
                />
                <span class="load-more-label">{{ 'ADD_ITEMS.LOAD_MORE_RESULTS' | translate }}</span>
              </button>
            </div>
          </cdk-virtual-scroll-viewport>
          <div
            id="no-search-results"
            class="no-search-results"
            *ngIf="showNoSearchResultsReturned()"
          >
            <img
              class="no-search-icon"
              src="assets/images/binoculars.svg"
              alt="{{ 'ADD_ITEMS.NO_RESULTS_SUB' | translate }}"
            />
            <div class="main-text">{{ 'ADD_ITEMS.NO_RESULTS_MAIN' | translate }}
              <span class="no-results-bolded">{{selectedSearchText}}</span>
            </div>
            <div class="sub-text">{{ 'ADD_ITEMS.NO_RESULTS_SUB' | translate }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="addItemStep === 'ADD_GFS_ITEM'">
    <gfs-edit-gfs-item
      [countableItem]="selectedItem"
      (back)="goBack($event)"
      (closeEmitter)="closeModal()"
      [isAdd]="true"
    ></gfs-edit-gfs-item>
  </div>
  <div *ngIf="addItemStep === 'CREATE_CUSTOM_ITEM'">
    <gfs-add-custom-item
      (back)="goBack($event)"
      (closeEmitter)="closeModal()"
      [isManage]="isManage"
      [isDirectCustomItemAdd]="false"
      [isEdit]="false"
      [isAdd]="true"
      (itemAdded)="onItemSelected($event)"
    ></gfs-add-custom-item>
  </div>
  <div *ngIf="addItemStep === 'ADD_CUSTOM_ITEM'">
    <gfs-add-custom-item
      [countableItem]="selectedItem"
      [isEdit]="true"
      [isAdd]="true"
      [isDirectCustomItemAdd]="true"
      (back)="goBack($event)"
      (closeEmitter)="closeModal()"
      (itemAdded)="onItemSelected($event)"
    ></gfs-add-custom-item>
  </div>
  <div *ngIf="addItemStep === 'SEARCH'">
    <div
      id="add-modal-button-bar"
      class="add-modal-button-bar"
    >
      <button
        id="non-gfs-item-button"
        class="secondary-button large-button add-non-gfs-item-button"
        type="button"
        [hidden]="shouldHideNonGfsItemsTab()"
        (click)="onAddNonGfsItem()"
        attr.aria-label="{{ 'ADD_ITEMS.ADD_NON_GFS_ITEM' | translate }}"
      >{{ 'ADD_ITEMS.ADD_NON_GFS_ITEM' | translate }}
      </button>
      <button
        id="add-item-done-button"
        class="primary-button large-button"
        type="button"
        (click)="closeModal()"
        attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"
      >{{ 'MODALS.DONE' | translate }}
      </button>
    </div>
    <button
      id="add-item-close-button"
      class="close-button"
      (click)="closeModal()"
      attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' }}"
    >
      <span class="close-button-text">{{ 'MODALS.CLOSE' | translate }}</span>
      <img
        class="close-button-img"
        src="assets/images/close-icon.svg"
        alt="{{ 'MODALS.CLOSE' | translate }}"
      />
    </button>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Cart, CountableItem, Entitlement } from '@gfs/shared-models';
import { Observable } from 'rxjs';

export interface IInventoryDataProvider {
  products: Observable<CountableItem[]>;
}

@Injectable({
  providedIn: 'root',
})
export class MockCustomerUnitSelectionContextFactoryService {
  public static factory(mockObj: IInventoryDataProvider) {
    return mockObj;
  }
}

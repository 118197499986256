import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    ErrorModalComponent,
    ConfirmationModalComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MaterialModule,
    TranslateModule,
    CommonModule
  ],
  exports: [
    ErrorModalComponent,
    ConfirmationModalComponent
  ],
})
export class SharedModalsModule { }

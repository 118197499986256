import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gfs-reset-confirm-dialog',
  templateUrl: './reset-confirm-dialog.component.html',
  styleUrls: ['./reset-confirm-dialog.component.scss']
})
export class ResetConfirmDialogComponent {

  constructor(public dialogRef: MatDialogRef<ResetConfirmDialogComponent>) { }

  closeModal(didConfirm: boolean) {
    this.dialogRef.close(didConfirm);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddItemsStoreEffects } from './add-items.effects';
import * as fromAddItems from './add-items.reducer';
import * as fromAddItemsState from './add-items.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAddItemsState.FeatureKey, fromAddItems.addItemsReducer),
    EffectsModule.forFeature([AddItemsStoreEffects])
  ],
  declarations: []
})
export class AddItemsStateModule {}

import { Injectable } from '@angular/core';
import {
  InventoryItemValue,
  MeasurementUnit,
  WorksheetItem,
  ConversionUnit,
  CountableItem,
  Worksheet,
  StorageArea,
  CustomItemData,
  GfsItem,
  ItemPrice
} from '@gfs/shared-models';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { UnitConversionUtilService } from './unit-conversion-util.service';
import { InventoryConstants } from '@gfs/constants';

import { AppState } from '@gfs/store/inventory/reducers';
import { WorksheetItemService } from './worksheet-item.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryValueService {

  safeNumber = safeNumber;
  standardWeightUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits).pipe(
    map(units => units.filter(unit => unit.measurementType === 'weight'))
  );
  standardUnits: MeasurementUnit[] = [];
  customItemData: CustomItemData[] = [];

  constructor(
    private store: Store<AppState>,
    private unitConversionUtil: UnitConversionUtilService,
    public worksheetItemService: WorksheetItemService
  ) { }

  getTotalWorksheetValue(
    worksheet: Worksheet,
    countableItems: { [s: string]: CountableItem; },
    measurementUnits: MeasurementUnit[],
    customItemData: CustomItemData[]
  ): number {
    const worksheetWithMutatedWorksheetItems = this.worksheetItemService.transformWorksheetItemUnitsForSap(worksheet, countableItems);
    const storageAreaTotals = this.decomposeWorksheetIntoStorageAreaTotals(
      worksheetWithMutatedWorksheetItems,
      countableItems,
      measurementUnits,
      customItemData);

    return this.sumStorageAreaTotals(storageAreaTotals);
  }

  decomposeWorksheetIntoStorageAreaTotals(
    worksheet: Worksheet,
    countableItems: { [s: string]: CountableItem; },
    measurementUnits: MeasurementUnit[],
    customItemData: CustomItemData[]
  ): { id: string, name: string, value: number }[] {
    return worksheet
      .storageAreas
      ?.map(area => ({
        name: area.name,
        id: area.id,
        value: this.getStorageAreaValueTotal(area, countableItems, measurementUnits, customItemData)
      }))
      ?? [];
  }

  sumStorageAreaTotals(storageAreaTotals: { value: number }[]): number {
    return storageAreaTotals
      .map(obj => obj.value)
      .reduce((sum, itemValue) => (sum || itemValue)
        ? sum + itemValue
        : null
        , null
      );
  }

  getStorageAreaValueTotal(
    storageArea: StorageArea,
    countableItems: { [s: string]: CountableItem; },
    measurementUnits: MeasurementUnit[],
    customItemData: CustomItemData[]
  ): number {
    if (!storageArea?.worksheetItems?.length) {
      return null;
    }

    const inventoryValues = this.computeInventoryValues(
      storageArea.worksheetItems,
      countableItems,
      measurementUnits,
      customItemData);

    return inventoryValues
      .map(item => this.sumInventoryItemValueTiers(item))
      .reduce((sum, itemValue) => (sum || itemValue) ? sum + itemValue : null, null);
  }

  sumInventoryItemValueTiers(item: InventoryItemValue): number {
    const primaryItemValue = this.safeNumber(item?.primaryItemValue);
    const secondaryItemValue = this.safeNumber(item?.secondaryItemValue);
    const itemTotalValue = item ? (secondaryItemValue + primaryItemValue) : null;
    return itemTotalValue;
  }

  computeInventoryValues(
    items: WorksheetItem[],
    countableItems: { [s: string]: CountableItem; },
    measurementUnits: MeasurementUnit[],
    customItemData: CustomItemData[]
  ): InventoryItemValue[] {
    const inventoryItemValues = items
      .map(item => {
        const countableItem = countableItems[item.itemId];
        let itemId = item.itemId;
        if (item.itemType === 'GENERAL') {
          if (countableItem && countableItem.customItem) {
            itemId = countableItem.customItem.id;
          }
        }
        const itemData = customItemData.find(data => data.itemId === itemId);
        const result = this.computeInventoryValue(item, countableItem, measurementUnits, itemData);
        return result;
      });

    return inventoryItemValues;
  }

  computeInventoryValue(
    item: WorksheetItem,
    countableItem: CountableItem,
    units: MeasurementUnit[],
    customItemData: CustomItemData
  ): InventoryItemValue {
    return (this.canComputeInventoryItemValue(item, countableItem, units)) ? {
      id: item.itemId,
      primaryItemValue: this.computePrimaryItemValue(item, countableItem, units, customItemData),
      secondaryItemValue: this.computeSecondaryItemValue(item, countableItem, units, customItemData)
    } as InventoryItemValue : null;
  }

  canComputeInventoryItemValue(
    item: WorksheetItem,
    countableItem: CountableItem,
    units: MeasurementUnit[]
  ): boolean {
    return ((item && countableItem && units) || (!countableItem && item.itemPrice)) ? true : false;
  }

  computePrimaryItemValue(
    item: WorksheetItem,
    countableItem: CountableItem,
    units: MeasurementUnit[],
    customItemData: CustomItemData
  ): number {
    this.standardUnits = units;
    const canComputeItemValue = this.canComputeTierValue(item.primaryUnitQty, item.primaryUnit, item.primaryUnitType, item.itemPrice);
    if (!canComputeItemValue || !item.primaryUnit) {
      return null;
    }

    const primaryUnit = this.unitConversionUtil.getConversionUnit(item.primaryUnitType, item.primaryUnit, customItemData);
    return this.computeItemValue(item, countableItem, primaryUnit, item.primaryUnitQty, customItemData);
  }

  computeSecondaryItemValue(
    item: WorksheetItem,
    countableItem: CountableItem,
    units: MeasurementUnit[],
    customItemData: CustomItemData
  ): number {
    this.standardUnits = units;
    const canComputeItemValue = this.canComputeTierValue(item.secondaryUnitQty, item.secondaryUnit, item.secondaryUnitType, item.itemPrice);
    if (!canComputeItemValue || !item.secondaryUnit) {
      return null;
    }

    const secondaryUnit = this.unitConversionUtil.getConversionUnit(item.secondaryUnitType, item.secondaryUnit, customItemData);
    return this.computeItemValue(item, countableItem, secondaryUnit, item.secondaryUnitQty, customItemData);
  }

  private computeSapItemValue(
    unit: string,
    qty: number,
    item: WorksheetItem,
    isCatchWeight: boolean,
    gfsItem: GfsItem,
    customItemData: CustomItemData
  ): number {
    let price = 0;
    try {
      if (unit && unit === InventoryConstants.SAP_UOM_EACH) {
        if (isCatchWeight) {
          const price0 = item.itemPrice.unitCatchWeightPrice;
          price = price0;
        } else if (customItemData && customItemData.purchasedByUnit && item.itemPrice.unitPrice > 0) {
          const itemPrice = item.itemPrice;
          const itemUnitPrice = (itemPrice.unitCatchWeightPrice as any) === 'null'
            ? itemPrice.unitPrice
            : itemPrice.unitCatchWeightPrice;
          const price1 = itemUnitPrice;
          price = price1;
        } else if (gfsItem?.baseUom === InventoryConstants.SAP_UOM_CASE && item.primaryUnit === InventoryConstants.SAP_UOM_EACH) {
          const price3 = item.itemPrice.casePrice;
          price = price3;
        } else {
          const price2 = item.itemPrice.casePrice / (!!gfsItem.qtyPerMasterSellUnit ? gfsItem.qtyPerMasterSellUnit : 1);
          price = price2;
        }
      } else {
        if (!!item?.itemPrice) {
          item.itemPrice.caseCatchWeightPrice = sanitizeNullString(item.itemPrice.caseCatchWeightPrice) ?? 0;
          const casePrice = sanitizeNullString(item.itemPrice.casePrice) ?? 0;
          const price3 = isCatchWeight ? item.itemPrice.caseCatchWeightPrice : casePrice;
          price = price3;
        }
      }
      const result0 = qty * price;
      return result0;
    }
    catch (ex) {
      console.warn(`there was a problem computing price for worksheet-item-id: ${item.id}`);
      return price;
    }
  }

  computeItemValue(
    item: WorksheetItem,
    countableItem: CountableItem,
    countingUnit: ConversionUnit,
    countingUnitQty: number,
    customItemData: CustomItemData
  ): number {
    if (!countingUnitQty || !countableItem || !countingUnit) {
      if (!countableItem) {
        const result0 = this.computeItemValueWithoutCountableItem(item, countingUnit, countingUnitQty);
        return result0;
      }
      return null;
    }

    if (countableItem.gfsItem) {
      const result1 = this.computeMaterialItemValue(item, countingUnit, countingUnitQty, countableItem, customItemData);
      return result1;
    } else if (countableItem.customItem) {
      const result2 = this.computeGeneralCustomItemValue(item, countingUnit, countingUnitQty, countableItem, customItemData);
      return result2;
    } else {
      return 0;
    }
  }

  computeItemValueWithoutCountableItem(
    item: WorksheetItem,
    countingUnit: ConversionUnit,
    countingUnitQty: number
  ): number {
    let result = null;

    if (!countingUnitQty || !countingUnit) {
      result = null;
    }
    else if (item.itemPrice.casePrice > 0 && countingUnit.unitId === InventoryConstants.SAP_UOM_CASE) {
      result = (countingUnitQty * item.itemPrice.casePrice);
    }
    else if (item.itemPrice.unitPrice > 0 && countingUnit.unitId === InventoryConstants.SAP_UOM_EACH) {
      result = (countingUnitQty * item.itemPrice.unitPrice);
    }
    else {
      result = null;
    }
    return result;
  }


  computeMaterialItemValue(
    item: WorksheetItem,
    countingUnit: ConversionUnit,
    countingUnitQty: number,
    countableItem: CountableItem,
    customItemData: CustomItemData
  ): number {
    try {
      let pricePerProductPricingUnit: number;
      let productPricingUnit: ConversionUnit;

      const gfsItem = countableItem.gfsItem;
      const isCatchWeight = (gfsItem.isCatchWeight === true);
      const gfsBaseUnit = {
        unitType: InventoryConstants.UNIT_TYPE_SAP,
        unitId: gfsItem.baseUom,
        parentUnitId: gfsItem.baseUom,
        qtyInParent: '1'
      } as ConversionUnit;
      const productBaseWeightUnit = isCatchWeight
        ? this.getStandardBaseMeasurementUnit(item.itemPrice.catchWeightUom)
        : this.getStandardBaseMeasurementUnit();
      const productNetWeight = gfsItem.netWeightKg ? gfsItem.netWeightKg : 0;
      if (isCatchWeight && countingUnit.unitType !== 'CUSTOM') {
        productPricingUnit = productBaseWeightUnit;
        pricePerProductPricingUnit = (item.itemPrice && item.itemPrice.caseCatchWeightPrice) ? item.itemPrice.caseCatchWeightPrice : 0;
      } else {
        productPricingUnit = gfsBaseUnit;
        pricePerProductPricingUnit = (item.itemPrice && item.itemPrice.casePrice) ? item.itemPrice.casePrice : 0;
      }

      // Temporary handling for literal units - this will be removed once SAP and Custom units are implemented
      if (countingUnit.unitType === InventoryConstants.UNIT_TYPE_SAP) {
        const result0 = this.computeSapItemValue(
          countingUnit.unitId,
          countingUnitQty,
          item,
          isCatchWeight && (item.itemType !== 'GFS'),
          gfsItem,
          customItemData
        );
        return result0;
      }

      const result1 = this.computeRoundedInventoryValue(
        countingUnit,
        countingUnitQty,
        customItemData,
        productPricingUnit,
        pricePerProductPricingUnit,
        productBaseWeightUnit,
        productNetWeight
      );
      return result1;
    } catch (error) {
      console.warn(`there was a problem computing price for worksheet-item-id: ${item.id}`);
      return 0;
    }
  }

  computeGeneralCustomItemValue(
    item: WorksheetItem,
    countingUnit: ConversionUnit,
    countingUnitQty: number,
    countableItem: CountableItem,
    customItemData: CustomItemData
  ): number {
    if (!countingUnitQty || !countableItem || !countingUnit) {
      return null;
    }
    // This is based on the logic from InventoryValueService
    // This block must handle the following scenarios:
    // 1. Count by CASE (~SAP), catchWeight = false
    // 2. Count by CASE (~SAP), catchWeight = true
    // 3. Count by Weight, catchWeight = false (netWeight is required)
    // 4. Count by Weight, catchWeight = true  (netWeight not required)
    // 5. Count by Custom, catchWeight = false
    // 6. Count by Custom, catchWeight = true

    let purchaseUnitPrice: number;
    const customItem = countableItem.customItem;
    const isCatchWeight = (customItem.purchaseUnit.custom.catchWeight === true);
    const netWeight = Number.parseFloat(customItem.purchaseUnit.custom.netWeight);

    if (countingUnit.unitType === InventoryConstants.UNIT_TYPE_SAP) {
      // We are hijacking the SAP units for the time being. In reality, custom items have no relation
      // to SAP units, but for the time being we are borrowing the logic until a larger refactor can
      // be completed. Only "CASE"/"CS" is supported for custom items.

      const isAcceptableSapUnit = [
        InventoryConstants.SAP_UOM_CASE,
      ].some(sapUnit => countingUnit.unitId === sapUnit);

      if (!isAcceptableSapUnit) {
        // cannot count by anything other than CASE and EA for SAP units in custom items
        return 0;
      }

      if (isCatchWeight) {
        const pricePerWeight = Number.parseFloat(customItem.purchaseUnit.custom.price);
        if (netWeight && pricePerWeight) {
          purchaseUnitPrice = pricePerWeight * netWeight;
        } else {
          purchaseUnitPrice = 0;
        }
      } else {
        purchaseUnitPrice = Number.parseFloat(customItem.purchaseUnit.custom.price);
      }
      return purchaseUnitPrice ? countingUnitQty * purchaseUnitPrice : 0;

    } else if (countingUnit.unitType === InventoryConstants.UNIT_TYPE_CUSTOM) {
      const pricingUnit: ConversionUnit = {
        unitType: InventoryConstants.UNIT_TYPE_LITERAL,
        unitId: 'case',
        parentUnitId: null,
        qtyInParent: '1'
      };

      if (isCatchWeight) {
        const pricePerWeight = Number.parseFloat(customItem.purchaseUnit.custom.price);
        purchaseUnitPrice = pricePerWeight * netWeight;
      } else {
        purchaseUnitPrice = Number.parseFloat(customItem.purchaseUnit.custom.price);
      }
      return this.getValue(countingUnit, countingUnitQty, pricingUnit, purchaseUnitPrice, customItemData);
    } else if (countingUnit.unitType === InventoryConstants.UNIT_TYPE_STANDARD) {
      if (customItem.purchaseUnit.custom.weightUnitId === null) {
        // must have a unit ID to be measured by weight
        return 0;
      }

      const weightUnit = this.unitConversionUtil.getConversionUnit(
        InventoryConstants.UNIT_TYPE_STANDARD,
        customItem.purchaseUnit.custom.weightUnitId,
        null
      );

      if (isCatchWeight) {
        const catchWeightPrice = Number.parseFloat(customItem.purchaseUnit.custom.price);
        return this.getValue(countingUnit, countingUnitQty, weightUnit, catchWeightPrice);
      } else {
        purchaseUnitPrice = Number.parseFloat(customItem.purchaseUnit.custom.price);

        if (netWeight === 0) {
          // can't have a material with no weight
          return 0;
        }

        const weightUnitPrice = purchaseUnitPrice / netWeight;
        return this.getValue(countingUnit, countingUnitQty, weightUnit, weightUnitPrice);
      }
    }
  }

  worksheetItemTierHasQuantityWithoutUnit(
    quantity: number,
    unit: string,
  ): boolean {
    return !!quantity && !unit;
  }

  worksheetItemPriceIsComputable(
    itemPrice: ItemPrice,
  ): boolean {
    // Price is computable only if itemPrice has data
    if (!itemPrice) {
      return false;
    }

    // Price calculation requires either casePrice or caseCatchWeightPrice
    const conditions = [
      itemPrice?.casePrice,
      itemPrice?.caseCatchWeightPrice,
    ];

    return conditions.some(isTrue => isTrue);
  }

  worksheetItemTierIsUnitLiteralAndMissingUnitPrice(
    unit: string,
    unitType: string,
    itemPrice: ItemPrice,
  ): boolean {
    // Missing unit price when uom is UNIT LITERAL
    const conditions = [
      unitType === InventoryConstants.COUNTING_UNIT_TYPES.LITERAL,
      unit === InventoryConstants.LITERAL_UOM_UNIT,
      !itemPrice?.unitPrice,
    ];

    return conditions.every(isTrue => isTrue);
  }

  canComputeTierValue(
    quantity: number,
    unit: string,
    unitType: string,
    itemPrice: ItemPrice
  ): boolean {
    const priceIsComputable = this.worksheetItemPriceIsComputable(itemPrice);
    const hasQuantityWithoutUnit = this.worksheetItemTierHasQuantityWithoutUnit(quantity, unit);
    const unitLiteralMissingUnitPrice = this.worksheetItemTierIsUnitLiteralAndMissingUnitPrice(unit, unitType, itemPrice);

    const conditions = [
      priceIsComputable,
      !hasQuantityWithoutUnit,
      !unitLiteralMissingUnitPrice,
    ];

    return conditions.every(isTrue => isTrue);
  }

  private getValue(
    countingUnit: ConversionUnit,
    countingUnitQty: number,
    pricingUnit: ConversionUnit,
    pricingUnitPrice: number,
    customItemData?: CustomItemData
  ) {
    const pricingUnitPerCountingUnit = this.unitConversionUtil.getConversionFactorBetweenUnits(pricingUnit, countingUnit);
    const qtyInPricingUnits = countingUnitQty * pricingUnitPerCountingUnit;
    return pricingUnitPrice ? qtyInPricingUnits * pricingUnitPrice : 0;
  }

  private computeRoundedInventoryValue(
    countingUnit: ConversionUnit,
    countingUnitQty: number,
    customItemData: CustomItemData,
    productPricingUnit: ConversionUnit,
    pricePerProductPricingUnit: number,
    productBaseWeightUnit: ConversionUnit,
    productNetWeight: number
  ): number {
    if (countingUnit.unitType === InventoryConstants.UNIT_TYPE_CUSTOM) {
      productPricingUnit = {
        unitType: InventoryConstants.UNIT_TYPE_LITERAL,
        unitId: 'case',
        parentUnitId: null,
        qtyInParent: '1'
      };
    } else if (countingUnit.unitType === InventoryConstants.UNIT_TYPE_STANDARD) {
      // Get price in standard base weight unit when converting between unit types.
      if (productPricingUnit.unitType !== InventoryConstants.UNIT_TYPE_STANDARD) {
        pricePerProductPricingUnit = this.getPricePerBaseWeightUnit(
          pricePerProductPricingUnit,
          productPricingUnit,
          productBaseWeightUnit,
          productNetWeight
        );
        productPricingUnit = productBaseWeightUnit;
      }
    }

    // get quantity of item in pricing units
    const quantityInProductPricingUnits = this.getQtyInPricingUnits(countingUnitQty, countingUnit, productPricingUnit, customItemData);
    return (quantityInProductPricingUnits * pricePerProductPricingUnit);
  }

  private getPricePerBaseWeightUnit(
    pricePerProductUnit: number,
    productUnit: ConversionUnit,
    baseWeightUnit: ConversionUnit,
    baseWeightPerProductUnit: number
  ): number {
    let pricePerBaseWeightUnit = pricePerProductUnit;
    if (productUnit !== baseWeightUnit) {
      pricePerBaseWeightUnit = (pricePerProductUnit / baseWeightPerProductUnit);
    }
    return pricePerBaseWeightUnit;
  }

  private getQtyInPricingUnits(
    qty: number,
    countingUnit: ConversionUnit,
    pricingUnit: ConversionUnit,
    customItemData: CustomItemData
  ): number {
    const pricingUnitPerCountingUnit = this.unitConversionUtil.getConversionFactorBetweenUnits(pricingUnit, countingUnit);
    return qty * pricingUnitPerCountingUnit;
  }

  private getStandardBaseMeasurementUnit(baseWeightUom?: string): ConversionUnit {
    let standardizedUnitId = InventoryConstants.STANDARD_WEIGHT_UNIT_KG;
    if (!!baseWeightUom
      && baseWeightUom === InventoryConstants.SAP_WEIGHT_UOM_LB) {
      standardizedUnitId = InventoryConstants.STANDARD_WEIGHT_UNIT_LB;
    }
    return {
      unitType: InventoryConstants.UNIT_TYPE_STANDARD,
      unitId: standardizedUnitId,
      parentUnitId: standardizedUnitId,
      qtyInParent: '1'
    } as ConversionUnit;
  }
}

function sanitizeNullString(val: any): number | null {
  if (val === 'null') {
    return null;
  }

  return val;
}

export const unsafeNumberValues = [NaN, undefined, null, '', Infinity];
export function safeNumber(primaryItemValue: number): number {
  if (unsafeNumberValues.includes(primaryItemValue)) {
    return 0;
  }
  return primaryItemValue;
}

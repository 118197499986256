import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QuickAddItemsComponent } from './quick-add-items.component';
import { QuickAddItemsStateModule } from '@gfs/store/feature/quick-add-items';
import { PipeSharingModule } from '../pipes/pipe-sharing.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MaterialModule } from '../material.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [QuickAddItemsComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    MaterialModule,
    PipeSharingModule,
    QuickAddItemsStateModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
  ],
  exports: [ QuickAddItemsComponent ]
})
export class QuickAddItemsModule {}

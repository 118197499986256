import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { IAppContext } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { StorageAreaDTO, WorksheetDTO } from '@gfs/v2/shared-models';


@Component({
  selector: 'app-v2-storage-area-select',
  templateUrl: './storage-area-select.component.html',
  styleUrls: ['./storage-area-select.component.scss']
})
export class StorageAreaSelectV2Component {

  @Input() worksheetModel: WorksheetDTO;

  @Output() addStorageArea = new EventEmitter<void>();

  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;


  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
  ) { }


  async storageAreaSelected(storageArea: StorageAreaDTO) {
    const storageAreaId = `storage-area-item-panel-${storageArea.storageAreaId}`;
    const el = document.getElementById(storageAreaId);

    // added a slight async delay here because the element isnt expanded by the time we try to scroll it into view
    await new Promise(resolve => setTimeout(resolve, 100));

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
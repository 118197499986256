import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { GfsValidators } from '@gfs/shared-services/validation';
import { GeneralLedgerAccount } from '../../models/general-ledger.model';
import { GeneralLedgerAccountInput } from '../../models/general-ledger-account-input.model';
import { Subject, takeUntil } from 'rxjs';

export interface MaintainAccountsInputViewModel {
    accounts: GeneralLedgerAccount[];
    isSaving: boolean;
}

@Component({
    selector: 'gfs-maintain-accounts-input',
    templateUrl: './maintain-accounts-input.component.html',
    styleUrls: ['./maintain-accounts-input.component.scss']
})
export class MaintainAccountsInputComponent implements
    OnInit,
    OnChanges,
    OnDestroy
    {
    form: FormGroup;
    @ViewChild(FormGroupDirective) formGroupDirective;

    @Input() viewmodel: MaintainAccountsInputViewModel;
    @Output() addAccount = new EventEmitter<GeneralLedgerAccountInput>();
    public submitted$ = new Subject();
    public destroy$ = new Subject()

    constructor(
        public formBuilder: FormBuilder,
        public renderer: Renderer2
    ) { }

    ngOnInit() {
        const forbiddenGlCodes = this.getForbiddenEntries(this.viewmodel, account => account.glCode);
        const forbiddenGlDescriptions = this.getForbiddenEntries(this.viewmodel, account => account.description);
        this.form = this.formBuilder.group({
            glCode: new FormControl<string>('', [
                Validators.required,
                GfsValidators.notEmpty(),
                GfsValidators.duplicateName(forbiddenGlCodes)
            ]),
            description: new FormControl<string>('', [
                Validators.required,
                GfsValidators.notEmpty(),
                GfsValidators.duplicateName(forbiddenGlDescriptions)
            ]),
        });
        this.submitted$.pipe(
            takeUntil(this.destroy$)
        )
        .subscribe((isFormSubmitted)=>{
                this.renderer.selectRootElement('#glCode').focus();
        })

    }

    ngOnChanges(changes: SimpleChanges) {
        this.form = this.onChanges(changes, this.form);
    }

    onChanges(changes: SimpleChanges, form: FormGroup): FormGroup {
        const updateFormValidation = changes.viewmodel && !!form;
        return updateFormValidation
            ? this.updateFormValidation(form, changes.viewmodel.currentValue)
            : form;
    }

    updateFormValidation(
        form: FormGroup,
        viewmodel: MaintainAccountsInputViewModel
    ): FormGroup {
        const forbiddenGlCodes = this.getForbiddenEntries(viewmodel, account => account.glCode);
        const forbiddenGlDescriptions = this.getForbiddenEntries(viewmodel, account => account.description);

        form.controls.glCode.setValidators([
            Validators.required,
            GfsValidators.notEmpty(),
            GfsValidators.duplicateName(forbiddenGlCodes)
        ]);
        form.controls.description.setValidators([
            Validators.required,
            GfsValidators.notEmpty(),
            GfsValidators.duplicateName(forbiddenGlDescriptions)
        ]);

        form.controls.glCode.updateValueAndValidity();
        form.controls.description.updateValueAndValidity();
        return form;
    }

    getForbiddenEntries(
        viewmodel: MaintainAccountsInputViewModel,
        predicate: (model: GeneralLedgerAccount) => string
    ): string[] {
        return viewmodel.accounts.map(account => predicate(account));
    }

    submit() {
        const model = new GeneralLedgerAccountInput();
        model.glCode = this.form.value.glCode;
        model.description = this.form.value.description;
        this.addAccount.emit(model);
        this.formGroupDirective.resetForm();
        this.callNextOnSubmitted$(true)

    }
    callNextOnSubmitted$(data){
        this.submitted$.next(data);
    }

    isFormDisabled(form: FormGroup) {
        return form.invalid;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}

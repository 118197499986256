
import {
  CountingUnit, CustomGuide, CustomItemData, StorageArea,
  Worksheet,
  WorksheetItem
} from '@gfs/shared-models';
import * as worksheets from '../actions/worksheets.actions';

export interface State {
  useInfiniteScrolling?: boolean;
  useGroupOperations: boolean;
  useManageNonGfsToggleState?:boolean;
  worksheets: { [s: string]: Worksheet };
  customGuides?: CustomGuide[];
  selected: string;
  averageInventoryValue: number;
  error: string;
  isEditingName: boolean;
  isSaving: boolean;
  isCreating: boolean;
  isClosing: boolean;
  itemFilterText: string;
  countingUnits: CountingUnit[];
  customItemData: CustomItemData[];
  isRefreshingItems: boolean;
}

export const initialState: State = {
  worksheets: {},
  customGuides: [],
  selected: '',
  useGroupOperations: false,
  useManageNonGfsToggleState:false,
  averageInventoryValue: 0,
  error: null,
  isEditingName: false,
  isSaving: false,
  isCreating: false,
  isClosing: false,
  itemFilterText: '',
  countingUnits: [],
  customItemData: [],
  isRefreshingItems: false
};

export function worksheetReducer(
  state: State = initialState,
  action: worksheets.ActionUnion
): State {
  switch (action.type) {
    case worksheets.ActionTypes.SetItemFilterText: {
      return {
        ...state,
        itemFilterText: action.itemFilterText,
      };
    }
    case worksheets.ActionTypes.SetIsEditingName: {
      return {
        ...state,
        isEditingName: action.isEditingName,
      };
    }
    case worksheets.ActionTypes.SelectWorksheet: {
      return {
        ...state,
        selected: action.payload.worksheetId,
      };
    }
    case worksheets.ActionTypes.GetWorksheetSuccess: {
      return handleGetWorksheetSuccess(state, action);
    }
    case worksheets.ActionTypes.GetWorksheetError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.SetStorageAreaExpandStatus: {
      return handleSetStorageAreaExpandStatus(state, action);
    }
    case worksheets.ActionTypes.GetAllInventoryItemsAttempt: {
      return handleGetAllInventoryItems(state, action);
    }
    case worksheets.ActionTypes.CreateStorageAreaName: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case worksheets.ActionTypes.SetWorksheetDone: {
      return {
        ...state,
        isClosing: true,
      };
    }
    case worksheets.ActionTypes.CreateStorageAreaNameSuccess: {
      return handleCreateStorageAreaNameSuccess(state, action);
    }
    case worksheets.ActionTypes.PatchStorageArea: {
      return handlePatchStorageArea(state, action);
    }
    case worksheets.ActionTypes.PatchStorageAreaSuccess: {
      return {
        ...state,
        isSaving: false,
        worksheets: {
          ...state.worksheets,
          [action.payload.worksheetLastModified.id]: {
            ...state.worksheets[action.payload.worksheetLastModified.id],
            lastModified: action.payload.worksheetLastModified.lastModified,
          },
        },
      };
    }
    case worksheets.ActionTypes.DeleteStorageArea: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case worksheets.ActionTypes.DeleteStorageAreaSuccess: {
      return handleDeleteStorageAreaSuccess(state, action);
    }
    case worksheets.ActionTypes.DeleteStorageAreasSuccessV2: {
      return handleStorageAreaDeletionSuccessV2(state, action);
    }
    case worksheets.ActionTypes.DeleteStorageAreaError: {
      return {
        ...state,
        isSaving: false,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.CreateBlankWorksheetAttempt: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case worksheets.ActionTypes.CreateBlankWorksheetSuccess: {
      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.worksheet.id]: action.worksheet,
        },
        selected: action.worksheet.id,
        isCreating: false,
      };
    }
    case worksheets.ActionTypes.CreateBlankWorksheetError: {
      return {
        ...state,
        isCreating: false,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.UpdateWorksheetItem: {

      const worksheetItemArray = state
        .worksheets[action.payload.worksheetId]
        .storageAreas.find(area => area.id === action.payload.storageAreaId)
        .worksheetItems;

      const worksheetItemIndex = worksheetItemArray
        .findIndex(item => item.id === action.payload.worksheetItem.id);
      const currentWorksheetItem = worksheetItemArray[worksheetItemIndex];

      const mutatedProperties = Object.keys(action.payload.worksheetItem)
        .filter(key => !!action.payload.worksheetItem[key])
        .reduce((acc, curr: string) => ({ ...acc, [curr]: action.payload.worksheetItem[curr] }), {});
      const worksheetItems = [
        ...worksheetItemArray.slice(0, worksheetItemIndex),
        {
          ...currentWorksheetItem,
          ...mutatedProperties
        },
        ...worksheetItemArray.slice(worksheetItemIndex + 1, worksheetItemArray.length),
      ];

      const areaArray = state.worksheets[action.payload.worksheetId].storageAreas;
      const areaIndex = areaArray
        .findIndex(area => area.id === action.payload.storageAreaId);
      const worksheetItemsStorageArea = areaArray[areaIndex];
      const storageAreaArray = [
        ...areaArray.slice(0, areaIndex),
        {
          ...worksheetItemsStorageArea,
          worksheetItems
        },
        ...areaArray.slice(areaIndex + 1, areaArray.length),
      ];
      state.isSaving = true;
      state.worksheets[action.payload.worksheetId].storageAreas = storageAreaArray;
      return state;
    }

    case worksheets.ActionTypes.UpdateWorksheetItemSuccess: {
      return {
        ...state,
        isSaving: false
      };
    }
    case worksheets.ActionTypes.SetWorksheetLastModifiedDate: {
      return {
        ...state,
        isSaving: false,
        activeWorksheetLastModified: action.payload.worksheetLastModifiedDate,
      } as any;
    }
    case worksheets.ActionTypes.ImportWorksheetFromOrderGuideAttempt: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case worksheets.ActionTypes.ImportWorksheetFromOrderGuideSuccess: {
      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.worksheet.id]: action.worksheet,
        },
        selected: action.worksheet.id,
        isCreating: false,
      };
    }
    case worksheets.ActionTypes.ImportWorksheetFromOrderGuideError: {
      return {
        ...state,
        isCreating: false,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.ImportWorksheetFromCustomGuideAttempt: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case worksheets.ActionTypes.ImportWorksheetFromCustomGuideSuccess: {
      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.worksheet.id]: action.worksheet,
        },
        selected: action.worksheet.id,
        isCreating: false,
      };
    }
    case worksheets.ActionTypes.ImportWorksheetFromCustomGuideError: {
      return {
        ...state,
        isCreating: false,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.GetWorksheetSummarySuccess: {
      return handleGetWorkSheetSummarySuccess(state, action);
    }
    case worksheets.ActionTypes.GetWorksheetSummaryError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.GetCustomGuidesSuccess: {
      return {
        ...state,
        customGuides: action.customGuides,
      };
    }
    case worksheets.ActionTypes.CreateWorksheetItemError:
    case worksheets.ActionTypes.CreateGroupWorksheetItemsError:
    case worksheets.ActionTypes.GetCustomGuidesError: {
      return {
        ...state,
        error: action.error.message,
      };
    }

    case worksheets.ActionTypes.PatchWorksheetAttempt: {
      return {
        ...state,
        isSaving: true,
        worksheets: {
          ...state.worksheets,
          [action.payload.worksheetId]: {
            ...state.worksheets[action.payload.worksheetId],
            ...action.payload.fields,
          },
        },
      };
    }
    case worksheets.ActionTypes.PatchWorksheetSuccess: {
      const isClosing =
        action.payload.fields.status === 'closed' ? false : state.isClosing;
      return {
        ...state,
        isSaving: false,
        isClosing,
        worksheets: {
          ...state.worksheets,
          [action.payload.worksheetLastModified.id]: {
            ...state.worksheets[action.payload.worksheetLastModified.id],
            ...action.payload.fields,
            lastModified: action.payload.worksheetLastModified.lastModified,
          },
        },
      };
    }
    case worksheets.ActionTypes.CloseWorksheetError:
    case worksheets.ActionTypes.PatchWorksheetError: {
      return {
        ...state,
        isSaving: false,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.CopyWorksheetAttempt: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case worksheets.ActionTypes.CopyWorksheetSuccess: {
      return {
        ...state,
        isCreating: false,
        selected: action.worksheet.id,
        worksheets: {
          ...state.worksheets,
          [action.worksheet.id]: action.worksheet,
        },
      };
    }
    case worksheets.ActionTypes.CopyWorksheetError: {
      return {
        ...state,
        isCreating: false,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.ResetWorksheetAttempt: {
      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.payload.worksheetToCloseId]: {
            ...state.worksheets[action.payload.worksheetToCloseId],
            status: 'closed',
          },
        },
        isCreating: true,
      };
    }
    case worksheets.ActionTypes.ResetWorksheetError: {
      return {
        ...state,
        isCreating: false,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.CreateWorksheetItemSuccess: {
      return handleCreateWorksheetItemSuccess(state, action);
    }
    case worksheets.ActionTypes.DeleteInventoryItemSuccess: {
      return {
        ...state,
        isSaving: false,
        worksheets: {
          ...state.worksheets,
          [action.payload.worksheetLastModified.id]: {
            ...state.worksheets[action.payload.worksheetLastModified.id],
            lastModified: action.payload.worksheetLastModified.lastModified,
          },
        },
      };
    }
    case worksheets.ActionTypes.DeleteInventoryItemError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.DeleteInventoryItemAttempt: {
      return handleDeleteInventoryItemAttempt(state, action);
    }
    case worksheets.ActionTypes.GetWorkSheetFromSummarySuccess: {
      return handleGetWorkSheetFromSummarySuccess(state, action);
    }
    case worksheets.ActionTypes.DeleteWorksheetSuccess: {
      const worksheetsCopy = { ...state.worksheets };
      worksheetsCopy[action.payload.worksheet.id].deleted = true;
      const worksheetsCopyValue = Object.entries(worksheetsCopy).map((worksheet) => worksheet[1]);

      return {
        ...state,
        averageInventoryValue: getAverageInventoryValue(worksheetsCopyValue),
        worksheets: worksheetsCopy,
      };
    }
    case worksheets.ActionTypes.GetCountingUnitsSuccess: {
      return {
        ...state,
        countingUnits: action.payload,
      };
    }
    case worksheets.ActionTypes.GetCountingUnitsError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.GetAllCustomItemDataAttempt: {

      updateCustomItemDataLoadingState(state, 1);

      return state;
    }
    case worksheets.ActionTypes.GetAllCustomItemDataSuccess: {
      updateCustomItemDataLoadingState(state, -1);
      return {
        ...state,
        customItemData: action.customItemData,
      };
    }
    case worksheets.ActionTypes.GetAllCustomItemDataError: {

      updateCustomItemDataLoadingState(state, -1);
      return {
        ...state,
        error: action.error.message,
      };
    }
    case worksheets.ActionTypes.RefreshWorksheetItems: {
      return {
        ...state,
        isRefreshingItems: true
      };
    }
    case worksheets.ActionTypes.RefreshWorksheetItemsError: {
      return {
        ...state,
        isRefreshingItems: false
      };
    }
    case worksheets.ActionTypes.SetInfiniteScrollingToggleState: {
      state.useInfiniteScrolling = action.toggleState;
      return state;
    }
    case worksheets.ActionTypes.SetGroupOperationsToggleState: {
      state.useGroupOperations = action.toggleState;
      return state;
    }
    case worksheets.ActionTypes.SetManageNonGfsbulkMode: {
      state.useManageNonGfsToggleState = action.toggleState;
      return state;
    }
    case worksheets.ActionTypes.ClearWorksheetFromState: {
      delete state.worksheets[action.worksheetId];
      return state;
    }

    default: {
      return state;
    }
  }
}

function updateCustomItemDataLoadingState(state: any, valueShift) {
  const j = state.customItemDataPending ?? 0;
  state.customItemDataPending = valueShift + j;
  state.loadingCustomItemData = state.customItemDataPending > 0;
}

function handleGetWorkSheetFromSummarySuccess(
  state: State,
  action: worksheets.GetWorkSheetFromSummarySuccess
): State {
  const newSheet = action.openWorkSheet;
  if (
    state.worksheets[action.openWorkSheet.id] &&
    state.worksheets[action.openWorkSheet.id].storageAreas &&
    action.openWorkSheet.storageAreas
  ) {
    newSheet.storageAreas = action.openWorkSheet.storageAreas.map(
      (incomingArea) => {
        const exsitingArea = state.worksheets[
          action.openWorkSheet.id
        ].storageAreas.find((ea) => incomingArea.id === ea.id);

        return {
          ...incomingArea,
          expandStatus: exsitingArea
            ? exsitingArea.expandStatus
            : incomingArea.expandStatus,
        };
      }
    );
  }
  return {
    ...state,
    worksheets: {
      ...state.worksheets,
      [action.openWorkSheet.id]: newSheet,
    },
  };
}

function handleGetWorksheetSuccess(
  state: State,
  action: worksheets.GetWorksheetSuccess
): State {
  // If the worksheet exists, map the expandStatuses to the existing areas
  const newSheet = action.worksheet;
  if (
    state.worksheets[action.worksheet.id] && state.worksheets[action.worksheet.id].storageAreas &&
    action.worksheet.storageAreas
  ) {
    newSheet.storageAreas = action.worksheet.storageAreas.map(
      (incomingArea) => {
        const exsitingArea = state.worksheets[
          action.worksheet.id
        ].storageAreas.find((ea) => incomingArea.id === ea.id);

        return {
          ...incomingArea,
          expandStatus: exsitingArea
            ? exsitingArea.expandStatus
            : incomingArea.expandStatus,
        };
      }
    );
  }

  return {
    ...state,
    isSaving: false,
    selected: action.worksheet.id,
    worksheets: {
      ...state.worksheets,
      [action.worksheet.id]: newSheet,
    },
  };
}

function handleCreateStorageAreaNameSuccess(
  state: State,
  action: worksheets.CreateStorageAreaNameSuccess
): State {
  // If the storageArea is created, add newArea to AreaArray
  const areaArray: StorageArea[] =
    state.worksheets[action.storageAreaLastModified.worksheetLastModified.id]
      .storageAreas;
  const newArea: StorageArea = {
    id: action.storageAreaLastModified.storageArea.id,
    name: action.storageAreaLastModified.storageArea.name,
    worksheetItems: [],
    expandStatus: false,
  };

  return {
    ...state,
    isSaving: false,
    worksheets: {
      ...state.worksheets,
      [action.storageAreaLastModified.worksheetLastModified.id]: {
        ...state.worksheets[
        action.storageAreaLastModified.worksheetLastModified.id
        ],
        lastModified:
          action.storageAreaLastModified.worksheetLastModified.lastModified,
        storageAreas: [...areaArray, newArea],
        storageAreaOrder: [...state.worksheets[action.storageAreaLastModified.worksheetLastModified.id].storageAreaOrder, newArea.id]
      },
    },
  };
}

function handleDeleteStorageAreaSuccess(
  state: State,
  action: worksheets.DeleteStorageAreaSuccess
): State {
  const worksheetId = action.payload.worksheetLastModified.id;
  const areaArray: StorageArea[] = state.worksheets[worksheetId].storageAreas;
  const areaToDelete: StorageArea = areaArray.find(
    (storageArea) => storageArea.id === action.payload.storageArea.id
  );
  const unassignedArea: StorageArea = areaArray.find(
    (storageArea) => storageArea.id === 'unassigned'
  );

  if (areaToDelete && areaToDelete.worksheetItems) {
    const saveItems: WorksheetItem[] = areaToDelete.worksheetItems;
    unassignedArea.worksheetItems = unassignedArea.worksheetItems.concat(
      saveItems
    );
  }

  const filtered = areaArray.filter(function (area, index, arr) {
    return (
      area.id !== action.payload.storageArea.id && area.id !== 'unassigned'
    );
  });

  const storageAreas: StorageArea[] = [...filtered, unassignedArea];

  return {
    ...state,
    isSaving: false,
    worksheets: {
      ...state.worksheets,
      [worksheetId]: {
        ...state.worksheets[worksheetId],
        storageAreas,
        lastModified: action.payload.worksheetLastModified.lastModified,
      },
    },
  };
}

function handleStorageAreaDeletionSuccessV2(state: State, action: worksheets.DeleteStorageAreasSuccessV2): State {
  const areaArray: StorageArea[] = state.worksheets[action.payload.worksheetId].storageAreas;
  const filtered = areaArray.filter(function (area, index, arr) {
    return (
      area.id !== action.payload.storageAreaId && area.id !== 'unassigned'
    );
  });
  const unassignedArea: StorageArea = areaArray.find(
    (storageArea) => storageArea.id === 'unassigned'
  );
  const storageAreas: StorageArea[] = [...filtered, unassignedArea];
  return {
    ...state,
    isSaving: false,
    worksheets: {
      ...state.worksheets,
      [action.payload.worksheetId]: {
        ...state.worksheets[action.payload.worksheetId],
        storageAreas,
      }
    }
  }
}

function handlePatchStorageArea(
  state: State,
  action: worksheets.PatchStorageArea
): State {
  const areaIndex = state.worksheets[state.selected].storageAreas.findIndex(
    (el) => el.id === action.payload.storageAreaId
  );

  const areaArray: StorageArea[] =
    state.worksheets[state.selected].storageAreas;

  if (action.payload.fields.name) {
    areaArray[areaIndex].name = action.payload.fields.name;
  }

  if (action.payload.fields.worksheetItemOrder) {
    areaArray[areaIndex].worksheetItemOrder =
      action.payload.fields.worksheetItemOrder;
  }
  state.isSaving = true;
  state.worksheets[state.selected].storageAreas = areaArray;

  return state;

}

function handleSetStorageAreaExpandStatus(
  state: State,
  action: worksheets.SetStorageAreaExpandStatus
): State {
  const areaIndex = state.worksheets[state.selected].storageAreas.findIndex(
    (el) => el.id === action.payload.areaId
  );

  const areaArray: StorageArea[] =
    state.worksheets[state.selected].storageAreas;

  const area = areaArray[areaIndex];
  area.expandStatus = action.payload.status;

  const newArray = [
    ...areaArray.slice(0, areaIndex),
    area,
    ...areaArray.slice(areaIndex + 1, areaArray.length),
  ];

  return {
    ...state,
    worksheets: {
      ...state.worksheets,
      [state.selected]: {
        ...state.worksheets[state.selected],
        storageAreas: newArray,
      },
    },
  };
}

function handleGetAllInventoryItems(
  state: State,
  action: worksheets.GetAllInventoryItemsAttempt
): State {
  if (state.selected) {
    return {
      ...state,
      worksheets: {
        ...state.worksheets,
        [state.selected]: {
          ...state.worksheets[state.selected],
          storageAreas: state.worksheets[state.selected].storageAreas,
        },
      },
    };
  } else {
    return state;
  }
}

function handleCreateWorksheetItemSuccess(
  state: State,
  action: worksheets.CreateWorksheetItemSuccess
): State {
  const updatedAreas: StorageArea[] = state.worksheets[
    action.payload.worksheetItemLastModified.worksheetLastModified.id
  ]?.storageAreas?.map((area) => {
    if (area.id === action.payload.storageAreaId) {
      if (action.payload.duplicate) {
        const index = area.worksheetItems.findIndex(
          (item) =>
            action.payload.worksheetItemLastModified.worksheetItem.itemId ===
            item.itemId
        );
        if (index >= 0) {
          area.worksheetItems.splice(
            index + 1,
            0,
            action.payload.worksheetItemLastModified.worksheetItem
          );
          return area;
        }
      }
      area.worksheetItems.push(
        action.payload.worksheetItemLastModified.worksheetItem
      );
    }
    return area;
  }) ?? [];

  return {
    ...state,
    worksheets: {
      ...state.worksheets,
      [action.payload.worksheetItemLastModified.worksheetLastModified.id]: {
        ...state.worksheets[
        action.payload.worksheetItemLastModified.worksheetLastModified.id
        ],
        lastModified:
          action.payload.worksheetItemLastModified.worksheetLastModified
            .lastModified,
        storageAreas: updatedAreas,
      },
    },
  };
}

/**
 * Calculate average inventory value using only worksheets that haven't
 * been marked as deleted
 */
export function getAverageInventoryValue(payload: Worksheet[]): number {
  if (payload.length > 0) {
    const allNonDeletedWorksheets: Worksheet[] = payload.filter(
      (worksheet: Worksheet) => worksheet.deleted === false
    );
    const totalInventoryValue: number = allNonDeletedWorksheets.reduce(
      (accumulator: number, currentWorksheet: Worksheet) =>
        accumulator + currentWorksheet.totalValue,
      0
    );
    const newAverageInventoryValue: number =
      totalInventoryValue / allNonDeletedWorksheets.length;

    return newAverageInventoryValue;
  } else {
    return 0;
  }
}

function handleDeleteInventoryItemAttempt(
  state: State,
  action: worksheets.DeleteInventoryItemAttempt
): State {
  const areaIndex = state.worksheets[state.selected].storageAreas.findIndex(
    (el) => el.id === action.payload.storageAreaId
  );

  const areaArray: StorageArea[] =
    state.worksheets[state.selected].storageAreas;

  areaArray[areaIndex].worksheetItems = areaArray[
    areaIndex
  ].worksheetItems.filter(function (worksheetItem) {
    return worksheetItem.id !== action.payload.inventoryItem.id;
  });

  return {
    ...state,
    isSaving: true,
    worksheets: {
      ...state.worksheets,
      [action.payload.worksheetId]: {
        ...state.worksheets[action.payload.worksheetId],
        storageAreas: areaArray,
      },
    },
  };
}

function handleGetWorkSheetSummarySuccess(
  state: State,
  action: worksheets.GetWorksheetSummarySuccess
) {
  const sheetsAsMap = action.worksheets
    .reduce((acc, sheet) => {
      acc[sheet.id] = sheet;
      return acc;
    }, {});

  if (action.openWorksheet) {
    sheetsAsMap[action.openWorksheet.id].totalValue =
      action.openWorksheet.totalValue;
    sheetsAsMap[action.openWorksheet.id].status = action.openWorksheet.status;
  }

  // if (state.selected !== '' && sheetsAsMap[state.selected] !== undefined)  {
  //   sheetsAsMap[state.selected].totalValue = 999;//calculateTotalForOpenWorksheet(state, state.selected); // action.total; //
  //   sheetsAsMap[state.selected].status = state.worksheets[state.selected].status;
  // }

  // const openSheet = action.worksheets.filter((sheet: Worksheet) => sheet.status === 'open');
  // if (openSheet.length > 0) {
  //   sheetsAsMap[openSheet[0].id].totalValue = 888;//calculateTotalForOpenWorksheet(state, openSheet[0].id); // action.total; //
  // }

  return {
    ...state,
    worksheets: sheetsAsMap,
    averageInventoryValue: getAverageInventoryValue(action.worksheets)
  };
}

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationUserRole, RecipeProfitCalculatorConfig } from '@gfs/shared-models';
import { Observable } from 'rxjs';
import { AuthenticationState } from './models/authentication-state.model';
import { NamAuthenticationService } from './providers/nam/nam-authentication.service';
import { OktaAuthenticationService } from './providers/okta/okta-authentication.service';

export const defaultAuthState: AuthenticationState = {
    accessToken: undefined,
    idToken: undefined,
    claims: undefined,
    isAuthenticated: false,
    error: undefined
}

export function blockFromRecipeProfitCalculator(
    recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    window: Window,
): boolean {
    const isFeatureEnabled = recipeProfitCalculatorConfig.isFeatureEnabled();
    const isNavigatingFromCalculator = recipeProfitCalculatorConfig.isNavigatingFromCalculator(window);
    return !isFeatureEnabled && isNavigatingFromCalculator;
}

export abstract class AuthenticationService {
    abstract authenticationState$(): Observable<AuthenticationState>;

    abstract reauthorizeWithoutPrompt$(): Observable<void>;

    abstract loginWithRedirect$(): Observable<void>;

    abstract handleRedirect$(): Observable<AuthenticationState>;

    abstract logout$(): Observable<boolean>;

    abstract canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean>;

    public static factory(
        userRole: ApplicationUserRole,
        oktaAuthenticationService: OktaAuthenticationService,
        namAuthenticationService: NamAuthenticationService
    ): AuthenticationService {

        if (userRole == ApplicationUserRole.Customer) {
            return oktaAuthenticationService
        }

        if (userRole == ApplicationUserRole.Employee) {
            return namAuthenticationService
        }

        throw Error("User role not configured to an auth provider");
    }
}

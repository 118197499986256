import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs as saveAsFn } from 'file-saver-es';
import { CustomerPK, WorksheetSummary } from '@gfs/shared-models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InjectionTokens } from '../../injection-tokens';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private baseUrl: string;

  private type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  private httpOptions = {
    responseType: 'arraybuffer' as 'arraybuffer',
    headers: new HttpHeaders({
      'Accept': this.type,
      'Content-Type': 'application/json'
    })
  };
  saveAs = saveAsFn;
  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  downloadInventoryValueExcel(
    worksheet: WorksheetSummary,
    worksheetName: string,
    locale: string,
    exportType: string,
    listBy: string = 'STORAGE_AREAS',
  ): Observable<any> {
    const body = {
      worksheetId: worksheet.id,
      listBy,
      locale
    };
    return this.http.post(
      `${this.baseUrl}/reports/inventory-value`,
      body, this.httpOptions
    ).pipe(tap(response => {
      this.downloadFile(worksheetName, response, this.type, exportType);
    }));
  }

  private downloadFile(name: string, data: any, type: string, exportType: string) {
    const blob = new Blob([data], { type });
    switch (exportType) {
      case 'excel':
        this.saveAs(blob, `${name}.xlsx`);
        break;
      case 'csv':
        this.saveAs(blob, `${name}.csv`);
        break;
    }
  }

  private downloadFileMulti(name: string, data: any, type: string) {
    const blob = new Blob([data], { type });
    this.saveAs(blob, `${name}.xlsx`);
  }

  downloadMultiUnitReportExcel(
    dateRange: { start: any, end: any },
    pk: CustomerPK
  ): Observable<any> {
    const params = {
      startDate: moment(dateRange.start).format('yyyy-MM-DD'),
      endDate: moment(dateRange.end).format('yyyy-MM-DD'),
      ...pk
    };

    return this.http.get(
      `${this.baseUrl}/reports/multi-unit-rollup`,
      { ...this.httpOptions, params }
    ).pipe(tap(response => this.downloadFileMulti(
      `${moment(dateRange.start).toLocaleString()} - ${moment(dateRange.end).toLocaleString()}`,
      response, this.type)));
  }
}

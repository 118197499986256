import { RecipeConstants } from '@gfs/constants';
import { LayoutActionTypes as ActionTypes, LayoutActionUnion as ActionUnion } from '../actions/layout.actions';

export interface State {
  title: string;
  isMobile: boolean;
  language: string;
  headerHeight: number;
}

export const initialState: State = {
  title: 'recipe',
  isMobile: true,
  language: RecipeConstants.LANGUAGES.EN_CA,
  headerHeight: 100,
};

export function layoutReducer(
  state: State = initialState,
  action: ActionUnion
): State {
  switch (action.type) {
    case ActionTypes.SetTitle: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case ActionTypes.SetLanguage: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case ActionTypes.SetMobile: {
      return {
        ...state,
        isMobile: action.payload,
      };
    }
    case ActionTypes.SetHeaderHeight: {
      return {
        ...state,
        headerHeight: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

import { AuthorizationServer } from 'oauth4webapi';
import { Observable, of } from 'rxjs';

export type OIDCConfig = Observable<OIDC>

export type OIDC = {
    issuer: string;
    redirectUri: string;
    clientId: string;
    authorizationServer: AuthorizationServer;
}

export const mockOIDC: OIDC = {
    issuer: 'https://enterprise-auth-issuer.com/oidc',
    redirectUri: '/',
    clientId: 'clientId',
    authorizationServer: {
        authorization_endpoint: 'https://enterprise-auth-issuer.com/oidc/authorize',
        end_session_endpoint: 'https://enterprise-auth-issuer.com/oidc/terminate',
        token_endpoint: 'https://enterprise-auth-issuer.com/oidc/token',
        revocation_endpoint: 'https://enterprise-auth-issuer.com/oidc/revoke',
    } as AuthorizationServer,
}

export const mockOpenIDConfig: OIDCConfig = of({ ...mockOIDC });

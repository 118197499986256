import { ActionReducerMap } from '@ngrx/store';
import * as auth from '../../../common/reducer/auth.reducer';
import {
  layoutReducer,
  State as layoutState,
  initialState as initialLayoutState,
  networkReducer,
  NetworkFeatureState,
  initialNetworkFeatureState
} from '@gfs/store/common';
import * as worksheets from './worksheets.reducer';
import * as inventoryItems from './inventoryItems.reducer';
import * as inventory from './inventory.reducer';
import * as customerItems from './customerItems.reducer';
import {
  addItemsReducer,
  FeatureState as addItemsState,
  initialState as addItemsInitialState,
} from '@gfs/store/feature/add-items';
import {
  customerUnitSelectionReducer,
  FeatureState as customerUnitSelectionState,
  initialState as customerUnitSelectionInitialState
} from '@gfs/store/feature/customer-unit-selection';
import {
  cartReducer,
  FeatureState as cartState,
  initialState as cartInitialState
} from '@gfs/store/feature/cart';
import {
  generalLedgerReducer,
  GeneralLedgerState,
  initialState as generalLedgerInitialState
} from '@gfs/shared-components';

export interface AppState {
  auth: auth.AuthState;
  layout: layoutState;
  worksheets: worksheets.State;
  inventoryItems: inventoryItems.State;
  addItemsFeature: addItemsState;
  network: NetworkFeatureState;
  inventory: inventory.State;
  customerUnitSelectionFeature: customerUnitSelectionState;
  customerItems: customerItems.State;
  cartFeature: cartState;
  generalLedger: GeneralLedgerState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: auth.authReducer,
  layout: layoutReducer,
  worksheets: worksheets.worksheetReducer,
  inventoryItems: inventoryItems.inventoryItemsReducer,
  addItemsFeature: addItemsReducer,
  network: networkReducer,
  inventory: inventory.inventoryReducer,
  customerUnitSelectionFeature: customerUnitSelectionReducer,
  customerItems: customerItems.customerItemsReducer,
  cartFeature: cartReducer,
  generalLedger: generalLedgerReducer
};

export const initialState: AppState = {
  auth: auth.initialAuthState,
  layout: initialLayoutState,
  worksheets: worksheets.initialState,
  inventoryItems: inventoryItems.initialState,
  addItemsFeature: addItemsInitialState,
  network: initialNetworkFeatureState,
  inventory: inventory.initialState,
  customerUnitSelectionFeature: customerUnitSelectionInitialState,
  customerItems: customerItems.initialState,
  cartFeature: cartInitialState,
  generalLedger: generalLedgerInitialState
};

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { WINDOW } from '@gfs/shared-services';
import { distinctUntilHashChanged, isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { BehaviorSubject, combineLatest, EMPTY, fromEvent, merge, Observable, range, Subject, Subscription } from 'rxjs';
import { exhaustMap, filter, finalize, first, map, pairwise, skip, startWith, take, tap, toArray } from 'rxjs/operators';


interface ScrollPosition {
  sH: number;
  sT: number;
  cH: number;
}

export interface InfiniteScrollOptions {

  // automatically load the first page of data when the component is rendered
  autoLoadFirstPage: boolean;

  // percentage of vertical scroll before the next page of data tries to load
  scrollPercent: number;

  // height of the scroll region in pixels
  containerHeight: number;


  // override the optimal minimum calculated page size
  pageSize?: number;

  // output log to console
  enableLog?: boolean;

  // one loader or many
  loaderStyle: 'single' | 'many';

  // hide the scroll bar in the container
  hideScrollbar?: boolean;
}

export const DEFAULT_SCROLL_OPTIONS: InfiniteScrollOptions = {
  autoLoadFirstPage: true,
  loaderStyle: 'single',
  scrollPercent: 60,
  enableLog: true,
  hideScrollbar: true,
  containerHeight: 500,
  pageSize: 10
};

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
  sH: 0,
  sT: 0,
  cH: 0
};

export enum DataStatus {
  INIT,
  IDLE,
  LOADING,
  EOD
}

const BROWSER_SCROLL_EVENT = 'scroll';

@Component({
  selector: 'gfs-infinite-scroll-list',
  templateUrl: './infinite-scroll-list.component.html',
  styleUrls: ['./infinite-scroll-list.component.scss'],
  exportAs: 'infinite-scroll',
  changeDetection: ChangeDetectionStrategy.Default
})
export class InfiniteScrollListComponent
  implements AfterViewInit, OnDestroy, OnInit {
  DataStatus = DataStatus;
  uid = `${+ new Date()}`;
  scrollBarPositionMemory = -1;
  @Input() enable = false;
  @Input() options: InfiniteScrollOptions = DEFAULT_SCROLL_OPTIONS;
  @Input() getPage: (q: any, pageSize: number, page: number) => Observable<any[]>;
  @Input()
  public get query(): any {
    return this._query;
  }
  public set query(value: any) {
    this._query = value;
    this.activeQuery.next(value);
  }
  private _query: any;
  @Input() scrollerHeight : string


  // row template
  @ContentChild('row', { static: false }) rowTemplateRef: TemplateRef<any>;

  // end of data template
  @ContentChild('eod', { static: false }) eodTemplateRef: TemplateRef<any>;

  // loader template
  @ContentChild('rowSkeleton', { static: false }) rowSkeletonTemplateRef: TemplateRef<any>;

  // scroll container element
  @ViewChild('infiniteContainer') scrollContainer: ElementRef;

  // x
  status: DataStatus;
  pageNumber = 0;
  data = [];
  skeletons = [];
  reloadTrigger = new Subject<any>();
  refreshTrigger = new Subject<any>();
  advancePage$ = new Subject<any>();
  activeQuery = new BehaviorSubject<any>(null);
  hasFullPage = false;

  private clearCount: 0;
  private reloadCount: 0;
  private directiveEffectsSubscription: Subscription;

  /**w
   *
   */
  constructor(
    @Inject(WINDOW) private window2: Window,
    private changeDetector: ChangeDetectorRef
  ) { }


  createBehaviorResetDataOnQueryChange() {
    return this.activeQuery.pipe(skip(1), distinctUntilHashChanged(), tap(v => { this.resetData(); }));
  }

  refreshLoadedData() { this.refreshTrigger.next(this.reloadCount++); }

  resetData() {
    this.data = [];
    this.pageNumber = 0;
    this.status = DataStatus.IDLE;
    this.reloadTrigger.next(this.clearCount++);
  }

  goToTop() { this.setScrollbarPosition(0); }

  getScrollbarPosition() { return this.scrollContainer.nativeElement.scrollTop; }

  setScrollbarPosition(val) { this.scrollContainer.nativeElement.scrollTop = val; }

  async ngOnInit(): Promise<void> {
    this.skeletons = await this.createSkeletons();
  }

  ngOnDestroy(): void { this.directiveEffectsSubscription.unsubscribe(); }

  ngAfterViewInit() {
    const onScrollDown$ = this.getScrollDownEvent$();

    this.directiveEffectsSubscription =
      combineLatest([
        this.createBehaviorLoadNextPageOnScroll$(onScrollDown$),
        this.createBehaviorResetDataOnQueryChange()
      ]).subscribe();
  }

  isLoading() {
    return this.status === DataStatus.LOADING;
  }

  isEOD() {
    return this.status === DataStatus.EOD;
  }

  private createSkeletons(): Promise<number[]> {
    return range(0, this.options.pageSize)
      .pipe(
        take(this.options.pageSize / 2),
        toArray(),
        first()
      ).toPromise();
  }


  getScrollDownEvent$() {
    const useScrollContainer = this.options.containerHeight > 0;
    const onScroll$ = this.getScrollEventSource$(useScrollContainer);
    return this.createEventOnScrolledDown$(onScroll$);
  }

  getScrollEventSource$(useScrollContainer: boolean) {
    return useScrollContainer
      ? this.getElementScrollEvent$()
      : this.getWindowScrollEvent$();
  }

  getWindowScrollEvent$() {
    return fromEvent(this.window2.document, BROWSER_SCROLL_EVENT)
      .pipe(
        map((e: any): ScrollPosition => {
          const target = e.target['scrollingElement'];
          return ({
            sH: target.scrollHeight,
            sT: target.scrollTop + e.target.clientHeight,
            cH: target.clientHeight
          });
        })
      );
  }

  getElementScrollEvent$() {
    return fromEvent(this.scrollContainer.nativeElement, BROWSER_SCROLL_EVENT)
      .pipe(
        map((e: any): ScrollPosition => {
          return ({
            sH: e.target.scrollHeight,
            sT: e.target.scrollTop,
            cH: e.target.clientHeight
          });
        })
      );
  }

  createEventOnScrolledDown$(scrollEvent$) {
    return scrollEvent$
      .pipe(
        pairwise(),
        tap(v => { this.log(v[1]); }),
        filter(positions => [
          this.isUserScrollingDown(positions),
          this.isScrollExpectedPercent(positions[1])
        ].indexOf(false) === -1)
      );
  }

  createBehaviorLoadNextPageOnScroll$(scrollDownEvent$) {
    return merge(
      merge(
        this.createScroller$(scrollDownEvent$),
        this.reloadTrigger.pipe()
      )
        .pipe(
          map(e => ({
            sz: this.options.pageSize,
            p: this.pageNumber++,
            restoreStatus: null,
            mode: 'append',
          }))
        ),
      this.refreshTrigger
        .pipe(
          map(e => ({
            sz: this.data.length,
            p: 0,
            mode: 'refresh',
            restoreStatus: this.status
          })),
          tap(e => {
            this.scrollBarPositionMemory = this.getScrollbarPosition();
            this.status = DataStatus.LOADING;
          }),
        )
    )
      .pipe(
        filter(() => this.enable),
        exhaustMap(({ sz, p, mode, restoreStatus }) => {

          this.log([
            'GETTING PAGE',
            p,
            {
              status: this.status,
              startIndex: this.data.length
            }]);

          this.status = DataStatus.LOADING;

          // the expected number of items to get
          const itemsToLoad = sz * (1 + p);

          // this page has already been loaded
          if (itemsToLoad < this.data.length) {
            this.log(['PAGE ALREADY CACHED']);
            this.status = DataStatus.IDLE;
            return EMPTY;
          }

          return this.getPage(this.query, sz, p)
            .pipe(
              first(),
              isTruthy(),
              map(pageData => ({
                pageData,
                isEOD: pageData.length < this.options.pageSize
              })),
              tap(({ pageData, isEOD }) => {

                this.log(['Data Page Loaded']);
                this.status = restoreStatus ?? isEOD ? DataStatus.EOD : DataStatus.IDLE;
                this.hasFullPage = this.data.length >= this.options.pageSize;

                switch (mode) {

                  case 'refresh':
                    this.data = pageData;
                    break;

                  case 'append':
                    this.data.push(...pageData);
                    break;

                }

                this.log({
                  status: this.status,
                  query: this.query,
                  pageNumber: this.pageNumber,
                  page: pageData
                });
                this.tryRestoreScrollBarPosition();
              }),
              finalize(() => { this.changeDetector.markForCheck(); })
            );


        }),
      );
  }

  isUserScrollingDown = (positions) => {
    return positions[0].sT < positions[1].sT;
  };

  isScrollExpectedPercent = (position) => {
    return ((position.sT + position.cH) / position.sH) >= (this.options.scrollPercent / 100);
  };

  tryRestoreScrollBarPosition() {
    if (this.scrollBarPositionMemory > -1) {
      return setTimeout(() => {
        const that = this;
        that.setScrollbarPosition(this.scrollBarPositionMemory);
        that.scrollBarPositionMemory = -1;
      }, 500);
    }
    return null;
  }

  createScroller$(scrollDownEvent$: any): Observable<any> {
    return this.options.autoLoadFirstPage
      ? scrollDownEvent$.pipe(startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION]))
      : scrollDownEvent$;
  }

  private log(data: any) {
    if (this.options.enableLog) {
      console.log(`infinite-scroll:${this.uid}`, data);
    }
  }
}


export function calculateOptimalPageSizeForRenderRegion(containerHeightPx, rowHeightPx) {
  const visibleItemCountEstimate = containerHeightPx / rowHeightPx;
  let optimalPageSize = 1.1 * visibleItemCountEstimate;
  optimalPageSize = Math.round(optimalPageSize + Number.EPSILON);
  return optimalPageSize;
}
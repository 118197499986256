import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, withLatestFrom } from 'rxjs/operators';
import {
  ActionTypes,
  ActionUnion,
  GetCartDataError,
  GetCartDataSuccess
} from './actions';
import { of } from 'rxjs';
import { FeatureState } from './state';
import { Store } from '@ngrx/store';
import { InventoryConstants } from '@gfs/constants';
import { InventoryService } from '@gfs/shared-services/services/inventory.service';

@Injectable()
export class CartEffects {


  getCartDataAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCartDataAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {
      const existentCart =
        state.cartsQuantities?.[action.payload.customerPK.customerId];
      if (existentCart && !action.payload.includePrice) {
        return of(
          new GetCartDataSuccess({
            cart: existentCart,
            customerId: action.payload.customerPK.customerId,
          })
        );
      }

      // only call the /carts service if the entity is not in the GROUP_ENTITIES
      if (!InventoryConstants.GROUP_ENTITIES.includes(action.payload.customerPK.entityType)) {
        return this.inventoryService
          .getCartData(action.payload.customerPK, action.payload.includePrice)
          .pipe(map((cart) =>
            new GetCartDataSuccess({
              cart,
              customerId: action.payload.customerPK.customerId,
            })
          ), catchError((err) => of(new GetCartDataError(err))));
      } else {
        return [];
      }
    })
  ));

  constructor(
    private actions$: Actions<ActionUnion>,
    private inventoryService: InventoryService,
    private store: Store<FeatureState>,
  ) { }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'libs/shared-components/src/lib/material.module';
import { PipeSharingModule } from 'libs/shared-components/src/lib/pipes/pipe-sharing.module';
import { LocalizedCurrencyValueComponent } from './localized-currency-value/localized-currency-value.component';
import { LocalizedDatetimeValueComponent } from './localized-datetime-value/localized-datetime-value.component';
import { LocalizedImageComponent } from './localized-image/localized-image.component';
import { LocalizedTextComponent } from './localized-text/localized-text.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    PipeSharingModule
  ],
  declarations: [
    LocalizedCurrencyValueComponent,
    LocalizedDatetimeValueComponent,
    LocalizedTextComponent,
    LocalizedImageComponent
  ],
  exports: [
    LocalizedCurrencyValueComponent,
    LocalizedDatetimeValueComponent,
    LocalizedTextComponent,
    LocalizedImageComponent
  ],
  providers: []
})

export class LocalizedUIV2Module { }
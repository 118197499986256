import { CustomerPK } from './user.model';

export interface Worksheet {
  id: string;
  customerPK: CustomerPK;
  name: string;
  storageAreas?: StorageArea[];
  status: string;
  totalValue: number;
  storageAreaOrder?: string[];
  created: string;
  lastModified: string;
  closedDate?: string;
  inventoryDate: string;
  deleted: boolean;
  isActiveInventory: boolean;
  isMostRecentWorksheet?: boolean;
}

export interface WorksheetUpdate {
  name?: string;
  status?: string;
  storageAreaOrder?: string[];
}

export interface StorageAreaUpdate {
  name?: string;
  worksheetItemOrder?: string[];
}

export interface WorksheetLastModified {
  id: string;
  lastModified: string;
}

export interface StorageArea {
  id: string;
  name: string;
  worksheetId?: string;
  worksheetItems?: WorksheetItem[];
  worksheetItemOrder?: string[];
  expandStatus: boolean;
}

export interface StorageAreaLastModified {
  worksheetLastModified: WorksheetLastModified;
  storageArea: StorageArea;
}

export interface WorksheetItem {
  id: string;
  itemId: string;
  itemType: string;
  primaryUnit: string;
  primaryUnitQty: number;
  primaryUnitType: string;
  secondaryUnit: string;
  secondaryUnitQty: number;
  secondaryUnitType: string;
  lastModified: Date;
  itemPrice: ItemPrice;
  isEach?: boolean;
  ignoreStandardSecondaryUnit?: boolean;
}

export interface ItemPrice {
  casePrice: number;
  unitPrice: number;
  caseCatchWeightPrice: number;
  unitCatchWeightPrice: number;
  catchWeightUom: string;
}

export interface WorksheetSummary {
  id: string;
  customerPK: CustomerPK;
  name: string;
  created: Date;
  lastModified: Date;
  status: string;
  totalValue: number;
}

export interface WorksheetItemLastModified {
  worksheetLastModified: WorksheetLastModified;
  worksheetItem: WorksheetItem;
}

export interface WorksheetItemPost {
  itemId: string;
  itemType: string;
  primaryUnit: string;
  primaryUnitQty: string;
  primaryUnitType: string;
  secondaryUnit: string;
  secondaryUnitQty: string;
  secondaryUnitType: string;
}

export interface WorksheetItemGroupPost {
  customerPK: CustomerPK;
  itemId: string;
  itemType: string;
  primaryUnit: string;
  primaryUnitQty: string;
  primaryUnitType: string;
  secondaryUnit: string;
  secondaryUnitQty: string;
  secondaryUnitType: string;
}

export interface WorksheetItemChange {
  worksheetId: string;
  storageAreaId: string;
  worksheetItem: WorksheetItem;
  saveRetryAttemptCount: number;
}

export interface Category {
  name: string;
  categorySequence: number;
  materialNumbers?: string[];
}

export interface CustomGuide {
  id: string;
  name: string;
  categories: Category[];
}

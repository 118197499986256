import { Injectable } from '@angular/core';
import { CustomerPK, Worksheet, WorksheetLastModified } from '@gfs/shared-models';
import { InventoryService, WorksheetService, falseyToNull } from '@gfs/shared-services';
import { isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { WorksheetDataServiceBLL } from './worksheet-data.bll';

@Injectable({ providedIn: 'root' })
export class WorksheetDataService {



  constructor(
    private store: Store<AppState>,
    private bll: WorksheetDataServiceBLL,
    private inventoryService: InventoryService,
    private worksheetService: WorksheetService
  ) { }

  getWorksheetById(worksheetId: string): Observable<Worksheet> {
    return this.bll.selectWorksheetById$(this.store, worksheetId).pipe(isTruthy(),first());
  }

  getWorksheetById$(worksheetId: string): Observable<Worksheet> {
    return this.bll.selectWorksheetById$(this.store, worksheetId);
  }

  triggerLoadWorksheetData(worksheetId: string) {
    return this.bll.dispatchLoadWorksheetActions(this.store, falseyToNull(worksheetId));
  }

  getMostRecentWorksheet(pk: CustomerPK): Observable<Worksheet> {
    return this.bll.fetchLatestWorksheet(this.inventoryService, pk);
  }

  cloneWorksheet(s: string, pk: CustomerPK): Observable<Worksheet> {
    return this.bll.copyWorksheet(this.worksheetService, s, pk);
  }

  deleteWorksheetById(worksheetId: string): Observable<WorksheetLastModified> {
    return this.bll.deleteWorksheetById(this.worksheetService, worksheetId);
  }

  closeWorksheet(worksheetId: string) {
    return this.bll.closeWorksheetById(this.worksheetService, worksheetId);
  }

  getAllWorksheets(pk: CustomerPK): Observable<Worksheet[]> {
    return this.bll.getAllWorksheets(this.inventoryService, pk);
  }

  clearWorksheetFromState(worksheetId: string) {
    return this.bll.clearWorksheetFromState(this.store, worksheetId);
  }

  refreshWorksheetItems(): void {
    return this.bll.refreshWorksheetItems(this.store);
  }
}
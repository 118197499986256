import { RecipeConstants } from '@gfs/constants';
import { LocalizedValue } from '@gfs/shared-models';

export function asLocalizedValue(value: string): LocalizedValue[] {
    return [{ languageCode: RecipeConstants.LANGUAGES.DEFAULT, value } as LocalizedValue];
}

export function caseInsensitiveEquals(a, b): boolean {
    return (a ?? '').localeCompare(b ?? '', undefined, { sensitivity: 'base' }) === 0;
}

export function moveKeyToIndex<T>(
    currentSequence: Array<T>,
    event: {
      moveToIndex: any;
      moveFromIndex: any;
    }
  ) {
    const arrayCopy = [...currentSequence];
    const targetValue = arrayCopy[event.moveFromIndex];
    const modifiedArray = arrayCopy.filter((_, index) => index !== event.moveFromIndex);

    modifiedArray.splice(clamp(
      +event.moveToIndex,
      0,
      currentSequence.length
    ), 0, targetValue);

    return modifiedArray;
}


export const clamp = (value: number, min: number, max: number): number => {
    return Math.min(Math.max(value, min), max);
  };
import { Inject, Injectable } from '@angular/core';
import { IAppContext } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { CustomerUtilService } from '@gfs/shared-services/services/customer-util.service';
import { DataLayerService } from '@gfs/shared-services/services/google-tag-manager/data-layer.service';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
 customer = JSON.parse((localStorage.getItem('customer')))
 customer_unit = this.customer?.customerId;
 entity_type =  this.customer?.entityType;
 event_timestamp = new Date().toString();
 isUsCustomer$ = this.store.select(state => state.auth).pipe(map(auth => this.customerUtilService.isUsCustomer(auth.pk)));

constructor(
  private dataLayer: DataLayerService,
  public store: Store<AppState>,
  public customerUtilService: CustomerUtilService,
  ) {}

  GA4EventLogger(eventName : string){
    this.dataLayer.push({
      event: eventName,
      ...this.createEventParams(eventName)
    })
  }

  createEventParams(eventName){
    let params;
    switch(eventName){
      case('close_inventory') : {
        params = {
          customer : {
            customer_unit: this.customer_unit,
            entity_type: this.entity_type
          },
          interaction:{
            event_timestamp : this.event_timestamp
          }
        }
      }
    }
    return params;
  }
}

<mat-list
  *ngIf="(worksheet$ | async) && (assignedStorageAreas$ | async) && (unAssignedStorageArea$ | async)"
  id="storage-area-list"
  cdkDropList
  [cdkDropListDisabled]="!(isOnline$ | async)"
  class="item-drag"
  (cdkDropListDropped)="drop($event)">
    <mat-list-item tabindex="0"
      class="storage-area-bkg"
      (click)="storageAreaClicked({id: 'unassigned'})"
      id="storage-area-list-item-unassigned"
    >
      <div class="storage-area-item">
        <div cdkDragHandle class="drag-handle"></div>
        <p class="storage-area-name">
          {{'INVENTORY_WORKSHEET.UNASSIGNED' | translate}} ({{(unAssignedStorageArea$ | async ).worksheetItems.length}})
        </p>
        <div class="icon-container"></div>
      </div>
    </mat-list-item>
    <mat-list-item  id="storage-area-list-item-{{storageArea.name.replace(' ', '-')}}-{{storageArea.id}}"
      class="storage-area-bkg"
      *ngFor="let storageArea of (assignedStorageAreas$ | async)"
      (focus)="onFocus(storageArea.id,storageArea.name)"
      (click)="storageAreaClicked(storageArea)"
      (mouseover)="onFocus(storageArea.id,storageArea.name)"
      (mouseout)= "onBlur(storageArea.id, storageArea.name)"
      (keydown.ArrowRight) = "onRightArrow(storageArea.id,storageArea.name)"
      (keydown.ArrowLeft) = "onLeftArrow(storageArea.id,storageArea.name)"
      (keyup.ENTER)="storageAreaClicked(storageArea)"
      tabindex="0"
      cdkDrag
      cdkDragBoundary="#storage-area-list"
      style="border-top-width:0">
    <div class="storage-area-item">
      <div cdkDragHandle class="drag-handle">
        <img *ngxPermissionsOnly="permissionRoles.updateStorageAreaOrder" class="img-drag-handle" src="assets/images/category_draghandle_gray.svg" alt="Drag & Drop Button" i18n-alt="@@DragAndDropAlt"/>
      </div>
      <p class="storage-area-name">{{getTranslation(storageArea.name) | translate | truncated:17 }} ({{storageArea.worksheetItems.length}})</p>
      <div id="storage-area-list-edit-container-{{storageArea.name.replace(' ', '-')}}-{{storageArea.id}}"
        class="icon-container storage-area-list-edit-container">
        <button
          *ngxPermissionsOnly="permissionRoles.updateStorageArea"
          id="storage-area-list-edit-button-{{storageArea.name.replace(' ', '-')}}-{{storageArea.id}}"
          class="icon-hover-bkg"
          [disabled]="!(isOnline$ | async)"
          (click)="onEditStorageArea(storageArea)"
          tabindex="-1"
          attr.aria-label="{{ 'STORAGE_AREA.EDIT_STORAGE_AREA_BUTTON_ARIA_LABEL' | translate }}">
           <mat-icon class="icon storage-area-edit-icon" [svgIcon]="editButtonIcon" alt="Edit Button"></mat-icon>
        </button>
      </div>
      <div id="storage-area-list-delete-container-{{storageArea.name.replace(' ', '-')}}-{{storageArea.id}}" class="icon-container storage-area-list-delete-container">
        <button
          *ngxPermissionsOnly="permissionRoles.deleteStorageArea"
          id="storage-area-list-delete-button-{{storageArea.name.replace(' ', '-')}}-{{storageArea.id}}"
          class="icon-hover-bkg"
          [disabled]="!(isOnline$ | async)"
          (click)="onDeleteStorageArea(storageArea)"
          tabindex="-1"
          attr.aria-label="{{ 'STORAGE_AREA.DELETE_STORAGE_AREA_BUTTON_ARIA_LABEL' | translate }}">
          <mat-icon class="icon storage-area-delete-icon" [svgIcon]="deleteButtonIcon" alt="Delete Button" i18n-alt="@@DeleteButtonAlt"></mat-icon>
        </button>
      </div>
    </div>
    </mat-list-item>
</mat-list>

import {
  animate,
  AnimationTriggerMetadata,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

// Disabling no-duplicate-string because while the strings are duplicate, they are not meant to be shared.
/* eslint-disable  */
export const animations: {
  readonly badgeIcon: AnimationTriggerMetadata;
} = {
  badgeIcon: trigger('badgeIcon', [
    state('grow', style({})),
    transition('* => grow', [
      animate(
        '250ms ease-in',
        keyframes([
          style({
            opacity: 0,
            transform: 'scale(0) translateX(48%)',
            offset: 0
          }),
          style({
            opacity: 1,
            transform: 'scale(1) translateX(48%)',
            offset: 1.0
          })
        ])
      )
    ]),
    state('heartbeat', style({})),
    transition('* => heartbeat', [
      animate(
        400,
        keyframes([
          style({
            transform: 'scale(1) translateX(48%)',
            offset: 0
          }),
          style({
            transform: 'scale(1.3) translateX(48%)',
            offset: 0.5
          }),
          style({
            transform: 'scale(1) translateX(48%)',
            offset: 1.0
          })
        ])
      )
    ]),
    state('shrink', style({})),
    transition('* => shrink', [
      animate(
        '200ms ease-out',
        keyframes([
          style({
            opacity: 1,
            transform: 'scale(1) translateX(48%)',
            offset: 0
          }),
          style({
            opacity: 0,
            transform: 'scale(0) translateX(48%)',
            offset: 1.0
          })
        ])
      )
    ])
  ]),
};
/* eslint-enable */

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AppConfigService } from '@gfs/shared-services';
import { Observable } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAuthStateFacade } from '@gfs/store/common';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  authorizedBaseURL: string[];

  constructor(
    private appConfigSvc: AppConfigService,
    public store: Store<IAuthStateFacade>,
  ) {
    const config = this.appConfigSvc.getSettings();

    this.authorizedBaseURL = [
      config.API_BASE_URL,
      config.INVENTORY_API_BASE_URL,
    ];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isAuthRequired = this.authorizedBaseURL.some(url => RegExp('^' + url).exec(request.url));
    return isAuthRequired
      ? this.setBearerToken(request, next)
      : next.handle(request);
  }

  setBearerToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store
      .select(state => state.auth)
      .pipe(
        map(auth => auth.user.accessToken),

        // Keep first(). Otherwise requests to resource servers will repeat infinitely
        first(),
        concatMap((accessToken: string) => {
          const headers = request.headers.set('Authorization', `Bearer ${accessToken}`)
          const requestWithAuth = request.clone({ headers });
          return next.handle(requestWithAuth);
        })
      );
  }
}

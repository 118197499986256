import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function noLeadingOrTrailingSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const leadingSpace = control.value.startsWith(' ');
        const trailingSpace = control.value.endsWith(' ');
        const isInvalid = leadingSpace || trailingSpace;
        if (isInvalid) {
            return { noLeadingOrTrailingSpaceValidatorError: true };
        }
        return null;
    };
}

import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/inventory/reducers';

@Component({
  selector: 'gfs-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NetworkStatusComponent {
  @Output() expandedToggle = new EventEmitter<void>();
  isOnline$ = this.store.select(state => state.network.isOnline);
  retryingConnection$ = this.store.select(state => state.network.retryingConnection);
  isMobile$ = this.store.select(state => state.layout.isMobile);
  expanded = true;

  constructor(private store: Store<AppState>) {
    this.isOnline$.subscribe(_ => this.expanded = true);
  }

  onExpandedToggle() {
    this.expandedToggle.emit();
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from '@gfs/shared-components';
import { Worksheet } from '@gfs/shared-models';
import { MessageService } from '@gfs/shared-services';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { promiseMeAlways } from '@inventory-ui/v2/common/preparing-app/preparing-app.component';
import { WorksheetDataService } from '@inventory-ui/v2/common/services/worksheet-data.service';
import { Observable, iif, of } from 'rxjs';
import { catchError, concatMap, first, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-worksheet-close',
  template: '',
})
export class CloseWorksheetComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingSpinner: LoadingSpinnerOverlayService,
    private worksheetData: WorksheetDataService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.activatedRoute.data.pipe(
      first(),
      map<any, Worksheet>(({ 0: worksheet }) => worksheet),
      mergeMap((worksheet: Worksheet) => this.open(worksheet)),
    ).subscribe();
  }

  open(row: Worksheet): Observable<string> {

    return this.createConfirmationModal(row)
      .afterClosed()
      .pipe(
        first(),
        tap(() => this.showSpinner()),
        concatMap((worksheet) => {
          return iif(() => !!worksheet,
            of(worksheet)
              .pipe(
                concatMap(({ id }) => this.closeWorksheet(id)),
                map(() => null as string),
                catchError(
                  (_, caught) => of('INVENTORY.FAILED_TO_CLOSE_WORKSHEET')
                )
              ),
            of<string>(null)
          );
        }),
        tap((message) => {
          this.hideSpinner();
          if (message) {
            this.messageService.queue(message);
          }
        }),
        mergeMap((x) =>
          promiseMeAlways(
            this.navigateToWorksheetList(this.router)
          )
            .pipe(
              map(() => x)
            )
        )
      );
  }

  createConfirmationModal(row) {
    return createCloseWorksheetConfirmationModal(this.dialog, row);
  }

  showSpinner() {
    return this.loadingSpinner.show();
  }

  hideSpinner() {
    return this.loadingSpinner.hide();
  }

  closeWorksheet(worksheetId: string) {
    return this.worksheetData.closeWorksheet(worksheetId);
  }

  async navigateToWorksheetList(router: Router) {
    return router.navigateByUrl('/inventory', { replaceUrl: true });
  }
}

//#region domain extensions

export const createCloseWorksheetConfirmationModal = (dialog: MatDialog, row: Worksheet) => {
  return dialog
    .open<ConfirmationModalComponent, any, Worksheet>
    (
      ConfirmationModalComponent,
      {
        data: {
          returnData: row,
          title: 'MODALS.CLOSE_WORKSHEET.DIALOG_TITLE',
          titleData: { worksheetName: row.name },
          subtitle: 'MODALS.CLOSE_WORKSHEET.DIALOG_SUBTITLE',
          submitButtonAriaLabel: 'MODALS.CLOSE_WORKSHEET.SUBMIT_BUTTON',
          submitButtonLabel: 'MODALS.CLOSE_WORKSHEET.SUBMIT_BUTTON',
          cancelButtonId: 'cancel-close-worksheet-button',
          submitButtonId: 'close-worksheet-button',
        },
        width: '604px',
        height: '253px'
      });
};

<div class="reset-modal-container">
  <button
    mat-icon-button
    (click)="closeModal(false)"
    class="close-button"
    attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
    <mat-icon svgIcon="close-icon"></mat-icon>
  </button>
  <div class="reset-modal-content">
    <div class="modal-text confirm-reset-title">{{ 'INVENTORY.CONFIRM_RESET_WORKSHEET' | translate }}</div>
    <div class="modal-text confirm-reset-description">{{ 'INVENTORY.CONFIRM_RESET_DESCRIPTION' | translate }}</div>
  </div>
  <div class="modal-button-bar">
    <button id="reset-cancel-button"
      type="button"
      class="secondary-button large-button"
      (click)="closeModal(false)">
      {{ 'MODALS.CANCEL' | translate }}
    </button>
    <button id="reset-confirm-button"
      type="button"
      class="primary-button large-button"
      (click)="closeModal(true)">
      {{ 'INVENTORY.RESET_WORKSHEET' | translate }}
    </button>
  </div>
</div>
import { Action } from '@ngrx/store';
import { CustomItem, GeneralItem, PurchaseUnit } from '@gfs/shared-models';

export enum ActionTypes {
  GetAllCustomItemsAttempt = '[Customer Items] Get All Custom Items Attempt',
  GetAllCustomItemsSuccess = '[Customer Items] Get All Custom Items Success',
  GetAllCustomItemsError = '[Customer Items] Get All Custom Items Error',
  GetAllGeneralItemsAttempt = '[Customer Items] Get All General Items Attempt',
  GetAllGeneralItemsSuccess = '[Customer Items] Get All General Items Success',
  GetAllGeneralItemsError = '[Customer Items] Get All General Items Error',
  UpdateCustomItemPriceAttempt = '[Customer Items] Update Custom Item Price Attempt',
  UpdateCustomItemPriceSuccess = '[Customer Items] Update Custom Item Price Success',
  UpdateCustomItemPriceError = '[Customer Items] Update Custom Item Price Error',
  UpdateLocalGeneralItem = '[Customer Items] Update Local General Item',
  DeleteLocalCustomItem = '[Customer Items] Delete Local Custom Item',
  DeleteGroupLocalCustomItem = '[Customer Items] Delete Group Local Custom Item',
  DeleteCustomItemAttempt = '[Customer Items] Delete Custom Item Attempt',
  DeleteGroupCustomItemAttempt = '[Customer Items] Delete Group Custom Item Attempt',
  DeleteCustomItemSuccess = '[Customer Items] Delete Custom Item Success',
  DeleteGroupCustomItemSuccess = '[Customer Items] Delete Group Custom Item Success',
  DeleteCustomItemError = '[Customer Items] Delete Custom Item Error',
  DeleteGeneralItemAttempt = '[Customer Items] Delete General Item Attempt',
  DeleteGroupGeneralItemAttempt = '[Customer Items] Delete Group General Item Attempt',
  DeleteGeneralItemsSuccess = '[Customer Items] Delete General Item Success',
  DeleteGroupGeneralItemsSuccess = '[Customer Items] Delete Group General Item Success',
  DeleteGeneralItemError = '[Customer Items] Delete General Item Error'
}

export class GetAllCustomItemsAttempt implements Action {
  readonly type = ActionTypes.GetAllCustomItemsAttempt;
}

export class GetAllCustomItemsSuccess implements Action {
  readonly type = ActionTypes.GetAllCustomItemsSuccess;
  constructor(public payload: CustomItem[]) {}
}

export class GetAllCustomItemsError implements Action {
  readonly type = ActionTypes.GetAllCustomItemsError;
  constructor(public error: any) { }
}

export class GetAllGeneralItemsAttempt implements Action {
  readonly type = ActionTypes.GetAllGeneralItemsAttempt;
}

export class GetAllGeneralItemsSuccess implements Action {
  readonly type = ActionTypes.GetAllGeneralItemsSuccess;
  constructor(public payload: GeneralItem[]) {}
}

export class GetAllGeneralItemsError implements Action {
  readonly type = ActionTypes.GetAllGeneralItemsError;
  constructor(public error: any) { }
}

export class UpdateCustomItemPriceAttempt implements Action {
  readonly type = ActionTypes.UpdateCustomItemPriceAttempt;
  constructor(public payload: { customItemId: string, purchaseUnit: PurchaseUnit }) {}
}

export class UpdateCustomItemPriceSuccess implements Action {
  readonly type = ActionTypes.UpdateCustomItemPriceSuccess;
  constructor(public payload: CustomItem) {}
}

export class UpdateCustomItemPriceError implements Action {
  readonly type = ActionTypes.UpdateCustomItemPriceError;
  constructor(public error: any) { }
}

export class UpdateLocalGeneralItem implements Action {
  readonly type = ActionTypes.UpdateLocalGeneralItem;
  constructor(public payload: GeneralItem) {}
}

export class DeleteLocalCustomItem implements Action {
  readonly type = ActionTypes.DeleteLocalCustomItem;
  constructor(public payload: { customItemId: string, generalItemId: string }) {}
}

export class DeleteGroupLocalCustomItem implements Action {
  readonly type = ActionTypes.DeleteGroupLocalCustomItem;
  constructor(public payload: { customItemIds: string[], generalItemIds: string[] }) {}
}

export class DeleteCustomItemAttempt implements Action {
  readonly type = ActionTypes.DeleteCustomItemAttempt;
  constructor(public payload: { customItemId: string, generalItemId: string }) {}
}

export class DeleteGroupCustomItemAttempt implements Action {
  readonly type = ActionTypes.DeleteGroupCustomItemAttempt;
  constructor(public payload: { customItemIds: CustomItem[], generalItemIds: GeneralItem[] }) {}
}

export class DeleteCustomItemSuccess implements Action {
  readonly type = ActionTypes.DeleteCustomItemSuccess;
  constructor(public payload: CustomItem) {}
}

export class DeleteGroupCustomItemSuccess implements Action {
  readonly type = ActionTypes.DeleteGroupCustomItemSuccess;
  constructor(public payload: CustomItem[]) {}
}

export class DeleteCustomItemError implements Action {
  readonly type = ActionTypes.DeleteCustomItemError;
  constructor(public error: any) { }
}

export class DeleteGeneralItemAttempt implements Action {
  readonly type = ActionTypes.DeleteGeneralItemAttempt;
  constructor(public payload: string) {}
}
export class DeleteGroupGeneralItemAttempt implements Action {
  readonly type = ActionTypes.DeleteGroupGeneralItemAttempt;
  constructor(public payload: GeneralItem[]) {}
}

export class DeleteGeneralItemSuccess implements Action {
  readonly type = ActionTypes.DeleteGeneralItemsSuccess;
  constructor(public payload: GeneralItem) {}
}
export class DeleteGroupGeneralItemsSuccess implements Action {
  readonly type = ActionTypes.DeleteGroupGeneralItemsSuccess;
  constructor(public payload: GeneralItem[]) {}
}

export class DeleteGeneralItemError implements Action {
  readonly type = ActionTypes.DeleteGeneralItemError;
  constructor(public error: any) { }
}

export type ActionUnion =
  GetAllCustomItemsAttempt |
  GetAllCustomItemsSuccess |
  GetAllCustomItemsError |
  GetAllGeneralItemsAttempt |
  GetAllGeneralItemsSuccess |
  GetAllGeneralItemsError |
  UpdateCustomItemPriceAttempt |
  UpdateCustomItemPriceSuccess |
  UpdateCustomItemPriceError |
  UpdateLocalGeneralItem |
  DeleteLocalCustomItem |
  DeleteCustomItemAttempt |
  DeleteCustomItemSuccess |
  DeleteCustomItemError |
  DeleteGeneralItemAttempt |
  DeleteGeneralItemSuccess |
  DeleteGeneralItemError |
  DeleteGroupLocalCustomItem |
  DeleteGroupCustomItemAttempt |
  DeleteGroupCustomItemSuccess | 
  DeleteGroupGeneralItemAttempt |
  DeleteGroupGeneralItemsSuccess
  

<gfs-edit-gfs-item *ngIf="isGfsItem"
  [countableItem]="item"
  [focusSection]="focusSection"
  [isEdit]="false"
  [isDuplicate]="true"
  [currentStorageAreaId]="currentStorageAreaId"
  (closeEmitter)="close()"
></gfs-edit-gfs-item>
<gfs-add-custom-item *ngIf="!isGfsItem"
  [countableItem]="item"
  [focusSection]="focusSection"
  [isEdit]="false"
  [isDuplicate]="true"
  [isManage]="isManage"
  [isAdd]="true"
  [currentStorageAreaId]="currentStorageAreaId"
  (closeEmitter)="close()"
></gfs-add-custom-item>

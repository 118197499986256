import { Observable } from 'rxjs';
import { ApplicationUserRole } from './application-user-role.enum';

export interface User {
  idToken?: string;
  accessToken?: string;
  claims?: any;
  entitlements?: Entitlement[];
  userRole: ApplicationUserRole
}

export interface CustomerPK {
  salesOrg: string;
  channel: string;
  division: string;
  customerId: string;
  entityType: string;
  hash?: string;
}

export interface Entitlement {
  customerPK: CustomerPK;
  customerName: string;
  customerCountry: string;
  roleName: string;
  groupCode: string;
  chainCode: string;
  plantId: string;
  entityType: string;
  slug?: string;
}

export class SelectedEntitlement {
  entitlement: Observable<Entitlement>;
}

export class ObservableDependency<TModel extends any> {
  constructor(public value: Observable<TModel>) { }
}
export class SelectedEntitlementObservableDependency
  extends ObservableDependency<Entitlement> {

}

export interface RolePermission {
  role: string;
  permissions: string[];
}

<gfs-network-status [hidden]="!showBanner"></gfs-network-status>
<ng-container *ngIf="!(isMobile$ | async); else mobileHeader">
  <div #headerContainer id="header-cont">
    <div class="top-section">
      <div class="boxed-content">
        <div *ngIf="showElements" class="application-selection">
          <div *ngFor="let app of gfsApplications$ | async">

            <a class="{{app.label}}-link selected"
              [ngClass]="{'selected': app.selected, disabled: !(isOnline$ | async)}"
              [routerLink]="['/redirect', app.key]"
              role="link">{{app.label | translate | uppercase}}</a>
          </div>
        </div>
        <div class="right-side">
          <gfs-customer-unit-selector *ngIf="showElements"></gfs-customer-unit-selector>
          <gfs-language-menu></gfs-language-menu>
          <gfs-account-menu></gfs-account-menu>
        </div>
      </div>
    </div>
    <div class="mid-section">
      <div class="boxed-content">
        <a
          class="logo"
          [routerLink]="LOGO_ROUTER_LINK$ | async">
          <img [hidden]="!(isOnline$ | async)" src="{{ 'HEADER.LOGO.URL' | translate }}"
            alt="{{ 'HEADER.LOGO.TEXT' | translate }}" />
          <img [hidden]="isOnline$ | async" src="{{ 'HEADER.LOGO.OFFLINE_URL' | translate }}"
            alt="{{ 'HEADER.LOGO.TEXT' | translate }}" />
        </a>
        <div *ngIf="showElements" class="header-container">
          <div class="cart">
            <gfs-cart-icon [disabled]="!(isOnline$ | async)" class="cart"></gfs-cart-icon>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showSubHeader" class="bottom-section">
      <div class="boxed-content">
        <gfs-sub-header></gfs-sub-header>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #mobileHeader>
  <header>
    <div class="header mid-section">
      <gfs-hamburger-menu class="hamburger-container"></gfs-hamburger-menu>
      <a
        class="logo"
        [routerLink]="LOGO_ROUTER_LINK$ | async">
        <img [hidden]="!(isOnline$ | async)" src="{{ 'HEADER.LOGO.URL' | translate }}"
          alt="{{ 'HEADER.LOGO.TEXT' | translate }}" />
        <img [hidden]="isOnline$ | async" src="{{ 'HEADER.LOGO.OFFLINE_URL' | translate }}"
          alt="{{ 'HEADER.LOGO.TEXT' | translate }}" />
      </a>
      <div *ngIf="showElements" class="cart">
        <gfs-cart-icon [disabled]="!(isOnline$ | async)" class="cart"></gfs-cart-icon>
      </div>
    </div>
  </header>
</ng-template>

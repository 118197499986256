import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppConfigGuard } from '@gfs/shared-services';

export const appConfigCanActivateFn: CanActivateFn =
  (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree => {
    const service = inject(AppConfigGuard);
    return service.canActivate(route, state);
  };

<gfs-select-menu
  buttonId="customer-unit-select"
  [disabled]="!(isOnline$ | async)"
  [options]="entitlements$ | async"
  [value]="selectedUnit"
  (valueChange)="selectUnit($event)"
  [optionLabelFn]="getOptionLabel"
  optionIdField="customerPK.customerId"
>
  <mat-icon class="shop-icon" svgIcon="shop"></mat-icon>
  {{selectedUnit.customerName | truncated: 16}} #{{selectedUnit.customerPK.customerId}}
</gfs-select-menu>

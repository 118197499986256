import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingSpinnerOverlayService } from '../loader/loading-spinner-overlay.service';

@Component({
  selector: 'gfs-loading-overlay-end',
  template: '{{ this.loadingOverlay.hide() }}',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadEndComponent {
  constructor(public loadingOverlay: LoadingSpinnerOverlayService) { }
}
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { InventoryConstants } from '@gfs/constants';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { EditItemDialogComponent } from '../../../lib/add-items/edit-item-dialog/edit-item-dialog.component';
import { DuplicateItemDialogComponent } from '../../../lib/add-items/duplicate-item-dialog/duplicate-item-dialog.component';
import { CountableItem, CustomItem, CustomItemUpdate, MeasurementUnit, Supplier } from '@gfs/shared-models';
import { InventoryValueService, LocalizedValueUtilsService } from '@gfs/shared-services';
import {
  DeleteCustomItemAttempt,
  DeleteLocalCustomItem,
  GetAllCustomItemsAttempt,
  GetAllGeneralItemsAttempt,
  UpdateCustomItemPriceAttempt
} from '@gfs/store/inventory/actions/customerItems.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { UpdateCustomItemsAttempt } from '@gfs/store/inventory/actions/inventoryItems.actions';
import locale_fr_CA from '@angular/common/locales/fr-CA';

@Component({
  selector: 'gfs-custom-item-card',
  templateUrl: './custom-item-card.component.html',
  styleUrls: ['./custom-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomItemCardComponent implements OnInit {
  @Input() customItems: CustomItem[];
  @Input() activeItems: CustomItem[];
  @Input() checkboxSelectedItemsFromPanel:CustomItem[] ;
  @Input() countableItems$: Observable<{ [s: string]: CountableItem }>;
  @Input() suppliers: Supplier[];
  @Output() lastPriceEnter = new EventEmitter<string>();
  @Output() checkboxCheckedEvent = new EventEmitter<any>();
  weightUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits);
  currentLang$ = this.store.select(state => state.layout.language);
  isMobile$ = this.store.select(state => state.layout.isMobile);
  isOnline$ = this.store.select(state => state.network.isOnline);
  customerPK$ = this.store.select(state => state.auth.pk);
  worksheetItems$ = this.store.select(state => state.worksheets.worksheets[state.worksheets.selected]).pipe(
    filter(ws => !!ws),
    map(ws => {
      const itemMap = {};
      ws?.storageAreas?.forEach(sa => {
        if (sa.worksheetItems?.length > 0) {
          sa.worksheetItems.forEach(item => {
            if (item.itemType === 'GENERAL') {
              itemMap[item.itemId] = item;
            }
          });
        }
      });
      return itemMap;
    })
  );
  useManageNonGfsGroupOperations$: Observable<boolean> = this.store.select((state)=> state.worksheets?.useManageNonGfsToggleState    ).pipe(
    filter(v => v !== undefined),
    );
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;
  tripleDotPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet.itemTripleDot;
  priceInputRegex: any;
  itemPriceForm: any;
  customItems$: Observable<CustomItem[]> = this.store
    .select(appState => appState.customerItems.customItems)
    .pipe(
      filter(items => !!items),
      map(items => items.filter(item => !item.deleted))
    );
    
  constructor(
    private store: Store<AppState>,
    private localizedValueUtils: LocalizedValueUtilsService,
    private currencyPipe: CurrencyPipe,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    registerLocaleData(locale_fr_CA);
    this.currentLang$.subscribe(lang => {
      this.priceInputRegex = InventoryConstants.PURCHASE_UNIT_LOCALIZED_REGEX[lang] ??
        InventoryConstants.PURCHASE_UNIT_LOCALIZED_REGEX.en_CA;
    });

    this.itemPriceForm = this.formBuilder.group({
      price: new UntypedFormControl('')
    });
  }

  getCustomItems(){
    if(this.activeItems){
      return this.activeItems
    } else {
      return this.customItems;
    }
  }
 
  getLocalizedMeasurementUnitName(unit: MeasurementUnit, lang: string): string {
    return this.localizedValueUtils.getLocalizedMeasurementUnitName(unit, lang);
  }

  getLocalizedMeasurementUnitSymbol(unit: MeasurementUnit, lang: string): string {
    return this.localizedValueUtils.getLocalizedMeasurementUnitSymbol(unit, lang);
  }


  displayPurchaseUnit(item: CustomItem, lang: string): Observable<string> {
    if (item.purchaseUnit?.custom?.catchWeight) {
      return this.weightUnits$.pipe(
        map((units: MeasurementUnit[]) => {
          const unit = units?.find(u => u.id === item.purchaseUnit.custom.weightUnitId);
          return this.getLocalizedMeasurementUnitName(unit, lang);
        })
      );
    }
    const unitName = item.purchaseUnit?.custom?.name;
    if (unitName && (unitName.toUpperCase() === 'CASE' || unitName.toUpperCase() === 'UNIT')) {
      return of(this.translate.instant(`ADD_ITEMS.${unitName.toUpperCase()}`));
    }
    return of(unitName);
  }

  displayPricePerWeight(item: CustomItem, lang: string): Observable<string> {
    if (item.purchaseUnit.custom.catchWeight) {
      return of('');
    }

    return this.weightUnits$.pipe(
      filter(units => !!units),
      first(),
      map(units => {
        const price = item.purchaseUnit?.custom?.price;
        if (price) {
          const unit = units.find(u => u.id === item.purchaseUnit.custom.weightUnitId);
          const symbol = this.getLocalizedMeasurementUnitSymbol(unit, lang);
          if (symbol) {
            const netWeight = Number.parseFloat(item.purchaseUnit.custom.netWeight);
            const dividedPrice = netWeight ? (Number.parseFloat(price) / netWeight) : null;
            const displayPrice = dividedPrice ? this.currencyPipe.transform(dividedPrice, 'CAD', '$', undefined, lang) : null;
            return displayPrice ? displayPrice + '/' + symbol : '';
          }
        }
        return '';
      })
    );
  }

  onEditClicked(itemId: string) {
    this.countableItems$.pipe(first()).subscribe(countableItems => {
      this.dialog.open(EditItemDialogComponent, {
        data: {
          item: countableItems[itemId],
          isManage: true
        },
        width: '840px',
        autoFocus: false,
        panelClass: 'inventory-edit-item-dialog'
      });
    });
  }

  convertPriceToNumber(price: string): number {
    if (price) {
      const replacedPrice = price.toString().replace(
        this.priceInputRegex.REPLACE_REGEX,
        (match) => this.priceInputRegex.REPLACEMENTS[match]
      );

      const parsed = Number.parseFloat(replacedPrice);
      return !isNaN(parsed) ? parsed : null;
    }
    return null;
  }

  formatPrice(price: string, lang: string) {
    if (price) {
      return this.currencyPipe.transform(this.convertPriceToNumber(price), 'CAD', '', undefined, lang);
    }
    return price;
  }

  saveUpdatedPrice(item: CustomItem) {
    item.purchaseUnit.custom.price = this.convertPriceToNumber(item.purchaseUnit.custom.price)?.toString();
    this.store.dispatch(new UpdateCustomItemPriceAttempt({ customItemId: item.id, purchaseUnit: item.purchaseUnit }));
  }

  isAllowedToEdit(item: CustomItem): Observable<boolean> {
    return this.customerPK$.pipe(
      filter(pk => !!pk),
      map(pk => pk.customerId === item.customerPK.customerId)
    );
  }

  isOnWorksheet(item: CountableItem): Observable<boolean> {
    return combineLatest(([this.worksheetItems$, this.customerPK$]))
      .pipe(
        filter(([items, pk]) => !!items && !!item?.generalItem && InventoryConstants.CUSTOMER_ENTITIES.includes(pk.entityType)),
        map(([items, _]) => !!items[item.generalItem.id])
      );
  }

  processDelete(undo: boolean, item: CountableItem) {
    if (undo) {
      this.store.dispatch(new GetAllCustomItemsAttempt());
      this.store.dispatch(new GetAllGeneralItemsAttempt());
    } else {
      this.store.dispatch(new DeleteCustomItemAttempt({
        customItemId: item.customItem.id,
        generalItemId: item.generalItem.id
      }));
    }
  }

  onDeleteClicked(itemId: string) {
    this.countableItems$.pipe(first()).subscribe(countableItems => {
      const item = countableItems[itemId];
      const title = this.translate.instant(
        'STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_MODAL.REMOVE_STORAGE_AREA_ITEM_DIALOG_TITLE',
        { itemName: item.customItem.description }
      );
      const submitButtonAriaLabel = this.translate.instant('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DELETE');
      const submitButtonLabel = this.translate.instant('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DELETE');
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        data: {
          returnData: item,
          title,
          cancelButtonId: 'cancel-delete-item-button',
          submitButtonId: 'delete-item-button',
          submitButtonAriaLabel,
          submitButtonLabel
        },
        width: '608px',
        height: '237px'
      });

      dialogRef.afterClosed().subscribe(itemToDelete => {
        if (itemToDelete) {
          this.store.dispatch(new DeleteLocalCustomItem({
            customItemId: itemToDelete.customItem.id,
            generalItemId: itemToDelete.generalItem.id
          }));

          const snackref = this.snackBar.open(`${CountableItem.ingredientData(itemToDelete, this.translate.currentLang).itemDescription} ` +
            this.translate.instant('STORAGE_AREA.DELETE_MANAGE_AREA_ITEM_DELETED_SUCCESSFULLY'),
            this.translate.instant('UNDO'), { duration: 4000 });
          let undo = false;
          snackref.afterDismissed().subscribe(() => {
            this.processDelete(undo, item);
          });
          snackref.onAction().subscribe(() => {
            undo = true;
          });
        }
      });
    });
  }

  async onDuplicateClicked(itemId: string): Promise<any> {
    return this.countableItems$
      .pipe(
        first(),
        map(countableItems => this.dialog
          .open(DuplicateItemDialogComponent, {
            data: {
              item: countableItems[itemId],
              isManage: true
            },
            width: '840px',
            autoFocus: false,
            panelClass: 'inventory-add-item-dialog'
          })
        ))
      .toPromise();
  }

  moveToNextItemPrice(index: number) {
    if (index < this.customItems.length - 1) {
      const nextIndex = index + 1;
      this.isAllowedToEdit(this.customItems[nextIndex]).subscribe(isEditable => {
        if (isEditable) {
          this.selectFullPrice(nextIndex);
        }
        else {
          this.moveToNextItemPrice(nextIndex);
        }
      });
    } else {
      const supplierId = this.customItems[index].supplierId;
      this.lastPriceEnter.emit(supplierId);
    }
  }

  selectFirstPrice(supplierId: string) {
    if (this.customItems.length > 0) {
      this.moveToNextItemPrice(-1);
    } else {
      this.lastPriceEnter.emit(supplierId);
    }
  }

  selectFullPrice(index: number) {
    const id = this.customItems[index].id;
    const element = document.getElementById('item-price-input-' + id);
    element.focus();
    (element as HTMLInputElement).select();
  }

  itemAlreadyExistsByNameOrNumber(supplierId: string, itemNameOrNum: string, isName: boolean): boolean {
    let itemExists = false;
    this.customItems$.pipe(first()).forEach((customItems) => {
      if (isName) {
        const customItem = customItems.find(
          (customIt) => (customIt.supplierId === supplierId && customIt.description === itemNameOrNum)
        );
        if (customItem) {
          itemExists = true;
        }
      } else {
        const customItem = customItems.find(
          (customIt) => (customIt.supplierId === supplierId && customIt.supplierItemCode === itemNameOrNum)
        );
        if (customItem) {
          itemExists = true;
        }
      }
    });
    return itemExists;
  }

  duplicateItemSnackbarWarning(warningCode: string) {
    this.snackBar.open(this.translate.instant(warningCode),
      this.translate.instant('OK'), { duration: 4000 });
  }

  moveToSupplier(customItemId: string, supplierId: string) {
    this.countableItems$.pipe(first()).subscribe(countItems => {
      const customItemPatch: CustomItemUpdate = { supplierId };
      const generalItemId = countItems[customItemId]?.generalItem?.id;
      const itemName = countItems[customItemId]?.customItem?.description;
      const itemNumber = countItems[customItemId]?.customItem?.supplierItemCode;
      if (this.itemAlreadyExistsByNameOrNumber(supplierId, itemName, true)) {
        this.duplicateItemSnackbarWarning('STORAGE_AREA.STORAGE_AREA_ITEM_NAME_ALREADY_EXISTS');
        return;
      }
      if (this.itemAlreadyExistsByNameOrNumber(supplierId, itemNumber, false)) {
        this.duplicateItemSnackbarWarning('STORAGE_AREA.STORAGE_AREA_ITEM_NUMBER_ALREADY_EXISTS');
        return;
      }
      this.store.dispatch(new UpdateCustomItemsAttempt({
        customItemId,
        generalItemId,
        customItemPatch
      }));
    });
  }

  itemSelectedForBulkOperation(event){
    this.checkboxCheckedEvent.emit(event)
}
}


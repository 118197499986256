import { Inject, Injectable } from '@angular/core';
import { GeneralItem, IAppContext, IngredientType, ItemReference, RecipePriceType, ResolvedItem } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { arrayToEntityDictionary, entityDictionaryToArray } from '@gfs/shared-services/core/entityUtil';
import { from, Observable, of } from 'rxjs';
import { concatMap, first, map, switchMap, tap } from 'rxjs/operators';
import { ItemDataBLL } from './item-data.bll';

export interface RecipeIngredientPrice2 {
    ingredientType: IngredientType;
    ingredientId: string;
    purchasePrice: number;
    portionPrice: number;
}

export type CalculatorConfiguration = {
    constantType: RecipePriceType;
    constantValue: string;
};

export const defaultCalculatorConfiguration: CalculatorConfiguration = {
    constantType: RecipePriceType.DEFAULT,
    constantValue: '0',
};

// @note item-data-service
@Injectable({
    providedIn: 'root',
})
export class ItemDataService {
    constructor(
        @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
        private itemDataBll: ItemDataBLL 
    ) { }

    resolveItemsByReference(
        itemRefSource: ItemReference[] ,
        recipeId? : string ,
        createNewIngredient? :string
    ): Observable<ResolvedItem[]> {
        const itemRefs$ = of(itemRefSource);
        return this.appContext.customerPK
            .pipe(concatMap(customerPK$ => {
                return this.itemDataBll.prepareItemRefs$(itemRefs$, of(customerPK$))
                    .pipe(
                        concatMap(items => this.itemDataBll.loadCoreItemData$(of(items), of(customerPK$))),
                        concatMap(items => this.itemDataBll.loadCustomItemData$(of(items), of(customerPK$))),
                        concatMap(items => this.itemDataBll.loadUnitOfMeasure$(of(items), of(customerPK$))),
                        concatMap(items => of(items)
                            .pipe(
                                map(f2 => {
                                    // @note this is a patch for the item editor to be able to update the associated general item
                                    const linkGeneralItems = f2
                                        .map(j => {
                                            if (!!j.itemReference.parent) {
                                                j.generalItem = {
                                                    id: j.itemReference.parent.key
                                                } as GeneralItem;
                                            }
                                            return j;
                                        });
                                    // @note we need to project the general items out of their children
                                    const projections = linkGeneralItems
                                        .filter(h => !!h.generalItem?.id)
                                        .map(j => {
                                            j.generalItem.id = j.itemReference.parent.key;
                                            return ({ itemReference: j.itemReference.parent } as ResolvedItem);
                                        });
                                    const generalItemDictionary = arrayToEntityDictionary<ResolvedItem>(projections, e => `${e.itemReference.type}.${e.itemReference.key}`);
                                    const distinctGeneralItems = entityDictionaryToArray<ResolvedItem>(generalItemDictionary);
                                    const mappedItemsWithoutLiterals = [...linkGeneralItems, ...distinctGeneralItems]
                                    itemRefSource?.forEach((itemref)=>{
                                       if(itemref.type === 'GFS'){
                                        mappedItemsWithoutLiterals.filter((items)=>items.gfsItem).forEach((itemDataSet)=>{
                                            itemDataSet.units.units.find((unit)=>unit.group === 'COUNT').units = [];
                                        });
                                       } else if(itemref.type === 'GENERAL'){
                                        mappedItemsWithoutLiterals.filter((items)=>items.units).forEach((itemDataSet)=>{
                                            itemDataSet.units.units.find((unit)=>unit.group === 'COUNT').units.splice(1,1)
                                        });
                                       } else {
                                        return
                                       }
                                    })                                    
                                    return mappedItemsWithoutLiterals
                                }),
                            ),
                            
                        ),concatMap((items)=>{
                           return this.appContext.customerPK.pipe(
                            concatMap((pk)=>{
                                const GFSItemsList = [] as ItemReference[]
                                itemRefSource.forEach((item)=>{
                                    if(item.type == "GFS"){
                                        GFSItemsList.push(item)
                                    }
                                })
                                return this.itemDataBll.getSAPUOMunits$( items, recipeId , pk , GFSItemsList ,createNewIngredient)
                            })
                           )
                        }),
                    );
            }));
    }

    getUOMsForItemReference(
        itemRefSource: ResolvedItem
    ): Observable<ResolvedItem> {
        return this.itemDataBll.loadUnitOfMeasure$(
            of([itemRefSource]),
            this.appContext.customerPK
        ).pipe(
            concatMap(g => from(g)),
            first()
        );

    }
}

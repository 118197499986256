import { Injectable } from '@angular/core';

export interface IGlobalDialogsService {
  closeLoadingModal(): void;
  closeResetErrorModal(): void;
  openResetErrorModal(): any;
}

@Injectable({ providedIn: 'root' })
export class MockGlobalDialogsService implements IGlobalDialogsService {
  closeLoadingModal(): void {}
  closeResetErrorModal(): void {}
  openResetErrorModal(): any {}
}

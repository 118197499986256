import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Worksheet } from '@gfs/shared-models';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { Observable, of, race, throwError } from 'rxjs';
import { concatMap, delay, first, map, tap } from 'rxjs/operators';
import { WorksheetDataService } from '../../common/services/worksheet-data.service';

@Injectable({
  providedIn: 'root'
})
export class GetWorksheetResolver {

  constructor(
    private worksheetData: WorksheetDataService,
    private loadingSpinner: LoadingSpinnerOverlayService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Worksheet> {
    return of(route.params)
      .pipe(
        first(),
        tap(({ id: worksheetId }) => {
          this.worksheetData.clearWorksheetFromState(worksheetId);
          this.loadingSpinner.show();
        }
        ),
        concatMap(({ id: worksheetId }) => this.tryGetWorksheetById(worksheetId)),
        // tap(() => this.loadingSpinner.hide()),
        first(),
      );
  }

  tryGetWorksheetById(worksheetId: any): Observable<Worksheet> {
    return tryGetWorksheetById$(this.worksheetData, worksheetId);
  }
}

//#region domain extensions
export function tryGetWorksheetById$(worksheetData: WorksheetDataService, worksheetId: string): Observable<Worksheet> {
  return race(
    of(worksheetId).pipe(
      delay(1),
      tap((id) => { worksheetData.triggerLoadWorksheetData(id); }),
      map(x => null),
      delay(60000),
      tap(x => throwError('Failed to load worksheet in 1 minute. try again in a moment')),
      first(),
    ),
    worksheetData.getWorksheetById(worksheetId),
  );
}
  //#endregion
<main>
  <div>
    <ng-container *ngIf='buildComponentState$ | async'></ng-container>
    <ng-container *ngIf='isReady$ | async'>
      <div class='page-header-container'>
        <div class='header'>
          <h1>{{ 'GENERAL_LEDGER.MANAGE_GENERAL_LEDGER' | translate }}</h1>
          <br>
        </div>
      </div>

      <div class='page-main-container'>
        <div>
          <div class='table-label'>
            {{ 'GENERAL_LEDGER.MAINTAIN_ACCOUNTS.SECTION_HEADING' | translate }}
          </div>
          <gfs-maintain-accounts-input
            [viewmodel]='maintainAccountsInputViewModel$ | async'
            (addAccount)='addAccount($event)'>
          </gfs-maintain-accounts-input>
          <gfs-maintain-accounts-table
            [viewmodel]='maintainAccountsViewModel$ | async'
            (updateAccount)='updateAccount($event)'
            (deleteGeneralAccount)="deleteGeneralAccount($event)">
          </gfs-maintain-accounts-table>
        </div>
        <gfs-assign-accounts
          [viewmodel]='assignAccountsViewModel$ | async'
          (updateItemCategoryGLAccount)='updateItemCategoryGLAccount($event)'>
        </gfs-assign-accounts>
      </div>
    </ng-container>
  </div>
</main>

import { Component, Input } from '@angular/core';

@Component({
    selector: 'gfs-warning-icon',
    templateUrl: './warning-icon.component.html',
})
export class WarningIconComponent {
    @Input() i18nTooltip: string;
    @Input() hidden: boolean;
    @Input() isPlaceholder: boolean;

    hidePlaceholder(): boolean {
        return this.isPlaceholder
            ? false
            : this.hidden;
    }
}

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';
import { ConfirmationModalComponent } from '@gfs/shared-components';
import { StorageArea } from '@gfs/shared-models';
import { MessageService, WorksheetHttpService } from '@gfs/shared-services';
import { moveKeyToIndex } from '@gfs/shared-services/extensions/primitive';
import { DeleteStorageAreasSuccessV2 } from '@gfs/store/inventory/actions/worksheets.actions';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { AppState } from '@gfs/store/inventory/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { StorageAreaDTO, WorksheetDTO } from '@gfs/v2/shared-models';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom, iif, of ,first} from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-storage-area-list-v2',
  templateUrl: './storage-area-list.component.html',
  styleUrls: ['./storage-area-list.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageAreaListV2Component {

  worksheet$ = this.store.select(state => state?.worksheets?.worksheets[state?.worksheets?.selected]);
  assignedStorageAreas$: Observable<StorageArea[]>;
  unAssignedStorageArea$: Observable<StorageArea>;
  isOnline$ = this.store.select(state => state.network.isOnline);
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;

  @Input() worksheetModel: WorksheetDTO;
  @Output() worksheetModelChange = new EventEmitter<WorksheetDTO>();
  @Output() storageAreaSelected = new EventEmitter<string>();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private worksheetHttp: WorksheetHttpService,
    private localizedMessageService: MessageService,
    private loadingOverlay: LoadingSpinnerOverlayService,
    @Inject(DOCUMENT) private document: Document

  ) { }

  async handleStorageAreaCDKDrop(
    {
      currentIndex,
      previousIndex,
      container: {
        data: storageAreaOrder
      }
    }: CdkDragDrop<string[]>
  ): Promise<any> {
    // prevent movement of items to or from index 0
    if ([previousIndex, currentIndex].indexOf(0) > -1) { return; }
    return firstValueFrom(of([
      storageAreaOrder,
      moveKeyToIndex(
        storageAreaOrder,
        {
          moveToIndex: currentIndex,
          moveFromIndex: previousIndex
        })
    ])
      .pipe(
        tap(([, newState]) => {
          this.loadingOverlay.show();
          this.worksheetModel.storageAreaOrder = newState;
        }),
        concatMap(([oldState, newState]) =>
          this.worksheetHttp.patchStorageAreaOrder(this.worksheetModel.header.worksheetId, newState)
            .pipe(
              catchError(() => {
                //revert the order since it probably wasnt persisted on the backend
                this.worksheetModel.storageAreaOrder = oldState;
                this.localizedMessageService.queue('MESSAGES.SNACKBAR_ERROR_MESSAGE');
                this.loadingOverlay.hide();
                return of(false);
              }),
            )
        ),
        tap(x => { WorksheetEffects.storageAreaSchemaChange$.next(); }),
      )
    );
  }

  storageAreaClicked(storageArea: StorageAreaDTO) {
    this.storageAreaSelected.emit(storageArea.storageAreaId)
  }

  setButtonVisibility(storageAreaId: string, storageAreaName: string, type: string, visibility: string) {
    const identifier = storageAreaName.replace(' ', '-') + '-' + storageAreaId;
    const button = this.document.getElementById(`storage-area-list-${type}-button-${identifier}`);

    if (button) {
      button.style.visibility = visibility;
    }
  }

  onRightArrow(storageAreaId: string, storageAreaName: string) {
    if (this.document.activeElement.id.startsWith('storage-area-list-edit')) {
      this.setItemFocus('storage-area-list-delete-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else if (this.document.activeElement.id.startsWith('storage-area-list-delete')) {
      this.setItemFocus('storage-area-list-item-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else {
      this.setItemFocus('storage-area-list-edit-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    }
  }

  onLeftArrow(storageAreaId: string, storageAreaName: string) {
    if (this.document.activeElement.id.startsWith('storage-area-list-edit')) {
      this.setItemFocus('storage-area-list-item-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else if (this.document.activeElement.id.startsWith('storage-area-list-delete')) {
      this.setItemFocus('storage-area-list-edit-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else {
      this.setItemFocus('storage-area-list-delete-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    }
  }

  setItemFocus(id: string): void {
    this.document.getElementById(id)?.focus();
  }

  async onDeleteStorageArea(storageArea: StorageAreaDTO) {
    return firstValueFrom(
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          returnData: storageArea.storageAreaId,
          title: 'STORAGE_AREA.DELETE_STORAGE_AREA_MODAL.DELETE_STORAGE_AREA_DIALOG_TITLE',
          subtitle: 'STORAGE_AREA.DELETE_STORAGE_AREA_MODAL.DELETE_STORAGE_AREA_DIALOG_SUBTITLE',
          cancelButtonId: 'cancel-delete-storage-area-button',
          submitButtonId: 'delete-storage-area-button',
          submitButtonAriaLabel: 'STORAGE_AREA.DELETE_STORAGE_AREA_BUTTON_ARIA_LABEL',
          submitButtonLabel: 'STORAGE_AREA.DELETE_STORAGE_AREA_BUTTON',
          titleData: {
            storageAreaName: storageArea.name
          }
        },
        width: '604px',
        height: '280px',
        disableClose: true
      })
        .afterClosed()
        .pipe(
          concatMap(
            storageAreaId => iif(
              () => storageAreaId,
              this.worksheetHttp
                .createDeleteStorageAreaRequest(
                  this.worksheetModel.header.worksheetId,
                  storageAreaId
                ).pipe(
                  tap(() => {
                    if (storageAreaId) {
                      this.worksheet$.pipe(first()).subscribe(ws => {
                        this.store.dispatch(new DeleteStorageAreasSuccessV2({worksheetId : ws?.id, storageAreaId : storageArea.storageAreaId}));
                      });
                    }
                    WorksheetEffects.storageAreaSchemaChange$.next();
                  })
                ),
              of(true)
            ))
        ));
  }

  isUserManageableStorageArea(storageAreaIndex) {
    return storageAreaIndex > 0;
  }
}
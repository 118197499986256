import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { InventoryComponent } from './inventory.component';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { InventoryConstants } from '@gfs/constants';
import { InventoryIconService } from '@gfs/shared-services';
import { PipeSharingModule } from '@gfs/shared-components';
import { NgxPermissionsModule } from 'ngx-permissions';

const { icons } = InventoryConstants;

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        MatDividerModule,
        MatButtonModule,
        MatCardModule,
        RouterModule,
        MatIconModule,
        MatTableModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatGridListModule,
        MatMenuModule,
        TranslateModule.forChild(),
        PipeSharingModule,
        MatTooltipModule,
        NgxPermissionsModule
    ],
    declarations: [
        InventoryComponent
    ],
    exports: [InventoryComponent]
})

export class InventoryModule {
  readonly storageAreaIcons = Object.keys(icons).map(icon => icons[icon]);

  constructor(public inventoryIconService: InventoryIconService) {
    inventoryIconService.registerIcons(this.storageAreaIcons);
  }
}

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Constants } from '@gfs/constants';
import { CountableItem, IAppContext, RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig, Worksheet } from '@gfs/shared-models';
import { IAddItemsModalAppSettingsProviderService, InjectionTokens, WINDOW, localizeCatalogueToUSEnglishSpelling } from '@gfs/shared-services';
import {
  ClearCountableItems,
  GetAutocompleteResultsAttempt,
  GetCountableItemsAttempt,
  GetGeneralItemByCustomItemIdAttempt,
  IFeatureStateFacade,
  SearchTabTypes
} from '@gfs/store/feature/add-items';
import { AddCountableItemsToWorksheet } from '@gfs/store/inventory/actions/inventoryItems.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, takeWhile, tap } from 'rxjs/operators';

export interface AddItemDialogData {
  worksheet: Worksheet;
  isManage?: boolean;
  selectRecipeIngredientMode?: boolean;
  ingredentOutput$?: Subject<{ itemId: string; itemType: string }>;
}

export enum AddItemDialogSteps {
  Search = 'SEARCH',
  AddGfsItem = 'ADD_GFS_ITEM',
  CreateCustomItem = 'CREATE_CUSTOM_ITEM',
  AddCustomItem = 'ADD_CUSTOM_ITEM',
}

@Component({
  selector: 'gfs-add-item-dialog',
  templateUrl: './add-item-dialog.component.html',
  styleUrls: ['./add-item-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddItemDialogComponent implements OnInit, OnDestroy {
  @ViewChild('searchString', { static: true }) searchBox: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: true })
  autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('itemsViewport') viewport: CdkVirtualScrollViewport;
  resultList$ = this.store.select((state) => {
    return state.addItemsFeature.autocompleteResults;
  });

  searchingItems$ = this.store.select(
    (state) => state.addItemsFeature.searching
  );
  queryField: UntypedFormControl;
  selectedSearchText: string;
  offset = 0;
  itemListLength = 0;
  displayAmount = 20;
  isMoreToLoad = true;
  hasSearched = false;
  isEmptyResult = false;
  addItemStep: AddItemDialogSteps;
  worksheet: Worksheet;
  selectedItem: CountableItem;
  filterChange$: Subject<SearchTabTypes> = new Subject();
  isManage = false;
  selectRecipeIngredientMode = false;
  unfilteredItems$: Observable<CountableItem[]> = this.store.select(
    (state) => state.addItemsFeature.countableItems
  );

  selectedFilter: SearchTabTypes = SearchTabTypes.Catalogue;
  SearchTabTypes = SearchTabTypes;

  filterKeys = Object.keys(SearchTabTypes);

  filters = {
    [SearchTabTypes.Catalogue]: (item: CountableItem) => item.gfsItem,
    [SearchTabTypes.OrderGuideItems]: (item: CountableItem) => item.gfsItem,
    [SearchTabTypes.NonGfsItems]: (item: CountableItem) => item.customItem,
    [SearchTabTypes.RecipeItems]: (item: CountableItem) => item.recipeItem
  };

  itemList$: Observable<CountableItem[]> = this.unfilteredItems$
    .pipe(
      filter(items => !!items),
      map(items => items.filter(item => this.applyFilterFunc(item)))
    );

  localizeCatalogueSpelling$: Observable<string>
    = localizeCatalogueToUSEnglishSpelling(this.appContext, this.translate, 'ADD_ITEMS.CATALOGUE');


  nextIngredient$ = new Subject<{ itemId: string; itemType: string }>();
  subscriptions: Subscription[] = [];
  constructor(
    private translate: TranslateService,
    private store: Store<IFeatureStateFacade>,
    public dialogRef: MatDialogRef<AddItemDialogComponent>,
    @Inject(InjectionTokens.IAddItemsModalAppSettingsProviderService) public configService: IAddItemsModalAppSettingsProviderService,
    @Inject(MAT_DIALOG_DATA) public data: AddItemDialogData,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    @Inject(WINDOW) public window: Window,
  ) {
    this.selectRecipeIngredientMode = data.selectRecipeIngredientMode ?? false;
    this.nextIngredient$ = data.ingredentOutput$;
    this.addItemStep = AddItemDialogSteps.Search;
    this.filterChange$.next(SearchTabTypes.Catalogue);
  }

  ngOnInit() {
    this.worksheet = this.data.worksheet;
    if (this.data.isManage) {
      this.isManage = true;
      this.addItemStep = AddItemDialogSteps.CreateCustomItem;
    }
    this.queryField = new UntypedFormControl();
    this.queryField.valueChanges.pipe(debounceTime(500)).subscribe((queryField) => {
      if (queryField) {
        this.store.dispatch(
          new GetAutocompleteResultsAttempt({
            locale: this.translate.currentLang,
            searchPrefix: queryField,
          })
        );
      }
    });
    const addCountableItemsSub = this.itemList$
      .pipe(
        filter((x) => x != null),
        tap((items) => {
          this.isEmptyResult = items.length === 0;
        }),
        filter((items) => items.length !== this.itemListLength)
      )
      .subscribe((items) => {
        this.isMoreToLoad = items.length >= this.displayAmount;
        this.itemListLength = items.length;
        this.store.dispatch(new AddCountableItemsToWorksheet(items));
      });
    this.subscriptions.push(addCountableItemsSub);
  }

  applyFilterFunc = (item: CountableItem): boolean => {
    return this.filters[this.selectedFilter.toString()]
      ? !!this.filters[this.selectedFilter.toString()](item)
      : true;
  };

  searchForValue(value: string) {
    if (value.trim()) {
      this.isEmptyResult = false;
      this.selectedSearchText = value;
      this.offset = 0;
      this.displayAmount = 20;
      this.store.dispatch(
        new GetCountableItemsAttempt({
          locale: this.translate.currentLang,
          searchText: value,
          offset: this.offset,
          limit: 40,
          // new date to allow support in recipe. will probably be reworked as we go
          inventoryDate:
            this.worksheet?.inventoryDate ?? new Date().toISOString(),
          isNewSearchTerm: true,
          searchType: this.selectedFilter,
        })
      );
      this.offset = 40;
      if (this.viewport) {
        this.viewport.scrollToIndex(0);
      }
      this.hasSearched = true;
      this.autocompleteTrigger.closePanel();
    }
  }

  loadMore() {
    this.displayAmount += 20;
    this.isMoreToLoad = this.itemListLength >= this.displayAmount;
    if (this.isMoreToLoad) {
      this.store.dispatch(
        new GetCountableItemsAttempt({
          locale: this.translate.currentLang,
          searchText: this.selectedSearchText,
          offset: this.offset,
          limit: 20,
          inventoryDate: this.worksheet.inventoryDate,
          isNewSearchTerm: false,
          searchType: this.selectedFilter,
        })
      );
    }
    this.offset += 20;
  }

  onItemSelected(item: CountableItem) {
    this.selectedItem = item;
    this.nextIngredient$?.next(
      CountableItem.ingredientData(
        this.selectedItem,
        this.translate.currentLang
      )
    );
    if (!this.selectRecipeIngredientMode) {
      this.addItemStep = AddItemDialogSteps.AddGfsItem;
    }
  }

  getRecipeIngredientInfo(
    item: CountableItem
  ): { itemId: string; itemType: string } {
    const itemData = [
      {
        itemType: 'GFS',
        itemId: item.gfsItem?.id,
        itemDescription: item.gfsItem?.description.find(
          (x) => x.languageCode === this.translate.currentLang
        ).value,
      },
      {
        itemType: 'RECIPE',
        itemId: item.recipeItem?.id,
        itemDescription: 'Recipe Item',
      },
      {
        itemType: 'CUSTOM',
        itemId: item.customItem?.id,
        itemDescription: item.customItem?.description,
      },
      {
        itemType: 'GENERAL',
        itemId: item.generalItem?.id,
        itemDescription: item.generalItem?.description,
      },
      { itemId: '', itemType: '', itemDescription: '' },
    ];
    const result = itemData.filter((x) => x.itemId != null);
    return result[0];
  }

  onAddNonGfsItem(item?: CountableItem) {
    if (item) {
      this.store.dispatch(
        new GetGeneralItemByCustomItemIdAttempt({
          customItemId: item.customItem.id,
        })
      );

      // Wait until the item has been updated with a generalItem, then set the step to AddCustomItem and complete the observable
      this.itemList$
        .pipe(
          filter((x) => x != null),
          map((items) =>
            items.find((i) => i.customItem.id === item.customItem.id)
          ),
          takeWhile((itemToBeSelected) => !itemToBeSelected.generalItem, true),
          filter((itemToBeSelected) => !!itemToBeSelected.generalItem)
        )
        .subscribe((itemToBeSelected) => {
          this.selectedItem = itemToBeSelected;

          if (this.selectRecipeIngredientMode) {
            this.nextIngredient$?.next(
              CountableItem.ingredientData(
                this.selectedItem,
                this.translate.currentLang
              )
            );
            return;
          } else {
            this.addItemStep = AddItemDialogSteps.AddCustomItem;
          }
        });
    } else {
      this.addItemStep = AddItemDialogSteps.CreateCustomItem;
    }
  }

  onAddRecipeItem(item?: CountableItem) {
    console.log('onAddRecipe!' + item?.recipeItem?.id);
    alert('Adding Recipe items not yet implemented.');
  }

  goBack(count: number) {
    this.selectedItem = null;
    this.addItemStep = AddItemDialogSteps.Search;
  }

  closeModal() {
    this.store.dispatch(new ClearCountableItems());
    this.dialogRef.close();
    // WorksheetEffects.itemChangedNotifier$
    // .next({
    //   storageAreaId: this.currentStorageAreaId,
    //   worksheetItemId: this.data.worksheetItemToEdit.id
    // })
  }

  ngOnDestroy() {
    this.closeModal();
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
  // if using the default name, translate it between english and french
  translateName(name: string): string {
    if (
      name.toLowerCase() === 'untitled inventory' ||
      name.toLowerCase() === `inventaire sans titre`
    ) {
      return this.translate.instant('INVENTORY_WORKSHEET.UNTITLED_INVENTORY');
    }
    return name;
  }

  selectFilter(nextFilter: SearchTabTypes) {
    if (nextFilter !== this.selectedFilter) {
      this.selectedFilter = nextFilter;
      this.filterChange$.next(nextFilter);
      const searchValue = this.searchBox.nativeElement.value;
      if (searchValue) {
        this.searchForValue(searchValue);
      }
    }
  }

  showNoSearchPerformed(): boolean {
    return this._showNoSearchPerformed(this.hasSearched, this.queryField.value);
  }

  private _showNoSearchPerformed(hasSearched: boolean, searchText: string): boolean {
    return !hasSearched && !searchText;
  }

  showNoSearchResultsReturned(): boolean {
    return this._showNoSearchResultsReturned(
      this.isEmptyResult,
      this.hasSearched,
      this.queryField.value,
      this.selectedSearchText
    );
  }

  private _showNoSearchResultsReturned(
    isEmptyResult: boolean,
    hasSearched: boolean,
    searchText: string,
    selectedSearchText: string,
  ): boolean {
    return [
      isEmptyResult,
      searchText === selectedSearchText,
      hasSearched
    ].every(conditionIsTrue => conditionIsTrue);
  }

  showResults(itemList$: Observable<CountableItem[]>): Observable<boolean> {
    return itemList$.pipe(
      map(items => items?.length > 0)
    );
  }

  shouldHideNonGfsItemsTab(): boolean {
    const allowNonGfsItemsSearch = Constants.FeatureFlags.AllowNonGfsItemsSearch;
    return this.shouldHideTab(
      this.recipeProfitCalculatorConfig,
      this.configService,
      this.window,
      allowNonGfsItemsSearch);
  }

  shouldHideRecipeItemsTab(): boolean {
    const allowRecipeItemsSearch = Constants.FeatureFlags.AllowRecipeItemsSearch;
    return this.shouldHideTab(
      this.recipeProfitCalculatorConfig,
      this.configService,
      this.window,
      allowRecipeItemsSearch);
  }

  shouldHideTab(
    recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    configService: IAddItemsModalAppSettingsProviderService,
    window: Window,
    featureFlag: string,
  ): boolean {
    const isUserInCalculator = recipeProfitCalculatorConfig.isNavigatingFromCalculator(window);
    if (isUserInCalculator) {
      return true;
    }

    const displayNonGfsItems = configService.getSettings()[featureFlag];
    return !displayNonGfsItems;
  }
}
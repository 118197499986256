import { CustomerPK, IDictionary } from '../lib';

export interface WorksheetDTO {
    worksheetItemErrors: IDictionary<string>;
    worksheetId: string,

    header: WorksheetHeaderDTO,

    units: IDictionary<IDictionary<string>>,
    storageAreas: IDictionary<StorageAreaDTO>,
    worksheetItems: IDictionary<WorksheetItemDTO>,
    worksheetValuations: IDictionary<number[]>,
    inventoryValuationModel: IDictionary<IDictionary<PriceableUnit>>,


    inventoryItems: IDictionary<InventoryItemDisplayDTO>,

    storageAreaOrder: string[],
    storageAreaItemOrder: IDictionary<string[]>,
    serverMessage: string[],
}



export class PriceableUnit {
    price: number;
    unitId: string
    unitMultiplier: number
    warning: string

    getPrice(
        qty: number
    ): number {
        return qty * (this.price * this.unitMultiplier)
    }
}

export function getUnitPrice(
    unit: PriceableUnit,
    qty: number
): number {
    return qty * (unit.price * unit.unitMultiplier)
}
export interface WorksheetHeaderDTO {
    worksheetId: string,

    name: string,
    status: string,
    totalInventoryValue?: number,
    valuationTimestamp: Date,

    customerPK: CustomerPK,
    createdDate: Date,
    lastModifiedDate: Date,
    closedDate?: Date,
}

export interface StorageAreaDTO {
    storageAreaId: string,
    totalInventoryValue: number,
    name: IDictionary<string>,
}

export interface WorksheetItemDTO {

    worksheetItemId: string,
    worksheetId: string,
    storageAreaId: string,
    itemId: string,

    itemCounts: ItemCountEntryDTO[],
    configuredCountingUnits: string[],

}

export interface ItemCountEntryDTO {
    updated: boolean;
    ordinal: number,
    unit: string,
    qty: number,
}
export interface TypedMeasurementUnitOption {
    key: string,
    type: string,
}

export interface InventoryItemDisplayDTO {
    itemId: string,
    descriptionLine1: Map<string, string>,
    descriptionLine2: Map<string, string>,
    referenceImage: Map<string, string>,
}

export interface CloneWorksheetItemResponse {
    ordinal: number,
    worksheetItem: WorksheetItemDTO,
}
export interface DeleteWorksheetItemResponse {
    worksheetItemId: string,
}

export interface TypedLocalizedMeasurementUnitDTO {
    key: string,
    type: string,
    localization: IDictionary<string>,
}

export interface UpdateWorksheetItemCountEntryRequest {
    worksheetId: string,
    storageAreaId: string,
    worksheetItemId: string,
    counts: UpdateWorksheetItemCountEntryRequest2[]
}

export interface UpdateWorksheetItemCountEntryRequest2 {
    ordinal: number,
    unit: string,
    type: string,
    qty?: number,
}








export interface ProductCountElement {
    worksheetItemId: string,
    itemCounts: Array<TierCount>,
    sequence: number
}

export interface TierCount {
    ordinal: number,
    unit: string,
    qty: number,
}

export interface BatchProductCountRequest {
    worksheetId: string,
    sequence: number
    batch: Array<ProductCountElement>,
}

export enum WorksheetValuationStatusFlags {
    Complete = 0,
    Pending = 1,
    Error = 2,
}

export interface BatchProductCountResponse {
    worksheetId: string,
    sequence: number,
    valuationTimestamp: Date,
    worksheetValuations: IDictionary<number>,
    errors: IDictionary<string>,
}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    CountableItem,
    WorksheetItem
} from '@gfs/shared-models';
import { GetProductInfoAttempt, IFeatureStateFacade } from '@gfs/store/feature/add-items';
import { Store } from '@ngrx/store';

@Component({
  selector: 'gfs-duplicate-item-dialog',
  templateUrl: './duplicate-item-dialog.component.html',
  styleUrls: ['./duplicate-item-dialog.component.scss']
})
export class DuplicateItemDialogComponent implements OnInit {

  item: CountableItem;
  currentStorageAreaId: string;
  isGfsItem = false;
  isManage = false;
  focusSection = null;

  constructor(
    private store: Store<IFeatureStateFacade>,
    public dialogRef: MatDialogRef<DuplicateItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DuplicateItemDialogComponent,
  ) { }

  ngOnInit() {
    this.item = this.data.item;
    this.currentStorageAreaId = this.data.currentStorageAreaId;
    this.isGfsItem = !!this.item.gfsItem;
    this.focusSection = this.data.focusSection;
    if (this.data.isManage) {
      this.isManage = this.data.isManage;
    }
    if (this.isGfsItem) {
      this.store.dispatch(new GetProductInfoAttempt(this.item.gfsItem.id));
    }
  }

  close() {
    this.dialogRef.close();
  }

}

import {
  CountableItem, GeneralItem, ItemCategory, Supplier, LocalizedValue
} from '@gfs/shared-models';
import { arrayToEntityDictionaryWithTransformation } from '@gfs/shared-services';
import * as fromAddItems from './add-items.actions';
import { FeatureState, initialState } from './add-items.state';


export function addItemsReducer(
  state: FeatureState = initialState,
  action: fromAddItems.ActionUnion
): FeatureState {
  switch (action.type) {
    case fromAddItems.ActionTypes.GetAutocompleteResultsSuccess: {
      return {
        ...state,
        autocompleteResults: createSuggestionsMap(
          action.payload.autocompleteSuggestions.slice(0, 10),
          action.payload.searchLength
        ),
      };
    }
    case fromAddItems.ActionTypes.GetCountableItemsAttempt: {
      return {
        ...state,
        searching: action.payload.isNewSearchTerm,
      };
    }
    case fromAddItems.ActionTypes.GetGeneralItemByCustomItemIdSuccess: {
      return {
        ...state,
        countableItems: mapGeneralItemToCountableItems(
          state.countableItems,
          action.payload.generalItem,
          action.payload.customItemId
        ),
      };
    }
    case fromAddItems.ActionTypes.CreateGeneralItemFromCustomItemSuccess: {
      return {
        ...state,
        customItem: {
          ...state.customItem,
          generalItem: action.payload.generalItem
        }
      };
    }
    case fromAddItems.ActionTypes.GetCountableItemsSuccess: {
      const countableItems = createItemList(
        state.countableItems,
        action.payload.items,
        action.payload.isNewSearchTerm
      );
      return {
        ...state,
        countableItems,
        lastCountableItemsSearch: {
          searchText: action.payload.searchText,
          searchType: action.payload.searchType,
          locale: action.payload.locale,
          countableItems,
        },
        searching: false,
      };
    }
    case fromAddItems.ActionTypes.ClearCountableItems: {
      return {
        ...state,
        countableItems: [],
      };
    }
    case fromAddItems.ActionTypes.GetItemCategoriesBeginLoading: {
      return {
        ...state,
        itemCategories_isLoading: true,
        itemCategories: null
      };
    }
    case fromAddItems.ActionTypes.GetItemCategoriesSuccess: {
      return {
        ...state,
        itemCategories_isLoading: false,
        itemCategories_untranslated: action.payload.categories,
        itemCategories: createMappedCategories(
          action.payload.categories,
          action.payload.language
        ),
      };
    }
    case fromAddItems.ActionTypes.GetSuppliersBeginLoading: {
      return {
        ...state,
        suppliers_loading: true,
        suppliers: null,
      };
    }
    case fromAddItems.ActionTypes.GetSuppliersSuccess: {
      return {
        ...state,
        suppliers_loading: false,
        suppliers: action.payload.suppliers,
      };
    }
    case fromAddItems.ActionTypes.CreateSupplierSuccess: {
      return {
        ...state,
        suppliers: [...state.suppliers, action.supplier],
      };
    }
    case fromAddItems.ActionTypes.ClearSupplierData: {
      return {
        ...state,
        suppliers: null,
      };
    }
    case fromAddItems.ActionTypes.SaveCustomItemStart: {
      return {
        ...state,
        isAddingCustomItem: true,
      };
    }
    case fromAddItems.ActionTypes.SaveCustomItemFinish: {
      return {
        ...state,
        isAddingCustomItem: false,
        customItem: {
          customItem: action.payload
        } as CountableItem
      };
    }
    case fromAddItems.ActionTypes.ClearCustomItem: {
      return {
        ...state,
        customItem: null,
      };
    }
    case fromAddItems.ActionTypes.GetProductInfoError:
      return {
        ...state,
        error: action.payload,
      };
    case fromAddItems.ActionTypes.GetMeasurementUnitsBeginLoading: {
      return {
        ...state,
        measurementUnits_isLoading: true,
        measurementUnits: null
      };
    }
    case fromAddItems.ActionTypes.GetCustomItemDataBeginLoading: {
      return {
        ...state,
        customItemData: null,
        customItemData_isLoading: true,
      };
    }
    case fromAddItems.ActionTypes.GetMeasurementUnitsSuccess: {
      return {
        ...state,
        measurementUnits_isLoading: false,
        measurementUnits: action.units,

      };
    }
    case fromAddItems.ActionTypes.GetCustomItemDataSuccess: {
      return {
        ...state,
        customItemData: action.customItemData,
        customItemData_isLoading: false,
      };
    }
    case fromAddItems.ActionTypes.ClearCustomItemData: {
      return {
        ...state,
        customItemData: null,
      };
    }
    case fromAddItems.ActionTypes.ClearEditItemProductInfo: {
      return {
        ...state,
        editItemProductInfo: null,
      };
    }
    case fromAddItems.ActionTypes.CreateCategoryAttempt: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case fromAddItems.ActionTypes.CreateCategorySuccess: {
      const newCategory = createMappedCategories(
        [action.payload.category],
        action.payload.language
      );
      return {
        ...state,
        isSaving: false,
        itemCategories: [...(state.itemCategories ?? []), ...newCategory],
        itemCategories_untranslated: [...state.itemCategories_untranslated, action.payload.category],
      };
    }
    case fromAddItems.ActionTypes.GetSupplierOrderSuccess: {

      return {
        ...state,
        supplierOrder: action.payload.supplierOrder
      };
    }
    case fromAddItems.ActionTypes.CreateSupplierOrderSuccess: {
      const supplierOrder = action.payload.supplierOrder;
      const suppliers = action.payload.suppliers.map(supplier => ({ ...supplier, ordinal: supplierOrder.order.indexOf(supplier.id) }));

      return {
        ...state,
        suppliers,
        supplierOrder
      };
    }
    case fromAddItems.ActionTypes.PatchSupplierSuccess: {
      const patchedSupplier: Supplier = action.payload.supplier;
      const suppliers = action.payload.suppliers;
      const updateIndex = suppliers.findIndex(item => item.id === patchedSupplier.id);
      suppliers[updateIndex] = { ...suppliers[updateIndex], ...patchedSupplier };

      return {
        ...state,
        suppliers
      };
    }
    case fromAddItems.ActionTypes.DeleteSupplierSuccess: {
      const suppliers = action.payload.suppliers
        .filter((supplier: Supplier) => supplier.id !== action.payload.supplier.id);
      return {
        ...state,
        suppliers
      };
    }
    case fromAddItems.ActionTypes.GetAutocompleteResultsError:
    case fromAddItems.ActionTypes.GetCountableItemsError:
    case fromAddItems.ActionTypes.CreateGeneralItemFromCustomItemError:
    case fromAddItems.ActionTypes.GetItemCategoriesError:
    case fromAddItems.ActionTypes.GetMeasurementUnitsError:
    case fromAddItems.ActionTypes.GetCustomItemDataError:
    case fromAddItems.ActionTypes.CreateCustomItemDataError:
    case fromAddItems.ActionTypes.UpdateCustomItemDataError:
    case fromAddItems.ActionTypes.CreateCategoryError:
    case fromAddItems.ActionTypes.GetSuppliersError:
    case fromAddItems.ActionTypes.GetGeneralItemByCustomItemIdError:
    case fromAddItems.ActionTypes.CreateSupplierError:
    case fromAddItems.ActionTypes.CreateSupplierError:
    case fromAddItems.ActionTypes.DeleteSupplierError:
    case fromAddItems.ActionTypes.PatchSupplierError:
    case fromAddItems.ActionTypes.GetSupplierOrderError:
    case fromAddItems.ActionTypes.CreateSupplierOrderError:
    case fromAddItems.ActionTypes.PatchSupplierError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    case fromAddItems.ActionTypes.CreateCustomItemError: {
      return {
        ...state,
        isAddingCustomItem: false,
        searching: false,
        error: action.error.message,
      };
    }
    case fromAddItems.ActionTypes.GetProductInfoSuccess: {
      return {
        ...state,
        editItemProductInfo: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export function createItemList(
  oldItems: CountableItem[],
  newItems: CountableItem[],
  isNewSearchTerm: boolean
): CountableItem[] {
  if (isNewSearchTerm) {
    return newItems;
  } else if (oldItems !== newItems) {
    return oldItems.concat(newItems);
  }

  return oldItems;
}

export function createSuggestionsMap(termList, searchTermLength) {
  return termList.map((term) => ({ termLength: searchTermLength, text: term }));
}

// map the categories for searching convenience
export function createMappedCategories(
  categories: ItemCategory[],
  language: string
): any[] {
  return categories
    .filter(cat => hasValidCategoryName(cat))
    .map((cat) => {
      const { name: localizations, id } = cat;
      // if name is not an array it is not possible to localize the categories that were input
      if (!isArrayLike(localizations)) {
        return cat;
      }

      const defaultTranslation = { id, name: localizations[0].value };
      const locales = {
        ...arrayToEntityDictionaryWithTransformation<LocalizedValue, { id: string, name: string; }>(
          localizations,
          key => key.languageCode,
          ({ value: name }) => ({ name, id, fuzzyName: localizations.map(x => x.value) }))
      };
      return locales[language] ?? defaultTranslation;
    });

}

const hasValidCategoryName = (cat: ItemCategory): boolean => cat.name.length > 0;
const isArrayLike = (val): boolean => typeof val.findIndex === 'function';

export function mapGeneralItemToCountableItems(
  oldItems: CountableItem[],
  generalItem: GeneralItem,
  customItemId: string
): CountableItem[] {
  return oldItems.reduce<CountableItem[]>((acc, next) => {
    if (next.customItem?.id === customItemId) {
      const newItem: CountableItem = {
        ...next,
        generalItem,
      };
      return [...acc, newItem];
    } else {
      return [...acc, next];
    }
  }, []);
}

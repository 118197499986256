import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Supplier } from '@gfs/shared-models';
import { BehaviorSubject } from 'rxjs';
import { WhitespaceOnlyValidator } from '@gfs/shared-services';
import { GfsValidators } from '@gfs/shared-services/validation';

export interface EditSupplierDialogData {
  entity: Supplier;
  forbiddenNames: string[];
}

interface EditSupplierDialogViewModel {
  submitButtonText: string;
  submitButtonAriaLabel: string;
  modalTitle: string;
  inputPlaceholder: string;
  inputValidation: { [key: string]: string; };
  form: UntypedFormGroup;
}

const addModalConfig = {
  submitButtonText: 'SUPPLIER.CREATE_SUPPLIER_MODAL.CREATE_SUPPLIER_BUTTON',
  submitButtonAriaLabel: 'SUPPLIER.CREATE_SUPPLIER_MODAL.CREATE_SUPPLIER_BUTTON_ARIA_LABEL',
  modalTitle: 'SUPPLIER.CREATE_SUPPLIER_MODAL.CREATE_TITLE',
};

const editModalConfig = {
  submitButtonText: 'SUPPLIER.EDIT_SUPPLIER_BUTTON',
  submitButtonAriaLabel: 'SUPPLIER.EDIT_SUPPLIER_BUTTON_ARIA_LABEL',
  modalTitle: 'SUPPLIER.CREATE_SUPPLIER_MODAL.RENAME_TITLE',
};

@Component({
  selector: 'gfs-edit-supplier-modal',
  templateUrl: './edit-supplier-modal.component.html',
  styleUrls: ['./edit-supplier-modal.component.scss']
})
export class EditSupplierModalComponent {

  form: UntypedFormGroup = this.formBuilder.group({
    supplierName: new UntypedFormControl(
      this.data.entity?.name,
      [
        Validators.required,
        GfsValidators.duplicateName(this.data.forbiddenNames, this.data?.entity?.name),
        WhitespaceOnlyValidator.validate(),
      ]
    )
  });

  private viewModelBase = {
    inputPlaceholder: 'SUPPLIER.CREATE_SUPPLIER_MODAL.SUPPLIER_NAME_PLACEHOLDER',
    inputValidation: {
      required: 'SUPPLIER.CREATE_SUPPLIER_MODAL.SUPPLIER_IS_REQUIRED_ERROR',
      duplicate: 'SUPPLIER.CREATE_SUPPLIER_MODAL.SUPPLIER_ALREADY_EXISTS_ERROR',
      invalidText: 'SUPPLIER.CREATE_SUPPLIER_MODAL.SUPPLIER_IS_REQUIRED_ERROR',
    },
    form: this.form
  };

  viewModelSubject = new BehaviorSubject<EditSupplierDialogViewModel>(
    {...this.viewModelBase, ...(!!this.data.entity ? editModalConfig : addModalConfig)});

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditSupplierModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditSupplierDialogData) { }

  closeModal() {
    this.dialogRef.close();
  }

  submit(supplierName: string) {
    this.data.entity = {...(this.data.entity ?? {} as Supplier), name: supplierName.trim()};
    this.dialogRef.close(this.data.entity);
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerPK } from '@gfs/shared-models';
import { AppConfigService } from '@gfs/shared-services';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap, delay } from 'rxjs/operators';
import { InventoryMigrationService } from '../services/inventory-migration.service';
import { InventoryMigrationConfigurationModalComponent } from '../modal/inventory-migration-configuration-modal.component';
import { MigrationResult, UsMigrationEligibility, UsMigrationOptions, UsMigrationResponse } from '../models/inventory-migration.model';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { InventoryWorksheetWizardComponent } from '@gfs/shared-components';
import { TranslateService } from '@ngx-translate/core';

export const defaultUsMigrationResponse: UsMigrationResponse = {
    migratedCategories: [],
    migratedSuppliers: [],
    migratedCustomItems: [],
    migratedCustomItemDatas: [],
    migratedGeneralItems: [],
    legacyIdToNewIdMap: new Map(),
    migratedStorageAreas: [],
    migratedWorksheet: []
};

export const defaultUsMigrationOptions: UsMigrationOptions = {
    migrateCustomItems: false,
    migrateWorksheet: false
};

@Injectable({ providedIn: 'root' })
export class LegacyCustomerMigrationFacade {

    constructor(
        private inventoryMigrationService: InventoryMigrationService,
        private dialog: MatDialog,
        private configService: AppConfigService,
        private loadingSpinner: LoadingSpinnerOverlayService,
        private translateService: TranslateService
    ) { }

    private shouldMigrateFromInventoryManager(configService: AppConfigService): boolean {
        return configService.getSettings().FF_MIGRATE_FROM_INVENTORY_MANAGER;
    }

    private shouldCommitMigration(options: { migrateWorksheet: boolean, migrateCustomItems: boolean, escapeWorkflow: boolean }): boolean {
        return !options.escapeWorkflow && (options.migrateCustomItems || options.migrateWorksheet);
    }

    migrateCustomerInventoryData(customerPk: CustomerPK, isMobile: boolean): Observable<MigrationResult> {
        const migrateFromInventoryManager = this.shouldMigrateFromInventoryManager(this.configService);
        if (!migrateFromInventoryManager) {
            return of({
                response: defaultUsMigrationResponse,
                options: defaultUsMigrationOptions,
                escapeWorkflow: false
            });
        }

        return this.inventoryMigrationService
            .getUsMigrationEligibility(customerPk)
            .pipe(
                concatMap((eligibility: UsMigrationEligibility) => {
                    const isEligible = eligibility.hasUdi || eligibility.hasWorksheet;
                    if (!isEligible) {
                        return of({
                            response: defaultUsMigrationResponse,
                            options: defaultUsMigrationOptions,
                            escapeWorkflow: false
                        });
                    }

                    return this.dialog
                        .open(InventoryMigrationConfigurationModalComponent, {
                            disableClose: true,
                            data: { usMigrationEligibility: eligibility }
                        })
                        .afterClosed()
                        .pipe(
                            concatMap(({ options, escapeWorkflow }: { options: UsMigrationOptions, escapeWorkflow: boolean }) => {
                                const commitMigration = this.shouldCommitMigration({
                                    escapeWorkflow,
                                    migrateCustomItems: options.migrateCustomItems,
                                    migrateWorksheet: options.migrateWorksheet
                                });
                                if (!commitMigration) {
                                    return of({
                                        response: defaultUsMigrationResponse,
                                        options,
                                        escapeWorkflow
                                    });
                                }

                                return forkJoin([
                                    of(customerPk),
                                    of(options),
                                    of(isMobile)
                                ])
                                    .pipe(
                                        tap(([, , isMobile]) => {
                                            const config = InventoryWorksheetWizardComponent.GetLoadingSpinnerConfig(isMobile, this.translateService);
                                            this.loadingSpinner.show(config);
                                        }),
                                        delay(2500),
                                        concatMap(([pk, opt]) => {
                                            return this.inventoryMigrationService.commitInventoryMigration(pk, opt)
                                                .pipe(map(response => ({ response, options: opt, escapeWorkflow: false })));
                                        }),
                                        tap((_) => {
                                            this.loadingSpinner.hide(InventoryWorksheetWizardComponent.GetLoadingModalId);
                                        }));
                            })
                        );
                }),
                catchError(() => {
                    this.loadingSpinner.hide(InventoryWorksheetWizardComponent.GetLoadingModalId);
                    return of({
                        response: defaultUsMigrationResponse,
                        options: defaultUsMigrationOptions,
                        escapeWorkflow: false
                    });
                })
            );
    }
}

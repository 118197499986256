import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerPK, Customer } from '@gfs/shared-models';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getCustomerData(customerPk: CustomerPK): Observable<Customer> {
    return this.http.get<Customer>(`${this.baseUrl}/customers`, { params: { ...customerPk } });
  }
}

<mat-list
  id="storage-area-list"
  cdkDropList
  [cdkDropListDisabled]="!(isOnline$ | async)"
  [cdkDropListData]="worksheetModel.storageAreaOrder"
  class="item-drag"
  (cdkDropListDropped)="handleStorageAreaCDKDrop($event)"
>
  <ng-container *ngFor="let currentStorageAreaId of worksheetModel.storageAreaOrder; let storageAreaIndex = index;">
    <ng-container *ngIf="worksheetModel.storageAreas[currentStorageAreaId] as storageArea">
      <ng-container *ngIf="worksheetModel.storageAreaItemOrder[currentStorageAreaId] as storageAreaItems">
        <mat-list-item
          id="storage-area-list-item-{{currentStorageAreaId}}"
          class="storage-area-bkg"
          (keydown.ArrowRight)="onRightArrow(currentStorageAreaId, storageArea.name)"
          (keydown.ArrowLeft)="onLeftArrow(currentStorageAreaId, storageArea.name)"
          (keyup.ENTER)="storageAreaClicked(storageArea)"
          (click)="storageAreaClicked(storageArea)"
          tabindex="0"
          cdkDrag
          cdkDragBoundary="storage-area-sidenav"
          [cdkDragData]="currentStorageAreaId"
          style="border-top-width:0"
        >
          <div class="storage-area-item">
            <div
              cdkDragHandle
              class="drag-handle"
              *ngIf="isUserManageableStorageArea(storageAreaIndex) else disabledDragHandle"
            >
              <img
                *ngxPermissionsOnly="permissionRoles.updateStorageAreaOrder"
                class="img-drag-handle"
                src="assets/images/category_draghandle_gray.svg"
                alt="Drag & Drop Button"
                i18n-alt="@@DragAndDropAlt"
              />
            </div>
            <p class="storage-area-name">
              <gfs-localized-text-value
                [value]="storageArea.name"
                [maxLength]="17"
              />
              ({{storageAreaItems.length}})
            </p>
            <div
              id="storage-area-list-edit-container-{{currentStorageAreaId}}"
              class="icon-container storage-area-list-edit-container"
              *ngIf="isUserManageableStorageArea(storageAreaIndex) else DisabledOption"
            >
              <button
                *ngxPermissionsOnly="permissionRoles.updateStorageArea"
                id="storage-area-list-edit-button-{{currentStorageAreaId}}"
                class="icon-hover-bkg"
                [disabled]="!(isOnline$ | async)"
                [routerLink]="[
                  { outlets: { 'modal': [ 'storage-area', currentStorageAreaId, 'edit' ] } }
                ]"
                tabindex="-1"
                attr.aria-label="{{ 'STORAGE_AREA.EDIT_STORAGE_AREA_BUTTON_ARIA_LABEL' | translate }}"
              >
                <mat-icon
                  class="icon storage-area-edit-icon"
                  svgIcon="edit-icon"
                  alt="Edit Button"
                ></mat-icon>
              </button>
            </div>
            <div
              id="storage-area-list-delete-container-{{currentStorageAreaId}}"
              class="icon-container storage-area-list-delete-container"
              *ngIf="isUserManageableStorageArea(storageAreaIndex)  else DisabledOption"
            >
              <button
                *ngxPermissionsOnly="permissionRoles.deleteStorageArea"
                id="storage-area-list-delete-button-{{currentStorageAreaId}}"
                class="icon-hover-bkg"
                [disabled]="!(isOnline$ | async)"
                (click)="onDeleteStorageArea(storageArea)"
                tabindex="-1"
                attr.aria-label="{{ 'STORAGE_AREA.DELETE_STORAGE_AREA_BUTTON_ARIA_LABEL' | translate }}"
              >
                <mat-icon
                  class="icon storage-area-delete-icon"
                  svgIcon="trash-icon"
                  alt="Delete Button"
                  i18n-alt="@@DeleteButtonAlt"
                ></mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>

      </ng-container>
    </ng-container>
  </ng-container>
</mat-list>


<ng-template #disabledDragHandle>
  <div
    cdkDragHandle
    class="drag-handle"
  ></div>
</ng-template>
<ng-template #DisabledOption>
  <div class="icon-container"></div>
</ng-template>
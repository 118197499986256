import { ErrorHandler, Injectable } from '@angular/core';
import LogRocket from 'logrocket';

@Injectable({ providedIn: 'root'})
export class CustomErrorHandlerService implements ErrorHandler {
  handleError(error: any): void {
    LogRocket.captureException(error);
    console.error(error);
  }
}

import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CountableItem, IAppContext, RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';
import { arrayToEntityDictionary, InjectionTokens, localizeCatalogueToUSEnglishSpelling, LocalizedValueUtilsService, StorageAreaUtilsService, WINDOW } from '@gfs/shared-services';
import { firstValueFrom } from '@gfs/shared-services/extensions/rxjs';
import { ClearCountableItems, GetCountableItemsAttempt, GetSuppliersAttempt, GetGeneralItemByCustomItemIdAttempt, IFeatureStateFacade } from '@gfs/store/feature/quick-add-items';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { concatMap, filter, first, map, pairwise, takeUntil } from 'rxjs/operators';
import { AddItemDialogComponent } from '../add-items/add-item-dialog/add-item-dialog.component';

@Component({
  selector: 'gfs-quick-add-items',
  templateUrl: './quick-add-items.component.html',
  styleUrls: ['./quick-add-items.component.scss']
})
export class QuickAddItemsComponent implements OnInit, OnDestroy {

  @ViewChild('quickSearchContainer') quickSearchContainer: ElementRef;
  @ViewChild('quickAddItemsInput') quickAddItemsInput: ElementRef;
  @ViewChild('quickAddItemsResultsContainer') quickAddItemsResultsContainer: ElementRef;
  @Output() addEvent: EventEmitter<{
    itemId: string;
    itemType: string;
    itemDescription: string;
  }> = new EventEmitter();

  searchResult$: Observable<CountableItem[]> = this.store.select((state) => state.quickAddItemsFeature.countableItems);
  isSearching$: Observable<boolean> = this.store.select((state) => state.quickAddItemsFeature.searching);

  suppliers$ = this.store.select(state => state.quickAddItemsFeature.suppliers);

  notifier = new Subject<void>();
  hasSearched = false;

  localizeCatalogueSpelling$: Observable<string>
    = localizeCatalogueToUSEnglishSpelling(this.appContext, this.translate, 'QUICK_ADD.SEARCH_CATALOGUE');

  constructor(
    public store: Store<IFeatureStateFacade>,
    public storageAreaUtils: StorageAreaUtilsService,
    public localizedValueUtils: LocalizedValueUtilsService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private translate: TranslateService,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    @Inject(WINDOW) public window: Window,
  ) { }

  ngOnInit() {
    this.store.dispatch(new GetSuppliersAttempt());
    this.isSearching$.pipe(
      takeUntil(this.notifier),
      pairwise()
    ).subscribe(([previous, current]) => {
      if (previous && !current) {
        this.hasSearched = true;
      }
    });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  getLocalizedImageUrl(item: CountableItem, width: number, height: number): Observable<string> {
    return this.localizedValueUtils.getLocalizedImageUrl(item, of(this.translateService.currentLang), width, height);
  }

  getLocalizedProductDescription(item: CountableItem): Observable<string> {
    return this.localizedValueUtils.getLocalizedProductDescription(item, of(this.translateService.currentLang));
  }

  getLocalizedBrand(item: CountableItem): Observable<string> {
    return firstValueFrom(this.suppliers$)
      .pipe(
        concatMap(
          suppliers =>
            this.localizedValueUtils.getLocalizedBrand(item, suppliers, of(this.translateService.currentLang))
        )
      );
  }

  getItemId(item: CountableItem): string {
    if (item.gfsItem?.id) {
      return item.gfsItem.id;
    } else if (item.recipeItem?.id) {
      return item.recipeItem.id;
    } else if (item.customItem?.id) {
      return item.customItem.id;
    } else if (item.generalItem?.id) {
      return item.generalItem.id;
    }
    return '';
  }

  getItemInnerPackSize(item: CountableItem) {
    return item.gfsItem?.innerPackSize ?? '';
  }

  getItemQtyPerUnit(item: CountableItem) {
    return item.gfsItem?.qtyPerMasterSellUnit ?? '';
  }

  getItemCasePrice(item: CountableItem): number {
    if (item.gfsItem) {
      return item.gfsItem?.price?.casePrice ?? 0;
    } else if (item.customItem) {
      if (item.customItem?.purchaseUnit?.custom?.catchWeight) {
        return Number.parseFloat(item.customItem?.purchaseUnit?.custom?.price)
          * Number.parseFloat(item.customItem?.purchaseUnit?.custom?.netWeight) ?? 0;
      } else {
        return Number.parseFloat(item.customItem?.purchaseUnit?.custom?.price) ?? 0;
      }
    }
  }

  getItemUnitPrice(item: CountableItem): number {
    if (item.gfsItem) {
      return item.gfsItem?.price?.unitPrice ?? 0;
    } else if (item.customItem) {
      return 0;
    }
  }

  onSearch(event: any, searchValue: string) {
    event.preventDefault();
    if (searchValue.trim().length > 0) {
      this.hasSearched = false;
      this.store.dispatch(new GetCountableItemsAttempt({
        searchText: searchValue,
        locale: this.translateService.currentLang,
        offset: 0,
        limit: 5,
      }));
    }
  }

  closeDropdownIfBlurred(event: FocusEvent) {
    if (!this.quickSearchContainer.nativeElement.contains(event.relatedTarget)
      && !this.quickAddItemsResultsContainer.nativeElement.contains(event.relatedTarget)) {
      this.hasSearched = false;
      this.store.dispatch(new ClearCountableItems());
    }
  }

  onAddClicked(item: CountableItem) {
    const itemData = CountableItem.ingredientData(item, this.translateService.currentLang);
    if (itemData.itemType === 'CUSTOM') {
      const customItemWithGeneralItem$ = this.store.select(state => state.quickAddItemsFeature.countableItems)
        .pipe(
          map(items => items.find(i =>
            i?.customItem?.id === itemData.itemId)
          ),
          filter(augmentedItem => !!augmentedItem?.generalItem)
        );

      customItemWithGeneralItem$.pipe(first()).subscribe(augmentedItem => {
        this.addEvent.emit(CountableItem.ingredientData(augmentedItem, this.translateService.currentLang));
        this.hasSearched = false;
        this.store.dispatch(new ClearCountableItems());
      });

      this.store.dispatch(new GetGeneralItemByCustomItemIdAttempt({ customItemId: itemData.itemId }));
    } else {
      this.addEvent.emit(CountableItem.ingredientData(item, this.translateService.currentLang));
      this.hasSearched = false;
      this.store.dispatch(new ClearCountableItems());
    }
    // clear input text on quick add
    this.quickAddItemsInput.nativeElement.value = '';
  }

  onSearchCatalogueClicked() {
    this.hasSearched = false;
    this.store.dispatch(new ClearCountableItems());
    const nextIngredient$ = new Subject<{
      itemId: string;
      itemType: string;
      itemDescription: string;
    }>();

    nextIngredient$.subscribe(val => {
      this.addEvent.emit(val);
    });

    this.dialog.open(AddItemDialogComponent, {
      data: {
        selectRecipeIngredientMode: true,
        ingredentOutput$: nextIngredient$,
      },
      panelClass: 'inventory-add-item-dialog',
    });
  }

  createTextUnitDetails(countableItem: CountableItem, lang: string): Observable<string> {
    return this.searchResult$
      .pipe(
        first(),
        map(results =>
          arrayToEntityDictionary(results, (e) => this.storageAreaUtils.getItemIdCore(e))
        ),
        concatMap(inventoryItems =>
          this.storageAreaUtils.getGFSDefaultUnitDetails(inventoryItems, countableItem, lang, ', ')
        )
      );
  }

  getSearchPrompt(): string {
    const prompt = this.recipeProfitCalculatorConfig.isNavigatingFromCalculator(this.window)
      ? 'QUICK_ADD.SEARCH_PROMPT_RECIPE_PROFIT_CALCULATOR'
      : 'QUICK_ADD.SEARCH_PROMPT';
    return this.translate.instant(prompt);
  }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { ResetConfirmDialogComponent } from '../../../reset-confirm-dialog/reset-confirm-dialog.component';
import { InventoryUtilsService } from '@gfs/shared-services';
import { InventoryWorksheetWizardComponent } from '../../../inventory-worksheet-wizard/inventory-worksheet-wizard.component';
import { LegacyCustomerMigrationFacade } from '@gfs/shared-components';

interface Option {
  id: string;
  url: string;
  display: string;
  action?: any;
}

@Component({
  selector: 'gfs-inventory-tools',
  templateUrl: './inventory-tools.component.html',
  styleUrls: ['./inventory-tools.component.scss']
})
export class InventoryToolsComponent {

  isMobile$ = this.store.select(state => state.layout.isMobile);
  isOnline$ = this.store.select(state => state.network.isOnline);
  selectedOption = '';
  allOptions: Option[] = [{
    id: 'reset-worksheet-menu-button',
    url: InventoryConstants.INVENTORY_PATH,
    display: 'INVENTORY.RESET_WORKSHEET',
    action: () => this.onResetWorksheetClick()
  }];

  get options(): Option[] {
    return this.allOptions.filter(opt => opt.url === this.router.url);
  }

  constructor(
    private store: Store<AppState>,
    public translate: TranslateService,
    private dialog: MatDialog,
    private inventoryUtilsService: InventoryUtilsService,
    private router: Router,
    private migrationFacade: LegacyCustomerMigrationFacade
  ) { }

  /**
   * translate an option display label
   *
   * @returns a translation
   */
  getOptionLabel() {
    return (option: Option) => this.translate.instant(option.display);
  }

  /**
   * confirm with user to reset worksheet, then open inventory worksheet wizard
   */
  onResetWorksheetClick(): void {
    const resetRef = this.dialog.open(ResetConfirmDialogComponent, {
      panelClass: 'reset-worksheet-modal'
    });

    const dialogSize$ = this.isMobile$.subscribe(isMobile => {
      if (isMobile) {
        resetRef.updateSize('90%', '80%');
      } else {
        resetRef.updateSize('610px', 'auto');
      }
    });

    resetRef.afterClosed()
      .subscribe(didConfirm => {
        dialogSize$.unsubscribe();
        if (didConfirm) {
          InventoryWorksheetWizardComponent.openInventoryWorksheetWizard(
            true,
            this.store,
            this.dialog,
            this.migrationFacade,
            this.router);
        }
      });
  }
}

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { InventoryIcon } from '@gfs/shared-models';

@Injectable({ providedIn: 'root' })
export class InventoryIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  registerIcons(icons: InventoryIcon[]) {
    icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        // SVGs must be resource URLs. Resource URLs must always be explicitly trusted, even if the inputs are safe.
        // See Angular's DomSanitizerImpl.sanitize().
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.location)
      );
    });
  }
}

import {
  Component,
  Input,
  HostBinding,
  ViewChild,
  Output,
  EventEmitter,
  Optional,
  Self,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { noop, Subject } from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { isEmpty, trim } from 'lodash-es';
import { escapeRegex as escapeRegexImport } from '@gfs/shared-services';

@Component({
  selector: 'gfs-add-element-autocomplete',
  templateUrl: './add-element-autocomplete.component.html',
  styleUrls: ['./add-element-autocomplete.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AddElementAutocompleteComponent,
    },
  ],
})
export class AddElementAutocompleteComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
  static nextId = 0;

  @Output() optionSelected = new EventEmitter();
  @Input() fieldName: string;
  @Input() addElementLabel: string;
  @Input() options: Array<any>;
  @Input() maxlength = 200;
  @Input() optionsValue;
  @Input() autocompleteClass;
  @Input() inputClass: string;
  @Input() isNullable = true;
  @Input() canCreateNew = true;
  @Input() valueParser: (currentValue: string) => string;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    // eslint-disable-next-line
    this._disabled = disabled;
    this.stateChanges.next();
  }
  // eslint-disable-next-line
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _disabled = false;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  // eslint-disable-next-line
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    // eslint-disable-next-line
    // eslint-disable-next-line
    this._required = (required as any) !== 'true';
    this.stateChanges.next();
  }
  // eslint-disable-next-line
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _required = false;

  @Input()
  get value(): string {
    return this.elementSelected;
  }
  set value(value: string) {
    this.elementSelected = value;
    if (this.onChange) {
      this.onChange(value);
    }
    this.stateChanges.next();
  }

  @Output() addElement: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MatAutocompleteTrigger, { static: true })
  autocompleteInput: MatAutocompleteTrigger;
  @ViewChild(MatAutocomplete, { static: true }) autocomplete: MatAutocomplete;

  stateChanges = new Subject<void>();
  controlType = 'add-element-autocomplete-input';
  focused = false;
  get empty() {
    return !this.lastValue;
  }
  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  lastValue = '';
  elementSelected = '';

  @HostBinding()
  id = `add-element-autocomplete-input-${AddElementAutocompleteComponent.nextId++}`;
  @HostBinding('class.add-element-autocomplete-floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  escapeRegex = escapeRegexImport;

  @HostBinding('attr.aria-describedby') describedBy;
  setDescribedByIds(ids: string[]) {
    if (ids.length) {
      this.describedBy = ids.join(' ');
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
    this.fm.monitor(this.elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(value: string): void {
    this.value = value;
    this.lastValue = value;
    this.autocompleteInput.writeValue(value);
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onContainerClick(event: MouseEvent) {
    if (!this.disabled) {
      this.onTouched();
    }
  }

  onKeyUp(event) {
    if (!this.disabled) {
      this.lastValue = event.target.value;
      this.onChange(event.target.value);
    }
  }

  onBlur(event) {
    if (event.relatedTarget && event.relatedTarget.nodeName !== 'MAT-OPTION') {
      if (
        (this.isNullable &&
          this.lastValue &&
          this.value &&
          this.lastValue !== this.value) ||
        (!this.isNullable && this.value && this.lastValue !== this.value)
      ) {
        this.lastValue = this.value;
        this.writeValue(this.value);
      } else if (
        (this.isNullable && (!this.value || !this.lastValue)) ||
        (!this.isNullable && !this.value)
      ) {
        if (this.ngControl !== null) {
          this.ngControl.reset();
        }
      }
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    let value = '';
    if (event.option.value === 'add_element_button') {
      if (isEmpty(this.lastValue)) {
        setTimeout(() => this.autocompleteInput.openPanel(), 0);
      } else {
        const option = this.getOption();
        if (!option) {
          value = trim(this.lastValue);
          this.addElement.emit(value);
        } else {
          value = this.getOptionValue(option);
        }
      }
    } else {
      value = trim(event.option.value);
    }
    this.optionSelected.emit(event);
    this.writeValue(value);
  }

  getOptionValue(option) {
    if (this.optionsValue) {
      return option[this.optionsValue];
    }
    return option;
  }

  getOption() {
    const value = trim(this.lastValue).toLowerCase();
    if (this.options) {
      return this.options.find(
        (option) =>
          this.parseValue(this.getOptionValue(option)).toLowerCase() === value
      );
    }
    return null;
  }

  elementExists() {
    return !!this.getOption();
  }

  parseValue(value: string): string {
    if (this.valueParser) {
      return this.valueParser(value);
    }
    return value;
  }

  getOptionText(option: any) {
    const value = this.getOptionValue(option);
    if (!this.lastValue) {
      return value;
    }

    const lastValueEscapedRegex = this.escapeRegex(this.lastValue);
    const regexFlags = [
      'i', // case insensitive
      'g', // global
    ].reduce((acc, curr) => acc + curr, '');
    const regex = new RegExp(lastValueEscapedRegex, regexFlags);
    return value.replace(regex, (val: string) => '<strong>' + val + '</strong>');
  }

  onChange = (value: string) => {
    noop();
  };

  onTouched = () => {
    noop();
  };
}

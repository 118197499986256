import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Worksheet, StorageArea, CountingUnit, ConversionUnit, WorksheetPrintInfo } from '@gfs/shared-models';
import { combineLatest, Observable, of } from 'rxjs';
import { StorageAreaUtilsService } from '@gfs/shared-services';
import { WorksheetPrintService } from '@inventory-ui/services/shared/worksheet-print/worksheet-print.service';
import { AppState } from '@gfs/store/inventory/reducers';
import { concatMap, first, map } from 'rxjs/operators';
import { GetSuppliersAttempt } from '@gfs/store/feature/add-items';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';

export interface PrintWorksheetDialogData {
  worksheetPrintInfo: WorksheetPrintInfo,
}

@Component({
  selector: 'app-print-worksheet-dialog',
  templateUrl: './print-worksheet-dialog.component.html',
  styleUrls: ['./print-worksheet-dialog.component.scss']
})
export class PrintWorksheetDialogComponent implements OnInit, OnDestroy {
  worksheetPrintInfo: WorksheetPrintInfo;
  selectedStorageAreas: StorageArea[] = [];
  allStorageAreas: StorageArea[] = [];
  selectAll = true;
  worksheet$: Observable<Worksheet>;
  allStorageAreas$: Observable<StorageArea[]>;
  assignedStorageAreas$: Observable<StorageArea[]>;
  unAssignedStorageArea$: Observable<StorageArea>;
  isMobile$ = this.store.select(state => state.layout.isMobile);

  customItemData$ = this.store.select((state) => state.worksheets.customItemData);
  customCountingUnits$ = this.customItemData$.pipe(
    map((data) => {
      const itemUnits: CountingUnit[] = [];
      data.forEach((datum) => {
        datum.countingUnits.forEach((unit) => {
          if (unit.custom) {
            itemUnits.push({
              itemId: datum.itemId,
              unit: {
                unitType: 'CUSTOM',
                unitId: unit.custom.id,
              } as ConversionUnit,
              customUnit: unit,
            } as CountingUnit);
          }
        });
      });
      return itemUnits;
    })
  );
  standardCountingUnits$ = this.store.select((state) => state.worksheets.countingUnits);
  countingUnits$ = combineLatest([this.standardCountingUnits$, this.customCountingUnits$])
    .pipe(
      map((response) => ([] as CountingUnit[]).concat(...response))
    );
  suppliers$ = this.store.select(state => state.addItemsFeature.suppliers);

  constructor(
    private store: Store<AppState>,
    private storageAreaUtils: StorageAreaUtilsService,
    public dialogRef: MatDialogRef<PrintWorksheetDialogComponent>,
    public printService: WorksheetPrintService,
    public loadingOverlay: LoadingSpinnerOverlayService,
    @Inject(MAT_DIALOG_DATA) public data: PrintWorksheetDialogData,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new GetSuppliersAttempt());

    this.worksheetPrintInfo = this.data.worksheetPrintInfo;
    this.worksheet$ = of(this.data.worksheetPrintInfo.worksheet);
    this.allStorageAreas$ = this.storageAreaUtils.getStorageAreasMinusCurrentStorageArea(this.worksheet$, '');

    this.assignedStorageAreas$ = this.storageAreaUtils.getStorageAreasMinusUnassigned(this.worksheet$);
    this.unAssignedStorageArea$ = this.storageAreaUtils.getUnassignedStorageArea(this.worksheet$);

    // Default all selected
    this.assignedStorageAreas$.subscribe(sa => this.selectedStorageAreas = sa.slice(0));
    this.unAssignedStorageArea$.subscribe(sa => this.selectedStorageAreas.unshift(sa));

    this.storageAreaUtils.getStorageAreasMinusCurrentStorageArea(this.worksheet$, '').subscribe(sa => this.allStorageAreas = sa);
    this.printService.setUp(
      this.beforePrint.bind(this),
      this.afterPrint.bind(this)
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.closeModal();
  }

  onStorageAreaSelected(event: MatCheckboxChange) {
    const value = event.source.value as any;
    if (event.checked) {
      this.selectedStorageAreas.push(value);
      if (this.selectedStorageAreas.length === this.allStorageAreas.length) {
        this.selectAll = true;
      }
    } else {
      const index = this.selectedStorageAreas.findIndex(cg => value.id === cg.id);
      if (index >= 0) {
        this.selectedStorageAreas.splice(index, 1);
      }
      this.selectAll = false;
    }
  }

  onSelectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.assignedStorageAreas$.subscribe(sa => this.selectedStorageAreas = sa.slice(0));
      this.unAssignedStorageArea$.subscribe(sa => this.selectedStorageAreas.unshift(sa));
      this.selectAll = true;
    } else {
      this.selectedStorageAreas = [];
      this.selectAll = false;
    }
  }

  afterPrint() {
    this.loadingOverlay.hide();
  }

  beforePrint(): Observable<WorksheetPrintInfo> {
    this.loadingOverlay.show();
    this.worksheetPrintInfo.selectedStorageAreas = this.selectedStorageAreas;
    return of(this.worksheetPrintInfo);
  }

  getStorageAreaTranslation(name: string) {
    return this.storageAreaUtils.getTranslation(name);
  }

  printWorksheet(): Promise<boolean> {
    return combineLatest([
      this.countingUnits$,
      this.suppliers$
    ])
      .pipe(
        first(),
        concatMap(([units, suppliers]) =>
          this.printService.print(units, suppliers)
        )
      ).toPromise();
  }

}

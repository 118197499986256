import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddItemDialogComponent } from '@gfs/shared-components';
import { Worksheet } from '@gfs/shared-models';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { promiseMeAlways } from '@inventory-ui/v2/common/preparing-app/preparing-app.component';
import { Observable } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-configure-product',
  template: '',
})
export class SearchProductComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingSpinner: LoadingSpinnerOverlayService,
  ) { }

  createOpenDialogBehavior() {
    return this.activatedRoute.data.pipe(
      first(),
      map(
        ({ 0: worksheet }) => {
          return worksheet as Worksheet;
        }
      ),
      mergeMap(v => this.open(v))
    );
  }

  ngOnInit() {
    this.createOpenDialogBehavior()
      .subscribe();
  }

  open(row: Worksheet): Observable<any> {
    return this.createProductSearchModal(row)
      .afterClosed()
      .pipe(
        tap(() => {
          WorksheetEffects.storageAreaSchemaChange$.next()
          this.hideSpinner()
        }),
        mergeMap(() =>
          promiseMeAlways(this.navigateToWorksheet(this.router, row.id))
        )
      );
  }


  createProductSearchModal(row: Worksheet) {
    return this.dialog
      .open(AddItemDialogComponent,
        {
          data: {
            worksheet: row,
          },
          panelClass: 'inventory-add-item-dialog',
        });

  }

  hideSpinner() {
    return this.loadingSpinner.hide();
  }


  async navigateToWorksheet(router: Router, worksheetId: string) {
    return router.navigateByUrl(`/worksheet/${worksheetId}`, { replaceUrl: true });
  }
}
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationState, AuthenticationService } from '@gfs/shared-services/auth';
import { Observable, of } from 'rxjs';

export class MockAuthenticationService implements
    AuthenticationService {
    reauthorizeWithoutPrompt$(): Observable<void> {
        return of(void 0);
    }
    loginWithRedirect$(): Observable<void> {
        return of(void 0);
    }
    handleRedirect$(): Observable<AuthenticationState> {
        return of({} as AuthenticationState);
    }
    authenticationState$(): Observable<AuthenticationState> {
        return of({} as AuthenticationState);
    }
    logout$(): Observable<boolean> {
        return of(true);
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return of(true)
    }
}

<mat-accordion id="supplier-item-accordian" multi="true">
  <ng-container *ngIf="viewModel | async as model">
    <ng-container *ngIf="panelClickAction | async"></ng-container>
    <mat-expansion-panel
      *ngFor="let supplierCustomItem of model.supplierCustomItems | orderBy: 'ordinal'"
      class="supplier-item-panel"
      [id]="supplierCustomItem.idAttribute"
      [expanded]="supplierCustomItem.isExpanded">
      <mat-expansion-panel-header
        class="supplier-item-panel-header item-background"
        (click)="panelClickSubject.next(supplierCustomItem.supplier.id)"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'">
        <mat-panel-title class="supplier-title">
          <div class="supplier-name">
            {{ supplierCustomItem.supplier.name | truncated:17 | uppercase }} ({{ supplierCustomItem.itemCount }})
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <gfs-custom-item-card
          #customItemCard
          [customItems]="supplierCustomItem.customItems"
          [activeItems]="supplierCustomItem.customItems
          | slice: getCurrentPageBysupplierItemId(supplierCustomItem.supplier.id)
            : getCurrentPageBysupplierItemId(supplierCustomItem.supplier.id, 10)"
          [countableItems$]="countableItems$.asObservable()"
          [suppliers]="suppliers"
          [checkboxSelectedItemsFromPanel]="checkboxSelectedItems"
          (lastPriceEnter)="onLastPriceEnter($event)"
          (checkboxCheckedEvent)="checkboxSelected($event)">
        </gfs-custom-item-card>
        <mat-paginator #supplierItemPaginator class="supplier-item-paginator" 
              [length]="supplierCustomItem.customItems.length"
              [pageSize]="10"
              [pageIndex]="supplierItemPaging[supplierCustomItem.supplier.id] ? supplierItemPaging[supplierCustomItem.supplier.id] / 10 : 0"
              hidePageSize="true" 
              showFirstLastButtons="true"
              (page) = "onPageClick($event, supplierCustomItem.supplier.id)"
              >
            </mat-paginator>
      </ng-template>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>

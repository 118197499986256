import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CategoryOrder, CustomerPK, RecipeCategory } from '@gfs/shared-models';
import { MessageService } from '../services/message.service';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private translate: TranslateService,
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  createCategory(categoryName: string, customerPK: CustomerPK): Observable<RecipeCategory> {
    const newCategory: RecipeCategory = {
      id: null,
      name: categoryName,
      customerPK,
      expandStatus: false
    };

    return this.http.post<RecipeCategory>(`${this.baseUrl}/categories`, newCategory).pipe(
      tap((cat: RecipeCategory) =>
        this.notifyUser(this.translate.instant('MESSAGES.CATEGORY_CREATION_MESSAGE', { value: cat.name }))),
      catchError(this.logError<RecipeCategory>('createCategory', newCategory))
    );
  }

  getCategories(customerPK: CustomerPK): Observable<RecipeCategory[]> {
    return this.http.get<RecipeCategory[]>(`${this.baseUrl}/categories`, { params: { ...customerPK } }).pipe(
      catchError(this.logError<RecipeCategory[]>('getCategories', []))
    );
  }

  getCategoriesOrder(customerPK: CustomerPK): Observable<CategoryOrder> {
    return this.http.get<CategoryOrder>(`${this.baseUrl}/category-orders`, { params: { ...customerPK } })
      .pipe(
        catchError(this.logError<CategoryOrder>('getCategoriesOrder'))
      );
  }

  putCategoriesOrder(categoryOrder: CategoryOrder): Observable<CategoryOrder> {
    return this.http.put<CategoryOrder>(`${this.baseUrl}/category-orders`, categoryOrder).pipe(
      catchError(this.logError<CategoryOrder>('putCategoriesOrder'))
    );
  }

  patchCategory(category: RecipeCategory): Observable<RecipeCategory> {
    return this.http.patch<RecipeCategory>(`${this.baseUrl}/categories/${category.id}`, category).pipe(
      tap(_ => this.notifyUser(this.translate.instant('MESSAGES.CATEGORY_CREATION_MESSAGE', { value: category.name }))),
      catchError(this.logError<RecipeCategory>('patchCategory', null))
    );
  }

  deleteCategory(category: RecipeCategory): Observable<void> {
    // Set categoryId to unassigned for all recipes that were in this category
    // TODO: either call the backend to do this or have the backend do it as part of the `delete` operation and update accordingly

    // Delete the category
    return this.http.delete<void>(`${this.baseUrl}/categories/${category.id}`).pipe(
      tap(_ => this.notifyUser(this.translate.instant('MESSAGES.CATEGORY_DELETION_MESSAGE', { value: category.name }))),
      catchError(this.logError<void>('deleteCategory', null))
    );
  }

  logError<T>(operation: string, result?: T) {
    return (error: Error): Observable<T> => {
      console.log(error);

      const localizedErrorMessage = this.translate.instant('MESSAGES.SNACKBAR_ERROR_MESSAGE');

      this.notifyUser(`${localizedErrorMessage} ${operation}`);
      return of(result as T);
    };
  }

  notifyUser(message: string) {
    this.messageService.queue(message);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectUri, WINDOW } from '@gfs/shared-services';
import { toEntityArray } from '@gfs/shared-services/extensions/rxjs';
import { of, Subject } from 'rxjs';
import { concatMap, delay, first, reduce } from 'rxjs/operators';
@Component({
  selector: 'app-preparing-app',
  template: '',
})
export class PreparingAppComponent implements OnInit {
  constructor(
    @Inject(WINDOW) private windowRef: Window,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }
  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      first(),
      toEntityArray<any>(),
      concatMap(x => x),
      reduce((acc, curr) => this.resolveRedirectUriFromResolvers(curr, acc), null),
      concatMap((uri: string) => {
        if (uri.toLowerCase().startsWith('http')) {
          this.windowRef.history.replaceState({}, 'foo', '/');
          this.windowRef.location.href = uri;
          return of(null).pipe(delay(5000));
        }
        return promiseMeAlways(this.router.navigateByUrl(uri ?? '/', { replaceUrl: true }));
      }),
      first(),
    ).subscribe();
  }

  private resolveRedirectUriFromResolvers(curr: any, acc: any): RedirectUri {
    return (!acc && curr instanceof RedirectUri) ? curr.uri : acc;
  }
}

export const promiseMeAlways = <TModel>(y: Promise<TModel>) => {
  const sub = new Subject();
  (y ?? new Promise<TModel>((x) => { return; })).then(() => { sub.next(true); });
  return sub;
};

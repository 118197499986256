
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTwoDigitDecimalNumber]', // eslint-disable-line
})
export class TwoDigitDecimalNumberDirective {
  // Allow decimal numbers and negative values
  @Input() regex: RegExp;
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor(private el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const selectEnd = this.el.nativeElement.selectionEnd;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');

    if ((current.length === 0 && event.key === '.')) {
      this.el.nativeElement.value = '0.';
      event.preventDefault();
    }
    if(next[0] === '.'){
      this.el.nativeElement.value = '0.';
      event.preventDefault();
    }

    if (
      (next && !String(next).match(this.regex)) ||
      (event.key === '.' && current.includes('.')) ||
      (event.key === '0' && position === 0 && selectEnd < current.length)
    ) {
      event.preventDefault();
    }
  }
}
<ng-container *ngIf="(vm$|async) as vm;">

  <gfs-loading-overlay [hidden]="!vm.isLoading"></gfs-loading-overlay>

  <ng-container *ngIf="vm.isReady">

    <ng-container  [ngSwitch]="vm.hasWorksheets">
      <ng-container *ngSwitchDefault>
        <div id="inventory-title-container">
          <h1 id="inventory-welcome-title">{{ 'INVENTORY.TITLE' | translate }}</h1>
          <h2 id="inventory-welcome-text">{{ 'INVENTORY.WELCOME_TEXT' | translate }}</h2>
          <div id="get-started-container">
            <button id="get-started-button" class="primary-button get-started-button" mat-raised-button color="primary"
              (click)="onGetStartedClick()" (keydown.ArrowRight)="onArrow()" (keydown.ArrowLeft)="onArrow()"
              attr.aria-label="{{'INVENTORY.GET_STARTED_ARIA_LABEL' | translate }}">
              {{ 'INVENTORY.GET_STARTED' | translate | uppercase }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <h1 id="inventory-welcome-title">{{ 'INVENTORY.DO_TODAY_PROMPT' | translate }}</h1>
        <div id="get-started-container">

          <button id="take-inventory-button" class="primary-button get-started-button" mat-raised-button color="primary"
            [routerLink]="['/take-inventory']" routerLinkActive="active" (keydown.ArrowRight)="onArrow()"
            (keydown.ArrowLeft)="onArrow()" attr.aria-label="{{ (inventoryButtonText$ | async) | translate }}">
            {{ (inventoryButtonText$ | async) | translate | uppercase }}
          </button>
          <ng-container *ngxPermissionsOnly="reportingPermissions">
            <button *ngIf="featureFlags.Inventory.EnableReports" id="run-report-button"
              class="primary-button get-started-button" mat-raised-button color="primary" [routerLink]="reportsUrl"
              (keydown.ArrowRight)="onArrow()" (keydown.ArrowLeft)="onArrow()"
              attr.aria-label="{{'INVENTORY.RUN_REPORT' | translate }}">
              {{ 'INVENTORY.RUN_REPORT' | translate | uppercase }}
            </button>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <mat-divider></mat-divider>

    <ng-container >
      <div class="table-label">
        <div id="inventory-counts">{{ 'INVENTORY.COUNTS' | translate }}</div>
        <div *ngxPermissionsOnly="pricingPermissions" id="average-inventory-value">
          <div>{{ 'INVENTORY.AVERAGE_INVENTORY_VALUE' | translate }}:</div>
          <div class="inventory-value">
            <gfs-localized-currency-value [value]="vm.averageInventoryValue" [language]="currentLang$ | async">
            </gfs-localized-currency-value>
          </div>
        </div>
      </div>
      <mat-table id="summary-table" mat-table [dataSource]="vm.worksheets">

        <ng-container matColumnDef="Description">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">DESCRIPTION</mat-header-cell>
          <mat-cell *matCellDef="let row" class="summary-cell mobile-card-title">
            <div class="summary-table-cell-value">{{row.name | translate}}</div>
          </mat-cell>
        </ng-container>

        <ng-template [ngxPermissionsOnly]="pricingPermissions" (permissionsAuthorized)="pricingAuthHandler(true)"
          (permissionsUnauthorized)="pricingAuthHandler(false)">
          <ng-container matColumnDef="Value">
            <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.VALUE' | translate |
              uppercase
              }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="summary-cell">
              <div class="summary-table-cell-value">
                <gfs-localized-currency-value [value]="row.totalValue" [language]="currentLang$ | async">
                </gfs-localized-currency-value>
              </div>
            </mat-cell>
          </ng-container>
        </ng-template>

        <ng-container matColumnDef="Created">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.CREATED' | translate |
            uppercase
            }}</mat-header-cell>
          <mat-cell *matCellDef="let row" class="summary-cell">
            <div class="mobile-label">{{ 'INVENTORY.CREATED' | translate | uppercase }}</div>
            <div class="summary-table-cell-value">
              <gfs-localized-datetime-value [value]="row.created" [language]="currentLang$ | async">
              </gfs-localized-datetime-value>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Modified">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.MODIFIED' | translate |
            uppercase }}</mat-header-cell>
          <mat-cell *matCellDef="let row" class="summary-cell">
            <div class="mobile-label">{{ 'INVENTORY.MODIFIED' | translate | uppercase }}</div>
            <div class="summary-table-cell-value">
              <gfs-localized-datetime-value [value]="row.lastModified" [language]="currentLang$ | async">
              </gfs-localized-datetime-value>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Status">
          <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">
            {{ 'INVENTORY.STATUS' | translate | uppercase }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="summary-cell">
            <div class="mobile-label">{{ 'INVENTORY.STATUS' | translate | uppercase }}</div>
            <div class="summary-table-cell-value">{{row.status | translate}}</div>
          </mat-cell>
        </ng-container>

        <ng-template
          [ngxPermissionsOnly]="permissionRoles.tripleDotMenu"
          (permissionsAuthorized)="tripleDotAuthHandler(true)"
          (permissionsUnauthorized)="tripleDotAuthHandler(false)">
          <ng-container matColumnDef="tripleDot">
            <mat-header-cell scope="col" *matHeaderCellDef class="header-columns tripledot action-width"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="summary-cell tripledot">

              <ng-container >
                <button mat-icon-button [matMenuTriggerFor]="worksheetMenu"
                  attr.aria-label="{{'INVENTORY.TRIPLE_DOT_MENU_OPTIONS' | translate }}">
                  <mat-icon svgIcon="triple-dot-menu"></mat-icon>
                </button>
                <mat-menu class="worksheet-tripledot-menu" #worksheetMenu>
                  <button id="worksheet-menu-edit" mat-menu-item [routerLink]="['/worksheet', row.id]"
                    routerLinkActive="active">{{
                    'INVENTORY.TRIPLE_DOT_MENU_EDIT' | translate }}</button>
                  <button id="worksheet-menu-close-period" mat-menu-item
                  *ngIf="row.isActiveInventory"
                  matTooltipPosition="left" matTooltip="{{ 'INVENTORY.CLOSE_WORKSHEET_TOOLTIP' | translate }}"
                  [routerLink]="['/worksheet', row.id,'close']"
                    routerLinkActive="active">{{
                    'INVENTORY.CLOSE_PERIOD' | translate }}</button>
                  <button *ngIf="row.isDeletable"
                    matTooltipPosition="left"
                    id="worksheet-menu-delete" mat-menu-item [routerLink]="['/worksheet',row.id,'delete']"
                      routerLinkActive="active">
                      {{
                      'INVENTORY.TRIPLE_DOT_MENU_DELETE' | translate }}</button>
                </mat-menu>
              </ng-container>
            </mat-cell>
          </ng-container>
        </ng-template>

        <ng-container matColumnDef="Info">
          <mat-header-cell class="action-width" scope="col" *matHeaderCellDef >
          </mat-header-cell>
          <mat-cell *matCellDef="let row"  class="summary-cell info action-width">
            <ng-container *ngIf="row.isActiveInventory">
              <div class="info-container">
                <mat-icon [inline]="true" svgIcon="info_icon"></mat-icon>
                <div class="info-overlay">
                  <mat-icon [inline]="true" svgIcon="info_icon_hover"
                    [matTooltip]="localizedTextResolver('INVENTORY.IN_PROGRESS_TOOLTIP')" matTooltipPosition="above"
                    [matTooltipClass]="'info-tooltip'">
                  </mat-icon>
                </div>
              </div>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row id="table-header" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </ng-container>

  </ng-container>
</ng-container>

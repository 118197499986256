import { Injectable } from '@angular/core';
import { SelectedEntitlementObservableDependency } from '@gfs/shared-models';
import { AppState as inventoryAppState } from '@gfs/store/inventory/reducers';
import { AppState as recipeAppState } from '@gfs/store/recipe/reducers';
import { Store } from '@ngrx/store';

export type EitherApp = inventoryAppState | recipeAppState;

@Injectable({
    providedIn: 'root',
})
export class SelectedCustomerUnitFactoryService {
    constructor(private store: Store<EitherApp>) { }

    public static factory(factory: SelectedCustomerUnitFactoryService) {
        return factory.Build();
    }

    Build(): SelectedEntitlementObservableDependency {
        const entitlement = this.store.select(state => {
            const entitlements = state.auth.user?.entitlements || [];
            const selectedCustomerId = state.auth.pk?.customerId;
            return entitlements
                .find(ent => ent.customerPK.customerId === selectedCustomerId);
        });
        return new SelectedEntitlementObservableDependency(entitlement);
    }
}

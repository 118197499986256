import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { InventoryConstants } from '@gfs/constants';
import { ConversionUnit, CountableItem, CountingUnit, IDictionary, StorageArea, Worksheet, WorksheetItem } from '@gfs/shared-models';
import { InventoryValueService, ItemTextSearchService, StorageAreaUtilsService } from '@gfs/shared-services';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { WorksheetItemService } from '@gfs/shared-services/services/worksheet-item.service';
import { PatchStorageArea, SetStorageAreaExpandStatus } from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, first, map, mergeAll, mergeMap, skip, take, takeUntil, tap, toArray, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-v2-storage-area-item-panel',
  templateUrl: './storage-area-item-panel.component.html',
  styleUrls: ['./storage-area-item-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class StorageAreaItemPanelComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private storageAreaUtils: StorageAreaUtilsService,
    private inventoryValueService: InventoryValueService,
    private searchService: ItemTextSearchService,
    private worksheetItemService: WorksheetItemService,
    private changeDetector: ChangeDetectorRef

  ) {
    this.storageAreaPaging = {};
  }

  worksheet$ = this.store.select(
    (state) => state.worksheets.worksheets[state.worksheets.selected]
  );
  useInfiniteScrolling$: Observable<boolean> = this.store.select(
    (state) => {
      return state.worksheets.useInfiniteScrolling;
    }
  ).pipe(
    filter(v => v !== undefined),
  );
  k = this.useInfiniteScrolling$
    .pipe(
      tap(v => {
        setTimeout(() => { this.changeDetector.markForCheck(); }, 1);
      })
    ).subscribe();

  currentLang$ = this.store.select((state) => state.layout.language);
  isMobile$ = this.store.select((state) => state.layout.isMobile);
  inventoryItems$ = this.store.select(
    (state) => state.inventoryItems.inventoryItems
  );
  itemFilterText$ = this.store.select(
    (state) => state.worksheets.itemFilterText
  );
  assignedStorageAreas$: Observable<StorageArea[]>;
  unAssignedStorageArea$: Observable<StorageArea>;
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;
  standardUnits$ = this.store
    .select((state) => state.addItemsFeature.measurementUnits)
    .pipe(
      filter(x => x !== null),
      map((units) => units.filter((unit) => unit.measurementType === 'weight'))
    );
  customItemData$ = this.store.select(
    (state) => state.worksheets.customItemData
  );
  standardCountingUnits$ = this.store.select(
    (state) => state.worksheets.countingUnits
  );
  customCountingUnits$ = this.customItemData$.pipe(
    map((data) => {
      const itemUnits: CountingUnit[] = [];
      data.forEach((datum) => {
        datum.countingUnits.forEach((unit) => {
          if (unit.custom) {
            itemUnits.push({
              itemId: datum.itemId,
              unit: {
                unitType: 'CUSTOM',
                unitId: unit.custom.id,
              } as ConversionUnit,
              customUnit: unit,
            } as CountingUnit);
          }
        });
      });
      return itemUnits;
    })
  );
  countingUnits$ = combineLatest([
    this.standardCountingUnits$,
    this.customCountingUnits$,
  ]).pipe(map((response) => [].concat(...response)));

  @Output() afterExpand = new EventEmitter();

  storageAreaPaging: { [s: string]: number };

  @ViewChildren('storageAreaPaginator')
  paginators: QueryList<MatPaginator>;
  @ViewChildren('storageAreaPaginatorIndexPlaceholder')
  paginatorsDOM: QueryList<ElementRef>;
  identify(_idx, item) { return item.id; }

  ngOnInit() {
    this.expandPanels();

    const transformedWorksheet$ =
      combineLatest([
        this.worksheet$,
        this.inventoryItems$,
      ])
        .pipe(
          map(([worksheet, inventoryItems]) =>
            this.worksheetItemService.transformWorksheetItemUnitsForSap(worksheet, inventoryItems)),
        );

    this.assignedStorageAreas$ = this.storageAreaUtils
      .getStorageAreasMinusUnassigned(transformedWorksheet$);
    this.unAssignedStorageArea$ = this.storageAreaUtils
      .getUnassignedStorageArea(transformedWorksheet$);


    // Returns to first page of storage area after item filter is executed
    const subscriptionHandler$: Subject<void> = new Subject();
    this.itemFilterText$
      .pipe(distinctUntilChanged(), takeUntil(subscriptionHandler$))
      .subscribe((_) => {
        Object.keys(this.storageAreaPaging).forEach((key) => {
          this.storageAreaPaging[key] = 0;
        });
        subscriptionHandler$.next();
        subscriptionHandler$.complete();
      });
  }

  onLastItemEnter(storageArea?: StorageArea) {
    const pageSize = 10;
    if (storageArea) {
      let pageIndex = this.storageAreaPaging[storageArea.id] / pageSize;
      if (!pageIndex) {
        pageIndex = 0;
      }
      if ((pageIndex + 1) * pageSize < storageArea.worksheetItems.length) {
        this.storageAreaPaging[storageArea.id] = (pageIndex + 1) * pageSize;
        const paginatorIndex = this.paginatorsDOM
          .toArray()
          .findIndex(
            (element) =>
              element.nativeElement.id === `paginator-${storageArea.id}`
          );
        const paginator = this.paginators.toArray()[paginatorIndex];
        paginator.nextPage();
      }
    } else {
      this.unAssignedStorageArea$.pipe(first()).subscribe((unassigned) => {
        let pageIndex = this.storageAreaPaging[unassigned.id] / pageSize;
        if (!pageIndex) {
          pageIndex = 0;
        }
        if ((pageIndex + 1) * pageSize < unassigned.worksheetItems.length) {
          this.storageAreaPaging[unassigned.id] = pageIndex;
          const paginator = this.paginators.toArray()[0];
          paginator.nextPage();
        }
      });
    }
  }

  getPage(q, pageSize, page) {
    const skipCt = page * pageSize;
    return merge(
      this.unAssignedStorageArea$.pipe(map(v => [v])),
      this.assignedStorageAreas$.pipe(),
    ).pipe(
      mergeAll(),
      filter(area => caseInsensitiveEquals(area.id, q.storageAreaId)),
      concatMap(matchArea =>
        this.filterStorageAreaItemsByFilterText(matchArea)
          .pipe(
            first(),
            mergeAll(),
            skip(skipCt),
            take(pageSize),
            toArray(),
          )
      ),
    );
  }

  onPageClick(event: PageEvent, storageAreaId?: string) {

    if (storageAreaId) {
      this.storageAreaPaging[storageAreaId] = event.pageIndex * event.pageSize;
    } else {
      this.unAssignedStorageArea$.pipe(first())
        .subscribe((unassigned) => {
          this.storageAreaPaging[unassigned.id] =
            event.pageIndex * event.pageSize;
        });
    }
  }

  getCurrentPageByStorageAreaId(
    storageAreaId: string,
    offsetToAdd: number = 0
  ) {
    return this.storageAreaPaging[storageAreaId]
      ? this.storageAreaPaging[storageAreaId] + offsetToAdd
      : 0 + offsetToAdd;
  }

  getTranslation(name: string) {
    return this.storageAreaUtils.getTranslation(name);
  }

  getRowItemCount(storageArea: StorageArea) {
    return this.storageAreaUtils.getRowItemCount(storageArea);
  }

  getWorksheetId(): Observable<string> {
    return this.worksheet$.pipe(
      filter((ws) => !!ws),
      map((ws: Worksheet) => ws.id)
    );
  }

  // expands the first panel with items if desktop, expands all if mobile
  expandPanels() {
    const subscriptionHandler$: Subject<void> = new Subject();
    combineLatest([this.isMobile$, this.worksheet$])
      .pipe(
        filter(([isMobile, ws]) => ws?.storageAreas != null),
        first(),
        takeUntil(subscriptionHandler$)
      )
      .subscribe(([isMobile, ws]) => {
        if (isMobile) {
          ws.storageAreas
            .filter((x) => !x.expandStatus)
            .forEach((area: StorageArea) => {
              this.store.dispatch(
                new SetStorageAreaExpandStatus({
                  status: true,
                  areaId: area.id,
                })
              );
            });
        } else if (ws.storageAreas) {
          const firstStorageAreaWithRowItems: StorageArea = ws.storageAreas.find(
            (stArea) => stArea.worksheetItems.length > 0
          );
          if (
            firstStorageAreaWithRowItems &&
            firstStorageAreaWithRowItems.expandStatus !== true
          ) {
            this.store.dispatch(
              new SetStorageAreaExpandStatus({
                status: true,
                areaId: firstStorageAreaWithRowItems.id,
              })
            );
          }
        }
        subscriptionHandler$.next();
        subscriptionHandler$.complete();
      });
  }

  async fireSetStorageAreaExpandStatus(
    event: Event,
    area: StorageArea,
  ): Promise<void> {
    if (area !== null) {
      this.store.dispatch(
        new SetStorageAreaExpandStatus({
          status: !area.expandStatus,
          areaId: area.id,
        })
      );
    } else if (area === null) {
      await this.unAssignedStorageArea$
        .pipe(
          first(),
          tap((unassigned) => {
            this.store.dispatch(
              new SetStorageAreaExpandStatus({
                status: !unassigned.expandStatus,
                areaId: unassigned.id,
              })
            );

          })
        ).toPromise();
    }
  }

  /** Filters general items to only the items contained in the given storage area
   *
   * @param area: the storage area to filter by
   */
  filterInventoryItemsByArea$(
    area: StorageArea,
    inventoryItems$: Observable<IDictionary<CountableItem>>
  ): Observable<{ [s: string]: CountableItem }> {
    return inventoryItems$
      .pipe(
        first(),
        filter(() => !!area && !!area.worksheetItems),
        map((items) =>
          Object.keys(items)
            .filter((id) =>
              area.worksheetItems.some((rowItem) => rowItem.itemId === id)
            )
            .reduce((acc, id) => {
              acc[id] = items[id];
              return acc;
            }, {})
        )
      );
  }

  filterStorageAreaItemsByFilterText(area: StorageArea): Observable<WorksheetItem[]> {
    return combineLatest([
      of(area),
      this.itemFilterText$,
      this.inventoryItems$,
      this.currentLang$,
    ]).pipe(
      filter(([storageArea, , , _]) => !!storageArea),
      map(([storageArea, text, invItems, lang]) => storageArea.worksheetItems
        ? this.getSortedWorksheetItems(storageArea)
          .filter((workItem) =>
            this.searchService.workItemTextSearch(
              text,
              lang,
              workItem.itemId,
              invItems[workItem.itemId]))
        : [])
    );
  }

  objectToArray(
    itemMap: Observable<{ [s: string]: CountableItem }>
  ): Observable<CountableItem[]> {
    return itemMap.pipe(
      map((items) => Object.keys(items).map((key) => items[key]))
    );
  }

  arrayToObject(itemArray: CountableItem[]): { [s: string]: CountableItem } {
    return itemArray.reduce((acc, item) => {
      if (item.gfsItem) {
        acc[item.gfsItem.id] = item;
      }
      return acc;
    }, {});
  }

  // Will sort all items found in worksheetItemOrder and then concat any left unsorted at the end
  getSortedWorksheetItems(storageArea: StorageArea): WorksheetItem[] {
    const sortedItems: WorksheetItem[] = !storageArea.worksheetItemOrder
      ? storageArea.worksheetItems
      : storageArea.worksheetItemOrder
        .map((itemId) =>
          storageArea.worksheetItems.find((item) => item.id === itemId)
        )
        .filter((item) => !!item);

    const itemsLeftUnsorted = storageArea.worksheetItems.filter(
      (unsortedItem) =>
        !sortedItems.some((sortedItem) => sortedItem.id === unsortedItem.id)
    );

    const allItems = sortedItems.concat(itemsLeftUnsorted);

    return allItems;
  }

  getUnassignedStorageAreaTotal(): Observable<number> {
    return this.unAssignedStorageArea$.pipe(
      mergeMap((area) => this.getStorageAreaValueTotal(area))
    );
  }

  isUnassignedStorageAreaTotalNull(): Observable<boolean> {
    return this.getUnassignedStorageAreaTotal().pipe(map((total) => !total));
  }

  canGetStorageAreaValueTotal(
    selectedArea: StorageArea,
    worksheet: Worksheet
  ): boolean {
    return [!!selectedArea?.id, !!worksheet?.storageAreas?.length]
      .every(isTrue => isTrue);
  }

  copyWorksheetItems(
    selectedArea: StorageArea,
    worksheet: Worksheet,
    countableItems: IDictionary<CountableItem>
  ): StorageArea {
    // Copy user updates from dom onto the storage area out of state
    const area = worksheet.storageAreas
      .find(area => area.id === selectedArea.id);
    area.worksheetItems = selectedArea.worksheetItems;

    // Make adjustments for SAP items
    return this.worksheetItemService.transformStorageAreaItemUnitsForSap(area, countableItems);
  }

  getStorageAreaValueTotal(area: StorageArea): Observable<number> {
    return of(area)
      .pipe(
        withLatestFrom(this.worksheet$),
        filter(([selectedArea, worksheet]) =>
          this.canGetStorageAreaValueTotal(selectedArea, worksheet)),
        withLatestFrom(this.inventoryItems$),
        map(([[selectedArea, worksheet], countableItems]) =>
          this.copyWorksheetItems(selectedArea, worksheet, countableItems)),
        withLatestFrom(
          this.inventoryItems$,
          this.customItemData$,
          this.standardUnits$),
        map(([area, items, itemData, units]) => {
          return this.inventoryValueService.getStorageAreaValueTotal(area, items, units, itemData);
        })
      );
  }

  isStorageAreaValueTotalNull(area: StorageArea): Observable<boolean> {
    return this.getStorageAreaValueTotal(area).pipe(
      map((total) => !total || total == null || isNaN(total))
    );
  }

  handleWorksheetItemDrop(eventPayload: any) {
    if (eventPayload.currentIndex === eventPayload.previousIndex) {
      return;
    }

    const worksheetItemOffset = eventPayload.isDragDrop
      ? this.getCurrentPageByStorageAreaId(eventPayload.storageAreaId, 0)
      : 0;
    const currentIndexWithOffset =
      eventPayload.currentIndex + worksheetItemOffset;
    const previousIndexWithOffset =
      eventPayload.previousIndex + worksheetItemOffset;
    if (previousIndexWithOffset > -1) {
      this.worksheet$.pipe(first()).subscribe((worksheet) => {
        const currentSorted = this.getSortedWorksheetItems(
          worksheet.storageAreas.find(
            (area) => area.id === eventPayload.storageAreaId
          )
        );

        const newSorted = currentSorted
          .filter((item, index) => index !== previousIndexWithOffset)
          .filter((item) => !!item);
        newSorted.splice(
          currentIndexWithOffset,
          0,
          currentSorted[previousIndexWithOffset]
        );

        const newSortedIds = newSorted.map((item) => item.id);

        this.store.dispatch(
          new PatchStorageArea({
            worksheetId: worksheet.id,
            storageAreaId: eventPayload.storageAreaId,
            fields: {
              worksheetItemOrder: newSortedIds,
            },
          })
        );
      });
    }
  }
}
import { Observable } from 'rxjs';
import { CountableItem, ItemReference, ResolvedItem, StorageArea, WorksheetItem } from './lib';

export * from './lib/cart.model';
export * from './lib/core/app-context.core.model';
export * from './lib/customer.model';
export * from './lib/customItem.model';
export * from './lib/generalItem.model';
export * from './lib/IDictionary';
export * from './lib/inventory-icon.model';
export * from './lib/product.model';
export * from './lib/recipe.model';
export * from './lib/recipe.view.model';
export * from './lib/supplier.model';
export * from './lib/user.model';
export * from './lib/worksheet-print-info.model';
export * from './lib/worksheet-print-meta-info.model';
export * from './lib/worksheet.model';
export * from './lib/recipe-profit-calculator.model';
export * from './lib/application-user-role.enum';















//#region V2
export type MeasurementUnitDisplayOptions = {
    minText?: boolean;
};
export type MeasurementUnitDataSource = {
    unitDataSource?: (itemref: ItemReference) => Observable<any>;
};
export type ResolvedItemDataSource = {
    itemDataSource?: (itemref: ItemReference, opt: ResolvedItemDataSource) => Observable<ResolvedItem>;
    useDisplayItem: boolean
};
export type reallyCoolOpts = MeasurementUnitDisplayOptions & ResolvedItemDataSource & MeasurementUnitDataSource;
//#endregion


export interface WorksheetItemConfigDTO {
    worksheetId: string,
    worksheetItem: WorksheetItem,
    countableItem: CountableItem,
    storageArea: StorageArea,
}
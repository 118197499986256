import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { IAppContext, StorageArea } from '@gfs/shared-models';
import { InjectionTokens, WorksheetService } from '@gfs/shared-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetStorageAllAreasInWorksheetResolver implements Resolve<Observable<StorageArea[]>> {

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private worksheetSvc: WorksheetService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StorageArea[]> {
    return this.worksheetSvc.getWorkSheet(route.parent.params.id).pipe(map(v => v.storageAreas));
  }

}
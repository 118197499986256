import { Constants } from '@gfs/constants';
import { ApplicationUserRole, RecipeProfitCalculatorConfig } from '@gfs/shared-models';
import { AppConfigService } from '../services/app-config/app-config.service';

export function recipeProfitCalculatorConfigurationFactory(
    userRole: ApplicationUserRole,
    appConfig: AppConfigService,
): RecipeProfitCalculatorConfig {
    const recipeProfitCalculatorPaths = Constants.RecipeFeaturePaths
        .RECIPE_PROFIT_CALCULATOR[userRole];
    return {
        ...recipeProfitCalculatorPaths,
        isNavigatingFromCalculator: function (window: Window) {
            return Object
                .values(recipeProfitCalculatorPaths)
                .some((path: string) => 0 < window.location.href.indexOf(path));
        },
        isFeatureEnabled: function () {
            return appConfig.getSettings().FF_RECIPE_PROFIT_CALCULATOR
        },
    };
}

import * as fromQuickAddItems from './quick-add-items.actions';
import { initialState, FeatureState } from './quick-add-items.state';
import { mapGeneralItemToCountableItems, createMappedCategories } from '../add-items';

export function quickAddItemsReducer(
  state: FeatureState = initialState,
  action: fromQuickAddItems.ActionUnion
): FeatureState {
  switch (action.type) {
    case fromQuickAddItems.ActionTypes.GetCountableItemsAttempt: {
      return {
        ...state,
        searching: true,
      };
    }
    case fromQuickAddItems.ActionTypes.GetGeneralItemByCustomItemIdSuccess: {
      const newState = {
        ...state,
        countableItems: mapGeneralItemToCountableItems(
          state.countableItems,
          action.payload.generalItem,
          action.payload.customItemId
        ),
      };
      return newState;
    }
    case fromQuickAddItems.ActionTypes.GetCountableItemsSuccess: {
      return {
        ...state,
        countableItems: action.payload.items,
        searching: false,
      };
    }
    case fromQuickAddItems.ActionTypes.ClearCountableItems: {
      return {
        ...state,
        countableItems: [],
      };
    }
    case fromQuickAddItems.ActionTypes.GetItemCategoriesBeginLoading: {
      return {
        ...state,
        itemCategories_isLoading: true,
        itemCategories: null
      };
    }
    case fromQuickAddItems.ActionTypes.GetItemCategoriesSuccess: {
      return {
        ...state,
        itemCategories_isLoading: false,
        itemCategories: createMappedCategories(
          action.payload.categories,
          action.payload.language
        ),
      };
    }
    case fromQuickAddItems.ActionTypes.GetSuppliersBeginLoading: {
      return {
        ...state,
        suppliers_loading: true,
        suppliers: null,
      };
    }
    case fromQuickAddItems.ActionTypes.GetSuppliersSuccess: {
      return {
        ...state,
        suppliers_loading: false,
        suppliers: action.payload.suppliers,
      };
    }
    case fromQuickAddItems.ActionTypes.GetMeasurementUnitsBeginLoading: {
      return {
        ...state,
        measurementUnits_isLoading: true,
        measurementUnits: null
      };
    }
    case fromQuickAddItems.ActionTypes.GetMeasurementUnitsSuccess: {
      return {
        ...state,
        measurementUnits_isLoading: false,
        measurementUnits: action.units,

      };
    }
    case fromQuickAddItems.ActionTypes.ClearCustomItemData: {
      return {
        ...state,
        customItemData: null,
      };
    }
    case fromQuickAddItems.ActionTypes.GetCountableItemsError:
    case fromQuickAddItems.ActionTypes.GetItemCategoriesError:
    case fromQuickAddItems.ActionTypes.GetMeasurementUnitsError:
    case fromQuickAddItems.ActionTypes.GetSuppliersError:
    case fromQuickAddItems.ActionTypes.GetGeneralItemByCustomItemIdError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    default: {
      return state;
    }
  }
}

export function createSuggestionsMap(termList, searchTermLength) {
  return termList.map((term) => ({ termLength: searchTermLength, text: term }));
}

import { Injectable } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { CountableItem, GfsItem, StorageArea, Worksheet, WorksheetItem } from '@gfs/shared-models';

@Injectable({
  providedIn: 'root'
})
export class WorksheetItemService {

  private ignoreSmallestUnitFns = {
    promoteSecondaryUnitQtyToPrimaryUnitQty(item: WorksheetItem): WorksheetItem {
      const mapSecondaryQtyToPrimaryQty = [
        !item.primaryUnitQty,
        item.secondaryUnitQty,
        item.primaryUnitType === InventoryConstants.COUNTING_UNIT_TYPES.LITERAL,
        item.secondaryUnitType === InventoryConstants.COUNTING_UNIT_TYPES.LITERAL
      ].every(isTrue => isTrue);
      if (mapSecondaryQtyToPrimaryQty) {
        item.primaryUnitQty = item.secondaryUnitQty;
      }
      return item;
    },

    clearSecondaryUnit(item: WorksheetItem): WorksheetItem {
      const clearSecondaryUnit = [
        item.secondaryUnitType === InventoryConstants.COUNTING_UNIT_TYPES.LITERAL
      ].every(isTrue => isTrue);
      if (clearSecondaryUnit) {
        item.secondaryUnit = null;
        item.secondaryUnitType = null;
      }
      return item;
    }
  };

  isPrimaryUnitEach(gfsItem: GfsItem): boolean {
    return gfsItem
      ?.units
      .some(unit => [
        gfsItem?.baseUom === unit.name[0].value,
        unit.name[0].value === InventoryConstants.SAP_UOM_CASE,
        unit.displayCode === InventoryConstants.SAP_UOM_EACH
      ].every(isTrue => isTrue))
      ?? false;
  }

  transformStorageAreaItemUnitsForSap(
    storageArea: StorageArea,
    countableItems: { [s: string]: CountableItem; }
  ): StorageArea {
    return {
      ...storageArea,
      worksheetItems: storageArea.worksheetItems
        .map(item => ({ ...item }))
        .map(item => {
          const gfsItem = countableItems[item.itemId]?.gfsItem;
          if (!gfsItem) {
            return item;
          }

          const step1IsEach = { ...item, isEach: this.isPrimaryUnitEach(gfsItem) };
          const step2HandleMapSecondaryUnitToPrimaryUnit = this.handleMapSecondaryUnitToPrimaryUnit({ ...step1IsEach });
          const step3HandleClearSecondaryUnit = this.handleClearSecondaryUnit({ ...step2HandleMapSecondaryUnitToPrimaryUnit });

          const composite = {
            gfsItem,
            step1IsEach,
            step2HandleMapSecondaryUnitToPrimaryUnit,
            step3HandleClearSecondaryUnit,
          };

          return composite.step3HandleClearSecondaryUnit;
        })
    };
  }

  transformWorksheetItemUnitsForSap(
    worksheet: Worksheet,
    countableItems: { [s: string]: CountableItem; }
  ): Worksheet {
    return {
      ...worksheet,
      storageAreas: worksheet
        ?.storageAreas
        ?.map(area => this.transformStorageAreaItemUnitsForSap(area, countableItems))
    };
  }

  handleMapSecondaryUnitToPrimaryUnit(item: WorksheetItem): WorksheetItem {
    const mapSecondaryUnitToPrimaryUnit = [
      item.isEach,
      !item.primaryUnitQty,
      item.primaryUnit?.toLowerCase() === InventoryConstants.LITERAL_UOM_CASE,
      item.secondaryUnitQty,
      item?.secondaryUnit?.toLowerCase() === InventoryConstants.LITERAL_UOM_UNIT
    ].every(isTrue => isTrue);
    if (mapSecondaryUnitToPrimaryUnit) {

      // Primary
      item.primaryUnit = InventoryConstants.SAP_UOM_EACH;
      item.primaryUnitQty = item.secondaryUnitQty;
      item.primaryUnitType = InventoryConstants.UNIT_TYPE_LITERAL;

      // Secondary
      item.secondaryUnitQty = null;
      item.secondaryUnit = null;
      item.secondaryUnitType = null;
    }

    return item;
  }

  handleClearSecondaryUnit(item: WorksheetItem): WorksheetItem {
    const shouldClearSecondaryUnit = [
      item.isEach,
      item.primaryUnit?.toLowerCase() === InventoryConstants.LITERAL_UOM_CASE,
      item?.secondaryUnit?.toLowerCase() === InventoryConstants.LITERAL_UOM_UNIT
    ].every(isTrue => isTrue);

    return shouldClearSecondaryUnit
      ? {
        ...item,
        secondaryUnit: null,
        secondaryUnitType: null
      }
      : item;
  }

  ignoreSmallestUnit(gfsItem: GfsItem): boolean {
    const hasUnits = !!gfsItem?.units?.length;
    if (!hasUnits) {
      return false;
    }

    const lastChildUnit = gfsItem.units.slice(-1)[0];
    const isLastChildUnitStandardUnit = InventoryConstants.GLOBAL_STANDARD_WEIGHT_CODES.includes(lastChildUnit.name[0].value);
    const isLastChildUnitSecondaryUnit = lastChildUnit.parentUom === gfsItem.baseUom;
    return isLastChildUnitStandardUnit && isLastChildUnitSecondaryUnit;
  }

  handleIgnoreSmallestUnit(item: WorksheetItem, gfsItem: GfsItem): WorksheetItem {
    item.ignoreStandardSecondaryUnit = this.ignoreSmallestUnit(gfsItem);
    if (item.ignoreStandardSecondaryUnit) {
      item = this.ignoreSmallestUnitFns.promoteSecondaryUnitQtyToPrimaryUnitQty(item);
      item = this.ignoreSmallestUnitFns.clearSecondaryUnit(item);
    }

    return item;
  }
}

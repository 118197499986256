import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionService {

  constructor(
    private permissionsService: NgxPermissionsService
  ) { }

  hasPermission(permission: string | string[]): Promise<boolean> {
    return this.permissionsService.hasPermission(permission);
  }

}
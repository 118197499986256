import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { IDictionary } from '@gfs/shared-models';
import { tapLog } from '@gfs/shared-services/extensions/rxjs';
import { ItemCountEntryDTO, ProductCountElement, TierCount, TypedLocalizedMeasurementUnitDTO, WorksheetItemDTO } from '@gfs/v2/shared-models';
import { Observable, Subject, combineLatest, from, of } from 'rxjs';
import { catchError, concatMap, filter, map, startWith, takeUntil, tap, toArray } from 'rxjs/operators';
import { InventoryCountEntryComponent } from '../inventory-count-entry/inventory-count-entry.component';

@Component({
  selector: 'app-inventory-count-list',
  templateUrl: './inventory-count-list.component.html',
  styleUrls: ['./inventory-count-list.component.css']
})
export class InventoryCountListComponent implements OnDestroy, AfterViewInit {

  valueChange$ = new Subject<ItemCountEntryDTO>();

  @ViewChildren(InventoryCountEntryComponent) countingUnitTiers!: QueryList<InventoryCountEntryComponent>;

  @Output() countEntryCompleted = new EventEmitter<InventoryCountListComponent>();
  @Output() productCountChange = new EventEmitter<ProductCountElement>();
  @Input()
  localizedUnits: IDictionary<TypedLocalizedMeasurementUnitDTO> = {};

  @Input()
  worksheetItem: WorksheetItemDTO;

  @Input()
  disableUnitChange = true;

  constructor() { }

  destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngAfterViewInit() {

    this
      .initBehavior()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

  }

  updateMutuallyExclusiveOptionState(
    countingUnitTierElements: QueryList<InventoryCountEntryComponent>,
    currentTierElement: InventoryCountEntryComponent,
    selectedKey?: string,
  ) {
    countingUnitTierElements
      .filter(unitTierElement => unitTierElement !== currentTierElement)
      .forEach(siblingTierElement => {
        siblingTierElement.countingUnitSelect.options
          .forEach(matOption => {
            matOption.disabled = matOption.value === selectedKey;
          });
      });

    return `Selected ${currentTierElement.countingUnitSelect.value ?? 'NOTHING'} `;
  }

  initBehavior() {
    return combineLatest([
      this.createBehaviorMutuallExclusiveOptionAvailability(
        this.countingUnitTiers.toArray(),
      ),
      this.createBehaviorNotifyProductCountChange(
        this.valueChange$,
      )
    ]);
  }

  createBehaviorMutuallExclusiveOptionAvailability(
    countEntryCollection: InventoryCountEntryComponent[],
  ) {
    return from(countEntryCollection)
      .pipe(
        tap(element => {
          // set the initial state of disabled unit options
          this.updateMutuallyExclusiveOptionState(
            this.countingUnitTiers,
            element,
            element.itemCountEntry.unit
          );
        }),
        // when the selected counting unit changes
        map(element =>
          element.countingUnitSelect
            .valueChange
            .asObservable()
            .pipe(
              // disable that unit for other counting unit tiers
              map(
                (selectedUnitKey) =>
                  this.updateMutuallyExclusiveOptionState(
                    this.countingUnitTiers,
                    element,
                    selectedUnitKey
                  )
              )
            )
        ),
        map(v => v.pipe(startWith('Ready...'))),
        toArray(),
        concatMap(v => combineLatest(v))
      );
  }

  async tryFocusNextUnitTier(
    $event: ItemCountEntryDTO,
  ) {
    const nextUnitTierOrdinal = $event.ordinal + 1;
    if (nextUnitTierOrdinal === this.countingUnitTiers.length) {
      this.countEntryCompleted.emit(this);
    } else {
      const unitTier = this.countingUnitTiers.get(nextUnitTierOrdinal);
      unitTier.itemCountInput.nativeElement.focus();
    }
  }

  createBehaviorNotifyProductCountChange(
    notifier$: Observable<ItemCountEntryDTO>,
  ) {
    return notifier$
      .pipe(
        filter(countEntry => {
          return this.isValidCountEntry(countEntry)
            || this.isValidUnsetItemUnitEntry(countEntry);
        }
        ),
        tapLog('product count changed'),
        tap(countEntry => {
          const entryIndex = this.worksheetItem.itemCounts.findIndex(k => k.ordinal === countEntry.ordinal);
          this.worksheetItem.itemCounts[entryIndex].updated = true;

          if (this.isValidUnsetItemUnitEntry(countEntry)) {
            this.worksheetItem.itemCounts[entryIndex].qty = null;
          }

        }),
        map(() => {

          const {
            itemCounts,
            worksheetItemId
          } = this.worksheetItem;

          return ({
            worksheetItemId,
            itemCounts: itemCounts
              .filter(c => c.updated)
              .map(({ ordinal, qty, unit }) => ({
                ordinal,
                qty,
                unit
              } as TierCount))
          } as ProductCountElement);
        }),
        tapLog('emit product count change'),
        tap(updatedCount => {
          this.productCountChange.emit(updatedCount);
        }),
        catchError(() => of(true))
      );
  }

  isValidCountEntry(countEntry: ItemCountEntryDTO): boolean {
    return countEntry.unit !== null;
  }

  isValidUnsetItemUnitEntry(countEntry: ItemCountEntryDTO): boolean {
    return countEntry.unit === '--';
  }

}

<ng-container *ngIf="(gfsApplications$ | async) as gfsApplications">
<ng-container *ngIf="(selectedApp$ | async) as selectedApp">
<ng-container *ngIf="(isCustomerUnitRoute$ | async) as isCustomerUnitRoute">
<div class="header hamburger-box">
  <button
    (click)="navigateBack()"
    class="close"
    attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"
    type="button">
    <mat-icon svgIcon="close-icon"></mat-icon>
    {{ 'MODALS.CLOSE' | translate }}
  </button>
  <div *ngIf="!isCustomerUnitRoute.value" class="applications-menu">
    <gfs-select-menu
      buttonId="applications-menu-button"
      [options]="gfsApplications"
      [value]="selectedApp"
      [optionLabelFn]="getApplicationOptionLabel()">
      <span>{{ selectedApp?.label | translate | uppercase }}</span>
    </gfs-select-menu>
  </div>
</div>
<div class="scene-element scene-element-fadeinleft container hamburger-menu-container">
  <div class="hamburger-menu-top hamburger-box">
    <div *ngIf="!isCustomerUnitRoute.value" class="top-item">
      <gfs-cart-icon [wideLayout]="true"></gfs-cart-icon>
    </div>
    <div class="top-item">
      <gfs-customer-unit-selector></gfs-customer-unit-selector>
    </div>
  </div>
  <div *ngIf="!isCustomerUnitRoute.value && (activeApplication === 'inventory')" class="hamburger-menu-middle hamburger-box">
    <div *ngFor="let link of subHeaderLinks$ | async; let i = index" class="menu-item">
      <a class="links"
        [ngClass]="{disabled: !(isOnline$ | async), active: link.isActive}"
        [routerLink]="[(isOnline$ | async) ? link.link : null]"
        [tabindex]="i">
        {{ link.text | translate }}
      </a>
    </div>
  </div>
  <div class="hamburger-menu-bottom hamburger-box">
    <div class="menu-item app-language-menu-wrapper">
      <gfs-language-menu></gfs-language-menu>
    </div>
    <div class="menu-item">
      <mat-icon class="logout-icon" svgIcon="unavailable"></mat-icon>
      <button class="logout links" role="link" (click)="logout()">
        <span>{{'HEADER.LOGOUT' | translate}}</span>
      </button>
    </div>
  </div>
</div>
</ng-container>
</ng-container>
</ng-container>

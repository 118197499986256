import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerPK, ItemCategory, LocalizedValue } from '@gfs/shared-models';
import { Observable } from 'rxjs';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class ItemCategoryService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getItemCategories(
    customerPK: CustomerPK
  ): Observable<ItemCategory[]> {
    return this.http.get<ItemCategory[]>(`${this.baseUrl}/item-categories`, { params: { ...customerPK }});
  }

  createItemCategory(
    customerPK: CustomerPK,
    name: LocalizedValue
  ): Observable<ItemCategory> {
    return this.http.post<ItemCategory>(`${this.baseUrl}/item-categories`, { customerPK, name: [name] });
  }
}

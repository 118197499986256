import { Injectable, Inject } from '@angular/core';
import { IAppConfig } from './IAppConfig';
import { WINDOW } from '../window.service';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { Constants, InventoryConstants, RecipeConstants } from '@gfs/constants';
import { OIDC, OIDCConfig } from '@gfs/shared-services/auth/oauth2/models/oidc-config.model';
import { forkJoin, map, of } from 'rxjs';
import { ApplicationUserRole } from '@gfs/shared-models';
import { OpenIDConnectService } from '@gfs/shared-services/auth';

const SESSION_CONFIG = '__APP_CONFIG__';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(@Inject(WINDOW) private window: Window) { }

  getSettings(): IAppConfig {
    const sessionData = this.window.sessionStorage.getItem(SESSION_CONFIG);
    return JSON.parse(sessionData);
  }

  getInfiniteScrollSetting(): any {
    const isInfiniteScrollEnabled = this.getSettings().FF_INFINITE_SCROLL;
    if (!isInfiniteScrollEnabled) {
      return false;
    }

    return this.getLocalStorageValue(InventoryConstants.infiniteScrollSettingPath, false);
  }

  getLocalStorageValue(togglePath: string, defaultValue?: any): any {
    try {
      const v = this.window.localStorage.getItem(togglePath);
      return v ? JSON.parse(v) : (defaultValue ?? undefined);
    } catch (error) {
      return defaultValue ?? undefined;
    }
  }

  setLocalStorageValue(togglePath: string, value: any): void {
    this.window.localStorage.setItem(togglePath, JSON.stringify(value));
  }
}

export function getAPIBaseUrl(appConfigService: AppConfigService) {
  return appConfigService.getSettings().API_BASE_URL;
}

export function getInventoryAPIBaseUrl(appConfigService: AppConfigService) {
  return appConfigService.getSettings().INVENTORY_API_BASE_URL;
}

export function oktaConfigFactory(
  appSettingsService: AppConfigService
): { oktaAuth: OktaAuth } {
  const config = appSettingsService.getSettings();
  const authConfig: OktaAuthOptions = {
    issuer: `${config.OKTA_BASE_URL}/oauth2/${config.OKTA_AUTH_SERVER}`,
    redirectUri: `${config.APP_BASE_URL}/implicit/callback`,
    clientId: config.OKTA_CLIENT_ID,
    pkce: false,
    postLogoutRedirectUri: config.LOGOUT_REDIRECT_URL,
    scopes: ['openid', 'profile', 'email', 'guid', 'entitlements']
  };
  const oktaAuth = new OktaAuth(authConfig);
  return { oktaAuth };
}

export function employeeOIDCConfigFactory(
  activeApplication: 'recipe' | 'inventory',
  appSettingsService: AppConfigService,
  openIDConnectService: OpenIDConnectService,
  userRole: ApplicationUserRole,
): OIDCConfig {
  const isRecipe = activeApplication === RecipeConstants.APPLICATION_KEY;
  const isEmployee = userRole === ApplicationUserRole.Employee;

  // Employee auth is only supported by Recipe, in the recipe profit calculator feature.
  // It should not be accessible from Inventory
  if (!(isRecipe && isEmployee)) {
    return undefined;
  }

  const appSettings = appSettingsService.getSettings();
  const issuer = `${appSettings.NAM_BASE_URL}/nidp/oauth/nam`;
  return forkJoin([
    openIDConnectService.resolveAuthorizationServer$(issuer),
    of(appSettings),
    of(issuer),
  ]).pipe(
    map(([authServer, config, issuer]) => {
      const callback = Constants.RecipeFeaturePaths.RECIPE_PROFIT_CALCULATOR.callback;
      const oidc: OIDC = {
        issuer,
        redirectUri: `${config.APP_BASE_URL}/${callback}`,
        clientId: config.NAM_CLIENT_ID,
        authorizationServer: authServer,
      };
      return { ...oidc };
    })
  );
}
<div class="print-worksheet-container">
  <div class="print-worksheet-dialog-header">
    <div class="print-worksheet-dialog-header-title-wrapper">
      <div class="print-worksheet-dialog-header-title">{{ 'PRINT_WORKSHEET.PRINT_WORKSHEET_TITLE' | translate }}</div>
    </div>

    <button
      mat-icon-button
      class="print-worksheet-dialog-header-close"
      attr.aria-label="{{ 'MODALS.CLOSE' | translate }}"
      (click)="dialogRef.close()"
      (keyup.enter)="dialogRef.close()">
      <mat-icon svgIcon="close-icon"></mat-icon>
    </button>
  </div>

  <div class="print-worksheet-dialog-content">
    <div class="dialog-content-header">
      <div class="print-worksheet-dialog-text">{{ 'PRINT_WORKSHEET.PRINT_WORKSHEET_TEXT' | translate }}</div>
      <div class="print-worksheet-dialog-subtext">{{ 'PRINT_WORKSHEET.PRINT_WORKSHEET_SUB_TEXT' | translate }}</div>
    </div>

    <mat-divider></mat-divider>
    <mat-grid-list class="storage-area-grid" gutterSize="0px" rowHeight="48px" [cols]="(isMobile$ | async) ? 1 : 2">
      <mat-grid-tile class="storage-area-grid">
        <mat-checkbox class="storage-area-option"
          [checked]="selectAll"
          (change)="onSelectAll($event)">{{ 'PRINT_WORKSHEET.SELECT_ALL' | translate }}
        </mat-checkbox>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-divider></mat-divider>
    <div class="storage-area-container">
      <mat-grid-list class="storage-area-grid" gutterSize="0px" rowHeight="48px" [cols]="(isMobile$ | async) ? 1 : 2">
        <mat-grid-tile>
          <mat-checkbox class="storage-area-option"
            [value]="(unAssignedStorageArea$ | async)"
            [checked]="selectedStorageAreas.indexOf((unAssignedStorageArea$ | async)) > -1"
            (change)="onStorageAreaSelected($event)">{{'INVENTORY_WORKSHEET.UNASSIGNED' | translate}} ({{(unAssignedStorageArea$ | async).worksheetItems.length}})
          </mat-checkbox>
        </mat-grid-tile>
        <mat-grid-tile *ngFor="let storageArea of (assignedStorageAreas$ | async)">
          <mat-checkbox class="storage-area-option"
            [value]="storageArea"
            [checked]="selectedStorageAreas.indexOf(storageArea) > -1"
            (change)="onStorageAreaSelected($event)">{{getStorageAreaTranslation(storageArea.name) | translate | truncated:17 }} ({{storageArea.worksheetItems.length}})
          </mat-checkbox>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
</div>

<div class="print-worksheet-dialog-button-bar">
  <div
    class="print-worksheet-dialog-button-wrapper print-worksheet-dialog-blank-wrapper">
    <button
      id="print-value-report-button-cancel"
      class="secondary-button large-button"
      type="button"
      (click)="dialogRef.close()">
        {{ 'MODALS.CANCEL' | translate }}
    </button>
  </div>
  <div
    class="print-worksheet-dialog-button-wrapper print-worksheet-dialog-blank-wrapper">
    <button
      id="print-value-report-button-download"
      class="primary-button large-button"
      type="button"
      [disabled]="selectedStorageAreas.length < 1"
      (click)="printWorksheet()">
        {{ 'PRINT_WORKSHEET.DOWNLOAD_PDF_BUTTON' | translate }}
    </button>
  </div>
</div>

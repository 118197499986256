import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { CustomItemData, MeasurementUnit } from '@gfs/shared-models';
import { CustomUnitUtilsService, InjectionTokens, LocalizedValueUtilsService } from '@gfs/shared-services';
import { filter, first, map, mergeMap, skip, startWith, tap, timeout } from 'rxjs/operators';
import { InventoryConstants } from '@gfs/constants';
import { IAddItemsFeatureBridge, IFeatureStateFacade } from '@gfs/store/feature/add-items';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get, sortBy } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';

@Component({
  selector: 'gfs-unit-of-measure-form',
  templateUrl: './unit-of-measure-form.component.html',
  styleUrls: ['./unit-of-measure-form.component.scss']
})
export class UnitOfMeasureFormComponent implements OnInit, AfterViewInit {
  @Input() measurementUnitForm: UntypedFormGroup;
  @Input() isAllowedToEdit = true;
  saveClicked = false
  @Input() customItemData$: Observable<CustomItemData[]>;
  @Output() weightUnitBlur = new EventEmitter<any>();
  @Output() netWeightBlur = new EventEmitter<void>();
  currentLang$ = this.addItemsCtx.currentLang$;
  weightUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits).pipe(
    map(units => (units ?? []).filter(unit => unit.measurementType === 'weight' && unit.measurementSystem !== 'imperial'))
  );
  volumeUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits).pipe(
    map(units => (units ?? []).filter(unit => unit.measurementType === 'volume' && unit.measurementSystem !== 'imperial'))
  );
  measurementUnits$ = this.store.select(state => state.addItemsFeature.measurementUnits).pipe(
    map(units => (units ?? []).filter(unit =>
      unit.measurementSystem !== 'imperial' && unit.measurementType !== 'menuItem' && unit.measurementType !== 'batchRecipe'
    ))
  );
  filteredWeightUnits$: Observable<MeasurementUnit[]>;
  filteredVolumeUnits$: Observable<MeasurementUnit[]>;
  filteredStandardUnitList$: Observable<MeasurementUnit[]>[] = [];
  lastValidVolumeUnit: MeasurementUnit;
  lastValidStandardUnitList: MeasurementUnit[] = [];
  purchaseInputRegex: any;
  qtyRegex = InventoryConstants.ITEM_QTY_REGEX;

  @ViewChildren('nameInput') countingUnitNames: QueryList<ElementRef>;

  constructor(
    private localizedValueUtils: LocalizedValueUtilsService,
    public translate: TranslateService,
    private store: Store<IFeatureStateFacade>,
    private customUnitUtils: CustomUnitUtilsService,
    @Inject(InjectionTokens.IAddItemsFeatureBridge) private addItemsCtx: IAddItemsFeatureBridge
  ) { }

  ngOnInit(): void {
    this.currentLang$.pipe(first()).subscribe(lang => {
      this.purchaseInputRegex = InventoryConstants.PURCHASE_UNIT_LOCALIZED_REGEX[lang] ||
        InventoryConstants.PURCHASE_UNIT_LOCALIZED_REGEX.en_CA;
    });

    this.measurementUnitForm.controls.volumeUnit.setAsyncValidators(this.volumeUnitValidator());
    this.measurementUnitForm.controls.netVolume.setValidators(this.netVolumeValidator());

    this.filteredWeightUnits$ = this.measurementUnitForm.controls?.catchWeightUnit?.valueChanges.pipe(
      tap(_ => this.measurementUnitForm.controls.netWeight.updateValueAndValidity()),
      startWith(''),
      mergeMap(value => this.filterUnits(value, this.weightUnits$)),
      map(units => sortBy(units, ['measurementSystem', 'size']))
    );
    this.filteredVolumeUnits$ = this.measurementUnitForm.controls?.volumeUnit?.valueChanges.pipe(
      tap(_ => this.measurementUnitForm.controls.netVolume.updateValueAndValidity()),
      startWith(''),
      mergeMap(value => this.filterUnits(value, this.volumeUnits$)),
      map(units => sortBy(units, ['measurementSystem', 'size']))
    );

    this.createCustomRecipeUnits();
  }

  ngAfterViewInit() {
    this.countingUnitNames.changes.subscribe(elements => {
      elements.last?.nativeElement.focus();
    });
  }

  createStandardUnitsFilter(formGroup: number): Observable<MeasurementUnit[]> {
    return this.getCountingUnitParent(formGroup).valueChanges.pipe(
      startWith(''),
      map(value => this.filterUnits(value, this.measurementUnits$)),
      mergeMap(units => this.removeUnknownUnits(units)),
      map(units => sortBy(units, ['measurementType', 'measurementSystem', 'size']))
    );
  }

  getUnitQtyPlaceholder(lang, units, formControl: string) {
    const unitName = this.unitIdToDisplayName(lang, units, this.measurementUnitForm.controls[formControl].value);
    return unitName ?
      this.translate.instant('ADD_ITEMS.NUMBER_OF_CUSTOM_UNIT', { value: unitName }) : this.translate.instant('ADD_ITEMS.NUMBER_OF_UNITS');
  }

  unitIdToDisplayName(lang, units, unitId) {
    const unit = units.find(u => u.id === unitId);
    return this.getUnitDisplayName(unit, lang);
  }

  getUnitDisplayName(unit: MeasurementUnit, lang: string): string {
    return this.localizedValueUtils.getUnitDisplayName(unit, lang);
  }

  onCatchWeightUnitBlur(event) {
    this.weightUnitBlur.emit(event);
    this.updateStandardUnitLists();
  }

  onNetWeightBlur() {
    this.netWeightBlur.emit();
    this.updateStandardUnitLists();
  }

  onVolumeUnitBlur(event) {
    if (get(event, 'relatedTarget.nodeName', null) !== 'MAT-OPTION') {
      this.measurementUnitForm.controls.volumeUnit.setValue(this.lastValidVolumeUnit?.id ?? '');
    }
    this.updateStandardUnitLists();
  }

  onNetVolumeBlur() {
    this.measurementUnitForm.controls.volumeUnit.updateValueAndValidity();
    this.updateStandardUnitLists();
  }

  filterUnits(value: string, units$: Observable<MeasurementUnit[]>): Observable<MeasurementUnit[]> {
    return this.currentLang$.pipe(
      mergeMap(lang => units$.pipe(
        map(units => {
          if (value) {
            return units.filter(unit => this.getUnitDisplayName(unit, lang).toLowerCase().includes(value.toLowerCase()));
          }
          return units;
        })
      )));
  }

  removeUnknownUnits(units$: Observable<MeasurementUnit[]>): Observable<MeasurementUnit[]> {
    return units$.pipe(
      map(units => {
        const filteredUnits = units;
        return filteredUnits;
      })
    );
  }

  volumeUnitValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control) {
        return this.volumeUnits$.pipe(
          first(),
          map(units => {
            const val = control.value;
            if (!val || val.trim().length === 0) {
              this.lastValidVolumeUnit = null;
              const qty = this.measurementUnitForm?.controls?.netVolume?.value;
              if (!qty || qty.toString().trim().length === 0) {
                return null;
              } else {
                return { invalidVolumeUnit: true };
              }
            } else {
              const selectedUnit = units.find(unit =>
                unit.id.toLowerCase().trim() === control.value.toLowerCase().trim()
              );
              if (selectedUnit) {
                this.lastValidVolumeUnit = selectedUnit;
                return null;
              } else {
                return { invalidVolumeUnit: true };
              }
            }
          }));
      } else {
        return of({ invalidVolumeUnit: true });
      }
    };
  }

  netVolumeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control) {
        const val = control.value;
        const unit = this.measurementUnitForm?.controls?.volumeUnit?.value;
        if ((!val || val.toString().trim().length === 0) && unit?.trim().length > 0) {
          return { invalidVolumeUnit: true };
        }
        return null;
      }
    };
  }

  get countingUnitFormArray(): UntypedFormArray {
    return this.customUnitUtils.getCountingUnitFormArray(this.measurementUnitForm);
  }

  getCountingUnitName(formGroup: number): AbstractControl {
    return this.customUnitUtils.getCountingUnitName(formGroup, this.measurementUnitForm);
  }

  getCountingUnitQty(formGroup: number): AbstractControl {
    return this.customUnitUtils.getCountingUnitQty(formGroup, this.measurementUnitForm);
  }

  getCountingUnitParent(formGroup): AbstractControl {
    return this.customUnitUtils.getCountingUnitParent(formGroup, this.measurementUnitForm);
  }

  standardUnitValidator(formGroup: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control) {
        return this.measurementUnits$.pipe(
          filter(units => units?.length > 0),
          first(),
          map(units => {
            const val = control.value;
            if (!val || val.trim().length === 0) {
              this.lastValidStandardUnitList[formGroup] = null;
              return null;
            } else {

              const selectedUnit = units.find(unit =>
                unit.id.toLowerCase().trim() === control.value.toLowerCase().trim()
              );
              if (selectedUnit) {
                this.lastValidStandardUnitList[formGroup] = selectedUnit;
                return null;
              } else {
                return { invalidStandardUnit: true };
              }
            }
          }));
      } else {
        return of({ invalidStandardUnit: true });
      }
    };
  }

  onStandardUnitBlur(event, formGroup: number) {
  }

  addCountingUnitGroup(name: string, qty: string, parent: string) {
    const index = this.countingUnitFormArray.length;
    this.customUnitUtils.addCountingUnitGroup(this.measurementUnitForm, name, qty, parent, this.isAllowedToEdit, false);
    this.filteredStandardUnitList$.push(this.createStandardUnitsFilter(index));
    this.lastValidStandardUnitList.push(null);
    this.getCountingUnitParent(index).setAsyncValidators(this.standardUnitValidator(index));
    this.getCountingUnitParent(index).updateValueAndValidity();
  }

  getCustomUnitQtyPlaceholder(formGroup: number) {
    return this.customUnitUtils.getCustomUnitQtyPlaceholder(formGroup, this.measurementUnitForm);
  }

  deleteCountingUnitGroup(formGroup: number) {
    this.customUnitUtils.removeCountingQty(formGroup,this.measurementUnitForm)
    this.filteredStandardUnitList$.splice(formGroup, 1);
    this.lastValidStandardUnitList.splice(formGroup, 1);
    this.customUnitUtils.deleteCountingUnitGroup(formGroup, this.measurementUnitForm);
    if (formGroup < this.countingUnitFormArray.length) {
      // update index used for standard unit validators
      for (let i = formGroup; i < this.countingUnitFormArray.length; i++) {
        this.getCountingUnitParent(i).clearAsyncValidators();
        this.getCountingUnitParent(i).setAsyncValidators(this.standardUnitValidator(i));
        this.getCountingUnitParent(i).updateValueAndValidity();
      }
    }
  }

  // recreate lists of available standard units in order to include
  // or remove edited weight or volume units
  updateStandardUnitLists() {
    this.filteredStandardUnitList$ = this.filteredStandardUnitList$.map((list, index) => this.createStandardUnitsFilter(index));
  }

  createCustomRecipeUnits() {
    this.customItemData$.pipe(tap((data)=>{
      if(data.length<1){
        this.saveClicked = true
      }else{
        this.saveClicked = false
      }
    })).subscribe();
    combineLatest(([this.customItemData$.pipe(skip(3),
      timeout({
        each: 5000,
        with: () =>  this.customItemData$.pipe(first())
      }),
      map((items) => items)), this.measurementUnits$])).pipe(
      filter(([data, units]) => !!data && data.length > 0 && !!units && units.length > 0),
      map(([data, units]) => data[0]),
      tap((data)=>{
        if(data){
          this.saveClicked = true
      }
    })
    ).subscribe(data => {
      data.recipeUnits.forEach(unit => {
        if (unit.custom) {
          this.addCountingUnitGroup(unit.custom.name, unit.custom.qtyInParent, unit.custom.parentUnitId);
        }
      });
    });
  }
}

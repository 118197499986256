import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsMigrationEligibility } from '../models/inventory-migration.model';

@Component({
  selector: 'gfs-inventory-migration-configuration-modal',
  templateUrl: './inventory-migration-configuration-modal.component.html',
  styleUrls: ['./inventory-migration-configuration-modal.component.scss']
})
export class InventoryMigrationConfigurationModalComponent {
  viewmodel = {
    migrateWorksheet: this.data.usMigrationEligibility.hasWorksheet,
    migrateCustomItems: this.data.usMigrationEligibility.hasUdi
  };

  constructor(
    public dialogRef: MatDialogRef<InventoryMigrationConfigurationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { usMigrationEligibility: UsMigrationEligibility }
  ) { }

  isWorksheetsCheckboxDisabled(): boolean {
    return !this.data.usMigrationEligibility.hasWorksheet;
  }

  isCustomItemsCheckboxDisabled(): boolean {
    return !this.data.usMigrationEligibility.hasUdi;
  }


  canSubmit(): boolean {
    return [this.viewmodel.migrateWorksheet, this.viewmodel.migrateCustomItems].some(isTrue => isTrue);
  }

  submit() {
    this.dialogRef.close({
      options: this.viewmodel,
      escapeWorkflow: false
    });
  }

  closeModal(escapeWorkflow: boolean = false) {
    this.dialogRef.close({
      options: {
        migrateWorksheet: false,
        migrateCustomItems: false
      },
      escapeWorkflow
    });
  }
}

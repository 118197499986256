import { Action } from '@ngrx/store';
import { CountableItem, WorksheetSummary } from '@gfs/shared-models';

export enum ActionTypes {
  DownloadInventoryValueReportAttempt = '[Reports] Download Report Attempt',
  DownloadInventoryValueReportSuccess = '[Reports] Download Report Success',
  DownloadInventoryValueReportError = '[Reports] Download Report Error',
  DownloadMultiUnitReportAttempt = '[Reports] Download Multi Unit Report Attempt',
  DownloadMultiUnitReportSuccess = '[Reports] Download Multi Unit Report Success',
  DownloadMultiUnitReportError = '[Reports] Download Multi Unit Report Error'
}


export class DownloadInventoryValueReportAttempt implements Action {
  readonly type = ActionTypes.DownloadInventoryValueReportAttempt;
  constructor(public payload: {
    worksheetSummary: WorksheetSummary,
    listBy?: string,
    worksheetName: string,
    locale?: string,
    exportType: string,
  }) {}
}

export class DownloadInventoryValueReportSuccess implements Action {
  readonly type = ActionTypes.DownloadInventoryValueReportSuccess;
}

export class DownloadInventoryValueReportError implements Action {
  readonly type = ActionTypes.DownloadInventoryValueReportError;
  constructor(public payload: string) {}
}

export class DownloadMultiUnitReportAttempt implements Action {
  readonly type = ActionTypes.DownloadMultiUnitReportAttempt;
  constructor(public payload: {
    dateRange: { start: any, end: any },
    locale?: string,
  }) {}
}

export class DownloadMultiUnitReportSuccess implements Action {
  readonly type = ActionTypes.DownloadInventoryValueReportSuccess;
}

export class DownloadMultiUnitReportError implements Action {
  readonly type = ActionTypes.DownloadMultiUnitReportError;
  constructor(public payload: string) {}
}

export type ActionUnion =
  DownloadInventoryValueReportAttempt |
  DownloadInventoryValueReportSuccess |
  DownloadInventoryValueReportError |
  DownloadMultiUnitReportAttempt |
  DownloadMultiUnitReportSuccess |
  DownloadMultiUnitReportError;

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CountableItem,
  WorksheetItem
} from '@gfs/shared-models';
import { GetProductInfoAttempt, IFeatureStateFacade } from '@gfs/store/feature/add-items';
import { Store } from '@ngrx/store';

@Component({
  selector: 'gfs-edit-item-dialog',
  templateUrl: './edit-item-dialog.component.html',
  styleUrls: ['./edit-item-dialog.component.scss']
})
export class EditItemDialogComponent implements OnInit {
  item: CountableItem;
  currentStorageAreaId: string;
  worksheetItemToEdit: WorksheetItem;
  isGfsItem = false;
  isManage = false;
  focusSection = null;

  constructor(
    private store: Store<IFeatureStateFacade>,
    public dialogRef: MatDialogRef<EditItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditItemDialogComponent,
  ) { }

  ngOnInit() {
    this.item = this.data.item;
    this.worksheetItemToEdit = this.data.worksheetItemToEdit;
    this.currentStorageAreaId = this.data.currentStorageAreaId;
    this.isGfsItem = !!this.item.gfsItem;

    if (this.isGfsItem) {
      this.store.dispatch(new GetProductInfoAttempt(this.item.gfsItem.id));
    }
    if (this.data.isManage) {
      this.isManage = this.data.isManage;
    }
    this.focusSection = this.data.focusSection;
  }

  close() {
    // WorksheetEffects.itemChangedNotifier$
    // .next({
    //   storageAreaId: this.currentStorageAreaId,
    //   worksheetItemId: this.data.worksheetItemToEdit.id
    // })
    this.dialogRef.close();

  }
}
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { InventoryConstants } from '@gfs/constants';

@Component({
  selector: 'gfs-customer-unit-searchbar',
  templateUrl: './customer-unit-searchbar.component.html',
  styleUrls: ['./customer-unit-searchbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerUnitSearchbarComponent {

  @Output()
  searchTextEmitter = new EventEmitter<string>();

  maxInputLength = InventoryConstants.MAX_INPUT_LENGTH;
  value: string;

  search() {
    this.searchTextEmitter.emit(this.value);
  }

  clearSearch() {
    this.value = '';
    this.search();
  }
}

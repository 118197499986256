import { Injectable } from '@angular/core';
import { IAppConfig } from './IAppConfig';
import configData from './config.json';

@Injectable({
  providedIn: 'root'
})
export class MockAppConfigService {
  static config: IAppConfig = configData;

  config: IAppConfig;

  static initializeSettings(config: IAppConfig) {
    MockAppConfigService.config = config;
  }

  public getSettings(): IAppConfig {
    return this.config || MockAppConfigService.config;
  }

  public getLocalStorageValue(): any {
    return {};
  }

  initializeSettings(config: IAppConfig) {
    this.config = config;
  }

  public load() {
    // no-op
  }
}
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { Constants } from '@gfs/constants';
import { Router } from '@angular/router';
import { AppConfigService } from '@gfs/shared-services';

interface Option {
  id: string;
  display: string;
  icon: string;
  action: () => void;
  isDisplayed: () => boolean;
}

@Component({
  selector: 'gfs-manage-menu',
  templateUrl: './manage-menu.component.html',
  styleUrls: ['./manage-menu.component.scss']
})
export class ManageMenuComponent {

  isMobile$ = this.store.select(state => state.layout.isMobile);
  isOnline$ = this.store.select(state => state.network.isOnline);
  selectedOption = '';
  options: Option[] = [{
    id: 'manage-nongfs-items-menu-button',
    icon: '',
    display: 'MANAGE_NONGFS_ITEMS.MANAGE_NONGFS_ITEMS',
    action: () => this.goToCustomItems(),
    isDisplayed: () => true
  }, {
    id: 'manage-general-ledger-menu-button',
    icon: '',
    display: 'GENERAL_LEDGER.MANAGE_GENERAL_LEDGER',
    action: () => this.goToGeneralledger(),
    isDisplayed: () => this.displayGeneralLedger()
  }];

  constructor(
    public translate: TranslateService,
    private store: Store<AppState>,
    public router: Router,
    private configService: AppConfigService
  ) { }

  getOptions() {
    return this.options.filter(option => option.isDisplayed());
  }

  getOptionLabel() {
    return (option: Option) => this.translate.instant(option.display);
  }

  goToCustomItems(): void {
    this.router.navigateByUrl(Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATHNAME);
  }

  displayGeneralLedger() {
    return this.configService.getSettings().FF_GENERAL_LEDGER;
  }

  goToGeneralledger(): void {
    this.router.navigateByUrl(Constants.SharedFeaturePaths.GENERAL_LEDGER_ITEMS_PATHNAME);
  }
}

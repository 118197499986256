import { formatDate, registerLocaleData } from '@angular/common';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import {
  ConfirmationModalComponent,
  InventoryWorksheetWizardComponent,
  LoadingModalComponent,
  LegacyCustomerMigrationFacade
} from '@gfs/shared-components';
import { IAppContext, Worksheet } from '@gfs/shared-models';
import {
  AppConfigService,
  GetMostRecentInventoryId,
  IAppConfig,
  InjectionTokens,
  InventoryService,
  InventoryUtilsService
} from '@gfs/shared-services';
import {
  CopyWorksheetAttempt,
  DeleteWorksheetAttempt,
  GetCustomGuidesAttempt,
  SelectWorksheet
} from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { concatMap, filter, first, map } from 'rxjs/operators';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  readonly openStatus: string = 'open';
  readonly closedStatus: string = 'closed';

  isMobile$ = this.store.select(state => state.layout.isMobile);
  currentLang$ = this.store.select(state => state.layout.language);

  allWorksheetSummaries$: Observable<Worksheet[]> = this.appContext.customerPK.pipe(
    concatMap(customerPK => this.inventorySvc.getWorksheetSummaries(customerPK)),
    map(InventoryUtilsService.sortSummaries),
  );

  activeWorksheetSummaries$ = this.allWorksheetSummaries$.pipe(
    map(value => value.filter(ws => ws.deleted === false)),
  );

  isInProgressSummaryPresent$: Observable<boolean> = this.activeWorksheetSummaries$.pipe(
    map((worksheets: Worksheet[]) => this.isInProgressSummaryPresent(worksheets))
  );

  inventoryButtonText$: Observable<string> = this.isInProgressSummaryPresent$.pipe(
    map((isInProgressSummaryPresent: boolean) => this.getInventoryButtonText(isInProgressSummaryPresent))
  );

  worksheetAverageInventoryValue$ = this.store.select(state => state.worksheets.averageInventoryValue);
  isCreatingWorksheet$ = this.store.select(state => state.worksheets.isCreating);
  displayedColumns: string[] = ['Description', 'Created', 'Modified', 'Status'];
  customerPk$ = this.store.select(state => state.auth.pk);
  isClosingWorksheet$ = this.store.select(state => state.worksheets.isClosing);
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryCounts;
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;
  reportingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.reports.inventoryValue;
  reportsUrl = InventoryConstants.REPORTS_PATH;
  appSettings: IAppConfig;

  hasWorksheetSummaries$ = this.activeWorksheetSummaries$.pipe(
    filter(x => x.length > 0),
    first()
  ).pipe(map(x => x.length > 0));

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private configService: AppConfigService,
    private inventorySvc: InventoryService,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private migrationFacade: LegacyCustomerMigrationFacade,
    private router: Router
  ) {
    this.appSettings = this.configService.getSettings();
  }

  ngOnInit(): void {
    registerLocaleData(locale_fr_CA);
  }

  onArrow() {
    document.getElementById('search-box').focus();
  }

  onGetStartedClick(resetCurrentSheet: boolean) {
    InventoryWorksheetWizardComponent.openInventoryWorksheetWizard(
      resetCurrentSheet,
      this.store,
      this.dialog,
      this.migrationFacade,
      this.router);
  }

  onTakeInventoryClick(): void {
    this.allWorksheetSummaries$
      .pipe(
        first(),
        filter((worksheets: Worksheet[]) => worksheets.length > 0),
        map((worksheets: Worksheet[]) => {
          const mostRecentWorksheetId = GetMostRecentInventoryId(worksheets);
          return worksheets.find((worksheet: Worksheet) => worksheet.id === mostRecentWorksheetId);
        })
      ).subscribe((mostRecentWorksheet: Worksheet) => {
        if (mostRecentWorksheet.status === this.closedStatus) {
          this.openLoadingModal();
          this.copyLatestWorksheet(mostRecentWorksheet);
        } else if (mostRecentWorksheet.status === this.openStatus) {
          this.navigateToWorksheet(mostRecentWorksheet.id);
        }
      });
  }

  private openLoadingModal() {
    this.dialog.open(LoadingModalComponent, {
      id: InventoryConstants.LOADING_MODAL_ID,
      panelClass: 'recipe-mat-dialog',
      disableClose: true
    });
  }

  copyLatestWorksheet(worksheetToCopy: Worksheet) {
    this.store.dispatch(new CopyWorksheetAttempt(worksheetToCopy.id));
  }

  getStatus(status: string): string {
    return status === this.openStatus ? this.translateService.instant('INVENTORY.IN_PROGRESS') : this.translateService.instant('INVENTORY.DONE');
  }

  translateName(name: string): string {
    if (name.toLowerCase() === 'Untitled Inventory'.toLowerCase() || name.toLowerCase() === `Inventaire sans titre`.toLowerCase()) {
      return this.translateService.instant('INVENTORY_WORKSHEET.UNTITLED_INVENTORY');
    }
    return name;
  }

  getSummaryTableDateTime(date: string, currentLang: string): string {
    if (currentLang === InventoryConstants.LANGUAGES.ENGLISH) {
      const dateString = formatDate(date, 'MMM. d, y', currentLang);
      const timeString = formatDate(date, 'shortTime', currentLang);

      return `${dateString} at ${timeString}`;
    } else if (currentLang === InventoryConstants.LANGUAGES.FRENCH) {
      const dateString = formatDate(date, 'mediumDate', currentLang);
      const timeString = formatDate(date, 'HH\'h\'mm', currentLang);

      return `${dateString} à ${timeString}`;
    }
  }

  getCustomGuides() {
    this.store.dispatch(new GetCustomGuidesAttempt());
  }

  navigateToWorksheet(worksheetId: string) {
    this.store.dispatch(new SelectWorksheet({ worksheetId, shouldNavigate: true }));
  }

  deleteWorksheet(row: Worksheet): void {
    const title = this.translateService.instant(
      'INVENTORY.DELETE_MODAL.DIALOG_TITLE',
      { worksheetName: row.name }
    );
    const subtitle = this.translateService.instant('INVENTORY.DELETE_MODAL.DIALOG_SUBTITLE');
    const submitButtonLabel = this.translateService.instant('INVENTORY.DELETE_MODAL.SUBMIT_BUTTON');
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        returnData: row,
        title,
        subtitle,
        cancelButtonId: 'cancel-delete-worksheet-button',
        submitButtonId: 'delete-worksheet-button',
        submitButtonAriaLabel: submitButtonLabel,
        submitButtonLabel
      },
      width: '604px',
      height: '253px'
    });

    dialogRef.afterClosed()
      .subscribe((worksheet: Worksheet) => {
        if (worksheet) {
          this.store.dispatch(new DeleteWorksheetAttempt(worksheet));
        }
      });
  }

  pricingAuthHandler(isAuth: boolean) {
    if (isAuth && !this.displayedColumns.includes('Value')) {
      this.displayedColumns.splice(1, 0, 'Value');
    } else {
      const colIndex = this.displayedColumns.findIndex(col => col === 'Value');
      if (colIndex >= 0) { this.displayedColumns.splice(colIndex, 1); }
    }
  }

  tripleDotAuthHandler(isAuth: boolean) {
    if (isAuth && !this.displayedColumns.includes('tripleDot')) {
      this.displayedColumns.push('tripleDot');
    } else {
      const colIndex = this.displayedColumns.findIndex(col => col === 'tripleDot');
      if (colIndex >= 0) { this.displayedColumns.splice(colIndex, 1); }
    }
  }

  isInProgressSummaryPresent(worksheets: Worksheet[]): boolean {
    return worksheets.some(summary => summary.status === this.openStatus);
  }

  getInventoryButtonText(isInProgressSummaryPresent: boolean): string {
    return isInProgressSummaryPresent
      ? 'INVENTORY.CONTINUE_IN_PROGRESS'
      : 'INVENTORY.START_NEW';
  }

  isInventoryInProcess(status: string): boolean {
    return status === this.openStatus;
  }

  getInProcessInventoryToolTip(): string {
    return this.translateService.instant('INVENTORY.IN_PROGRESS_TOOLTIP');
  }

  canDeleteInventory(worksheet: Worksheet): boolean {
    return !worksheet.isActiveInventory;
  }
}

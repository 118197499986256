import { HttpErrorResponse } from '@angular/common/http';
import { CountableItem, CustomItem, CustomItemUpdate, GeneralItem, GeneralItemUpdate, WorksheetItemConfigDTO } from '@gfs/shared-models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetInventoryItemsAttempt = '[Inventory Items] Get Inventory Items Attempt',
  GetInventoryItemsSuccess = '[Inventory Items] Get Inventory Items Success',
  GetInventoryItemsError = '[Inventory Items], Get Inventory Items Error',
  GetGeneralItemsAttempt = '[General Items] Get General Items Attempt',
  GetGeneralItemsSuccess = '[General Items] Get General Items Success',
  GetGeneralItemsError = '[General Items], Get General Items Error',
  UpdateGeneralItemsAttempt = '[General Items], Update General Items Attempt',
  UpdateGeneralItemsSuccess = '[General Items], Update General Items Success',
  UpdateGeneralItemsError = '[General Items] Update General Items Error',
  GetCustomItemSuccess = '[Custom Items], Get Custom Items Success',
  GetCustomItemError = '[Custom Items], Get Custom Items Error',
  AddCountableItemsToWorksheet = '[General Items], Add Countable Items To Worksheet',
  UpdateCustomItemsAttempt = '[Custom Items] Update Custom Items Attempt',
  UpdateCustomItemsSuccess = '[Custom Items] Update Custom Items Success',
  UpdateCustomItemsError = '[Custom Items] Update Custom Items Error',
  ClearInventoryItems = '[Inventory Items] Clear inventory items',
  SetCountableItem = '[Inventory Items] Set Countable Item Value'

}

export class SetCountableItem implements Action {
  readonly type = ActionTypes.SetCountableItem;
  constructor(public value: WorksheetItemConfigDTO) { }
}

export class GetInventoryItemsAttempt implements Action {
  readonly type = ActionTypes.GetInventoryItemsAttempt;
  constructor(public payload: string[]) { }
}

export class GetInventoryItemsSuccess implements Action {
  readonly type = ActionTypes.GetInventoryItemsSuccess;
  constructor(public payload: { items: CountableItem[] }) { }
}

export class GetInventoryItemsError implements Action {
  readonly type = ActionTypes.GetInventoryItemsError;
  constructor(public payload: string) { }
}

export class AddCountableItemsToWorksheet implements Action {
  readonly type = ActionTypes.AddCountableItemsToWorksheet;
  constructor(public payload: CountableItem[]) { }
}

export class GetGeneralItemsAttempt implements Action {
  readonly type = ActionTypes.GetGeneralItemsAttempt;
  constructor(public payload: { ids: string[], includeDeleted: boolean }) { }
}

export class GetGeneralItemsSuccess implements Action {
  readonly type = ActionTypes.GetGeneralItemsSuccess;
  constructor(public payload: { items: CountableItem[], includeDeleted: boolean }) { }
}

export class GetGeneralItemsError implements Action {
  readonly type = ActionTypes.GetGeneralItemsError;
  constructor(public error: HttpErrorResponse) { }
}
export class GetCustomItemsSuccess implements Action {
  readonly type = ActionTypes.GetCustomItemSuccess;
  constructor(public payload: CountableItem[]) { }
}

export class GetCustomItemsError implements Action {
  readonly type = ActionTypes.GetCustomItemError;
  constructor(public error: HttpErrorResponse) { }
}

export class UpdateCustomItemsAttempt implements Action {
  readonly type = ActionTypes.UpdateCustomItemsAttempt;
  constructor(public payload: { customItemId: string, generalItemId: string, customItemPatch: CustomItemUpdate }) { }
}

export class UpdateCustomItemsSuccess implements Action {
  readonly type = ActionTypes.UpdateCustomItemsSuccess;
  constructor(public payload: { customItem: CustomItem, generalItemId: string }) { }
}

export class UpdateCustomItemsError implements Action {
  readonly type = ActionTypes.UpdateCustomItemsError;
  constructor(public error: HttpErrorResponse) { }
}

export class UpdateGeneralItemsAttempt implements Action {
  readonly type = ActionTypes.UpdateGeneralItemsAttempt;
  constructor(public payload: { generalItemId: string, generalItemPatch: GeneralItemUpdate }) { }
}

export class UpdateGeneralItemsSuccess implements Action {
  readonly type = ActionTypes.UpdateGeneralItemsSuccess;
  constructor(public payload: { generalItem: GeneralItem }) { }
}

export class UpdateGeneralItemsError implements Action {
  readonly type = ActionTypes.UpdateGeneralItemsError;
  constructor(public error: HttpErrorResponse) { }
}

export class ClearInventoryItems implements Action {
  readonly type = ActionTypes.ClearInventoryItems;
  constructor() { }
}

export type ActionUnion =
  GetInventoryItemsAttempt |
  GetInventoryItemsSuccess |
  GetInventoryItemsError |
  AddCountableItemsToWorksheet |
  GetGeneralItemsAttempt |
  GetGeneralItemsError |
  GetGeneralItemsSuccess |
  GetCustomItemsSuccess |
  GetCustomItemsError |
  UpdateCustomItemsAttempt |
  UpdateCustomItemsSuccess |
  UpdateCustomItemsError |
  UpdateGeneralItemsAttempt |
  UpdateGeneralItemsSuccess |
  UpdateGeneralItemsError |
  ClearInventoryItems |
  SetCountableItem
  ;
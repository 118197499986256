<div class="navigation-wrapper">
  <nav
    mat-tab-nav-bar
    disableRipple="true"
    [ngClass]="{ 'hidden-links': isCustomerUnitSelectionPage }"
  >
    <a
      mat-tab-link
      *ngFor="let tab of subHeaderLinks; let i = index"
      class="{{tab.text}}-link"
      [ngClass]="{disabled: !(isOnline$ | async)}"
      [active]="tab.isActive"
      [routerLink]="(isOnline$ | async) ? tab.link : null"
      [tabindex]="i"
    >
      {{ tab.text | translate }}
    </a>
    <gfs-manage-menu *ngIf="displayManageMenu | async"></gfs-manage-menu>
  </nav>
  <div class="action-wrapper" *ngxPermissionsOnly="permissionRoles.resetWorksheet">
    <gfs-inventory-tools></gfs-inventory-tools>
  </div>
</div>

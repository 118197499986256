import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Entitlement, RolePermission } from '@gfs/shared-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { InjectionTokens } from '../../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getUserEntitlements(): Observable<Entitlement[]> {
    return this.http.get<Entitlement[]>(`${this.baseUrl}/user-entitlements`)
      .pipe(
        map((x) => x.map(y => ({ ...y, slug: Md5.hashStr(JSON.stringify(y)) } as Entitlement))),
      );
  }

  getUserRolePermissions(): Observable<RolePermission[]> {
    return this.http.get<RolePermission[]>(`${this.baseUrl}/role-permissions`);
  }
}

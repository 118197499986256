import { ValidatorFn } from '@angular/forms';
import { duplicateNameValidator } from './duplicate-name.validator';
import { greaterThanValidator } from './greater-than.validator';
import { noLeadingOrTrailingSpaceValidator } from './no-leading-or-trailing-space.validator';
import { notEmptyValidator } from './not-empty.validator';

export const GfsValidators = {
    greaterThan: (value: number): ValidatorFn =>
        greaterThanValidator(value),

    duplicateName: (
        forbiddenNames: string[],
        exceptionToForbidden: string = null
    ): ValidatorFn =>
        duplicateNameValidator(forbiddenNames, exceptionToForbidden),

    noLeadingOrTrailingSpace: (): ValidatorFn =>
        noLeadingOrTrailingSpaceValidator(),

    notEmpty: (): ValidatorFn =>
        notEmptyValidator()
};

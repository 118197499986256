import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InventoryConstants } from '@gfs/constants';
import { CustomerPK, CustomItem, CustomItemUpdate, IAppContext, PurchaseUnit } from '@gfs/shared-models';
import { forkJoin, merge, Observable } from 'rxjs';
import { concatMap, first, map, toArray } from 'rxjs/operators';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class CustomItemService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getCustomItems(
    ids: string[],
    includeDeleted: boolean,
    customerPK: CustomerPK
  ): Observable<CustomItem[]> {
    if (!ids) {
      return this.http.get<CustomItem[]>(`${this.baseUrl}/custom-items`,
        {
          params: {
            includeDeleted: includeDeleted.toString(),
            ...customerPK
          }
        });
    }
    const customItems = ids.slice();
    const requests: Observable<CustomItem[]>[] = [];
    while (customItems.length) {
      const currentIds = customItems.splice(0, InventoryConstants.MAX_INVENTORY_PRODUCTS).join(',');
      const options = { params: { ids: currentIds, includeDeleted: includeDeleted.toString(), ...customerPK } };
      requests.push(this.http.get<CustomItem[]>(`${this.baseUrl}/custom-items`, options));
    }

    return forkJoin(requests).pipe(
      concatMap(customItemResults => merge(...customItemResults)),
      map(customItem => ({ ...customItem, itemType: 'CUSTOM' } as CustomItem)),
      toArray(),
    );
  }

  createCustomItem(
    customerPK: CustomerPK,
    supplierItemCode: string,
    supplierId: string,
    description: string,
    purchaseUnit: PurchaseUnit,
    addToWorksheet: boolean,
    storageAreaId: string
  ): Observable<CustomItem> {
    return this.http.post<CustomItem>(`${this.baseUrl}/custom-items`,
      {
        customerPK,
        supplierItemCode,
        supplierId,
        description,
        purchaseUnit,
        addToWorksheet,
        storageAreaId
      });
  }

  updateCustomItem(
    customItemId: string,
    fields: CustomItemUpdate
  ): Observable<CustomItem> {
    return this.http.patch<CustomItem>(`${this.baseUrl}/custom-items/${customItemId}`, fields);
  }

  deleteCustomItem(
    customItemId: string
  ): Observable<CustomItem> {
    return this.http.delete<CustomItem>(`${this.baseUrl}/custom-items/${customItemId}`);
  }

  deleteGroupCustomItem(
    customItemIdList: CustomItem[]
  ): Observable<CustomItem[]> {
    const customItemIdListString = customItemIdList.map((data)=>data.id)
    return this.http.post<CustomItem[]>(`${this.baseUrl}/custom-items/bulk/delete`,{
      customItemIdList : customItemIdListString 
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { ItemCategory, LocalizedValue } from '@gfs/shared-models';
import { sort as sortFn } from '@gfs/shared-services';
import { AppState } from '@gfs/store/inventory/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { filter, map, tap, withLatestFrom, first } from 'rxjs/operators';
import { AssignAccountsDropdownOption, AssignAccountsViewModel } from '../assign-accounts/assign-accounts.component';
import {
  MaintainAccountsTableComponentViewModel
} from '../maintain-accounts/maintain-accounts-table/maintain-accounts-table.component';
import {
  MaintainAccountsInputViewModel
} from '../maintain-accounts/manage-accounts-input/maintain-accounts-input.component';
import { GeneralLedgerAccountInput } from '../models/general-ledger-account-input.model';
import {
  GeneraLedgerAccountPatch,
  GeneralLedgerAccountPage,
  ItemCategoryGLAccount,
  GeneralLedgerAccount,
  UpdateItemCategoryGLAccountInput, GeneralLedgerAccountDelete
} from '../models/general-ledger.model';
import {
  AddAccount, DeleteGeneralLedger,
  LoadGeneralLedger,
  UpdateAccountPopUp,
  UpdateItemCategoryAccount
} from '../state/general-ledger.actions';

export interface ComponentState {
  generalLedgerAccountPage: GeneralLedgerAccountPage;
  itemCategories: ItemCategory[];
  language: string;
  isSaving: boolean;
  isLoading: boolean;
}

@Component({
  selector: 'gfs-general-ledger',
  templateUrl: './general-ledger.component.html'
})
export class GeneralLedgerComponent implements OnInit {

  sort = sortFn;

  constructor(
    public store: Store<AppState>,
    public loadingOverlay: LoadingSpinnerOverlayService
  ) { }

  componentState = new BehaviorSubject<ComponentState>(undefined);
  viewModelComponentState = this.componentState
    .pipe(filter(state => !!state?.generalLedgerAccountPage));

  buildComponentState$ = this.store
    .select(state => ({
      generalLedgerAccountPage: state.generalLedger.generalLedgerAccountPage,
      itemCategories: state.generalLedger.itemCategories,
      language: state.layout.language,
      isSaving: state.generalLedger.isSaving,
      isLoading: state.generalLedger.isLoading
    } as ComponentState))
    .pipe(
      withLatestFrom(this.componentState),
      filter(([nextState, currentState]) => {
        const result = !this.areEqual(nextState, currentState);
        return result;
      }),
      tap(([nextState]) => this.componentState.next(nextState))
    );

  maintainAccountsInputViewModel$: Observable<MaintainAccountsInputViewModel> = this.viewModelComponentState
    .pipe(
      map(state => ({
        isSaving: state.isSaving,
        accounts: state.generalLedgerAccountPage.generalLedgerSection.glAccounts
      }))
    );

  maintainAccountsViewModel$: Observable<MaintainAccountsTableComponentViewModel> = this.viewModelComponentState
    .pipe(map(state => {
      const section = state.generalLedgerAccountPage.generalLedgerSection;
      const glAccounts = this.getSortedAccounts(section.glAccounts);
      return { maintainGeneralLedgerSection: { ...section, glAccounts } };
    }));

  assignAccountsViewModel$: Observable<AssignAccountsViewModel> = this.viewModelComponentState
    .pipe(map(state => {
      const assignAccountsTableData = this.buildAssignAccountsTableData(state);
      const result: AssignAccountsViewModel = {
        assignAccountsTableData,
        dropdownOptions: this.getDropdownOptions(state.generalLedgerAccountPage.generalLedgerSection.glAccounts)
      };
      return result;
    }));

  isReady$: Observable<boolean> = forkJoin([
    this.maintainAccountsInputViewModel$.pipe(first()),
    this.maintainAccountsViewModel$.pipe(first()),
    this.assignAccountsViewModel$.pipe(first())
  ]).pipe(
    tap(() => this.loadingOverlay.hide()),
    map(() => true)
  );



    ngOnInit(): void {
        this.loadingOverlay.show();
        this.store.dispatch(new LoadGeneralLedger());
    }

    addAccount(model: GeneralLedgerAccountInput): void {
        this.store.dispatch(new AddAccount(model));
    }



  updateAccount(model: GeneraLedgerAccountPatch): void {
    this.store.dispatch(new UpdateAccountPopUp(model));
  }



  deleteGeneralAccount(model: GeneralLedgerAccountDelete){
      this.store.dispatch(new DeleteGeneralLedger(model) );
  }

  updateItemCategoryGLAccount(model: UpdateItemCategoryGLAccountInput): void {
    this.store.dispatch(new UpdateItemCategoryAccount(model));
  }


  areEqual(nextState: any, currentState: any): boolean {
    return JSON.stringify(nextState) === JSON.stringify(currentState);
  }




  getDropdownOptions(accounts: GeneralLedgerAccount[]): AssignAccountsDropdownOption[] {
    const sortedAccounts = this.getSortedAccounts(accounts);
    const options = sortedAccounts.map(account => ({ id: account.id, value: account.glCode, entityType: account.customerPK.entityType }));
    return options;
  }

  getSortedAccounts(accounts: GeneralLedgerAccount[]): GeneralLedgerAccount[] {
    return this.sort(accounts, account => account.glCode);
  }

  buildAssignAccountsTableData(state: ComponentState) {
    const result = state.generalLedgerAccountPage.itemCategorySection.itemCategories
      .map((itemCategoryGLAccount) => {
        const itemCateogory = this.getEntityById(itemCategoryGLAccount.itemCategoryId, state.itemCategories);
        const localizedItemCategoryName = this.getLocalizedName(itemCateogory, state.language);
        const isCustomItemCategory = this.isCustomItemCategory(itemCategoryGLAccount);
        const accountEntity = this.getEntityById(itemCategoryGLAccount.generalLedgerAccountId, state.generalLedgerAccountPage.generalLedgerSection.glAccounts);

        return {
          itemCategoryId: itemCategoryGLAccount.itemCategoryId,
          isCustomItemCategory,
          localizedItemCategoryName,
          accountId: itemCategoryGLAccount.generalLedgerAccountId,
          accountName: accountEntity?.glCode,
          fromFamily : itemCategoryGLAccount.fromFamily
        };
      });
    return this.sort(result, model => model.localizedItemCategoryName);
  }

  getLocalizedName<TModel extends { name: LocalizedValue[] }>(entity: TModel, language: string): string {
    return entity.name.find(aa => aa.languageCode === language)?.value ?? entity.name[0].value;
  }

  isCustomItemCategory(itemCategoryGLAccount: ItemCategoryGLAccount): boolean {
    return 0 !== itemCategoryGLAccount.itemCategoryId.indexOf('gfs_default');
  }

  getEntityById<TModel extends { id: string }>(id: string, entities: TModel[]): TModel {
    return entities.find(entity => entity.id === id);
  }
}

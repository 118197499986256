import { Constants } from '@gfs/constants';
import { appConfigCanActivateFn, unitSelectionGuardFn } from '@gfs/shared-services';
import { GeneralLedgerComponent } from './general-ledger/general-ledger.component';
import { OktaAuthGuard } from '@okta/okta-angular';

export const GeneralLedgerRoutes = [{
    path: Constants.SharedFeaturePaths.GENERAL_LEDGER_ITEMS_PATH,
    canActivate: [
        OktaAuthGuard,
        unitSelectionGuardFn,
        appConfigCanActivateFn
    ],
    data: {
        featureFlags: [Constants.FeatureFlags.GeneralLedger]
    },
    component: GeneralLedgerComponent,
}];

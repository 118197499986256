import { AppFeatureFlags } from '@gfs/shared-services';
import { Observable, of } from 'rxjs';
import { CustomerPK, Entitlement, User } from '../user.model';
import { Authorizations } from '@gfs/store/common';

export interface IAppContext {
  customerPK: Observable<CustomerPK>;
  entitlements: Observable<Entitlement[]>;
  title: Observable<string>;
  isMobile: Observable<boolean>;
  language: Observable<string>;
  headerHeight: Observable<number>;
  featureFlags?: AppFeatureFlags;
  isOnline$: Observable<boolean>;
  authorizations$: Observable<Authorizations>;
  user$: Observable<User>;
}

export const mockAppContext: IAppContext = {
  customerPK: of({
    customerId: '1',
    division: '2',
    channel: '3',
    salesOrg: '4',
    entityType: '',
  }),
  entitlements: of([]),
  headerHeight: of(0),
  isMobile: of(false),
  language: of('en_CA'),
  title: of('1'),
  featureFlags: {
    Recipe: {
      EnableUserRecipeImages: true
    },
    Inventory: {
      EnableReports: true,
      MigrateFromInventoryManager: true,
      UseWorksheetV2: true
    },
    System: {
      UseStaticSAPUnits: true
    }
  },
  isOnline$: of(true),
  authorizations$: of({}),
  user$: of(null)
};

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';
import { ConfirmationModalComponent } from '@gfs/shared-components';
import { StorageArea } from '@gfs/shared-models';
import { StorageAreaUtilsService } from '@gfs/shared-services';
import * as workSheetActions from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-storage-area-list',
  templateUrl: './storage-area-list.component.html',
  styleUrls: ['./storage-area-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageAreaListComponent implements OnInit {

  @Output() editStorageArea = new EventEmitter<StorageArea>();

  worksheet$ = this.store.select(state => state.worksheets.worksheets[state.worksheets.selected]);
  assignedStorageAreas$: Observable<StorageArea[]>;
  unAssignedStorageArea$: Observable<StorageArea>;
  isOnline$ = this.store.select(state => state.network.isOnline);
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;

  editButtonIcon = 'edit-icon';
  deleteButtonIcon = 'trash-icon';

  constructor(
    private store: Store<AppState>,
    private storageAreaUtils: StorageAreaUtilsService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.assignedStorageAreas$ = this.storageAreaUtils.getStorageAreasMinusUnassigned(this.worksheet$);
    this.unAssignedStorageArea$ = this.storageAreaUtils.getUnassignedStorageArea(this.worksheet$);
  }

  getTranslation(name: string) {
    return this.storageAreaUtils.getTranslation(name);
  }

  getRowItemCount(storageArea: StorageArea) {
    return this.storageAreaUtils.getRowItemCount(storageArea);
  }

  drop(event: CdkDragDrop<string[]>) {
    combineLatest([this.worksheet$, this.assignedStorageAreas$]).pipe(
      first(),
    ).subscribe(([ws, assignedStorageAreas]) => {
      const newOrder = assignedStorageAreas.map(sa => sa.id);
      const cutOut = newOrder.splice(event.previousIndex, 1)[0];
      newOrder.splice(event.currentIndex, 0, cutOut);
      this.store.dispatch(new workSheetActions.PatchWorksheetAttempt({
        worksheetId: ws.id,
        fields: { storageAreaOrder: newOrder }
      }));
    });
    this.worksheet$.pipe(first());
  }

  storageAreaClicked(storageArea: StorageArea) {
    const name = storageArea.name ? storageArea.name.replace(' ', '-') + '-' : '';
    const id = 'storage-area-item-panel-' + name + storageArea.id;
    const el = document.getElementById(id);
    const scrollContainer = document.getElementById('mat-sidenav-content');

    this.store.dispatch(new workSheetActions.SetStorageAreaExpandStatus({ status: true, areaId: storageArea.id }));

    scrollContainer.scrollTop = el.offsetTop;
  }

  clearFocus() {
    const elements = document.getElementsByClassName('icon-hover-bkg');
    Array.prototype.forEach.call(elements, (el: HTMLElement) => {
      el.style.visibility = 'hidden';
    });
  }

  onFocus(storageAreaId: string, storageAreaName: string) {
    this.clearFocus();
    this.setButtonVisibility(storageAreaId, storageAreaName, 'delete', 'visible');
    this.setButtonVisibility(storageAreaId, storageAreaName, 'edit', 'visible');
  }

  onBlur(storageAreaId: string, storageAreaName: string) {
    this.setButtonVisibility(storageAreaId, storageAreaName, 'delete', 'hidden');
    this.setButtonVisibility(storageAreaId, storageAreaName, 'edit', 'hidden');
  }

  setButtonVisibility(storageAreaId: string, storageAreaName: string, type: string, visibility: string) {
    const identifier = storageAreaName.replace(' ', '-') + '-' + storageAreaId;
    const button = document.getElementById(`storage-area-list-${type}-button-${identifier}`);

    if (button) {
      button.style.visibility = visibility;
    }
  }

  onRightArrow(storageAreaId: string, storageAreaName: string) {
    if (document.activeElement.id.startsWith('storage-area-list-edit')) {
      this.setItemFocus('storage-area-list-delete-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else if (document.activeElement.id.startsWith('storage-area-list-delete')) {
      this.setItemFocus('storage-area-list-item-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else {
      this.setItemFocus('storage-area-list-edit-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    }
  }

  onLeftArrow(storageAreaId: string, storageAreaName: string) {
    if (document.activeElement.id.startsWith('storage-area-list-edit')) {
      this.setItemFocus('storage-area-list-item-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else if (document.activeElement.id.startsWith('storage-area-list-delete')) {
      this.setItemFocus('storage-area-list-edit-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    } else {
      this.setItemFocus('storage-area-list-delete-button-' + storageAreaName.replace(' ', '-') + '-' + storageAreaId);
    }
  }

  setItemFocus(id: string) {
    const element = document.getElementById(id);
    if (element) {
      document.getElementById(id).focus();
    }
  }

  onEditStorageArea(storageArea: StorageArea) {
    this.editStorageArea.emit(storageArea);
  }

  onDeleteStorageArea(storageArea: StorageArea): void {
    const title = this.translate.instant(
      'STORAGE_AREA.DELETE_STORAGE_AREA_MODAL.DELETE_STORAGE_AREA_DIALOG_TITLE',
      { storageAreaName: storageArea.name }
    );
    const subtitle = this.translate.instant('STORAGE_AREA.DELETE_STORAGE_AREA_MODAL.DELETE_STORAGE_AREA_DIALOG_SUBTITLE');
    const submitButtonAriaLabel = this.translate.instant('STORAGE_AREA.DELETE_STORAGE_AREA_BUTTON_ARIA_LABEL');
    const submitButtonLabel = this.translate.instant('STORAGE_AREA.DELETE_STORAGE_AREA_BUTTON');
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        returnData: storageArea.id,
        title,
        subtitle,
        cancelButtonId: 'cancel-delete-storage-area-button',
        submitButtonId: 'delete-storage-area-button',
        submitButtonAriaLabel,
        submitButtonLabel
      },
      width: '604px',
      height: '280px',
      disableClose: true
    });

    dialogRef.afterClosed()
      .subscribe(storageAreaId => {
        if (storageAreaId) {
          let worksheetId = '';
          this.worksheet$.pipe(first()).subscribe(ws => {
            worksheetId = ws.id;
            this.store.dispatch(new workSheetActions.DeleteStorageArea({ worksheetId, storageArea }));
          });
        }
      });
  }
}

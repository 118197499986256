import { Injectable } from '@angular/core';
import { CountableItem } from '@gfs/shared-models';

@Injectable({
  providedIn: 'root',
})
export class ItemTextSearchService {

  workItemTextSearch(
    textInput: string,
    lang: string,
    workItemId: string,
    countableItem: CountableItem
  ): boolean {
    const localizedDescription =
      countableItem?.gfsItem?.description.find(
        (localValue) => localValue.languageCode === lang
      )?.value ?? '';
    const localizedBrand =
      countableItem?.gfsItem?.brand.find(
        (localValue) => localValue.languageCode === lang
      )?.value ?? '';
    const customDescription = countableItem?.customItem?.description ?? '';
    const supplierId = countableItem?.customItem?.supplierId ?? '';
    const generalItemDescription = countableItem?.generalItem?.description ?? '';

    const inputFields = [
      workItemId,
      localizedDescription,
      localizedBrand,
      customDescription,
      supplierId,
      generalItemDescription,
    ];

    return inputFields.some((x) =>
      x.toLowerCase().includes(textInput.toLowerCase())
    );
  }
}

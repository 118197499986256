import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { RedirectUri } from '@gfs/shared-services';
import { Observable } from 'rxjs';
import { TakeInventoryResolver } from './take-inventory.resolver';

export const takeInventoryResolveFn: ResolveFn<Observable<RedirectUri>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<RedirectUri> => {
  const service = inject(TakeInventoryResolver);
  return service.resolve(route, state);
};

<gfs-select-menu
  buttonId="language-select"
  [disabled]="!(isOnline$ | async)"
  [options]="languages"
  [value]="selectedLanguage$ | async"
  (valueChange)="toggleLanguage($event)"
  [optionLabelFn]="getOptionLabel"
>
  <mat-icon class="language-icon" svgIcon="language"></mat-icon>
  <span>{{(selectedLanguage$ | async).display}}</span>
</gfs-select-menu>

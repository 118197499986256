import {Observable} from "rxjs";
import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from "@angular/router";
import {
  UnitSelectionSingleUnitRedirectGuard
} from "@gfs/shared-services/guards/unit-selection-single-unit-redirect.guard";

export const unitSelectionSingleUnitRedirectGuardFn: CanActivateFn =
  (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> => {
    const service = inject(UnitSelectionSingleUnitRedirectGuard);
    return service.canActivate(route, state);
  };

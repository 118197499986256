<div class="no-results-card">
  <div class="binoculars">
    <img alt="" src="assets/images/binoculars.svg" />
  </div>
  <div class="no-results-description">
    <div class="try-again">
      {{ 'SEARCH.TRY_AGAIN' | translate }}
    </div>
  </div>
</div>
<div class="search-tips-box">
  <div
    class="arrow"
    [ngClass]="{ 'arrow-left': !isMobile, 'arrow-up': isMobile }"
  ></div>
  <div class="search-tips-content">
    <div class="search-tips-title">{{ 'SEARCH.TIPS' | translate }}</div>
    <ul>
      <li class="search-tips">{{ 'SEARCH.CHECK_SPELLING' | translate }}</li>
      <li class="search-tips">{{ 'SEARCH.SIMPLE_TERMS' | translate }}</li>
      <li class="search-tips">{{ 'SEARCH.LESS_SPECIFIC' | translate }}</li>
    </ul>
  </div>
</div>

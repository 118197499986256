import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Customer, RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig, IAppContext } from '@gfs/shared-models';
import { GetCustomerDataAttempt, ICustomerUnitSelectionFeatureBridge, IFeatureStateFacade } from '@gfs/store/feature/customer-unit-selection';
import { InjectionTokens } from '@gfs/shared-services';
import { Authorization, Authorizations } from '@gfs/store/common';
import { Constants } from '@gfs/constants';

@Component({
  selector: 'gfs-customer-unit-selector',
  templateUrl: './customer-unit-selector.component.html',
  styleUrls: ['./customer-unit-selector.component.scss']
})
export class CustomerUnitSelectorComponent implements OnInit, OnDestroy {

  notifier = new Subject<void>();
  customers$ = this.store
    .select(state => state.customerUnitSelectionFeature.customers)
    .pipe(
      first(),
    );
  selectedEntitlement$ = this.featureContext.selectedEntitlement;

  routerLinkUrl$ = this.getRouterLinkUrl(this.appContext);

  constructor(
    private store: Store<IFeatureStateFacade>,
    @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext,
    @Inject(InjectionTokens.ICustomerUnitSelectionBridge) private featureContext: ICustomerUnitSelectionFeatureBridge,
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
  ) { }

  getRouterLinkUrl(
    appContext: IAppContext
  ): Observable<string> {
    return appContext.authorizations$
      .pipe(
        map((authorizations: Authorizations) => {
          const isRecipeProfitCalculator = authorizations[Authorization.RecipeProfitCalculator];
          return isRecipeProfitCalculator
            ? this.recipeProfitCalculatorConfig.RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATHNAME
            : Constants.SharedFeaturePaths.CUSTOMER_SELECTION_PATHNAME;
        }))
  }

  ngOnInit() {
    this.selectedEntitlement$
      .pipe(
        takeUntil(this.notifier),
        tap(selectedEntitlement => {

          if (selectedEntitlement) {
            this.store.dispatch(new GetCustomerDataAttempt(selectedEntitlement.customerPK));
          }
        }))
      .subscribe();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  getSelectedCustomerUnit(): Observable<Customer> {
    return combineLatest([this.customers$, this.selectedEntitlement$]).pipe(
      filter(([customers, selected]) => !!customers && !!selected),
      map(([customers, selected]) => {
        if (customers[selected?.customerPK?.customerId]) {
          return customers[selected.customerPK.customerId];
        } else if (selected?.customerName && selected?.customerPK) {
          return {
            customerName: selected.customerName,
            customerPK: selected.customerPK,
            statusCode: null,
            shipToAddress1: null,
            shipToAddress2: null,
            shipToCity: null,
            shipToCountry: null,
            shipToPhoneNumber: null,
            shipToPostalCode: null,
            shipToProvinceState: null
          };
        }
        return null;
      })
    );
  }
}

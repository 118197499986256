<ng-container [ngSwitch]="hasWorksheetSummaries$ | async">
  <ng-container *ngSwitchDefault>
    <div id="inventory-title-container">
      <h1 id="inventory-welcome-title">{{ 'INVENTORY.TITLE' | translate }}</h1>
      <h2 id="inventory-welcome-text">{{ 'INVENTORY.WELCOME_TEXT' | translate }}</h2>
      <div id="get-started-container">
        <button id="get-started-button-old" class="primary-button get-started-button" mat-raised-button color="primary"
          (click)="onGetStartedClick(false)" (keydown.ArrowRight)="onArrow()" (keydown.ArrowLeft)="onArrow()"
          attr.aria-label="{{'INVENTORY.GET_STARTED_ARIA_LABEL' | translate }}">
          {{ 'INVENTORY.GET_STARTED' | translate | uppercase }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="true">
    <h1 id="inventory-welcome-title">{{ 'INVENTORY.DO_TODAY_PROMPT' | translate }}</h1>
    <div id="get-started-container">
      <button id="take-inventory-button" class="primary-button get-started-button" mat-raised-button color="primary"
        [routerLink]="['/take-inventory']" (keydown.ArrowRight)="onArrow()" (keydown.ArrowLeft)="onArrow()"
        attr.aria-label="{{ (inventoryButtonText$ | async) | translate }}">
        {{ (inventoryButtonText$ | async) | translate | uppercase }}
      </button>
      <ng-container *ngxPermissionsOnly="reportingPermissions">
        <button *ngIf="appSettings.FF_REPORTS" id="run-report-button" class="primary-button get-started-button"
          mat-raised-button color="primary" [routerLink]="reportsUrl" (keydown.ArrowRight)="onArrow()"
          (keydown.ArrowLeft)="onArrow()" attr.aria-label="{{'INVENTORY.RUN_REPORT' | translate }}">
          {{ 'INVENTORY.RUN_REPORT' | translate | uppercase }}
        </button>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<mat-divider></mat-divider>

<div class="table-label">
  <div id="inventory-counts">{{ 'INVENTORY.COUNTS' | translate }}</div>
  <div *ngxPermissionsOnly="pricingPermissions" id="average-inventory-value">
    <div>{{ 'INVENTORY.AVERAGE_INVENTORY_VALUE' | translate }}:</div>
    <div class="inventory-value">{{worksheetAverageInventoryValue$ | async | currency:'CAD':'$':undefined:(currentLang$
      | async) }}</div>
  </div>
</div>
<mat-table id="summary-table" mat-table [dataSource]="activeWorksheetSummaries$ | async">

  <ng-container matColumnDef="Description">
    <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">DESCRIPTION</mat-header-cell>
    <mat-cell *matCellDef="let row" class="summary-cell mobile-card-title">
      <div class="summary-table-cell-value">{{translateName(row.name)}}</div>
    </mat-cell>
  </ng-container>

  <ng-template [ngxPermissionsOnly]="pricingPermissions" (permissionsAuthorized)="pricingAuthHandler(true)"
    (permissionsUnauthorized)="pricingAuthHandler(false)">
    <ng-container matColumnDef="Value">
      <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.VALUE' | translate | uppercase
        }}</mat-header-cell>
      <mat-cell *matCellDef="let row" class="summary-cell">
        <div class="summary-table-cell-value">{{(row.totalValue || 0) | currency:'CAD':'$':undefined:(currentLang$ |
          async)}}</div>
      </mat-cell>
    </ng-container>
  </ng-template>

  <ng-container matColumnDef="Created">
    <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.CREATED' | translate | uppercase
      }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="summary-cell">
      <div class="mobile-label">{{ 'INVENTORY.CREATED' | translate | uppercase }}</div>
      <div class="summary-table-cell-value">{{getSummaryTableDateTime(row.created, (currentLang$ | async))}}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="Modified">
    <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.MODIFIED' | translate |
      uppercase }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="summary-cell">
      <div class="mobile-label">{{ 'INVENTORY.MODIFIED' | translate | uppercase }}</div>
      <div class="summary-table-cell-value">{{getSummaryTableDateTime(row.lastModified, (currentLang$ | async))}}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="Status">
    <mat-header-cell scope="col" *matHeaderCellDef class="header-columns">{{ 'INVENTORY.STATUS' | translate | uppercase
      }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="summary-cell">
      <div class="mobile-label">{{ 'INVENTORY.STATUS' | translate | uppercase }}</div>
      <div class="summary-table-cell-value">{{getStatus(row.status)}}</div>
    </mat-cell>
  </ng-container>

  <ng-template [ngxPermissionsOnly]="permissionRoles.tripleDotMenu" (permissionsAuthorized)="tripleDotAuthHandler(true)"
    (permissionsUnauthorized)="tripleDotAuthHandler(false)">
    <ng-container matColumnDef="tripleDot">
      <mat-header-cell scope="col" *matHeaderCellDef class="header-columns tripledot"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="summary-cell tripledot">

        <ng-container *ngIf="!isInventoryInProcess(row.status)">
          <button mat-icon-button [matMenuTriggerFor]="worksheetMenu"
            attr.aria-label="{{'INVENTORY.TRIPLE_DOT_MENU_OPTIONS' | translate }}">
            <mat-icon svgIcon="triple-dot-menu"></mat-icon>
          </button>
          <mat-menu class="worksheet-tripledot-menu" #worksheetMenu>
            <button id="worksheet-menu-edit" mat-menu-item (click)="navigateToWorksheet(row.id)">{{
              'INVENTORY.TRIPLE_DOT_MENU_EDIT' | translate }}</button>
            <button *ngIf="canDeleteInventory(row)" id="worksheet-menu-delete" mat-menu-item
              (click)="deleteWorksheet(row)">{{ 'INVENTORY.TRIPLE_DOT_MENU_DELETE' | translate }}</button>
          </mat-menu>
        </ng-container>

        <ng-container *ngIf="isInventoryInProcess(row.status)">
          <div class="info-container">
            <mat-icon [inline]="true" svgIcon="info_icon"></mat-icon>
            <div class="info-overlay">
              <mat-icon [inline]="true" svgIcon="info_icon_hover" [matTooltip]="getInProcessInventoryToolTip()"
                matTooltipPosition="above" [matTooltipClass]="'info-tooltip'">
              </mat-icon>
            </div>
          </div>
        </ng-container>

      </mat-cell>
    </ng-container>
  </ng-template>

  <mat-header-row id="table-header" *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

import { Injectable } from '@angular/core';
import { MessageService } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class ErrorLogBaseService {
  constructor(
    public messageService: MessageService,
    public translate: TranslateService,
  ) { }

  logError<T>(
    error: any,
    operation: string,
    result?: T
  ): Observable<T> {
    console.log(error);
    const localizedErrorMessage = this.translate.instant('MESSAGES.SNACKBAR_ERROR_MESSAGE');
    this.notifyUser(`${localizedErrorMessage} ${operation}`);
    return of(result);
  }

  notifyUser(message: string): void {
    this.messageService.queue(message);
  }
}

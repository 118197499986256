import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';


export function notEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const trimmed = control.value? control.value.trim(): null;
        if (!trimmed) {
            return { notEmptyValidatorError: true };
        }
        return null;
    };
}

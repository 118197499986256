import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CustomItemData, PortioningUnit } from '@gfs/shared-models';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';
import { GfsValidators } from '../validation';

@Injectable({
  providedIn: 'root'
})
export class CustomUnitUtilsService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService
  ) { }

  getCountingUnitFormArray(form: UntypedFormGroup): UntypedFormArray {
    return (form?.controls?.countingUnits ?? []) as UntypedFormArray;
  }

  validateCountingUnitFormArray(form: UntypedFormGroup) {
    for (let i = 0; i < this.getCountingUnitFormArray(form).controls.length; i++) {
      this.getCountingUnitName(i, form).updateValueAndValidity();
    }
  }

  getCountingUnitName(formGroup: number, form: UntypedFormGroup): AbstractControl {
    return this.getCountingUnitFormArray(form).controls[formGroup]?.get('unitName');
  }

  trimCountingUnitName(formGroup: number, form: UntypedFormGroup) {
    const unitName = this.getCountingUnitName(formGroup, form).value;
    this.getCountingUnitFormArray(form).controls[formGroup]?.get('unitName').setValue(unitName.trim());
  }

  removeCountingQty(formGroup: number, form: UntypedFormGroup) {
    this.getCountingUnitFormArray(form).controls[formGroup]?.get('unitQty').setValue(null);
  }
  
  getCountingUnitQty(formGroup: number, form: UntypedFormGroup): AbstractControl {
    return this.getCountingUnitFormArray(form).controls[formGroup]?.get('unitQty');
  }

  isUserManagable(formGroup: number, form: UntypedFormGroup): boolean {
    const j = this.getCountingUnitFormArray(form).controls[formGroup];
    try {
      return j?.get('isUserManagable').value;
    } catch {
      return false;
    }
  }

  getCountingUnitIsDeleted(formGroup: number, form: UntypedFormGroup): AbstractControl {
    return this.getCountingUnitFormArray(form).controls[formGroup]?.get('unitDeleted');
  }

  getCountingUnitParent(formGroup, form: UntypedFormGroup): AbstractControl {
    return this.getCountingUnitFormArray(form).controls[formGroup]?.get('parentUnit');
  }

  countingUnitNameValidator(form: UntypedFormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control && control.value) {
        for (let i = 0; i < this.getCountingUnitFormArray(form).controls.length; i++) {
          const isDeleted = (this.getCountingUnitIsDeleted(i, form).value === true);
          const isDuplicateName = [
            [ control.value, this.getCountingUnitName(i, form).value]
          ].map( vals => vals.map( v => v.trim() ) )
          .map( ([v1, v2]) => caseInsensitiveEquals( v1, v2 ) )
          .indexOf(true) > -1;
          if ( isDeleted === false && isDuplicateName && control !== this.getCountingUnitName(i, form) ) {
            return { duplicateCustomUnit: true };
          }
        }
      }
      return null;
    };
  }

  getCustomUnitQtyPlaceholder(formGroup: number, form: UntypedFormGroup) {
    const unitName = this.getCountingUnitName(formGroup, form)?.value;
    return unitName ?
      this.translate.instant('ADD_ITEMS.NUMBER_OF_CUSTOM_UNIT', { value: unitName }) : this.translate.instant('ADD_ITEMS.NUMBER_OF_UNITS');
  }

  addCountingUnitGroup(form: UntypedFormGroup, name: string, qty: string, parent: string, isAllowedToEdit: boolean, isDeleted: boolean) {

    // only apply name validation to user managed units
    // its still checking against the entire set...
    const nameValidator = [
      Validators.required,
      isAllowedToEdit ? this.countingUnitNameValidator(form) : null
    ].filter(v => !!v);


    this.getCountingUnitFormArray(form).push(
      this.formBuilder.group({
        unitDeleted: new UntypedFormControl(
          { value: isDeleted, disabled: true }
        ),
        unitName: new UntypedFormControl(
          { value: name, disabled: !isAllowedToEdit },
          isDeleted ? [] : nameValidator
        ),
        unitQty: new UntypedFormControl(
          { value: qty, disabled: !isAllowedToEdit },
          [
            Validators.required,
            GfsValidators.greaterThan(0)
          ]
        ),
        parentUnit: new UntypedFormControl(
          { value: parent, disabled: !isAllowedToEdit },
          Validators.required
        ),
        isUserManagable: new UntypedFormControl(
          { value: isAllowedToEdit, disabled: true }
        ),

      })
    );
  }

  deleteCountingUnitGroup(formGroup: number, form: UntypedFormGroup) {
    // If it's blank or invalid, just hard-delete it
    if (this.getCountingUnitFormArray(form).controls[formGroup]?.invalid === true) {
      this.getCountingUnitFormArray(form).controls[formGroup]?.get('unitDeleted').setValue(true);
      this.getCountingUnitFormArray(form).controls.splice(formGroup, 1);
      this.getCountingUnitFormArray(form).updateValueAndValidity();
    }
    // Otherwise, soft-delete it by marking it as deleted and hiding it
    else {
      this.getCountingUnitFormArray(form).controls[formGroup]?.get('unitDeleted').setValue(true);
      this.getCountingUnitFormArray(form).controls[formGroup].markAsDirty();
      this.getCountingUnitFormArray(form).updateValueAndValidity();

    }
  }

  hasEditedRecipeUnits(itemData: CustomItemData, form: UntypedFormGroup): boolean {
    const recipeUnitList = this.getCountingUnitFormArray(form);
    if (!itemData && recipeUnitList.length === 0) {
      return false;
    } else if ((!itemData && recipeUnitList.length > 0) || (itemData?.recipeUnits?.length !== recipeUnitList.length)) {
      return true;
    }
    return recipeUnitList.controls.some((control, index) => {
      return control.get('unitName').value !== itemData.recipeUnits?.[index]?.custom?.name
        || control.get('unitQty').value !== itemData.recipeUnits?.[index]?.custom?.qtyInParent
        || control.get('parentUnit').value !== itemData.recipeUnits?.[index]?.custom?.parentUnitId
        || control.get('unitDeleted').value !== itemData.recipeUnits?.[index]?.custom?.deleted;
    });
  }

  createRecipeUnitList(itemData: CustomItemData, form: UntypedFormGroup): PortioningUnit[] {
    const recipeUnitControlList = this.getCountingUnitFormArray(form);
    return recipeUnitControlList.controls.map((control, index) => ({
      custom: {
        id: itemData?.recipeUnits?.[index]?.custom?.id ?? uuid.v4(),
        qtyInParent: control.get('unitQty').value,
        parentUnitId: control.get('parentUnit').value,
        name: control.get('unitName').value,
        deleted: control.get('unitDeleted').value
      },
      standard: null
    }));
  }

}

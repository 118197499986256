import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerPK, GeneralItem, Product } from '@gfs/shared-models';
import { Observable, of, forkJoin } from 'rxjs';
import { InventoryConstants } from '@gfs/constants';
import { map } from 'rxjs/operators';
import { GeneralItemUpdate } from '@gfs/shared-models';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class GeneralItemService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getGeneralItems(
    ids: string[],
    includeDeleted: boolean,
    customerPK: CustomerPK
  ): Observable<GeneralItem[]> {
    if (!ids) {
      return this.http.get<GeneralItem[]>(`${this.baseUrl}/general-items`,
        {
          params: {
            includeDeleted: includeDeleted.toString(),
            ...customerPK
          }
        });
    }
    const generalItems = ids.slice();
    const requests = [];
    while (generalItems.length) {
      const currentIds = generalItems.splice(0, InventoryConstants.MAX_INVENTORY_PRODUCTS).join(',');
      const options = { params: { ids: currentIds, includeDeleted: includeDeleted.toString(), ...customerPK } };
      requests.push(this.http.get<GeneralItem[]>(`${this.baseUrl}/general-items`, options));
    }
    return forkJoin(requests).pipe(map(response => [].concat(...response)));
  }

  getGeneralItemByCustomItemId(
    customItemId: string,
    customerPK: CustomerPK
  ): Observable<GeneralItem> {
    return this.http.get<GeneralItem>(
      `${this.baseUrl}/general-items/product-list/${customItemId}`,
      { params: { ...customerPK, itemType: 'CUSTOM' } }
    );
  }

  createGeneralItem(
    customerPK: CustomerPK,
    description: string,
    categoryId: string,
    productList: Product[]
  ): Observable<GeneralItem> {
    return this.http.post<GeneralItem>(`${this.baseUrl}/general-items`, { customerPK, description, productList, categoryId });
  }

  updateGeneralItem(
    generalItemId: string,
    fields: GeneralItemUpdate
  ): Observable<GeneralItem> {
    return this.http.patch<GeneralItem>(`${this.baseUrl}/general-items/${generalItemId}`, fields);
  }

  deleteGeneralItem(
    generalItemId: string
  ): Observable<GeneralItem> {
    return this.http.delete<GeneralItem>(`${this.baseUrl}/general-items/${generalItemId}`);
  }
  deleteGroupGeneralItem(
    generalItemIdList: GeneralItem[]
  ): Observable<GeneralItem[]> {
    const generalIdListString = generalItemIdList.map((item)=>item.id)
    return this.http.post<GeneralItem[]>(`${this.baseUrl}/general-items/bulk/delete`,
    {
      generalItemIdList: generalIdListString
    });
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerPK, PortioningUnit, CustomItemData, PurchaseUnit } from '@gfs/shared-models';
import { Observable } from 'rxjs';
import { InjectionTokens } from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class CustomItemDataService {
  private baseUrl: string;
  constructor(
    private http: HttpClient,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private API_BASE_URL: string,
  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getCustomItemData(
    customerPK: CustomerPK,
    itemId: string,
    itemType: string
  ): Observable<CustomItemData[]> {
    const params = !!(itemId && itemType)
      ? { ...customerPK, itemId, itemType }
      : { ...customerPK };

    return this.http.get<CustomItemData[]>(`${this.baseUrl}/custom-item-data`, { params });
  }

  createCustomItemData(
    customerPK: CustomerPK,
    itemId: string,
    itemType: string,
    countingUnits: PortioningUnit[],
    categoryId: string,
    purchasedByUnit: boolean,
    purchaseUnit: PurchaseUnit,
    recipeUnits: PortioningUnit[]
  ): Observable<CustomItemData> {
    return this.http.post<CustomItemData>(`${this.baseUrl}/custom-item-data`, {
      customerPK,
      itemId,
      itemType,
      countingUnits,
      categoryId,
      purchasedByUnit,
      purchaseUnit,
      recipeUnits
    });
  }

  updateCustomItemData(
    customItemDataId: string,
    countingUnits: PortioningUnit[],
    categoryId: string,
    purchasedByUnit: boolean,
    purchaseUnit: PurchaseUnit,
    recipeUnits: PortioningUnit[]
  ): Observable<CustomItemData> {
    return this.http.patch<CustomItemData>(`${this.baseUrl}/custom-item-data/${customItemDataId}`, {
      countingUnits,
      categoryId,
      purchasedByUnit,
      purchaseUnit,
      recipeUnits
    });
  }
}

import * as network from '../actions/network.actions';

export interface NetworkFeatureState {
  isOnline: boolean;
  retryingConnection: boolean;
}

export const initialNetworkFeatureState: NetworkFeatureState = {
  isOnline: navigator.onLine,
  retryingConnection: false,
};

export interface IFeatureStateFacade {
  network: {
    isOnline: boolean;
    retryingConnection: boolean;
  };
}

export function networkReducer(
  state: NetworkFeatureState = initialNetworkFeatureState,
  action: network.ActionUnion
): NetworkFeatureState {
  switch (action.type) {
    case network.ActionTypes.SetIsOnline:
      return {
        ...state,
        isOnline: action.payload,
      };
    case network.ActionTypes.SetRetryingConnection:
      return {
        ...state,
        retryingConnection: action.payload,
      };
    default:
      return state;
  }
}

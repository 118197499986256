import { inject } from '@angular/core';
import { RouterStateSnapshot, CanActivateFn, Router } from '@angular/router';
import { Observable, first, forkJoin, map, of, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { Authorization, IAuthStateFacade } from '@gfs/store/common';
import { ActivatedAuthorizedRouteSnapshot } from '../models/ActivatedAuthorizedRouteSnapshot';
import { RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';

export const featureAuthorizationGuard: CanActivateFn =
  (
    route: ActivatedAuthorizedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store<IAuthStateFacade> = inject(Store<IAuthStateFacade>),
    router: Router = inject(Router),
    recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig = inject(RECIPE_PROFIT_CALCULATOR_CONFIG),
  ): Observable<boolean> => {
    return forkJoin([
      of(route.data),
      store.select(state => state.auth.authorizations).pipe(first())
    ])
      .pipe(
        map(([data, currentAuthorizations]) => {
          const configuredAuthorizations = Object.entries(data.requiredAuthorizations)
            .map(([requiredAuthorization, requiredValue]: [Authorization, boolean]) => ({
              authorization: requiredAuthorization,
              isAuthorized: currentAuthorizations[requiredAuthorization] === requiredValue
            }));

          return {
            configuredAuthorizations,
            isSuccessful: configuredAuthorizations
              .every(auth => auth.isAuthorized)
          };
        }),
        withLatestFrom(of(router)),
        tap(([dto, router]) => {
          if (!dto.isSuccessful) {
            if (!dto.configuredAuthorizations[Authorization.RecipeProfitCalculator]) {
              const path = recipeProfitCalculatorConfig.RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH;
              router.navigateByUrl(path);
            }
          }
        }),
        map(([dto,]) => {
          return dto.isSuccessful;
        })
      )
  };

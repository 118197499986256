import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { WorksheetItemConfigDTO } from '@gfs/shared-models';
import { WorksheetHttpService } from '@gfs/shared-services';
import { SetCountableItem } from '@gfs/store/inventory/actions/inventoryItems.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GetProductResolver implements Resolve<Observable<WorksheetItemConfigDTO>> {

  constructor(
    private loadingSpinner: LoadingSpinnerOverlayService,
    private worksheetHttp: WorksheetHttpService,
    private store: Store<AppState>
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<WorksheetItemConfigDTO> {
    return of({ ...route.params, ...route.parent.params })
      .pipe(
        tap(() => { this.loadingSpinner.show(); }),
        concatMap(({
          id: worksheetId,
          worksheetItemId
        }) => {
          return this.worksheetHttp.createRequestGetEditableWorksheetItemGetRequest(worksheetId, worksheetItemId);
        }),
        tap(countableItem => {
          this.store.dispatch(new SetCountableItem(countableItem))
        }),
        tap(() => this.loadingSpinner.hide()),
        first(),
      );
  }

}
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomerPK, Entitlement, GfsMaterialUnit, GfsMaterialUnitsResult, IDictionary, LocalizedValue, SelectedEntitlementObservableDependency } from '@gfs/shared-models';
import { API_DATA_GFS_MATERIAL_UNITS, AppFeatureFlags, langCodeMap, localizedSAPUnits } from '@gfs/shared-services';
import { tapLog } from '@gfs/shared-services/extensions/rxjs';
import { EntityUtil } from '@gfs/shared-services/util/auth/entity-util';
import { InjectionTokens } from 'libs/shared-services/src/injection-tokens';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnitsHttpService {
  sapUOMCache$ = new BehaviorSubject<localizedSAPUnits>(null);
  destroy$ = new Subject<boolean>();
  isInit = false;
  constructor(
    private http: HttpClient,
    @Inject(EntityUtil) public entityUtil: EntityUtil,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private baseUrl: string,
    private selectedEntitlement: SelectedEntitlementObservableDependency,
    private featureFlags: AppFeatureFlags
  ) {
  }

  init(): void {
    if (!this.isInit) {
      this.isInit = true;
      this.initBehavior$()
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  getCurrentEntitlementUOMs$(): Observable<localizedSAPUnits> {
    this.init()

    return this.sapUOMCache$
      .pipe(
        filter(val => !!val)
      );

  }

  private initBehavior$(): Observable<[Entitlement, localizedSAPUnits]> {
    return combineLatest([
      this.createBehaviorResetCacheOnEntitlementChange$(this.selectedEntitlement.value, this.sapUOMCache$),
      this.createBehaviorAutoLoadEntitlementSAPUnits$(this.selectedEntitlement.value, this.sapUOMCache$)
    ]);
  }

  private createBehaviorResetCacheOnEntitlementChange$(
    notifierSubject: Observable<Entitlement>,
    cacheSubject: BehaviorSubject<localizedSAPUnits>
  ): Observable<Entitlement> {
    return notifierSubject.pipe(
      tap(_ => { cacheSubject.next(null); })
    );
  }

  private createBehaviorAutoLoadEntitlementSAPUnits$(
    entitlementSubject$: Observable<Entitlement>,
    cacheSubject$: Subject<localizedSAPUnits>
  ): Observable<localizedSAPUnits> {
    return combineLatest([
      entitlementSubject$,
      cacheSubject$,
    ]).pipe(
      debounceTime(500),
      filter(([entitlement, cache]) => !!entitlement && !cache),
      tapLog('Entitlement changed, reloading unit data'),
      concatMap(([{ customerPK }]) => this.getGFSMaterialUnitInfo$(customerPK)),
      map((unitData) => ({
        single: unitData.map(e => {
          return ({
            type: e.displayCode,
            name: this.getLocalizations(e.descriptions.single),
          });
        }),
        plural: unitData
          .map(e => ({
            type: e.displayCode,
            name: this.getLocalizations(e.descriptions.plural),
          }))
      }) as localizedSAPUnits),
      tap(v => {
        cacheSubject$.next(v);
      })
    );
  }

  private getLocalizations(
    dictionary: IDictionary<string>
  ): LocalizedValue[] | IDictionary<string> {
    const localizedValues: LocalizedValue[] = [];

    if (dictionary.map) {
      return dictionary;
    }
    for (const [, key] of Object.keys(dictionary).entries()) {
      localizedValues.push({
        languageCode: langCodeMap[key.toLowerCase()],
        value: dictionary[key]
      });
    }
    return localizedValues;
  }

  getGFSMaterialUnitInfo$(
    customerPK: CustomerPK
  ): Observable<GfsMaterialUnit[]> {

    const useStaticSAPUnits = this.featureFlags.System.UseStaticSAPUnits;
    if (useStaticSAPUnits) {
      return of(API_DATA_GFS_MATERIAL_UNITS);
    }

    const isCustomerEntity = this.entityUtil.isCustomerEntity(customerPK);
    if (isCustomerEntity) {
      const url = `${this.baseUrl}/api/v1/products-units`;
      const params = { params: { ...customerPK } };
      return this.http.get<GfsMaterialUnitsResult>(url, params)
        .pipe(
          map(r => r.displayCodes),
          catchError(err => {
            console.error(err);
            return of([]);
          })
        );
    }

    return of([]);
  }
}
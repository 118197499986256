import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomerPK } from '@gfs/shared-models';
import { MessageService, ErrorLogBaseService } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { InjectionTokens } from 'libs/shared-services/src/injection-tokens';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UsMigrationEligibility, UsMigrationOptions, UsMigrationResponse } from '../models/inventory-migration.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryMigrationService
  extends ErrorLogBaseService {
  private readonly baseUrl: string;

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public translate: TranslateService,
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string
  ) {
    super(messageService, translate);
    this.baseUrl = API_BASE_URL + '/api/v1';
  }

  getUsMigrationEligibility(
    pk: CustomerPK
  ): Observable<UsMigrationEligibility> {
    return this.http
      .post<UsMigrationEligibility>(`${this.baseUrl}/migration/eligibility`, pk)
      .pipe(
        catchError(error => this.logError<UsMigrationEligibility>(error, 'getUsMigrationEligibility', {
          hasWorksheet: false,
          hasUdi: false,
        }))
      );
  }

  commitInventoryMigration(
    pk: CustomerPK,
    options: UsMigrationOptions
  ): Observable<UsMigrationResponse> {
    const migrateUrl =
      `${this.baseUrl}/migration/migrate?migrateCustomItems=${options.migrateCustomItems}&migrateWorksheet=${options.migrateWorksheet}`;
    return this.http
      .post<UsMigrationResponse>(migrateUrl, pk)
      .pipe(
        catchError(error => this.logError<UsMigrationResponse>(error, 'commitInventoryMigration', {
          migratedCategories: [],
          migratedSuppliers: [],
          migratedCustomItems: [],
          migratedCustomItemDatas: [],
          migratedGeneralItems: [],
          legacyIdToNewIdMap: new Map(),
          migratedStorageAreas: [],
          migratedWorksheet: []
        }))
      );
  }

  logError<T>(error: any, operation: string, result?: T): Observable<T> {
    console.log(error);

    const localizedErrorMessage = this.translate.instant(
      'MESSAGES.SNACKBAR_ERROR_MESSAGE'
    );

    this.notifyUser(`${localizedErrorMessage} ${operation}`);
    return of(result);
  }
}

<button
  [id]="buttonId"
  #menuButton="matMenuTrigger"
  mat-button
  [attr.aria-label]="buttonLabel"
  [disabled]="disabled"
  class="mat-button-base select-menu-button"
  (mouseenter)="hoverOpenMenu(menuButton)"
  (mouseleave)="hoverCloseMenu(menuButton)"
  (menuClosed)="isMenuOpen = false"
  (menuOpened)="isMenuOpen = true"
  [matMenuTriggerFor]="menu">
    <mat-icon
      class="select-menu-arrow"
      [ngClass]="{ 'rotate-arrow': isMenuOpen }"
      svgIcon="arrow">
    </mat-icon>
  <ng-content></ng-content>
</button>
<mat-menu #menu="matMenu" [hasBackdrop]="false">
  <span
    (mouseenter)="hoverOpenMenu(menuButton)"
    (mouseleave)="hoverCloseMenu(menuButton)">
    <button
      *ngFor="let option of options"
      mat-menu-item
      (click)="selectOption(option)"
      [ngClass]="{'selected': value === option}"
      [attr.id]="getOptionId(option)">
      <mat-icon
        *ngIf="option.icon"
        class="{{option.icon}}-icon option-icon mat-icon"
        [svgIcon]="option.icon">
      </mat-icon>
      <span [innerHTML]="getOptionLabel(option)"></span>
    </button>
  </span>
</mat-menu>
